import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import LocationService from '../../../services/settings/location.service';
const locationService = new LocationService;

export const getAreas = () => dispatch => {
    dispatch(fetchAreasBegin());
    locationService.getAreasFetch().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchAreasSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchAreasFailure(res ?.data ?.response));
        }
    });
};

function fetchAreasBegin() {
    return {
        type: _Constants.FETCH_AREAS_BEGIN
    };
}

function fetchAreasSuccess(payload) {
    return {
        type: _Constants.FETCH_AREAS_SUCCESS,
        payload: payload,
    };
}

function fetchAreasFailure(error) {
    return {
        type: _Constants.FETCH_AREAS_FAILURE,
        payload: error
    };
}

export const getVendors = () => dispatch => {
    dispatch(fetchVendorsBegin());
    locationService.getVendorsFetch().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchVendorsSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchVendorsFailure(res ?.data ?.response));
        }
    });
};

function fetchVendorsBegin() {
    return {
        type: _Constants.FETCH_VENDORS_BEGIN
    };
}

function fetchVendorsSuccess(payload) {
    return {
        type: _Constants.FETCH_VENDORS_SUCCESS,
        payload: payload,
    };
}

function fetchVendorsFailure(error) {
    return {
        type: _Constants.FETCH_VENDORS_FAILURE,
        payload: error
    };
}

export const fetchCustomers = (payload) => dispatch => {
    dispatch(fetchCustomersBegin());
    locationService.fetchCustomersData(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchCustomersSuccess(res ?.data ?.viewResponse));
        } else {
            dispatch(fetchCustomersFailure(res ?.data ?.response));
        }
    });
};

function fetchCustomersBegin() {
    return {
        type: _Constants.FETCH_CUSTOMERS_BEGIN
    };
}

function fetchCustomersSuccess(payload) {
    return {
        type: _Constants.FETCH_CUSTOMERS_SUCCESS,
        payload: payload,
    };
}

function fetchCustomersFailure(error) {
    return {
        type: _Constants.FETCH_CUSTOMERS_FAILURE,
        payload: error
    };
}

export const fetchLocation = (payload, isReset) => dispatch => {
    dispatch(fetchLocationsBegin());
    locationService.getLocationsFetch(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchLocationsSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchLocationsFailure(res ?.data ?.response));
        }
    });
};

export const deleteLocation = (id, payload) => dispatch => {
    dispatch(fetchLocationsBegin());
    locationService.deleteLocation(id).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            fetchLocation(payload);
        } else {
            dispatch(fetchLocationsFailure(res ?.data ?.response));
        }
    });
}

function fetchLocationsBegin() {
    return {
        type: _Constants.FETCH_LOCATIONS_BEGIN
    };
}

function fetchLocationsSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_LOCATIONS_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchLocationsFailure(error) {
    return {
        type: _Constants.FETCH_LOCATIONS_FAILURE,
        payload: error
    };
}

export const getLocationById = params => dispatch => {
    dispatch(fetchlocationByIdBegin());
    locationService.getlocationById(params).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchlocationByIdSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchlocationByIdFailure(res ?.data ?.response));
        }
    });
}

function fetchlocationByIdBegin() {
    return {
        type: _Constants.FETCH_LOCATIONBYID_BEGIN
    };
}

function fetchlocationByIdSuccess(payload) {
    return {
        type: _Constants.FETCH_LOCATIONBYID_SUCCESS,
        payload: payload
    };
}

function fetchlocationByIdFailure(error) {
    return {
        type: _Constants.FETCH_LOCATIONBYID_FAILURE,
        payload: error
    };
}

export const createLocation = (payload) => dispatch => {
    dispatch(locationModificationBegin());
    locationService.createLocation(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(locationModificationSuccess(res ?.data ?.response));
        } else {
            dispatch(locationModificationFailure(res ?.data ?.response));
        }
    });
}

export const updateLocation = (id, payload) => dispatch => {
    dispatch(locationModificationBegin());
    locationService.updateLocation(id, payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(locationModificationSuccess({}));
        } else {
            dispatch(locationModificationFailure(res ?.data ?.response));
        }
    });
}

function locationModificationBegin() {
    return {
        type: _Constants.LOCATION_MODIFICATION_BEGIN
    };
}

function locationModificationSuccess(payload) {
    return {
        type: _Constants.LOCATION_MODIFICATION_SUCCESS,
        payload: payload
    };
}

function locationModificationFailure(error) {
    return {
        type: _Constants.LOCATION_MODIFICATION_FAILURE,
        payload: error
    };
}

export const getPromiseTime = (payload) => dispatch => {
    dispatch(promistTimeModificationBegin());
    locationService.createCustomerPromiseTimeRules(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(promistTimeModificationSuccess(res ?.data ?.response));
        } else {
            dispatch(promistTimeModificationFailure(res ?.data ?.response));
        }
    });
}

function promistTimeModificationBegin() {
    return {
        type: _Constants.PROMISE_TIME_MODIFICATION_BEGIN
    };
}

function promistTimeModificationSuccess(payload) {
    return {
        type: _Constants.PROMISE_TIME_MODIFICATION_SUCCESS,
        payload: payload
    };
}

function promistTimeModificationFailure(error) {
    return {
        type: _Constants.PROMISE_TIME_MODIFICATION_FAILURE,
        payload: error
    };
}

export const getlocationByGroup = (payload) => dispatch => {
    dispatch(groupLocationBegin());
    locationService.getlocationByGroup(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(groupLocationSuccess(res ?.data ?.response));
        } else {
            dispatch(groupLocationFailure(res ?.data ?.response));
        }
    });
}

function groupLocationBegin() {
    return {
        type: _Constants.GROUP_LOCATION_BEGIN
    };
}

function groupLocationSuccess(payload) {
    return {
        type: _Constants.GROUP_LOCATION_SUCCESS,
        payload: payload
    };
}

function groupLocationFailure(error) {
    return {
        type: _Constants.GROUP_LOCATION_FAILURE,
        payload: error
    };
}

// export const getCompleteData = (payload) => dispatch => {
//     locationService.getCompleteData(payload).then(res => {
//         console.log(res)
//     });
// }