import React, { Component } from 'react';
import { Row, Button, Modal, Select, Form } from 'antd';
import { connect } from 'react-redux';
import { Constants } from '../../../common/utils/constants';
import * as actions from './actions';

class LsnFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            loading: false,
            selectedValues: {},
        }
    }

    handlefilterCancel = () => {
      this.props.cancelModal();
    };
    
    handleFilterOk = () => {
      this.props.handleSubmit(this.state.selectedValues);
    };

   entityTypeChange = (entityType) => {
    let selectedObject = {...this.state.selectedValues};
    if (entityType === 'CUSTOMER') {
      this.props.fetchCustomers({view: 'LITE_CUSTOMER_LIST'});
    } else if (entityType === 'VENDOR') {
      this.props.getVendors();
    } else {}
    selectedObject['entityType'] = entityType;
    selectedObject['entityIds'] = [];
    this.props.form.resetFields();
    this.setState({ownerType : entityType, selectedValues : selectedObject })
  }

  handlefilterClear = () => {
    this.setState({ selectedValues : {}});
    this.props.form.resetFields()
  }

  getTypeownerData = (entityType) => {
    if (entityType === 'CUSTOMER') {
      return this.props ?.ownerCustomer;
    } else if (entityType === 'VENDOR') {
      return this.props ?.ownervendor;
    } else {
      return [{id: Constants.PARTNER_ID, name: 'Self'}];
    }
  }

  onUserChange = (entityIds) => {
    let selectedObject = {...this.state.selectedValues};
    selectedObject['entityIds'] = entityIds;
    this.setState({selectedValues : selectedObject});
  }


    render() {
      const { form, visible, title} = this.props;
      const { getFieldDecorator } = form;
      let userOptions;
      if(this.state.ownerType !== null){ 
        userOptions = this.getTypeownerData(this.state.ownerType)
      }
        return (
          <>
            <Row gutter={24}>
              <Modal
                  title={title} 
                  visible={visible}
                  onOk={this.handleFilterOk}
                  width={400}
                  onCancel={this.handlefilterCancel}
                  footer={[
                    <Button key="back" onClick={this.handlefilterClear}>
                      Clear Filters
                    </Button>,
                    <Button key="submit" type="primary"  onClick={this.handleFilterOk}>
                      Apply Filters
                    </Button>,
                  ]}
              >
            <Form>
            <Form.Item label="Owner Type">
              {getFieldDecorator('locationOwner', {
                rules: [
                  {
                    required: true,
                    message: "Please input Owner Type"
                  }
                ],
              })(
                <Select placeholder="Select Owner Type" showSearch filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } showArrow onChange={this.entityTypeChange}>
                  {Constants.locationOwners ?.map(locOwn => (
                    <Select.Option
                      key={'owner' + locOwn.name}
                      value={locOwn.value}
                      label={locOwn.name}
                    >
                      {locOwn.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Owner">
                {getFieldDecorator('locationuser', {
                  rules: [
                    {
                      required: true,
                      message: "Please input Owner"
                    }
                  ],
                })(
                  <Select mode="multiple" placeholder="Select Owner" showSearch filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } showArrow onChange={this.onUserChange}>
                    {userOptions ?.map(user => (
                      <Select.Option
                        key={'user' + user.name}
                        value={user.id}
                        label={user.name}
                      >
                        {user.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Form>
              </Modal>
            </Row>
            </>
        );
    }
}

const mapStateToProps = state => ({
  ownerCustomer: state.settingLocation.viewResponse,
  ownervendor: state.settingLocation.vendors,
});

const mapDispatchToProps = dispatch => ({
  fetchCustomers: param => dispatch(actions.fetchCustomers(param)),
  getVendors: () => dispatch(actions.getVendors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'LsnFilters', onValuesChange: (props, val) => console.log(props.form.getFieldsValue()) })(LsnFilters));