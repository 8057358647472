import * as CONSTANTS from './constants';
import { removeDuplicateLocations } from '../../../common/utils/utils';


export const PartnerCustomerUserReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.GET_PARTNER_USERS_LIST_BEGIN: return getUsersBegin(state);
        case CONSTANTS.GET_PARTNER_USERS_LIST_SUCCESS: return getUsersSuccess(state, action);
        case CONSTANTS.GET_PARTNER_USERS_LIST_FAILURE: return getUsersFailure(state);



        case CONSTANTS.GET_PARTNER_CUSTOMER_USERS_LIST_BEGIN: return getPartnerUsersBegin(state);
        case CONSTANTS.GET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS: return getPartnerUsersSuccess(state, action);
        case CONSTANTS.GET_PARTNER_CUSTOMER_USERS_LIST_FAILURE: return getPartnerUsersFailure(state);


        case CONSTANTS.GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_BEGIN: return getEditUsersBegin(state);
        case CONSTANTS.GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_SUCCESS: return getEditUsersSuccess(state, action);
        case CONSTANTS.GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_FAILURE: return getEditUsersFailure(state);


        case CONSTANTS.SET_PARTNER_CUSTOMER_USERS_LIST_BEGIN: return setUsersBegin(state);
        case CONSTANTS.SET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS: return setUsersSuccess(state, action);
        case CONSTANTS.SET_PARTNER_CUSTOMER_USERS_LIST_FAILURE: return setUsersFailure(state);


        case CONSTANTS.SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_BEGIN: return updateUsersBegin(state);
        case CONSTANTS.SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_SUCCESS: return updateUsersSuccess(state, action);
        case CONSTANTS.SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_FAILURE: return updateUsersFailure(state);

        case CONSTANTS.FETCH_PARTNER_CUSTOMER_LOCATIONS_BEGIN: return fetchLocationBegin(state);
        case CONSTANTS.FETCH_PARTNER_CUSTOMER_LOCATIONS_SUCCESS: return fetchLocationSuccess(state, action);
        case CONSTANTS.FETCH_PARTNER_CUSTOMER_LOCATIONS_FAILURE: return fetchLocationFailure(state);


        case CONSTANTS.GET_PARTNER_CUSTOMER_RESEND_INVITE_BEGIN: return getInviteBegin(state);
        case CONSTANTS.GET_PARTNER_CUSTOMER_RESEND_INVITE_SUCCESS: return getInviteSuccess(state, action);
        case CONSTANTS.GET_PARTNER_CUSTOMER_RESEND_INVITE_FAILURE: return getInviteFailure(state);


        case CONSTANTS.GET_CUSTOMER_DEACTIVATE_BEGIN: return getDeactivateBegin(state);
        case CONSTANTS.GET_CUSTOMER_DEACTIVATE_SUCCESS: return getDeactivateSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_DEACTIVATE_FAILURE: return getDeactivateFailure(state);


        case CONSTANTS.GET_EXPORT_CUSTOMERS_BEGIN: return getExportBegin(state);
        case CONSTANTS.GET_EXPORT_CUSTOMERS_SUCCESS: return getExportSuccess(state, action);
        case CONSTANTS.GET_EXPORT_CUSTOMERS_FAILURE: return getExportFailure(state);

        case CONSTANTS.RESET_CUSTOMER_USERS_FIELDS: return resetFields(state);
        case CONSTANTS.RESET_CUSTOMER_USERS_EDIT_FIELDS: return resetEditFields(state);
        case CONSTANTS.RESET_CUSTOMER_LOCATIONS_FIELDS: return resetEditLocationsFields(state);
        default:
            return state;
    }
}

function resetFields(state) {
    return {};
}

function resetEditFields(state) {
    return {
        ...state,
        editUsers: {}
    };
}

function resetEditLocationsFields(state) {
    return {
        ...state,
        locations:{}
    }
}

function getUsersBegin(state) {
    return {
        ...state,
        users: {
            loading: true
        },
        userInvite: undefined
    }
}

function getUsersSuccess(state, action) {
    return {
        ...state,
        users: {
            usersData: action.payload,
            loading: false
        },
        userInvite: undefined
    }
}

function getUsersFailure(state) {
    return {
        ...state,
        users: {
            loading: false
        }
    }
}


function getPartnerUsersBegin(state) {
    return {
        ...state,
        partnerUsers: {
            loading: true
        },
        userInvite: undefined
    }
}

function getPartnerUsersSuccess(state, action) {
    return {
        ...state,
        partnerUsers: {
            usersData: action.payload,
            loading: false
        },
        userInvite: undefined
    }
}

function getPartnerUsersFailure(state) {
    return {
        ...state,
        partnerUsers: {
            loading: false
        }
    }
}

function getEditUsersBegin(state) {
    return {
        ...state,
        editUsers: {
            loading: true,
            usersData: []
        },
        userInvite: undefined
    }
}

function getEditUsersSuccess(state, action) {
    return {
        ...state,
        editUsers: {
            usersData: action.payload,
            loading: false
        },
        userInvite: undefined
    }
}

function getEditUsersFailure(state) {
    return {
        ...state,
        editUsers: {
            loading: false
        }
    }
}

function setUsersBegin(state) {
    return {
        ...state,
        createUser: {
            loading: true
        }        
    }
}

function setUsersSuccess(state, action) {
    return {
        ...state,
        createUser: {
            userInvite: action.payload,
            loading: false
        }        
    }
}

function setUsersFailure(state) {
    return {
        ...state,
        createUser: {
            loading: false
        }
    }
}

function updateUsersBegin(state) {
    return {
        ...state,
        updateUser: {
            loading: true
        }        
    }
}

function updateUsersSuccess(state, action) {
    return {
        ...state,
        updateUser: {
            userInvite: action.payload,
            loading: false
        }        
    }
}

function updateUsersFailure(state) {
    return {
        ...state,
        updateUser: {
            loading: false
        }
    }
}

function fetchLocationBegin(state) {
    return {
        ...state,
        locations: {
            loading: true
        }    
    }
}

function fetchLocationSuccess(state, action) {
    return {
        ...state,
        locations: {
            locationsList: removeDuplicateLocations(action.payload.locations, { nameWithRole: 'name' }),
            loading: false
        },       
    }
}

function fetchLocationFailure(state) {
    return {
        ...state,
        locations: {
            loading: false
        }
    }
}


function getInviteBegin(state) {
    return {
        ...state,
        invite: {
            loading: true
        }    
    }
}

function getInviteSuccess(state, action) {
    return {
        ...state,
        invite: {
            inviteData: action.payload,
            loading: false
        },       
    }
}

function getInviteFailure(state) {
    return {
        ...state,
        invite: {
            loading: false
        }
    }
}


function getDeactivateBegin(state) {
    return {
        ...state,
        deactivate: {
            loading: true
        }    
    }
}

function getDeactivateSuccess(state, action) {
    return {
        ...state,
        deactivate: {
            deactivatedata: action.payload,
            loading: false
        },       
    }
}

function getDeactivateFailure(state) {
    return {
        ...state,
        deactivate: {
            loading: false
        }
    }
}



function getExportBegin(state) {
    return {
        ...state,
        export: {
            loading: true
        }    
    }
}

function getExportSuccess(state, action) {
    return {
        ...state,
        export: {
            exportData: {...action.payload},
            loading: false
        },       
    }
}

function getExportFailure(state) {
    return {
        ...state,
        export: {
            loading: false
        }
    }
}