export const FETCH_CUSTOM_CONFIG_BEGIN = "FETCH_CUSTOM_CONFIG_BEGIN";
export const FETCH_CUSTOM_CONFIG_SUCCESS = "FETCH_CUSTOM_CONFIG_SUCCESS";
export const FETCH_CUSTOM_CONFIG_FAILURE = "FETCH_CUSTOM_CONFIG_FAILURE";


export const UPDATE_CUSTOM_CONFIG_BEGIN = "UPDATE_CUSTOM_CONFIG_BEGIN";
export const UPDATE_CUSTOM_CONFIG_SUCCESS = "UPDATE_CUSTOM_CONFIG_SUCCESS";
export const UPDATE_CUSTOM_CONFIG_FAILURE = "UPDATE_CUSTOM_CONFIG_FAILURE";

export const RESET_UPDATED_DATA = 'RESET_UPDATED_DATA';
