import React, { Component } from 'react';
import { Map, Marker, TileLayer, Polyline } from 'react-leaflet';
import L from 'leaflet';
//import RoutingMachine from './RoutingMachine';
import Polylines from './Polylines';
import FuturePolylines from './FuturePolylines';
import CirclePins from './CirclePins';
import CircleMarkerpins from './CircleMarkerpins';
import img from '../../assets/img/truck_0.svg';
import "leaflet-polylinedecorator";
import 'leaflet/dist/leaflet.css';

import './map-widget.css';
   const pinkicon = L.icon({
      iconUrl:
        "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
      iconSize: [40, 40],
      iconAnchor: [20, 40],
    });

const markerStyle = {
    color: '#091c9b',
    fillColor: "#091c9b",
    opacity: 1,
    radius: 8,
  };

  const markerStyle2 = {
    color: '#3b5a98',
    opacity: 1,
    radius: 8,
    fill: "#fff",
    fillRule: "a"
  };

//   const redMarker = L.divIcon({
//     iconUrl: img,
//     iconSize: [40, 40],
//     iconAnchor: [20, 40],
//   });

  const redMarker = L.divIcon({
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    className: `u-turn-icon`,
    html: `<img src=${img} />`
  });


export default class MapRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMapInit: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.routing !== nextProps.routing;
    }

    getBounds = () => {
        const { routing } = this.props;
        let jsonData = [];
        if(routing){
            routing.thcRouteMappingBOS.map((x,key) => {
                let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
                let coordinates = x[setConsignMent]['address']['latitude'] ? [x[setConsignMent]['address']['latitude'], x[setConsignMent]['address']['longitude']] : null;
                if(coordinates !== null){
                    jsonData.push(coordinates);
                }
            })
        }
        return jsonData;
    }

    markerBounds = () => {
        const { routing } = this.props;
        let setDummy = 64;
        if(routing){
            return routing.thcRouteMappingBOS.map((x, key) => {
            let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
            let coordinates = x[setConsignMent]['address']['latitude'] ? [Number(x[setConsignMent]['address']['latitude']), Number(x[setConsignMent]['address']['longitude'])] : null;
            if(coordinates !== null){
                if(key === (routing.thcRouteMappingBOS.length - 1)) {
                    return (
                        <>
                            <Marker key={key} position={coordinates} interactive={true} icon={pinkicon} keyboard={true}></Marker>
                            {routing.vehicleTrackerBO && <Marker key="location" position={[Number(routing.vehicleTrackerBO.latitude), Number(routing.vehicleTrackerBO.longitude)]} interactive={true} icon={redMarker} rotationAngle={routing.vehicleTrackerBO.bearing - 82} keyboard={true}></Marker>}
                        </>
                    )
                }
                else if(key === 0) return <CirclePins key={key} center={coordinates} {...markerStyle2}/>
                else {
                    setDummy = setDummy + 1;
                    return <CircleMarkerpins key={key} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true}></CircleMarkerpins>
                    // return <CirclePins key={key} center={coordinates} number="4" { ...markerStyle}></CirclePins>
                }
            }
            })
        }
    }

    saveMap = (map: any) => {
        this.map = map;
        this.setState({
            isMapInit: true
        })
    }

     urlDecode = (encoded) => {
        var points=[ ]
        var index = 0, len = encoded.length;
        var lat = 0, lng = 0;
        while (index < len) {
            var b, shift = 0, result = 0;
            do {
    
        b = encoded.charAt(index++).charCodeAt(0) - 63;                                                                                
                  result |= (b & 0x1f) << shift;
                  shift += 5;
                 } while (b >= 0x20);
    
    
           var dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
           lat += dlat;
          shift = 0;
          result = 0;
         do {
            b = encoded.charAt(index++).charCodeAt(0) - 63;
            result |= (b & 0x1f) << shift;
           shift += 5;
             } while (b >= 0x20);
         var dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
         lng += dlng;
     
       points.push([( lat / 1E5),( lng / 1E5)])  
     
      }
      return points
    }


  render() {
      const { routing } = this.props;
      let position = [];
      let lastPosition = [];
      let markerlet = []; 
      let markerlist;
      if(routing){
        position = [routing.thcRouteMappingBOS[0].locationBO.address.latitude, routing.thcRouteMappingBOS[0].locationBO.address.longitude];
        lastPosition = [routing.thcRouteMappingBOS[routing.thcRouteMappingBOS.length - 1].locationBO.address.latitude, routing.thcRouteMappingBOS[routing.thcRouteMappingBOS.length - 1].locationBO.address.longitude];
        markerlet = this.getBounds();
        markerlist = this.markerBounds();
      }

    return(
          <div className="directions">
           {routing &&  position && lastPosition && markerlet &&
           <div className="boxapprox">
            <Map
                ref={this.saveMap}
                id='map'
                animate={true}
                onZoomEnd={this.updateZoom}
                onClick={this.updatePosition}
                bounds={markerlet}
                boundsOptions={{padding: [50, 50]}}>
                <TileLayer
                    url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
                />
                {/* {this.state.isMapInit && <RoutingMachine map={this.map} road={markerlet} />} */}
                {this.state.isMapInit && routing && routing.vehicleTrackerBO.polyLine && <Polylines map={this.map}  road={this.urlDecode(routing.vehicleTrackerBO.polyLine)} />}
                {this.state.isMapInit && routing && routing.vehicleTrackerBO.futurePolyline && <FuturePolylines map={this.map} road={this.urlDecode(routing.vehicleTrackerBO.futurePolyline)}/>}
                {markerlist}
                </Map>
              </div>}
          </div>
    )
  }
}