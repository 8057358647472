export const initialState = {
  loading: false,
};

export const GET_CITY_LIST_BEGIN = "GET_CITY_LIST_BEGIN";
export const GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS";
export const GET_CITY_LIST_FAILURE = "GET_CITY_LIST_FAILURE";

export const GET_CITY_LIST_ID_BEGIN = "GET_CITY_LIST_ID_BEGIN";
export const GET_CITY_LIST_ID_SUCCESS = "GET_CITY_LIST_ID_SUCCESS";
export const GET_CITY_LIST_ID_FAILURE = "GET_CITY_LIST_ID_FAILURE";

export const GET_USERDATA_BEGIN = "GET_USERDATA_BEGIN";
export const GET_USERDATA_SUCCESS = "GET_USERDATA_SUCCESS";
export const GET_USERDATA_FAILURE = "GET_USERDATA_FAILURE";

export const GET_ADD_PERMISSION_BEGIN = "GET_ADD_PERMISSION_BEGIN";
export const GET_ADD_PERMISSION_SUCCESS = "GET_ADD_PERMISSION_SUCCESS";
export const GET_ADD_PERMISSION_FAILURE = "GET_ADD_PERMISSION_FAILURE";

export const ZONE_OPTIONS_FETCH_BEGIN = "ZONE_OPTIONS_FETCH_BEGIN";
export const ZONE_OPTIONS_FETCH_SUCCESS = "ZONE_OPTIONS_FETCH_SUCCESS";
export const ZONE_OPTIONS_FETCH_FAILURE = "ZONE_OPTIONS_FETCH_FAILURE";

export const HYPERLOCAL_ZONE_OPTIONS_FETCH_BEGIN =
  "HYPERLOCAL_ZONE_OPTIONS_FETCH_BEGIN";
export const HYPERLOCAL_ZONE_OPTIONS_FETCH_SUCCESS =
  "HYPERLOCAL_ZONE_OPTIONS_FETCH_SUCCESS";
export const HYPERLOCAL_ZONE_OPTIONS_FETCH_FAILURE =
  "HYPERLOCAL_ZONE_OPTIONS_FETCH_FAILURE";

export const HYPERLOCAL_ELIGIBLE_ZONE_BEGIN = "HYPERLOCAL_ELIGIBLE_ZONE_BEGIN";
export const HYPERLOCAL_ELIGIBLE_ZONE_SUCCESS =
  "HYPERLOCAL_ELIGIBLE_ZONE_SUCCESS";
export const HYPERLOCAL_ELIGIBLE_ZONE_FAILURE =
  "HYPERLOCAL_ELIGIBLE_ZONE_FAILURE";

export const HYPERLOCAL_BOX_ZONE_BEGIN = "HYPERLOCAL_BOX_ZONE_BEGIN";
export const HYPERLOCAL_BOX_ZONE_SUCCESS = "HYPERLOCAL_BOX_ZONE_SUCCESS";
export const HYPERLOCAL_BOX_ZONE_FAILURE = "HYPERLOCAL_BOX_ZONE_FAILURE";

export const HYPERLOCAL_BOX_CLIENT_BEGIN = "HYPERLOCAL_BOX_CLIENT_BEGIN";
export const HYPERLOCAL_BOX_CLIENT_SUCCESS = "HYPERLOCAL_BOX_CLIENT_SUCCESS";
export const HYPERLOCAL_BOX_CLIENT_FAILURE = "HYPERLOCAL_BOX_CLIENT_FAILURE";

export const HYPERLOCAL_GEOFENCE_BEGIN = "HYPERLOCAL_GEOFENCE_BEGIN";
export const HYPERLOCAL_GEOFENCE_SUCCESS = "HYPERLOCAL_GEOFENCE_SUCCESS";
export const HYPERLOCAL_GEOFENCE_FAILURE = "HYPERLOCAL_GEOFENCE_FAILURE";

export const HYPERLOCAL_GETALL_FE_BEGIN = "HYPERLOCAL_GETALL_FE_BEGIN";
export const HYPERLOCAL_GETALL_FE_SUCCESS = "HYPERLOCAL_GETALL_FE_SUCCESS";
export const HYPERLOCAL_GETALL_FE_FAILURE = "HYPERLOCAL_GETALL_FE_FAILURE";

export const HYPERLOCAL_GETORDER_BEGIN = "HYPERLOCAL_GETORDER_BEGIN";
export const HYPERLOCAL_GETORDER_SUCCESS = "HYPERLOCAL_GETORDER_SUCCESS";
export const HYPERLOCAL_GETORDER_FAILURE = "HYPERLOCAL_GETORDER_FAILURE";

export const HYPERLOCAL_GETALL_COUNT_BEGIN = "HYPERLOCAL_GETALL_COUNT_BEGIN";
export const HYPERLOCAL_GETALL_COUNT_SUCCESS =
  "HYPERLOCAL_GETALL_COUNT_SUCCESS";
export const HYPERLOCAL_GETALL_COUNT_FAILURE =
  "HYPERLOCAL_GETALL_COUNT_FAILURE";

export const HYPERLOCAL_RIDER_DETAILS_BEGIN = "HYPERLOCAL_RIDER_DETAILS_BEGIN";
export const HYPERLOCAL_RIDER_DETAILS_SUCCESS =
  "HYPERLOCAL_RIDER_DETAILS_SUCCESS";
export const HYPERLOCAL_RIDER_DETAILS_FAILURE =
  "HYPERLOCAL_RIDER_DETAILS_FAILURE";

export const GET_FETCH_TEMPLATE_NOTIFY_BEGIN =
  "GET_FETCH_TEMPLATE_NOTIFY_BEGIN";
export const GET_FETCH_TEMPLATE_NOTIFY_SUCCESS =
  "GET_FETCH_TEMPLATE_NOTIFY_SUCCESS";
export const GET_FETCH_TEMPLATE_NOTIFY_FAILURE =
  "GET_FETCH_TEMPLATE_NOTIFY_FAILURE";

export const GET_CUSTOMER_LIST_BEGIN = "GET_CUSTOMER_LIST_BEGIN";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";

export const GET_STAFFDATA_BEGIN = "GET_STAFFDATA_BEGIN";
export const GET_STAFFDATA_SUCCESS = "GET_STAFFDATA_SUCCESS";
export const GET_STAFFDATA_FAILURE = "GET_STAFFDATA_FAILURE";

export const RESET_STAFFDATA = "RESET_STAFFDATA";
