export const GET_PARTNER_USERS_LIST_BEGIN = 'GET_PARTNER_USERS_LIST_BEGIN';
export const GET_PARTNER_USERS_LIST_SUCCESS = 'GET_PARTNER_USERS_LIST_SUCCESS';
export const GET_PARTNER_USERS_LIST_FAILURE = 'GET_PARTNER_USERS_LIST_FAILURE';


export const GET_PARTNER_CUSTOMER_USERS_LIST_BEGIN = 'GET_PARTNER_CUSTOMER_USERS_LIST_BEGIN';
export const GET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS = 'GET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS';
export const GET_PARTNER_CUSTOMER_USERS_LIST_FAILURE = 'GET_PARTNER_CUSTOMER_USERS_LIST_FAILURE';



export const GET_PARTNER_CUSTOMER_RESEND_INVITE_BEGIN = 'GET_PARTNER_CUSTOMER_RESEND_INVITE_BEGIN';
export const GET_PARTNER_CUSTOMER_RESEND_INVITE_SUCCESS = 'GET_PARTNER_CUSTOMER_RESEND_INVITE_SUCCESS';
export const GET_PARTNER_CUSTOMER_RESEND_INVITE_FAILURE = 'GET_PARTNER_CUSTOMER_RESEND_INVITE_FAILURE';

export const GET_CUSTOMER_DEACTIVATE_BEGIN = 'GET_CUSTOMER_DEACTIVATE_BEGIN';
export const GET_CUSTOMER_DEACTIVATE_SUCCESS = 'GET_CUSTOMER_DEACTIVATE_SUCCESS';
export const GET_CUSTOMER_DEACTIVATE_FAILURE = 'GET_CUSTOMER_DEACTIVATE_FAILURE';


export const GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_BEGIN = 'GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_BEGIN';
export const GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_SUCCESS = 'GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_SUCCESS';
export const GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_FAILURE = 'GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_FAILURE';


export const SET_PARTNER_CUSTOMER_USERS_LIST_BEGIN = 'SET_PARTNER_CUSTOMER_USERS_LIST_BEGIN';
export const SET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS = 'SET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS';
export const SET_PARTNER_CUSTOMER_USERS_LIST_FAILURE = 'SET_PARTNER_CUSTOMER_USERS_LIST_FAILURE';


export const SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_BEGIN = 'SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_BEGIN';
export const SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_SUCCESS = 'SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_SUCCESS';
export const SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_FAILURE = 'SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_FAILURE';

export const FETCH_PARTNER_CUSTOMER_LOCATIONS_BEGIN = 'FETCH_PARTNER_CUSTOMER_LOCATIONS_BEGIN';
export const FETCH_PARTNER_CUSTOMER_LOCATIONS_SUCCESS = 'FETCH_PARTNER_CUSTOMER_LOCATIONS_SUCCESS';
export const FETCH_PARTNER_CUSTOMER_LOCATIONS_FAILURE = 'FETCH_PARTNER_CUSTOMER_LOCATIONS_FAILURE';


export const GET_EXPORT_CUSTOMERS_BEGIN = 'GET_EXPORT_CUSTOMERS_BEGIN';
export const GET_EXPORT_CUSTOMERS_SUCCESS = 'GET_EXPORT_CUSTOMERS_SUCCESS';
export const GET_EXPORT_CUSTOMERS_FAILURE = 'GET_EXPORT_CUSTOMERS_FAILURE';


export const RESET_CUSTOMER_USERS_FIELDS = 'RESET_CUSTOMER_USERS_FIELDS';
export const RESET_CUSTOMER_USERS_EDIT_FIELDS = 'RESET_CUSTOMER_USERS_EDIT_FIELDS';
export const RESET_CUSTOMER_LOCATIONS_FIELDS = 'RESET_CUSTOMER_LOCATIONS_FIELDS';