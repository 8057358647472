import React from 'react';
import './Typography.scss';

export const typography = (props) => {
    const styles = {
        fontSize: "14px",
        fontWeight: "normal",
        fontFamily: "'Lato', sans-serif",
        ...props.styles,
    }
    const classes = `${props.className} Typography`;
    if (props.condition === false || !props.children) {
        return <p className={classes} style={styles} >NA</p>;
    }
    return (
        <p className={classes} style={styles} onClick={props.onClick}>{props.children}</p>
    )
}