import React, { PureComponent } from 'react';
import * as actions from '../actions';
import '../dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Select, Icon, Modal, Radio, Tooltip, Tag, Menu, Dropdown, Switch, Form } from 'antd';
import { table as Table } from "../../../ui/table/Table";
import { canAllow, notifyApiError, windowRedirect, notifyApiSuccess } from '../../../../common/utils/utils';
import { permissionTags } from '../../../../configs/permissionTags';
import moment from 'moment';
import { Constants, validationRules } from '../../../../common/utils/constants';
import * as apiUtils from '../../../../common/utils/apiGateway';
import shipperAction from "../../../../assets/img/icons/shipper_action.png";
import "./shipper.scss";
import { openNotification } from '../../../ui/notification/Notification';
import _ from 'lodash';
import { Mixpanel } from '../../../../utils/mixpanel';


const { confirm } = Modal;

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}


class ShipperDashboard extends PureComponent {
    constructor(props) {
        super(props);
        this.copyArea = React.createRef();
        this.state = {
            pageNo: 1,
            customerPermission: {
                view: canAllow(permissionTags.settings.customer.shippers),
                create: canAllow(permissionTags.settings.customer.create_shippers),
                update: canAllow(permissionTags.settings.customer.update_shippers),
                status: canAllow(permissionTags.settings.customer.status_shippers),
                rate: canAllow(permissionTags.settings.customer.ratecard_shippers),
            },
            filterPayload: {
                entityType: "CUSTOMER"
            },
            shipperModalVisible: false,
            loading: false,
            isEdit: false,
            editId: '',
            searchedValue: '',
            customerName: ''
        }
        this.InputSearchRef = React.createRef();
        if (!this.state.customerPermission.view) {
            windowRedirect('settings/dashboard/');
        }
    }




    componentDidMount() {
        const id = this.props.location?.state?.id;
        this.setState({ customerName: this.props.location?.state?.name })
        
        const menu = (data, row) => (
            <Menu>

                <Menu.Item key="0" style={{ textAlign: 'right' }}>
                    {this.state.customerPermission.status ?
                        <><span >Status: </span><Switch checked={row.status === "ACTIVE"} onChange={(checked) => this.changeActive(checked, row)} /></>

                        : <><span >Status: </span><Switch checked={row.status === "ACTIVE"} disabled /></>
                    }
                </Menu.Item>


                <Menu.Divider />

                <Menu.Item key="1">
                    {row.status === "ACTIVE" && this.state.customerPermission?.rate ?
                        <><span>Rate Card Validation: </span><Switch checked={row?.isMandatory} onChange={(checked) => this.changeMandatory(checked, row, data)} /></>
                        : <><span>Rate Card Validation: </span><Switch disabled checked={row?.isMandatory} /></>
                    }
                </Menu.Item>

            </Menu>
        );

        this.setState(state => ({
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'shipperName',
                    key: 'shipperName',
                    sorter: (a, b) => {
                        if (a.shipperName < b.shipperName) { return -1; }
                        if (a.shipperName > b.shipperName) { return 1; }
                        return 0;
                    }
                },
                {
                    title: 'Code',
                    dataIndex: 'contactShortCode',
                    key: 'contactShortCode',
                },
                {
                    title: 'Contact Info',
                    dataIndex: 'contactInfo',
                    key: 'contactInfo',
                    render: (data, row) => (<div>{row.contactPersonName}<br />{row.contactNumber}</div>)
                },
                {
                    title: 'Rate Card Validation',
                    dataIndex: 'isMandatory',
                    key: 'isMandatory',
                    render: data => data ? <Tag color="#87d068">Required</Tag> : <Tag color="#f50">Not Required</Tag>,
                    sorter: (a, b) => a.isMandatory - b.isMandatory,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: data => data === "ACTIVE" ? <Tag color="#87d068">Active</Tag> : <Tag color="#f50">Inactive</Tag>,
                    // sorter: (a, b) => a.isActive - b.isActive,
                },
                {
                    title: 'Action',
                    dataIndex: '',
                    key: 'action',
                    render: (data, row) => (<Row>
                        {
                            state.customerPermission.update && <Col span={7}><Tooltip placement="top" title={'Edit'}>
                                {row?.status==="ACTIVE" ?
                                    <Icon type="edit" onClick={() => { Mixpanel.track("Shipper Edit Clicked.", { 'ERPCustomerEditClick': moment().valueOf() }); this.editSHipper(data, row) }} />
                                    : <Icon type="edit" style={{ cursor: "not-allowed", opacity: 0.5 }} />
                                }
                            </Tooltip>
                            </Col>
                        }
                        {/* <Col span={12}>
                            <Tooltip title={row?.isActive ? "Active" : "Inactive"}>
                                <Switch checked={row?.isActive} onChange={(checked) => this.changeActive(checked, row)} />
                            </Tooltip>
                        </Col> */}
                        <Col span={12}>
                            <Dropdown overlay={menu(data, row)} trigger={['click']}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <Tooltip title={"Actions"}>
                                        <img src={shipperAction} width={18} />
                                        {/* <Icon className="more" type="more" style={{ fontSize: 18 }} /> */}
                                    </Tooltip>
                                </a>
                            </Dropdown>
                        </Col>
                    </Row >)
                },
            ]
        }));

        this.getPaginatedData(id)
    }

    getPaginatedData = (id) => {
        const filters = {
            "filter": {
                "entityIds": [id]
            }


        }

        this.props.fetchShippers(filters)

    }

    componentDidUpdate(prevProps) {
        const { PaginatedShippers, shipperData } = this.props;

        if (PaginatedShippers !== prevProps.PaginatedShippers) {
            this.setState({
                shipperList: PaginatedShippers
            })
        }

    }
    //   applyPagination = (filters, isReset = false) => {
    //     if (isReset) {
    //       this.setState({
    //         pageNo: 1
    //       })
    //     }   
    //     this.props.fetchShippers({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
    //   }


    reloadCustomerList = () => {
        // Mixpanel.track("Customer Reload Clicked.", { 'ERPCustomerReload': moment().valueOf() });
        // this.setState({
        //     pageNo: 1
        // })
        // this.InputSearchRef.current.input.state.value = '';
        this.setState({ searchedValue: '' })
        this.getPaginatedData(this.props.location?.state?.id);
    }

    //   getPaginatedData = (pageNo, paginatedData) => {
    //     const { PaginatedShippers, } = this.props;
    //     if (!PaginatedShippers ? !PaginatedShippers : !PaginatedShippers[pageNo]) {
    //       this.applyPagination({ pageNo })
    //     }
    //     this.setState({
    //       pageNo
    //     });
    //   }

    editSHipper = (data, row) => {
        const { form } = this.props;
        form.setFieldsValue({
            shipperName: data?.shipperName,
            contactShortCode: data?.contactShortCode,
            isMandatory: data?.isMandatory,
            contactPersonName: data?.contactPersonName,
            contactNumber: data?.contactNumber
        }, () => {
            this.setState({ isEdit: true, editId: data?.id });
            this.openShipperModal();
        })
    }


    openShipperModal = () => {
        this.setState({ shipperModalVisible: true });
    }


    handleCancel = () => {
        this.setState({ shipperModalVisible: false, editId: '', isEdit: false });
        this.props.form.resetFields();
    }

    handleCreateOk = () => {
        const { form } = this.props;
        const { shipperList } = this.state;
        form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            this.setState({ loading: true });
            const { shipperName, contactShortCode, contactPersonName, contactNumber, isMandatory } = values;
            const { entityId, partnerId, locationId } = shipperList[0];

            const params = {
                shipperName,
                contactShortCode,
                contactPersonName,
                contactNumber,
                "entityType": "CUSTOMER",
                entityId: this.props.location?.state?.id,
                partnerId: Constants.PARTNER_ID,
                locationId: Constants.LOGGED_PARTNER_LOCATION_ID,
                "isMandatory": isMandatory ? 1 : 0,
            }

            apiUtils.apiRequest({
                url: `/b2b/v1/customer/shipper-mapping/update`,
                data: params,
                method: 'POST'

            })
                .then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        this.setState({ loading: false }, () => {
                            notifyApiSuccess("Created Shipper Successfully!", "SUCCESS");
                            form.resetFields();
                            this.handleCancel();
                            this.reloadCustomerList();
                        });
                    } else {
                        this.setState({ loading: false });
                        form.resetFields();
                        this.handleCancel();
                    }

                })
                .catch(error => {
                    this.setState({ loading: false });
                    notifyApiError(error, "ERROR");
                    form.resetFields();
                    this.handleCancel();
                });

        })
    }

    handleUpdateOk = () => {
        const { form } = this.props;
        const { shipperList } = this.state;
        form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            this.setState({ loading: true });
            const { shipperName, contactShortCode, contactPersonName, contactNumber, isMandatory } = values;
            const { entityId, partnerId, locationId } = shipperList[0];

            const params = {
                id: this.state?.editId,
                shipperName,
                contactShortCode,
                contactPersonName,
                contactNumber,
                "entityType": "CUSTOMER",
                entityId: this.props.location?.state?.id,
                partnerId: Constants.PARTNER_ID,
                locationId: Constants.LOGGED_PARTNER_LOCATION_ID,
                "isMandatory": isMandatory ? 1 : 0,
                isActive: 1,
                "status": "ACTIVE"
            }

            apiUtils.apiRequest({
                url: `/b2b/v1/customer/shipper-mapping/update`,
                data: params,
                method: 'POST'

            })
                .then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        this.setState({ loading: false }, () => {
                            notifyApiSuccess("Updtaed Shipper Successfully!", "SUCCESS");
                            form.resetFields();
                            this.handleCancel();
                            this.reloadCustomerList();
                        });
                    } else {
                        this.setState({ loading: false });
                        form.resetFields();
                        this.handleCancel();
                    }

                })
                .catch(error => {
                    this.setState({ loading: false });
                    notifyApiError(error, "ERROR");
                    form.resetFields();
                    this.handleCancel();
                });

        })
    }

    changeActive = (checked, row) => {
       
        let isMandatory = checked ? 1 : 0;
        this.setState({ loading: true }, () => {
            apiUtils.apiRequest({
                url: `/b2b/v1/customer/shipper-mapping/updateStatus?id=${row.id}&isActive=${isMandatory}`,
                method: 'PUT'

            })
                .then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        this.setState({ loading: false }, () => {
                            notifyApiSuccess("Mandatory Updated", "SUCCESS");
                            this.reloadCustomerList();
                        });
                    } else {
                        this.setState({ loading: false });
                        this.handleCancel();
                    }

                })
                .catch(error => {
                    this.setState({ loading: false });
                    notifyApiError(error, "ERROR");
                    this.handleCancel();
                });
        })

    }

    changeMandatory = (checked, row, data) => {

        let isMandatory = checked ? 1 : 0;
        const { id, shipperName, contactShortCode, contactPersonName, contactNumber, entityType, entityId, partnerId, locationId, isActive, status } = row;
        const params = {
            id,
            shipperName,
            contactShortCode,
            contactPersonName,
            contactNumber,
            entityType,
            entityId: this.props.location?.state?.id,
            partnerId: Constants.PARTNER_ID,
            locationId: Constants.LOGGED_PARTNER_LOCATION_ID,
            "isMandatory": isMandatory,
            isActive: isActive ? 1 : 0,
            status
        }
        this.setState({ loading: true }, () => {
            apiUtils.apiRequest({
                url: `/b2b/v1/customer/shipper-mapping/update`,
                data: params,
                method: 'POST'

            })
                .then(response => {
                    if (checkHttpStatus(response.data?.status)) {
                        this.setState({ loading: false }, () => {
                            notifyApiSuccess("Mandaotry Updated Successfully!", "SUCCESS");

                            this.reloadCustomerList();
                        });
                    } else {
                        this.setState({ loading: false });

                    }

                })
                .catch(error => {
                    this.setState({ loading: false });
                    notifyApiError(error, "ERROR");
                });
        });


    }

    onChangeSearch = e => {
        let value = e.target.value;
        
        this.setState({ searchedValue: value })
        

    }

    searchData = searchedValue => {
        let value = searchedValue.trim();
        if (value) {
            this.setState({ searchedValue: value });
            let { shipperList } = this.state;
            shipperList = shipperList?.filter(shipper => shipper?.shipperName.toLowerCase().includes(value.toLowerCase()));
            this.setState({ shipperList })
        } else {
            this.setState({ searchedValue })
            this.setState({
                shipperList: this.props?.PaginatedShippers
            })
        }
    }


    render() {
        const { columns, customerPermission, pageNo, shipperList, loading } = this.state;
        const { PaginatedShippers, shipperData } = this.props;
        const { getFieldDecorator, getFieldsValue } = this.props.form;



        const formItemLayout = {
            formLayout: 'vertical',
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
        };

        const actionBtns = (
            <Row>
                <Col span={12}>
                    <Input.Search size={'large'} onChange={this.onChangeSearch} value={this.state.searchedValue} placeholder="Search by shipper" allowClear onSearch={this.searchData} enterButton />
                </Col>
                <Col span={3}>
                    <Tooltip placement="top" title={'Reload Customer List'}>
                        <Button block size={'large'} onClick={this.reloadCustomerList} type="primary" style={{ marginLeft: 2 }}>
                            <Icon type="reload" />
                        </Button>
                    </Tooltip>
                </Col>
                {
                    <Col span={9}>
                        <Button size={'large'} disabled={!customerPermission.create} onClick={this.openShipperModal} type="primary" style={{ marginLeft: 12 }}>CREATE SHIPPER</Button>
                    </Col>
                }
            </Row>
        );
        return (
            <>
                <Card className="CustomerDashBoard" title={<h2><Icon type="arrow-left" onClick={() => this.props.history.goBack()} /> Shipper List - {this.state.customerName}</h2>} extra={actionBtns} >
                    <Table
                        bordered
                        locale={{
                            emptyText: (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                        }}
                        loading={shipperData?.loading || loading}
                        dataSource={shipperList}
                        columns={columns} />
                </Card>

                <Modal
                    title={this.state.isEdit ? 'Edit Shipper' : 'Add Shipper'}
                    visible={this.state.shipperModalVisible}
                    style={{ top: 25 }}
                    closable={false}
                    footer={[
                        <Button key="back" disabled={loading} onClick={this.handleCancel}>
                            Cancel
                        </Button>,
                        <>
                            {this.state.isEdit ?
                                <Button key="submit" loading={loading} type="primary" onClick={this.handleUpdateOk}>
                                    Update
                                </Button>
                                :
                                <Button key="submit" loading={loading} type="primary" onClick={this.handleCreateOk}>
                                    Add
                                </Button>
                            }

                        </>
                    ]}
                >

                    <Form {...formItemLayout}>
                        <Row>
                            <Col span={24} className="user-invite-form">
                                {/* <Row gutter={[0, 0]}> */}
                                {/* <Col span={24}> */}
                                <Form.Item colon={false} label={<span className="form-label-text">Shipper Name</span>}>
                                    {getFieldDecorator('shipperName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input name!',
                                            },
                                            {
                                                pattern: validationRules.name,
                                                message: "Please enter valid name"
                                            }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter name"
                                        // disabled={this.state.editMode}
                                        />
                                    )}
                                </Form.Item>


                                <Form.Item colon={false} label={<span className="form-label-text">Contact Short Code</span>}>
                                    {getFieldDecorator('contactShortCode', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input short code!',
                                            }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Enter short code"
                                        // disabled={this.state.editMode}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item colon={false} label={<span className="form-label-text">Contact Person Name</span>}>
                                    {getFieldDecorator('contactPersonName', {
                                        // initialValue: contactPersonName,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input contact person name!',
                                            }
                                        ],
                                    })(
                                        <Input
                                            // disabled
                                            placeholder="Enter contact person name"
                                        // disabled={this.state.editMode}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item colon={false} label={<span className="form-label-text">Contact Person Number</span>}>
                                    {getFieldDecorator('contactNumber', {
                                        // initialValue: contactNumber,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input contact person number!',
                                            }
                                        ],
                                    })(
                                        <Input
                                            // disabled
                                            placeholder="Enter contact person number"
                                        // disabled={this.state.editMode}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item colon={false} label={<span className="form-label-text">Rate Card Validation Required</span>}>
                                    {getFieldDecorator('isMandatory', {
                                        initialValue: true,
                                        valuePropName: "checked",
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Please input short code!',
                                            }
                                        ],
                                    })(
                                        <Switch
                                            disabled={!this.state.customerPermission?.rate}
                                         />
                                    )}
                                </Form.Item>


                            </Col>
                        </Row>


                    </Form>

                </Modal>

            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    shipperData: state.settingsCustomers,
    PaginatedShippers: state.settingsCustomers?.PaginatedShippers,
});
const mapDispatchToProps = dispatch => ({
    fetchShippers: (param, isReset = false) => dispatch(actions.fetchShippers(param, isReset)),
});
// export default connect(mapStateToProps, mapDispatchToProps)(ShipperDashboard);
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Shippers', onValuesChange: (props, val) => { } })(ShipperDashboard));
