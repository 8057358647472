import { pModules, permissionTags, paTags } from "./permissionTags";
import * as utils from "../common/utils/utils";
import {
  Constants,
  checkConfigurationsForPermission,
} from "../common/utils/constants";

let pincodePermissionCheckInConfiguration = (congiValue) => {
  const data = utils.checkConfigurationsForPermission(congiValue);

  if (
    data &&
    data.length &&
    data.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  ) {
    return true;
  }

  return false;
};

let configPermissionCheckInConfiguration = (congiValue) => {
  const data = utils.checkConfigurationsForPermission(congiValue);

  if (
    data &&
    data.length &&
    data.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  ) {
    return true;
  }

  return false;
};

// path : path of menu/tab to be appended in url
// label: string to be displayed on UI for a menu/tab
// name : key for a menu/tab present in permissions object
export const menuconfig = [
  {
    key: utils.generateID(),
    path: "tracking",
    label: "Tracking",
    name: pModules.tracking.title,
    inactiveIcon: "4_b.svg",
    activeIcon: "4_w.svg",
    canAllow: utils.canAllow(permissionTags.tracking.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Tracking",
        path: "tracking",
        name: pModules.tracking.subModule.tracking,
        canAllow: utils.canAllow(permissionTags.tracking.tracking.subModule),
        isReact: true,
      },
    ],
  },

  {
    key: utils.generateID(),
    path: "settings",
    label: "Settings",
    name: pModules.settings.title,
    inactiveIcon: "7_b.svg",
    activeIcon: "7_w.svg",
    canAllow: utils.canAllow(permissionTags.settings.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "CUSTOMER",
        path: "customer",
        name: pModules.settings.subModule.customer,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.customer.subModule),
      },
      {
        label: "VENDOR",
        path: "vendor",
        name: pModules.settings.subModule.vendor,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.vendor.subModule),
      },
      {
        label: "USER",
        path: "user",
        name: pModules.settings.subModule.user,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.user.subModule),
      },
      {
        label: "ASSET",
        path: "asset",
        isReact: true,
        name: pModules.settings.subModule.asset,
        canAllow:
          localStorage.getItem("currentPartnerId") !== "5"
            ? utils.canAllow(permissionTags.settings.asset.subModule)
            : false,
      },
      {
        label: "ROUTE",
        path: "route",
        isReact: true,
        name: pModules.settings.subModule.route,
        canAllow: utils.canAllow(permissionTags.settings.route.subModule),
      },
      {
        label: "LOCATION",
        path: "location",
        isReact: true,
        name: pModules.settings.subModule.location,
        canAllow: utils.canAllow(permissionTags.settings.location.subModule),
      },
      {
        label: "ZONE",
        path: "zone",
        name: pModules.settings.subModule.zone,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.zone.subModule),
      },
      {
        label: "CUSTOMER SLAs",
        path: "sla-rules",
        name: pModules.settings.subModule.slaRules,
        isReact: true,
        canAllow: utils.canAllow(permissionTags.settings.slaRules.subModule),
      },
      {
        label: "PINCODE",
        path: "pincode",
        name: pModules.settings.subModule.pincode,
        canAllow: pincodePermissionCheckInConfiguration(
          "pincodeEditPermission"
        ),
        isReact: true,
      },
      {
        label: "PARTNERS ONBOARDING",
        path: "partners-onboarding",
        name: pModules.settings.subModule.partners_onboarding,
        canAllow: utils.canAllow(
          permissionTags.settings.partners_onboarding.subModule,
          true
        ),
        isReact: true,
      },
      {
        label: "ZOHO",
        path: "zoho-dashboard",
        name: pModules.settings.subModule.zoho,
        canAllow: utils.canAllow(permissionTags.settings.zoho.subModule),
        isReact: true,
      },
      {
        label: "CUSTOM CONFIG",
        path: "custom-config",
        name: pModules.settings.subModule.custom_config,
        isReact: true,
        canAllow: utils.canAllow(
          permissionTags.settings.custom_config.subModule,
          true
        ),
        // canAllow: configPermissionCheckInConfiguration("authorizedUserIdsToUpdateApplicationConfig")
      },
      {
        label: "RATE CARD CONFIG",
        path: "ratecard-config",
        name: pModules.settings.subModule.ratecard_config,
        isReact: true,
        canAllow: utils.canAllow(
          permissionTags.settings.ratecard_config.subModule
        ),
        // canAllow: configPermissionCheckInConfiguration("authorizedUserIdsToUpdateApplicationConfig")
      },
      {
        label: "CUSTOMER ONBOARDING",
        path: "client-onboarding",
        name: pModules.settings.subModule.client_onboarding,
        isReact: true,
        canAllow: utils.canAllow(
          permissionTags.settings.client_onboarding.subModule
        ),
      },
      {
        label: "ZONE CREATION",
        path: "zone-creation",
        name: pModules.settings.subModule.zone_creation,
        isReact: true,
        canAllow: utils.canAllow(
          permissionTags.settings.zone_creation.subModule
        ),
      },
    ],
  },

  {
    key: utils.generateID(),
    path: "manage_fleet",
    label: "Manage Fleet",
    name: pModules.manage_fleet.title,
    inactiveIcon: "4_b.svg",
    activeIcon: "4_w.svg",
    canAllow: utils.canAllow(permissionTags.manage_fleet.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Live Tracking",
        path: "live-tracking",
        name: pModules.manage_fleet.subModule.map,
        canAllow: utils.canAllow(permissionTags.manage_fleet.map.subModule),
        isReact: true,
      },
      {
        label: "KYC",
        path: "kyc",
        name: pModules.tracking.subModule.kyc,
        canAllow: utils.canAllow(permissionTags.manage_fleet.kyc.subModule),
        isReact: true,
      },
    ],
  },
  {
    key: utils.generateID(),
    path: "hyperlocal",
    label: "Hyperlocal",
    name: pModules.hyperlocal.title,
    inactiveIcon: "2_b.svg",
    activeIcon: "2_w.svg",
    // canAllow: true,
    canAllow: utils.canAllow(permissionTags.hyperlocal.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Manage FE",
        path: "fleet",
        name: pModules.hyperlocal.subModule.fleet,
        // canAllow: true,
        canAllow: utils.canAllow(permissionTags.hyperlocal.fleet.subModule),
        isReact: true,
      },
      {
        label: "Staff Status",
        path: "staff-status",
        name: pModules.hyperlocal.subModule.fe_status,
        // canAllow: true,
        canAllow: utils.canAllow(permissionTags.hyperlocal.fe_status.subModule),
        isReact: true,
      },
      {
        label: "Zones",
        path: "zones",
        name: pModules.hyperlocal.subModule.zones,
        canAllow: utils.canAllow(permissionTags.hyperlocal.zones.subModule),
        isReact: true,
      },
      {
        label: "Map View",
        path: "map-view",
        name: pModules.hyperlocal.subModule.mapview,
        canAllow: utils.canAllow(permissionTags.hyperlocal.mapview.subModule),
        isReact: true,
      },
      {
        label: "Order Details",
        path: "order-details",
        name: pModules.hyperlocal.subModule.order_details,
        canAllow: utils.canAllow(
          permissionTags.hyperlocal.order_details.subModule
        ),
        // canAllow: true,
        isReact: true,
      },
    ],
  },

  {
    key: utils.generateID(),
    path: "digital-payment",
    label: "Digital Payment",
    name: pModules.digital_payment.title,
    inactiveIcon: "eway_inactive.svg",
    activeIcon: "eway_active.svg",
    canAllow: utils.canAllow(permissionTags.digital_payment.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Payment Status",
        path: "payment-status",
        name: pModules.digital_payment.title,
        isReact: true,
        canAllow: utils.canAllow(
          permissionTags.digital_payment.payment_status.subModule
        ),
      },
    ],
  },

  {
    key: utils.generateID(),
    path: "config",
    label: "Configs",
    name: pModules.fm_lm_config.title,
    inactiveIcon: "4_b.svg",
    activeIcon: "4_w.svg",
    canAllow: utils.canAllow(permissionTags.fm_lm_config.module),
    type: "link",
    isReact: true,
    children: [
      {
        label: "Zone Level Configs",
        path: "zone",
        name: pModules.fm_lm_config.subModule.fm_lm_zone,
        canAllow: utils.canAllow(
          permissionTags.fm_lm_config.fm_lm_zone.subModule
        ),
        isReact: true,
      },
      {
        label: "City Level Configs",
        path: "city",
        name: pModules.fm_lm_config.subModule.fm_lm_city,
        canAllow: utils.canAllow(
          permissionTags.fm_lm_config.fm_lm_city.subModule
        ),
        isReact: true,
      },
      {
        label: "Pan India Configs",
        path: "country",
        name: pModules.fm_lm_config.subModule.fm_lm_country,
        // canAllow: true,
        canAllow: utils.canAllow(
          permissionTags.fm_lm_config.fm_lm_country.subModule
        ),
        isReact: true,
      },
      {
        label: "Default Configs",
        path: "default",
        name: pModules.fm_lm_config.subModule.fm_lm_default,
        canAllow: utils.canAllow(
          permissionTags.fm_lm_config.fm_lm_default.subModule
        ),
        isReact: true,
      },
    ],
  },
];
