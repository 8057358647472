import * as React from 'react';
import L from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet';
import './map-widget.css';
import img from '../../assets/img/truck_90.svg';

class Pin extends React.Component {
  getTripRoute = (tripId) => {
    this.props.action(tripId);
  }

  render() {
    const { position, title, date, tripId } = this.props;
    const redMarker = L.divIcon({
      className: "lsn-div-icon",
      iconSize: [28, 18],
      iconAnchor: [0, 0],
      popupAnchor: [0, 0],
      html: `<div className="toolBox">
              <div className="setTitlefor">${title.slice(-4)}</div>
              <div className="imageFor">
                <img src='${img}' />
              </div>
            </div>`
    });

    return (
      <>
      <Marker
        className=""
        position={position}
        interactive={true}
        keyboard={true}
        icon={redMarker}
        onClick={() => this.getTripRoute(tripId)}>
      <Tooltip width={150} direction="auto">
        <div className="tooltiplsn">
          <div className="tooltipName"><b>{title.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</b></div>
          <div className="tooltipDate">Last Updated: <span className="lastupdated">{date}</span></div>
        </div>
      </Tooltip>
      </Marker>
      </>
    );
  }
}

export default Pin;