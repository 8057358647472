
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const LocationReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_CUSTOMERS_BEGIN: return fetchCustomersBegin(state);
        case _Constants.FETCH_CUSTOMERS_SUCCESS: return fetchCustomersSuccess(state, action);
        case _Constants.FETCH_CUSTOMERS_FAILURE: return fetchCustomersFailure(state, action);
        case _Constants.FETCH_AREAS_BEGIN: return fetchAreasBegin(state);
        case _Constants.FETCH_AREAS_SUCCESS: return fetchAreasSuccess(state, action);
        case _Constants.FETCH_AREAS_FAILURE: return fetchAreasFailure(state, action);
        case _Constants.FETCH_LOCATIONS_BEGIN: return fetchLocationsBegin(state);
        case _Constants.FETCH_LOCATIONS_SUCCESS: return fetchLocationsSuccess(state, action);
        case _Constants.FETCH_LOCATIONS_FAILURE: return fetchLocationsFailure(state, action);
        case _Constants.FETCH_VENDORS_BEGIN: return fetchVendorBegin(state);
        case _Constants.FETCH_VENDORS_SUCCESS: return fetchVendorSuccess(state, action);
        case _Constants.FETCH_VENDORS_FAILURE: return fetchVendorFailure(state, action);
        case _Constants.FETCH_LOCATIONBYID_BEGIN: return fetchLocationByIdBegin(state);
        case _Constants.FETCH_LOCATIONBYID_SUCCESS: return fetchLocationByIdSuccess(state, action);
        case _Constants.FETCH_LOCATIONBYID_FAILURE: return fetchLocationByIdFailure(state, action);
        case _Constants.LOCATION_MODIFICATION_BEGIN: return locationModificationBegin(state);
        case _Constants.LOCATION_MODIFICATION_SUCCESS: return locationModificationSuccess(state, action);
        case _Constants.LOCATION_MODIFICATION_FAILURE: return locationModificationFailure(state, action);
        case _Constants.PROMISE_TIME_MODIFICATION_BEGIN: return promiseTimeModificationBegin(state);
        case _Constants.PROMISE_TIME_MODIFICATION_SUCCESS: return promiseTimeModificationSuccess(state, action);
        case _Constants.PROMISE_TIME_MODIFICATION_FAILURE: return promiseTimeModificationFailure(state, action);
        case _Constants.GROUP_LOCATION_BEGIN: return groupLocationBegin(state);
        case _Constants.GROUP_LOCATION_SUCCESS: return groupLocationSuccess(state, action);
        case _Constants.GROUP_LOCATION_FAILURE: return groupLocationFailure(state, action);
        default:
            return state;
    }
}

function fetchAreasBegin(state, action) {
    return {
        ...state,
    };
}

function fetchAreasSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchAreasFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        areaData: []
    };
}


function fetchCustomersBegin(state, action) {
    return {
        ...state,
    };
}

function fetchCustomersSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchCustomersFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        customersData: []
    };
}


function fetchLocationsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchLocationsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedLocations: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedLocations ? state.PaginatedLocations : {}, action ?.payload ?.locations , action.isReset),
        ...action.payload
    };
}

function fetchLocationsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        locations: []
    };
}


function fetchVendorBegin(state, action) {
    return {
        ...state
    };
}

function fetchVendorSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchVendorFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        vendor: []
    };
}

function fetchLocationByIdBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchLocationByIdSuccess(state, action) {
    return {
        ...state,
        loading: false,
        locationById : {...action.payload}
    };
}

function fetchLocationByIdFailure(state, action) {
    return {
        ...state,
        loading: false,
        locationById: {},
        error: action.payload
    };
}

function locationModificationBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function locationModificationSuccess(state, action) {
    return {
        ...state,
        loading: false,
        locations: { ...action.payload }
    };
}

function locationModificationFailure(state, action) {
    return {
        ...state,
        loading: false,
        locations: {},
        error: action.payload
    };
}

function promiseTimeModificationBegin(state, action) {
    return {
        ...state,
    };
}

function promiseTimeModificationSuccess(state, action) {
    return {
        ...state,
        promiseTime: { ...action.payload }
    };
}

function promiseTimeModificationFailure(state, action) {
    return {
        ...state,
        promiseTime: {},
        error: action.payload
    };
}

function groupLocationBegin(state, action) {
    return {
        ...state,
    };
}

function groupLocationSuccess(state, action) {
    return {
        ...state,
        groupLocation: { ...action.payload }
    };
}

function groupLocationFailure(state, action) {
    return {
        ...state,
        groupLocation: {},
        error: action.payload
    };
}