import { Constants } from "../../../common/utils/constants";
import * as _Constants from "./constants";
import FleetService from "../../../services/fleetmanage/fleet.service";
import ManageFleetService from "../../../services/fleetmanage/fleetmanage.service";

export const getCustomer = () => (dispatch) => {
  dispatch(customerListBegin());
  FleetService.getCustomer().then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(customerListSuccess(res?.data?.response));
    } else {
      dispatch(customerListFailure(res?.data?.response));
    }
  });
};

function customerListBegin() {
  return {
    type: _Constants.GET_CUSTOMER_LIST_BEGIN,
  };
}

function customerListSuccess(payload) {
  return {
    type: _Constants.GET_CUSTOMER_LIST_SUCCESS,
    payload: payload,
  };
}

function customerListFailure(error) {
  return {
    type: _Constants.GET_CUSTOMER_LIST_FAILURE,
    payload: error,
  };
}

export const getCityList = () => (dispatch) => {
  dispatch(cityListBegin());
  FleetService.cityList().then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(cityListSuccess(res?.data?.response));
    } else {
      dispatch(cityListFailure(res?.data?.response));
    }
  });
};

function cityListBegin() {
  return {
    type: _Constants.GET_CITY_LIST_BEGIN,
  };
}

function cityListSuccess(payload) {
  return {
    type: _Constants.GET_CITY_LIST_SUCCESS,
    payload: payload,
  };
}

function cityListFailure(error) {
  return {
    type: _Constants.GET_CITY_LIST_FAILURE,
    payload: error,
  };
}

export const getCityListById = (cityId) => (dispatch) => {
  dispatch(cityListByIdBegin());
  FleetService.cityListbyId(cityId).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(cityListByIdSuccess(res?.data?.response));
    } else {
      dispatch(cityListByIdFailure(res?.data?.response));
    }
  });
};

function cityListByIdBegin() {
  return {
    type: _Constants.GET_CITY_LIST_ID_BEGIN,
  };
}

function cityListByIdSuccess(payload) {
  return {
    type: _Constants.GET_CITY_LIST_ID_SUCCESS,
    payload: payload,
  };
}

function cityListByIdFailure(error) {
  return {
    type: _Constants.GET_CITY_LIST_ID_FAILURE,
    payload: error,
  };
}

export const fetchUserData = (payload) => (dispatch) => {
  dispatch(userDataPageBegin());
  FleetService.fetchUsersData(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(userDataPageSuccess(res?.data?.response));
    } else {
      dispatch(userDataPageFailure(res?.data?.response));
    }
  });
};

function userDataPageBegin() {
  return {
    type: _Constants.GET_USERDATA_BEGIN,
  };
}

function userDataPageSuccess(payload) {
  return {
    type: _Constants.GET_USERDATA_SUCCESS,
    payload: payload,
  };
}

function userDataPageFailure(error) {
  return {
    type: _Constants.GET_USERDATA_FAILURE,
    payload: error,
  };
}

export const setPermissions = (payload, callback) => (dispatch) => {
  dispatch(addPermissionPageBegin());
  FleetService.setPermission(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(addPermissionPageSuccess(res?.data?.response));
      if (callback) {
        console.log("calling call back");
        dispatch(callback);
      }
    } else {
      dispatch(addPermissionPageFailure(res?.data?.response));
    }
  });
};

function addPermissionPageBegin() {
  return {
    type: _Constants.GET_ADD_PERMISSION_BEGIN,
  };
}

function addPermissionPageSuccess(payload) {
  return {
    type: _Constants.GET_ADD_PERMISSION_SUCCESS,
    payload: payload,
  };
}

function addPermissionPageFailure(error) {
  return {
    type: _Constants.GET_ADD_PERMISSION_FAILURE,
    payload: error,
  };
}

export const fetchZoneOptions = (params) => (dispatch) => {
  dispatch(fetchZoneOptionsBegin());
  let payload = {
    filters: {
      fetchObjects: ["ZONE"],
    },
  };
  FleetService.fetchZones(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(fetchZoneOptionsSuccess(res?.data?.response));
    } else {
      dispatch(fetchZoneOptionsFailure(res?.data?.response));
    }
  });
};

function fetchZoneOptionsBegin() {
  return {
    type: _Constants.ZONE_OPTIONS_FETCH_BEGIN,
  };
}

function fetchZoneOptionsSuccess(zones) {
  return {
    type: _Constants.ZONE_OPTIONS_FETCH_SUCCESS,
    payload: zones,
  };
}

function fetchZoneOptionsFailure(errorMessage) {
  return {
    type: _Constants.ZONE_OPTIONS_FETCH_FAILURE,
    payload: errorMessage,
  };
}

export const hyperlocalServicableAreas = (payload) => (dispatch) => {
  dispatch(hyperLocalZoneBegin());
  FleetService.hyperlocalServicableAreas(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      let arr = res?.data?.response.zones;
      if (arr && arr.length) {
        arr.sort((a, b) => a._key.localeCompare(b._key));
      }
      const dispatchPayload = {
        customers: res?.data?.response.customers,
        zones: arr,
      };
      // dispatch(hyperLocalZoneSuccess(res ?.data ?.response));
      dispatch(hyperLocalZoneSuccess(dispatchPayload));
    } else {
      dispatch(hyperLocalZoneFailure(res?.data?.response));
    }
  });
};

function hyperLocalZoneBegin() {
  return {
    type: _Constants.HYPERLOCAL_ZONE_OPTIONS_FETCH_BEGIN,
  };
}

function hyperLocalZoneSuccess(zones) {
  return {
    type: _Constants.HYPERLOCAL_ZONE_OPTIONS_FETCH_SUCCESS,
    payload: zones,
  };
}

function hyperLocalZoneFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_ZONE_OPTIONS_FETCH_FAILURE,
    payload: errorMessage,
  };
}

export const getFilterData = (payload) => (dispatch) => {
  return FleetService.fetchUsersData(payload).then((res) => {
    return res;
  });
};

export const zoneToggle = (payload) => (dispatch) => {
  let payload2 = {
    cityId: payload.cityId,
    locationIds: payload.locationIds,
  };
  return FleetService.zoneToggle(payload).then((res) => {
    dispatch(hyperlocalServicableAreas(payload2));
    return res;
  });
};

export const getEligibleZones = (userId) => (dispatch) => {
  return FleetService.getEligibleZones(userId).then((res) => {
    return res;
  });
};

export const getEligibleClients = (userId) => (dispatch) => {
  return FleetService.getEligibleClients(userId).then((res) => {
    return res;
  });
};

export const saveZones = (userId, payload) => (dispatch) => {
  return FleetService.saveZones(userId, payload).then((res) => {
    return res;
  });
};

export const saveClients = (userId, payload) => (dispatch) => {
  return FleetService.saveClients(userId, payload).then((res) => {
    return res;
  });
};

export const getCustomers = (userId) => (dispatch) => {
  dispatch(getClientsBegin());
  FleetService.getCustomers(userId).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(getClientsSuccess(res?.data?.response));
    } else {
      dispatch(getClientsFailure(res?.data?.response));
    }
  });
};

function getClientsBegin() {
  return {
    type: _Constants.HYPERLOCAL_BOX_CLIENT_BEGIN,
  };
}

function getClientsSuccess(clients) {
  return {
    type: _Constants.HYPERLOCAL_BOX_CLIENT_SUCCESS,
    payload: clients,
  };
}

function getClientsFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_BOX_CLIENT_FAILURE,
    payload: errorMessage,
  };
}

export const getZoneData = (userId) => (dispatch) => {
  dispatch(getZonesBegin());
  FleetService.getZoneData(userId).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(getZonesSuccess(res?.data?.response));
    } else {
      dispatch(getZonesFailure(res?.data?.response));
    }
  });
};

function getZonesBegin() {
  return {
    type: _Constants.HYPERLOCAL_BOX_ZONE_BEGIN,
  };
}

function getZonesSuccess(zones) {
  return {
    type: _Constants.HYPERLOCAL_BOX_ZONE_SUCCESS,
    payload: zones,
  };
}

function getZonesFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_BOX_ZONE_FAILURE,
    payload: errorMessage,
  };
}

export const getGeoFence = (payload) => (dispatch) => {
  dispatch(getGeoFenceBegin());
  FleetService.geoFence(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(getGeoFenceSuccess(res?.data?.response));
    } else {
      dispatch(getGeoFenceFailure(res?.data?.response));
    }
  });
};
function getGeoFenceBegin() {
  return {
    type: _Constants.HYPERLOCAL_GEOFENCE_BEGIN,
  };
}

function getGeoFenceSuccess(zones) {
  return {
    type: _Constants.HYPERLOCAL_GEOFENCE_SUCCESS,
    payload: zones,
  };
}

function getGeoFenceFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_GEOFENCE_FAILURE,
    payload: errorMessage,
  };
}

export const allActiveFes = (payload) => (dispatch) => {
  dispatch(getAllFeBegin());
  FleetService.allActiveFes(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(getAllFeSuccess(res?.data?.response));
    } else {
      dispatch(getAllFeFailure(res?.data?.response));
    }
  });
};
function getAllFeBegin() {
  return {
    type: _Constants.HYPERLOCAL_GETALL_FE_BEGIN,
  };
}

function getAllFeSuccess(zones) {
  return {
    type: _Constants.HYPERLOCAL_GETALL_FE_SUCCESS,
    payload: zones,
  };
}

function getAllFeFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_GETALL_FE_FAILURE,
    payload: errorMessage,
  };
}

export const allOrderData = (payload) => (dispatch) => {
  dispatch(allOrderBegin());
  FleetService.allOrderData(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(allOrderSuccess(res?.data?.response));
    } else {
      dispatch(allOrderFailure(res?.data?.response));
    }
  });
};
function allOrderBegin() {
  return {
    type: _Constants.HYPERLOCAL_GETORDER_BEGIN,
  };
}

function allOrderSuccess(zones) {
  return {
    type: _Constants.HYPERLOCAL_GETORDER_SUCCESS,
    payload: zones,
  };
}

function allOrderFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_GETORDER_FAILURE,
    payload: errorMessage,
  };
}

export const allCountDetails = (payload) => (dispatch) => {
  dispatch(getAllCountBegin());
  FleetService.feCountDetails(payload).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(getAllCountSuccess(res?.data?.response));
    } else {
      dispatch(getAllCountFailure(res?.data?.response));
    }
  });
};
function getAllCountBegin() {
  return {
    type: _Constants.HYPERLOCAL_GETALL_COUNT_BEGIN,
  };
}

function getAllCountSuccess(data) {
  return {
    type: _Constants.HYPERLOCAL_GETALL_COUNT_SUCCESS,
    payload: data,
  };
}

function getAllCountFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_GETALL_COUNT_FAILURE,
    payload: errorMessage,
  };
}

export const riderDetails = (id) => (dispatch) => {
  dispatch(getRiderDetailsBegin());
  FleetService.riderDetails(id).then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(getRiderDetailsSuccess(res?.data?.response));
    } else {
      dispatch(getRiderDetailsFailure(res?.data?.response));
    }
  });
};
function getRiderDetailsBegin() {
  return {
    type: _Constants.HYPERLOCAL_RIDER_DETAILS_BEGIN,
  };
}

function getRiderDetailsSuccess(data) {
  return {
    type: _Constants.HYPERLOCAL_RIDER_DETAILS_SUCCESS,
    payload: data,
  };
}

function getRiderDetailsFailure(errorMessage) {
  return {
    type: _Constants.HYPERLOCAL_RIDER_DETAILS_FAILURE,
    payload: errorMessage,
  };
}

export const getTemplates = () => (dispatch) => {
  dispatch(fetchTemplateBegin());
  FleetService.getTemplates().then((res) => {
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(fetchTemplateSuccess(res?.data?.response));
    } else {
      dispatch(fetchTemplateFailure(res?.data?.response));
    }
  });
};

export const getMultiTaggedEligibility = (payload) => (dispatch) => {
  return FleetService.getMultiTaggedEligibility(payload).then((res) => {
    return res;
  });
};
export const saveMultiTaggedEligibility = (payload) => (dispatch) => {
  return FleetService.saveMultiTaggedEligibility(payload).then((res) => {
    return res;
  });
};
export const getMultiTaggedZones = (payload) => (dispatch) => {
  return FleetService.getMultiTaggedZones(payload).then((res) => {
    return res;
  });
};
export const saveMultiTaggedZones = (payload) => (dispatch) => {
  return FleetService.saveMultiTaggedZones(payload).then((res) => {
    return res;
  });
};
export const getMultiTaggedCustomers = (payload) => (dispatch) => {
  return FleetService.getMultiTaggedCustomers(payload).then((res) => {
    return res;
  });
};
export const saveMultiTaggedCustomers = (payload) => (dispatch) => {
  return FleetService.saveMultiTaggedCustomers(payload).then((res) => {
    return res;
  });
};
export const getCodEligibility = (payload) => (dispatch) => {
  return FleetService.getCodEligibility(payload).then((res) => {
    return res;
  });
};
export const saveCodEligibility = (payload) => (dispatch) => {
  return FleetService.saveCodEligibility(payload).then((res) => {
    return res;
  });
};

function fetchTemplateBegin() {
  return {
    type: _Constants.GET_FETCH_TEMPLATE_NOTIFY_BEGIN,
  };
}

function fetchTemplateSuccess(payload) {
  return {
    type: _Constants.GET_FETCH_TEMPLATE_NOTIFY_SUCCESS,
    payload: payload,
  };
}

function fetchTemplateFailure(error) {
  return {
    type: _Constants.GET_FETCH_TEMPLATE_NOTIFY_FAILURE,
    payload: error,
  };
}

export const callDropDownApi_v2 = (endpoint) => (dispatch) => {
  return ManageFleetService.callDropDownApi_v2(endpoint).then((res) => {
    return res;
  });
};

export const sendPushNotification = (payload) => (dispatch) => {
  return ManageFleetService.sendPushNotification(payload).then((res) => {
    return res;
  });
};

export const getRidersDetails = (id) => (dispatch) => {
  return FleetService.riderDetails(id).then((res) => {
    return res;
  });
};

export const cancellation = (data) => (dispatch) => {
  return FleetService.cancellation(data).then((res) => {
    return res;
  });
};

export const getCustomerName = (data) => (dispatch) => {
  return FleetService.getCustomerName(data).then((res) => {
    return res;
  });
};

export const setRainSurge = (data, isActive) => {
  console.log("setRainSurge was called from action");
  return FleetService.setRainSurge(data, isActive);
};

export const setDemandSurge = (data, isActive) => {
  console.log("setDemandSurge was called from action");
  return FleetService.setDemandSurge(data, isActive);
};

export const fetchStaffData = (payload) => (dispatch) => {
  dispatch(staffDataPageBegin());
  FleetService.fetchStaffsData(payload).then((res) => {
    console.log("Dispatched from action");
    if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
      dispatch(staffDataPageSuccess(res?.data?.response));
    } else {
      dispatch(staffDataPageFailure(res?.data?.response));
    }
  });
};
function staffDataPageBegin() {
  return {
    type: _Constants.GET_STAFFDATA_BEGIN,
  };
}

function staffDataPageSuccess(payload) {
  return {
    type: _Constants.GET_STAFFDATA_SUCCESS,
    payload: payload,
  };
}

function staffDataPageFailure(error) {
  return {
    type: _Constants.GET_STAFFDATA_FAILURE,
    payload: error,
  };
}

export function resetStaffData(payload) {
  return {
    type: _Constants.RESET_STAFFDATA,
    payload: payload,
  };
}
