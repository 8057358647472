import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { Constants } from '../../../common/utils/constants';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';


const partnerId = localStorage.getItem('loggedPartnerId');


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getUsers = (params) => dispatch => {
    dispatch(getUsersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customer-users/fetch`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getUsersSuccess(response.data?.response))
        })
        .catch(error => dispatch(getUsersFailure(error)));
}


export const getCustomerUsers = (params) => dispatch => {
    dispatch(getPartnerUsersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getPartnerUsersSuccess(response.data?.response))
        })
        .catch(error => dispatch(getPartnerUsersFailure(error)));
}

export const getEditUsers = (id) => dispatch => {
    dispatch(getEditUsersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/users/${id}`,        
        method: 'GET'

    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getEditUsersSuccess(response.data?.response))
        })
        .catch(error => dispatch(getEditUsersFailure(error)));
}


export const getResendInvite = (id) => dispatch => {
    dispatch(getInviteBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/user/resend-activation/${id}`,        
        method: 'GET'

    })
        .then(response => {            
            if (checkHttpStatus(response.data?.status))
                dispatch(getInviteSuccess(response.data))
        })
        .catch(error => dispatch(getInviteFailure(error)));
}


export const deactivateUser = (id) => dispatch => {
    dispatch(getDeactivateBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/user/deactivate/${id}`,        
        method: 'GET'

    })
        .then(response => {            
            if (checkHttpStatus(response.data?.status))
                dispatch(getDeactivateSuccess(response.data))
        })
        .catch(error => dispatch(getDeactivateFailure(error)));
}


export const addUsers = (params) => dispatch => {
    dispatch(setUsersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customer-users`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(setUsersSuccess(response.data?.response))
        })
        .catch(error => dispatch(setUsersFailure(error)));
}

export const updateUser = (params, userID) => dispatch => {
    dispatch(updateUsersBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/customer-users/migrate`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(updateUsersSuccess(response.data))
        })
        .catch(error => dispatch(updateUsersFailure(error)));
}

export const getLocations = (params) => dispatch => {
    dispatch({
        type: CONSTANTS.FETCH_PARTNER_CUSTOMER_LOCATIONS_BEGIN
    });
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
        data: params
    })
        .then(response => {

            dispatch({
                type: CONSTANTS.FETCH_PARTNER_CUSTOMER_LOCATIONS_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.FETCH_PARTNER_CUSTOMER_LOCATIONS_FAILURE,
            payload: error
        }));
}

export const resetFields = () => dispatch => {
    dispatch({
        type: CONSTANTS.RESET_CUSTOMER_USERS_FIELDS
    })
}

export const resetEditFields = () => dispatch => {
    dispatch({
        type: CONSTANTS.RESET_CUSTOMER_USERS_EDIT_FIELDS
    })
}

export const resetEditLocationsFields = () => dispatch => {
    dispatch({
        type: CONSTANTS.RESET_CUSTOMER_LOCATIONS_FIELDS
    })
}


function getUsersBegin() {
    return {
        type: CONSTANTS.GET_PARTNER_USERS_LIST_BEGIN,
        payload: null
    }
}

function getUsersSuccess(response) {
    return {
        type: CONSTANTS.GET_PARTNER_USERS_LIST_SUCCESS,
        payload: response
    }
}

function getUsersFailure(response) {
    return {
        type: CONSTANTS.GET_PARTNER_USERS_LIST_FAILURE,
        payload: response
    }
}


function getPartnerUsersBegin() {
    return {
        type: CONSTANTS.GET_PARTNER_CUSTOMER_USERS_LIST_BEGIN,
        payload: null
    }
}

function getPartnerUsersSuccess(response) {
    return {
        type: CONSTANTS.GET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS,
        payload: response
    }
}

function getPartnerUsersFailure(response) {
    return {
        type: CONSTANTS.GET_PARTNER_CUSTOMER_USERS_LIST_FAILURE,
        payload: response
    }
}

function getInviteBegin() {
    return {
        type: CONSTANTS.GET_PARTNER_CUSTOMER_RESEND_INVITE_BEGIN,
        payload: null
    }
}

function getInviteSuccess(response) {    
    return {
        type: CONSTANTS.GET_PARTNER_CUSTOMER_RESEND_INVITE_SUCCESS,
        payload: response
    }
}

function getInviteFailure(response) {
    return {
        type: CONSTANTS.GET_PARTNER_CUSTOMER_RESEND_INVITE_FAILURE,
        payload: response
    }
}

function getDeactivateBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_DEACTIVATE_BEGIN,
        payload: null
    }
}

function getDeactivateSuccess(response) {    
    return {
        type: CONSTANTS.GET_CUSTOMER_DEACTIVATE_SUCCESS,
        payload: response
    }
}

function getDeactivateFailure(response) {
    return {
        type: CONSTANTS.GET_CUSTOMER_DEACTIVATE_FAILURE,
        payload: response
    }
}


function getEditUsersBegin() {
    return {
        type: CONSTANTS.GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_BEGIN,
        payload: null
    }
}

function getEditUsersSuccess(response) {
    return {
        type: CONSTANTS.GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_SUCCESS,
        payload: response
    }
}

function getEditUsersFailure(response) {
    return {
        type: CONSTANTS.GET_EDIT_PARTNER_CUSTOMER_USERS_LIST_FAILURE,
        payload: response
    }
}

function setUsersBegin() {
    return {
        type: CONSTANTS.SET_PARTNER_CUSTOMER_USERS_LIST_BEGIN,
        payload: null
    }
}

function setUsersSuccess(response) {
    return {
        type: CONSTANTS.SET_PARTNER_CUSTOMER_USERS_LIST_SUCCESS,
        payload: response
    }
}

function setUsersFailure(response) {
    return {
        type: CONSTANTS.SET_PARTNER_CUSTOMER_USERS_LIST_FAILURE,
        payload: response
    }
}

function updateUsersBegin() {
    return {
        type: CONSTANTS.SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_BEGIN,
        payload: null
    }
}

function updateUsersSuccess(response) {
    return {
        type: CONSTANTS.SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_SUCCESS,
        payload: response
    }
}

function updateUsersFailure(response) {
    return {
        type: CONSTANTS.SET_UPDATE_PARTNER_CUSTOMER_USERS_LIST_FAILURE,
        payload: response
    }
}


export const exportCustomer = (params) => dispatch => {
    dispatch(exportCustomerBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/reports/CUSTOMER_USER_REPORT/get`,
        data: params,
        method: 'POST'

    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(exportCustomerSuccess(response.data?.response))
        })
        .catch(error => dispatch(exportCustomerFailure(error)));
}


function exportCustomerBegin() {
    return {
        type: CONSTANTS.GET_EXPORT_CUSTOMERS_BEGIN        
    }
}

function exportCustomerSuccess(response) {
    return {
        type: CONSTANTS.GET_EXPORT_CUSTOMERS_SUCCESS,
        payload: response
    }
}

function exportCustomerFailure(response) {
    return {
        type: CONSTANTS.GET_EXPORT_CUSTOMERS_FAILURE,
        payload: response
    }
}