import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { modal as Modal } from "../../ui/modal/Modal";
import { Upload, Icon, message, Button, Spin } from "antd";
import * as Constants from "./../../../common/utils/constants";
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { FILE_UPLOAD_TYPE_DRAGDROP } from "../../settings/settingConstants";
import { FILE_UPLOAD_TYPE_UPLOAD_BUTTON } from "../../settings/settingConstants";
import _ from 'lodash';

const { Dragger } = Upload;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const FileUpload = props => {
  const [fileList, updateFileList] = useState([]);
  const [uploading, updateUploading] = useState(false);
  const [isUploadDisabled, updateIsUploadDisabled] = useState(false);
  const [uploadButtonText, setUploadButtontext] = useState('Start Upload');
  const [acceptTypes, updateAcceptTypes] = useState(getInitialAceeptTypes());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let types = getInitialAceeptTypes()
    updateAcceptTypes(types);
    filePropObj['accept'] = types
  }, [props.acceptTypes]);

  useEffect(() => {
    if (props.clearFileAfterUpload) {
      handleClear()
    }
  }, [props.clearFileAfterUpload])


  function getFileExtension(fileName) {
    return "." + fileName.split(".").pop();
  }

  function getInitialAceeptTypes() {
    if (props.acceptTypes && Array.isArray(props.acceptTypes)) return props.acceptTypes
    else return []
  }

  function checkAcceptTypes(file) {

    if (acceptTypes.length) {
      const extension = getFileExtension(file.name);
      const isValidType = acceptTypes.indexOf(extension) > -1;
      if (!isValidType) {
        // props.onAcceptTypeError.emit(extension);
        return false;
      }
      return isValidType;
    }
    return true;
  }

  function handleClear() {
    updateFileList([]);
    updateIsUploadDisabled(false);
    setUploadButtontext('Start Upload');
  }

  function handleUpload(newFile) {
    updateUploading(true);
    setUploadButtontext('Uploading');
    updateIsUploadDisabled(true)
    let file = fileList[0];
    if (newFile && !newFile.target) {
      file = newFile;
    }

    if (props?.showLoader) {
      setLoading(true);
    }


    if (file && checkAcceptTypes(file)) {
      if (!props.directUpload && !props.disableUploadButton) {
        props.onUploadFinish(file);
        if (props.clearFileAfterUpload) { handleClear() }
        updateUploading(false);
        updateIsUploadDisabled(true)
        setUploadButtontext('Retry Uploading');
      } else {
        if (props?.fileSizeLimit) {
          const isLt5M = file.size / 1024 / 1024 < props?.fileSizeLimit;
          if (!isLt5M) {
            updateUploading(false);
            setLoading(false);
            setUploadButtontext('Uploaded');
            updateIsUploadDisabled(false)
            notifyApiError(`File size is greater than ${props?.fileSizeLimit} MB`);            
          } else {
            AwsUploadLink(file)
          }
        } else {
          AwsUploadLink(file)
        }

      }
    } else {
      // onError()
      updateUploading(false);
      setLoading(false);
      setUploadButtontext('Uploading Failed');
      updateIsUploadDisabled(false)
      notifyApiError("Something is wrong. Please check file or type.");
      console.error("Something went wrong.");
    }

    // if (props.directUpload) {
    //   if (file && checkAcceptTypes(file)) {
    //     props.onUploadFinish(file);
    //     if (props.clearFileAfterUpload) { handleClear() }
    //     updateUploading(false);
    //   }
    // } else {
    //   if (file && checkAcceptTypes(file)) {
    //     AwsUploadLink(file)
    //   } else {
    //     // onError()
    //     updateUploading(false);
    //     notifyApiError("Something is wrong. Please check file or type.");
    //     console.error("Something went wrong.");
    //   }
    // }
  }

  function AwsUploadLink(file) {
    // const file = file;
    AWS.config.region = Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].pool
    });

    const bucketName = Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].bucketName;
    const bucket = new AWS.S3({
      params: {
        Bucket: bucketName
      }
    });
    const uuid = Math.random();
    const fileExtension = getFileExtension(file.name);
    let key;
    if (props.path && props.path.length) {
      key = `${Constants.Constants.ENV}/${Constants.Constants.PARTNER_ID}/${props.path
        }/${props.objKey}_${uuid}.${fileExtension}`;
    } else {
      key = `${Constants.Constants.ENV}/${Constants.Constants.PARTNER_ID}/${props.objKey
        }_${uuid}.${fileExtension}`;
    }

    const filePath =
      bucket.endpoint.protocol +
      "//" +
      bucketName +
      "." +
      bucket.endpoint.hostname +
      "/" +
      key;
    const params = {
      Key: key,
      ContentType: file.type,
      Body: file,
      ACL: Constants.Constants.s3Connection[props.objKey === 'reports' ? 'mumbai' : 'default'].acl,
      Bucket: bucketName
    };
    bucket.putObject(params).send((err, data) => {
      return new Promise((resolve, reject) => {
        if (err) {
          setLoading(false);
          notifyApiError(JSON.stringify(err), "File Upload");
          console.error("ERROR: " + err);
          updateUploading(false);
          updateIsUploadDisabled(false)
          setUploadButtontext('Retry Upload');
          reject(false);
        } else {
          // onSuccess(data.response, file);

          if (_.hasIn(props, 'showToastMessage') && !props?.showToastMessage) {
            console.info("file upload successfully");
          } else {
            notifyApiSuccess("SUCESSFULLY Uploaded.", "File Upload");
          }
          setLoading(false);
          updateUploading(false);
          setUploadButtontext('Uploaded');
          updateIsUploadDisabled(true)
          props.onUploadFinish(filePath, file.name);
          if (props.clearFileAfterUpload) {
            handleClear()
          }
          resolve(true);
        }
      });
    });
  }

  function getDragDropHtml() {

    return (
      <>
        <Dragger {...filePropObj}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p style={{ marginRight: '10px' }}> (Acceptabe file(s): {acceptTypes ? acceptTypes.join(', ') : 'Any File'} ) </p>
        </Dragger>
        {
          !props.disableUploadButton
            ?
            <>
              <Button
                onClick={() => handleUpload('')}
                disabled={fileList.length === 0 || isUploadDisabled}
                loading={uploading}
                style={{ marginTop: 16 }}
              >
                {uploadButtonText}
              </Button>
              <Button
                type="danger"
                onClick={handleClear}
                disabled={fileList.length === 0}
                style={{ marginTop: 16, marginLeft: 5 }}
              >
                Clear
              </Button>
            </>
            : ''
        }
      </>
    );
  }
  function getButtonHtml() {

    let buttonHtml = props.uploadButtonHtml ? (
      props?.showLoader ?
        <Spin spinning={loading}>
          {props.uploadButtonHtml}
        </Spin>
        : props.uploadButtonHtml
    ) : (
        <Button disabled={props.disableAll}>
          <Icon type="upload" /> Click to Upload
        </Button>
      );
    return <Upload {...filePropObj}>{buttonHtml}</Upload>;
  }
  function handleOnChange(event) {
    if (typeof props.beforeUpload === 'function') {
      props.beforeUpload(event.file);
    }
    updateFileList([...fileList, event.file]);
    handleUpload(event.file);
  }
  const filePropObj = {
    accept: acceptTypes.join(","),
    disabled: !props.disableUploadButton ? fileList.length > 0 : false,
    multiple: props.multiple,
    beforeUpload: !props?.disableUploadButton
      ? file => {
        updateFileList([...fileList, file]);
        return false;
      }
      : null,
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      updateFileList(newFileList);
      updateUploading(false);
      setUploadButtontext('Start Upload');
    },
    fileList,
    customRequest: (e) => { return props.disableUploadButton ? handleOnChange(e) : null }
  };
  switch (props.fileUploadType) {
    case Constants.UPLOAD_BUTTON_TYPE.DRAGDROP:
      return getDragDropHtml();
    case Constants.UPLOAD_BUTTON_TYPE.BUTTON:
      return getButtonHtml();
    default:
      return getButtonHtml();
  }
};
export default FileUpload;
