import React, { PureComponent } from 'react'
import { canAllow, windowRedirect } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import CustomerDashboard from "./customer/CustomerDashboard";
import UserDashboard from './user/UserDashboard';
import Asset from './asset/Asset'
import Pincode from './pincode/Pincode'
import PartnersOnboarding from './partners-onboarding/PartnersOnboarding'
import CustomConfig from './config/config'
import RateCardCustomConfig from './ratecard/ratecard-config'
import ZohoDashboard from './zoho-dashboard/zohoDashboard'
import ZoneDashboard from '../../components/hyperlocal/fleet/ZonesDashboard'
import VendorList from './vendor/Vendor.list'
import LocationDashboard from './location/LocationDashboard'
import RouteDashboard from './route/RouteDashboard'
// import ClientOnboardingForm from './client-onboarding/ClientOnboardingForm';

class Settingmodules extends PureComponent {

    constructor(props) {
        super(props)
    }
    state = {
        userSettingPermission: {
            customerRead: canAllow(permissionTags.settings.customer.read),
            userRead: canAllow(permissionTags.settings.user.read),
            assetRead: canAllow(permissionTags.settings.asset.read),
            zoneRead: canAllow(permissionTags.settings.zone.read),
            zohoRead: canAllow(permissionTags.settings.zoho.read),
            // pincodeRead: canAllow(permissionTags.settings.pincode.read), //doesn't have create
            partners_onboardingRead: canAllow(permissionTags.settings.partners_onboarding.read),
            custom_configRead: canAllow(permissionTags.settings.custom_config.read), 
            ratecard_configRead: canAllow(permissionTags.settings.ratecard_config.read), //doesn't have create
            vendorRead: canAllow(permissionTags.settings.vendor.read),
            locationRead: canAllow(permissionTags.settings.location.read),
            routeRead: canAllow(permissionTags.settings.route.read),
            // client_onboardingRead: canAllow(permissionTags.settings.client_onboardingRead.read),
        },
    }
    render() {
        const { userSettingPermission } = this.state;
        if (userSettingPermission.customerRead) {
            return <CustomerDashboard {...this.props} />
        }
        if (userSettingPermission.userRead) {
            return <UserDashboard {...this.props} />
        }
        if (userSettingPermission.assetRead) {
            return <Asset {...this.props} />
        }
        if (userSettingPermission.zoneRead) {
            return <ZoneDashboard {...this.props} />
        }
        if (userSettingPermission.zohoRead) {
            return <ZohoDashboard {...this.props} />
        }
        // if (userSettingPermission.pincodeRead) {
        //     return <Pincode {...this.props} />
        // }
        if (userSettingPermission.partners_onboardingRead) {
            return <PartnersOnboarding {...this.props} />
        }
        if (userSettingPermission.custom_configRead) {
            return <CustomConfig {...this.props} />
        }
        if (userSettingPermission.ratecard_configRead) {
            return <RateCardCustomConfig {...this.props} />
        }
        if (userSettingPermission.vendorRead) {
            return <VendorList {...this.props} />
        }
        if (userSettingPermission.locationRead) {
            return <LocationDashboard {...this.props} />
        }
        if (userSettingPermission.routeRead) {
            return <RouteDashboard {...this.props} />
        }
        // if (userSettingPermission.client_onboardingRead) {
        //     return <ClientOnboardingForm {...this.props} />
        // }
       
        return (
            <></>
        )
    }
}

export default Settingmodules;