export const GET_STATE_DETAILS_BEGIN = 'GET_STATE_DETAILS_BEGIN';
export const GET_STATE_DETAILS_SUCCESS = 'GET_STATE_DETAILS_SUCCESS';
export const GET_STATE_DETAILS_FAILURE = 'GET_STATE_DETAILS_FAILURE';

export const GET_PINCODE_UPDATE_BEGIN = 'GET_PINCODE_UPDATE_BEGIN';
export const GET_PINCODE_UPDATE_SUCCESS = 'GET_PINCODE_UPDATE_SUCCESS';
export const GET_PINCODE_UPDATE_FAILURE = 'GET_PINCODE_UPDATE_FAILURE';

export const GET_PARTNERS_ONBOARD_FILE_BEGIN = 'GET_PARTNERS_ONBOARD_FILE_BEGIN';
export const GET_PARTNERS_ONBOARD_FILE_SUCCESS = 'GET_PARTNERS_ONBOARD_FILE_SUCCESS';
export const GET_PARTNERS_ONBOARD_FILE_FAILURE = 'GET_PARTNERS_ONBOARD_FILE_FAILURE';

