export default [
  { status: 1, t: "180927072508000", course: 0, lat: 53.22376666666667, lng: 50.745841666666664 },
  { status: 1, t: "180927072508000", course: 0, lat: 53.22376666666667, lng: 50.745841666666664 },
  { status: 1, t: "180927072523000", course: 114, lat: 53.223728333333334, lng: 50.74598666666667 },
  { status: 1, t: "180927072529000", course: 138, lat: 53.223705, lng: 50.746021666666664 },
  { status: 1, t: "180927072538000", course: 149, lat: 53.22365166666667, lng: 50.746075 },
  { status: 1, t: "180927072538000", course: 149, lat: 53.22365166666667, lng: 50.746075 },
  { status: 1, t: "180927072554000", course: 111, lat: 53.22358333333333, lng: 50.74638 },
  { status: 1, t: "180927072608000", course: 15, lat: 53.2238, lng: 50.746478333333336 },
  { status: 1, t: "180927072623000", course: 245, lat: 53.22374333333333, lng: 50.74627666666667 },
  { status: 1, t: "180927072638000", course: 165, lat: 53.223725, lng: 50.746285 },
  { status: 1, t: "180927074023000", course: 0, lat: 53.22374, lng: 50.746285 },
  { status: 1, t: "180927074023000", course: 0, lat: 53.22374, lng: 50.746285 },
  { status: 1, t: "180927074038000", course: 160, lat: 53.22356833333333, lng: 50.746386666666666 },
  { status: 1, t: "180927074038000", course: 160, lat: 53.22356833333333, lng: 50.746386666666666 },
  { status: 1, t: "180927074050000", course: 270, lat: 53.22356833333333, lng: 50.74625 },
  { status: 1, t: "180927074053000", course: 0, lat: 53.22358333333333, lng: 50.74625 },
  { status: 1, t: "180927075308000", course: 188, lat: 53.22356833333333, lng: 50.746246666666664 },
  { status: 1, t: "180927075323000", course: 301, lat: 53.223618333333334, lng: 50.746105 },
  { status: 1, t: "180927075329000", course: 352, lat: 53.223681666666664, lng: 50.74609 },
  { status: 1, t: "180927075338000", course: 134, lat: 53.223675, lng: 50.74610166666667 },
  { status: 1, t: "180927080939000", course: 22, lat: 53.22372, lng: 50.74613166666666 },
  { status: 1, t: "180927080939000", course: 22, lat: 53.22372, lng: 50.74613166666666 },
  { status: 1, t: "180927080939000", course: 22, lat: 53.22372, lng: 50.74613166666666 },
  { status: 1, t: "180927080953000", course: 171, lat: 53.223391666666664, lng: 50.746215 },
  { status: 1, t: "180927081009000", course: 162, lat: 53.22277, lng: 50.746543333333335 },
  { status: 1, t: "180927081023000", course: 154, lat: 53.22221833333333, lng: 50.746993333333336 },
  { status: 1, t: "180927081038000", course: 141, lat: 53.22172166666667, lng: 50.747665 },
  { status: 1, t: "180927081038000", course: 141, lat: 53.22172166666667, lng: 50.747665 },
  { status: 1, t: "180927081050000", course: 138, lat: 53.221408333333336, lng: 50.74813 },
  { status: 1, t: "180927081053000", course: 139, lat: 53.221245, lng: 50.74836666666667 },
  { status: 1, t: "180927081108000", course: 146, lat: 53.220706666666665, lng: 50.74897 },
  { status: 1, t: "180927081116000", course: 144, lat: 53.220531666666666, lng: 50.74918 },
  { status: 1, t: "180927081123000", course: 145, lat: 53.22033666666667, lng: 50.749408333333335 },
  { status: 1, t: "180927081138000", course: 140, lat: 53.21983, lng: 50.75011833333333 },
  { status: 1, t: "180927081138000", course: 140, lat: 53.21983, lng: 50.75011833333333 },
  { status: 1, t: "180927081153000", course: 134, lat: 53.219375, lng: 50.75090333333333 },
  { status: 1, t: "180927081208000", course: 131, lat: 53.21893333333333, lng: 50.751751666666664 },
  { status: 1, t: "180927081223000", course: 123, lat: 53.21850666666667, lng: 50.75283 },
  { status: 1, t: "180927081238000", course: 119, lat: 53.21807166666667, lng: 50.75411666666667 },
  { status: 1, t: "180927081238000", course: 119, lat: 53.21807166666667, lng: 50.75411666666667 },
  { status: 1, t: "180927081254000", course: 119, lat: 53.217681666666664, lng: 50.75528 },
  { status: 1, t: "180927081308000", course: 118, lat: 53.217315, lng: 50.756413333333334 },
  { status: 1, t: "180927081324000", course: 117, lat: 53.21696166666667, lng: 50.75758 },
  { status: 1, t: "180927081338000", course: 117, lat: 53.216595, lng: 50.75879333333334 },
  { status: 1, t: "180927081338000", course: 117, lat: 53.216595, lng: 50.75879333333334 },
  { status: 1, t: "180927081354000", course: 118, lat: 53.21621666666667, lng: 50.76000666666667 },
  { status: 1, t: "180927081405000", course: 118, lat: 53.215968333333336, lng: 50.7608 },
  { status: 1, t: "180927081408000", course: 121, lat: 53.21584, lng: 50.761155 },
  { status: 1, t: "180927081424000", course: 115, lat: 53.21556833333333, lng: 50.762141666666665 },
  { status: 1, t: "180927081435000", course: 115, lat: 53.215435, lng: 50.762618333333336 },
  { status: 1, t: "180927081438000", course: 109, lat: 53.215378333333334, lng: 50.76288666666667 },
  { status: 1, t: "180927081438000", course: 109, lat: 53.215378333333334, lng: 50.76288666666667 },
  { status: 1, t: "180927081438000", course: 109, lat: 53.215378333333334, lng: 50.76288666666667 },
  { status: 1, t: "180927081453000", course: 85, lat: 53.215423333333334, lng: 50.763686666666665 },
  { status: 1, t: "180927081509000", course: 30, lat: 53.21579666666667, lng: 50.764041666666664 },
  { status: 1, t: "180927081523000", course: 28, lat: 53.21608666666667, lng: 50.76429666666667 },
  { status: 1, t: "180927081535000", course: 26, lat: 53.21628166666667, lng: 50.76445833333333 },
  { status: 1, t: "180927081539000", course: 29, lat: 53.216393333333336, lng: 50.76456 },
  { status: 1, t: "180927081539000", course: 29, lat: 53.216393333333336, lng: 50.76456 },
  { status: 1, t: "180927081553000", course: 47, lat: 53.21669, lng: 50.76508666666667 },
  { status: 1, t: "180927081559000", course: 70, lat: 53.21674333333333, lng: 50.76532666666667 },
  { status: 1, t: "180927081608000", course: 65, lat: 53.216876666666664, lng: 50.765815 },
  { status: 1, t: "180927081623000", course: 62, lat: 53.21711, lng: 50.76653666666667 },
  { status: 1, t: "180927081623000", course: 62, lat: 53.21711, lng: 50.76653666666667 },
  { status: 4, t: "180927081638000", course: 62, lat: 53.21732333333333, lng: 50.767205 },
  { status: 4, t: "180927081638000", course: 62, lat: 53.21732333333333, lng: 50.767205 },
  { status: 4, t: "180927081639000", course: 62, lat: 53.21732333333333, lng: 50.767205 },
]
