import React from 'react';
import { Modal } from 'antd';

export const modal = (props) => {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={props.onOk}
            footer={props.footer}
            width={props.width || 520}
            centered={props.centered || false}
            mask={props.mask}
            maskClosable={props.maskClosable}
            style={props.style}
            closable={props.closable}
            destroyOnClose={props.destroyOnClose}
            wrapClassName={props.wrapClassName}
            title={props.title}
            className={props.className}
        >
            {props.children}
        </Modal>
    )
}
