import * as CONSTANTS from './constant';
import * as apiUtils from '../../../common/utils/apiGateway';
import {BASE_URL_TYPES} from "../../../common/utils/constants";

export const downloadSearchedWaybills = data => dispatch => {
    dispatch(downloadSearchedWaybillsBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/reports/${data.documentType}/get`,
        baseUrlType: BASE_URL_TYPES.REPORTS,
        data
    }).then(response => {
        if (response.data.response.url) {
            dispatch(downloadSearchedWaybillsSuccess(response.data.response.url));
        } else {
            dispatch(downloadSearchedWaybillsFailure());
        }
    }).catch(err => {
        dispatch(downloadSearchedWaybillsFailure(err));

    })
}

function downloadSearchedWaybillsBegin() {
    return {
        type: CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_BEGIN
    }
}

function downloadSearchedWaybillsSuccess(url) {
    return {
        type: CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_SUCCESS,
        payload: url
    }
}

function downloadSearchedWaybillsFailure(err) {
    return {
        type: CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_FAILURE,
        payload: err
    }
}

export function filterWaybills(fullTableData, searchInput) {
    return {
        type: CONSTANTS.FILTER_WAYBILLS,
        payload: {
            fullTableData,
            searchInput
        }
    }
}
