import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./workers/serviceWorker";
import { Provider } from "react-redux";
import store from "./utils/store";
import AppRoute from "./AppRoute";
import Login from "./components/authentication/login";
// import Login from "./components/authentication/loginOld";

import { Route, Switch, withRouter, Router, Redirect } from "react-router-dom";
import history from "./utils/history";

if (window.location.hostname === "hyperlocal-atlas.loadshare.net") {
  const clarityScript = document.createElement("script");
  clarityScript.type = "text/javascript";
  clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "e4wwwgzqo6");`;
  window.document.head.appendChild(clarityScript);
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route
          component={withRouter(Login)}
          path={"/appv2/login"}
          exact={true}
        />
        <AppRoute path="/appv2/" key="loadshare_pvt" />
        <Route
          // Catch all route for both logged in and not logged in users
          render={() => <Redirect to={"/appv2/login"} />}
        />
      </Switch>
    </Router>
    {/* <AppRoute path="/appv2/customer-login" key="loadshare_login"/> */}
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
