import * as CONSTANTS from "./constants";
import { Constants } from "../../common/utils/constants";
import * as apiUtils from "../../common/utils/apiGateway";
import * as suspiciousActions from "../suspicious/action";
import * as trackingActions from "../tracking/action";

export const getTrackingDetails = (param) => (dispatch) => {
  if (!param.data) {
    return;
  }
  if (
    param.source !== undefined &&
    param.source === Constants.SUSPICIOUS_CLASSNAME
  ) {
    dispatch(suspiciousActions.getSuspiciousWaybillDetails(param.data));
  } else {
    dispatch(getCustomerContactNumberFailureReset());
    dispatch(trackingActions.getWaybillTrackingDetails(param.data));
  }
};

export const updateConsignmentPod = (streamId, payload) => {
  return (dispatch) => {
    dispatch(updateConsignmentPodBegin());
    apiUtils
      .apiRequest({
        url: `/b2b/v1/consignmentpod/streams/${streamId}`,
        method: "put",
        data: payload,
      })
      .then((response) => {
        if (
          Constants.SUCCESS_HTTP_CODES.indexOf(response.data.status.code) > -1
        ) {
          return dispatch(updateConsignmentPodSuccess(response.data));
        }
        return dispatch(
          updateConsignmentPodFailure(response?.data?.status?.message)
        );
      })
      .catch((error) => dispatch(updateConsignmentPodFailure(error)));
  };
};

const updateConsignmentPodBegin = () => {
  return {
    type: CONSTANTS.UPDATE_CONSIGNMENT_POD_BEGIN,
  };
};

const updateConsignmentPodSuccess = (response) => {
  return {
    type: CONSTANTS.UPDATE_CONSIGNMENT_POD_SUCCESS,
    payload: response.data,
  };
};

const updateConsignmentPodFailure = (errorMessage) => {
  return {
    type: CONSTANTS.UPDATE_CONSIGNMENT_POD_FAILURE,
    payload: errorMessage,
  };
};

export const resetNotificationData = () => {
  return {
    type: CONSTANTS.RESET_NOTIFICATION_DATA,
  };
};

export const getCustomerContactNumber = (details, id, type) => {
  return (dispatch) => {
    dispatch(getCustomerContactNumberFailureReset());
    dispatch(getCustomerContactNumberBegin());
    apiUtils
      .apiRequest({
        url: `/b2c/v1/consignee-details/contact/${details.waybillNo}/${details.type}`,
        method: "get",
      })
      .then((response) => {
        if (
          Constants.SUCCESS_HTTP_CODES.indexOf(response.data.status.code) > -1
        ) {
          return dispatch(
            getCustomerContactNumberSuccess(
              response.data,
              details.id,
              details.type
            )
          );
        }
        return dispatch(updateConsignmentPodFailure(response?.data?.status));
      })
      .catch((error) =>
        dispatch(
          getCustomerContactNumberFailure(error, details.id, details.type)
        )
      );
  };
};

const getCustomerContactNumberBegin = () => {
  return {
    type: CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_BEGIN,
  };
};

const getCustomerContactNumberSuccess = (response, id, type) => {
  return {
    type: CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_SUCCESS,
    payload: {
      response,
      id,
      type,
    },
  };
};

const getCustomerContactNumberFailure = (errorMessage, id, type) => {
  return {
    type: CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_FAILURE,
    payload: {
      errorMessage: errorMessage,
      id,
      type,
    },
  };
};
const getCustomerContactNumberFailureReset = () => {
  return {
    type: CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_RESET,
  };
};
