import * as CONSTANTS from "./constants";
import * as actions from "./action";

export const codReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSTANTS.GET_STATE_DETAILS_BEGIN:
      return getStateDetailsBegin(state);
    case CONSTANTS.GET_SETTELMENTS_BEGIN:
      return getSettlementsBegin(state);
    case CONSTANTS.GET_SETTELMENTS_SUCCESS:
      return getSettlementsSuccess(state, action);
    case CONSTANTS.GET_SETTELMENTS_FAILURE:
      return getSettlementsFailure(state);
    case CONSTANTS.GET_ACTIVITY_BEGIN:
      return getActivityBegin(state);
    case CONSTANTS.GET_ACTIVITY_SUCCESS:
      return getActivitySuccess(state, action);
    case CONSTANTS.GET_ACTIVITY_FAILURE:
      return getActivityFailure(state);
    case CONSTANTS.REMIT_TO_USER_BEGIN:
      return remitToUserBegin(state);
    case CONSTANTS.REMIT_TO_USER_SUCCESS:
      return remitToUserSuccess(state, action);
    case CONSTANTS.REMIT_TO_USER_FAILURE:
      return remitToUserFailure(state);
    case CONSTANTS.REPORT_BEGIN:
      return reportBegin(state);
    case CONSTANTS.REPORT_SUCCESS:
      return reportSuccess(state, action);
    case CONSTANTS.REPORT_FAILURE:
      return reportFailure(state);
    case CONSTANTS.SEND_EMAIL_BEGIN:
      return sendEmailBegin(state);
    case CONSTANTS.SEND_EMAIL_SUCCESS:
      return sendEmailSuccess(state, action);
    case CONSTANTS.SEND_EMAIL_FAILURE:
      return sendEmailFailure(state);
    case CONSTANTS.TRANSACTION_BEGIN:
      return transactionBegin(state);
    case CONSTANTS.TRANSACTION_SUCCESS:
      return transactionSuccess(state, action);
    case CONSTANTS.TRANSACTION_FAILURE:
      return transactionFailure(state);
    default:
      return state;
  }
};

function transactionBegin(state) {
  return {
    ...state,
    loading: true
  };
}

function transactionSuccess(state, action) {
  return {
    ...state,
    loading: true,
    transactionStatus: action.payload
  };
}

function transactionFailure(state) {
  return {
    ...state,
    loading: false,
    settlementsDetails: undefined,
    transactionStatus: { sync: false }
  };
}

function getStateDetailsBegin(state) {
  return {
    ...state,
    loading: true,
    metaData: null
  };
}

function getSettlementsBegin(state) {
  return {
    ...state,
    loading: true,
    metaData: null
  };
}

function getSettlementsFailure(state) {
  return {
    ...state,
    loading: false,
    settlementsDetails: undefined,
    metaData: null
  };
}

function getSettlementsSuccess(state, action) {
  return {
    ...state,
    loading: false,
    settlementsDetails: action.payload,
    metaData: null
  };
}

function getActivityBegin(state) {
  return {
    ...state,
    loading: true,
    metaData: null
  };
}

function getActivitySuccess(state, action) {
  return {
    ...state,
    loading: false,
    activityData: action.payload,
    metaData: null
  };
}
function getActivityFailure(state) {
  return {
    ...state,
    loading: false,
    activityData: undefined,
    metaData: null
  };
}

function remitToUserBegin(state) {
  return {
    ...state,
    loading: true,
    metaData: null
  };
}

function remitToUserSuccess(state, action) {
  return {
    ...state,
    loading: false,
    remitInfo: action.payload,
    metaData: null
  };
}
function remitToUserFailure(state) {
  return {
    ...state,
    loading: false,
    remitInfo: undefined,
    metaData: null
  };
}

function reportBegin(state) {
  return {
    ...state,
    loading: true,
    metaData: null
  };
}

function reportSuccess(state, action) {
  return {
    ...state,
    loading: false,
    reportSuccess: action.payload,
    metaData: null
  };
}
function reportFailure(state) {
  return {
    ...state,
    loading: false,
    reportSuccess: undefined,
    metaData: null
  };
}

function sendEmailBegin(state) {
  return {
    ...state,
    loading: true,
    metaData: null
  };
}

function sendEmailSuccess(state, action) {
  return {
    ...state,
    loading: false,
    email: action.payload,
    metaData: null
  };
}
function sendEmailFailure(state) {
  return {
    ...state,
    loading: false,
    email: undefined,
    metaData: null
  };
}
