import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import "./payouts.scss";
import {
  Button,
  Row,
  Col,
  Divider,
  Icon,
  Spin,
  Badge,
  Tag,
  DatePicker,
  Card,
  Pagination
} from "antd";
import moment from "moment";
import { checkHttpStatus, formatCurrency } from "../../../common/utils/utils";
import PaymentStatusService from "../../../services/digital-payment/payment-status.service";
import Passbook from "./screens/passbook";
import DailyEarnings from "./screens/earnings";
import Summary from "./screens/summary";

const paymentService = new PaymentStatusService();
const { MonthPicker } = DatePicker;

class OriginalPayouts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      payoutIds: [],
      loading: true,
      pageNo: 1,
      pageSize: 5,
      loading2: false,
      payment: "paid",
      summaryData: {},
      defaultMonth: moment().format("YYYY") + "/" + moment().format("MM"),
      fromDate: moment()
        .startOf("month")
        .valueOf(),
      endDate: moment()
        .endOf("month")
        .valueOf()
    };
  }

  componentDidMount() {
    this.fetchPaymentData();
    this.getSummary();
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentDidUpdate(prevProps) {}

  fetchPaymentData = (payload = {}) => {
    this.setState(
      {
        loading: true
      },
      () => {
        let request = {
          settledAtDateRange: {
            from: this.state.fromDate,
            to: this.state.endDate
          }
        };

        paymentService.getPaidPayouts(request).then(({ data }) => {
          if (checkHttpStatus(data.status)) {
            this.setState({
              loading: false,
              payoutData: data.response
            });
          } else {
            this.setState({
              loading: false,
              payoutData: {}
            });
          }
        });

        // paymentService.getpayouts(request).then(({ data }) => {
        //     if (checkHttpStatus(data.status)) {
        //         this.setState({
        //             loading: false,
        //             payoutData: data.response
        //         })
        //     } else {
        //         this.setState({
        //             loading: false,
        //             payoutData: {}
        //         })
        //     }
        // }).catch(err => {
        //     this.setState({
        //         loading: false,
        //         payoutData: {}
        //     })
        // })
      }
    );
  };

  fetchPendingPayments = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        let request = {
          dateRange: {
            from: this.state.fromDate,
            to: this.state.endDate
          }
        };

        paymentService.getPendingPayouts(request).then(({ data }) => {
          if (checkHttpStatus(data.status)) {
            this.setState({
              loading: false,
              payoutData: data.response
            });
          } else {
            this.setState({
              loading: false,
              payoutData: {}
            });
          }
        });
      }
    );
  };

  getSummary = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        let request = {
          dateRange: {
            from: this.state.fromDate,
            to: this.state.endDate
          }
        };

        paymentService.getSummary(request).then(({ data }) => {
          if (checkHttpStatus(data.status)) {
            this.setState({
              loading: false,
              summaryData: data.response?.payoutSummaryResponse
            });
          } else {
            this.setState({
              loading: false,
              summaryData: {}
            });
          }
        });
      }
    );
  };

  payoutDetails = id => {
    let payoutId = [...this.state.payoutIds];
    if (payoutId.includes(id)) {
      var index = payoutId.indexOf(id);
      if (index !== -1) {
        payoutId.splice(index, 1);
      }
      this.setState({
        payoutIds: payoutId
      });
      return;
    }
    this.setState(
      {
        loading2: true
      },
      () => {
        paymentService
          .payoutDetailsdata(id)
          .then(({ data }) => {
            payoutId.push(id);
            if (checkHttpStatus(data.status)) {
              this.setState(
                {
                  loading2: false,
                  payoutDetails: data.response
                  // payoutIds: payoutId,
                },
                () => {
                  let findIndex = payoutId.findIndex(p => p !== id);
                  if (findIndex >= 0) {
                    payoutId.splice(findIndex, 1);
                  }
                  this.setState({ payoutIds: payoutId });
                }
              );
            } else {
              this.setState({
                loading2: false,
                payoutDetails: {},
                payoutIds: payoutId
              });
            }
          })
          .catch(err => {
            this.setState({
              loading2: false,
              payoutDetails: {},
              payoutIds: payoutId
            });
          });
      }
    );
  };

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  componentCleanup() {
    // expireCookie(USER_LOGGEDIN_INFO.LOGGED_IN);
    // localStorage.clear();
  }

  getPayment = payment => {
    this.setState({ payment });
    if (payment === "paid") {
      this.fetchPaymentData();
    } else {
      this.fetchPendingPayments();
    }
  };

  changeMonth = date => {
    let defaultMonth =
      moment(date).format("YYYY") + "/" + moment(date).format("MM");
    let fromDate = moment(date)
      .startOf("month")
      .valueOf();
    let endDate = moment(date)
      .endOf("month")
      .valueOf();
    this.setState({ fromDate, endDate, defaultMonth }, () => {
      if (this.state.payment === "paid") {
        this.fetchPaymentData();
      } else {
        this.fetchPendingPayments();
      }
      this.getSummary();
    });
  };

  disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  render() {
    const { pageNo, summaryData } = this.state;
    return (
      <>
        <div className="payoutDashBoard">Payout Details</div>
        {/* <Row className="greyCard">
            <Col xs={1}></Col>
            <Col xs={22} className="whiteCard">
              <Col xs={11}><div className="titlesName">Bank Account Number </div></Col>
              <Col xs={11}><div className="titlesNumber"> {this.state ?.payoutData ?.accountNumberUpiId} </div></Col>
            </Col>
            <Col xs={1}></Col>
          </Row> */}
        <Spin spinning={this.state.loading2}>
          {/* <Card>
            <Row gutter={[12, 12]} style={{ textAlign: 'center' }}>
              <Col span={12}>
                <Button block type="primary">Paid</Button>
              </Col>
              <Col span={12}>
                <Button block type="primary">Pending</Button>
              </Col>
            </Row> */}
          <div className="whiteCard-summary">
            <MonthPicker
              suffixIcon={<Icon type="caret-down" />}
              style={{ width: "100%" }}
              onChange={this.changeMonth}
              allowClear={false}
              disabledDate={this.disabledDate}
              value={moment(this.state.defaultMonth, "YYYY/MM")}
              format={"MMM YYYY"}
            />
          </div>
          <div className="whiteCard-summary">
            {summaryData && (
              <Row>
                <Col xs={24}>
                  <Col xs={12}>
                    <div className="titlesName">Total Monthly Income</div>
                    <span
                      className=""
                      style={{
                        color: "#061c56",
                        fontSize: 20,
                        fontWeight: "bold"
                      }}
                    >
                      {formatCurrency(summaryData?.totalAmount)}
                      {/* {formatCurrency(456078)} */}
                    </span>
                  </Col>
                  <Col xs={12}>
                    <ul className="titlesName">
                      <li style={{ color: "#1164fb" }}>
                        Paid Amount
                        <br />
                        <span className="" style={{ color: "black" }}>
                          {formatCurrency(summaryData?.paidAmount)}
                          {/* {formatCurrency(5460)} */}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} style={{ top: 10 }}>
                    <div className="titlesName">
                      <Tag color="#e7eeff">
                        <span style={{ color: "#061c56" }}>
                          {summaryData?.totalOrders
                            ? summaryData?.totalOrders
                            : 0}{" "}
                          orders delivered
                        </span>{" "}
                      </Tag>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <ul className="titlesName">
                      <li style={{ color: "#f6993f", fontSize: 13 }}>
                        Pending Amount
                        <br />
                        <span className="" style={{ color: "black" }}>
                          {formatCurrency(summaryData?.pendingAmount)}
                          {/* {formatCurrency(5460)} */}
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Col>
              </Row>
            )}
          </div>
          <div className="greyCard2">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <div className="badge-payout">
                  <Badge
                    count={
                      this.state?.payoutData?.feEarningsSettlementBOS?.length
                    }
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  >
                    <Button
                      style={{
                        background:
                          this.state.payment === "paid" ? "#061c56" : "#e5e8ef",
                        color:
                          this.state.payment === "paid" ? "#ffff" : "#78839a"
                      }}
                      size={"large"}
                      block
                      onClick={() => this.getPayment("paid")}
                    >
                      Paid
                    </Button>
                  </Badge>
                </div>
              </Col>
              <Col span={12}>
                <div className="badge-payout">
                  <Badge
                    count={this.state?.payoutData?.pendingPayoutDetails?.length}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  >
                    <Button
                      style={{
                        background:
                          this.state.payment === "pending"
                            ? "#061c56"
                            : "#e5e8ef",
                        color:
                          this.state.payment === "pending" ? "#ffff" : "#78839a"
                      }}
                      size={"large"}
                      block
                      onClick={() => this.getPayment("pending")}
                    >
                      Pending
                    </Button>
                  </Badge>
                </div>
              </Col>
            </Row>
            {this.state.payment === "paid" ? (
              <>
                {this.state?.payoutData?.feEarningsSettlementBOS?.map(
                  (payouts, index) => (
                    <Row key={index}>
                      {/* <Col xs={1}></Col> */}
                      <Col xs={24} className="whiteCard">
                        <Col xs={8}>
                          <div className="titlesName">Payout Date </div>
                        </Col>
                        <Col xs={16}>
                          <div className="titlesNumber">
                            {moment(payouts?.settledAt).format("DD-MMM-YYYY")}
                          </div>
                        </Col>
                        <Col xs={8}>
                          <div className="titlesName">Payout Period </div>
                        </Col>
                        <Col xs={16}>
                          <div className="titlesNumber">
                            {moment(payouts?.payoutPeriodStart).format(
                              "DD-MMM-YYYY"
                            )}{" "}
                            to{" "}
                            {moment(payouts?.payoutPeriodEnd).format(
                              "DD-MMM-YYYY"
                            )}
                          </div>
                        </Col>
                        <Col xs={8}>
                          <div className="titlesName">UTR Number </div>
                        </Col>
                        <Col xs={16}>
                          <div className="titlesNumber">
                            {payouts?.txnNumber}{" "}
                          </div>
                        </Col>
                        <Col xs={8}>
                          <div className="titlesName">Total Amount </div>
                        </Col>
                        <Col xs={16} style={{ marginBottom: 10 }}>
                          <div className="titlesNumber">
                            {formatCurrency(payouts?.amount)}{" "}
                          </div>
                        </Col>
                        <Divider className="divideCenter"></Divider>
                        {this.state?.payoutIds &&
                          this.state?.payoutIds?.includes(payouts?.id) && (
                            <Row>
                              <Col span={10}>
                                {this.state?.payoutDetails?.orderBreakups.map(
                                  (payouts, index) => (
                                    <div key={index}>
                                      <Col span={18}>
                                        <div className="titlesName">
                                          {payouts?.orderBreakupCategory}
                                        </div>
                                      </Col>
                                      <Col span={6}>
                                        <div className="titlesNumber">
                                          {payouts?.noOfOrders}
                                        </div>
                                      </Col>
                                    </div>
                                  )
                                )}
                                {this.state?.payoutDetails && (
                                  <div key="lastOtotalorders">
                                    <Col xs={24}>
                                      <Divider className="divideCenter2"></Divider>
                                    </Col>
                                    <Col span={18}>
                                      <div className="titlesName">
                                        Total Orders
                                      </div>
                                    </Col>
                                    <Col span={6}>
                                      <div className="titlesNumber">
                                        {
                                          this.state?.payoutDetails
                                            ?.totalNoOfOrders
                                        }
                                      </div>
                                    </Col>
                                  </div>
                                )}
                              </Col>
                              <Col span={12}>
                                {this.state?.payoutDetails?.payoutBreakups.map(
                                  (payouts, index) => (
                                    <div key={index}>
                                      <Col span={18}>
                                        <div
                                          className={
                                            "titlesName " +
                                            (Math.sign(payouts?.amount) == -1
                                              ? "redSet"
                                              : "")
                                          }
                                        >
                                          {payouts?.payoutBreakupCategory}
                                        </div>
                                      </Col>
                                      <Col span={6}>
                                        <div
                                          className={
                                            "titlesNumber " +
                                            (Math.sign(payouts?.amount) == -1
                                              ? "redSet"
                                              : "")
                                          }
                                        >
                                          {formatCurrency(
                                            Math.abs(payouts?.amount)
                                          )}
                                        </div>
                                      </Col>
                                    </div>
                                  )
                                )}
                                {this.state?.payoutDetails && (
                                  <div key="lastOtotalpayouts">
                                    <Col xs={24}>
                                      <Divider className="divideCenter2"></Divider>
                                    </Col>
                                    <Col span={18}>
                                      <div className="titlesName">
                                        Total Amount
                                      </div>
                                    </Col>
                                    <Col span={6}>
                                      <div className="titlesNumber">
                                        {formatCurrency(
                                          this.state?.payoutDetails?.totalAmount
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          )}
                        <Col className="buttonBn" span={24}>
                          {this.state?.payoutIds &&
                            !this.state?.payoutIds?.includes(payouts?.id) && (
                              <Button
                                type="primary"
                                onClick={() => this.payoutDetails(payouts?.id)}
                              >
                                More Details <Icon type="plus" />
                              </Button>
                            )}
                          {this.state?.payoutIds &&
                            this.state?.payoutIds?.includes(payouts?.id) && (
                              <Button
                                type="primary"
                                onClick={() => this.payoutDetails(payouts?.id)}
                              >
                                Collapse <Icon type="minus" />
                              </Button>
                            )}
                        </Col>
                      </Col>
                      {/* <Col xs={1}></Col> */}
                    </Row>
                  )
                )}
              </>
            ) : (
              <>
                {this.state?.payoutData?.pendingPayoutDetails?.map(
                  (payouts, index) => (
                    <Row key={index}>
                      <Col xs={24} className="whiteCard">
                        <Col xs={24}>
                          <div className="titlesName">
                            {moment(payouts?.operationDate).format(
                              "DD-MMM-YYYY"
                            )}
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="titlesNumber">
                            {payouts?.totalOrders} orders delivered
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div
                            style={{ bottom: 15, fontSize: 18 }}
                            className="titlesName-right"
                          >
                            {formatCurrency(payouts?.totalEarning)}
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  )
                )}
              </>
            )}

            {/* </Card> */}

            {this.state?.loading && (
              <div className="padding_to_spinner">
                <Spin />
              </div>
            )}
          </div>
          {/* </Card> */}
        </Spin>
      </>
    );
  }
}

class OriginalPayoutsRevamp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      payoutIds: [],
      payoutPendingIds: [],
      loading: false,
      pageNo: 1,
      pageSize: 10,
      loading2: false,
      payment: "pending",
      summaryData: props.data,
      defaultMonth:
        moment(props.monthValue).format("MMM") +
        "/" +
        moment(props.monthValue).format("YYYY")
    };
  }

  componentDidMount() {
    // this.fetchPaymentData();
    // this.getSummary();
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  componentDidUpdate(prevProps) {}

  payoutDetails = id => {
    let payoutId = [...this.state.payoutIds];
    if (payoutId.includes(id)) {
      var index = payoutId.indexOf(id);
      if (index !== -1) {
        payoutId.splice(index, 1);
      }
      this.setState({
        payoutIds: payoutId
      });
      return;
    }
    payoutId.push(id);
    this.setState({ payoutIds: payoutId });
  };

  payoutPendingDetails = id => {
    let payoutPendingIds = [...this.state.payoutPendingIds];
    if (payoutPendingIds.includes(id)) {
      var index = payoutPendingIds.indexOf(id);
      if (index !== -1) {
        payoutPendingIds.splice(index, 1);
      }
      this.setState({
        payoutPendingIds: payoutPendingIds
      });
      return;
    }
    payoutPendingIds.push(id);
    this.setState({ payoutPendingIds: payoutPendingIds });
  };

  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  componentCleanup() {
    // expireCookie(USER_LOGGEDIN_INFO.LOGGED_IN);
    // localStorage.clear();
  }

  getPayment = payment => {
    this.setState({ payment });
  };

  changeMonth = date => {
    let defaultMonth =
      moment(date).format("MMM") + "-" + moment(date).format("YYYY");
    this.setState({ defaultMonth }, () => {
      this.props.fetchWalletSummary &&
        this.props.fetchWalletSummary(defaultMonth);
    });
  };

  disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  render() {
    const { pageNo, summaryData } = this.state;
    const { disablePayment } = this.props;
    return (
      <>
        <div className="payoutDashBoard">Payout Details</div>
        {/* <Row className="greyCard">
            <Col xs={1}></Col>
            <Col xs={22} className="whiteCard">
              <Col xs={11}><div className="titlesName">Bank Account Number </div></Col>
              <Col xs={11}><div className="titlesNumber"> {this.state ?.payoutData ?.accountNumberUpiId} </div></Col>
            </Col>
            <Col xs={1}></Col>
          </Row> */}
        <Spin spinning={this.state.loading2}>
          {/* <Card>
            <Row gutter={[12, 12]} style={{ textAlign: 'center' }}>
              <Col span={12}>
                <Button block type="primary">Paid</Button>
              </Col>
              <Col span={12}>
                <Button block type="primary">Pending</Button>
              </Col>
            </Row> */}
          <div className="whiteCard-summary">
            <MonthPicker
              suffixIcon={<Icon type="caret-down" />}
              style={{ width: "100%" }}
              onChange={this.changeMonth}
              allowClear={false}
              disabledDate={this.disabledDate}
              value={moment(this.state.defaultMonth, "MMM-YYYY")}
              format={"MMMM YYYY"}
            />
          </div>
          <div className="whiteCard-summary">
            {summaryData && (
              <Row>
                <Col xs={24}>
                  <Col xs={12}>
                    <div className="titlesName">Total Monthly Income</div>
                    <span
                      className=""
                      style={{
                        color: "#061c56",
                        fontSize: 20,
                        fontWeight: "bold"
                      }}
                    >
                      {formatCurrency(summaryData?.monthlyIncome)}
                      {/* {formatCurrency(456078)} */}
                    </span>
                  </Col>
                  <Col xs={12}>
                    <ul
                      className="titlesName"
                      style={{
                        visibility: disablePayment ? "hidden" : "visible"
                      }}
                    >
                      <li style={{ color: "#1164fb" }}>
                        {"Paid Amount"}
                        <br />
                        <span className="" style={{ color: "black" }}>
                          {formatCurrency(summaryData?.paidAmount)}
                          {/* {formatCurrency(5460)} */}
                        </span>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} style={{ top: 10 }}>
                    <div className="titlesName">
                      <Tag color="#e7eeff">
                        <span style={{ color: "#061c56" }}>
                          {summaryData?.deliveryText || "-"}
                        </span>
                      </Tag>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <ul
                      className="titlesName"
                      style={{
                        visibility: disablePayment ? "hidden" : "visible"
                      }}
                    >
                      <li style={{ color: "#f6993f", fontSize: 13 }}>
                        {"Pending Amount"}
                        <br />
                        <span className="" style={{ color: "black" }}>
                          {formatCurrency(summaryData?.pendingAmount)}
                          {/* {formatCurrency(5460)} */}
                        </span>
                      </li>
                    </ul>
                  </Col>
                </Col>
              </Row>
            )}
          </div>
          <div className="greyCard2">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <div className="badge-payout">
                  <Badge
                    count={summaryData?.paidItems?.length}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  >
                    <Button
                      style={{
                        background:
                          this.state.payment === "paid" ? "#061c56" : "#e5e8ef",
                        color:
                          this.state.payment === "paid" ? "#ffff" : "#78839a"
                      }}
                      size={"large"}
                      block
                      onClick={() => this.getPayment("paid")}
                      disabled={disablePayment}
                    >
                      Paid
                    </Button>
                  </Badge>
                </div>
              </Col>
              <Col span={12}>
                <div className="badge-payout">
                  <Badge
                    count={summaryData?.pendingItems?.length}
                    style={{
                      backgroundColor: "#fff",
                      color: "#999",
                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                    }}
                  >
                    <Button
                      style={{
                        background:
                          this.state.payment === "pending"
                            ? "#061c56"
                            : "#e5e8ef",
                        color:
                          this.state.payment === "pending" ? "#ffff" : "#78839a"
                      }}
                      size={"large"}
                      block
                      onClick={() => this.getPayment("pending")}
                    >
                      Pending
                    </Button>
                  </Badge>
                </div>
              </Col>
            </Row>
            {this.state.payment === "paid" ? (
              <>
                {summaryData?.paidItems?.map((payouts, index) => (
                  <Row key={index} gutter={12}>
                    {/* <Col xs={1}></Col> */}
                    <Col xs={24} className="whiteCard">
                      <Col xs={8}>
                        <div className="titlesName">Payout Date </div>
                      </Col>
                      <Col xs={16}>
                        <div className="titlesNumber">
                          {moment(payouts?.payoutDate).format("DD-MMM-YYYY")}
                        </div>
                      </Col>
                      <Col xs={8}>
                        <div className="titlesName">Payout Period </div>
                      </Col>
                      <Col xs={16}>
                        <div className="titlesNumber">
                          {payouts?.payoutPeriod || "-"}
                        </div>
                      </Col>
                      <Col xs={8}>
                        <div className="titlesName">UTR Number </div>
                      </Col>
                      <Col xs={16}>
                        <div className="titlesNumber">
                          {payouts?.utrNumber || "-"}
                        </div>
                      </Col>
                      <Col xs={8}>
                        <div className="titlesName">Total Amount </div>
                      </Col>
                      <Col xs={16} style={{ marginBottom: 10 }}>
                        <div className="titlesNumber">
                          {formatCurrency(payouts?.amount)}
                        </div>
                      </Col>
                      <Divider className="divideCenter"></Divider>
                      {this.state?.payoutIds &&
                        this.state?.payoutIds?.includes(payouts?.id) && (
                          <Row>
                            <Col span={10}>
                              {payouts?.orderItems?.lineItems?.map(
                                (orders, index) => (
                                  <div key={index}>
                                    <Col span={18}>
                                      <div className="titlesName">
                                        {orders?.display}
                                      </div>
                                    </Col>
                                    <Col span={6}>
                                      <div className="titlesNumber">
                                        {orders?.amount}
                                      </div>
                                    </Col>
                                  </div>
                                )
                              )}
                              {payouts?.orderItems && (
                                <div key="lastOtotalorders">
                                  <Col xs={24}>
                                    <Divider className="divideCenter2"></Divider>
                                  </Col>
                                  <Col span={18}>
                                    <div className="titlesName">
                                      Total Orders
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div className="titlesNumber">
                                      {formatCurrency(
                                        payouts?.orderItems?.amount
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              )}
                            </Col>
                            <Col span={12}>
                              {payouts?.amountItems?.lineItems?.map(
                                (amount, index) => (
                                  <div key={index}>
                                    <Col span={18}>
                                      <div
                                        className={
                                          "titlesName " +
                                          (Math.sign(amount?.amount) == -1
                                            ? "redSet"
                                            : "")
                                        }
                                      >
                                        {amount?.display}
                                      </div>
                                    </Col>
                                    <Col span={6}>
                                      <div
                                        className={
                                          "titlesNumber " +
                                          (Math.sign(amount?.amount) == -1
                                            ? "redSet"
                                            : "")
                                        }
                                      >
                                        {formatCurrency(
                                          Math.abs(amount?.amount)
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                )
                              )}
                              {payouts.amountItems && (
                                <div key="lastOtotalpayouts">
                                  <Col xs={24}>
                                    <Divider className="divideCenter2"></Divider>
                                  </Col>
                                  <Col span={18}>
                                    <div className="titlesName">
                                      Total Amount
                                    </div>
                                  </Col>
                                  <Col span={6}>
                                    <div className="titlesNumber">
                                      {formatCurrency(
                                        payouts?.amountItems?.amount
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      {payouts?.orderItems?.lineItems?.length &&
                        payouts?.orderItems?.lineItems?.length && (
                          <Col className="buttonBn" span={24}>
                            {this.state?.payoutIds &&
                              !this.state?.payoutIds?.includes(payouts?.id) && (
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    this.payoutDetails(payouts?.id)
                                  }
                                >
                                  More Details <Icon type="plus" />
                                </Button>
                              )}
                            {this.state?.payoutIds &&
                              this.state?.payoutIds?.includes(payouts?.id) && (
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    this.payoutDetails(payouts?.id)
                                  }
                                >
                                  Collapse <Icon type="minus" />
                                </Button>
                              )}
                          </Col>
                        )}
                    </Col>
                    {/* <Col xs={1}></Col> */}
                  </Row>
                ))}
              </>
            ) : (
              <>
                {summaryData?.pendingItems?.map((payouts, index) => (
                  <Row
                    key={index}
                    onClick={() => this.payoutPendingDetails(payouts?.dateText)}
                  >
                    <Col xs={24} className="whiteCard">
                      <Col xs={24}>
                        <div className="titlesName">{payouts?.dateText}</div>
                      </Col>
                      <Col xs={12}>
                        <div className="titlesNumber">
                          {payouts?.deliveryText}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div
                          style={{ bottom: 15, fontSize: 18 }}
                          className="titlesName-right"
                        >
                          {formatCurrency(payouts?.amount)}
                        </div>
                      </Col>
                      {this.state?.payoutPendingIds &&
                        this.state?.payoutPendingIds?.includes(
                          payouts?.dateText
                        ) && (
                          <Col span={24}>
                            {payouts?.lineItems?.map((item, index) => (
                              <div key={index}>
                                <Col span={12}>
                                  <div
                                    className={
                                      Math.sign(item?.amount) == -1
                                        ? "redSet"
                                        : ""
                                    }
                                  >
                                    {item?.description}
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div
                                    className={
                                      "titlesNumber " +
                                      (Math.sign(item?.amount) == -1
                                        ? "redSet"
                                        : "")
                                    }
                                    style={{
                                      textAlign: "right"
                                    }}
                                  >
                                    {!!item.perUnitAmount &&
                                      !!item.totalUnits &&
                                      `${formatCurrency(item.perUnitAmount)}x${
                                        item.totalUnits
                                      }=`}
                                    <b>
                                      {formatCurrency(Math.abs(item?.amount))}
                                    </b>
                                  </div>
                                </Col>
                              </div>
                            ))}
                          </Col>
                        )}
                    </Col>
                  </Row>
                ))}
              </>
            )}

            {/* </Card> */}

            {this.state?.loading && (
              <div className="padding_to_spinner">
                <Spin />
              </div>
            )}
          </div>
          {/* </Card> */}
        </Spin>
      </>
    );
  }
}

class PayoutsRevamp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCard: "",
      loading: false
    };
  }

  render() {
    const { selectedCard } = this.state;
    const { data } = this.props;
    return (
      <div style={{ background: "#e7e7e7", minHeight: "100vh" }}>
        <div
          className="payoutDashBoard payoutHeader"
          style={{
            padding: 12,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <span> Payout details </span>
          <Button
            style={{
              background: "#061c56",
              color: "#ffffff",
              marginTop: 8,
              borderRadius: 6
            }}
            size={"default"}
            onClick={() => {
              this.props.history.push(
                `/appv2/digital-payment/dashboard/rate-card?feuserId=${localStorage.getItem(
                  "feuserId"
                )}&tokenid=${localStorage.getItem(
                  "tokenId"
                )}&token=${localStorage.getItem("accessToken")}`,
                { source: true }
              );
            }}
          >
            Rate Card
          </Button>
        </div>
        <div style={{ padding: 8 }}>
          <Spin spinning={this.state.loading}>
            <Card style={{ borderRadius: 8 }} bodyStyle={{ padding: 12 }}>
              <Row
                gutter={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <Col
                  span={10}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItem: "center",
                    justifyContent: "center"
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "bold" }}>Pending Balance</div>
                    <div
                      style={{
                        color: "#061c56",
                        fontWeight: "bold",
                        fontSize: 22,
                        marginTop: 8,
                        marginBottom: 8
                      }}
                    >
                      {formatCurrency(data?.pendingBalance)}
                    </div>
                    {!!data?.ordersText && (
                      <Tag color="#E5EDFF" style={{ color: "#061c56" }}>
                        {data?.ordersText}
                      </Tag>
                    )}
                  </div>
                </Col>
                <Col
                  span={14}
                  style={{
                    borderLeft: "1px solid #e8e8e8",
                    padding: "0 16px"
                  }}
                >
                  {!!data?.todayBreakup &&
                    data?.todayBreakup.map((item, index) => (
                      <Row style={{ marginTop: index === 0 ? 0 : 2 }}>
                        <Col
                          span={14}
                          style={{ color: "#1e2933", fontSize: 12 }}
                        >
                          {item?.display}
                        </Col>
                        <Col
                          span={10}
                          style={{
                            color: "#061c56",
                            fontSize: 12,
                            fontWeight: "bold"
                          }}
                        >
                          :{" "}
                          {item?.display === "No of Orders"
                            ? item?.amount
                            : formatCurrency(item?.amount)}
                        </Col>
                      </Row>
                    ))}
                </Col>
              </Row>
            </Card>

            {data?.lineItems?.map((item, index) => {
              return (
                <Card
                  style={{ borderRadius: 8, marginTop: 8 }}
                  bodyStyle={{ padding: 12 }}
                  onClick={() => this.setState({ selectedCard: index })}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <div>
                      <div
                        style={{
                          color: "#2b2b2b",
                          fontWeight: "bold",
                          fontSize: 16
                        }}
                      >
                        {item?.weekName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            color: "#2b2b2b",
                            fontSize: 12
                          }}
                        >
                          {item?.description}
                        </div>
                        {!!item?.paymentText && (
                          <Tag
                            color={"#fff"}
                            style={{
                              marginLeft: 5,
                              backgroundColor: !!item?.payment
                                ? "#E2FDF2"
                                : "#FFF4EB",
                              color: !item?.payment ? "#f07305" : "#0aa061",
                              fontSize: 10
                            }}
                          >
                            {item?.paymentText}
                          </Tag>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end"
                      }}
                    >
                      <div
                        style={{
                          color: "#061c56",
                          fontWeight: "bold",
                          fontSize: 16,
                          textAlign: "right"
                        }}
                      >
                        {formatCurrency(item?.amount)}
                      </div>
                      <div
                        style={{
                          color: "#2b2b2b",
                          textAlign: "right",
                          fontSize: 12
                        }}
                      >
                        {item?.orderCount}
                      </div>
                    </div>
                  </div>
                  {index === selectedCard && (
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          backgroundColor: "#f1f3f6",
                          borderRadius: 8,
                          padding: "8px 16px",
                          marginTop: 8
                        }}
                      >
                        {item?.weekComponents?.map(paymentItem => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: 0
                                }}
                              >
                                {paymentItem?.display}
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold"
                                }}
                              >
                                {formatCurrency(paymentItem?.amount)}
                              </div>
                            </div>
                            {!!paymentItem.lineItems?.length &&
                              paymentItem.lineItems?.map(innerItem => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "normal",
                                      marginLeft: 10
                                    }}
                                  >
                                    {innerItem?.display}
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "normal"
                                    }}
                                  >
                                    {formatCurrency(innerItem?.amount)}
                                  </div>
                                </div>
                              ))}
                          </>
                        ))}
                      </div>
                      <Button
                        style={{
                          background: "#061c56",
                          color: "#ffffff",
                          width: 184,
                          marginTop: 8,
                          borderRadius: 6
                        }}
                        size={"default"}
                        onClick={() => {
                          this.props.openDetails(item?.weekSundayDate);
                        }}
                      >
                        More Details +
                      </Button>
                    </div>
                  )}
                </Card>
              );
            })}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 10
              }}
            >
              <Pagination
                {...{
                  onChange: this.props.onPageChange,
                  total: data?.totalCount,
                  position: "bottom",
                  current: data?.currentPageNo,
                  pageSize: data?.currentPageSize || 5
                }}
              />
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}
class PayoutDetailRevamp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      summaryData: {}
    };
  }

  componentDidMount() {
    this.fetchWeeklyDetailSummary();
  }

  fetchWeeklyDetailSummary = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        paymentService
          .getWeeklyPayoutDetailSummary(this.props.data)
          .then(resp => {
            if (resp?.data?.response) {
              const { response } = resp?.data;
              const { showNewEarningsScreen, ...summaryInfo } = response;
              this.setState({
                loading: false,
                summaryData: summaryInfo
              });
            } else {
              this.setState({
                loading: false
              });
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({
              loading: false
            });
          });
      }
    );
  };

  render() {
    const { selectedCard, summaryData, loading } = this.state;
    if (loading) {
      return (
        <div
          className="payout-summary"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spin />
        </div>
      );
    }
    return (
      <div style={{ background: "#e7e7e7", minHeight: "100vh" }}>
        <div className="payoutDashBoard payoutHeader" style={{ padding: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center"
              }}
            >
              <Icon
                onClick={() => this.props.closeDetails("")}
                style={{ marginRight: 8 }}
                type="arrow-left"
              />
              <div>
                <div
                  style={{
                    color: "#2b2b2b",
                    fontWeight: "bold",
                    fontSize: 14,
                    letterSpacing: "normal"
                  }}
                >
                  {summaryData?.weekName}
                </div>
                <div
                  style={{
                    color: "#2b2b2b",
                    fontSize: 12,
                    letterSpacing: "normal"
                  }}
                >
                  {summaryData?.description}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                textAlign: "right"
              }}
            >
              {!!summaryData?.paymentText && (
                <Tag
                  color={"#fff"}
                  style={{
                    backgroundColor: !!summaryData?.payment
                      ? "#E2FDF2"
                      : "#FFF4EB",
                    color: !summaryData?.payment ? "#f07305" : "#0aa061",
                    fontSize: 10,
                    marginRight: 0,
                    marginBottom: 4,
                    letterSpacing: "normal"
                  }}
                >
                  {summaryData?.paymentText}
                </Tag>
              )}
              <div
                style={{
                  color: "#2b2b2b",
                  textAlign: "right",
                  fontSize: 12
                }}
              >
                {summaryData?.paymentRefNo}
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: 8 }}>
          <Spin spinning={this.state.loading}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Card
                style={{ borderRadius: 8, minWidth: "32%" }}
                bodyStyle={{
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{ color: "#1e2933", fontSize: 12, marginBottom: 8 }}
                >
                  Total Earnings
                </div>
                <div
                  style={{ color: "#061c56", fontWeight: "bold", fontSize: 16 }}
                >
                  {formatCurrency(summaryData?.totalEarnings)}
                </div>
              </Card>
              <Card
                style={{ borderRadius: 8, minWidth: "32%" }}
                bodyStyle={{
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{ color: "#1e2933", fontSize: 12, marginBottom: 8 }}
                >
                  Order Earnings
                </div>
                <div
                  style={{ color: "#061c56", fontWeight: "bold", fontSize: 16 }}
                >
                  {formatCurrency(summaryData?.orderEarnings)}
                </div>
              </Card>
              <Card
                style={{ borderRadius: 8, minWidth: "32%" }}
                bodyStyle={{
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{ color: "#1e2933", fontSize: 12, marginBottom: 8 }}
                >
                  Bonus
                </div>
                <div
                  style={{ color: "#061c56", fontWeight: "bold", fontSize: 16 }}
                >
                  {formatCurrency(summaryData?.bonus)}
                </div>
              </Card>
            </div>

            {summaryData?.lineItems?.map((item, index) => {
              return (
                <Card
                  style={{ borderRadius: 8, marginTop: 8 }}
                  bodyStyle={{ padding: 12 }}
                  onClick={() => this.setState({ selectedCard: index })}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <div
                      style={{
                        color: "#2b2b2b",
                        fontWeight: "bold",
                        fontSize: 16
                      }}
                    >
                      {item?.dayText}
                    </div>
                    <div>
                      <div
                        style={{
                          color: "#061c56",
                          fontWeight: "bold",
                          fontSize: 16,
                          textAlign: "right"
                        }}
                      >
                        {formatCurrency(item?.amount)}
                      </div>
                      <div
                        style={{
                          color: "#2b2b2b",
                          textAlign: "right",
                          fontSize: 12
                        }}
                      >
                        {item?.orderCount}
                      </div>
                    </div>
                  </div>
                  {index === selectedCard && (
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          backgroundColor: "#f1f3f6",
                          borderRadius: 8,
                          padding: "8px 16px",
                          marginTop: 8
                        }}
                      >
                        {item?.dayComponents?.map(paymentItem => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between"
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginLeft: 0
                                }}
                              >
                                {paymentItem?.display}
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold"
                                }}
                              >
                                {formatCurrency(paymentItem?.amount)}
                              </div>
                            </div>
                            {!!paymentItem.lineItems?.length &&
                              paymentItem.lineItems?.map(innerItem => (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "normal",
                                      marginLeft: 10
                                    }}
                                  >
                                    {innerItem?.display}
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "normal"
                                    }}
                                  >
                                    {formatCurrency(innerItem?.amount)}
                                  </div>
                                </div>
                              ))}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </Card>
              );
            })}
          </Spin>
        </div>
      </div>
    );
  }
}

class Payouts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPassbookPageActive: false,
      isDailyPageActive: false,
      isDetailedPageActive: false,
      loading: false,
      showNewScreen: false,
      showWeeklyScreen: true,
      disablePayment: false,
      summaryData: {},
      monthValue: moment(),
      selectedWeek: ""
    };
  }

  componentDidMount() {
    this.fetchWeeklySummary();
  }

  componentDidUpdate(prevProps) {}

  toggleOldView = isActive => {
    this.setState({
      showNewScreen: isActive
    });
  };

  openPassbook = () => {
    this.setState({
      isPassbookPageActive: true,
      isDailyPageActive: false
    });
  };

  openDailyEarnings = () => {
    this.setState({
      isPassbookPageActive: false,
      isDailyPageActive: true
    });
  };

  closeAllPage = () => {
    this.setState({
      isPassbookPageActive: false,
      isDailyPageActive: false
    });
  };

  fetchWeeklySummary = page => {
    this.setState(
      {
        loading: true
      },
      () => {
        paymentService
          .getWeeklyPayoutSummary({
            currentPageNo: page || 1,
            currentPageSize: 5
          })
          .then(resp => {
            if (resp?.data?.response) {
              const { response } = resp?.data;
              const { showNewWeeklyEarningsScreen, ...summaryInfo } = response;
              this.setState(
                {
                  loading: false,
                  showWeeklyScreen: showNewWeeklyEarningsScreen,
                  summaryData: summaryInfo
                },
                () => {
                  !showNewWeeklyEarningsScreen &&
                    this.fetchWalletSummary(moment().format("MMM-YYYY"));
                }
              );
            } else {
              this.setState(
                {
                  loading: true,
                  showWeeklyScreen: false
                },
                () => {
                  this.fetchWalletSummary(moment().format("MMM-YYYY"));
                }
              );
            }
          })
          .catch(err => {
            console.error(err);
            this.setState(
              {
                loading: true,
                showWeeklyScreen: false
              },
              () => {
                this.fetchWalletSummary(moment().format("MMM-YYYY"));
              }
            );
          });
      }
    );
  };

  fetchWalletSummary = date => {
    this.setState(
      {
        loading: true,
        monthValue: moment(date)
      },
      () => {
        paymentService
          .getPayoutSummary(date)
          .then(resp => {
            if (resp?.data?.response) {
              const { response } = resp?.data;
              const {
                showNewEarningsScreen,
                disablePayment,
                ...summaryInfo
              } = response;
              this.setState({
                loading: false,
                showNewScreen: showNewEarningsScreen,
                summaryData: summaryInfo,
                disablePayment
              });
            } else {
              this.setState({
                loading: false,
                showNewScreen: false
              });
            }
          })
          .catch(err => {
            console.error(err);
            this.setState({
              loading: false,
              showNewScreen: false
            });
          });
      }
    );
  };

  openDetails = data => {
    this.setState({
      isDetailedPageActive: !this.state.isDetailedPageActive,
      selectedWeek: data
    });
  };

  render() {
    const {
      isPassbookPageActive,
      isDailyPageActive,
      isDetailedPageActive,
      showNewScreen,
      showWeeklyScreen,
      loading,
      summaryData,
      disablePayment,
      monthValue,
      selectedWeek
    } = this.state;
    if (loading) {
      return (
        <div
          className="payout-summary"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spin />
        </div>
      );
    }
    if (isPassbookPageActive) {
      return <Passbook closeAllPage={this.closeAllPage} />;
    }
    if (isDailyPageActive) {
      return <DailyEarnings closeAllPage={this.closeAllPage} />;
    }
    if (showWeeklyScreen) {
      return !isDetailedPageActive ? (
        <PayoutsRevamp
          data={summaryData}
          openDetails={this.openDetails}
          history={this.props.history}
          onPageChange={page => this.fetchWeeklySummary(page)}
        />
      ) : (
        <PayoutDetailRevamp
          data={selectedWeek}
          closeDetails={this.openDetails}
        />
      );
    } else {
      return showNewScreen ? (
        <OriginalPayoutsRevamp
          fetchWalletSummary={this.fetchWalletSummary}
          data={summaryData}
          disablePayment={disablePayment}
          monthValue={monthValue}
        />
      ) : (
        <OriginalPayouts />
      );
    }
  }
}

// <Summary
//   data={summaryData}
//   openPassbook={this.openPassbook}
//   openDailyEarnings={this.openDailyEarnings}
//   toggleOldView={this.toggleOldView}
// />
export default withRouter(Payouts);
