import React, { Component } from "react";
import { Row, Col, Icon, Button, Popover } from "antd";
import { connect } from "react-redux";
import Stepper from "./Stepper"
import * as utils from "../../../common/utils/utils";
import { getRoutDate } from "../action";
import MapRoute from "../../maps/MapRoute";
import "./RouteTracking.scss";
import img from "../../../assets/img/spinner.svg";

class RouteTracking extends Component {
  constructor(props) {
    super(props);
    this.state = { copySuccess: "" }
    this.copyArea = React.createRef();
  }
  
  componentDidMount() {    
    let activePath = this.props.location.pathname;    
    if(activePath){
      let pathValue = activePath.split('/');
      this.props.dispatch(getRoutDate(pathValue[pathValue.length -1]));
    }
  }

  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.copyArea.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copySuccess: 'Copied!' }, ()=>{
      setTimeout(() => {
        this.setState({copySuccess: ''});
      }, 1000)
    });
  };

  getNextStop = (data) => {
      let nextStop;
        data.some(x => {
          let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
          if(x.status === "PENDING") {
            return nextStop = x[setConsignMent]['name'];
          }
        })
      return nextStop;
  }

  reloadApp = () => {
    window.location.reload();
  }

  vehicleTracking = () => {
    this.props.history.push(`/appv2/tracking/dashboard/vehicle-tracking`)
  }

  render() {
      const {routingData } = this.props;
      let nextStop;
      let content;
      if(routingData){
        nextStop = this.getNextStop(routingData.thcRouteMappingBOS);
        content = (
          <div>
            <p>Name: <b>{routingData.vehicleTrackerBO.assetBO.driverName.toLowerCase()}</b></p>
            <p>Contact: <b><span className="lsn-textarea" ref={this.copyArea}>{routingData.vehicleTrackerBO.assetBO.contactNumber} </span></b><Icon onClick={this.copyToClipboard} type="copy" /><span className="grren">{this.state.copySuccess}</span></p> 
          </div>
        );
      }
      let isShowLoader = !routingData;
      if(isShowLoader) {
        return (<Row><div className="noData"><img className="loading" src={img} alt="Loading"/></div></Row>)
      }
      
    return (
        <>
        <Row>
            <Col xs={14}>
                <Row>
                  {routingData && <div className="arrowLeft"><Icon type="arrow-left" onClick={this.vehicleTracking} /></div>}
                  {routingData &&<div className="vehicleTitle"> {routingData.vehicleTrackerBO.assetBO.vehicleNumber.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</div>}
                  {routingData && <div className="vehicleDes">● ETA : {utils.dateFormat(routingData.vehicleTrackerBO.startTime)}</div>}
                </Row>
                <Row className="pt_20">
                    <Col className="gutter-row" span={24}>
                        <div className="gutter-box">
                        {routingData &&<div><MapRoute routing={routingData}/></div>} 
                        </div>
                    </Col> 
                </Row>
            </Col>
            <Col xs={1}></Col>
            <Col xs={9}>
            {routingData &&
            <>
            <div className="buttongroup">
              <Popover content={content} placement="leftTop" title="Driver Info" trigger="click">
              <Button className="topBt" icon="contacts">Driver Info</Button>
              </Popover>
              {/* <Button className="topBt" icon="share-alt">Share</Button> */}
              <Button className="topBt" icon="reload" onClick={this.reloadApp}>Sync</Button>
            </div>
            <div className="route-card">
                <div className="Route-Activity">Route Activity</div>
                <div className="divider"></div>
                <div className="tripdetails">
                  <div className="detail_box">
                    <div className="start-time">Start Time</div>
                    <div className="start_date">{utils.dateFormat(routingData.vehicleTrackerBO.startTime)}</div>
                  </div>
                  {nextStop &&
                  <div className="detail_box br-l">
                    <div className="start-time">Next Stop</div>
                    <div className="start_date truncate">{nextStop}</div>
                  </div>
                  }
                  {!nextStop &&
                  <div className="detail_box br-l">
                    <div className="start-time">Reached</div>
                    <div className="start_date truncate">{routingData.thcRouteMappingBOS[routingData.thcRouteMappingBOS.length -1]['locationBO']['name']}</div>
                  </div>
                  }
                  <div className="detail_box br-l">
                    <div className="start-time">Last Updated</div>
                    <div className="start_date truncate">{utils.dateDiffFormat(routingData.vehicleTrackerBO.updatedAt)}</div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="tripdetails2">
                  <div className="blink_me"></div>
                  <div className="current_location">{routingData.vehicleTrackerBO.location}</div>
                </div>
                <div className="divider"></div>
                <div className="stepper">
                  {routingData &&
                    <Stepper tripData={routingData}></Stepper>
                  }
                </div>
            </div>
            </>}
            </Col>
        </Row>
        </>
    );
  }
}
const mapStateToProps = state => ({
    routingData: state.tracking.routeData,
});

export default connect(mapStateToProps)(RouteTracking);
