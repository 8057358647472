import React, { PureComponent } from 'react';
import * as actions from './actions';
import './dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Tooltip } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect} from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import LsnFilters from './LsnFilters.jsx';
import { Constants } from '../../../common/utils/constants';
import { Mixpanel } from '../../../utils/mixpanel';


const { confirm } = Modal;

class LocationDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      filterVisible: false,
      locationPermission: {
        read: canAllow(permissionTags.settings.location.read),
        update: canAllow(permissionTags.settings.location.update),
        create: canAllow(permissionTags.settings.location.create),
        delete: canAllow(permissionTags.settings.location.delete),
        view: canAllow(permissionTags.settings.location.view),
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          status: true,
          fetchObjects: ["ADDRESSES", "PINCODES", "CITIES", "PARTNER", "CUSTOMER", "VENDOR", "SERVICEABLE_AREAS", "CUSTOMER_PROMISE_TIME_RULE"]
        }
      }
    }
    this.InputSearchRef = React.createRef();
    if (!this.state.locationPermission.read) {
      windowRedirect('settings/dashboard/location');
    }
  }

  componentDidMount() {
    const user = JSON.parse(atob(localStorage.getItem('user')));
    const loggedPartnerId = localStorage.getItem('loggedPartnerId');
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
        $first_name: user?.name,
        $email: user?.email,
        $phone_number: user?.number,
        $partner_id: user?.partner?.id,
        $partner_name: user?.partner?.name,
    });
    Mixpanel.track("Location Page Initiated", { 'ERPLocationIntiatted': moment().valueOf() })
    this.setState(state => ({
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          width: 150,
          key: 'name',
        },
        {
          title: 'Owners / Owner Types',
          dataIndex: 'entityType',
          width: 200,
        render: (data, row) => (<Row>{row ?.entity ?.accountType === "PARTNER" && Constants.PARTNER_ID === row ?.entity ?.id && <Col span={24}>Self</Col>}{Constants.PARTNER_ID !== row ?.entity ?.id  && <Col span={24}>{row ?.entity ?.custObject ?.name}</Col>}<Col span={24}><small><i>{data} <span>{state.locationPermission.update && ( (row?.entityType === 'CUSTOMER' ) && row?.entity?.custObject?.customerType === 'ADOHC_CUSTOMER') && '(Walk In Customer)'}</span></i></small></Col></Row>)
        },
        {
          title: 'Roles',
          dataIndex: 'roles',
          key: 'roles',
          render: (data, row) => (<div>{data ?.join(', ')}</div>)
        },
        {
          title: 'City',
          dataIndex: 'address',
          key: 'cityname',
          render: (data, row) => (<div>{data ?.pincode ?.city ?.name}</div>)
        },
        {
            title: 'Pincode',
            dataIndex: 'address',
            key: 'pincode',
            render: (data, row) => (<div>{data ?.pincode ?.zipcode} </div>)
          },
        {
          title: 'Created On',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: data => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>)
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'action',
          render: (data, row) => (<Row>
            {
              state.locationPermission.update && ( (row?.entityType === 'CUSTOMER' || row?.entityType === 'PARTNER' || row?.entityType === 'VENDOR' ) && row?.entity?.custObject?.customerType !== 'ADOHC_CUSTOMER') && <Col span={12}><Tooltip placement="top" title={'Edit'}>
                <Icon type="edit" onClick={() => 
                {
                  Mixpanel.track("Location Page Edit Clicked", { 'ERPLocationEditClick': moment().valueOf(), location_edit_id: row.id });
                  this.props.history.push(`/appv2/settings/form/location/${row.id}`)} 
                }
                  /></Tooltip>
              </Col>
            }
            {
              state.locationPermission.update && ( (row?.entityType === 'CUSTOMER' || row?.entityType === 'PARTNER' || row?.entityType === 'VENDOR' ) && row?.entity?.custObject?.customerType === 'ADOHC_CUSTOMER') && <Col span={12}><Tooltip placement="top" title={'Walk In Location'}>
                <Icon type="edit" style={{opacity: '0.5'}} /></Tooltip>
              </Col>
            }
            {
              (!state.locationPermission.update && state.locationPermission.view) && <Col span={12}><Tooltip placement="top" title={'View'}>
                <Icon type="eye" onClick={() => 
                {
                  Mixpanel.track("Location Page View Clicked", { 'ERPLocationViewClick': moment().valueOf(), location_view_id: row.id });
                  this.props.history.push(`/appv2/settings/form/location/${row.id}`)} 
                }
                /></Tooltip>
              </Col>
            }
            {
              state.locationPermission.delete && <Col span={12}><Tooltip placement="top" title={'Delete'}>
                <Icon type="delete" onClick={() =>{                  
                  Mixpanel.track("Location Page delete Clicked", { 'ERPLocationDeleteClick': moment().valueOf(), location_delete_id: row.id });
                 this.showConfirm(row.id)} }/>
                </Tooltip>
                </Col>
            }
          </Row >)
        },
      ]
    }));
    if (!this.props ?.locationsData ?.locations){
      this.getPaginatedData(this.state.pageNo);
    }
  }

  componentDidUpdate(prevProps) {
    const { PaginatedLocations, locationsData } = this.props;
    if (PaginatedLocations !== prevProps.PaginatedLocations) {
      this.setState({
        PaginatedLocations: PaginatedLocations[locationsData.currentPageNo],
      })
    }
  }

  componentWillMount(){
    this.props.fetchLocation({ filters: { ...this.state.filterPayload.filters } }, true);
  };

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }

    Mixpanel.track("Location Page Searched", { 'ERPLocationSearchLocation': moment().valueOf() })


    this.props.fetchLocation({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
    this.setState({partialContentSearch : filters.partialContentSearch})
  }

  toTitleCase = (str) =>{
      return str.replace(
          /\w\S*/g,
          function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
  }

  showConfirm = (selectedLocation) => {
    confirm({
      title: 'Are you sure?',
      content: 'Do you want to delete this Location!!!',
      onOk() {
        this.props.deleteLocation(selectedLocation, { filters: { ...this.state.filterPayload.filters } })
      },
      onCancel() { },
    });
  }

  reloadLocationList = () => {
    this.setState({
      pageNo: 1,
      selectedValues : {}
    })
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedLocations} = this.props;
    if (!PaginatedLocations ? !PaginatedLocations : !PaginatedLocations[pageNo]) {
      let filter = {
        pageNo: pageNo
      };
      if(this.state ?.selectedValues ?.entityType) {
        filter['entityTypes'] = [this.state ?.selectedValues ?.entityType]
      }
  
      if(this.state ?.selectedValues ?.entityIds && this.state ?.selectedValues ?.entityIds.length > 0) {
        filter['entityIds'] = this.state ?.selectedValues ?.entityIds
      }

      if(this.state ?.partialContentSearch){
        filter['partialContentSearch']= this.state ?.partialContentSearch;
      }
      this.applyPagination(filter);
    }
    this.setState({
      pageNo
    });
  }

  showFilterModal = () => {
    const { PaginatedLocations } = this.props;
    // let locationDataFilter = this.getLocationList(PaginatedLocations[this.state.pageNo]);
    this.setState({
      filterVisible: true,
    });
  };

  submitHandler = (selectboxData) => {
    Mixpanel.track("Location Page Filters Applied", { 'ERPLocationFilterInitiated': moment().valueOf() });

      let filters = {
        pageNo: 1
      }
      if(selectboxData ?.entityType) {
        filters["entityTypes"] = [selectboxData.entityType]
      }

      if(selectboxData ?.entityIds && selectboxData ?.entityIds.length > 0) {
        filters["entityIds"] = selectboxData.entityIds
      }
    this.props.fetchLocation({ filters: { ...this.state.filterPayload.filters, ...filters } }, true);
    this.setState({
      filterVisible: false,
      selectedValues : selectboxData,
      pageNo: 1
    });
  }

  closeModal = () => {
    this.setState({
      filterVisible: false,
    });
  }


  // getLocationList = (PaginatedLocations) => {
  //   let locationData = [];
  //   PaginatedLocations.map((value, index) => {
  //     let locationObject = {
  //       'key': index,
  //       'entityId' : value.entity.custObject.id,
  //       'entityName' : value.entity.custObject.name,
  //       'entityType' : value.entityType,
  //     };
  //     locationData.push(locationObject);
  //   })
  //   return locationData;
  // }

  render() {
    const { columns, locationPermission, pageNo, filterVisible } = this.state;
    const { PaginatedLocations, locationsData } = this.props;
    const actionBtns = (
      <Row>
        <Col span={locationPermission.create ? 11 : 19}>
          <Input.Search size={'large'} placeholder="Search" allowClear ref={this.InputSearchRef} onSearch={value => this.applyPagination({ partialContentSearch: value.trim() }, true)} enterButton />
        </Col>
        <Col span={2} style={{ marginLeft: 1 }}>
          <Tooltip placement="top" title={'Reload Location List'}>
            <Button block size={'large'} onClick={this.reloadLocationList} type="primary">
              <Icon type="reload" />
            </Button>
          </Tooltip>
        </Col>
        {
          locationPermission.create && <Col span={8} style={{ marginLeft: 2 }}>
            <Button block size={'large'} onClick={() =>{ 
              Mixpanel.track("Location Page Created Clicked", { 'ERPLocationCreateClick': moment().valueOf() });
              this.props.history.push(`/appv2/settings/form/location`)}} type="primary">CREATE LOCATION</Button>
          </Col>
        }
        <Col span={2} style={{ marginLeft: 2 }}>
          <Tooltip placement="top" title={'Filter List'}>
            <Button block size={'large'} onClick={this.showFilterModal} type="primary">
              <Icon type="filter" />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
    return (
      <>
        <Card className="CustomerDashBoard filterbox" title={<h2>Location List</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            loading={locationsData ?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: locationsData ?.totalCount,
              position: 'bottom',
              current: pageNo,
              pageSize: locationsData ?.currentPageSize
          }}
            dataSource={PaginatedLocations ? PaginatedLocations[this.state.pageNo] : []}
            columns={columns} />
            <Row>
            {filterVisible &&
                  <LsnFilters
                    title="Apply Filters"
                    visible={this.state.filterVisible}
                    cancelModal={this.closeModal}
                    handleSubmit = {this.submitHandler}
                  >
                  </LsnFilters>
            }
          </Row>
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  locationsData: state.settingLocation,
  PaginatedLocations: state.settingLocation ?.PaginatedLocations
});
const mapDispatchToProps = dispatch => ({
  fetchLocation: (param, isReset = false) => dispatch(actions.fetchLocation(param, isReset)),
  deleteLocation: (id, payload) => dispatch(actions.deleteLocation(id, payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LocationDashboard);