import * as CONSTANTS from './constants';
import { CONTACT_NUMBER } from '../../common/utils/constants';

const initialState = {
    defaultAccordianActiveKey: 0
}
export const trackingPopupReducer = (state = initialState, action) => {
    switch (action.type) {

        case CONSTANTS.UPDATE_CONSIGNMENT_POD_SUCCESS:
            return {
                ...state,
                consignmentPodUpdate: true
            }
        case CONSTANTS.UPDATE_CONSIGNMENT_POD_FAILURE:
            return {
                ...state,
                consignmentPodUpdate: false,
                errorMessage: action.payload,
            }
        case CONSTANTS.RESET_NOTIFICATION_DATA:
            return {
                ...state,
                consignmentPodUpdate: undefined,
                errorMessage: ''
            }
        case CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_SUCCESS:
            return {
                ...state,
                primary: action.payload.type === CONTACT_NUMBER.PRIMARY ? action.payload.response.response.contactNumber : state ?.primary,
                secondary: action.payload.type === CONTACT_NUMBER.SECONDARY ? action.payload.response.response.secondaryContactNumber : state ?.secondary,
                defaultAccordianActiveKey: action.payload.id
            }
        case CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_FAILURE:
            return {
                ...state,
                primary: action.payload.type === CONTACT_NUMBER.PRIMARY ? 'NA' : null,
                secondary: action.payload.type === CONTACT_NUMBER.SECONDARY ? 'NA' : null,
            }
        case CONSTANTS.GET_CUSTOMER_CONTACT_NUMBER_RESET:
            return {
                ...state,
                primary: null,
                secondary: null,
            }
        default: return state;
    }
}