import React, { Component } from "react";
import {
  accordian as Accordian,
  Panel,
} from "../../components/ui/accordian/Accordian";
import { typography as Typography } from "../../components/ui/typography/Typography";
import { table as Table } from "../../components/ui/table/Table";
import { TrackingFooter } from "./TrackingFooter/TrackingFooter";
import { connect } from "react-redux";
import * as actions from "./action";
import { expandedRow as ExpandedRow } from "./ExpandedRow/ExpandedRow";
import {
  formatDate,
  formatTime,
  notifyApiError,
  notifyApiSuccess,
  openWindowWithData,
  formatDateoption,
} from "../../common/utils/utils";
import { map as Map } from "../../components/ui/map/Map";
import { Constants, CONTACT_NUMBER } from "../../common/utils/constants";
import { icon as Icon } from "../../components/ui/icon/Icon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { openNotification } from "../ui/notification/Notification";
/* Style imports */
import "./TrackingPopup.scss";
import { Divider, Button } from "antd";

// used to style accordian container
const accordianStyle = {
  marginBottom: "10px",
};
const TRACKING_EVENTS = {
  BOOKING: "BOOKING",
  INSCAN: "INSCAN",
  DRS_SCAN: "DRS_SCAN",
  DELIVERED: "DELIVERED",
  UNDELIVERED: "UNDELIVERED",
  RESCHEDULED: "RESCHEDULED",
  INWARD_SCAN: "INWARD_SCAN",
  BOOKING_RECEIVED: "BOOKING_RECEIVED",
  THIRD_PARTY_SYNC_EVENT: "THIRD_PARTY_SYNC_EVENT",
  ARRIVED_AT_LOCATION: "ARRIVED_AT_LOCATION",
  CALL_RECORD: "CALL_RECORD",
  POD_STREAM: "POD_STREAM",
  PICKUP_CREATED: "PICKUP_CREATED",
  OUT_FOR_PICKUP: "OUT_FOR_PICKUP",
  PICKUP_ASSIGNED: "PICKUP_ASSIGNED",
  UPDATED_STATUS: "UPDATED_STATUS",
  MANIFEST_SCAN: "MANIFEST_SCAN",
  THC_SCAN: "THC_SCAN",
  PICKUP_CANCELLED: "PICKUP_CANCELLED",
  PICKED_UP: "PICKED_UP",
  UPDATED: "UPDATED",
  MANIFEST_POD: "MANIFEST_POD",
  RTO_IN: "RTO_IN",
  RTO_DRS_SCAN: "RTO_DRS_SCAN",
  UNDELIVERED_B2B: "UNDELIVERED_B2B",
  RETURN_B2B: "RETURN_B2B",
};
class TrackingPopup extends Component {
  state = {
    prepareAccordianData: true,
    prepareExpandedRowData: true,
    copied: false,
    columns: [
      {
        title: "DATE",
        dataIndex: "eventTime",
        render: (text) => formatDate(text),
      },
      {
        title: "TIME",
        dataIndex: "eventTime",
        className: "greyed-out",
        render: (text) => formatTime(text),
      },
      {
        title: "EVENT",
        dataIndex: "eventType",
        render: (text, row) => {
          return (
            <div>
              {text}{" "}
              {row.isSuspicious ? (
                <Icon type="warning" className="icon-warning" theme="filled" />
              ) : null}
            </div>
          );
        },
      },
      {
        title: "LOCATION",
        className: "greyed-out",
        dataIndex: "user.location.name",
      },
      {
        title: "USER",
        dataIndex: "user.name",
      },
      {
        title: "ACTION",
        dataIndex: "action",
        render: (a, row) => {
          let text = "View";
          if (
            row?.data?.attributes?.latitude &&
            row?.data?.attributes?.longitude
          ) {
            text = "View Map";
          }
          return (
            <a href="javascript:;" className="expand-link">
              {text}
            </a>
          );
        },
      },
    ],
    accordianData: [],
    tableData: [],
  };
  componentDidMount() {
    this.props.getTrackingDetails({
      data: this.props.getDetailInput,
      source: this.props.source,
    });
  }
  componentDidUpdate(prevProps) {
    if (
      this.state.prepareAccordianData &&
      this.props.trackingDetails !== prevProps.trackingDetails
    ) {
      this.prepareAccordianData();
    }
    if (
      this.state.prepareExpandedRowData &&
      this.props.trackingDetails !== prevProps.trackingDetails
    ) {
      this.appendExpandedRowData();
    }
    if (this.props.consignmentPodUpdate && !prevProps.consignmentPodUpdate) {
      notifyApiSuccess(
        "POD Action complete",
        undefined,
        this.props.resetNotificationData
      );
      this.props.onClose(); //close popup
    }
    if (this.props.consignmentPodUpdate === false) {
      notifyApiError(
        this.props.errorMessage,
        undefined,
        this.props.resetNotificationData
      );
    }
    if (
      this.props.primaryNumber !== prevProps.primaryNumber ||
      this.props.secondaryNumber !== prevProps.secondaryNumber
    ) {
      this.prepareAccordianData();
    }
  }
  prepareAccordianData = () => {
    const consigneeContactNumber = [
      this.props?.trackingDetails?.consignment?.consignee?.phoneNumber,
    ];
    if (this.props.source === Constants.SUSPICIOUS_CLASSNAME) {
      consigneeContactNumber.push(
        this.props.trackingDetails?.consignment?.consignee?.key
      );
    }
    const accordianData = [
      {
        id: 0,
        title: "Shipper Details",
        data: {
          Name: this.props?.trackingDetails?.consignment?.shipper?.name,
          Address: [
            this.props?.trackingDetails?.consignment?.shipper?.address
              ?.address1,
            this.props?.trackingDetails?.consignment?.shipper?.address
              ?.address2,
            this.props?.trackingDetails?.consignment?.shipper?.address
              ?.address3,
          ].join(","),
          Pincode: this.props?.trackingDetails?.consignment?.shipper?.address
            ?.pincode?.zipcode,
          "Contact No": this.props?.trackingDetails?.consignment?.shipper
            ?.phoneNumber,
        },
      },
      {
        id: 1,
        title: "Consignee Details",
        data: {
          "Acc No": "NA",
          Name: this.props?.trackingDetails?.consignment?.consignee?.name,
          Address: [
            this.props?.trackingDetails?.consignment?.consignee?.address
              ?.address1,
            this.props?.trackingDetails?.consignment?.consignee?.address
              ?.address2,
            this.props?.trackingDetails?.consignment?.consignee?.address
              ?.address3,
          ].join(","),
          Pincode: this.props?.trackingDetails?.consignment?.consignee?.address
            ?.pincode?.zipcode,
          "Primary No": (
            <span
              className="clickable-link"
              onClick={() => {
                this.requestForPhoneNumber({
                  waybillNo: this.props.waybillNo,
                  id: 1,
                  type: CONTACT_NUMBER.PRIMARY,
                });
              }}
            >
              {this.props?.primaryNumber
                ? this.displayNumber(this.props.primaryNumber)
                : this.props.primaryNumber === ""
                ? "NA"
                : "Request for primary number. Note: This request will be logged."}
            </span>
          ),
          "Secondary No": (
            <span
              className="clickable-link"
              onClick={() => {
                this.requestForPhoneNumber({
                  waybillNo: this.props.waybillNo,
                  id: 1,
                  type: CONTACT_NUMBER.SECONDARY,
                });
              }}
            >
              {this.props?.secondaryNumber
                ? this.displayNumber(this.props.secondaryNumber)
                : this.props.secondaryNumber === ""
                ? "NA"
                : "Request for secondary number. Note: This request will be logged."}
            </span>
          ),
        },
        open: this.props.primaryNumber ? true : false,
      },
      {
        id: 2,
        title: "Consignment Details",
        data: {
          "Product Detail": this.props?.trackingDetails?.consignment
            ?.description,
          "Total Shipment Count": this.props?.trackingDetails?.consignment
            ?.totalShipmentCount,
          "Total Weight": `${this.props?.trackingDetails?.consignment?.totalWeight} Kg(s)`,
          "Total Chargeble Weight": `${this.props?.trackingDetails?.consignment?.totalChargableWeight} Kg(s)`,
        },
      },
      {
        id: 3,
        title: "Booking Details",
        data: {
          "Booking Date": formatDate(
            this.props?.trackingDetails?.consignment?.bookingDate
          ),
          "Shipment Value": this.props?.trackingDetails?.consignment
            ?.consignmentAmount,
          "Payable Amount": this.props?.trackingDetails?.consignment
            ?.payableAmount,
        },
      },
      {
        id: 4,
        title: "Delivery Details",
        data: {
          "Expected Delivery Date": formatDate(
            this.props?.trackingDetails?.consignment?.expectedDeliveryDate
          ),
        },
      },
      {
        id: 5,
        title: "POD Images",
        data: {},
        render: () => {
          const images = [];
          const consignmentImages = this.props?.trackingDetails?.consignment?.consignmentPODBO?.map(
            (pod) => {
              if (pod.podImpLink || pod.sigImgLink) {
                images.push(<div>Consignment</div>);
              }

              if (pod.podImpLink) {
                pod.podImpLink.split(",").forEach((imgUrl) => {
                  images.push(
                    <a href={imgUrl} target="_blank">
                      <img
                        src={imgUrl}
                        className="pod-image"
                        alt="consignment_pod"
                      />
                    </a>
                  );
                });
              }
              if (pod.sigImgLink) {
                pod.sigImgLink.split(",").forEach((imgUrl) => {
                  images.push(
                    <a href={imgUrl} target="_blank">
                      <img
                        src={imgUrl}
                        className="pod-image"
                        alt="consignment_pod"
                      />
                    </a>
                  );
                });
              }
            }
          );
          // const manifestImages = this.props ?.trackingDetails ?.manifest ?.manifestPODBO ?.map(pod => {
          //     if (pod.podImpLink || pod.sigImgLink) {
          //         images.push(<div>Manifest</div>);
          //     }
          //     if (pod.podImpLink) {
          //         pod.podImpLink.split(',').forEach(imgUrl => {
          //             images.push(
          //                 <a href={imgUrl} target="_blank" >
          //                     <img src={imgUrl} className="pod-image" alt="manifest_pod" />
          //                 </a>
          //             )
          //         })
          //     }
          //     if (pod.sigImgLink) {
          //         pod.sigImgLink.split(',').forEach(imgUrl => {
          //             images.push(
          //                 <a href={imgUrl} target="_blank" >
          //                     <img src={imgUrl} className="pod-image" alt="consignment_pod" />
          //                 </a>
          //             )
          //         })
          //     }
          // });
          return images;
        },
      },
    ];
    // this.setState({ accordianData, prepareAccordianData: false })
    this.setState((prevState) => {
      let newState = {
        ...prevState,
        accordianData,
      };
      if (this.props.trackingDetails) {
        newState.prepareAccordianData = false;
      }
      return newState;
    });
  };
  appendExpandedRowData = () => {
    let jIndex, partner_name, location_name;
    if (!this.state.prepareExpandedRowData) {
      return;
    }
    let track = [...this.props?.trackingDetails?.tracking.reverse()];
    if (Constants.sessionUser.location.entityType === "CUSTOMER") {
      track = track.map((event) => {
        if (!jIndex && event.eventType === "BOOKING") {
          jIndex = event.data.partner_name;
          partner_name = event.data.partner_name;
          location_name = event.data.location_name;
        }
        switch (event.eventType) {
          case "BOOKING_RECEIVED":
          case "INSCAN":
          case "BOOKING":
          case "DRS_SCAN":
          case "UNDELIVERED_B2B":
          case "INWARD_SCAN":
            if (partner_name !== event.data.partner_name) {
              event.data.partner_name = partner_name + " Partner";
            }
            if (
              location_name !==
              event.data[
                event.eventType === "DRS_SCAN" ? "loc_name" : "location_name"
              ]
            ) {
              event.data[
                event.eventType === "DRS_SCAN" ? "loc_name" : "location_name"
              ] = partner_name + " Partner's Location";
            }
            break;
          default:
        }

        return event;
      });
    }
    const expandedRowTrackingEvents = track.reverse()?.map((event) => {
      const row = this.getExpandedRowHtmlByEvent(event);
      event.renderExpandedRow = () => <ExpandedRow row={row} />;
      return event;
    });
    // this.setState({ tableData: expandedRowTrackingEvents, prepareExpandedRowData: false });
    this.setState((prevState) => {
      let newState = {
        ...prevState,
        tableData: expandedRowTrackingEvents,
      };
      if (this.props.trackingDetails) {
        newState.prepareExpandedRowData = false;
      }
      return newState;
    });
  };
  getExpandedRowHtmlByEvent(event) {
    switch (event.eventType) {
      case TRACKING_EVENTS.UNDELIVERED:
        let mapHtml = null;
        let rescheduleDateHtml = null;
        if (
          event?.data?.attributes?.latitude &&
          event?.data?.attributes?.longitude
        ) {
          mapHtml = (
            <Map
              isMarkerShown
              googleMapURL={Constants.MAP_URL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              lat={event?.data?.attributes?.latitude || 0}
              lng={event?.data?.attributes?.longitude || 0}
            />
          );
        }
        if (event?.data?.reasonBO?.reasonCode === "900") {
          rescheduleDateHtml = (
            <div className="expanded-data-row">
              <p className="heading">Rescheduled Date</p>
              <Typography className="data">
                {formatDate(event?.data?.rescheduledDate)}
              </Typography>
            </div>
          );
        }
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Reason</p>
                <Typography className="data">
                  {event?.data?.reasonBO?.reasonDescription}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">FE User Details</p>
                <Typography
                  className="data"
                  condition={!!event?.data?.drsUser?.name}
                >
                  {event?.data?.drsUser?.name} (
                  {event?.data?.drsUser?.contactNumber})
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
              {rescheduleDateHtml}
              {mapHtml}
            </div>
          </div>
        );
      case TRACKING_EVENTS.BOOKING:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Partner Name</p>
                <Typography className="data">
                  {event?.data?.partner_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Source</p>
                <Typography className="data">
                  {event?.data?.shipper_pincode_zipcode}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Destination</p>
                <Typography className="data">
                  {" "}
                  {event?.data?.consignee_pincode_zipcode}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Location Name</p>
                <Typography className="data">
                  {event.data.location_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Created At</p>
                <Typography className="data">{event.data.createdAt}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.INSCAN:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Location </p>
                <Typography className="data">
                  {event?.data?.location_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">User Details </p>
                <Typography className="data" condition={!!event.data.user_name}>
                  {event.data.user_name} | {event.data.user_username}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.DRS_SCAN:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">DRS ID</p>
                <Typography className="data">{event?.data?.drsCode}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">OFD Marked By</p>
                <Typography className="data">
                  {event?.data?.originatedUser_username}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">OFD Marked For</p>
                <Typography className="data">
                  {event.data.drsUser_name}
                </Typography>
                {event?.data?.drsUser_contactNumber && (
                  <Typography className="data">
                    ({event?.data?.drsUser_contactNumber})
                  </Typography>
                )}
              </div>
              <div className="expanded-data-row">
                <p className="heading">Location</p>
                <Typography className="data" condition={!!event.data.loc_name}>
                  {event.data.loc_name} - {event.data.loc_id}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.DELIVERED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Delivered</p>
                <Typography className="data">
                  {event?.data?.receivedBy}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">FE Name</p>
                <Typography
                  className="data"
                  condition={!!event?.data?.drsUser?.name}
                >
                  {event?.data?.drsUser?.name} (
                  {event?.data?.drsUser?.contactNumber})
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Payment Mode</p>
                <Typography className="data">
                  {event?.data?.paymentType}
                </Typography>
                {event?.data?.attributes?.transactionId ? (
                  <React.Fragment>
                    <p className="heading">Transaction ID</p>
                    <Typography className="data">
                      {event?.data?.attributes?.transactionId}
                    </Typography>
                  </React.Fragment>
                ) : null}
              </div>
              <div className="expanded-data-row">
                <p className="heading">Delivered Time</p>
                <Typography className="data">
                  {formatDate(event?.data?.podCreatedDate, formatDateoption())}
                </Typography>
              </div>
              <Map
                isMarkerShown
                googleMapURL={Constants.MAP_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                lat={event?.data?.attributes?.latitude}
                lng={event?.data?.attributes?.longitude}
              />
            </div>
            <Button
              type="primary"
              disabled={
                event?.data?.podImpLink || event?.data?.sigImgLink
                  ? false
                  : true
              }
              onClick={() => this.openPrint(event.data)}
              className="pull-right"
              style={{ width: "120px" }}
            >
              Download POD
            </Button>
          </div>
        );
      case TRACKING_EVENTS.RESCHEDULED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Reason</p>
                <Typography className="data">
                  {event?.data?.reasonBO?.reasonDescription}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">User Details</p>
                <Typography
                  className="data"
                  condition={!!event?.data?.drsUser?.name}
                >
                  {event?.data?.drsUser?.name} (
                  {event?.data?.drsUser?.contactNumber})
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.INWARD_SCAN:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Scan Location</p>
                <Typography className="data">
                  {event?.data?.location_name} - {event?.data?.location_id}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">User Details</p>
                <Typography className="data">
                  {event?.data?.user_name}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.BOOKING_RECEIVED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Consignee Pincode</p>
                <Typography className="data">
                  {event?.data?.consignmentBo_consignee_pincode_zipcode}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Location Address</p>
                <Typography className="data">
                  {event?.data?.consignmentBo_location_address_pincode_zipcode}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Partner Name</p>
                <Typography className="data">
                  {event?.data?.partner_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.ARRIVED_AT_LOCATION:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
              <Map
                isMarkerShown
                googleMapURL={Constants.MAP_URL}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                lat={event?.data?.latitude}
                lng={event?.data?.longitude}
              />
            </div>
          </div>
        );
      case TRACKING_EVENTS.CALL_RECORD:
        let callStatus = "NA";
        let caller;
        let callee;
        const apiTypeItems = event?.data?.apiType.split("_");
        if (apiTypeItems.length === 3) {
          caller = apiTypeItems[0].toLowerCase();
          callee = apiTypeItems[2].toLowerCase();
          switch (event?.data?.legStatus2) {
            case "no-answer":
              callStatus = `${callee} Didn't pick up`;
              break;
            case "busy":
              callStatus = `${callee} Disconnected`;
              break;
            case "canceled":
              callStatus = ` ${callee} Didn't pick up`;
              break;
            case "completed":
              callStatus = "Call attempt successful";
              break;
            case "failed":
              callStatus = `Call couldn't connect to ${callee}`;
              break;
            default:
              callStatus = "NA";
          }
        }
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
              {/* Displaying who called whom*/}
              <div className="expanded-data-row">
                <p className="heading">Event</p>
                <Typography condition={callee && caller}>
                  <span className="init-caps">{caller}</span> called{" "}
                  <span className="init-caps">{callee}</span>
                </Typography>
              </div>
              {/* Status text for consignee based on legStatus2 */}
              <div className="expanded-data-row">
                <p className="heading">Status</p>
                <Typography className="data">{callStatus}</Typography>
              </div>
              {/* Leg 1 details */}
              <div className="expanded-data-row">
                <p className="heading">Leg1</p>
                <div className="flex">
                  <span>
                    <p className="heading inline">Status</p>
                    <Typography className="data inline">
                      {event?.data?.legStatus1}
                    </Typography>
                  </span>
                  <span>
                    <p className="heading inline">Duration</p>
                    <Typography
                      className="data inline"
                      condition={event?.data?.leg1Duration}
                    >
                      {event?.data?.leg1Duration} secs
                    </Typography>
                  </span>
                </div>
              </div>

              {/* Leg2 Details */}
              <div className="expanded-data-row">
                <p className="heading">Leg2</p>
                <div className="flex">
                  <span>
                    <p className="heading inline">Status</p>
                    <Typography className="data inline">
                      {event?.data?.legStatus2}
                    </Typography>
                  </span>
                  <span>
                    <p className="heading inline">Duration</p>
                    <Typography
                      className="data inline"
                      condition={event?.data?.leg2Duration}
                    >
                      {event?.data?.leg2Duration} secs
                    </Typography>
                  </span>
                </div>
              </div>

              <div className="right-col">
                {event?.data?.recordingSourceUrl && (
                  <React.Fragment>
                    <audio controls class="tracking-audio">
                      <source
                        src={event?.data?.recordingSourceUrl}
                        type="audio/mp3"
                      ></source>
                      <source
                        src={event?.data?.recordingSourceUrl}
                        type="audio/ogg"
                      ></source>
                      <span className="data">
                        Your browser doesn't support HTML5 audio. Here is a{" "}
                        <a href="event?.data?.recordingSourceUrl">
                          link to the audio
                        </a>{" "}
                        instead.
                      </span>
                    </audio>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.POD_STREAM:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Reason</p>
                <Typography className="data">{event?.data?.reason}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Remarks</p>
                <Typography className="data">{event?.data?.remarks}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Consignment Status</p>
                <Typography className="data">
                  {event?.data?.consignmentStatus}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Status</p>
                <Typography className="data">{event?.data?.status}</Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.PICKUP_CREATED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Lead Code</p>
                <Typography className="data">
                  {event?.data?.lead_code}
                </Typography>
              </div>
            </div>
            <div className="right-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.PICKUP_ASSIGNED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Name</p>
                <Typography className="data">
                  {event?.data?.user_name}
                </Typography>
              </div>
            </div>
            <div className="right-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.OUT_FOR_PICKUP:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Name</p>
                <Typography className="data">
                  {event?.data?.user_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.UPDATED_STATUS:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Status</p>
                <Typography className="data">
                  {event?.data?.consignmentStatus}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.MANIFEST_SCAN:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Manifest ID</p>
                <Typography className="data">
                  {event?.data?.manifestCode}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.MANIFEST_POD:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Manifest ID</p>
                <Typography className="data">
                  {event?.data?.manifestCode}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.THC_SCAN:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Truck Hire Chalans ID</p>
                <Typography className="data">{event?.data?.thcCode}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.PICKUP_CANCELLED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Pickup Cancelation Reason</p>
                <Typography className="data">
                  {event?.data?.reason?.reasonDescription}
                </Typography>
              </div>
            </div>
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.PICKED_UP:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.UPDATED:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.RTO_IN:
      case TRACKING_EVENTS.RTO_DRS_SCAN:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.UNDELIVERED_B2B:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">User Details</p>
                <Typography className="data">{event.data.user_name}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Location Name</p>
                <Typography className="data">
                  {event.data.location_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">Reason Category Name</p>
                <Typography className="data">
                  {event.data.reasonCategory_name}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">Reason</p>
                <Typography className="data">
                  {event.data.reason_reasonDescription}
                </Typography>
              </div>
            </div>
          </div>
        );
      case TRACKING_EVENTS.RETURN_B2B:
        return (
          <div className="expanded-row">
            <div className="left-col">
              <div className="expanded-data-row">
                <p className="heading">User Details</p>
                <Typography className="data">{event.data.user_name}</Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Location Name</p>
                <Typography className="data">
                  {event.data.location_name}
                </Typography>
              </div>
              <div className="expanded-data-row">
                <p className="heading">Event Time</p>
                <Typography className="data">
                  {formatDate(event?.eventTime, formatDateoption())}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">Reason Category Name</p>
                <Typography className="data">
                  {event.data.reasonCategory_name}
                </Typography>
              </div>

              <div className="expanded-data-row">
                <p className="heading">Reason</p>
                <Typography className="data">
                  {event.data.reason_reasonDescription}
                </Typography>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  updateConsignmentPod = (formValues, isValid) => {
    const payload = {
      reasonId: formValues.reason,
      remarks: formValues.remarks,
      status: isValid ? "RESOLVED_VALID" : "RESOLVED_INVALID",
      action: "RESOLVED",
    };
    this.props.updateConsignmentPod(this.props.getDetailInput, payload);
  };
  notifyCopyEvent() {
    openNotification({
      message: "Copied",
      description: "Waybill has been copied to clipboard",
      className: "warning",
    });
  }

  openPrint(val) {
    const head =
      "<style> body {font-family: 'Roboto', sans-serif;} div { margin-left: 25px} </style>";
    const body = `
        <h2>WayBill No: ${val.waybillNo}</h2>
        <div>
            <p><strong>Delivery Date: </strong>${new Intl.DateTimeFormat(
              "en-GB"
            ).format(val.updatedAt)}</p>
            <p><strong>Delivery To: </strong> ${
              val.receivedBy ? val.receivedBy : "NA"
            } (${
      val.relationshipText === "SELF" && val.relationshipText
        ? "SELF"
        : val.relationshipText
    }) ${val.phoneNumber ? "- " + val.phoneNumber : ""} </p>
            <p><strong>Delivery By: </strong>${
              val.drsUser.name ? val.drsUser.name : "NA"
            } (${
      val.drsUser.contactNumber ? val.drsUser.contactNumber : "NA"
    })</p>
            <p><strong>Delivery Location: </strong>${
              val?.attributes?.latitude ? val?.attributes?.latitude : "NA"
            }, ${
      val?.attributes?.longitude ? val?.attributes?.longitude : "NA"
    } </p>
            ${
              val.podImpLink
                ? `<p><strong>POD: </strong><p><img width='200px' src=${val.podImpLink} alt='No POD Image' /></p></p>`
                : ""
            }
            ${
              val.sigImgLink
                ? `<p><strong>Signature: </strong><p><img width="200px" src="${val.sigImgLink}" alt="signature"></p></p>`
                : ""
            }
        </div>`;

    const printConfig = {
      body,
      head,
      isPrint: false,
    };
    openWindowWithData(printConfig);
  }

  render() {
    const accordianHtml = this.state.accordianData.map((accordian) => {
      return (
        <Panel
          header={accordian.title}
          key={accordian.id}
          style={accordianStyle}
        >
          {!accordian.render
            ? Object.keys(accordian.data).map((key, index) => {
                return (
                  <div className="row" key={index}>
                    <label className="bold">{key} :</label>{" "}
                    <div className="data">{accordian.data[key]}</div>
                  </div>
                );
              })
            : accordian.render()}
        </Panel>
      );
    });
    const consignee = {
      name: this.props.trackingDetails?.consignment?.consignee?.name,
      contactNumber: this.props.trackingDetails?.consignment?.consignee
        ?.phoneNumber,
      key: this.props.trackingDetails?.consignment?.consignee?.key,
    };
    const drs =
      this.props.drses && this.props.drses.length > 0 && this.props.drses[0];
    let fe;
    if (drs) {
      fe = {
        name: drs?.drsUser?.name,
        contactNumber: drs?.drsUser?.contactNumber,
      };
    }
    let trackingFooterHtml;
    if ("TrackingListing" === Constants.SUSPICIOUS_CLASSNAME) {
      trackingFooterHtml = (
        <TrackingFooter
          reasons={this.props.reasons}
          onMarkClick={this.updateConsignmentPod}
          selectedReasonId={this.props.reasonId}
          selectedStatus={this.props.status}
          consignee={consignee}
          fe={fe}
          shouldResolve={this.props.shouldResolve}
        />
      );
    }
    return (
      <>
        {this.props.trackingDetails && (
          <div className="TrackingPopup">
            <div className="header">
              WayBill No : <span>{this.props.waybillNo}</span>
              <CopyToClipboard
                text={this.props.waybillNo}
                onCopy={this.notifyCopyEvent}
              >
                <Icon type="copy" className="copy-icon" />
              </CopyToClipboard>
            </div>
            <div className="content">
              <div className="sidebar">
                <Accordian
                  defaultActiveKey={this.props.defaultAccordianActiveKey}
                >
                  {accordianHtml}
                </Accordian>
              </div>
              <div className="main-content">
                <Typography className="content-header">
                  Shipment Activity Log
                </Typography>
                <Table
                  columns={this.state.columns}
                  dataSource={this.state.tableData}
                  expandedRowRender={(row) => row.renderExpandedRow()}
                  bordered={true}
                  // defaultExpandedRowKeys={[13905]}
                  expandRowByClick={true}
                />
              </div>
            </div>
            <Divider />
            {trackingFooterHtml}
          </div>
        )}
      </>
    );
  }

  requestForPhoneNumber(data) {
    this.props.getCustomerContactNumber(data);
  }

  displayNumber(data) {
    if (data) {
      return data;
    }

    return "You are not permitted to view the contact number.";
  }
}

const mapStateToProps = (state, props) => {
  let trackingDetails = state.tracking?.waybillDetails;
  let loading = state.tracking?.loading;
  if (
    props.source !== undefined &&
    props.source === Constants.SUSPICIOUS_CLASSNAME
  ) {
    trackingDetails = state.suspicious?.waybillDetails;
    loading = state.suspicious?.loading;
  }
  if (trackingDetails?.tracking) {
    trackingDetails.tracking = trackingDetails.tracking.reverse();
    // console.log("tracking", trackingDetails.tracking)
    const newTrackingDeatils = trackingDetails.tracking.reverse();
    let newIndex, swapIndex;
    let delivered = newTrackingDeatils.find((tr, index) => {
      if (tr.eventType === "DELIVERED") {
        newIndex = index;
      }

      return tr.eventType === "DELIVERED";
    });
    // console.log("delivered", delivered, newIndex)
    if (delivered) {
      newTrackingDeatils.map((tr, index) => {
        if (tr.eventTime === delivered.eventTime) {
          if (newIndex < index) {
            swapIndex = index;
          }
        }
      });
      if (swapIndex) {
        let temp = newTrackingDeatils[swapIndex];
        newTrackingDeatils[swapIndex] = newTrackingDeatils[newIndex];
        newTrackingDeatils[newIndex] = temp;
      }
    }

    // console.log("newTrackingDeatils", newTrackingDeatils)
    trackingDetails.tracking = newTrackingDeatils.reverse();
  }
  return {
    reasons: state.app?.configurations?.reasons?.UNDELIVERED,
    trackingDetails: trackingDetails,
    consignmentPodUpdate: state.trackingDetails.consignmentPodUpdate,
    errorMessage: state.trackingDetails?.errorMessage,
    drses: trackingDetails?.drses,
    loadingData: loading,
    primaryNumber: state.trackingDetails?.primary,
    secondaryNumber: state.trackingDetails?.secondary,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getTrackingDetails: (params) => dispatch(actions.getTrackingDetails(params)),
  updateConsignmentPod: (streamId, params) =>
    dispatch(actions.updateConsignmentPod(streamId, params)),
  resetNotificationData: () => dispatch(actions.resetNotificationData()),
  getCustomerContactNumber: (params) =>
    dispatch(actions.getCustomerContactNumber(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TrackingPopup);
