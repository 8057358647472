import React, { Component } from 'react';
import { Row, Col,Select,Input, Icon,Button,Radio } from "antd";
import L from 'leaflet';
import { Map, TileLayer, LayersControl,Marker, Popup,Tooltip } from 'react-leaflet'
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Control from 'react-leaflet-control';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Pins from './Pins.js';
import 'leaflet/dist/leaflet.css';
import './google-map-leaflet.css'
import {carIcon,truckIcon,createCustomMarker,createCustomMarkerHtml} from './CustomIcon'
import * as utils from '../../common/utils/utils';
import {beeconGoogleMapKey,liveFeedToken} from "../../environments/node-react-environment";
import io from "socket.io-client";
import VehicleSocket from './VehicleSocket';
import ReactDOMServer from 'react-dom/server';
//import 'leaflet.markercluster.freezable';
require('leaflet.markercluster.freezable');
const axios = require('axios');
var spiderfyStartTime ="";
var lastUpdateTime ="";
var spiderfyLayer;
var spiderfyOpen=false;
var pendingUpdate = [];
var pendingLisUpdate= [];
var socket;
var isClustered = true;

class GoogleMapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markerIndex: '',
      curIndex: undefined,
      lat: 13.0827,
      lng: 80.2707,
      finalVehicles: [],
      liveFeedVehicles: [],
      vehicleRegNo:null,
      vehicleStatusValue:  this.props.status,
      address:{}
    }
    //this.onChange = this.onChange.bind(this);
    //this.onBlur = this.onBlur.bind(this);
    //this.copyArea = React.createRef();
    this.showHideMarkerClustering = this.showHideMarkerClustering.bind(this);
    this.markerRefs = [];
    this.listRefs = [];
    this.layerSpiderfied=false;
    this.isClustered=true;
  }
  onVehicleStatusChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({
      vehicleStatusValue: e.target.value,
    });
    this.props.onVehicleStatusChange(e.target.value);
  };
  componentDidMount() {
    const leafletMap = (!utils.isEmpty(this.leafletMap) && !utils.isEmpty(this.leafletMap.leafletElement))?this.leafletMap.leafletElement:"";
    const offVehicles = this.getVehicles('OFF').map((x, key) => {
      x.igni = "OFF";
      return x;
    });
    const onVehicles = this.getVehicles('ON').map((x, key) => {
      x.igni = "ON"
      return x;
    });
    let finalVehicles = [...offVehicles, ...onVehicles];
    this.setState({
      finalVehicles:finalVehicles,
      liveFeedVehicles:finalVehicles
    });
    let _this = this;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: 'loadshare@beecon.in',password:'lsnbee@123'})
  };
  let loginUrl = "https://beecon.in/api/login";
  if (window && window.location && window.location.protocol !== 'https:') {
    loginUrl = "http://beecon.in/api/login";
  }
  fetch(loginUrl, requestOptions)
      .then(response => response.json())
      .then(data =>{
          //console.log("the data",data);
          const options = {
            forceNew: true,
            autoConnect: true,
            pingInterval: 6000,
            pingTimeout: 10000,
            transports: ['websocket'],
            agent: false, // [2] Please don't set this to true
            query: "token=" + data.token + "&deviceId=" + "profile_organization_J46SFFAE",
          };
          if (socket) {
            socket.disconnect();
            socket.close();
          }
          let url = "https://www.beecon.in";
          if (window && window.location && window.location.protocol !== 'https:') {
            url = "http://www.beecon.in";
          }
          socket = io.connect(url + "/webSocket", options);
          socket.on('connect', function (data){
              console.log("ON WEBSOCKET");
              lastUpdateTime = new Date();
          });
          socket.on('error', function (data){
            console.log(data);
          });
          socket.on("vehicletrackingEvent", function (data) {
              if(data){
                //console.log("the dd",_this.state.finalVehicles)
                //_this.onLiveFeedDataChange(data);
                  /*this.setState({
                    vehicles: data,
                  });*/
                  /*const vehicle_data = vehicleTrackingData[data.id]
                  if(!isEmpty(vehicle_data)){
                      //console.log("Updated vehicle "+vehicle_data.id+" from location "+vehicle_data.telematicData+" to location"+data.telematicData )
                      vehicle_data.telematicData = data.telematicData
                  }*/
              }
          });
          socket.on("vehicleTelematicEvent", function (data) {
              if(data){
                //console.log("the dd",_this.state.finalVehicles)
                _this.onLiveFeedDataChange(data);
                  /*this.setState({
                    vehicles: data,
                  });*/
                  /*const vehicle_data = vehicleTrackingData[data.id]
                  if(!isEmpty(vehicle_data)){
                      //console.log("Updated vehicle "+vehicle_data.id+" from location "+vehicle_data.telematicData+" to location"+data.telematicData )
                      vehicle_data.telematicData = data.telematicData
                  }*/
              }
          });
      });
  }
  getBounds = () => {
    const { geojson } = this.props;
    let jsonData = [];
    if(geojson){
      for (const key in geojson) {
        if (geojson.hasOwnProperty(key)) {
          const element = geojson[key];
          if(element && element.telematicData && element.telematicData.gps && element.telematicData.gps.location && element.telematicData.gps.location.lat && element.telematicData.gps.location.long){
              jsonData.push([element.telematicData.gps.location.lat,element.telematicData.gps.location.long]);
          }
        }
      }
    }
    return jsonData;
  };
  getOptions = () => {
    const { geojson } = this.props;
    let jsonData = [];
    if(geojson){
        for (const key in geojson) {
          if (geojson.hasOwnProperty(key)) {
            const element = geojson[key];
            jsonData.push(element);
          }
        }
    }
    return jsonData;
  };
  showHideMarkerClustering(isClustered) {
    this.isClustered = isClustered;
    if (!utils.isEmpty(this.leafletMarkerCluster)) {
      if (isClustered) {
        this.leafletMarkerCluster.enableClustering();
      } else {
        this.leafletMarkerCluster.disableClustering();
      }
    }
	}
  getVehicles = (status) => {
    const { geojson } = this.props;
    let jsonData = [];
    for (const key in geojson) {
      if (geojson.hasOwnProperty(key)) {
        const element = geojson[key];
        let locationFound = false;
        if(element && element.telematicData && element.telematicData.gps && element.telematicData.gps.location && element.telematicData.gps.location.lat && element.telematicData.gps.location.long){
          element.vehicleLatitude = element.telematicData.gps.location.lat;
          element.vehicleLongitude = element.telematicData.gps.location.long;
          element.vehicleAngle = element.telematicData.gps.angle;
          locationFound = true;
        }
        if(locationFound && element && element.telematicData && element.telematicData.ignition != 0 && status =='ON'){
          jsonData.push(element);
        }else if(locationFound && element && element.telematicData && element.telematicData.ignition == 0 && status =='OFF'){
          jsonData.push(element);
        }else if(locationFound && element && element.telematicData && status =='ALL'){
          jsonData.push(element);
        }
      }
    };
    return jsonData;
  };
  childHandlerData = (dataFromChild) => {
    this.props.history.push(`/appv2/tracking/dashboard/show-details/${dataFromChild}`);
  }
  onLiveFeedDataChange(data){
    let existingIndex = utils.findArrayObjectIndex("id", data.id, this.state.finalVehicles);
    if(data && data.telematicData && data.telematicData.gps && data.telematicData.gps.location && data.telematicData.gps.location.lat && data.telematicData.gps.location.long){
      data.vehicleLatitude = data.telematicData.gps.location.lat;
      data.vehicleLongitude = data.telematicData.gps.location.long;
      data.vehicleAngle = data.telematicData.gps.angle;
      if(data && data.telematicData && data.telematicData.ignition != 0){
        data.igni = "ON";
      }else if(data && data.telematicData && data.telematicData.ignition == 0){
        data.igni = "OFF";
      }
    }
    if (utils.isEmpty(this.state.vehicleRegNo) && !utils.isEmpty(pendingLisUpdate)) {
      let clonePendingList = [...pendingLisUpdate];
      pendingLisUpdate = [];
      clonePendingList.forEach(data => {
        if (this.listRefs[data.id]) {
          let inner = this.vehicleInfo(data);
          this.listRefs[data.id].innerHTML =ReactDOMServer.renderToStaticMarkup(inner[0]);
        }
      });
    }
    if (this.listRefs[data.id]) {
      let inner = this.vehicleInfo(data);
      this.listRefs[data.id].innerHTML =ReactDOMServer.renderToStaticMarkup(inner[0]);
    }else{
       pendingLisUpdate.push(data);
    }
    if (this.markerRefs[existingIndex]) {
        if (!utils.isEmpty(spiderfyLayer)) {
          pendingUpdate.push({
            i:existingIndex,
            data:data
          });
          if (new Date() - lastUpdateTime > 10 * 1000 && !utils.isEmpty(pendingUpdate)) {
              if (!utils.isEmpty(spiderfyLayer)) {
                spiderfyLayer.unspiderfy();
              }
              spiderfyOpen = false;
              const clonePendingUpdate = [...pendingUpdate];
              pendingUpdate = [];
              for (const key in clonePendingUpdate) {
                if (clonePendingUpdate.hasOwnProperty(key)) {
                  const element = clonePendingUpdate[key];
                  if (!utils.isEmpty(element.i) && !utils.isEmpty(element.data)) {
                      this.markerRefs[element.i].leafletElement.setLatLng([element.data.vehicleLatitude,data.vehicleLongitude]);
                      this.markerRefs[existingIndex].leafletElement.setIcon(createCustomMarkerHtml(element.data.vehicleAngle,element.data.igni,element.data.regNo));
                      //this.markerRefs[element.i].leafletElement.setRotationAngle(0);
                      //this.markerRefs[element.i].leafletElement.setRotationAngle(element.data.vehicleAngle);
                      //this.markerRefs[existingIndex].leafletElement.setRotationOrigin(this.markerRefs[existingIndex].leafletElement.options.rotationOrigin);
                      this.markerRefs[existingIndex].leafletElement._popup.setLatLng([element.data.vehicleLatitude,element.data.vehicleLongitude]);
                      this.markerRefs[existingIndex].leafletElement._popup.setContent(`<div>${ReactDOMServer.renderToString(this.getPopUpContent(element.data))}</div>`);
                      if(!utils.isEmpty(this.leafletMarkerCluster)){
                        this.leafletMarkerCluster.refreshClusters(this.markerRefs[existingIndex].leafletElement);
                      }
                  }
                }
              }
              lastUpdateTime = new Date();
              spiderfyOpen = true;
              spiderfyLayer=undefined;
              if(!utils.isEmpty(this.leafletMarkerCluster)){
                this.leafletMarkerCluster.refreshClusters();
              }
              //this.leafletMarkerCluster.refreshClusters();
              //spiderfyLayer.spiderfy();
          }
        }else{
          this.markerRefs[existingIndex].leafletElement.setLatLng([data.vehicleLatitude,data.vehicleLongitude]);
          this.markerRefs[existingIndex].leafletElement.setIcon(createCustomMarkerHtml(data.vehicleAngle,data.igni,data.regNo));
          //this.markerRefs[existingIndex].leafletElement.setRotationAngle(0);
          //this.markerRefs[existingIndex].leafletElement.setRotationAngle(data.vehicleAngle);
          //this.markerRefs[existingIndex].leafletElement.setRotationOrigin(this.markerRefs[existingIndex].leafletElement.options.rotationOrigin);
          this.markerRefs[existingIndex].leafletElement._popup.setLatLng([data.vehicleLatitude,data.vehicleLongitude]);
          this.markerRefs[existingIndex].leafletElement._popup.setContent(`<div>${ReactDOMServer.renderToString(this.getPopUpContent(data))}</div>`);
          if(!utils.isEmpty(this.leafletMarkerCluster)){
            this.leafletMarkerCluster.refreshClusters(this.markerRefs[existingIndex].leafletElement);
            this.leafletMarkerCluster.refreshClusters();
          }
        }
    }
    /*this.setState(prevState => {
        const liveFeedVehicles = [...prevState.liveFeedVehicles];
        liveFeedVehicles[existingIndex] = data;
        return { liveFeedVehicles: liveFeedVehicles };
    });*/
    /*const { finalVehicles } = this.state;
    finalVehicles.splice(existingIndex, 1,data)
    this.setState({ finalVehicles: [...finalVehicles] }, () => {
      //call back function of set state you could check here updated state
    });*/
    //this.state.finalVehicles[existingIndex] = data;
  }
  onChange(index) {
    //const leafletMap = this.leafletMap.leafletElement;
    if (this.markerRefs[index]) {
      this.markerRefs[index].leafletElement.openPopup();
    }
    /*this.setState(
      {
        lat: this.state.finalVehicles[index].vehicleLatitude,
        lng: this.state.finalVehicles[index].vehicleLongitude
      },
      () => {
        if (this.markerRefs[index]) {
          this.markerRefs[index].leafletElement.openPopup();
        }
      }
    );*/
    //leafletMap.openPopup();
  }

  onBlur(index) {
  }
  onFocus() {
  }
  onSearch(val) {
  }
  markerClick(index) {
    const leafletMap = (!utils.isEmpty(this.leafletMap) && !utils.isEmpty(this.leafletMap.leafletElement))?this.leafletMap.leafletElement:"";
    if (index) {
      if (this.markerRefs[index]) {
        this.markerRefs[index].leafletElement.openPopup();
      }
      /*this.setState(
        {
          lat: this.state.finalVehicles[index].vehicleLatitude,
          lng: this.state.finalVehicles[index].vehicleLongitude
        },
        () => {
          if (this.markerRefs[index]) {
            /leafletMap.fitBounds([
              [this.state.finalVehicles[index].vehicleLatitude, this.state.finalVehicles[index].vehicleLongitude]
            ]);*
            this.markerRefs[index].leafletElement.openPopup();
          }
        }
      );*/
    }
  }
  dropDownClick(index) {
    const leafletMap = (!utils.isEmpty(this.leafletMap) && !utils.isEmpty(this.leafletMap.leafletElement))?this.leafletMap.leafletElement:"";
    if (index) {
      if (this.markerRefs[index]) {
        console.log("the this.markerRef",this.markerRefs[index],this.markerRefs[index].leafletElement.getLatLng())
        /*if (!utils.isEmpty(this.leafletMarkerCluster)) {
          if (this.isClustered) {
            this.leafletMarkerCluster.disableClustering();
            this.isClustered = false;
          }
        }*/
        leafletMap.flyTo(this.markerRefs[index].leafletElement.getLatLng());
        console.log("the zoom is",this.markerRefs[index].contextValue)
        let zoom = leafletMap.getZoom();
        /*leafletMap.eachLayer((layer)=>{
          console.log("the zoom is",layer)
        })*/
        this.markerRefs[index].contextValue.layerContainer.zoomToShowLayer(this.markerRefs[index].leafletElement, function () {
				});
        //leafletMap.setZoom( this.markerRefs[index].contextValue.layerContainer._zoom);
        this.markerRefs[index].leafletElement.fire("click");
      }
      /*this.setState(
        {
          lat: this.state.finalVehicles[index].vehicleLatitude,
          lng: this.state.finalVehicles[index].vehicleLongitude
        },
        () => {
          if (this.markerRefs[index]) {
            /leafletMap.fitBounds([
              [this.state.finalVehicles[index].vehicleLatitude, this.state.finalVehicles[index].vehicleLongitude]
            ]);*
            this.markerRefs[index].leafletElement.openPopup();
          }
        }
      );*/
    }
  }
  handleMarkerRef = (index, node) => {
    this.markerRefs[index] = node;
  };
  handleListRef = (x,index,node) => {
    this.listRefs[x.id] = node;
  };
  handleMarkerClusterRef = (element, node) => {
    //this.markerRefs[index] = node;
  };
  initEvents = (markers) => {
    if (markers) {
      //markers.refreshClusters();
      markers.on('clustermouseover', function (a) {
        lastUpdateTime = new Date();
        spiderfyOpen = true;
        spiderfyLayer = a.layer;
        //markers.refreshClusters(a.layer);
        a.layer.spiderfy();
      });
      markers.on('clustermouseout', function (a) {
        //markers.refreshClusters(a.layer);
        a.layer.unspiderfy();
        this.layerSpiderfied = false;
        spiderfyOpen = false;
        spiderfyLayer=undefined;
      });
    }
  };
  getPopUpContent = (x)=>{
    let url = x.id+'-'+x.regNo+(!utils.isEmpty(x.sim) && x.sim ? '-simtrack':'')+(!utils.isEmpty(x.contractId)? '-'+x.contractId:'');
        if(x.onTrip){
          url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
        }
    return(<div id="fleet-home-iw" className="fleet-home-iw">
                <div id="regNo">
                <label>Reg No :&nbsp;</label>
                <strong><span><a onClick={() => this.childHandlerData(url)}>{x.regNo}</a>&nbsp;{!utils.isEmpty(x.sim) && x.sim && <img className="simIcon" src={require('../../assets/img/sim-card.png')} />}</span></strong>
                <br/>
                </div>
                <div id="name">
                  <label>Name :&nbsp;</label>		<span>4138 (TA)</span><br/></div>
                  {/* <div id="regNo"><label>Vehicle Groups :&nbsp;</label><span>TAN-CNN-BEL-CNN-TAN</span><br/></div>
                  <div id="model"><label>Model :&nbsp;</label><span>ASHOK LEYLAND AL4123</span><br/></div> */}
                  <div id="telematicData_ignition_mod"><label>Ignition :&nbsp;</label><span><span className={ (x.telematicData.ignition != 0) ? 'statusRunning1' : 'statusStopped1' }>{(x.telematicData.ignition != 0) ? "ON" : "OFF"}</span></span><span>{x.telematicData.ignitionStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.ignitionStateChangeTime), "small")+' ) '}</span><br/></div>
                  <div id="telematicData_speed_mod"><label>Speed :&nbsp;</label><span>{x.telematicData && x.telematicData.gps && x.telematicData.gps.speed ? x.telematicData.gps.speed : 0} Km/hr</span><span>{x.telematicData.staticStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.staticStateChangeTime), "small")+' ) '}</span>		<br/></div>
                  <div id="telematicData_location_button"><label>Location :&nbsp;</label><a className="badge bg-blue fg-white">Get Address</a><br/></div>
                  <div id="telematicData_temperature_mod"><label>Temperature :&nbsp;</label><span>{x.telematicData && utils.vehicleTemp(x.telematicData)}</span><br/></div>
                  <div id="telematicData_unixTimeStamp_mod"><label>Updated :&nbsp;</label><span>{!utils.isEmpty(x.statusChangeDate)?utils.getTimeAsDuration(utils.getTimeDifference(x.statusChangeDate), "small"):utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.unixTimeStamp), "small")} ago &nbsp;</span><br/></div>
              </div>)
  }
  onChangeVehicle = (e) =>{
      let selectedVehicle = e.target.value;
      this.setState({vehicleRegNo : `${selectedVehicle}`});
  }
  childHandler = (dataFromChild) => {
    this.props.history.push(`/appv2/tracking/dashboard/show-details/${dataFromChild}`);
  }
  reloadData = () => {
    //this.props.dispatch(getBeeconTelematicTrackingData());
    window.location.reload();
  }
  handlePopupClose = (e) => {
    //console.log(e.popup)
    console.log("Popup is closed")
    if (!this.isClustered) {
      this.leafletMarkerCluster.enableClustering();
      this.isClustered = true;
    }
  }

  onGetAddress = (regNo,lat,long) => {
    var url = "https://nominatim.openstreetmap.org/reverse?lat="+parseFloat(lat)+"&lon="+parseFloat(long)+"&addressdetails=1&format=json";
    var display_name;
    var _this = this;
    axios.get(url)
    .then(function (response) {
        if(!utils.isEmpty(response) && !utils.isEmpty(response.data)){
            display_name = response.data.display_name
        }
    })
    .catch(function (error) {
        console.log(error);
    })
    .then(function () {
        if(!utils.isEmpty(display_name)){
            _this.state.address = {
                ..._this.state.address,
                [regNo] : display_name
            }
            _this.setState({ address: _this.state.address});
        }
    });
}

  childHandlerData = (dataFromChild) => {
      this.props.history.push(`/appv2/tracking/dashboard/show-details/${dataFromChild}`);
    }
  searchVehicle = (data) => {
      return data.filter(x => x.regNo.toLowerCase().includes(this.state.vehicleRegNo.toLowerCase()));
  }
  vehicleList(data) {
    return data.map((x, key) => {
        let url = x.id+'-'+x.regNo+(!utils.isEmpty(x.sim) && x.sim ? '-simtrack':'')+(!utils.isEmpty(x.contractId)? '-'+x.contractId:'');
        if(x.onTrip){
          url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
        }
        return (
          <div key={key} ref={this.handleListRef.bind(this,x, key)}>

          <div className="vehicle_box" onClick={() => this.childHandlerData(url)}>
              <Row>
                  <div className="vehicleNumber">{x.regNo}</div>
                  <div className="vehicleIcon">
                      {!utils.isEmpty(x.sim) && x.sim && <img className="simIcon" src={require('../../assets/img/sim-card.png')} />}
                  <div className={ (x.telematicData.ignition != 0) ? 'statusRunning' : 'statusStopped' }>({(x.telematicData.ignition != 0) ? "Running" : "Stopped"})</div>
                  </div>
              </Row>
              
              <div ><label>Speed&nbsp;&nbsp;&nbsp;:&nbsp;</label><span>{x.telematicData && x.telematicData.gps && x.telematicData.gps.speed ? x.telematicData.gps.speed : 0 } Km/hr</span><span>{x.telematicData.staticStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.staticStateChangeTime), "small")+' ) '}</span><br/></div>
              <div ><label>Last Updated:&nbsp;</label><span>&nbsp;{!utils.isEmpty(x.statusChangeDate)? utils.dateFormat(x.statusChangeDate):utils.dateFormat(x.telematicData.unixTimeStamp)}</span><br/></div>     
          </div>
          {!utils.isEmpty(this.state.address[x.regNo]) ? <div className="vehicleAddr1">
                            {this.state.address[x.regNo]}
                        </div>: x.telematicData && x.telematicData.gps && x.telematicData.gps.location &&
                        <div className="vehicleAddr1">
                        <Button
                        onClick={() => this.onGetAddress(x.regNo,x.telematicData.gps.location.lat,x.telematicData.gps.location.long)}
                            size={"small"}
                            shape={"round"}
                            style={{
                                height: "auto",
                                font: "Roboto",
                                fontSize : "12px",
                                backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                color: "white",
                                width: "auto"
                            }}
                        >
                        Get Address
                        </Button>
                        </div>
            }
          <div className="divider"></div>
          </div>
        )
    })
  }
  vehicleInfo(data) {
        var x = data;
        let url = x.id+'-'+x.regNo+(!utils.isEmpty(x.sim) && x.sim ? '-simtrack':'')+(!utils.isEmpty(x.contractId)? '-'+x.contractId:'');
        if(x.onTrip){
          url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
        }
        return (
          <div>
          <div className="vehicle_box" onClick={() => this.childHandlerData(url)}>
              <Row>
                  <div className="vehicleNumber">{x.regNo}</div>
                  <div className="vehicleIcon">
                      {!utils.isEmpty(x.sim) && x.sim && <img className="simIcon" src={require('../../assets/img/sim-card.png')} />}
                  <div className={ (x.telematicData.ignition != 0) ? 'statusRunning' : 'statusStopped' }>({(x.telematicData.ignition != 0) ? "Running" : "Stopped"})</div>
                  </div>
              </Row>
              
              <div ><label>Speed&nbsp;&nbsp;&nbsp;:&nbsp;</label><span>{x.telematicData && x.telematicData.gps && x.telematicData.gps.speed ? x.telematicData.gps.speed : 0 } Km/hr</span><span>{x.telematicData.staticStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.staticStateChangeTime), "small")+' ) '}</span><br/></div>
              <div ><label>Last Updated:&nbsp;</label><span>&nbsp;{!utils.isEmpty(x.statusChangeDate)? utils.dateFormat(x.statusChangeDate):utils.dateFormat(x.telematicData.unixTimeStamp)}</span><br/></div>     
          </div>
          {!utils.isEmpty(this.state.address[x.regNo]) ? <div className="vehicleAddr">
                            {this.state.address[x.regNo]}
                        </div>: x.telematicData && x.telematicData.gps && x.telematicData.gps.location &&
                        <div className="vehicleAddr">
                        <Button
                        onClick={() => this.onGetAddress(x.regNo,x.telematicData.gps.location.lat,x.telematicData.gps.location.long)}
                            size={"small"}
                            shape={"round"}
                            style={{
                                height: "auto",
                                font: "Roboto",
                                fontSize : "12px",
                                backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                color: "white",
                                width: "auto"
                            }}
                        >
                        Get Address
                        </Button>
                        </div>
            }
          <div className="divider"></div>
          </div>
        )
  }

  render() {
    if (this.leafletMap ){
      /*this.leafletMap.leafletElement.eachLayer(function(layer){
          layer.remove();
      });
      //this.leafletMap.leafletElement.remove();
      this.leafletMap = null;*/
  }
    const position = [13.0827, 80.2707];
    const defaultBounds = [[23.63936, 68.14712],[28.20453, 97.34466]]
    const { Option } = Select;
    var _this = this;
    let vehicleList;
    vehicleList = this.vehicleList(this.state.vehicleRegNo ? this.searchVehicle(this.state.liveFeedVehicles) : this.state.liveFeedVehicles);
    const finalVehicleMarkers = this.state.finalVehicles.map((x, key) => {
      let url = x.id+'-'+x.regNo+(!utils.isEmpty(x.sim) && x.sim ? '-simtrack':'')+(!utils.isEmpty(x.contractId)? '-'+x.contractId:'');
        if(x.onTrip){
          url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
        }
      return (
          <Marker key={key} position={[x.vehicleLatitude,x.vehicleLongitude]} interactive={true} keyboard={true} ref={this.handleMarkerRef.bind(this, key)}  onclick={() => {_this.markerClick(key)}} icon={createCustomMarkerHtml(x.vehicleAngle,x.igni,x.regNo)}>
            <Popup key={`popup-vehicle-${x.key}`} position={[x.vehicleLatitude,x.vehicleLongitude]}>
            <div id="fleet-home-iw" className="fleet-home-iw">
                <div id="regNo">
                <label>Reg No :&nbsp;</label>
                <strong><span><a onClick={() => this.childHandlerData(url)}>{x.regNo}</a>&nbsp;{!utils.isEmpty(x.sim) && x.sim && <img className="simIcon" src={require('../../assets/img/sim-card.png')} />}</span></strong>
                <br/>
                </div>
                <div id="name">
                  <label>Name :&nbsp;</label>		<span>4138 (TA)</span><br/></div>
                  {/* <div id="regNo"><label>Vehicle Groups :&nbsp;</label><span>TAN-CNN-BEL-CNN-TAN</span><br/></div>
                  <div id="model"><label>Model :&nbsp;</label><span>ASHOK LEYLAND AL4123</span><br/></div> */}
                  <div id="telematicData_ignition_mod"><label>Ignition :&nbsp;</label><span><span className={ (x.telematicData.ignition != 0) ? 'statusRunning1' : 'statusStopped1' }>{(x.telematicData.ignition != 0) ? "ON" : "OFF"}</span></span><span>{x.telematicData.ignitionStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.ignitionStateChangeTime), "small")+' ) '}</span><br/></div>
                  <div id="telematicData_speed_mod"><label>Speed :&nbsp;</label><span>{x.telematicData && x.telematicData.gps && x.telematicData.gps.speed ? x.telematicData.gps.speed : 0} Km/hr</span><span>{x.telematicData.staticStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.staticStateChangeTime), "small")+' ) '}</span>		<br/></div>
                  <div id="telematicData_location_button"><label>Location :&nbsp;</label><a className="badge bg-blue fg-white">Get Address</a><br/></div>
                  <div id="telematicData_temperature_mod"><label>Temperature :&nbsp;</label><span>{x.telematicData && utils.vehicleTemp(x.telematicData)}</span><br/></div>
                  <div id="telematicData_unixTimeStamp_mod"><label>Updated :&nbsp;</label><span>{!utils.isEmpty(x.statusChangeDate)?utils.getTimeAsDuration(utils.getTimeDifference(x.statusChangeDate), "small"):utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.unixTimeStamp), "small")} ago&nbsp;</span><br/></div>
              </div>
            </Popup>
            {/* <Tooltip direction='right' offset={[-8, -2]} opacity={1} permanent>
                <span>{x.regNo}</span>
            </Tooltip> */}
          </Marker>
      )
    });
    //bounds={this.getBounds()}
    //const markerItem = finalVehicles[this.state.markerIndex];
    //const MarkerToShow = <Popup isOpen={markerItem && markerItem.vehicleLatitude && markerItem.vehicleLongitude?false:false} key={`popup-vehicle-${this.state.markerIndex}`} position={[markerItem && markerItem.vehicleLatitude?markerItem.vehicleLatitude:0,markerItem && markerItem.vehicleLongitude?markerItem.vehicleLongitude:0]}></Popup>;
    return (
      <>
        <Row>
            <Col xs={7}>
            <div className="vehicleTitlebox">Vehicle Tracking</div>
            {vehicleList &&
            <>
            <Row className="pb_20">
                    <Input
                        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Search Vehicle"
                        onChange={(e) => this.onChangeVehicle(e)}
                        />
                </Row>
            {vehicleList.length > 0 && <div className="vehicle-card">
                <Row className="vehicleListBox">
                 <div className="vehicle_list">Vehicle List</div>
                 {vehicleList && <div className="bluecircle2">{vehicleList.length}</div>}
                </Row>
                <div className="divider"></div>
                <div className="vehiclescroll">
                {vehicleList}
                </div>
            </div>}
            {vehicleList.length <= 0 && <Row><div className="noData">No Data Available</div></Row>}
            </>
            }
            </Col>
            <Col xs={1}></Col>
            <Col xs={16}>
                <Row className="pb_20">
                  <div className="buttongroup">
                    <Button className="topBt" icon="reload" onClick={this.reloadData}>Refresh</Button>
                  </div>
                </Row>
                <Row className="pt_10">
                    <Col className="gutter-row" span={24}>
                        <div className="gutter-box">
                        <div className="boxgooglemap">
                            <Select allowClear showSearch style={{ width: 200,marginBottom:5 }} placeholder="Select a Vehicle" optionFilterProp="children" onChange={this.dropDownClick.bind(this)} onFocus={this.onFocus} onBlur={this.onBlur} onSearch={this.onSearch}
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.state.finalVehicles.map((x, key) => {
                                  return (<Option key={key} value={key}>{x.regNo}</Option>)
                              })}
                            </Select>
                            <Radio.Group onChange={this.onVehicleStatusChange} style={{marginLeft:5}} value={this.state.vehicleStatusValue}>
                                <Radio value={'ALL'}>All Vehicles</Radio>
                                <Radio value={'ONTRIP'}>On Trip Vehicles</Radio>
                              </Radio.Group>
                              {!utils.isEmpty(this.state.finalVehicles) && !utils.isEmpty(finalVehicleMarkers) && <Map zoom={11} bounds={this.getBounds()} style={{height:600+'px'}}  ref={m => { this.leafletMap = m; }} onPopupClose={this.handlePopupClose}>
                                  {/* googleMapsLoaderConf={{ KEY:beeconGoogleMapKey }} */}
                                  {/* <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'roadmap'} /> */}
                                  <TileLayer
                    url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
                />
                                  {/* ref={(markerClusterGroup) => initEvents(markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement:'')} */}
                                  {/* ref={(markerClusterGroup) =>{this.leafletMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement:'');this.initEvents(markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement:'')}} */}
                                    <MarkerClusterGroup chunkedLoading={true} maxClusterRadius={60} animate={true} ref={(markerClusterGroup) =>{this.leafletMarkerCluster = (markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement:'');}}
                                    showCoverageOnHover={false}
                                    onClusterMouseOver={cluster =>{
                                      lastUpdateTime = new Date();
                                      spiderfyOpen = true;
                                      cluster.layer.spiderfy();
                                      spiderfyLayer = cluster.target._spiderfied;
                                      }
                                    }
                                    offClusterMouseOver={cluster =>{
                                      /*lastUpdateTime = new Date();
                                      spiderfyOpen = true;
                                      cluster.layer.spiderfy();
                                      spiderfyLayer = cluster.layer;*/
                                      }
                                    }
                                    onSpiderfied={cluster =>{
                                      /*lastUpdateTime = new Date();
                                      spiderfyOpen = true;
                                      cluster.layer.spiderfy();
                                      spiderfyLayer = cluster.layer;*/
                                      }
                                    }>
                                        {/* <Marker position={[9.925200,78.119800]} interactive={true} keyboard={true} icon={truckIcon}>
                                          <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
                                        </Marker>
                                        <Marker position={[13.0827, 80.2707]} icon={carIcon}>
                                          <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
                                        </Marker>
                                        <Marker position={[8.0883, 77.5385]} icon={createCustomMarker()}>
                                          <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
                                        </Marker> */}
                                        {finalVehicleMarkers}
                                    </MarkerClusterGroup>
                                    {/* <MarkerClusterGroup showCoverageOnHover={false} ref={(markerClusterGroup) => initEvents(markerClusterGroup && markerClusterGroup.leafletElement ? markerClusterGroup.leafletElement:'')} spiderfyDistanceMultiplier={1} iconCreateFunction={createClusterOnIcon}>
                                    </MarkerClusterGroup> */}
                                  <Control position="topleft" >
                                    <button onClick={()=>{this.showHideMarkerClustering(!this.isClustered)}} className="cluster-control"><Icon type="codepen" style={{ color: 'black' }}></Icon></button>
                                  </Control>
                            </Map>}
                            {(utils.isEmpty(this.state.finalVehicles) || utils.isEmpty(finalVehicleMarkers)) && <Row><div className="noData">No Data Available</div></Row>}
                        </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        {vehicleList && vehicleList.length <= 0 &&
        <Row><div className="noData">No Data Available</div></Row>}
      </>
    );
  }
}

export default GoogleMapLeaflet;
