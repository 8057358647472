import React, { useState, useEffect } from "react"
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, PageHeader, Tooltip, Spin} from "antd";
import { table as Table } from "../../ui/table/Table";
import { validationRules, Constants  } from "../../../common/utils/constants";
import { permissionTags } from "../../../configs/permissionTags";
import * as apiUtils from "../../../common/utils/apiGateway";
import { canAllow } from "../../../common/utils/utils";
import "./ClientDashboard.scss"
import moment from "moment";
import ClientOnboardingForm from "./ClientOnboardingForm";

let customerData = {}
let isEditCustomer = {}

function ClientDashboard(props) {
    const [pageNo, setPageNo] = useState(1)
    const [filterTable, setFilterTable] = useState(null)
    const [data, setData] = useState()
    const [isEdit, setIsEdit] = useState(false)
    const [selectedCustomer, setSelectedCustomer] = useState({})
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
                                                current: 1,
                                                pageSize: 10,
                                                });
    const [clientOnboardingPermission, setClientOnboardingPermission] = useState({read: canAllow(permissionTags.settings.client_onboarding.read),
                                                                                create: canAllow(permissionTags.settings.client_onboarding.create),
                                                                                update: canAllow(permissionTags.settings.client_onboarding.update)})
    const [columns, setColumns] = useState([{
                                                title: "Name",
                                                dataIndex: "name",
                                                key: "name",
                                                render: (name) => `${name}`,
                                                width: '20%',
                                            },
                                            {
                                              title: 'Created On',
                                              dataIndex: 'address.createdAt',
                                              key: 'address.createdAt',
                                              render: createdAt => (moment(createdAt).format('DD/MM/YYYY HH:mm'))                                              
                                            },                                                                                      
                                            {
                                              title: "Action",
                                              dataIndex: "",
                                              key: "action",
                                              width: '20%',
                                              render: (row) => (<Row>
                                                {clientOnboardingPermission.update && <Col span={8}><Tooltip placement="top" title={"Edit"}>
                                                    <Icon type="edit" onClick={() => openEditCustomer(row)}/></Tooltip>
                                                  </Col>
                                                }
                                              </Row >)
                                              },
                                            
                                        ])
    const InputSearchRef = React.createRef();
    
    const fetchCustomers = (params = {}) => {
      setLoading(true)
      setIsEdit(false)
      apiUtils.apiRequest({
        url: `/hyperlocal/customer`,
        method: "GET"
      })
      .then(res => {
        console.log(res);
        setData(res.data.response.customers)
        setLoading(false)
        setPagination({
          ...params.pagination,          
          total: data?.totalCount,
        });
        setIsEdit(false)
        isEditCustomer = false
      })
    }
    
    const applyPagination = (filters, isReset = false, params) => {
        if (isReset) {setPageNo(1)}
        fetchCustomers()
    }

    const reloadCustomerList = () => {
        setPageNo(1)
        setFilterTable(null)
        InputSearchRef.current.input.state.value = "";
        applyPagination({ pageNo: 1 }, true);
      }

    const createCustomerHandler = () => {
        props.history.push("/appv2/settings/form/client-onboarding")
    }
    
    const searchHandler = (value) => {
      const filterData = data.filter((o) => Object.keys(o).some((k) => String(o[k])
        .toLowerCase()
        .includes(value.toLowerCase())));
      setFilterTable(filterData);
    };    

  const openEditCustomer = (row) => {
    setIsEdit(true)
    isEditCustomer = true
    setSelectedCustomer(row)
    customerData = row
    // console.log({customerData});
    props.history.push(`/appv2/settings/form/client-onboarding/${row.address.id}`)      
}
    
  const handleTableChange = (newPagination, filters, sorter) => {
    fetchCustomers({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    })
  }

    useEffect(() => {
      fetchCustomers({
        pagination,
      });
    }, []);

    const actionBtns = (
        <div className="outer-container-client-dashboard">
        <Input.Search className="search-client-dashboard" size={"large"} placeholder="Search" allowClear ref={InputSearchRef} onSearch={searchHandler} enterButton /> 

        <Tooltip placement="top" title={"Reload Customer List"}>
            <Button block size={"large"} onClick={reloadCustomerList} type="primary" className="reload-button-client-dashboard">
            <Icon type="reload"/>
            </Button>
        </Tooltip>
        <Button size={"large"}  type="primary" onClick={createCustomerHandler} style={{ marginLeft: 12 }}>CREATE CUSTOMER</Button>                
    </div> 
    )
  return (
    <Card className="CustomerDashBoard" title={<h2>Customer List</h2>} extra={actionBtns}>      
        <Table
            bordered            
            columns={columns}
            dataSource={filterTable == null ? data : filterTable}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}           
            />
    </Card>
  )}

export default ClientDashboard
export {customerData, isEditCustomer};