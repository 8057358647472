
export const initialState = {
    bulkOperation: undefined
}

export const CONSIGMENTS_SCAN_BEGIN = 'CONSIGMENTS_SCAN_BEGIN';
export const CONSIGMENTS_SCAN_SUCCESS = 'CONSIGMENTS_SCAN_SUCCESS';
export const CONSIGMENTS_SCAN_FAILURE = 'CONSIGMENTS_SCAN_FAILURE';

export const UPLOAD_BOOKING_BEGIN = 'UPLOAD_BOOKING_BEGIN';
export const UPLOAD_BOOKING_SUCCESS = 'UPLOAD_BOOKING_SUCCESS';
export const UPLOAD_BOOKING_FAILURE = 'UPLOAD_BOOKING_FAILURE';

export const UPLOAD_INSCAN_BEGIN = 'UPLOAD_INSCAN_BEGIN';
export const UPLOAD_INSCAN_SUCCESS = 'UPLOAD_INSCAN_SUCCESS';
export const UPLOAD_INSCAN_FAILURE = 'UPLOAD_INSCAN_FAILURE';
