import React, { PureComponent } from 'react';
import * as actions from './actions';
import './form.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Modal, Form, Divider, Icon, Select, Checkbox, Radio, Spin } from 'antd';
import { canAllow, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import { validationRules, Constants } from '../../../common/utils/constants';
import GooglePlaceAutoComplete from '../../ui/google-place-autocomplete/google.place-autocomplete';
import { validateServiceable } from '../../inbound/inbound-create/b2b-lite-booking/customer-management/actions';
import LocationMap from '../../maps/LocationMap';
import ZoneMapping from '../../shared-components/zone-mapping/ZoneMapping';
import PromiseTime from '../../shared-components/customer-promise-time/PromiseTime';
import moment from 'moment';

import { Mixpanel } from '../../../utils/mixpanel';

import _ from 'lodash';
const { confirm } = Modal;
const { Search } = Input;
let id = 0;

class LocationForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tableDataBox: [],
      searchText: '',
      searchedColumn: '',
      ownerType: null,
      locationType: true,
      selectedAddress: '',
      visibleServicable: false,
      visibleCutOff: false,
      cpthasSet: false,
      selectedPincodes1: [],
      selectedAreas: [],
      updatedserviableAreaMapping: [],
      ValidationRules: {
        Address: {
          validateStatus: "success",
        }
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
        }
      },
      locationPermission: {
        read: canAllow(permissionTags.settings.location.read),
        update: canAllow(permissionTags.settings.location.update),
        create: canAllow(permissionTags.settings.location.create),
        delete: canAllow(permissionTags.settings.location.delete),
        view: canAllow(permissionTags.settings.location.view),
      },
      locationId: props.match.params.id,
      isEdit: !(props.match.params.id > 0),
    }
    this.InputSearchRefOption = React.createRef();
    this.InputSearchRefList = React.createRef();
    if (!this.state.locationPermission.read) {
      windowRedirect('settings/dashboard/location');
    }
  }

  componentDidMount() {
    const user = JSON.parse(atob(localStorage.getItem('user')));
    const loggedPartnerId = localStorage.getItem('loggedPartnerId');
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name,
    });
    if (!this.state.isEdit) {
      this.props.getLocationById(this.state.locationId);
    }
    this.props.getAreas();

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.locationset !== prevProps.locationset) {
      this.props.fetchLocation(this.state.filterPayload);
      this.props.history.push('/appv2/settings/dashboard/location')
      if (this.state.cpthasSet === true) {
        if (this.state.isEdit) {
          this.customerPromiseSet(this.props?.locationset?.locations[0]?.id);
        } else {
          this.customerPromiseSet();
        }
      }
    }

    if (this.props.locationById !== prevProps.locationById) {
      const { locationById, form } = this.props;
      const groupPayload = {
        filters: {
          roles: ['PICKUP'],
          entityTypes: ['CUSTOMER'],
          deviceFilter: {
            deviceType: 'MOBILE',
            view: 'LITE_LOCATION_LIST'
          },
          entityIds: [locationById.entityId],
          status: true
        }
      };
      this.props.getlocationByGroup(groupPayload);
      this.entityTypeChange(locationById.entityType);
      if (locationById.name === locationById.billingName) {
        this.setState({ sameNames: true })
      }
      locationById.roles.length === 5 ? this.setState({ locationType: true }) : this.setState({ locationType: false })
      this.setCustomerPromiseData(locationById.customerPromiseTimeRuleMappingBOS);
      this.setServicableArea(locationById.serviableAreaMapping);
      this.setState({ updatedserviableAreaMapping: locationById.serviableAreaMapping, selectedAddress: locationById.address.address1, lat: locationById.address.latitude, lng: locationById.address.longitude, locationGroup: locationById.entityId, editCustomerPromise: locationById.customerPromiseTimeRuleMappingBOS, locationId: locationById.id })
      form.setFieldsValue({
        locationName: locationById.name,
        billingName: locationById.billingName,
        locationOwner: locationById.entityType,
        locationuser: locationById.entityId,
        shortCode: locationById.shortCode,
        types: locationById.roles.length === 5 ? 'hub' : 'branch',
        address: locationById.name,
        houseNo: locationById.address.address2,
        pincode: locationById.address.pincode.zipcode,
        latitude: locationById.address.latitude,
        longitude: locationById.address.longitude,
        landmark: locationById.address.landmark,
      });
    }
  }

  patchAreaMapping = selectedAreas => {
    this.setState({
      selectedAreas
    })
  }

  setServicableArea = data => {
    let pincode = [];
    if (data.length > 0) {
      data.map(x => {
        if (x.toEntityId) {
          pincode.push(x.toEntityId);
        }
      })
    }
    this.setState({ selectedPincodes1: pincode })
  }

  handleSubmit = e => {
    e.preventDefault();
    const _this = this;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let zoneMapp = [];
        let payload = {}
        let rolesData = [];
        // console.log("this.isManageServicableAllowed()", this.isManageServicableAllowed())
        if (this.isManageServicableAllowed()) {
          let selectedAreas = [...this.state.selectedAreas];
          let updatedserviableAreaMapping = [...this.state.updatedserviableAreaMapping]
          if (selectedAreas.length > 0) {
            selectedAreas.map(x => {
              x.cities.map(z => {
                z.pincodes.map(y => {
                  zoneMapp.push(this.getServiableAreaMapping(values.pincode, y.id));
                })
              })
            })
          } else if (updatedserviableAreaMapping.length > 0) {
            updatedserviableAreaMapping.map(y => {
              zoneMapp.push(this.getServiableAreaMapping(values.pincode, y.toEntityId));
            })
          } else {
            zoneMapp = [];
          }

        } else {
          zoneMapp.push(this.getServiableAreaMapping(values.pincode, values.pincode));
        }
        if (values['locationOwner'] === 'PARTNER' && zoneMapp.length <= 0 && (_.hasIn(this.props.app.configurations, 'locationserviceabilty') && this.props.app.configurations['locationserviceabilty'] == 'true')) {
          zoneMapp.push(this.getServiableAreaMapping(values.pincode, values.pincode));
        }

        if (values['locationOwner'] === 'PARTNER' && zoneMapp.length <= 0 && (_.hasIn(this.props.app.configurations, 'locationserviceabilty') && this.props.app.configurations['locationserviceabilty'] == 'false')) {
          zoneMapp = [];
        }


        rolesData = values.types === "hub" ? ["BILLING", "PICKUP", "DELIVERY", "HEAD_OFFICE", "BOOKING_OFFICE"] : ["BILLING", "PICKUP", "DELIVERY", "BOOKING_OFFICE"];
        if (!_this.state.isEdit) {
          payload = {
            id: _this.props.locationById.id,
            alias: _this.props.locationById.alias,
            role: _this.props.locationById.role,
            name: values.locationName,
            billingName: values.billingName,
            shortCode: values.shortCode,
            roles: rolesData,
            addressId: this.props.locationById.addressId,
            cityId: this.props.locationById.cityId,
            pincodeId: this.props.locationById.pincodeId,
            entity: this.props.locationById.entity,
            creator: this.props.locationById.creator,
            creatorId: this.props.locationById.creatorId,
            creatorType: this.props.locationById.creatorType,
            status: this.props.locationById.status,
            locationHash: this.props.locationById.locationHash,
            defaultUserId: this.props.locationById.defaultUserId,
            createdAt: this.props.locationById.createdAt,
            updatedAt: this.props.locationById.updatedAt,
            walletAmount: this.props.locationById.walletAmount,
            geofenceRadius: this.props.locationById.geofenceRadius,
            pincode: this.props.locationById.pincode,
            customerPromiseTimeRuleMappingBOS: this.props.locationById.customerPromiseTimeRuleMappingBOS,
            shortCodeBO: this.props.locationById.shortCodeBO,
            locationOpsType: this.props.locationById.locationOpsType,
            parentLocationId: this.props.locationById.parentLocationId,
            entityId: values.locationuser,
            entityType: values.locationOwner,
            address: {
              address1: this.state.selectedAddress,
              landmark: values.landmark ? values.landmark : null,
              cityName: this.state.city,
              cityCode: this.state.cityCode,
              state: this.state.stateName,
              country: this.state.country,
              zipcode: values.pincode,
              latitude: values.latitude,
              longitude: values.longitude,
              address2: values.houseNo ? values.houseNo : null,
            },
            serviableAreaMapping: zoneMapp
          };
          Mixpanel.track("Location Updation Initiated", { 'ERPLocationUpdated': moment().valueOf(), location_id: _this.props.locationById.id, location_pincode: values.pincode })

          _this.props.updateLocation(_this.props.locationById.id, payload);
        } else {
          payload = {
            name: values.locationName,
            billingName: values.billingName,
            shortCode: values.shortCode,
            roles: rolesData,
            entityId: values.locationuser,
            entityType: values.locationOwner,
            address: {
              address1: this.state.selectedAddress,
              landmark: values.landmark ? values.landmark : null,
              cityName: this.state.city,
              cityCode: this.state.cityCode,
              state: this.state.stateName,
              country: this.state.country,
              zipcode: values.pincode,
              latitude: values.latitude,
              longitude: values.longitude,
              address2: values.houseNo ? values.houseNo : null,
            },
            serviableAreaMapping: zoneMapp
          };
          // console.log("payload", payload)
          Mixpanel.track("Location Creation Initiated", { 'ERPLocationCreated': moment().valueOf(), location_pincode: values.pincode })
          _this.props.createLocation(payload);
        }
      }
    });
  }

  isManageServicableAllowed = () => {
    // const entityType = this.props.form.getFieldValue('locationOwner');
    // let abc = JSON.parse(atob(localStorage.getItem('_configrations')));
    // let owner = this.props.form.getFieldValue('locationuser');
    // console.log("owen", this.props.app.configurations['locationserviceabilty'])
    // // console.log("entu", )
    // if (entityType !== Constants.locationOwners[0].value) {
    //   return (
    //     this.props.app.configurations['excludeServiceablePartners'] &&
    //     this.props.app.configurations['excludeServiceablePartners'].indexOf(
    //       owner.toString()
    //     ) > -1
    //   );
    // }
    // return false;
    if (this.state.ownerType === 'CUSTOMER') {
      if (!_.hasIn(this.props.app.configurations, 'locationserviceabilty') || (_.hasIn(this.props.app.configurations, 'locationserviceabilty') && this.props.app.configurations['locationserviceabilty'] == 'false')) {
        return true;
      } else {
        return false;
      }
    }

    if (this.state.ownerType === 'PARTNER') {
      return true;
    }
  }

  getServiableAreaMapping = (from, to) => {
    let obj = {};
    if (typeof from === 'string') {
      obj = {
        ...obj,
        fromEntityString: from,
        fromEntityStringType: 'ZIPCODE'
      };
    } else {
      obj = {
        ...obj,
        fromEntityId: from,
        fromEntityType: 'PINCODE'
      };
    }
    if (typeof to === 'string') {
      obj = {
        ...obj,
        toEntityString: to,
        toEntityStringType: 'ZIPCODE'
      };
    } else {
      obj = {
        ...obj,
        toEntityId: to,
        toEntityType: 'PINCODE'
      };
    }

    return obj;
  }

  goBack = () => {
    this.props.history.goBack();
    this.props.fetchLocation(this.state.filterPayload);
  }

  entityTypeChange = (entityType) => {
    if (entityType === 'CUSTOMER') {
      this.props.fetchCustomers({ view: 'LITE_CUSTOMER_LIST' });
    } else if (entityType === 'VENDOR') {
      this.props.getVendors();
    } else {

    }
    this.setState({ ownerType: entityType })
  }

  getTypeownerData = (entityType) => {
    // console.log("this.props ?.ownerCustomer", this.props?.header?.partnerHeaderDetails?.partners)
    if (entityType === 'CUSTOMER') {
      return this.props?.ownerCustomer;
    } else if (entityType === 'VENDOR') {
      return this.props?.ownervendor;
    } else {
      if (!this.state.isEdit && this.props?.locationById?.entityId === Constants.PARTNER_ID) {
        return this.props?.header?.partnerHeaderDetails?.partners;
      }
      if (!this.state.isEdit && this.props?.locationById?.entityId !== Constants.PARTNER_ID) {
        const partner = this.props?.header?.partnerHeaderDetails?.partners.filter(p => p.linkPartnerId === this.props?.locationById?.entityId);
        // console.log("partner->>", partner)
        if (partner && partner.length > 0) {
          return [{ id: partner[0]?.linkPartnerBO?.id, name: partner[0]?.linkPartnerBO?.name }];
        }

      }
      return [{ id: Constants.PARTNER_ID, name: 'Self' }];
    }
  }

  handleLocationType = (e) => {
    let locationTypes = e.target.value === "hub" ? true : false;
    this.setState({ locationType: locationTypes })
  }

  copyLocationName = () => {
    const { setFieldsValue, getFieldsValue } = this.props.form;
    const formData = getFieldsValue();
    setFieldsValue({
      'billingName': formData.locationName,
    });
  }

  sameAslocationChange = (event) => {
    if (event.target.checked) {
      this.copyLocationName();
    }
    this.setState({ sameNames: event.target.checked })
  }

  getPincode(pincode, selectedAddress, lat, lng, city, cityCode, stateName, country) {
    const { setFieldsValue } = this.props.form;
    this.checkServiceable(pincode, selectedAddress);
    setFieldsValue({
      'latitude': lat,
      'longitude': lng,
      'pincode': pincode,
    });
    this.setState({
      selectedAddress, city, cityCode, stateName, country, lat, lng
    })
  }

  checkServiceable(pincode, address) {
    this.setState({
      selectedAddress: address
    })
    const loggedLocId = Constants.LOGGED_PARTNER_LOCATION_ID
    const payload = {
      locationServiceableRequests: [
        {
          pincode: {
            zipcode: pincode
          },
          location: {
            id: loggedLocId
          },
          referenceId: Date.now()
        }
      ]
    }
    this.props.validateServiceable(payload);
  }

  showServicable = () => {
    this.setState({
      visibleServicable: true,
    });
  };

  handleOkServicable = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visibleServicable: false });
    }, 3000);
  };

  handleCancelServicable = () => {
    this.setState({ visibleServicable: false });
  };

  showCutoff = () => {
    this.setState({
      visibleCutOff: true,
    });
  };

  handleOkshowCutoff = e => {
    this.setState({ visibleCutOff: false });
  };

  handleCancelshowCutoff = () => {
    this.setState({ loading: true, visibleCutOff: true });
  };

  onUserChange = (userName) => {
    this.setState({ locationGroup: userName });
  }

  setCustomerPromise = (customerPromisTime) => {
    console.log("customerPromisTime", customerPromisTime)
    this.setState({ customerPromisTime: customerPromisTime, visibleCutOff: false, cpthasSet: true })
  }

  setCustomerPromiseData = (data) => {
    data.map(x => {
      delete x.updatedAt;
      delete x.createdAt;
      x.customerPromiseTimeRuleBOS.map(y => {
        delete y.updatedAt;
        delete y.createdAt;
      })
    })
    this.setState({ customerPromisTimeedit: data })
  }

  customerPromiseSet = (id) => {
    let payload = {};
    let customerPromise = [...this.state.customerPromisTime];
    if (id) {
      customerPromise.map(x => {
        x.destinationEntityId = id;
      })
      payload = {
        "locationId": id,
        "customerPromiseTimeRuleMappingBOS": customerPromise,
      }
    } else {
      let idCopyArray = [...this.state.editCustomerPromise]
      customerPromise.map((x, key) => {
        x.destinationEntityId = this.state.locationId;
        if (x.sourceEntityId === idCopyArray[key]?.sourceEntityId) {
          x.id = idCopyArray[key]?.id ? idCopyArray[key]?.id : null;
          x.customerPromiseTimeRuleBOS.map((y, index) => {
            y.id = idCopyArray[key]?.customerPromiseTimeRuleBOS[index]?.id ? idCopyArray[key]?.customerPromiseTimeRuleBOS[index]?.id : null;
          })
        }
      })
      payload = {
        "locationId": this.state.locationId,
        "customerPromiseTimeRuleMappingBOS": customerPromise,
      }
    }

    this.props.getPromiseTime(payload)
  }

  dragMarker = (e) => {
    const { setFieldsValue } = this.props.form;
    // this.props.getCompleteData(e);
    setFieldsValue({
      'latitude': e.lat,
      'longitude': e.lng,
    });
  }

  render() {
    const { isEdit, visibleServicable, visibleCutOff, locationPermission } = this.state;
    const { form, isCustomerLoading } = this.props;
    const { getFieldDecorator } = form;
    let userOptions;
    if (this.state.ownerType !== null) {
      userOptions = this.getTypeownerData(this.state.ownerType)
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelAlign: "left",
      colon: false
    };

    // console.log("parter->>", (!isEdit && this.props?.locationById?.entityId === 'PARTNER'  && this.props?.locationById?.entityId !== Constants.PARTNER_ID))

    return (
      <>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Card className="CustomerDashBoard" title={<h3> <Icon onClick={this.goBack} style={{ margin: 15, marginLeft: 0 }} type="arrow-left" /> {isEdit ? 'Create' : 'Update'} Location</h3>}>
            <Spin spinning={isCustomerLoading}>
              <Row>
                <Col span={12}>
                  <Form.Item label="Location Name">
                    {getFieldDecorator('locationName', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Location name!',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Location name"
                        disabled={(!locationPermission.update && locationPermission.view && !isEdit)}
                      />
                    )}
                  </Form.Item>
                  <Divider orientation="center"><Checkbox disabled={(!locationPermission.update && locationPermission.view && !isEdit)} checked={this.state.sameNames} onChange={this.sameAslocationChange}>Same As Location Name</Checkbox></Divider>
                  <Form.Item label="Billing Name">
                    {getFieldDecorator('billingName', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Billing name!',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Billing name"
                        disabled={(!locationPermission.update && locationPermission.view && !isEdit)}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Owner Type">
                    {getFieldDecorator('locationOwner', {
                      rules: [
                        {
                          required: true,
                          message: "Please input Owner Type"
                        }
                      ],
                    })(
                      <Select disabled={(!locationPermission.update && locationPermission.view && !isEdit) || (!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID)} placeholder="Select Owner Type" showSearch filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                        showArrow={(!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID) ? false : true}
                        onChange={this.entityTypeChange}>
                        {Constants.locationOwners?.map(locOwn => (
                          <Select.Option
                            key={'owner' + locOwn.name}
                            value={locOwn.value}
                            label={locOwn.name}
                          >
                            {locOwn.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Owner">
                    {getFieldDecorator('locationuser', {
                      rules: [
                        {
                          required: true,
                          message: "Please input Owner"
                        }
                      ],
                    })(
                      <Select disabled={(!locationPermission.update && locationPermission.view && !isEdit) || (!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID)} placeholder="Select Owner" showSearch filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                        showArrow={(!isEdit && this.props?.locationById?.entityType === 'PARTNER' && this.props?.locationById?.entityId !== Constants.PARTNER_ID) ? false : true}
                        onChange={this.onUserChange}>
                        {userOptions?.map(user => (
                          <Select.Option
                            key={'user' + user.name}
                            value={user.id}
                            label={user.name}
                          >
                            {user.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item label="Short Code">
                    {getFieldDecorator('shortCode', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input Short Code!',
                        },
                        {
                          pattern: validationRules.shortCode,
                          message: "Short code can't be blank also special characters and spaces are not allowed /(except _ and -)."
                        },
                        { min: 3, message: `Short code can't be blank also special characters and spaces are not allowed /(except _ and -).` }
                      ],
                    })(
                      <Input
                        placeholder="Enter Short Code (3 to 10 characters)"
                        maxLength={10}
                        disabled={(!locationPermission.update && locationPermission.view && !isEdit)}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Type">
                    {getFieldDecorator('types', {
                      initialValue: 'hub',
                      rules: [
                        {
                          required: true,
                          message: `Type can't be blank!`
                        }
                      ],
                    })(
                      <Radio.Group onChange={this.handleLocationType} disabled={(!locationPermission.update && locationPermission.view && !isEdit)}>
                        <Radio value={'hub'}>HUB</Radio>
                        <Radio value={'branch'}>BRANCH</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <Form.Item label="Roles">
                    {getFieldDecorator('roles', {
                      initialValue: ['BILLING', 'PICKUP', 'DELIVERY', 'HEAD_OFFICE', 'BOOKING_OFFICE'],
                      rules: [
                        {
                          required: true,
                          message: "Atleast One Payment Type should be selected"
                        }
                      ],
                    })(
                      <Checkbox.Group
                        options={this.state.locationType ? Constants.rolesTypesHub : Constants.rolesTypesBranch}
                        disabled
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Address"
                    validateStatus={this.state.ValidationRules.Address.validateStatus}
                    help={this.state.ValidationRules.Address.help}
                  >
                    {getFieldDecorator('address', {
                      rules: [{ required: true, message: 'Please input your distination location!' }],
                    })(
                      <GooglePlaceAutoComplete
                        onSelection={(pincode, address, lat, lng, city, citycode, stateName, country) => this.getPincode(pincode, address, lat, lng, city, citycode, stateName, country)}
                        onChange={value => this.setState({ selectedAddress: value })}
                        selectedAddress={this.state.selectedAddress}
                        location="locationSet"
                        disabled={(!locationPermission.update && locationPermission.view && !isEdit)}
                      ></GooglePlaceAutoComplete>
                    )}
                  </Form.Item>
                  <Form.Item label="House No/Flat No">
                    {getFieldDecorator('houseNo', {
                      rules: [
                        {
                          required: false,
                          message: 'Please input House No/Flat No!',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter House No/Flat No"
                        disabled={(!locationPermission.update && locationPermission.view && !isEdit)}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Pincode">
                    {getFieldDecorator('pincode', {
                      rules: [
                        {
                          required: true,
                          message: 'Unable to find Pincode. please Enter Detailed Address.',
                        },
                      ],
                    })(
                      <Input disabled={true}
                        placeholder="Enter House Pincode"
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Coordinates">
                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                      {getFieldDecorator('latitude', {
                        rules: [
                          {
                            required: false,
                            message: 'Please input latitude!',
                          }
                        ],
                      })(
                        <Input
                          placeholder="latitude"
                          disabled={true}
                        />
                      )}
                    </div>
                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                      {getFieldDecorator('longitude', {
                        rules: [
                          {
                            required: false,
                            message: "Please input longitude"
                          }
                        ],
                      })(
                        <Input
                          placeholder="longitude"
                          disabled={true}
                        />
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item label="Landmark">
                    {getFieldDecorator('landmark', {
                      rules: [
                        {
                          required: false,
                          message: 'Please input Landmark!',
                        },
                      ],
                    })(
                      <Input
                        placeholder="Enter Landmark"
                        disabled={(!locationPermission.update && locationPermission.view && !isEdit)}
                      />
                    )}
                  </Form.Item>
                </Col>
                {this.state.lat && this.state.lng && <Col span={12}>
                  <Col span={2}></Col>
                  <Col span={22}>
                    <LocationMap lat={this.state?.lat} lng={this.state?.lng} selectedAddress={this.state?.selectedAddress} updateMarker={this.dragMarker} />
                  </Col>
                </Col>}
              </Row>
              <Row>
                <Col span={12}>
                  {this.state.ownerType === "CUSTOMER" && <Button style={{ float: "right" }} type="primary" onClick={this.showCutoff}>Add Cut-off / CPT</Button>}
                  {this.isManageServicableAllowed() && <Button style={{ float: "right" }} type="primary" onClick={this.showServicable}>Manage Servicable Areas</Button>}
                </Col>
              </Row>
              <Modal
                visible={visibleServicable}
                title="Manage Servicable Areas"
                onOk={this.handleOkServicable}
                onCancel={this.handleCancelServicable}
                width={800}
                footer={[
                  <Button key="back" onClick={this.handleCancelServicable}>
                    Close
                  </Button>,
                ]}
              >
                <ZoneMapping onSelectedAreaAdd={this.patchAreaMapping} selectedPincodes={this.state.selectedPincodes1} />
              </Modal>
              <Modal
                visible={visibleCutOff}
                title="Add CutOff / CPT"
                onOk={this.handleOkshowCutoff}
                onCancel={this.handleOkshowCutoff}
                width={1200}
                style={{top: 20}}
                footer={null}
              // footer={[
              //   <Button key="back" onClick={this.handleCancelshowCutoff}>
              //     Reset
              //   </Button>,
              //   <Button key="submit" type="primary" onClick={this.handleOkshowCutoff}>
              //     Save
              //   </Button>,
              // ]}
              >
                <PromiseTime
                  showReset={true}
                  buttonLabel={"Save"}

                  pickupLocationLabel={"Pickup Location"}
                  cutoffTimeLabel={"Pickup Cutoff Time"}
                  promiseTimeLabel={"Time"}
                  ruleTypeLabel={"CPT/ETA"}


                  removeIndex={1}
                  showAddition={true}
                  showAddingLocation={true}
                  cutomerPromise={this.setCustomerPromise}
                  locationGroup={this.state.locationGroup}
                  initialData={this.state.editCustomerPromise}
                  edit={!isEdit}
                />
              </Modal>
            </Spin>
          </Card>
          <Card>
            <Spin spinning={isCustomerLoading}>
              <Row>
                <Col span={2} offset={18} >
                  <Button size="large" onClick={this.goBack} >CANCEL</Button>
                </Col>
                <Col span={3}>
                  <Button size="large" htmlType="submit" type="primary">{isEdit ? 'CREATE' : 'UPDATE'}</Button>
                </Col>
              </Row>
            </Spin>
          </Card>
        </Form>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  cities: state.app.cities,
  isCustomerLoading: state.settingLocation.loading,
  ownerCustomer: state.settingLocation?.viewResponse,
  locationset: state.settingLocation?.locations,
  ownervendor: state.settingLocation?.vendors,
  locationById: state.settingLocation?.locationById,
  areas: state.settingLocation?.stateAreas,
  serviceableResp: state.RetailerCustomer.validServiceables,
  groupLocation: state.settingLocation?.groupLocation,
  app: state?.app,
  header: state?.header
});
const mapDispatchToProps = dispatch => ({
  getLocationById: param => dispatch(actions.getLocationById(param)),
  createLocation: (location) => dispatch(actions.createLocation(location)),
  updateLocation: (id, location) => dispatch(actions.updateLocation(id, location)),
  fetchLocation: param => dispatch(actions.fetchLocation(param)),
  fetchCustomers: param => dispatch(actions.fetchCustomers(param)),
  getVendors: () => dispatch(actions.getVendors()),
  getAreas: () => dispatch(actions.getAreas()),
  validateServiceable: (payload) => dispatch(validateServiceable(payload)),
  getPromiseTime: (payload) => dispatch(actions.getPromiseTime(payload)),
  getlocationByGroup: (payload) => dispatch(actions.getlocationByGroup(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Location', onValuesChange: (props, val) => console.log(props.form.getFieldsValue()) })(LocationForm));