import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './form.scss';
import { Card, Input, Row, Col, Icon, Form, Spin, Tree } from 'antd';
import { notifyApiError } from '../../../common/utils/utils';
import { getPermissions, createRole, updateRole } from './actions';

import _ from 'lodash';

const { TreeNode } = Tree;
const application = atob(localStorage.getItem('application'))

class RoleForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            pendingList: [],
            selectedList: [],
            pendingLoading: false,
            pendingSearch: '',
            selectedSearch: '',
            tempSelectedList: []
        }
        this.props.getPermissions();
    }

    componentDidMount() {
    }

    removeDuplicates = data => (_.uniqWith(data, _.isEqual))

    transformPermissions = selectedPermission => {
        const newPermission = []
        selectedPermission.map(mod => {
            mod.children.map(smod => {
                smod.children.map(a => {
                    newPermission.push({ id: a.id })
                })
            })
        })

        return newPermission;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.permissions !== prevProps.permissions) {
            this.setState({
                pageLoading: false
            }, () => {
                this.prepareTreeStructure(this.props.permissions.filter(p => p.application === application), 'pendingList')
                this.prepareTreeStructure(this.props.selectedPermission, 'tempSelectedList')

            })
        }

        if (prevProps.selectedPermission !== this.props.selectedPermission) {
            this.setState({
                pendingList: [],
                tempSelectedList: [],
                selectedList: []
            }, () => {
                this.prepareTreeStructure(this.props.permissions.filter(p => p.application === application), 'pendingList')
                this.prepareTreeStructure(this.props.selectedPermission, 'tempSelectedList')
            })
        }

        if (prevState.pendingList.length !== this.state.pendingList.length) {
            this.prepareTreeStructure(this.props.selectedPermission, 'tempSelectedList')
        }
        if (prevState.tempSelectedList.length !== this.state.tempSelectedList.length) {
            const { pendingList } = this.state;
            let newPendingList = []
            this.state.tempSelectedList.sort(this.dataSorting).map((m) => {
                newPendingList = m.children.map((s) => {
                    s.children.map((a) => {
                        this.handlerPincode(m, s, a, 'ADD')
                    })
                })
            })

            newPendingList = pendingList.map(m => {

                const isFound = this.state.tempSelectedList.filter(tm => tm.title === m.title)
                if (isFound.length === 0) return { ...m }

                const children = m.children.map(s => {
                    const isChild = isFound[0].children.filter(ss => ss.title === s.title)
                    if (isChild.length === 0) return { ...s }

                    const child = _.differenceWith(s.children, isChild[0].children, _.isEqual)

                    return { ...s, children: [...child] }
                }).filter(s => s.children.length > 0)

                return { ...m, children: [...children] }
            }).filter(s => s.children.length > 0)

            this.setState({
                pendingList: newPendingList
            })
        }
    }
    prepareTreeStructure = (permissions, state) => {
        const modules = {}, test = {}
        permissions.forEach(mod => {
            modules[mod.module] = permissions.filter(p => p.module === mod.module)
        })

        test[state] = Object.keys(modules).map((mKey, index) => {
            return this.getPermissonModule(`${index}-${mKey}`, modules[mKey])
        })

        this.setState({
            [state]: test[state]
        });
    }

    getPermissonModule = (module, submodules) => {
        const subMod = {};
        submodules.forEach(sMod => {
            subMod[sMod.submodule] = submodules.filter(sm => sm.submodule === sMod.submodule)
        })

        const children = Object.keys(subMod).map((mKey, index) => {
            return this.getPermissionSubmodule(`${index}-${mKey}`, subMod[mKey])
        })


        const mod = {
            key: module,
            title: module.split('-')[1],
            children
        }
        return mod;
    }

    getPermissionSubmodule = (subModule, action) => {
        const children = action.map((a, index) => ({
            id: a.id,
            key: `${a.id}-${a.action}`,
            title: a.action
        }))
        const sMod = {
            key: subModule,
            title: subModule.split('-')[1],
            children
        }

        return sMod;
    }

    isSelectedStateExisted() {
        const { selectedState, pendingList, selectedList } = this.state;
        return (pendingList.find((mod => mod.title === selectedState)) || selectedList.find((mod => mod.title === selectedState)));
    }

    prepareTreeSturture = () => {
        const { selectedState, pendingList, selectedList } = this.state;
        const { areaList } = this.props;

        if (this.isSelectedStateExisted()) {
            notifyApiError('Module Already Added!', 'Error')
            return;
        }

        const newlist = areaList.filter(mod => mod.stateName === selectedState);

        this.setState({
            pendingList: [...pendingList, ...newlist]
        })
    }

    onNodeSelected = (selectedKeys, { selectedNodes, node }) => {
        this.setState({
            selectedKeys,
            selectedNodes,
            node
        })
    }

    handerState = (name, event) => {
        const { pendingList, selectedList } = this.state;
        const addPermissionSet = event === 'ADD' ? [...pendingList] : [...selectedList];
        const removePermissionSet = event !== 'REMOVE' ? [...selectedList] : [...pendingList];
        const selectedState = addPermissionSet.find(mod => mod.title === name)
        let newAreas = [];
        newAreas = removePermissionSet.map(mod => {
            if (mod.title === name) {
                const newChilds = mod.children.map(sm => {
                    const found = selectedState.children.filter(ssm => ssm.title === sm.title)

                    if (found.length > 0) {
                        return { ...sm, children: [...sm.children, ...found[0].children] }
                    } else {
                        return sm;
                    }
                })
                return { ...mod, children: [...newChilds] }
            }
            return mod
        })
        if (!newAreas.find(state => state.title === name)) {
            newAreas.push(selectedState)
        }

        if (event === 'ADD') {
            this.setState({
                selectedList: [...newAreas],
                pendingList: [...pendingList.filter(state => state.title !== name)],
                pendingLoading: false
            }, () => {
                this.props.onSelectedPermissionsChange(this.transformPermissions(this.state.selectedList));
            })
        } else {
            this.setState({
                pendingList: [...newAreas],
                selectedList: [...selectedList.filter(state => state.title !== name)],
                pendingLoading: false
            }, () => {
                this.props.onSelectedPermissionsChange(this.transformPermissions(this.state.selectedList));
            })
        }
    }

    handlerCity = (newState, newCity, event) => {
        let { pendingList, selectedList } = this.state;
        if (event === 'ADD') {
            if (selectedList.find(state => state.title === newState.title)) {
                selectedList = selectedList.map(state => {
                    if (state.title === newState.title) {
                        if (state.children.find(city => city.title === newCity.title)) {
                            let cityNew = state.children.map(city => {
                                if (city.title === newCity.title) {
                                    city.children = [...city.children, ...newCity.children]
                                }
                                return city
                            })
                            state.children = [...cityNew]
                        } else {
                            state.children.push({ ...newCity })
                        }
                    }

                    return state;
                }).filter(state => state.children.length > 0)
            } else {
                selectedList.push({ ...newState, children: [{ ...newCity }] })
            }

            pendingList = pendingList.map(state => {
                if (state.title === newState.title) {
                    let cityNew = state.children.map(city => {
                        if (city.title === newCity.title) {
                            return { ...city, children: [] }
                        }

                        return city;
                    })

                    return { ...state, children: cityNew.filter(city => city.children.length > 0) }
                }

                return state;
            }).filter(state => state.children.length > 0)

        } else {
            if (pendingList.find(state => state.title === newState.title)) {
                pendingList = pendingList.map(state => {
                    if (state.title === newState.title) {
                        if (state.children.find(city => city.title === newCity.title)) {
                            let cityNew = state.children.map(city => {
                                if (city.title === newCity.title) {
                                    city.children = [...city.children, ...newCity.children]
                                }
                                return city
                            })
                            state.children = [...cityNew]
                        } else {
                            state.children.push({ ...newCity })
                        }
                    }

                    return state;
                }).filter(state => state.children.length > 0)
            } else {
                pendingList.push({ ...newState, children: [{ ...newCity }] })
            }

            selectedList = selectedList.map(state => {
                if (state.stateName === newState.stateName) {
                    let cityNew = state.children.map(city => {
                        if (city.title === newCity.title) {
                            return { ...city, children: [] }
                        }

                        return city;
                    })

                    return { ...state, children: cityNew.filter(city => city.children.length > 0) }
                }

                return state;
            }).filter(state => state.children.length > 0)

        }
        this.setState({
            pendingList,
            selectedList,
            pendingLoading: false
        }, () => {
            this.props.onSelectedPermissionsChange(this.transformPermissions(this.state.selectedList));
        })
    }

    handlerPincode = (newState, newCity, newPincode, event) => {
        let { pendingList, selectedList } = this.state;
        if (event === 'ADD') {
            if (selectedList.find(state => state.title === newState.title)) {
                selectedList = selectedList.map(state => {
                    if (state.title === newState.title) {
                        if (state.children.find(city => city.title === newCity.title)) {
                            let cityNew = state.children.map(city => {
                                if (city.title === newCity.title) {
                                    city.children.push(newPincode);
                                }
                                return city
                            })
                        } else {
                            state.children.push({ ...newCity, children: [newPincode] })
                        }
                    }

                    return state;
                }).filter(state => state.children.length > 0)
            } else {
                selectedList.push({ ...newState, children: [{ ...newCity, children: [newPincode] }] })
            }

            pendingList = pendingList.map(state => {
                if (state.title === newState.title) {
                    let cityNew = state.children.map(city => {
                        if (city.title === newCity.title) {
                            return { ...city, children: city.children.filter(pin => pin.title !== newPincode.title) }
                        }

                        return city;
                    })

                    return { ...state, children: cityNew.filter(city => city.children.length > 0) }
                }

                return state;
            })
        } else {
            if (pendingList.find(state => state.title === newState.title)) {
                pendingList = pendingList.map(state => {
                    if (state.title === newState.title) {
                        if (state.children.find(city => city.title === newCity.title)) {
                            let cityNew = state.children.map(city => {
                                if (city.title === newCity.title) {
                                    city.children.push(newPincode);
                                }
                                return city
                            })
                        } else {
                            state.children.push({ ...newCity, children: [newPincode] })
                        }
                    }

                    return state;
                }).filter(state => state.children.length > 0)
            } else {
                pendingList.push({ ...newState, children: [{ ...newCity, children: [newPincode] }] })
            }

            selectedList = selectedList.map(state => {
                if (state.title === newState.title) {
                    let cityNew = state.children.map(city => {
                        if (city.title === newCity.title) {
                            return { ...city, children: city.children.filter(pin => pin.title !== newPincode.title) }
                        }

                        return city;
                    })

                    return { ...state, children: cityNew.filter(city => city.children.length > 0) }
                }

                return state;
            }).filter(state => state.children.length > 0)
        }

        this.setState({
            pendingList: pendingList.filter(city => city.children.length > 0),
            selectedList: [...selectedList.map(m => {
                return { ...m, children: m.children.map(s => ({ ...s, children: this.removeDuplicates([...s.children]) })) }
            })].filter(city => city.children.length > 0),
            pendingLoading: false
        }, () => {
            this.props.onSelectedPermissionsChange(this.transformPermissions(this.state.selectedList));
        })
    }

    getPendingTreeNode(mod) {
        return (<TreeNode
            style={{ lineHeight: mod?.title?.length > 16 ? 3 : 1.5, whiteSpace: mod?.title?.length > 16 ? 'normal' : 'nowrap' }}
            title={(
                <Row>
                    <Col span={22}>{mod.title}</Col>
                    <Col span={2} className="text-right" onClick={() => this.handerState(mod.title, 'ADD')} >
                        <Icon type="plus" />
                    </Col>
                </Row>
            )}
            key={mod.key}>
            {mod.children.sort((a, b) => this.dataSorting(a, b)).map(subMod =>
                <TreeNode
                    style={{ lineHeight: subMod?.title?.length > 16 ? 3 : 1.5, whiteSpace: subMod?.title?.length > 16 ? 'normal' : 'nowrap' }}
                    title={(
                        <Row>
                            <Col span={22}>{subMod.title}</Col>
                            <Col span={2} className="text-right" onClick={() => this.handlerCity(mod, subMod, 'ADD')} >
                                <Icon type="plus" />
                            </Col>
                        </Row>)}
                    key={subMod.key}>
                    {subMod.children.sort((a, b) => this.dataSorting(a, b)).map(actions =>
                        <TreeNode
                            style={{ lineHeight: actions?.title?.length > 16 ? 3 : 1.5, whiteSpace: actions?.title?.length > 16 ? 'normal' : 'nowrap' }}
                            title={<Row>
                                <Col span={22}>{actions.title}</Col>
                                <Col className="text-right" span={2} onClick={() => this.handlerPincode(mod, subMod, actions, 'ADD')} >
                                    <Icon type="plus" />
                                </Col>
                            </Row>}
                            key={actions.key}>
                        </TreeNode>)}
                </TreeNode>)}
        </TreeNode>)
    }

    getSelectedTreeNode(mod) {
        return (<TreeNode
            style={{ lineHeight: mod?.title?.length > 16 ? 3 : 1.5, whiteSpace: mod?.title?.length > 16 ? 'normal' : 'nowrap' }}
            title={(
                <Row>
                    <Col span={22}>{mod.title}</Col>
                    <Col span={2} onClick={() => this.handerState(mod.title, 'REMOVE')} >
                        <Icon type="delete" />
                    </Col>
                </Row>
            )}
            key={mod.key}>
            {mod.children.sort((a, b) => this.dataSorting(a, b)).map(subMod =>
                <TreeNode
                    style={{ lineHeight: subMod?.title?.length > 16 ? 3 : 1.5, whiteSpace: subMod?.title?.length > 16 ? 'normal' : 'nowrap' }}
                    title={(
                        <Row>
                            <Col span={22}>{subMod.title}</Col>
                            <Col span={2} onClick={() => this.handlerCity(mod, subMod, 'REMOVE')} >
                                <Icon type="delete" />
                            </Col>
                        </Row>)}
                    key={subMod.key}>
                    {subMod.children.sort((a, b) => this.dataSorting(a, b)).map(actions =>
                        <TreeNode
                            style={{ lineHeight: actions?.title?.length > 16 ? 3 : 1.5, whiteSpace: actions?.title?.length > 16 ? 'normal' : 'nowrap' }}
                            title={<Row>
                                <Col span={22}>{actions.title}</Col>
                                <Col span={2} onClick={() => this.handlerPincode(mod, subMod, actions, 'REMOVE')} >
                                    <Icon type="delete" />
                                </Col>
                            </Row>}
                            key={actions.key}>
                        </TreeNode>)}
                </TreeNode>)}
        </TreeNode>)
    }

    onSearch = (val, type) => {
        this.setState({
            [type]: val
        })
    }

    filterSearchedValue = (permission, search) => {
        const tempArr = []
        permission.map(modules => {
            if (!search) return tempArr.push(modules);
            const subModules = []
            modules.children.map(subMod => {
                let actions = []
                if (!isNaN(search)) {
                    actions = subMod.children.filter(action => action.title.includes(Number(search)));

                    if (actions.length > 0) subModules.push({ ...subMod, children: actions })
                }
                if (subMod.title.toLowerCase().includes(search.toLowerCase()))
                    subModules.push(subMod)
            }
            )

            if (subModules.length > 0)
                tempArr.push({ ...modules, children: subModules })
            else {
                if (modules.title.toLowerCase().includes(search.toLowerCase())) {
                    tempArr.push(modules)
                }
            }
        })

        return tempArr;
    }

    dataSorting(a, b) {
        if (a.title.toLowerCase() < b.title.toLowerCase())
            return -1
        if (a.title.toLowerCase() > b.title.toLowerCase())
            return 1
        return 0;
    }

    render() {
        const { pendingList, selectedList, pendingLoading, pendingSearch, selectedSearch, pageLoading } = this.state;
        const { form, isLoading } = this.props;
        let filteredPendingAreas = this.filterSearchedValue([...pendingList], pendingSearch)
        let filteredSelectedAreas = this.filterSearchedValue([...selectedList], selectedSearch)
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        return (
            <>
                <div className="role-form">
                    <Spin spinning={isLoading}>
                        <Row>
                            <Form.Item>
                                <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                    <h4>Pending List</h4>
                                    <Card ghost={true} title={<Input.Search allowClear placeholder="Search" onChange={(e) => this.onSearch(e.target.value, 'pendingSearch')} />} bodyStyle={{ padding: 5, height: '300px', overflow: 'scroll' }}>
                                        <Spin spinning={pendingLoading}>
                                            <Tree

                                                switcherIcon={<Icon type="down" />}
                                                onSelect={this.onNodeSelected}
                                            >
                                                {
                                                    filteredPendingAreas && filteredPendingAreas.sort(this.dataSorting)?.map(state =>
                                                        this.getPendingTreeNode(state))
                                                }
                                            </Tree>
                                        </Spin>
                                    </Card>
                                </div>
                                <span style={{ display: 'inline-block', width: '24px' }}></span>
                                <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                    <h4>Selected List</h4>
                                    <Card ghost title={<Input.Search allowClear placeholder="Search" onChange={(e) => this.onSearch(e.target.value, 'selectedSearch')} />} bodyStyle={{ padding: 5, height: '300px', overflow: 'scroll' }}>
                                        <Spin spinning={pendingLoading}>
                                            <Tree

                                                switcherIcon={<Icon type="down" />}
                                                onSelect={this.onSelect}
                                            >
                                                {
                                                    filteredSelectedAreas && filteredSelectedAreas.sort(this.dataSorting).map(state => (
                                                        this.getSelectedTreeNode(state)
                                                    ))
                                                }
                                            </Tree>
                                        </Spin>
                                    </Card>
                                </div>
                            </Form.Item>
                        </Row>
                    </Spin>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    permissions: state.settingsRoles.permissions,
    isLoading: state.settingsRoles.loading
});
const mapDispatchToProps = dispatch => ({
    getPermissions: () => dispatch(getPermissions()),
    createRole: role => dispatch(createRole(role)),
    updateRole: role => dispatch(updateRole(role))
});
const formWarppedComponent = Form.create({ name: 'Role', onValuesChange: (props, val) => { } })(RoleForm)
export default connect(mapStateToProps, mapDispatchToProps)(formWarppedComponent);