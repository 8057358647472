
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const ManageFleetReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.GET_FE_SUMMARY_BEGIN: return fetchFesummaryBegin(state);
        case _Constants.GET_FE_SUMMARY_SUCCESS: return fetchFesummarySuccess(state, action);
        case _Constants.GET_FE_SUMMARY_FAILURE: return fetchFesummaryFailure(state, action);
        case _Constants.GET_FE_SUMMARY_PAGINATION_BEGIN: return fetchFesummaryPageBegin(state);
        case _Constants.GET_FE_SUMMARY_PAGINATION_SUCCESS: return fetchFesummaryPageSuccess(state, action);
        case _Constants.GET_FE_SUMMARY_PAGINATION_FAILURE: return fetchFesummaryPageFailure(state, action);
        case _Constants.GET_CITY_LIST_BEGIN: return fetchCityListBegin(state);
        case _Constants.GET_CITY_LIST_SUCCESS: return fetchCityListSuccess(state, action);
        case _Constants.GET_CITY_LIST_FAILURE: return fetchCityListFailure(state, action);
        case _Constants.GET_CITY_LIST_ID_BEGIN: return fetchCityByIdListBegin(state);
        case _Constants.GET_CITY_LIST_ID_SUCCESS: return fetchCityByIdListSuccess(state, action);
        case _Constants.GET_CITY_LIST_ID_FAILURE: return fetchCityByIdListFailure(state, action);
        case _Constants.GET_KYC_SUMMARY_BEGIN: return fetchKycsummaryBegin(state);
        case _Constants.GET_KYC_SUMMARY_SUCCESS: return fetchKycsummarySuccess(state, action);
        case _Constants.GET_KYC_SUMMARY_FAILURE: return fetchKycsummaryFailure(state, action);
        case _Constants.GET_KYC_SUMMARY_PAGINATION_BEGIN: return fetchKycsummaryPageBegin(state);
        case _Constants.GET_KYC_SUMMARY_PAGINATION_SUCCESS: return fetchKycsummaryPageSuccess(state, action);
        case _Constants.GET_KYC_SUMMARY_PAGINATION_FAILURE: return fetchKycsummaryPageFailure(state, action);
        case _Constants.GET_USER_DATA_BEGIN: return userDataBegin(state);
        case _Constants.GET_USER_DATA_SUCCESS: return userDataSuccess(state, action);
        case _Constants.GET_USER_DATA_FAILURE: return userDataFailure(state, action);
        case _Constants.GET_USER_APPROV_BEGIN: return userApproveBegin(state);
        case _Constants.GET_USER_APPROV_SUCCESS: return userApproveSuccess(state, action);
        case _Constants.GET_USER_APPROV_FAILURE: return userApproveFailure(state, action);
        case _Constants.GET_ONDUTY_USER_DATA_BEGIN: return userOnDutyBegin(state);
        case _Constants.GET_ONDUTY_USER_DATA_SUCCESS: return userOnDutySuccess(state, action);
        case _Constants.GET_ONDUTY_USER_DATA_FAILURE: return userOnDutyFailure(state, action);
        case _Constants.GET_FETCH_TEMPLATE_BEGIN: return fetchTemplatesBegin(state);
        case _Constants.GET_FETCH_TEMPLATE_SUCCESS: return fetchTemplatesSuccess(state, action);
        case _Constants.GET_FETCH_TEMPLATE_FAILURE: return fetchTemplatesFailure(state, action);
        case _Constants.GET_RUNSHEET_LIST_BEGIN: return runsheetListBegin(state);
        case _Constants.GET_RUNSHEET_LIST_SUCCESS: return runsheetListSuccess(state, action);
        case _Constants.GET_RUNSHEET_LIST_FAILURE: return runsheetListFailure(state, action);
        default:
            return state;
    }
}

function fetchTemplatesBegin(state, action) {
    return {
        ...state,
    };
}

function fetchTemplatesSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchTemplatesFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        notificationTemplates: {}
    };
}

function userOnDutyBegin(state, action) {
    return {
        ...state,
    };
}

function userOnDutySuccess(state, action) {
    return {
        ...state,
        useronDutyData : action.payload
    };
}

function userOnDutyFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        useronDutyData: {}
    };
}

function userApproveBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function userApproveSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function userApproveFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

function userDataBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function userDataSuccess(state, action) {
    return {
        ...state,
        loading: false,
        userData : action.payload
    };
}

function userDataFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        userData: {}
    };
}


function fetchFesummaryPageBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchFesummaryPageSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedsummaryData: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedsummaryData ? state.PaginatedsummaryData : {}, action ?.payload ?.data , action.isReset),
        ...action.payload
    };
}

function fetchFesummaryPageFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        PaginatedsummaryData: {}
    };
}

function fetchFesummaryBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchFesummarySuccess(state, action) {
    return {
        ...state,
        loading: false,
        feSummary: action.payload
    };
}

function fetchFesummaryFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        feSummary: {}
    };
}

function fetchCityListBegin(state, action) {
    return {
        ...state,
    };
}

function fetchCityListSuccess(state, action) {
    return {
        ...state,
        cityList: {...action.payload}
    };
}

function fetchCityListFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        cityList: {}
    };
}

function fetchCityByIdListBegin(state, action) {
    return {
        ...state,
    };
}

function fetchCityByIdListSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchCityByIdListFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        cityListById: []
    };
}

function fetchKycsummaryBegin(state, action) {
    return {
        ...state,
    };
}

function fetchKycsummarySuccess(state, action) {
    return {
        ...state,
        kycSummary: action.payload
    };
}

function fetchKycsummaryFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        kycSummary: {}
    };
}

function fetchKycsummaryPageBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchKycsummaryPageSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedKycsummaryData: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedsummaryData ? state.PaginatedsummaryData : {}, action ?.payload ?.data, action.isReset),
        ...action.payload
    };
}

function fetchKycsummaryPageFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        PaginatedKycsummaryData: {}
    };
}


function runsheetListBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function runsheetListSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedRunsheetData: storePaginatedData(1, state.PaginatedsummaryData ? state.PaginatedsummaryData : {}, action ?.payload ?.runsheetListDetails, action.isReset),
        ...action.payload
    };
}

function runsheetListFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        PaginatedRunsheetData: {}
    };
}