export const FETCH_ZOHO_DASHBOARD_LIST_BEGIN = "FETCH_ZOHO_DASHBOARD_LIST_BEGIN";
export const FETCH_ZOHO_DASHBOARD_LIST_SUCCESS = "FETCH_ZOHO_DASHBOARD_LIST_SUCCESS";
export const FETCH_ZOHO_DASHBOARD_LIST_FAILURE = "FETCH_ZOHO_DASHBOARD_LIST_FAILURE";

export const FETCH_ZOHO_PERMISSIONS_LIST_BEGIN = "FETCH_ZOHO_PERMISSIONS_LIST_BEGIN";
export const FETCH_ZOHO_PERMISSIONS_LIST_SUCCESS = "FETCH_ZOHO_PERMISSIONS_LIST_SUCCESS";
export const FETCH_ZOHO_PERMISSIONS_LIST_FAILURE = "FETCH_ZOHO_PERMISSIONS_LIST_FAILURE";


export const FETCH_ZOHO_DASHBOARD_BYID_BEGIN = "FETCH_ZOHO_DASHBOARD_BYID_BEGIN";
export const FETCH_ZOHO_DASHBOARD_BYID_SUCCESS = "FETCH_ZOHO_DASHBOARD_BYID_SUCCESS";
export const FETCH_ZOHO_DASHBOARD_BYID_FAILURE = "FETCH_ZOHO_DASHBOARD_BYID_FAILURE";


export const FETCH_ZOHO_STATUS_BEGIN = "FETCH_ZOHO_STATUS_BEGIN";
export const FETCH_ZOHO_STATUS_SUCCESS = "FETCH_ZOHO_STATUS_SUCCESS";
export const FETCH_ZOHO_STATUS_FAILURE = "FETCH_ZOHO_STATUS_FAILURE";


export const FETCH_ZOHO_CREATE_BEGIN = "FETCH_ZOHO_CREATE_BEGIN";
export const FETCH_ZOHO_CREATE_SUCCESS = "FETCH_ZOHO_CREATE_SUCCESS";
export const FETCH_ZOHO_CREATE_FAILURE = "FETCH_ZOHO_CREATE_FAILURE";


export const FETCH_ZOHO_UPDATE_BEGIN = "FETCH_ZOHO_UPDATE_BEGIN";
export const FETCH_ZOHO_UPDATE_SUCCESS = "FETCH_ZOHO_UPDATE_SUCCESS";
export const FETCH_ZOHO_UPDATE_FAILURE = "FETCH_ZOHO_UPDATE_FAILURE";