import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsPane from "../../components/shared/TabsPane/TabsPane";
import Lsnalert from "../../components/shared/Lsnalert/Lsnalert";
import Sidebar from "../../components/shared/sidebar/Sidebar";
import Header from "../../components/shared/header/Header";
import RouteWithTitle from "../RouteWithTitle";
import "../app.scss";
import AuditNotice from "../../components/inventory/audit/audit-notice";

class MainLayout extends Component {

  render() {
    const { path, component, name } = this.props;
    return (
      <>
        <Header {...this.props}></Header>
        <Layout>
          <Sidebar {...this.props} />
          <Layout className="padding-container">
            <AuditNotice />
            <Lsnalert></Lsnalert>
            <Layout className="tabs-container">
              <TabsPane  {...this.props} />
            </Layout>
            <Layout>
              {path && component && <RouteWithTitle key={name} exact={true} path={path} component={component} name={name} />}
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(MainLayout))
);
