import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const RoleReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_ROLES_BEGIN: return fetchRolesBegin(state);
        case _Constants.FETCH_ROLES_SUCCESS: return fetchRolesSuccess(state, action);
        case _Constants.FETCH_ROLES_FAILURE: return fetchRolesFailure(state, action);

        case _Constants.FETCH_ROLE_BEGIN: return fetchRoleBegin(state);
        case _Constants.FETCH_ROLE_SUCCESS: return fetchRoleSuccess(state, action);
        case _Constants.FETCH_ROLE_FAILURE: return fetchRoleFailure(state, action);

        case _Constants.FETCH_PERMISSIONS_BEGIN: return fetchPermissionsBegin(state);
        case _Constants.FETCH_PERMISSIONS_SUCCESS: return fetchPermissionsSuccess(state, action);
        case _Constants.FETCH_PERMISSIONS_FAILURE: return fetchPermissionsFailure(state, action);

        case _Constants.MODIFY_ROLE_BEGIN: return modifyRolesBegin(state);
        case _Constants.MODIFY_ROLE_SUCCESS: return modifyRolesSuccess(state, action);
        case _Constants.MODIFY_ROLE_FAILURE: return modifyRolesFailure(state, action);

        case _Constants.RESET_ROLES: return resetRoles(state, action);
        default:
            return state;
    }
}
function resetRoles(state, action) {
    delete state.roleId;
    delete state.selectedRole;
    return { ...state }
}
function modifyRolesBegin(state, action) {
    return {
        ...state,
        loading: true,
        roleId: null
    };
}

function modifyRolesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload,
    };
}

function modifyRolesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        roleId: null
    };
}

function fetchRoleBegin(state, action) {
    return {
        ...state,
        loading: true,
        selectedRole: {}
    };
}

function fetchRoleSuccess(state, action) {
    return {
        ...state,
        loading: false,
        selectedRole: { ...action.payload.role }
    };
}

function fetchRoleFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        selectedRole: null
    };
}

function fetchRolesBegin(state, action) {
    return {
        ...state,
        loading: true,
        roles: []
    };
}

function fetchRolesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function fetchRolesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        roles: []
    };
}

function fetchPermissionsBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchPermissionsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function fetchPermissionsFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        permissions: []
    };
}