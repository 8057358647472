import * as CONSTANTS from './constants';

import { removeDuplicateLocations } from '../../../common/utils/utils';


export const vendorCreateReducer = (state = {}, action) => {
  switch (action.type) {


    case CONSTANTS.GET_PARTNER_LOCATION_FETCH_BEGIN: return getLocationFetchBegin(state, action);

    case CONSTANTS.GET_PARTNER_LOCATION_FETCH_SUCCESS: return getLocationFetchSuccess(state, action);

    case CONSTANTS.GET_PARTNER_LOCATION_FETCH_FAILURE: return getLocationFetchFailure(state, action);


    case CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_BEGIN: return getCustomerFetchBegin(state, action);

    case CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_SUCCESS: return getCustomerFetchSuccess(state, action);

    case CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_FAILURE: return getCustomerFetchFailure(state, action);

    case CONSTANTS.GET_CITIES_FETCH_BEGIN: return getCitiesFetchBegin(state, action);

    case CONSTANTS.GET_CITIES_FETCH_SUCCESS: return getCitiesFetchSuccess(state, action);

    case CONSTANTS.GET_CITIES_FETCH_FAILURE: return getCitiesFetchFailure(state, action);


    case CONSTANTS.GET_PINCODES_FETCH_BEGIN: return getPincodesFetchBegin(state, action);

    case CONSTANTS.GET_PINCODES_FETCH_SUCCESS: return getPincodesFetchSuccess(state, action);

    case CONSTANTS.GET_PINCODES_FETCH_FAILURE: return getPincodesFetchFailure(state, action);



    case CONSTANTS.SET_VENDOR_CREATE_BEGIN: return setVendorCreateBegin(state, action);

    case CONSTANTS.SET_VENDOR_CREATE_SUCCESS: return setVendorCreateSuccess(state, action);

    case CONSTANTS.SET_VENDOR_CREATE_FAILURE: return setVendorCreateFailure(state, action);



    case CONSTANTS.SET_VENDOR_UPDATE_BEGIN: return setVendorUpdateBegin(state, action);

    case CONSTANTS.SET_VENDOR_UPDATE_SUCCESS: return setVendorUpdateSuccess(state, action);

    case CONSTANTS.SET_VENDOR_UPDATE_FAILURE: return setVendorUpdateFailure(state, action);


    case CONSTANTS.GET_VENDORS_LIST_BEGIN: return getVendorsListBegin(state, action);

    case CONSTANTS.GET_VENDORS_LIST_SUCCESS: return getVendorsListSuccess(state, action);

    case CONSTANTS.GET_VENDORS_LIST_FAILURE: return getVendorsListFailure(state, action);


    case CONSTANTS.GET_VENDOR_DATA_BEGIN: return getVendorBegin(state, action);

    case CONSTANTS.GET_VENDOR_DATA_SUCCESS: return getVendorSuccess(state, action);

    case CONSTANTS.GET_VENDOR_DATA_FAILURE: return getVendorFailure(state, action);


    case CONSTANTS.GET_VENDOR_UNBLOCK_BEGIN: return getVendorUnblockBegin(state, action);

    case CONSTANTS.GET_VENDOR_UNBLOCK_SUCCESS: return getVendorUnblockSuccess(state, action);

    case CONSTANTS.GET_VENDOR_UNBLOCK_FAILURE: return getVendorUnblockFailure(state, action);

    case CONSTANTS.SET_INITIAL_STATE: return setInitialState();

    default: return state
  }
}

function setInitialState() {
  return {
    vendorsList: [],
    vendorDetails: undefined,
    vendorUpdateData: undefined
  }
}


function getLocationFetchBegin(state, action) {
  return {
    ...state,
    partnerLocationLoading: true,
  }
}
function getLocationFetchSuccess(state, action) {
  return {
    ...state,
    partnerLocationLoading: false,
    partnerLocationDetails: removeDuplicateLocations(action.payload.locations, { nameWithRole: 'name' })
  }
}
function getLocationFetchFailure(state, action) {
  return {
    ...state,
    partnerLocationLoading: false
  }
}



function getCustomerFetchBegin(state, action) {
  return {
    ...state,
    customerLocationLoading: true,
  }
}
function getCustomerFetchSuccess(state, action) {
  return {
    ...state,
    customerLocationLoading: false,
    customerLocationDetails: action.payload
  }
}
function getCustomerFetchFailure(state, action) {
  return {
    ...state,
    customerLocationLoading: false
  }
}

function getCitiesFetchBegin(state, action) {
  return {
    ...state,
    cityloading: true
  }
}

function getCitiesFetchSuccess(state, action) {  
  return {
    ...state,
    cityloading: false,
    cities: action.payload.citiesDetails
  }
}

function getCitiesFetchFailure(state, action) {
  return {
    ...state,
    cityloading: false
  }
}


function getPincodesFetchBegin(state, action) {
  return {
    ...state,
    pincodeloading: true
  }
}

function getPincodesFetchSuccess(state, action) {

  return {
    ...state,
    pincodeloading: false,
    allPincodes: action.payload.groupedByCityId,
    pincodeDetails: action.payload.pincodedetails,
  }
}

function getPincodesFetchFailure(state, action) {
  return {
    ...state,
    pincodeloading: false
  }
}

function setVendorCreateBegin(state, action) {
  return {
    ...state,
    vendorCreateLoading: true,
  }
}
function setVendorCreateSuccess(state, action) {
  return {
    ...state,
    vendorCreateLoading: false,
    vendorDetails: action.payload.vendors
  }
}
function setVendorCreateFailure(state, action) {
  return {
    ...state,
    vendorCreateLoading: false
  }
}


function getVendorsListBegin(state, action) {
  return {
    ...state,
    vendorslistloading: true,
    vendorsList: undefined,
    unblockVendor: undefined
  }
}

function getVendorsListSuccess(state, action) {

  return {
    ...state,
    vendorslistloading: false,
    vendorsList: action.payload.vendors
  }
}

function getVendorsListFailure(state, action) {
  return {
    ...state,
    vendorslistloading: false
  }
}


function getVendorBegin(state, action) {
  return {
    ...state,
    vendorDataLoading: true
  }
}

function getVendorSuccess(state, action) {
  return {
    ...state,
    vendorDataLoading: false,
    vendorData: action.payload
  }
}

function getVendorFailure(state, action) {
  return {
    ...state,
    vendorDataLoading: false
  }
}


function getVendorUnblockBegin(state, action) {
  return {
    ...state,
    vendorBlockLoading: true
  }
}

function getVendorUnblockSuccess(state, action) {
  return {
    ...state,
    vendorBlockLoading: false,
    unblockVendor: action.payload
  }
}

function getVendorUnblockFailure(state, action) {
  return {
    ...state,
    vendorBlockLoading: false
  }
}



function setVendorUpdateBegin(state, action) {
  return {
    ...state,
    vendorUpdateLoading: true
  }
}

function setVendorUpdateSuccess(state, action) {
  return {
    ...state,
    vendorUpdateLoading: false,
    vendorUpdateData: action.payload
  }
}

function setVendorUpdateFailure(state, action) {
  return {
    ...state,
    vendorUpdateLoading: false
  }
}