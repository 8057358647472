// import { permissions } from './permissions.service';
import { withRouter } from "react-router-dom";
import {
  USER_LOGGEDIN_INFO,
  ENVTYPES,
  BASE_URL,
} from "../common/utils/constants";
import { expireCookie } from "../common/utils/utils";
import { logoutUrlSass } from "../environments/node-react-environment";
import { devEnvType } from "../environments/node-react-environment";

class AuthenticationServiceClass {
  static logoutUser() {
    const appType = localStorage.getItem("isSassProduct");
    localStorage.clear();
    expireCookie(USER_LOGGEDIN_INFO.LOGGED_IN);
    // if (devEnvType === ENVTYPES.LOCAL) {
    //   window.location = BASE_URL + "login";
    // } else if (appType && JSON.parse(appType)) {
    //   window.location.replace(logoutUrlSass);
    // } else {
    //   window.location.href = "/";
    // }
    if (appType && JSON.parse(appType)) {
      window.location.replace(logoutUrlSass);
    } else {
      window.location.href = "/";
    }
  }
}

export const AuthenticationService = AuthenticationServiceClass;
