export const GET_STATE_DETAILS_BEGIN = "GET_STATE_DETAILS_BEGIN";
export const GET_STATE_DETAILS_SUCCESS = "GET_STATE_DETAILS_SUCCESS";
export const GET_STATE_DETAILS_FAILURE = "GET_STATE_DETAILS_FAILURE";

export const GET_SETTELMENTS_BEGIN = "GET_SETTELMENTS_BEGIN";
export const GET_SETTELMENTS_SUCCESS = "GET_SETTELMENTS_SUCCESS";
export const GET_SETTELMENTS_FAILURE = "GET_SETTELMENTS_FAILURE";

export const GET_ACTIVITY_BEGIN = "GET_ACTIVITY_BEGIN";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAILURE = "GET_ACTIVITY_FAILURE";

export const REMIT_TO_USER_BEGIN = "REMIT_TO_USER_BEGIN";
export const REMIT_TO_USER_SUCCESS = "REMIT_TO_USER_SUCCESS";
export const REMIT_TO_USER_FAILURE = "REMIT_TO_USER_FAILURE";

export const REPORT_BEGIN = "REPORT_BEGIN";
export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_FAILURE = "REPORT_FAILURE";

export const SEND_EMAIL_BEGIN = "SEND_EMAIL_BEGIN";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const TRANSACTION_BEGIN = "TRANSACTION_BEGIN";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILURE = "TRANSACTION__FAILURE";
