import { apiRequest } from "../../common/utils/apiGateway";
import { Constants } from '../../common/utils/constants';

const ZohoService = {
    getZohoDashboardList: (type = 'ZOHO') => {
        return apiRequest({
            url: `/v1/tpdashboard?type=${type}`,
            method: "GET"        
        })
    },

    getZohoDashboardById: (id) => {
        return apiRequest({
            url: `/v1/tpdashboard/get/${id}`,
            method: "GET"        
        })
    },

    updateZohoStatus: (params) => {
        return apiRequest({
            url: `/v1/tpdashboard/updateStatus`,
            method: "PUT",
            data: params        
        })
    },

    createZohoDashboard: (params) => {
        return apiRequest({
            url: `/v1/tpdashboard/create`,
            method: "POST",
            data: params        
        })
    },
    updateZohoDashboard: (params) => {
        return apiRequest({
            url: `/v1/tpdashboard/update`,
            method: "PUT",
            data: params        
        })
    },

    getZohoDashboardPermissions: (payload) => {
        return apiRequest({
            url: `/b2c/v1/permissions/getPermissionsWithFilter`,
            method: "POST",
            data: payload    
        })
    }
    
    
}

export default ZohoService