import React, { Component } from 'react';
import RootObject from '../RootObject/RootObject';
import RootObjectPreview from '../RootObject/RootObjectPreview';
import './Editor.css';
import { Row, Col, Button, Icon, Input, Form, Select, Switch } from 'antd';
import ReactJson from 'react-json-view'
import { canAllow } from '../../../../common/utils/utils';
import { permissionTags } from '../../../../configs/permissionTags';
const { TextArea } = Input;
class Editor extends Component {

    state = {
        data: this.props.data.json,
        value: this.props.data.json,
        formData: this.props.formData,
        codeView: false,
        rateCardType: this.props.rateCardTypes,
        textAreaData: JSON.stringify(this.props.data.json, null, 2),
        rateCardPermission: {
            update: canAllow(permissionTags.settings.ratecard_config.update),            
        },
    }

    componentDidMount() {
        window.scroll(0, 0);
        const subscriber = this.props.data.getListener();
        subscriber.on('update', (newData) => {

            this.setState({ data: newData.json, value: newData.json })
        })
    }

    download = () => {
        let filename = "export.json";
        let contentType = "application/json;charset=utf-8;";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(this.state.data)))], { type: contentType });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            var a = document.createElement('a');
            a.download = filename;
            a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(this.state.data));
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    updateConfig = (data, mode) => {
        if (mode === "ui") {
            this.props.updateNewConfig(data, this.state.formData)
        } else {
            if (this.props.isValidJson(data)) {
                this.props.updateNewConfig(JSON.parse(data), this.state.formData)
            }

        }

    }

    goBack = () => {
        this.props.history.goBack()
    }

    changeView = () => {
        if (this.props.isValidJson(this.state.textAreaData)) {
            if (this.state.codeView) {

                this.props.updateUIChanges(this.state.textAreaData, this.state.formData);
            }

            this.setState(prevState => ({
                codeView: !prevState.codeView,
                textAreaData: JSON.stringify(this.props.data.json, null, 2)
            }));
        }

    }

    handleChange = e => {
        // console.log("e", e.target.value)
        this.setState({ textAreaData: e.target.value })
    }

    onChangeRateCard = value => {
        let { formData } = this.state;
        formData = {
            ...formData,
            rateCardTypes: value
        };
        this.setState({ formData });
    }

    changeStatus = e => {
        let { formData } = this.state;
        formData = {
            ...formData,
            status: e
        };
        this.setState({ formData });
    }

    render() {
        const { codeView } = this.state;
        return (
            <div className='tree'>
                <div className="title">
                    <h3><Icon onClick={this.goBack} style={{ bottom: 10, float: 'left' }} type="arrow-left" /> Custom Config</h3>
                    <hr />
                </div>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item label="Rate Card Type" colon={false}>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select Rate Card Type"
                                value={this.state.formData?.rateCardTypes}
                                disabled={this.state.formData.type === 'global'}
                                onChange={this.onChangeRateCard}
                                mode="multiple"
                                filterOption={(input, option) => {
                                    return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0

                                }
                                }
                            >
                                {this.state.rateCardType.map(rType => (
                                    <Select.Option
                                        value={rType}
                                        key={rType}
                                    >
                                        {rType}
                                    </Select.Option>
                                ))}

                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <Form.Item label="Status" colon={false}>
                            <Switch
                                disabled={this.state.formData.type === 'global'}
                                checked={this.state.formData.status}
                                onChange={this.changeStatus}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    {/* <Col span={9} style={{ height: 510, overflow: 'scroll' }}> */}
                    {/* <pre style={{ width: '90%' }}> */}
                    {/* {JSON.stringify(this.state.data, null, 2)} */}
                    {/* <RootObjectPreview data={this.state.value} /> */}
                    {/* <ReactJson displayDataTypes={false} style={{ width: '90%' }} src={this.state.data} collapsed={true} /> */}
                    {/* </pre> */}
                    {/* <ReactJson displayDataTypes={false} style={{ width: '90%' }} src={this.state.data} collapsed={true} /> */}

                    {/* </Col> */}
                    {codeView ?
                        <Col span={15} style={{ height: 510, overflow: 'scroll' }}>
                            <TextArea className="textarea-ui" onChange={this.handleChange} rows={20} defaultValue={JSON.stringify(this.state.data, null, 2)} />
                        </Col> :
                        <Col span={15} style={{ height: 510, overflow: 'scroll' }}>
                            <RootObject data={this.state.value} />
                        </Col>
                    }
                    <Col span={7} offset={2}>
                        <a className="ui-text" onClick={this.changeView}>{codeView ? 'UI View' : 'Code View'}</a>
                    </Col>

                </Row>
                <Row>
                    {!codeView ?
                        <Col span={24}>
                            <div style={{ float: 'right' }}>
                                <Button loading={this.props.loading} onClick={this.download} type="primary">Download</Button>
                                <Button disabled={!this.state.rateCardPermission.update} loading={this.props.loading} onClick={() => this.updateConfig(this.state.data, "ui")} type="primary">Update</Button>
                            </div>

                        </Col>
                        :
                        <Col span={24}>
                            <div style={{ float: 'right' }}>
                                <Button loading={this.props.loading} onClick={this.download} type="primary">Download</Button>
                                <Button disabled={!this.state.rateCardPermission.update} loading={this.props.loading} onClick={() => this.updateConfig(this.state.textAreaData, "text")} type="primary">Update</Button>
                            </div>

                        </Col>
                    }
                </Row>





            </div>
        )
    }
}

export default Editor;