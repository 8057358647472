
import React, { Component } from 'react';
import TrackingPopup from "./TrackingPopup";

class TrackingContainerPop extends Component {
    
    render() {
      return (
        <TrackingPopup
            getDetailInput={this.props.match.params.waybillNo}
            waybillNo={this.props.match.params.waybillNo}
            source={"TrackingListing"}
        ></TrackingPopup>
      );
    }
  }

export default TrackingContainerPop;