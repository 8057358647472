import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Freezer from 'freezer-js';
import Editor from './Editor/Editor';
import { getConfig, updateConfig, resetUpdatedData } from './actions';
import { notifyApiSuccess, notifyApiError } from '../../../common/utils/utils';
import './config.scss';
import _ from 'lodash';
import { Card, Spin, Modal } from 'antd';
class RateCardConfigEditor extends PureComponent {

    state = {
        data: {},
        loading: false
    }

    componentDidMount() {

        const data = this.props?.location?.state?.config;
        this.setState({ data })
    }

    componentDidUpdate(prevProps) {
        if (this.props.updatedConfig && this.props.updatedConfig?.configData) {
            this.props.resetUpdatedData();
            const _this = this;
            this.setState({ loading: false });
            Modal.success({
                title: 'Config Updated succesfully! Please reload system cache!',
                maskClosable: false,
                keyboard: false,
                onOk: () => {

                    _this.props.history.goBack();
                }
            })

            // notifyApiSuccess("Config Data Updated", "SUCCESS")

        } else if (this.props.updatedConfig && this.props.updatedConfig?.error) {
            this.props.resetUpdatedData();
            this.setState({ loading: false });
        }
    }

    updateNewConfig(newData, formData) {

        this.setState({ loading: true });
        if (this.isJson(newData) && !_.isUndefined(this.state.data.vendorId)) {
            console.log("form", formData)
            const { bookingOfcLocId, id, partnerID, status, vendorId, rateCardTypes } = formData;
            let data = {};
            if(vendorId > 0) {
                data = {
                    "request": {
    
                        bookingOfcLocId,
                        id,
                        partnerID,
    
                        status,
                        vendorId,
    
                        ...(rateCardTypes && {rateCardType: rateCardTypes.join(",")}),
                        rateCardConfigMetaInfoBO: {
                            ...newData
                        }
                    }
    
                }
                // console.log("reques", data)                
            } else {
                data = {
                    "request": {
                         
                        vendorId,
    
                        ...(rateCardTypes && {rateCardType: rateCardTypes.join(",")}),
                        rateCardConfigMetaInfoBO: {
                            ...newData
                        }
                    }
    
                }
            }            
            // console.log("reques", data)
            this.props.updateConfig(data, this.state.data.vendorId)
        }


    }

    updateUIChanges = (newData, formData) => {
        // console.log("daatta", JSON.stringify(data))

        let data = {
            ...formData,
            config: JSON.parse(newData),
            id: this.state.data.id,

        }

        this.setState({ data: [] }, () => {
            this.setState({ data })
        })


    }


    download = () => {
        if (this.isJson(this.state.data)) {
            let filename = "export.json";
            let contentType = "application/json;charset=utf-8;";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(this.state.data)))], { type: contentType });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var a = document.createElement('a');
                a.download = filename;
                a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(this.state.data));
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }

    }

    isValidJson = data => {
        try {
            JSON.parse(data);
        } catch (e) {
            notifyApiError("Invalid JSON!", "Error")
            return false;
        }
        return true;
    }

    isJson = (str) => {
        // console.log(str)
        try {
            JSON.parse(JSON.stringify(str));
        } catch (e) {
            notifyApiError("Invalid JSON!", "Error")
            return false;
        }
        return true;
    }





    render() {
        if (!_.isEmpty(this.state.data)) {
            const fr = new Freezer({ json: this.state.data?.config });
            return (
                <div className="App">
                    <Card

                        bodyStyle={{ height: 530 }}
                    >

                        <Editor
                            {...this.props}
                            loading={this.state.loading}
                            isValidJson={(data) => this.isValidJson(data)}
                            updateUIChanges={this.updateUIChanges}
                            formData={this.state.data}
                            rateCardTypes={this.props.location.state.allRateCardTypes}
                            data={fr.get()}
                            updateNewConfig={(data, formData) => this.updateNewConfig(data, formData)}
                        />



                    </Card>

                </div >
            )
        }
        return (
            <div className="App">
                <Card
                    bodyStyle={{ height: 530 }}
                >
                    <Spin />
                </Card>

            </div>
        )

    }
}

// export default Config;


const mapStateToProps = (state, ownProps) => ({
    customConfig: state?.rateCardConfig?.config,
    updatedConfig: state?.rateCardConfig?.updatedConfig,
});
const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(getConfig()),
    updateConfig: (params, id) => dispatch(updateConfig(params, id)),
    resetUpdatedData: () => dispatch(resetUpdatedData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateCardConfigEditor);
