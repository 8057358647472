import React, { Component } from 'react';
import {
    Card, Row, Col, Icon, Tag,
    Button, Form, Input, Select, Modal, Divider, Spin, Empty
}
    from 'antd';
import FileUpload from '../../ui/file-upload/file.upload';
import { connect } from 'react-redux';
import * as assetActions from './action';
import * as userActions from "../user/actions";
import { USER_LOGGEDIN_INFO, validationRules, Constants } from '../../../common/utils/constants';
import _ from 'lodash';
import { notifyApiError } from '../../../common/utils/utils';
import * as moment from "moment";
import * as apiUtils from '../../../common/utils/apiGateway';

import { Mixpanel } from "../../../utils/mixpanel";


const InputGroup = Input.Group;
const { confirm } = Modal;


class AssetCreate extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        acceptTypes: [".pdf", ".jpg", ".jpeg"],
        isEdit: false,
        editLoading: false,
        licenceFrontFile: undefined,
        licenceBackFile: undefined,
        registrationFile: undefined,
        pollutionFile: undefined,
        insuranceFile: undefined,
        vehiclepermitFile: undefined,
        gpsinstallationFile: undefined,
        fitnessFile: undefined,
        identityDocuments: [],
        editId: null,
        setAssigned: false,
        showConfirmModal: false,
        createAsset: {},
        setLocationAssigned: false,
        setVendorAssigned: false,

        userModal: false,
        userEmail: undefined,
        username: undefined,
        contactNumber: undefined,
        showErrors: false,
        userErrors: [],
        isSubmitted: false,
        billingModels: [],
        capacities: [],
        dimensions: [],
        assetData: undefined,
        bodies: []
    }

    componentDidMount() {
        this.props.resetFields();
        this.getBillingModel()
        if (this.props?.location?.state?.assetId) {
            this.setState({ isEdit: true, editLoading: true, editId: this.props.location.state.assetId });
            this.props.getAssetData(this.props.location.state.assetId);
            apiUtils.apiRequest({
                url: `/b2b/v2/partners/${Constants.PARTNER_ID}/assets/${this.props.location.state.assetId}`,
            })
                .then(response => {
                    let asset = response?.data?.response?.assets[0];
                    let identityDocumentBOS = response?.data?.response?.identityDocumentBOS;
                    this.setState({ assetData: asset })
                    const { form } = this.props;
                    let { driverName, vehicleNumber, contactPersonMobile, contactPersonName, vehicleType, capacity, registrationNumber,
                        vendorId, capacityUnit, vehicleTypesBO } = asset;



                    form.setFieldsValue({
                        driverName,
                        vehicleNumber,
                        contactPersonMobile,
                        contactPersonName,
                        billingModel: vehicleType,
                        registrationNumber
                    }, () => {

                        this.selectBillingModel(vehicleType);

                        // form.setFieldsValue({

                        // }, () => {
                        //     console.log("vehicleTypesBO?.vehicleCapacity", vehicleTypesBO?.vehicleCapacity)

                        //     // form.setFieldsValue({
                        //     //     dimensionFeet: vehicleTypesBO?.id
                        //     // })
                        // })
                    });

                    if (this.props.identityDocumentBOS && this.props.identityDocumentBOS.length > 0) {
                        this.props.identityDocumentBOS.map(iden => {
                            this.onLoadUpload(iden.s3URL, iden.documentType);
                        })
                    }

                    this.selectCapacity(vehicleTypesBO?.vehicleCapacity);
                    this.selectBody(vehicleTypesBO?.bodyType)
                    form.setFieldsValue({
                        vehicleCapacity: vehicleTypesBO?.vehicleCapacity,
                        bodyType: vehicleTypesBO?.bodyType
                    }, () => {
                        form.setFieldsValue({
                            dimensionFeet: vehicleTypesBO?.id
                        })
                    });
                });
        }

        this.props.getVendors();
        const data = {
            "filters": {
                "entityTypes": ["PARTNER"],
                "entityIds": [Constants.PARTNER_ID],
                "isConsiderCreatorId": false,
                "fetchObjects": [],
                "status": true
            }
        }
        this.props.getLocations(data);


        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        Mixpanel.identify(loggedPartnerId);
        Mixpanel.people.set({
            $first_name: user?.name,
            $email: user?.email,
            $phone_number: user?.number,
            $partner_id: user?.partner?.id,
            $partner_name: user?.partner?.name,
        });


        // this.props.getCustomerList();
        // this.props.getCities();
        // this.props.getPincodes();
        // let initialVendors = [...this.state.initialVendors];
        // vTypes.map(v => {
        //     if (v.checked) {
        //         initialVendors.push({ "key": v.value, "label": v.name });
        //         this.setState({ initialVendors });
        //         v.disabled = true;
        //     } else {
        //         v.disabled = false;
        //     }
        // })


    }

    componentWillUnmount() {
        this.setState({ isEdit: false, editId: null });
        this.props.resetFields();
        this.props.form.resetFields();
        this.setState({ setAssigned: false, setLocationAssigned: false, setVendorAssigned: false })
    }

    componentDidUpdate(oldProps) {
        // let asset = this.props.assetsData && this.props.assetsData[0];
        // let oldAsset = oldProps.assetsData && oldProps.assetsData[0];
        let { assetData } = this.state;
        if (assetData && this.props.usersList && !this.state.setAssigned) {

            let { assignedUserId } = assetData;
            const { form } = this.props;
            this.props.usersList.map(users => {
                if (users.id == assignedUserId) {
                    form.setFieldsValue({
                        assignedUserId
                    });
                    this.setState({ setAssigned: true })
                }
            })
        }

        if (assetData && this.props.locationsList && !this.state.setLocationAssigned) {

            let { originLocationId } = assetData;
            let locationId = undefined;
            this.selectLocation(originLocationId);
            const { form } = this.props;
            this.props.locationsList.map(location => {

                if (location.id == originLocationId) {
                    locationId = originLocationId;

                }
            })

            form.setFieldsValue({
                originLocationId: locationId
            }, () => {
                this.setState({ setLocationAssigned: true })
            });

        }

        if (assetData && this.props.vendorsList && !this.state.setVendorAssigned) {
            // let { originLocationId } = asset;
            // this.selectLocation(originLocationId);
            // const { form } = this.props;
            // this.props.locationsList.map(location => {
            //     if (location.id == originLocationId) {
            //         form.setFieldsValue({
            //             originLocationId
            //         });
            //         this.setState({ setLocationAssigned: true })
            //     }
            // })
            let ownerName = { "key": assetData.vendorId ? assetData.vendorId : -1, "label": "SELF" };
            const { form } = this.props;
            form.setFieldsValue({
                owner: ownerName
            });
            this.setState({ setVendorAssigned: true })

        }


        // console.log("this.props.assetsData && (_.isUndefined(oldAsset) || asset.id != oldAsset.id) && this.state.billingModels.length > 0", this.props.assetsData && (_.isUndefined(oldAsset) || asset.id != oldAsset.id) && this.state.billingModels.length > 0)

        // if (this.props.vendorCreateResp && oldProps.vendorCreateResp !== this.props.vendorCreateResp) {
        //     if (this.props.vendorCreateResp.length > 0) {
        //         this.goBack();
        //     }
        // }

        if (this.props.assetCreateError && this.props.assetCreateError.status.code == 500 && this.props.assetCreateError.status.message == 'Duplicate asset not allowed' && !this.state.showConfirmModal) {
            if (this.props?.assetCreateError && this.props?.assetCreateError?.response && this.props?.assetCreateError?.response.assets && this.props.assetCreateError.response.assets[0].assetType == 'ADHOC_ASSET' && this.props.assetCreateError.response.assets[0].isAvailable) {
                this.setState({ showConfirmModal: true })
                this.showConfirm();
            }

        }
        if (this.props.assetDetails && this.props.assetDetails[0].id) {
            this.props.resetFields();
            this.props.form.resetFields();
            this.goBack();
        }

        if (this.props.updateAssetDetails && this.props.updateAssetDetails[0].id) {
            this.props.resetFields();
            this.props.form.resetFields();
            this.goBack();
        }

        if (this.props.userSettings?.user?.users && this.props.userSettings?.user?.users.length > 0 && this.state.userModal) {
            this.props.form.resetFields(['assignedUserId']);
            this.props.getUsers(this.props.form.getFieldValue('originLocationId'));
            this.setState({ userModal: false, userEmail: undefined, username: undefined, contactNumber: undefined, showErrors: false, errors: [] }, () => {
                const userId = this.props.userSettings?.user?.users[0]?.id;
                this.props.form.setFieldsValue({
                    assignedUserId: userId
                }, () => {
                    this.props.resetUser();
                })
            });
        }




        // const { setFieldsValue } = this.props.form;
        // const { vendorData } = this.props;
        // if (vendorData && oldProps.vendorData?.id != this.props.vendorData.id) {


        //     var promise = new Promise((resolve, reject) => {
        //         const { name, email, contactNumber, secondaryEmail,
        //             secondaryContactNumber, gstNumber, locations,
        //             contactPerson, identityDocuments, partnerBO,
        //             partnerLocation, customerBO, customerLocation
        //         } = vendorData;
        //         if (gstNumber) {
        //             this.changeGST(true);
        //         }
        //         const locationname = locations[0].name;
        //         const address1 = locations[0].address.address1;
        //         const address2 = locations[0].address.address2;
        //         const landmark = locations[0].address.landmark;
        //         const city = locations[0].address.pincode.city.id;
        //         const pincode = locations[0].address.pincode.zipcode;



        //         let panproof, pan, gstproof, tdsproof, tds, bankproof, accountNumber, ifsc, bankname, vendorcontract = [], vendor = [];

        //         if (identityDocuments && identityDocuments.length > 0) {
        //             identityDocuments.map((doc, index) => {

        //                 switch (doc.documentType) {
        //                     case "PAN_CARD":
        //                         pan = doc['documentValue'];
        //                         panproof = doc['s3URL'];
        //                         this.setState({ panFile: doc['documentType'] });
        //                         break;
        //                     case "OTHERS":
        //                         gstproof = doc['s3URL'];
        //                         this.setState({ gstFile: 'gst_file' });
        //                         break;
        //                     case "TDS_PROOF":
        //                         tdsproof = doc['s3URL'];
        //                         tds = doc['documentValue'];
        //                         this.setState({ tdsFile: doc['documentType'] });
        //                         break;
        //                     case "BANK_DOCUMENTS":
        //                         bankproof = doc['s3URL'];
        //                         const bank = JSON.parse(doc['documentValue']);
        //                         bankname = bank.bankname;
        //                         ifsc = bank.ifsc;
        //                         accountNumber = bank.accountNumber;
        //                         this.setState({ bankFile: doc['documentType'] });
        //                         break;
        //                     case "VENDOR_CONTRACT":
        //                         vendorcontract.push({ s3URL: doc['s3URL'], "documentType": "VENDOR_CONTRACT", documentValue: null });
        //                         vendor.push(this.state.vendorFiles.concat(doc['documentType'] + "_" + index));
        //                         this.setState({ vendorFiles: vendor });
        //                         break;
        //                     default:
        //                         void (0);
        //                 }

        //             })
        //         }

        //         let partnername = [], partnerlocations = [], customername = [], customerlocations = [];

        //         partnerBO.map(v => {
        //             if (partnername.indexOf(v.id) == -1)
        //                 partnername.push(v.id === 268 ? v.id.toString() : v.id)
        //         });

        //         customerBO.map(v => {
        //             if (customername.indexOf(v.id) == -1)
        //                 customername.push(v.id)
        //         })

        //         partnerLocation.map(v => {
        //             this.onPartnerChange(partnername);
        //             if (partnerlocations.indexOf(v.id) == -1)
        //                 partnerlocations.push(v.id)
        //         });

        //         customerLocation.map(v => {
        //             this.onCustomerChange(customername);
        //             if (customerlocations.indexOf(v.id) == -1)
        //                 customerlocations.push(v.id)
        //             resolve({ editngLoadingDone: true });
        //         });
        //         let vendorTypeSelect = [...vTypes];
        //         let vendorSelectTypes = [];
        //         // let initialVendors = [...this.state.initialVendors];
        //         // initialVendors = [];
        //         this.props.vendorData.vendorTypes.map(vendor => {
        //             vendorTypeSelect.map(vType => {
        //                 if (vType.value == vendor) {
        //                     let disabled = { ...vType };
        //                     disabled = true;
        //                     vType['disabled'] = disabled;
        //                     vType['key'] = vType.value;
        //                     vType['label'] = vType['name'];
        //                     vendorSelectTypes.push(vType);
        //                 }

        //             });

        //         })
        //         setFieldsValue({
        //             name,
        //             email,
        //             contactNumber,
        //             secondaryEmail,
        //             secondaryContactNumber,
        //             gst: gstNumber,
        //             contactPerson,
        //             vendorTypes: vendorSelectTypes,
        //             locationname,
        //             address1,
        //             address2,
        //             landmark,
        //             city,
        //             pincode,
        //             pan,
        //             panproof,
        //             gstproof,
        //             tds,
        //             tdsproof,
        //             accountNumber,
        //             ifsc,
        //             bankname,
        //             bankproof,
        //             vendorcontract,
        //             partnername,
        //             partnerlocations,
        //             customername,
        //             customerlocations
        //         });



        //     });

        //     promise.then(resp => {
        //         if (resp.editngLoadingDone) {
        //             this.setState({ editLoading: false })
        //         }
        //     })


        // }
    }


    getBillingModel = () => {
        apiUtils.apiRequest({
            method: 'GET',
            url: `/b2b/v1/vehicle-types/getVehicleTypes`
        }).then(res => {
            let { billingModels } = this.state;

            billingModels = res?.data?.response?.vehicleTypesGroupedBOS;

            this.setState({ billingModels })

        })
    }

    showConfirm() {
        let _this = this;
        // let params = {            
        //     "asset": _this.props.assetCreateError.response.assets[0],
        //     "identityDocuments":_this.props.assetCreateError.response.identityDocumentBOS

        // }
        // params.asset.assetType = 'ASSET';
        // params.asset.attributes.assetSubType = null;



        let { createAsset } = this.state;

        createAsset.asset.assetType = 'ASSET';
        let { asset } = createAsset;
        let { attributes } = asset;
        createAsset = {
            ...createAsset,
            asset: {
                ...asset,
                attributes: {
                    isMigrated: true
                }
            }

        }
        // createAsset.asset['attributes']['isMigrated'] = true;

        confirm({
            title: 'Adhoc Vehicle',
            content: 'The vehicle is already in system as Market Asset, do you want to convert it to a registered asset under the mentioned vendor?',
            onOk() {
                Mixpanel.track("Asset Page Adhoc Conversion OK", { 'ERPAssetAdhocConversion': moment().valueOf(), adhoc_update_id: _this.props.assetCreateError.response.assets[0].id })

                _this.props.updateAsset(_this.props.assetCreateError.response.assets[0].id, createAsset);
                _this.setState({ createAsset: {} })
            },
            onCancel() {
                Mixpanel.track("Asset Page Adhoc Conversion Cancel", { 'ERPAssetAdhocConversion': moment().valueOf(), adhoc_update_id: _this.props.assetCreateError.response.assets[0].id })

                _this.props.resetFields()
                _this.setState({ showConfirmModal: false })
            },
        });
    }

    goBack() {
        this.props.resetFields();
        this.props.history.goBack();
    }

    onLoadUpload = (filename, type) => {
        let identityDocuments = [];

        let s3URL = filename && type != 'DRIVERS_LICENCE' ? filename.replace(/^.*[\\\/]/, '') : null;
        switch (type) {
            case 'DRIVERS_LICENCE':
                let files = filename.split(',');
                if (files && files.length > 0) {
                    let file1 = files[0].replace(/^.*[\\\/]/, '');
                    let file2 = files[1].replace(/^.*[\\\/]/, '');
                    this.setState({ licenceBackFile: file2 });
                    this.setState({ licenceFrontFile: file1 });
                    identityDocuments.push({
                        's3URL': file1,
                        'documentType': 'DRIVERS_LICENCE'
                    });
                    identityDocuments.push({
                        's3URL': file2,
                        'documentType': 'DRIVERS_LICENCE'
                    });
                }

                break;

            case 'REGISTERATION_CERTIFICATE':
                this.setState({ registrationFile: s3URL });
                identityDocuments.push({
                    's3URL': s3URL,
                    'documentType': 'REGISTERATION_CERTIFICATE'
                });
                break;
            case 'POLLUTION_CERTIFICATE':
                this.setState({ pollutionFile: s3URL });
                identityDocuments.push({
                    's3URL': s3URL,
                    'documentType': 'POLLUTION_CERTIFICATE'
                });
                break;
            case 'INSURANCE_CERTIFICATE':
                this.setState({ insuranceFile: s3URL });
                identityDocuments.push({
                    's3URL': s3URL,
                    'documentType': 'INSURANCE_CERTIFICATE'
                });
                break;
            case 'VEHICLE_PERMIT':
                this.setState({ vehiclepermitFile: s3URL });
                identityDocuments.push({
                    's3URL': s3URL,
                    'documentType': 'VEHICLE_PERMIT'
                });
                break;
            case 'GPS_INSTALLATION_CERTIFICATE':
                this.setState({ gpsinstallationFile: s3URL });
                identityDocuments.push({
                    's3URL': s3URL,
                    'documentType': 'GPS_INSTALLATION_CERTIFICATE'
                });
                break;
            case 'FITNESS_CERTIFICATE':
                this.setState({ fitnessFile: s3URL });
                identityDocuments.push({
                    's3URL': s3URL,
                    'documentType': 'FITNESS_CERTIFICATE'
                });
                break;
            default:
                void (0);
                break;

        };

        this.setState({ identityDocuments })

    }

    onUploadFinish = (e, type, name, mode = "single", docType = "BANK_DOCUMENTS") => {
        let identityDocuments = this.state.identityDocuments;

        switch (type) {
            case 'licenceFront':
                this.setState({ licenceFrontFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'DRIVERS_LICENCE'
                });
                break;
            case 'licenceBack':
                this.setState({ licenceBackFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'DRIVERS_LICENCE'
                });
                break;
            case 'registration':
                this.setState({ registrationFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'REGISTERATION_CERTIFICATE'
                });
                break;
            case 'pollution':
                this.setState({ pollutionFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'POLLUTION_CERTIFICATE'
                });
                break;
            case 'insurance':
                this.setState({ insuranceFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'INSURANCE_CERTIFICATE'
                });
                break;
            case 'vehiclepermit':
                this.setState({ vehiclepermitFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'VEHICLE_PERMIT'
                });
                break;
            case 'gpsinstallation':
                this.setState({ gpsinstallationFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'GPS_INSTALLATION_CERTIFICATE'
                });
                break;
            case 'fitness':
                this.setState({ fitnessFile: '' });
                identityDocuments.push({
                    's3URL': e,
                    'documentType': 'FITNESS_CERTIFICATE'
                });
                break;
            default:
                void (0);
                break;

        };

        this.setState({ identityDocuments })



        // if (mode === "multiple") {
        //     let { multiFiles } = { ...this.state };
        //     if (!multiFiles[type]) {
        //         multiFiles[type] = [];
        //     }

        //     multiFiles[type].push({ s3URL: e, documentType: docType, documentValue: null })

        //     this.setState({ multiFiles });
        // }
        // else {
        //     const { multiFiles } = { ...this.state };
        //     this.setState({ multiFiles: { [type]: e } });
        // }
        // this.props.form.setFieldsValue(this.state.multiFiles)
    }

    handleUpdate() {

        // this.props.form.validateFieldsAndScroll((err, values) => {
        //     if (err) return;
        //     let { vendorData } = this.props;
        //     let data = { ...values };

        //     let { name, contactNumber, email, secondaryEmail, secondaryContactNumber, gst, contactPerson, vendorTypes } = { ...data };
        //     vendorTypes = vendorTypes.map(v => v.key)
        //     console.log("customerlocations->>", vendorData)
        //     // delete vendorData['partnerBO'];
        //     // delete vendorData['customerBO'];
        //     // delete vendorData['partnerLocation'];    
        //     // delete vendorData['customerLocation'];


        //     const linkedLocations = {
        //         "customer": this.getMappedData(this.props.customerLocations, data['customerlocations']),
        //         "partner": this.getMappedData(this.props.partnerLocationDetails, data['partnerlocations'])
        //     };

        //     const location = {
        //         id: vendorData['locations'][0]['id'],
        //         role: 'BILLING',
        //         name: data['locationname'],
        //         address: {
        //             address1: data['address1'],
        //             address2: data['address2'],
        //             landmark: data['landmark'],
        //             pincode: {
        //                 id: this.props.pincodeDetails[data['pincode']]['id'],
        //                 city: {
        //                     id: data['city']
        //                 }
        //             }
        //         }
        //     };

        //     let gstproof = {}, tdsproof = {};

        //     if (data['gst']) {
        //         gstproof = {
        //             "s3URL": data['gstproof'],
        //             "documentType": "OTHERS",
        //             "documentValue": data['gst']
        //         }
        //     };

        //     if (data['tds']) {
        //         tdsproof = {
        //             "s3URL": data['tdsproof'],
        //             "documentType": "TDS_PROOF",
        //             "documentValue": data['tds']
        //         }
        //     };

        //     let bankproof = {};
        //     if (data['bankproof']) {
        //         bankproof = {
        //             "s3URL": data['bankproof'],
        //             "documentType": "BANK_DOCUMENTS",
        //             "documentValue": JSON.stringify({ accountNumber: data['accountNumber'], ifsc: data['ifsc'], bankname: data['bankname'] })
        //         }
        //     }





        //     let identityDocuments = [
        //         {
        //             "s3URL": data['panproof'],
        //             "documentType": "PAN_CARD",
        //             "documentValue": data['pan']
        //         },
        //         gstproof,
        //         tdsproof,
        //         bankproof
        //     ];
        //     if (!data['panproof']) {
        //         identityDocuments.splice(0, 1);
        //     }

        //     identityDocuments = identityDocuments.concat(data['vendorcontract']);
        //     identityDocuments = identityDocuments.filter(value => Object.keys(value).length !== 0);


        //     let merge = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);
        //     if (vendorData['identityDocuments'] && vendorData['identityDocuments'].length > 0)
        //         identityDocuments = merge(identityDocuments, vendorData['identityDocuments'], "s3URL");


        //     // delete vendorData['locations'];

        //     const params = {
        //         ...vendorData,
        //         name,
        //         contactNumber,
        //         email,
        //         secondaryEmail,
        //         secondaryContactNumber,
        //         gst,
        //         contactPerson,
        //         vendorTypes,
        //         linkedLocations,
        //         location,
        //         identityDocuments,
        //     }

        //     console.log("params->>", params)

        //     this.props.updateVendor(params, params.id);


        // });

        this.setState({ isSubmitted: true }, () => {
            this.props.form.validateFieldsAndScroll((err, values) => {

                if (err) return;
                let ownerName = null, vendorId = null;
                const { driverName, vehicleNumber, contactPersonMobile, contactPersonName, originLocationId, vehicleType, registrationNumber, assignedUserId, owner, dimensionFeet, vehicleCapacity } = { ...values };
                // let { identityDocumentBOS } = this.props;
                let { identityDocuments } = this.state;
                // console.log("identi->>", identityDocuments, identityDocumentBOS)
                // identityDocuments.concat(identityDocumentBOS);

                if (owner.label !== 'self') {
                    ownerName = owner.label;
                    vendorId = owner.key;
                } else {
                    ownerName = Constants.sessionUser.name;
                    vendorId = null;
                }
                // let identityDocuments = [];
                // console.log("values->>", values)
                // if (values['licenceFront']) {
                //     identityDocuments.push({
                //         's3URL': values['licenceFront'],
                //         'documentType': 'DRIVERS_LICENCE'
                //     })
                // }
                // if (values['licenceBack']) {
                //     identityDocuments.push({
                //         's3URL': values['licenceBack'],
                //         'documentType': 'DRIVERS_LICENCE'
                //     })
                // }
                // if (values['registration']) {
                //     identityDocuments.push({
                //         's3URL': values['registration'],
                //         'documentType': 'REGISTERATION_CERTIFICATE'
                //     })
                // }
                // if (values['pollution']) {
                //     identityDocuments.push({
                //         's3URL': values['pollution'],
                //         'documentType': 'POLLUTION_CERTIFICATE'
                //     })
                // }
                // if (values['insurance']) {
                //     identityDocuments.push({
                //         's3URL': values['insurance'],
                //         'documentType': 'INSURANCE_CERTIFICATE'
                //     })
                // }
                // if (values['vehiclepermit']) {
                //     identityDocuments.push({
                //         's3URL': values['vehiclepermit'],
                //         'documentType': 'VEHICLE_PERMIT'
                //     })
                // }
                // if (values['gpsinstallation']) {
                //     identityDocuments.push({
                //         's3URL': values['gpsinstallation'],
                //         'documentType': 'GPS_INSTALLATION_CERTIFICATE'
                //     })
                // }
                // if (values['fitness']) {
                //     identityDocuments.push({
                //         's3URL': values['fitness'],
                //         'documentType': 'FITNESS_CERTIFICATE'
                //     })
                // }

                let params = {
                    asset: {
                        driverName,
                        vehicleNumber,
                        contactPersonMobile,
                        contactPersonName,
                        originLocationId,
                        vehicleType,
                        capacity: vehicleCapacity,
                        registrationNumber,
                        assignedUserId,
                        ownerName,
                        vendorId: vendorId === -1 ? null : vendorId,
                        capacityUnit: 'Ton',
                        vehicleTypesBO: {
                            "id": dimensionFeet
                        }
                    },
                    identityDocuments
                };

                Mixpanel.track("Asset Page Updated", { 'ERPAssetUpdated': moment().valueOf(), edit_asset_id: this.state.editId })

                this.props.updateAsset(this.state.editId, params);
                // this.props.createAsset(params);
                //     const { name, contactNumber, email, secondaryEmail, secondaryContactNumber, gst, contactPerson, vendorTypes } = { ...values };
                //     let vTypes = vendorTypes.map(v => v["key"]);
                //     let linkedLocations;
                //     linkedLocations = {
                //         "customer": values['customerlocations'] ? this.getMappedData(this.props.customerLocations, values['customerlocations']) : null,
                //         "partner": values['partnerlocations'] ? this.getMappedData(this.props.partnerLocationDetails, values['partnerlocations']) : null
                //     }

                //     const location = {
                //         role: 'BILLING',
                //         name: values['locationname'],
                //         address: {
                //             address1: values['address1'],
                //             address2: values['address2'],
                //             landmark: values['landmark'],
                //             pincode: {
                //                 id: this.props.pincodeDetails[values['pincode']]['id'],
                //                 city: {
                //                     id: values['city']
                //                 }
                //             }
                //         }
                //     };
                //     let gstproof = {}, tdsproof = {};
                //     if (values['gst']) {
                //         gstproof = {
                //             "s3URL": values['gstproof'],
                //             "documentType": "OTHERS",
                //             "documentValue": values['gst']
                //         }
                //     };

                //     if (values['tds']) {
                //         tdsproof = {
                //             "s3URL": values['tdsproof'],
                //             "documentType": "TDS_PROOF",
                //             "documentValue": values['tds']
                //         }
                //     };

                //     const bankproof = {
                //         "s3URL": values['bankproof'],
                //         "documentType": "BANK_DOCUMENTS",
                //         "documentValue": JSON.stringify({ accountNumber: values['accountNumber'], ifsc: values['ifsc'], bankname: values['bankname'] })
                //     }


                //     let identityDocuments = [
                //         {
                //             "s3URL": values['panproof'],
                //             "documentType": "PAN_CARD",
                //             "documentValue": values['pan']
                //         },
                //         gstproof,
                //         tdsproof,
                //         bankproof
                //     ];


                //     identityDocuments = identityDocuments.concat(values['vendorcontract']);
                //     identityDocuments = identityDocuments.filter(value => value != undefined && Object.keys(value).length !== 0);

                //     const params = {
                //         name,
                //         contactNumber,
                //         email,
                //         secondaryEmail,
                //         secondaryContactNumber,
                //         gst,
                //         contactPerson,
                //         vendorTypes: vTypes,
                //         linkedLocations,
                //         location,
                //         identityDocuments
                //     }


                //     this.props.createVendor(params);
            });

        });

    }


    handleSubmit() {
        this.setState({ isSubmitted: true }, () => {
            this.props.form.validateFieldsAndScroll((err, values) => {

                if (err) return;
                let ownerName = null, vendorId = null;
                const { driverName, vehicleNumber, contactPersonMobile, contactPersonName, originLocationId, billingModel, registrationNumber, assignedUserId, owner, dimensionFeet, vehicleCapacity } = { ...values };
                let { identityDocuments } = this.state;
                if (owner.label !== 'self') {
                    ownerName = owner.label;
                    vendorId = owner.key;
                } else {
                    ownerName = Constants.sessionUser.name;
                    vendorId = null;
                }
                // let identityDocuments = [];
                // console.log("values->>", values)
                // if (values['licenceFront']) {
                //     identityDocuments.push({
                //         's3URL': values['licenceFront'],
                //         'documentType': 'DRIVERS_LICENCE'
                //     })
                // }
                // if (values['licenceBack']) {
                //     identityDocuments.push({
                //         's3URL': values['licenceBack'],
                //         'documentType': 'DRIVERS_LICENCE'
                //     })
                // }
                // if (values['registration']) {
                //     identityDocuments.push({
                //         's3URL': values['registration'],
                //         'documentType': 'REGISTERATION_CERTIFICATE'
                //     })
                // }
                // if (values['pollution']) {
                //     identityDocuments.push({
                //         's3URL': values['pollution'],
                //         'documentType': 'POLLUTION_CERTIFICATE'
                //     })
                // }
                // if (values['insurance']) {
                //     identityDocuments.push({
                //         's3URL': values['insurance'],
                //         'documentType': 'INSURANCE_CERTIFICATE'
                //     })
                // }
                // if (values['vehiclepermit']) {
                //     identityDocuments.push({
                //         's3URL': values['vehiclepermit'],
                //         'documentType': 'VEHICLE_PERMIT'
                //     })
                // }
                // if (values['gpsinstallation']) {
                //     identityDocuments.push({
                //         's3URL': values['gpsinstallation'],
                //         'documentType': 'GPS_INSTALLATION_CERTIFICATE'
                //     })
                // }
                // if (values['fitness']) {
                //     identityDocuments.push({
                //         's3URL': values['fitness'],
                //         'documentType': 'FITNESS_CERTIFICATE'
                //     })
                // }

                let params = {
                    "asset": {
                        driverName,
                        vehicleNumber,
                        contactPersonMobile,
                        contactPersonName,
                        originLocationId,
                        vehicleType: billingModel,
                        capacity: vehicleCapacity,
                        registrationNumber,
                        assignedUserId,
                        ownerName,
                        vendorId: vendorId === -1 ? null : vendorId,
                        capacityUnit: 'Ton',
                        vehicleTypesBO: {
                            "id": dimensionFeet
                        }
                    },
                    identityDocuments

                };

                this.setState({ createAsset: params });
                Mixpanel.track("Asset Page Created", { 'ERPAssetCreated': moment().valueOf() })


                this.props.createAsset(params);
                //     const { name, contactNumber, email, secondaryEmail, secondaryContactNumber, gst, contactPerson, vendorTypes } = { ...values };
                //     let vTypes = vendorTypes.map(v => v["key"]);
                //     let linkedLocations;
                //     linkedLocations = {
                //         "customer": values['customerlocations'] ? this.getMappedData(this.props.customerLocations, values['customerlocations']) : null,
                //         "partner": values['partnerlocations'] ? this.getMappedData(this.props.partnerLocationDetails, values['partnerlocations']) : null
                //     }

                //     const location = {
                //         role: 'BILLING',
                //         name: values['locationname'],
                //         address: {
                //             address1: values['address1'],
                //             address2: values['address2'],
                //             landmark: values['landmark'],
                //             pincode: {
                //                 id: this.props.pincodeDetails[values['pincode']]['id'],
                //                 city: {
                //                     id: values['city']
                //                 }
                //             }
                //         }
                //     };
                //     let gstproof = {}, tdsproof = {};
                //     if (values['gst']) {
                //         gstproof = {
                //             "s3URL": values['gstproof'],
                //             "documentType": "OTHERS",
                //             "documentValue": values['gst']
                //         }
                //     };

                //     if (values['tds']) {
                //         tdsproof = {
                //             "s3URL": values['tdsproof'],
                //             "documentType": "TDS_PROOF",
                //             "documentValue": values['tds']
                //         }
                //     };

                //     const bankproof = {
                //         "s3URL": values['bankproof'],
                //         "documentType": "BANK_DOCUMENTS",
                //         "documentValue": JSON.stringify({ accountNumber: values['accountNumber'], ifsc: values['ifsc'], bankname: values['bankname'] })
                //     }


                //     let identityDocuments = [
                //         {
                //             "s3URL": values['panproof'],
                //             "documentType": "PAN_CARD",
                //             "documentValue": values['pan']
                //         },
                //         gstproof,
                //         tdsproof,
                //         bankproof
                //     ];


                //     identityDocuments = identityDocuments.concat(values['vendorcontract']);
                //     identityDocuments = identityDocuments.filter(value => value != undefined && Object.keys(value).length !== 0);

                //     const params = {
                //         name,
                //         contactNumber,
                //         email,
                //         secondaryEmail,
                //         secondaryContactNumber,
                //         gst,
                //         contactPerson,
                //         vendorTypes: vTypes,
                //         linkedLocations,
                //         location,
                //         identityDocuments
                //     }


                //     this.props.createVendor(params);
            });


        });

    }



    getMappedData(source, dest) {
        const list = {};

        source.map((val) => {
            dest.map((v, k) => {
                if (v == val["id"]) {
                    if (!list[val["entityId"]]) list[val["entityId"]] = [];
                    list[val["entityId"]].push(v)
                }
            })
        })

        return list;
    }

    selectVehicleType = (value) => {
        let units = undefined;
        let { setFieldsValue } = this.props.form;
        let assetCapacity = this.props.appConfig.assetTypes.map(asset => {
            if (asset.vehicleType == value) {
                units = asset.vehicleCapacity
            }
        });
        setFieldsValue({ capacity: units })
    }

    selectLocation = (id) => {
        this.props.form.resetFields(['assignedUserId']);
        this.props.getUsers(id);
    }

    selectUser = id => {
        this.props.assetAssignedUsers.map(users => {
            if (users.id == id) {
                notifyApiError("Selected User already mapped with Asset", "Validation");
                this.props.form.resetFields(['assignedUserId']);
            }
        })
    }

    selectVendor = (option) => {
        let { setFieldsValue } = this.props.form;
        this.props.vendorsList.map(vendor => {
            if (option.key == vendor.id) {
                setFieldsValue({
                    contactPersonMobile: vendor.contactNumber,
                    contactPersonName: vendor.contactPerson
                })
            }
        })
    }

    addUser = () => {
        this.setState({ userModal: true, username: this.props.form.getFieldValue('driverName'), errors: [], showErrors: false })
    }

    cancelUserModal = () => {
        this.setState({ userModal: false, username: undefined, contactNumber: undefined, userEmail: undefined })
    }

    changeState = (e, type) => {
        switch (type) {
            case "email":
                this.setState({ userEmail: e.target.value });
                break;
            case "username":
                this.setState({ username: e.target.value });
                break;
            case "contactNumber":
                this.setState({ contactNumber: e.target.value })
            default:
                break;
        }

    }

    createUser = () => {
        let { errors, showErrors } = this.state;
        showErrors = false;
        errors = [];

        if (_.isEmpty(this.state.contactNumber) || _.isEmpty(this.state.username)) {
            errors.push("Contact Number and Driver Name fields are mandatory");
            showErrors = true;
        }

        var phoneRegex = new RegExp(validationRules.mobile);
        if (!phoneRegex.test(this.state.contactNumber)) {
            showErrors = true;
            errors.push("Invalid Phone Number!");
        }

        // if(!this.state.userEmail) {
        //     this.setState({userEmail: `${this.state.contactNumber}@${this.state.username}.com`})
        // }
        // var emailRegex = new RegExp(validationRules.email);
        // if (!emailRegex.test(this.state.userEmail)) {
        //     showErrors = true;
        //     errors.push("Invalid Email!");
        // }
        this.setState({ errors, showErrors });
        if (showErrors) {
            return;
        }


        const { form } = this.props;
        const { getFieldValue } = form;
        const roleId = process.env.NODE_ENV === 'production' ? 1127 : 2174;
        let params = {
            "username": this.state.contactNumber,
            "password": this.state.contactNumber,
            "name": this.state.username,
            "contactNumber": this.state.contactNumber,
            "secondaryNumber": null,
            "email": this.state.userEmail ? this.state.userEmail : `${this.state.contactNumber}@${this.state.username.replace(/ /g, '')}.com`,
            "secondaryEmail": null,
            "locationId": getFieldValue('originLocationId'),
            "userLevel": "FIELD_EXECUTIVE",
            "rolesToLocationsMap": {
                [roleId]: [
                    {
                        "id": getFieldValue('originLocationId')
                    }
                ]
            },
            "accessExportData": false
        }
        // console.log("params", params)
        this.props.createNewUser(params);

    }


    handleVehicle = (rule, value, callback) => {
        if (this.state.isSubmitted) {
            callback();
        }
        var vehicleRegex = new RegExp(validationRules.vehicleNumber);
        const vehicleNumber = value;

        if (vehicleRegex.test(value) && !this.state.isEdit) {
            let filters = {
                filters: {

                    totalCount: 0,
                    fetchObjects: ['ASSET_LOCATION'],
                    partnerIds: [Constants.PARTNER_ID],
                    partialContentSearch: value
                }

            }

            apiUtils.apiRequest({
                method: 'POST',
                url: `/b2b/v1/assets/fetch`,
                data: filters
            }).then(res => {

                if (res?.data?.response?.assets && res?.data?.response?.assets?.length > 0) {

                    let vehicle = res?.data?.response?.assets[0]?.vehicleNumber;
                    if (vehicle === value) {
                        callback(`Vehicle ${vehicle} Already Exist`);
                    } else {
                        callback();
                    }


                }
            })

        } else if (vehicleRegex.test(value) && this.state.isEdit) {
            let asset = this.props.assetsData && this.props.assetsData[0];
            if (asset.vehicleNumber === value) {
                callback();
            } else {
                let filters = {
                    filters: {

                        totalCount: 0,
                        fetchObjects: ['ASSET_LOCATION'],
                        partnerIds: [Constants.PARTNER_ID],
                        partialContentSearch: value
                    }

                }
                apiUtils.apiRequest({
                    method: 'POST',
                    url: `/b2b/v1/assets/fetch`,
                    data: filters
                }).then(res => {

                    if (res?.data?.response?.assets && res?.data?.response?.assets?.length > 0) {

                        let vehicle = res?.data?.response?.assets[0]?.vehicleNumber;
                        if (vehicle === value) {
                            callback(`Vehicle ${vehicle} Already Exist`);
                        } else {
                            callback();
                        }


                    }
                })
            }
        } else {
            callback();
        }
    }

    selectBillingModel = bm => {

        this.props.form.setFieldsValue({ 'vehicleCapacity': undefined, 'dimensionFeet': undefined, bodyType: undefined });
        const capacities = this.state?.billingModels?.filter(b => b?.vehicleType === bm)[0];

        this.setState({ capacities: capacities?.capacityBOS });
    }

    selectCapacity = capacity => {
        this.props.form.setFieldsValue({ 'dimensionFeet': undefined, bodyType: undefined });


        const bodies = this.state?.capacities?.filter(b => b?.vehicleCapacity === capacity)[0];

        this.setState({ bodies: bodies?.bodyTypeBOS }, () => {
            if (this.state.bodies && this.state.bodies.length > 0) {
                const bodyType = this.state.bodies[0]?.bodyType;
                this.props.form.setFieldsValue({ bodyType: bodyType }, () => {
                    this.selectBody(bodyType);
                })
            }

        })



    }

    selectBody = body => {

        this.props.form.setFieldsValue({ 'dimensionFeet': undefined });

        const dimensions = this.state?.bodies?.filter(b => b?.bodyType === body)[0];
        if (dimensions?.lbhDetailsBOS && dimensions?.lbhDetailsBOS?.length > 0) {
            this.setState({ dimensions: dimensions?.lbhDetailsBOS }, () => {
                const dimensionFeet = this.state.dimensions.filter(d => d?.isDefault);
                if (dimensionFeet?.length > 0) {
                    this.props.form.setFieldsValue({ dimensionFeet: dimensionFeet[0]?.id })
                }
            });
        }
    }


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };

        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { Option } = Select;
        // let assetType = getFieldValue('assetVehicleType');

        return (
            <>

                <Form {...formItemLayout}>
                    <Row gutter={12}>
                        <h2><Icon type="arrow-left" onClick={() => this.goBack()} />  Asset Management</h2>
                        <Col span={16}>
                            <Card>
                                <Form.Item label="Owner">
                                    {getFieldDecorator('owner', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Owner field cannot be blank!',
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Vendor"
                                            showArrow
                                            labelInValue
                                            showSearch
                                            onSelect={this.selectVendor}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            loading={this.props.vendorslistloading}
                                        >
                                            {this.props.vendorsList &&
                                                <Select.Option
                                                    key={-1}
                                                    value={-1}
                                                    label={"SELF"}>
                                                    SELF
                                        </Select.Option>
                                            }

                                            {this.props?.vendorsList?.map(vendor => (
                                                <Select.Option
                                                    key={vendor.id}
                                                    value={vendor.id}
                                                    label={vendor.name}
                                                >
                                                    {vendor.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Driver Name" >
                                    {getFieldDecorator('driverName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Driver name cannot be blank!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Driver name"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Vehcile Number" >
                                    {getFieldDecorator('vehicleNumber', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vehcile number cannot be blank!',
                                            },
                                            {
                                                pattern: validationRules.vehicleNumber,
                                                message: "Please enter a valid vehicle number (Special chars and spaces are not allowed and minimum 7 characters"

                                            },
                                            { validator: this.handleVehicle }
                                        ],
                                    })(
                                        <Input
                                            placeholder="Vehcile number"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Billing Model" >
                                    {getFieldDecorator('billingModel', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Billing Model cannot be blank!',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Billing Model"
                                            showArrow
                                            showSearch
                                            onSelect={this.selectBillingModel}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.billingModels?.map(bm => (
                                                <Select.Option
                                                    key={bm.vehicleType}
                                                    value={bm.vehicleType}
                                                    label={bm.vehicleType}

                                                >
                                                    {bm.vehicleType}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Vehicle Capacity(in Ton)" >
                                    {getFieldDecorator('vehicleCapacity', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vehicle capacity cannot be blank!',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Capacity"
                                            showArrow
                                            showSearch
                                            onSelect={this.selectCapacity}
                                            filterOption={(input, option) =>
                                                option.props.children?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.capacities?.map(bm => (
                                                <Select.Option
                                                    key={bm.vehicleCapacity}
                                                    value={bm.vehicleCapacity}
                                                    label={bm.vehicleCapacity}

                                                >
                                                    {bm.vehicleCapacity} {bm.vehicleCapacityUnit}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Body Type" >
                                    {getFieldDecorator('bodyType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Body type cannot be blank!',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select body ttpe"
                                            showArrow
                                            showSearch
                                            onSelect={this.selectBody}
                                            filterOption={(input, option) =>
                                                option.props.children?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.bodies?.map(bm => (
                                                <Select.Option
                                                    key={bm.bodyType}
                                                    value={bm.bodyType}
                                                    label={bm.bodyType}

                                                >
                                                    {bm.bodyType}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Dimensions (in Feet)" >
                                    {getFieldDecorator('dimensionFeet', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Dimension Feet cannot be blank!',
                                            }
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Dimension"
                                            showArrow
                                            showSearch
                                            // onSelect={this.selectCapacity}
                                            filterOption={(input, option) =>
                                                option.props.children?.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.dimensions?.map(dm => (
                                                <Select.Option
                                                    key={dm.id}
                                                    value={dm.id}
                                                    label={dm.id}

                                                >
                                                    {dm.length} x {dm.breadth} x {dm.height}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                {/* <Form.Item label="Vehicle Type">
                                    {getFieldDecorator('vehicleType', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vehicle Type field cannot be blank!',
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Vehicle Type"
                                            showArrow
                                            showSearch
                                            onSelect={this.selectVehicleType}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.props.appConfig.assetTypes?.map(assetsType => (
                                                <Select.Option
                                                    key={assetsType.vehicleType}
                                                    value={assetsType.vehicleType}
                                                    label={assetsType.vehicleType}

                                                >
                                                    {assetsType.vehicleType}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Capacity" >
                                    {getFieldDecorator('capacity',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Units cannot be blank!',
                                                },
                                            ],
                                        })(

                                            <Row>
                                                <Col span={12}>
                                                    <Input
                                                        placeholder="Enter Units"
                                                        disabled
                                                        value={getFieldValue('capacity')}
                                                    />
                                                </Col>
                                                <Col span={10} offset={2}>
                                                    <Input
                                                        placeholder="Ton"
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>



                                        )}
                                </Form.Item> */}

                                <Form.Item label="Registration Number" >
                                    {getFieldDecorator('registrationNumber', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Registration number cannot be blank!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Registration number"
                                        />
                                    )}
                                </Form.Item>

                                <InputGroup>
                                    <Row gutter={2}>
                                        <Col span={24}>
                                            <Row gutter={2}>
                                                <Col span={16}>
                                                    <Form.Item wrapperCol={{ span: 12, offset: 4 }} label="Contact Details">

                                                        {getFieldDecorator('contactPersonName', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Contact name cannot be blank!',

                                                                }
                                                            ],
                                                        })(
                                                            <Input
                                                                placeholder="Enter Contact name"
                                                            />
                                                        )}

                                                    </Form.Item>

                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator('contactPersonMobile', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Contact number cannot be blank!"
                                                                },
                                                                {
                                                                    pattern: validationRules.mobile,
                                                                    message: "Please enter valid mobile number"

                                                                }
                                                            ],
                                                        })(
                                                            <Input
                                                                placeholder="Enter Contact number"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                            </Row>

                                        </Col>

                                    </Row>
                                </InputGroup>
                                {/* <Form.Item label="Contact Details">
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('contactPersonName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Contact name cannot be blank!',

                                                },
                                                {
                                                    pattern: validationRules.mobile,
                                                    message: "Please enter valid mobile number"

                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter Contact name"
                                            />
                                        )}
                                    </div>
                                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('contactPersonMobile', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Contact number cannot be blank!"
                                                },
                                                {
                                                    pattern: validationRules.mobile,
                                                    message: "Please enter valid mobile number"

                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter Contact number"
                                            />
                                        )}
                                    </div>
                                </Form.Item>
                                 */}
                                <Form.Item label="Location">
                                    {getFieldDecorator('originLocationId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Location field cannot be blank!',
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select Location"
                                            showArrow
                                            showSearch
                                            loading={this.props.locationslistloading}
                                            onSelect={this.selectLocation}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.props?.locationsList?.map(location => (
                                                <Select.Option
                                                    key={location.id}
                                                    value={location.id}
                                                    label={location.name}
                                                >
                                                    {location.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Users">
                                    {getFieldDecorator('assignedUserId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'User field cannot be blank!',
                                            },
                                        ],
                                    })(
                                        <Select
                                            placeholder="Select User"
                                            showArrow
                                            showSearch
                                            optionLabelProp="label"

                                            filterOption={(input, option) => {
                                                if (_.hasIn(option, "key")) {
                                                    return option?.key?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                                                }
                                            }

                                            }
                                            onSelect={this.selectUser}
                                            loading={this.props.userslistloading}
                                            dropdownRender={menu => (
                                                <>
                                                    {this.props.form.getFieldValue('originLocationId') &&
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            {Constants.SELECTED_APPLICATION === 'B2B' &&
                                                                <div
                                                                    style={{ padding: '4px 8px', cursor: 'pointer' }}
                                                                    onMouseDown={e => e.preventDefault()}
                                                                    onClick={this.addItem}
                                                                >
                                                                    <Button onClick={this.addUser}><Icon type="plus" /> Add Users</Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            )}
                                        >
                                            {this.props?.newUsers?.map(user => (
                                                <Select.Option
                                                    key={`${user.name} - ${user.contactNumber.substring(6, 10)}`}
                                                    value={user.id}
                                                    label={user.name}
                                                    disabled={user.assigned}
                                                >
                                                    {user.assigned ?
                                                        <div>
                                                            <span>{user.name} - <b>{("******").concat(user.contactNumber.substring(6, 10))}</b></span>
                                                            <span style={{ float: 'right' }}><Tag color="red">Assigned</Tag></span>
                                                        </div>
                                                        : <div>
                                                            <span>{user.name} - <b>{("******").concat(user.contactNumber.substring(6, 10))}</b></span>
                                                            <span style={{ float: 'right' }}><Tag color="green">Unassigned</Tag></span>
                                                        </div>
                                                    }

                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>


                                {/* <Form.Item label="Phone number">
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('contactNumber', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input vendor number!',
                                                },
                                                {
                                                    pattern: validationRules.mobile,
                                                    message: "Please enter valid mobile number"

                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter Phone number"
                                            />
                                        )}
                                    </div>
                                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('secondaryContactNumber', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Please enter something"
                                                },
                                                {
                                                    pattern: validationRules.mobile,
                                                    message: "Please enter valid mobile number"

                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder="Secondary number"
                                            />
                                        )}
                                    </div>
                                </Form.Item>
                                <Form.Item label="Email" >

                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'Please input valid email!',
                                                },
                                                {
                                                    required: true,
                                                    message: 'Please input email!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter Email ID"
                                            />
                                        )}
                                    </div>
                                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('secondaryEmail')(
                                            <Input
                                                placeholder="Secondary Email"
                                            />
                                        )}
                                    </div>


                                </Form.Item>
                                <Form.Item label="Location Name" >
                                    {getFieldDecorator('locationname', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input location name!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Location name"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="Partner" >
                                    {getFieldDecorator('partnername')(
                                        <Select mode="multiple" placeholder="Select Partners" showArrow onChange={this.onPartnerChange}>
                                            {this.props?.partnerList?.map(partner => (
                                                <Select.Option
                                                    key={partner.linkPartnerBO.id}
                                                    value={partner.linkPartnerBO.id}
                                                    label={partner.linkPartnerBO.name}
                                                >
                                                    {partner.linkPartnerBO.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Partner Locations" >
                                    {getFieldDecorator('partnerlocations')(
                                        <Select mode="multiple" placeholder="Select Partner location" showArrow loading={this.props.partnerLocationLoading}>
                                            {this.props?.partnerLocationDetails?.map(location => (
                                                <Select.Option
                                                    key={location.id}
                                                    value={location.id}
                                                    label={location.name}
                                                >
                                                    {location.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Customer" >
                                    {getFieldDecorator('customername')(
                                        <Select mode="multiple" placeholder="Select Customers" showArrow onChange={this.onCustomerChange}>
                                            {this.props?.customerList?.map(customer => (
                                                <Select.Option
                                                    key={customer.id}
                                                    value={customer.id}
                                                    label={customer.name}
                                                >
                                                    {customer.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Customer Locations" >
                                    {getFieldDecorator('customerlocations')(
                                        <Select mode="multiple" placeholder="Select Customer locations" showArrow>
                                            {this.props?.customerLocations?.map(location => (
                                                <Select.Option
                                                    key={location.id}
                                                    value={location.id}
                                                    label={location.name}
                                                >
                                                    {location.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="Head Office Address">
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('address1', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input address!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Address1"
                                            />
                                        )}
                                    </div>
                                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('address2')(
                                            <Input
                                                placeholder="Address2"
                                            />
                                        )}
                                    </div>

                                </Form.Item>
                                <Form.Item label="Landmark">
                                    {getFieldDecorator('landmark', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input Landmark!',
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Landmark"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="City">
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('city', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select city',
                                                },
                                            ],
                                        })(
                                            <Select placeholder="Select City" showSearch onChange={this.changeCity} loading={this.props.cityloading}>
                                                {this.props?.cities?.map(city => (
                                                    <Select.Option
                                                        key={city.id}
                                                        value={city.id}
                                                        label={city.name}
                                                    >
                                                        {city.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </div>
                                    <span style={{ display: 'inline-block', width: '24px' }}></span>
                                    <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                        {getFieldDecorator('pincode', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select pincode',
                                                },
                                            ],
                                        })(
                                            <Select placeholder="Select Pincode" showSearch loading={this.props.pincodeloading}>
                                                {this.state?.pincodes?.map(pincode => (
                                                    <Select.Option
                                                        key={pincode}
                                                        value={pincode}
                                                        label={pincode}
                                                    >
                                                        {pincode}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </div>


                                </Form.Item>
                                <Form.Item label="Contact Person">
                                    {getFieldDecorator('contactPerson', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "contact person can't be blank"
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="Contact Person"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="GST">
                                    <Switch checkedChildren="Yes" unCheckedChildren="No" checked={this.state.gstcheck} onChange={this.changeGST} /> <span>GST Applicable</span>

                                    <Form.Item>
                                        {getFieldDecorator('gst', {
                                            rules: [
                                                {
                                                    validator: async (rule, value) => {
                                                        if (this.state.gstcheck) {
                                                            if (!value) {
                                                                throw new Error("Field can't be wrong");
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    pattern: validationRules.gst,
                                                    message: "Please enter valid GSTIN number"

                                                }
                                            ]
                                        })(

                                            <Input
                                                placeholder="Enter GST"
                                                disabled={!this.state.gstcheck}
                                            />
                                        )}

                                    </Form.Item>



                                </Form.Item>
                                <Form.Item label="Vendor Type" >
                                    {getFieldDecorator('vendorTypes', {
                                        initialValue: this.state.initialVendors
                                    })(
                                        <Select mode="multiple" labelInValue={true} placeholder="Select Vendor Type" showArrow>
                                            {vTypes.map(vendor => (
                                                <Select.Option

                                                    key={vendor.value}
                                                    disabled={vendor.disabled}
                                                >
                                                    {vendor.name}
                                                </Select.Option>
                                            ))}

                                        </Select>
                                    )}
                                </Form.Item>
 */}
                            </Card>
                        </Col>

                        <Col span={8}>
                            <Card>
                                <h3>KYC Uploads</h3>
                                <p>Upload only duly filled & signed forms. We supoort only .jpg & pdf formats</p>
                                <Form.Item label="">

                                    {getFieldDecorator('licenceFront')(
                                        <>
                                            <div>Driving Licence - Front and Back View</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                label={"Upload Front View"}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "licenceFront", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.licenceFrontFile ? this.state.licenceFrontFile : ''}</span>

                                            {/* <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                label={"Upload Back View"}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "licenceBack", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.licenceBackFile ? this.state.licenceBackFile : ''}</span> */}
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="">

                                    {getFieldDecorator('licenceBack')(
                                        <>
                                            {/* <div>Driving Licence - Front and Back View</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                label={"Upload Front View"}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "licenceFront", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.licenceFrontFile ? this.state.licenceFrontFile : ''}</span> */}

                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                label={"Upload Back View"}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "licenceBack", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.licenceBackFile ? this.state.licenceBackFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="" >
                                    {getFieldDecorator('registration')(
                                        <>
                                            <div>Registration Certificate</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "registration", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.registrationFile ? this.state.registrationFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="" >
                                    {getFieldDecorator('pollution')(
                                        <>
                                            <div>Pollution Certificate</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "pollution", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.pollutionFile ? this.state.pollutionFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="" >
                                    {getFieldDecorator('insurance')(
                                        <>
                                            <div>Insurance Certificate</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "insurance", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.insuranceFile ? this.state.insuranceFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="" >
                                    {getFieldDecorator('vehiclepermit')(
                                        <>
                                            <div>Vehicle Permit</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "vehiclepermit", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.vehiclepermitFile ? this.state.vehiclepermitFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="" >
                                    {getFieldDecorator('gpsinstallation')(
                                        <>
                                            <span>GPS Installation Certificate</span>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "gpsinstallation", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.gpsinstallationFile ? this.state.gpsinstallationFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>

                                <Form.Item label="" >
                                    {getFieldDecorator('fitness')(
                                        <>
                                            <div>Fitness Certificate</div>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "fitness", name)}
                                                path="asset"
                                                objKey="assetCreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.fitnessFile ? this.state.fitnessFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                {/* {
                                    this.state.gstcheck ?
                                        <Form.Item label="GST Proof" >
                                            {getFieldDecorator('gstproof')(
                                                <>
                                                    <FileUpload
                                                        acceptTypes={this.state.acceptTypes}
                                                        directUpload={true}
                                                        disableUploadButton={true}
                                                        onUploadFinish={($events, name) => this.onUploadFinish($events, "gstproof", name)}
                                                        path="vendor"
                                                        objKey="vendorcreate"
                                                        clearFileAfterUpload={false}
                                                    />
                                                    <span>{this.state.gstFile ? this.state.gstFile : ''}</span>
                                                </>
                                            )}
                                        </Form.Item>
                                        : <></>
                                }
                                <Form.Item label="TDS Number" >
                                    {getFieldDecorator('tds', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "TDS number can't be blank",
                                            },
                                        ],
                                    })(
                                        <Input
                                            placeholder="TDS Number"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="TDS Proof" >
                                    {getFieldDecorator('tdsproof')(
                                        <>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "tdsproof", name)}
                                                path="vendor"
                                                objKey="vendorcreate"
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.tdsFile ? this.state.tdsFile : ''}</span>
                                        </>
                                    )}
                                </Form.Item>
                                <Form.Item label="Vendor Contract" >
                                    {getFieldDecorator('vendorcontract')(
                                        <>
                                            <FileUpload
                                                acceptTypes={this.state.acceptTypes}
                                                directUpload={true}
                                                disableUploadButton={true}
                                                onUploadFinish={($events, name) => this.onUploadFinish($events, "vendorcontract", name, "multiple", "VENDOR_CONTRACT")}
                                                path="vendor"
                                                multiple={true}
                                                objKey="vendorcreate"
                                                showUploadList
                                                clearFileAfterUpload={false}
                                            />
                                            <span>{this.state.vendorFiles.length > 0 ? this.state.vendorFiles.map(v => <>{v}<br /></>) : ''}</span>
                                        </>
                                    )}
                                </Form.Item> */}

                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Card>
                            <Row type="flex" justify="end">
                                <Col span={4}>
                                    <Button size="large" onClick={() => this.goBack()}>CANCEL</Button>
                                </Col>
                                <Col span={4}>
                                    {this.state.isEdit ?
                                        <Button size="large" loading={this.props.assetUpdateLoading} type="primary" onClick={() => this.handleUpdate()}>UPDATE</Button> :
                                        <Button size="large" loading={this.props.assetCreateLoading} type="primary" onClick={() => this.handleSubmit()}>CREATE</Button>
                                    }

                                </Col>
                            </Row>
                        </Card>
                    </Row>
                </Form >
                <Modal
                    title={"Create Driver"}
                    visible={this.state.userModal}
                    maskClosable={false}
                    closable={false}
                    footer={[
                        <Button key="back" loading={this.props.userSettings?.user?.loading} onClick={this.cancelUserModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={this.props.userSettings?.user?.loading} onClick={this.createUser}>
                            Create
                        </Button>,
                    ]}

                >
                    <Form.Item colon={false} style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }} label={<span className="form-label-text">Name <span style={{ color: 'red' }}>*</span></span>}>
                        <Input
                            placeholder="Enter Name"
                            onChange={(e) => this.changeState(e, "username")}
                            value={this.state.username}
                            disabled
                        />
                    </Form.Item>
                    <Form.Item colon={false} style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }} label={<span className="form-label-text">Contact Number <span style={{ color: 'red' }}>*</span></span>}>
                        <Input
                            placeholder="Enter Contact Number"
                            onChange={(e) => this.changeState(e, "contactNumber")}
                            value={this.state.contactNumber}
                        />
                    </Form.Item>

                    <Form.Item colon={false} style={{ marginBottom: 0 }} wrapperCol={{ span: 24 }} label={<span className="form-label-text">Email</span>}>
                        <Input
                            type="email"
                            onChange={(e) => this.changeState(e, "email")}
                            placeholder="Enter Email"
                            value={this.state.userEmail}
                        />
                    </Form.Item>



                    <Row style={{ top: 15, right: 25 }}>
                        <Col span={24}>
                            {this.state.showErrors &&
                                <ul>
                                    {this.state.errors.map(err => (
                                        <li style={{ color: '#ff4d4f' }}>{err}</li>
                                    ))}
                                </ul>
                            }
                        </Col>

                    </Row>



                </Modal>
            </>


        )
    }
}

const mapStateToProps = state => ({
    assetDetails: state?.assets?.assetDetails,
    assetCreateError: state?.assets?.assetCreateError,
    assetCreateLoading: state?.assets?.assetCreateLoading,
    vendorsList: state?.assets?.vendorsList,
    vendorslistloading: state?.assets?.vendorslistloading,
    appConfig: state?.app?.configurations,
    locationsList: state?.assets?.locationsList,
    locationslistloading: state?.assets?.locationslistloading,
    usersList: state?.assets?.usersList,
    userslistloading: state?.assets?.userslistloading,
    assetAssignedUsers: state?.assets?.assetAssignedUsers,
    newUsers: state?.assets?.newUsers,
    assetsDataLoading: state?.assets?.assetsDataLoading,
    assetsData: state?.assets?.assetsData,
    identityDocumentBOS: state?.assets?.identityDocumentBOS,
    updateAssetDetails: state?.assets?.updateAssetDetails,
    assetUpdateLoading: state?.assets?.assetUpdateLoading,
    userSettings: state?.settingsUsers
    // customerList: state.ingest.customerList,
    // partnerList: state.header?.partnerHeaderDetails?.partners,
    // locationList: state.ingest.locationList,
    // partnerLocationDetails: state.vendor?.partnerLocationDetails,
    // customerLocations: state.vendor?.customerLocationDetails?.locations,
    // cities: state.vendor.cities,
    // allPincodes: state.vendor.allPincodes,
    // pincodeDetails: state.vendor.pincodeDetails,
    // partnerLocationLoading: state.vendor?.partnerLocationLoading,
    // cityloading: state.vendor?.cityloading,
    // pincodeloading: state.vendor?.pincodeloading,
    // vendorCreateResp: state.vendor?.vendorDetails,
    // vendorData: state.vendor?.vendorData,
    // vendorUpdateData: state.vendor?.vendorUpdateData
});

const mapDispatchToProps = dispatch => ({
    createAsset: (params) => dispatch(assetActions.createAsset(params)),
    getVendors: () => dispatch(assetActions.getVendorData()),
    getLocations: (data) => dispatch(assetActions.getLocations(data)),
    getUsers: (locationId) => dispatch(assetActions.getUsers(locationId)),
    getAssetData: (locationId) => dispatch(assetActions.getAssetData(locationId)),
    updateAsset: (id, params) => dispatch(assetActions.updateAsset(id, params)),
    resetFields: () => dispatch(assetActions.resetFields()),
    createNewUser: (customer) => dispatch(userActions.createUser(customer)),
    resetUser: () => dispatch(userActions.resetUser()),
    // setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    // getCustomerList: () => dispatch(actions.getCustomerList()),
    // getPartnerLocations: (params) => dispatch(vendorActions.getPartnerLocatinDetailsV2(params)),
    // getCustomerLocations: (params) => dispatch(vendorActions.fetchCustomerLocatinDetails(params)),
    // getLocationList: (val) => dispatch(actions.getLocationList(val)),
    // getCustomApiCall: (url, method, payload) => dispatch(actions.customUrlApiCall(url, method, payload)),
    // getEmplyeesList: () => dispatch(actions.getEmplyeesList()),
    // getCities: () => dispatch(vendorActions.fetchCities()),
    // getPincodes: () => dispatch(vendorActions.fetchPincodes()),
    // createVendor: (params) => dispatch(vendorActions.createVendor(params)),
    // updateVendor: (params, id) => dispatch(vendorActions.updateVendor(params, id)),
    // getVendorData: (id) => dispatch(vendorActions.getVendorData(id))
});


export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'AssetCreate' })(AssetCreate));