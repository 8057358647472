import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Icon, Select, Form, Spin, TimePicker, Divider, Empty, Tooltip } from 'antd';
import * as actions from './actions';
import moment from 'moment';
import { Constants } from '../../../common/utils/constants';
import './promiseTime.scss';
let id = 0;

class PromiseTime extends PureComponent {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            cptValue: [],
            etaValue: [],
            resetbox: true,
            disableValue: [],
            locationId: 0,
        }
    }

    componentDidMount() {
        console.log("this.props.locationGroup", this.props.locationGroup)
        this.props.fetchLocationGroup(this.props.locationGroup);
        // if(this.props.getAllLocations) {
        //     let params = { "filters": { "entityTypes": ["CUSTOMER"], "entityIds": [this.props.locationGroup], "isConsiderCreatorId": false, "fetchObjects": [], "status": true, pageNo: 1, pageSize: 10, partialContentSearch: value } }
        //     this.props.getLocations(params);
        // } else {
        //     this.props.fetchLocationGroup(this.props.locationGroup);
        // }
        if (this.props.initialData && this.props.initialData.length > 0) {
            this.props.form.resetFields();
            this.getInitialDataFromat(this.props.initialData);
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.locationGroup !== prevProps.locationGroup) {
            this.getInitialDataFromat(this.props.initialData);
            this.props.fetchLocationGroup(this.props.locationGroup);
        }
    }

    onChangeTime = (time, timeString) => {
    }

    onReset = () => {
        this.props.form.resetFields();
        this.setState({ resetbox: false })
    };

    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const keys = this.props.form.getFieldValue('keys');
                let customerPromiseTimeRuleMappingBOS = [];
                let lastLocationId;
                let selectedData = values.location;
                console.log("values", this.props.initialData)
                let id = null, customerPromiseBOID = null, destinationEntityId;
                if (this.props.initialData && this.props.initialData?.length > 0) {
                    id = this.props.initialData[0]?.id;
                }
                if (this.props.initialData && this.props.initialData?.length > 0 && this.props.initialData[0]?.destinationEntityId) {
                    destinationEntityId = this.props.initialData[0]?.destinationEntityId;
                }
                let bagsElement = [];
                Object.keys(keys).forEach(x => {

                    if (x.substring(0, 8) === "location") {
                        lastLocationId = x;
                        bagsElement = [];
                    } else {
                        bagsElement.push(selectedData[x]);
                        selectedData[lastLocationId].bag = bagsElement;
                    }
                })
                Object.keys(keys).forEach((x, k) => {
                    if (x.substring(0, 8) === "location") {
                        let customerPromiseTimeRuleBOS = []
                        let baseObject = {};
                        baseObject.id = id;
                        baseObject.partnerId = Constants.PARTNER_ID;
                        baseObject.customerId = this.props.locationGroup;
                        baseObject.sourceEntityType = "LOCATION";
                        baseObject.destinationEntityType = "LOCATION";
                        baseObject.sourceEntityId = selectedData[x]?.name;
                        if (destinationEntityId) {
                            baseObject.destinationEntityId = destinationEntityId;
                        }
                        baseObject.isActive = true;
                        let parentCustomerPromise = {
                            "cutoffTime": selectedData[x]?.ctime.format('hh:mm:ss'),
                            "isActive": true,
                            "id": this.props.initialData && this.props.initialData?.length > 0 ? this.props.initialData[0]?.customerPromiseTimeRuleBOS[k]?.id : null,
                            "ruleType": selectedData[x]?.locationType === "CPT" ? "CPT_BOOKING" : "CPT_ETA_BOOKING",
                            "baseType": selectedData[x]?.baseType
                        }
                        if (selectedData[x].locationType === "CPT") {
                            parentCustomerPromise.promiseTime = selectedData[x]?.ptime.format('hh:mm:ss');
                            parentCustomerPromise.days = selectedData[x]?.ttime;
                        } else {
                            let timingValues = {
                                "eta": selectedData[x]?.ttime,
                                "unit": selectedData[x]?.timeSlot,
                            }
                            parentCustomerPromise.attributes = timingValues;
                        }
                        customerPromiseTimeRuleBOS.push(parentCustomerPromise);
                        if (selectedData[x]?.bag && selectedData[x].bag.length > 0) {
                            selectedData[x].bag.forEach((y, l) => {
                                let customerPromise = {
                                    "cutoffTime": y?.ctime.format('hh:mm:ss'),
                                    "isActive": true,
                                    "id": this.props.initialData && this.props.initialData?.length > 0 ? this.props.initialData[0]?.customerPromiseTimeRuleBOS[l]?.id : null,
                                    "ruleType": y?.locationType === "CPT" ? "CPT_BOOKING" : "CPT_ETA_BOOKING",
                                    "baseType": y?.baseType
                                }
                                if (x.locationType === "CPT") {
                                    customerPromise.promiseTime = y?.ptime.format('hh:mm:ss');
                                    customerPromise.days = y?.ttime;
                                } else {
                                    let timingValues = {
                                        "eta": y?.ttime,
                                        "unit": y?.timeSlot,
                                    }
                                    customerPromise.attributes = timingValues;
                                }
                                customerPromiseTimeRuleBOS.push(customerPromise);
                            })
                        }
                        baseObject.customerPromiseTimeRuleBOS = customerPromiseTimeRuleBOS;
                        customerPromiseTimeRuleMappingBOS.push(baseObject);
                    }
                })
                this.props.cutomerPromise(customerPromiseTimeRuleMappingBOS);
                this.props.form.resetFields();
            }
        });
    }

    remove = k => {
        const { form } = this.props;
        const location = form.getFieldValue(`location[${k}]`);
        delete location[`${k}`]
        const keys = form.getFieldValue('keys');
        delete keys[`${k}`]
        form.setFieldsValue({
            keys: { ...keys },
            location,
        });
        this.setState({ addedKey: true });
    };


    add = (k, type) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        id = ++id
        const nextKeys = {}
        Object.keys(keys).map(val => {
            if (val === k) {
                nextKeys[val] = keys[val];
                nextKeys[`${type}-` + id] = id;
            } else {
                nextKeys[val] = keys[val];
            }
        })
        form.setFieldsValue({
            keys: nextKeys,
        });
        this.setState({ keys: nextKeys });
    };

    oncptChange = (index, e) => {
        let etaArray = [...this.state.etaValue];
        let cptArray = [...this.state.cptValue];
        if (cptArray.includes(index)) {
            if (e === "ETA") {
                const currentIndex = cptArray.indexOf(index);
                if (currentIndex > -1) {
                    cptArray.splice(currentIndex, 1);
                }
            }
        } else {
            if (e === "CPT") {
                cptArray.push(index);
            }
        }
        etaArray.push(index);
        this.setState({ cptValue: cptArray, etaValue: etaArray });
    }

    pickUpLocationChange = (key, e) => {
        let disableArray = [];
        let currentArray = [];
        let keysArray = [];
        const { form } = this.props;
        const location = form.getFieldValue(`location`);
        const keys = form.getFieldValue('keys');
        Object.keys(keys).map(x => {
            if (x.substring(0, 8) === "location") {
                currentArray.push(location[x])
                keysArray.push(x);
            }
        })
        let currentIndex = Number(keysArray.indexOf(key));
        let previousIndex = currentIndex - 1;
        let nextIndex = currentIndex + 1;
        if (currentIndex === 0) {
            nextIndex = currentArray[nextIndex];
            if (nextIndex) {
                disableArray.push(nextIndex.name);
            }
            this.setState({ disableValue: disableArray })
        } else if (currentIndex > 0) {
            previousIndex = currentArray[previousIndex];
            nextIndex = currentArray[nextIndex];
            disableArray.push(previousIndex.name);
            if (nextIndex) {
                disableArray.push(nextIndex.name);
            }
            this.setState({ disableValue: disableArray })
        }
    }

    getInitialDataFromat = (data) => {
        const { form } = this.props;
        let setKeys = {};
        let locations = {};
        let location = {};
        const j = data?.forEach((x, index) => {
            if (x.customerPromiseTimeRuleBOS) {
                x.customerPromiseTimeRuleBOS.map((y, key) => {
                    if (key === 0) {
                        setKeys['location-' + id] = id;
                        locations['location-' + id] = y;
                        y.name = x.sourceEntityId;
                        y.locationType = ("CPT_BOOKING" === y.ruleType) ? "CPT" : "ETA";
                    } else {
                        setKeys['bag-' + id] = id;
                        locations['bag-' + id] = y;
                        y.locationType = ("CPT_BOOKING" === y.ruleType) ? "CPT" : "ETA";
                    }
                    id = ++id;
                })
            }
        })
        let cptArray = [];
        let etaArray = [];
        Object.keys(setKeys).map((x, key) => {
            if (locations[x].locationType === "CPT") {
                cptArray.push(key)
            } else {
                etaArray.push(key);
            }
            location[x] = {
                baseType: locations[x].baseType,
                ctime: moment(moment().format(locations[x].cutoffTime), "h:mm a"),
                locationType: locations[x].locationType,
            }
            if (locations[x].name) {
                location[x].name = locations[x].name;
            }
            if (locations[x].locationType === "CPT") {
                location[x].ttime = locations[x]?.days;
                location[x].ptime = moment(moment().format(locations[x].promiseTime), "h:mm a");
            } else {
                location[x].ttime = locations[x].attributes?.eta;
                location[x].timeSlot = locations[x].attributes?.unit;
            }
        })
        form.setFieldsValue({
            keys: setKeys
        });
        this.setState({ keys: setKeys, location: location, cptValue: cptArray, etaValue: etaArray });
    }


    render() {
        const { promisetimes, loading, form } = this.props;

        const { getFieldDecorator, getFieldValue } = form;

        getFieldDecorator('keys', { initialValue: { ['location-' + id]: id } });
        const keys = getFieldValue('keys');
        const formItems = promisetimes && Object.keys(keys).length > 0 ? Object.keys(keys).map((k, index) => {
            console.log("k.substring(0, 8) === 'location'", index)


            return (
                <div key={index}>
                    {
                        k.substring(0, 8) === 'location' && Object.keys(keys).length > 1 && index > 0 &&
                        <Divider />
                    }
                    <Row gutter={[6, 0]}>
                        <Col sm={24} md={24} lg={24} xl={24}>
                            <Col xl={4} xxl={4} lg={4} sm={24}>
                                {k.substring(0, 8) === 'location' && <Form.Item
                                    label={index === 0 || k.substring(0, 8) === 'location' ? this.props?.pickupLocationLabel : ''}
                                >
                                    {getFieldDecorator(`location[${k}]['name']`, {
                                        initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.name : null) : k.name,
                                        rules: [
                                            {
                                                required: true,
                                                message: `Please input Pickup Location`,
                                            },
                                        ],

                                    })(
                                        <Select
                                            disabled={this.props.pickupLocationDisable ? this.props?.pickupLocationDisable : false}
                                            placeholder="Location" showSearch filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } showArrow onMouseEnter={(e) => this.pickUpLocationChange(`${k}`, e)}>
                                            {promisetimes?.locations?.map(locOwn => (
                                                <Select.Option
                                                    key={'loc' + locOwn.name}
                                                    value={locOwn.id}
                                                    label={locOwn.name}
                                                    disabled={this.state?.disableValue?.includes(locOwn.id) ? true : false}
                                                >
                                                    <Tooltip title={locOwn.name}>
                                                        {locOwn.name}
                                                    </Tooltip>

                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>}
                            </Col>
                            <Col xl={4} xxl={5} lg={4} sm={24}>
                                <Form.Item
                                    label={index === 0 || k.substring(0, 8) === 'location' ? this.props?.cutoffTimeLabel : ''}
                                    key={k.id}
                                >
                                    {getFieldDecorator(`location[${k}]['ctime']`, {
                                        initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.ctime : moment(moment().format('HH:mm'), "h:mm a")) : moment(moment().format('HH:mm'), "h:mm a"),
                                        rules: [
                                            {
                                                required: true,
                                                message: `Please input Pickup Cutoff Time`,
                                            },
                                        ],

                                    })(
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            format="h:mm a"
                                            onChange={this.onChangeTime}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xl={3} xxl={2} lg={3} sm={24}>
                                <Form.Item
                                    label={index === 0 || k.substring(0, 8) === 'location' ? this.props?.ruleTypeLabel : ''}
                                    key={k.id}
                                >
                                    {getFieldDecorator(`location[${k}][locationType]`, {
                                        initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.locationType : '') : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input CPT/ETA",
                                            },
                                        ],

                                    })(
                                        <Select placeholder="CPT/ETA" showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } showArrow onChange={(e) => this.oncptChange(index, e)}>
                                            <Select.Option value="CPT">CPT</Select.Option>
                                            <Select.Option value="ETA">ETA</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {/* </Row> */}
                            {/* <Row gutter={[12, 0]}> */}
                            <Col xl={4} xxl={3} lg={3} sm={24}>
                                <Form.Item
                                    label={index === 0 || k.substring(0, 8) === 'location' ? 'Base Type' : ''}
                                    key={k.id}
                                >
                                    {getFieldDecorator(`location[${k}][baseType]`, {
                                        initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.baseType : '') : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: "Please input Base Type",
                                            },
                                        ],

                                    })(
                                        <Select showSearch filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } showArrow placeholder="Base Type">
                                            <Select.Option value="pickup">
                                                <Tooltip title={"Pickup Time"}>
                                                    Pickup Time
                                            </Tooltip>

                                            </Select.Option>
                                            <Select.Option value="cutoff">

                                                <Tooltip title={"Cutoff"}>
                                                    Cutoff
                                            </Tooltip>
                                            </Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xl={3} xxl={2} lg={3} sm={24}>
                                {this.state.cptValue.includes(index) && <Col xl={4} xxl={10} lg={10} sm={24} className={index === 0 || k.substring(0, 8) === 'location' ? 't-button' : 't-button2'}>{this.state.cptValue.includes(index) ? <span className="ant-form-text"> T+ </span> : null}</Col>}
                                <Col xl={this.state.cptValue.includes(index) ? 18 : 20} xxl={14} lg={14} sm={24}>
                                    <Form.Item
                                        className="class-label-hide"
                                        label={index === 0 || k.substring(0, 8) === 'location' ? this.state.cptValue.includes(index) ? 'Days' : 'ETA' : ''}
                                        // label={this.state.cptValue.includes(index) ? 'Days' : 'ETA'}
                                        key={k.id}
                                    >
                                        {getFieldDecorator(`location[${k}]['ttime']`, {
                                            initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.ttime : 0) : 0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `Please input Time`,
                                                },
                                            ],

                                        })(
                                            <Input placeholder="Time" />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col xl={3} xxl={3} lg={3} sm={24}>
                                {this.state.cptValue.includes(index) &&
                                    <Form.Item
                                        className="class-label-hide"
                                        label={index === 0 || k.substring(0, 8) === 'location' ? this.props?.promiseTimeLabel : ''}
                                        // label={this.state.cptValue.includes(index) ? 'Time': 'Unit'}
                                        key={k.id}
                                    >
                                        {getFieldDecorator(`location[${k}]['ptime']`, {
                                            initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.ptime : moment(moment().format('HH:mm'), "h:mm a")) : '',
                                            rules: [
                                                {
                                                    required: false,
                                                    message: `Please input Pickup Promise Time`,
                                                },
                                            ],

                                        })(
                                            <TimePicker style={{ width: '100%' }} format="h:mm a" onChange={this.onChangeTime} />
                                        )}
                                    </Form.Item>}
                                {this.state.etaValue.includes(index) && !this.state.cptValue.includes(index) &&
                                    <Form.Item
                                        label={index === 0 || k.substring(0, 8) === 'location' ? 'Unit' : ''}
                                        key={k.id}
                                    >
                                        {getFieldDecorator(`location[${k}][timeSlot]`, {
                                            initialValue: this.props.edit ? (this.state.location ? this.state.location[k]?.timeSlot : '') : '',
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Please input Time Slot",
                                                },
                                            ],

                                        })(
                                            <Select showSearch filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            } showArrow placeholder="select">
                                                <Select.Option value="hrs">Hrs</Select.Option>
                                                <Select.Option value="days">Days</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                }
                            </Col>
                            <Col className={index === 0 || k.substring(0, 8) === 'location' ? 'mius-button' : 'mius-button2'} xl={2} xxl={2} lg={3} sm={24}>
                                <Col xl={7}>
                                    <Form.Item>
                                        {index >= this.props?.removeIndex ? <Icon
                                            className="dynamic-delete-button"
                                            type="minus-circle-o"
                                            onClick={() => this.remove(k)}
                                        /> : null}
                                    </Form.Item>


                                </Col>
                                {this.props.showAddition &&
                                    <Col xl={7}>
                                        <Form.Item>
                                            <Icon
                                                className="dynamic-delete-button"
                                                type="plus-circle-o"
                                                onClick={() => this.add(k, `bag`)}
                                            />
                                        </Form.Item>

                                    </Col>
                                }
                            </Col>
                        </Col>
                    </Row>


                    {this.props.showAddingLocation && Object.keys(keys).length === (index + 1) &&
                        <Row>
                            <Col sm={24} md={24} lg={24} xl={24}>
                                <Col sm={24} md={12} lg={4} xl={4}>
                                    <Button type="link" onClick={() => this.add(k, "location")}>
                                        <Icon type="plus" />
                            Add Location
                        </Button>
                                </Col>
                            </Col>
                        </Row>
                    }
                </div>
            )
        }) : null;

        return (
            <>
                <Form onSubmit={this.handleSubmit} ref={this.formRef}>
                    <Card bordered={false} className="customCard_body">
                        <Spin spinning={loading}>
                            {formItems?.length > 0 ?
                                <Row>
                                    {formItems}
                                </Row>
                                :
                                <Empty
                                    description={"Rule Disabled"}
                                />
                            }
                        </Spin>
                        <Divider className="custom_divder" />
                        <Row>
                            <Button key="submit" style={{ float: 'right' }} type="primary" onClick={this.handleSubmit}>{this.props.buttonLabel}</Button>
                            {this.props.showReset &&
                                <Button key="back" style={{ float: 'right' }} onClick={this.onReset}>Reset</Button>
                            }
                        </Row>
                    </Card>
                </Form>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.promiseTime.loading,
    promisetimes: state.promiseTime,
});
const mapDispatchToProps = dispatch => ({
    fetchLocationGroup: payload => dispatch(actions.fetchLocationGroup(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'PromiseTime', onValuesChange: (props, val) => console.log() })(PromiseTime));