import * as CONSTANTS from './settingConstants';

export const settingsReducer = (state = {}, action) => {
    switch(action.type) {
        case CONSTANTS.GET_STATE_DETAILS_BEGIN: return getStateDetailsBegin(state);
        case CONSTANTS.GET_STATE_DETAILS_SUCCESS: return getStateDetailsSuccess(state, action);
        case CONSTANTS.GET_STATE_DETAILS_FAILURE: return getStateDetailsFailure(state);
        case CONSTANTS.GET_PINCODE_UPDATE_BEGIN: return pincodeUpdateBegin(state);
        case CONSTANTS.GET_PINCODE_UPDATE_SUCCESS: return pincodeUpdateSuccess(state, action);
        case CONSTANTS.GET_PINCODE_UPDATE_FAILURE: return pincodeUpdateFailure(state);
        case CONSTANTS.GET_PARTNERS_ONBOARD_FILE_BEGIN: return partnersOnboardBegin(state);
        case CONSTANTS.GET_PARTNERS_ONBOARD_FILE_SUCCESS: return partnersOnboardSuccess(state, action);
        case CONSTANTS.GET_PARTNERS_ONBOARD_FILE_FAILURE: return partnersOnboardFailure(state);
        default:
        return state;
    }
}

function partnersOnboardBegin(state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function partnersOnboardSuccess(state, action) {
    return {
        ...state,
        loading: false,
        partnersDetails: action?.payload,
        metaData: null
    }
}
function partnersOnboardFailure(state, action) {
    return {
        ...state,
        loading: false,
        partnersDetails: action && action.payload,
        metaData: null
    }
}

function getStateDetailsBegin (state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function getStateDetailsFailure(state) {
    return {
        ...state,
        loading: false,
        statesDetails: undefined,
        metaData: null
    }
}
function getStateDetailsSuccess(state, action) {
    return {
        ...state,
        loading: false,
        statesDetails: action.payload,
        metaData: null
    }
}

function pincodeUpdateBegin (state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function pincodeUpdateSuccess(state, action) {
    return {
        ...state,
        loading: false,
        pincodeDetails: action && action.payload,
        metaData: {
            showPincodeUpdateModal: true ? (action.payload.cityLinkedPincodeResponse &&
            action.payload.cityLinkedPincodeResponse[0] && 
            !action.payload.cityLinkedPincodeResponse[0].isCityPincodeUpdated): false
        }
    }
}
function pincodeUpdateFailure(state, action) {
    return {
        ...state,
        loading: false,
        pincodeDetails: action && action.payload,
        metaData: null
    }
}