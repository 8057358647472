import React from "react";
import {
  LOCAL_STORAGE_STATE,
  USER_LOGGEDIN_INFO,
  Constants,
  ENV_TYPE,
  ENVTYPES,
  BASE_URL,
  S3_REPORT_STATUS,
  USER_LEVEL_HIGER_PARTNERS,
  USER_LEVEL,
  FlowTypes,
  GenerateCodes,
} from "./constants";
import { openNotification } from "../../components/ui/notification/Notification";
import { menuconfig } from "../../configs/menu-config";
import WebWorker from "./../../workers/webWorker";
// import { setS3SyncStatus } from '../../app-actions';
// import { connect } from 'react-redux';
import * as moment from "moment";
import * as momenttimezone from "moment-timezone";
import s3worker from "../../workers/s3_worker";
import aesjs from "aes-js";
import { pModules } from "../../configs/permissionTags";
import { AuthenticationService } from "../../services/authentication.service";

const StatusColors = {
  pending: "pink",
  open: "orange",
  closed: "green",
  completed: "yellow",
  default: "yellow",
  error: "danger",
  success: "success",
  in_transit: "lsn",
};

export function generateID(length = 5) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

/**
 *
 * @param {*} num
 * @param {
 *    maximumFractionDigits: number,
 *    style: type (default: 'currency'),
 *    currency: currency code (default: 'INR')
 * } opt
 */
export function formatCurrency(num, opt = null) {
  let options = {
    style: "currency",
    currency: "INR",
  };
  if (opt) {
    options = {
      ...options,
      ...opt,
    };
  }
  if (!num) num = 0;
  return num.toLocaleString("en-IN", options);
}

export function formatDateoption(options = null) {
  if (options === null) {
    return {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
  }
  return options;
}

export function dateFormat(timeStampString) {
  return moment(timeStampString)
    .format("D MMM hh:mm A")
    .valueOf();
}
export function dateFormatWithMin(timeStampString) {
  return moment(timeStampString)
    .format("DD-MMM hh:mm:ss A")
    .valueOf();
}

export function dateTimeStampFormat(timeStampString) {
  return moment(timeStampString);
}

export function hexToBase64(hexstring) {
  return btoa(
    hexstring
      .match(/\w{2}/g)
      .map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
      })
      .join("")
  );
}

export function base64ToHex(str) {
  for (
    var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = [];
    i < bin.length;
    ++i
  ) {
    let tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = "0" + tmp;
    hex[hex.length] = tmp;
  }
  return hex.join("");
}

export function encrypt(message = "") {
  let keys = localStorage
    .getItem(USER_LOGGEDIN_INFO.TOKEN_ID)
    .substr(localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).length - 16);
  let key = aesjs.utils.utf8.toBytes(keys);
  let str2 = 16 - (message.length % 16) + message.length;
  let textbox = message.padEnd(str2, "_");
  let textBytes = aesjs.utils.utf8.toBytes(textbox);
  let aesEcb = new aesjs.ModeOfOperation.ecb(key);
  let encryptedBytes = aesEcb.encrypt(textBytes);
  let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
  return hexToBase64(encryptedHex);
}

export function decrypt(message = "") {
  let keys = localStorage
    .getItem(USER_LOGGEDIN_INFO.TOKEN_ID)
    .substr(localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).length - 16);
  let encryptedHex = base64ToHex(message);
  let key = aesjs.utils.utf8.toBytes(keys);
  let aesEcb = new aesjs.ModeOfOperation.ecb(key);
  let encryptedBytess = aesjs.utils.hex.toBytes(encryptedHex);
  let decryptedBytes = aesEcb.decrypt(encryptedBytess);
  let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return decryptedText;
}

export function dateDiff(lastTime) {
  let now = moment(moment(new Date())).format("MM/DD/YYYY");
  let end = moment(lastTime)
    .format("MM/DD/YYYY")
    .valueOf();
  let date1 = new Date(end);
  let date2 = new Date(now);
  let diffTime = Math.abs(date2 - date1);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays <= 1) {
    let time1 = moment(moment(new Date())).format("DD/MM/YYYY HH:mm:ss");
    let time2 = moment(lastTime)
      .format("DD/MM/YYYY HH:mm:ss")
      .valueOf();
    let ms = moment(time1, "DD/MM/YYYY HH:mm:ss").diff(
      moment(time2, "DD/MM/YYYY HH:mm:ss")
    );
    let hoursdiff = moment.duration(ms);
    let time =
      Math.floor(hoursdiff.asHours()) +
      moment.utc(ms).format(":mm") +
      " hrs ago";
    return time;
  } else {
    return diffDays + " Days ago";
  }
}

export function dateDiffFormat(lastTime) {
  let Day = moment(lastTime)
    .format()
    .valueOf();
  return moment(Day).fromNow();
}

export function dateFormating(lastTime) {
  let now = moment(moment(new Date())).format("MM/DD/YYYY");
  let end = moment(lastTime)
    .format("MM/DD/YYYY")
    .valueOf();
  let date1 = new Date(end);
  let date2 = new Date(now);
  let diffTime = Math.abs(date2 - date1);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays < 1) {
    let time =
      "Today, " +
      moment(lastTime)
        .format("h:mm A")
        .valueOf();
    return time;
  } else {
    return moment(lastTime)
      .format("D MMM h:mm A")
      .valueOf();
  }
}

export function formatDate(timeStampString, options = {}) {
  return new Intl.DateTimeFormat("en-GB", options).format(timeStampString);
}

export function formatTime(timeStampString) {
  return new Date(timeStampString).toLocaleTimeString();
}

export function getFilterParams() {
  return {
    pageNo: 1,
    pageSize: 10,
  };
}
// Handle HTTP errors since fetch won't.
export function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function parsePermission(permissions) {
  if (!permissions) {
    return null;
  }
  const pObj = {};
  permissions.forEach((permission) => {
    if (!pObj[permission.application]) {
      pObj[permission.application] = {};
    }

    if (!pObj[permission.application][permission.module]) {
      pObj[permission.application][permission.module] = { permissions: [] };
    }

    if (permission.submodule == null) {
      pObj[permission.application][permission.module].permissions.push(
        permission.action
      );
    } else {
      if (
        !pObj[permission.application][permission.module][permission.submodule]
      ) {
        pObj[permission.application][permission.module][
          permission.submodule
        ] = { permissions: [] };
      }
      pObj[permission.application][permission.module][
        permission.submodule
      ].permissions.push(permission.action);
    }
  });
  return pObj;
}

export function getApplicationState(permission) {
  if (!permission[0] || !permission[0].application) {
    return null;
  }
  return permission[0].application;
}

export function localStorageUtils(data) {
  /*
    data will get below params
    type: type of localstorage
    item: localstorage key name
    value: value needs to stored
  */
  if (data.type === LOCAL_STORAGE_STATE.SET) {
    localStorage.setItem(data.key, data.value);
  }

  if (data.type === LOCAL_STORAGE_STATE.GET) {
    return localStorage.getItem(data.key);
  }
}

export function cookieStorageUtils(data) {
  /*
    data will get below params
    path: path of cookie
    value: value needs to stored
    key: what key has to stored
  */
  // Cookies.set(data.key, data.value, { path: data.path });
}

/**
 * Get an element in an object.
 */
export function getElementFromObject(item, keystring, defaultValue) {
  if (!item) {
    return defaultValue || null;
  }

  const currentKey =
    keystring.substring(0, keystring.indexOf(".")) || keystring;
  if (keystring.length !== 0 && keystring.indexOf(".") !== -1) {
    keystring = keystring.replace(currentKey + ".", "");

    return getElementFromObject(item[currentKey], keystring, defaultValue);
  }

  return item[currentKey] || defaultValue || null;
}

export function getLoggedUserInfo(userInfoKeys) {
  /*
    Feature of the function:
    used to change the current logged in partner ID and current user partner localtion ID
    1. It will recieve input params as follows
    { 'tagNameInRedux': 'name to store and refer from redux : string',

    'tagHolderLocalStorage': 'key for the localstorage lookup : string',

    'parse': 'whether to parse the data or not : boolean'

    'storeLocalStorageData': 'to store the data in localstorage'

    'tagNameHolderPathLocalStorage': 'path where lookup should happen',

    'lookup': { 'lookupTagHolder': 'key for the localstorage lookup', 'parse': 'boolean'},
   }
  */

  let loggedUserInfo = {};
  for (let key in userInfoKeys) {
    let lookupValueFound = false,
      currentUserInfoKeyValue = null;

    // Get the data from localstorage
    function getLocalStorageData(keyName, parseValue, isAtob) {
      let localStorgeData = localStorageUtils({
        type: LOCAL_STORAGE_STATE.GET,
        key: keyName,
      });
      if (localStorgeData) {
        if (isAtob) {
          localStorgeData = atob(localStorgeData);
        }
        if (parseValue) {
          localStorgeData = JSON.parse(localStorgeData);
        }
      }
      return localStorgeData;
    }

    /*
      lookup generally tell to
      Check data already stored in the given path like localstorage, if yes use it or else fallback
    */
    if (userInfoKeys[key].lookup) {
      // if lookup exist, check in the lookup path and if its null then go for the below code
      // if value is found in lookup the dont execute in the below functionality
      currentUserInfoKeyValue = getLocalStorageData(
        userInfoKeys[key].lookup.localStorageTag,
        userInfoKeys[key].lookup.parse,
        false
      );
      if (currentUserInfoKeyValue) {
        lookupValueFound = true;
        loggedUserInfo[
          userInfoKeys[key].tagNameInRedux
        ] = currentUserInfoKeyValue;
        localStorageUtils({
          type: LOCAL_STORAGE_STATE.SET,
          key: userInfoKeys[key].tagNameInRedux,
          value: currentUserInfoKeyValue,
        });
      }
    }

    if (!lookupValueFound) {
      /*only for LOGGEDIN_PARTNER_ID and LOGGED_PARTNER_LOCATION_ID for now
        lookup does not exist in the above two keys currently
      */

      currentUserInfoKeyValue = getLocalStorageData(
        userInfoKeys[key].tagHolderLocalStorage,
        userInfoKeys[key].parse,
        true
      );
      const value = getElementFromObject(
        currentUserInfoKeyValue,
        userInfoKeys[key]["tagNameHolderPathLocalStorage"]
      );
      loggedUserInfo[userInfoKeys[key].tagNameInRedux] =
        currentUserInfoKeyValue && value
          ? value
          : (loggedUserInfo[USER_LOGGEDIN_INFO.INVALID_USER] = true);

      const loggedInParnterInfo = localStorageUtils({
        type: LOCAL_STORAGE_STATE.GET,
        key: userInfoKeys[key].tagNameInRedux,
      });
      /*
      if key is 'LOGGEDIN_PARTNER_ID' and 'LOGGED_PARTNER_LOCATION_ID
        Logged in partner ID and Logged in partner location ID will be replaced only once at login or if it is not found
      */

      if (!loggedInParnterInfo) {
        localStorageUtils({
          type: LOCAL_STORAGE_STATE.SET,
          key: userInfoKeys[key].tagNameInRedux,
          value,
        });
      }
    }
  }
  return loggedUserInfo;
}

/**
 * converts into titleCase of each word in the sentece.
 * @param str
 */
export function toTitleCase(str) {
  try {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  } catch (e) {
    console.error(e);
    return "";
  }
}

export function removeDuplicateLocations(locationsData, paramas) {
  const locations = [];
  const locationIds = [];
  try {
    locationsData.forEach((location) => {
      if (locationIds.indexOf(location.id) === -1) {
        location["rolesString"] =
          location.roles != null && location.roles.length > 0
            ? location.roles.join(", ")
            : "";
        location[
          paramas && paramas["nameWithRole"]
            ? paramas["nameWithRole"]
            : "nameWithRole"
        ] = `${location.name} (${toTitleCase(location.entityType)})`;
        locations.push(location);
        locationIds.push(location.id);
      }
    });
  } catch (e) {
    console.error(e);
  }
  return locations;
}

export function filterSelectOptions(input, option) {
  return (
    option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
}

export function compareObjectsByKeys(obj1, obj2) {
  if (!obj1 || !obj2) {
    return false;
  }
  return Object.keys(obj1).length === Object.keys(obj2).length;
}

export function checkApplicationType() {
  const val = localStorageUtils({
    key: USER_LOGGEDIN_INFO.APPLICATION,
    type: LOCAL_STORAGE_STATE.GET,
  });
  return atob(val);
}

export function expireCookie(name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
export function notifyApiError(message = "", title = "Error", onClose) {
  openNotification({
    message: title,
    description: message,
    className: "error-notification",
    onClose: onClose,
  });
}
export function notifyApiSuccess(message = "", title = "Api Success", onClose) {
  openNotification({
    message: title,
    description: message,
    className: "success-notification",
    onClose: onClose,
  });
}

// to destroy notification call destroyNotification()

export function reloadApp(payload = {}) {
  // return true
  window.location.reload();
}

export function windowRedirect(url) {
  // window.location = Constants.ENV_ANGULAR_REDIRECTION + url;
  window.location = Constants.ENV_REACT_REDIRECTION + url;

}
export function trimEachWaybill(data) {
  let trimedData = "";
  const groupedData = [];
  trimedData = data.trim();
  trimedData.split(",").map(function(waybill) {
    waybill = waybill.trim();
    waybill.split("\n").map(function(awb) {
      awb = awb.trim();
      awb.split(" ").map(function(item) {
        item = item.trim();
        if (item) {
          groupedData.push(item);
        }
      });
    });
  });
  return groupedData;
}

export function reportsDownload(url, reportType) {
  url = encodeURIComponent(url);
  return openNewTab(`/appv2/download?${reportType}=${url}`);
}

export function openNewTab(url) {
  const popUpWindow = window.open(url, "download_pop_up");
  if (
    !popUpWindow ||
    popUpWindow == null ||
    typeof popUpWindow === "undefined"
  ) {
    const errorMsg = `It seem\'s your browser popup blocker is enabled. Please disable popup blocker from your browser settings to continue download`;
    notifyApiError(errorMsg, "Permission Denied");

    return {
      statusCode: 500,
      statusMessage:
        "It seem's your browser popup blocker is enabled. " +
        "Please disable popup blocker from your browser settings to continue download",
    };
  } else {
    return { statusCode: 200, statusMessage: "Successfully opened new tab" };
  }
}

/**
 * to get all permissions
 */
export function getAllPersmission(isConfigValue) {
  let key = "permissions";
  if (isConfigValue) {
    key = "_configrations";
  }
  const permissions = localStorage.getItem(key);
  if (!permissions) {
    return null;
  }
  return JSON.parse(atob(permissions));
}

export function getPermissionSubmodule(module, subModule) {
  const config = {
    module: pModules[module].title,
    subModule: subModule,
  };
  return config;
}

function configurationCanAllow(_permissions, tag) {
  const permittedValue = _permissions[tag.subModule.configKey];

  if (
    permittedValue &&
    permittedValue.length &&
    permittedValue.indexOf(JSON.stringify(Constants.sessionUser.id)) > -1
  )
    return true;

  return false;
}

function permissionsCanAllow(_permissions, tags) {
  let _canAllow = false;
  const pApplication = _permissions[Constants.SELECTED_APPLICATION];
  if (!Array.isArray(tags)) {
    tags = [tags];
  }
  tags.map((tag) => {
    if (pApplication && pApplication?.[tag?.module]) {
      if (!tag.subModule) {
        if (!tag.action) {
          _canAllow = true;
        } else {
          _canAllow =
            pApplication[tag.module].permissions.indexOf(tag.action) !== -1;
        }
      } else {
        if (pApplication[tag.module][tag.subModule]) {
          if (!tag.action) {
            _canAllow = true;
          } else {
            _canAllow =
              pApplication[tag.module][tag.subModule].permissions.indexOf(
                tag.action
              ) !== -1;
          }
        }
      }
    }
  });
  return _canAllow;
}

export function canAllow(tags, isConfigValue = false) {
  const _permissions = getAllPersmission(isConfigValue);
  if (_permissions == null) {
    return false;
  }

  if (isConfigValue) {
    return configurationCanAllow(_permissions, tags);
  } else {
    return permissionsCanAllow(_permissions, tags);
  }
}

export function isPermitted(menuIndex, menuChildrenIndex) {
  return menuconfig[menuIndex]["children"][menuChildrenIndex]["canAllow"];
}

export function checkConfigurationsForPermission(configKey) {
  if (!localStorage.getItem("_configrations")) {
    return;
  }
  const localstorageConfigruation = JSON.parse(
    atob(localStorage.getItem("_configrations"))
  );
  if (localstorageConfigruation && localstorageConfigruation[configKey]) {
    return localstorageConfigruation[configKey];
  }
  return false;
}
export function updateValidators(fieldName, value, formValidators, cb) {
  formValidators[fieldName].errors = [];
  formValidators[fieldName].state = value;
  formValidators[fieldName].valid = true;
  formValidators[fieldName].rules.forEach((rule) => {
    if (rule.test instanceof RegExp) {
      if (!rule.test.test(value)) {
        formValidators[fieldName].errors.push(rule.message);
        formValidators[fieldName].valid = false;
      }
    } else if (typeof rule.test === "function") {
      if (!rule.test(value)) {
        formValidators[fieldName].errors.push(rule.message);
        formValidators[fieldName].valid = false;
      }
    }
  });
  if (cb && typeof cb === "function") {
    cb(isFormValid(formValidators));
  }
}

export function downloadFile(file_path, target = "_self") {
  if (!file_path) {
    return;
  }
  var a = document.createElement("A");
  a["href"] = file_path;
  a["download"] = file_path.substr(file_path.lastIndexOf("/") + 1);
  a["target"] = target;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function checkForDownloadReportStatus(props) {
  console.log("props", props);
  try {
    props.setS3SyncStatus(S3_REPORT_STATUS.BEGIN);
    const worker = new WebWorker(s3worker),
      counterLimit = Number(localStorage.getItem("s3ReportIntervalCounter"));
    worker.postMessage({
      invokeType: "report_s3_url",
      s3Url: props.uploadedFileResp.responseUrl,
      intervalTime: 10000,
      counterLimit: counterLimit ? counterLimit : 40,
    });

    worker.onmessage = (e) => {
      const message = e.data;
      if (message.type === "storeCounterValue") {
        localStorage.setItem("s3ReportIntervalCounter", message.value);
      } else if (message.type === "reportDownloadFailed") {
        localStorage.setItem("s3ReportIntervalCounter", "0");
        notifyApiError(
          "Report Failed to download, please retry again",
          "Report Failed"
        );
        props.setS3SyncStatus(S3_REPORT_STATUS.FAILURE);
        clearInterval();
        return false;
      } else if (message.type === "reportDownloadSuccess") {
        notifyApiSuccess("Report created successfully", "Report Success");
        props.setS3SyncStatus(S3_REPORT_STATUS.SUCCESS);
        downloadFile(message.link);
        localStorage.setItem("s3ReportIntervalCounter", "0");
        localStorage.removeItem("downloadReportInitiated");
        clearInterval();
        return true;
      }
    };
  } catch {
    notifyApiError(
      "Report request timed out. Please contact LoadShare support",
      "Report Failed"
    );
    props.setS3SyncStatus(S3_REPORT_STATUS.FAILURE);
  }
}

export function isFormValid(formValidators) {
  let status = true;
  Object.keys(formValidators).forEach((fieldName) => {
    if (!formValidators[fieldName].valid) {
      status = false;
    }
  });
  return status;
}

export function getLongValue(day, hour, minute) {
  return moment()
    .add(day, "days")
    .hours(hour)
    .minutes(minute)
    .seconds(minute)
    .milliseconds(minute)
    .valueOf();
}

export const generateModelType = (parentProps) => {
  let modelType = "";
  if (Number(parentProps.loggedInUser?.partner?.id) === Number(268)) {
    modelType = "lsnTableModel";
  } else if (
    USER_LEVEL_HIGER_PARTNERS.indexOf(parentProps.loggedInUser?.userLevel) > -1
  ) {
    modelType = "partnerTableModel";
  } else if (parentProps.loggedInUser?.userLevel === USER_LEVEL.BRANCH) {
    modelType = "branchTableModel";
  }
  return modelType;
};

export const codTransactionModes = () => {
  const defaultData = [
    { name: "Cash Deposit", value: "CD" },
    { name: "Cheque", value: "CHEQUE" },
    { name: "Demand Draft(DD)", value: "DD" },
    { name: "Electronic Transfer", value: "ET" },
    { name: "Cash Management Service(CMS)", value: "CMS" },
  ];

  if (!localStorage.getItem("_configrations")) {
    return defaultData;
  }

  const config = JSON.parse(atob(localStorage.getItem("_configrations")));

  if (config && config["codTransactionModes"]) {
    return config["codTransactionModes"];
  } else {
    return defaultData;
  }
};

export const codTimeLine = (param = []) => {
  const timeLine = {};

  if (param.indexOf("T") !== -1) {
    timeLine["T"] = {
      from: getLongValue(0, 0, 0),
      to: getLongValue(0, 23, 59),
    };
  }

  if (param.indexOf("T-1") !== -1) {
    timeLine["T-1"] = {
      from: getLongValue(-1, 0, 0),
      to: getLongValue(-1, 23, 59),
    };
  }

  if (param.indexOf("T-2") !== -1) {
    timeLine["T-2"] = {
      from: getLongValue(-2, 0, 0),
      to: getLongValue(-2, 23, 59),
    };
  }

  if (param.indexOf("T-3") !== -1) {
    timeLine["T-3"] = {
      from: getLongValue(-3, 0, 0),
      to: getLongValue(-3, 23, 59),
    };
  }

  if (param.indexOf(">T-2") !== -1) {
    timeLine[">T-2"] = {
      from: null,
      to: getLongValue(-3, 23, 59),
    };
  }

  if (param.indexOf("<T-3") !== -1) {
    timeLine["<T-3"] = {
      from: null,
      to: getLongValue(-3, 23, 59),
    };
  }

  if (param.indexOf("NET_OUTSTANDING") !== -1) {
    timeLine["NET_OUTSTANDING"] = {
      from: null,
      to: getLongValue(-1, 23, 59),
    };
  }

  if (param.indexOf("PENDING_FOR_APPROVAL") !== -1) {
    timeLine["PENDING_FOR_APPROVAL"] = {
      from: null,
      to: getLongValue(1, 23, 59),
      type: "REQUEST_FOR_APPROVAL",
    };
  }

  return timeLine;
};

export const setUserRoles = (remittanceType, userLevel, userLevels) => {
  // current logic
  // *if remittance == BRANHC_CUSTOMER
  //
  // and if Branch
  // show PARTNER_CUSTOMER && CUSTOMER AND !show  HO
  //
  // and if HO
  // hide remittance button
  //
  // *if remittance == PARNTER_CUSTOMER
  //
  // BRANCH
  // show HO
  //
  // HO LOGIN
  // show PARTNER_CUSTOMER AND CUSTOMER
  //
  // LSN
  // show CUSTOMER
  const roles = [];
  let rolesHashMap = {};
  let remitDeposit = true;
  if (remittanceType === "BRANCH_CUSTOMER") {
    if (userLevel === userLevels.BRANCH) {
      roles.push(
        { label: "CUSTOMER", value: "Customer" },
        { label: "PARTNER_CUSTOMER", value: "Partner Customer" }
      );
      rolesHashMap = {
        CUSTOMER: "CUSTOMER",
        PARTNER_CUSTOMER: "PARTNER_CUSTOMER",
      };
    } else {
      remitDeposit = false;
    }
  } else if (remittanceType === "PARTNER_CUSTOMER") {
    // IF for HO we wont show, after this
    if (userLevel === userLevels.BRANCH) {
      roles.push({ label: "HO", value: "HO" });
      rolesHashMap = {
        HO: "HO",
      };
    } else if (
      userLevel === userLevels.ADMIN ||
      userLevel === userLevels.PARTNER_HUB
    ) {
      roles.push(
        { label: "CUSTOMER", value: "Customer" },
        { label: "PARTNER_CUSTOMER", value: "Partner Customer" }
      );
      rolesHashMap = {
        CUSTOMER: "CUSTOMER",
        PARTNER_CUSTOMER: "PARTNER_CUSTOMER",
      };
    } else if (userLevel === "LSN") {
      roles.push({ label: "CUSTOMER", value: "Customer" });
      rolesHashMap = {
        CUSTOMER: "CUSTOMER",
      };
    }
  }
  return { roles, remitDeposit, rolesHashMap };
};
/**
 *
 *
 * @export
 * @param {*} config = {body,head,isPrint = false}
 */
export function openWindowWithData(config) {
  if (config.head == null)
    config.head = "<style> body {font-family: 'Roboto', sans-serif;} </style>";
  let win = window.open();
  win.document.head.innerHTML += config.head;
  win.document.body.innerHTML = config.body;
  if (config?.isPrint)
    setTimeout(() => {
      win.print();
      win.close();
    }, 20);
}

/**
 *
 * @exports
 * @param item = object to get value from
 * @param keystring = keystring to find with in item
 * @param defaultValue = if key not found in item then this value will return
 */
export function dataGet(item, keystring, defaultValue = null) {
  if (!item) {
    return defaultValue || null;
  }
  const currentKey =
    keystring.substring(0, keystring.indexOf(".")) || keystring;
  if (keystring.length !== 0 && keystring.indexOf(".") !== -1) {
    keystring = keystring.replace(currentKey + ".", "");

    return dataGet(item[currentKey], keystring, defaultValue);
  }

  return item[currentKey] || defaultValue || null;
}

export function storePaginatedData(PageNo, OldPages, NewPages, isReset) {
  if (!OldPages || !NewPages) {
    return OldPages;
  }
  if (isReset) {
    OldPages = {};
  }
  if (!OldPages[PageNo]) {
    OldPages[PageNo] = NewPages;
  }
  return OldPages;
}

export function seperateValueByDot(strArr) {
  let temp = [];
  temp.push(
    strArr.map((str) => {
      const arr = str.split(".");
      return arr;
    })
  );
  return temp;
}

export function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
  var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
  var CSV = "";
  CSV += ReportTitle + "\r\n\n";
  if (ShowLabel) {
    var row = "";
    for (var index in arrData[0]) {
      row += index + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  }
  for (var i = 0; i < arrData.length; i++) {
    var row = "";
    for (var index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }
    row.slice(0, row.length - 1);
    CSV += row + "\r\n";
  }
  if (CSV == "") {
    alert("Invalid data");
    return;
  }
  var fileName = "MyReport_";
  fileName += ReportTitle.replace(/ /g, "_");
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);
  var link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function capitalizeString(string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function downloadJsonData(data, fileName) {
  const dataStr =
    "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
  this.downloadData(dataStr, fileName);
}

export function downloadData(dataStr, fileName) {
  const dlAnchorElem = document.createElement("a");
  dlAnchorElem.setAttribute("href", dataStr);
  dlAnchorElem.setAttribute("download", fileName);
  dlAnchorElem.click();
}

export function getLocationData(pincodeObj) {
  if (!pincodeObj) {
    return null;
  }
  let location;
  location = dataGet(pincodeObj, "city.cityCode")
    ? pincodeObj["city"]["cityCode"] + " - "
    : "";
  location = location + dataGet(pincodeObj, "zipcode", "");
  return location;
}

export function isScanedOrKeyboardEntry(inputlen) {
  const inputEntryTimeListLength = inputlen.length;
  if (
    inputEntryTimeListLength &&
    inputlen.reduce((a, b) => a + b, 0) / inputEntryTimeListLength < 80
  ) {
    return true;
  }
  return false;
}

export function trimByConfig(str, config) {
  if (!str) {
    return "";
  }
  if (!config || config["applicable"] !== "true" || str.length < 20) {
    return str;
  }
  let from = parseInt(config["trimParams"]["pointer"], 0);
  const length = parseInt(config["trimParams"]["length"], 0);
  if (str.length < length) {
    return str;
  }
  if (from === -1) {
    from = str.length - length;
  }
  return str.substr(from, length);
}

function alterDescriptionName(data) {
  let name = data;

  switch (data) {
    case "ELIGIBLE_FOR_RTO":
      name = "ELIGIBLE FOR RTO";
      break;
    case "ELIGIBLE_FOR_RTS":
      name = "ELIGIBLE FOR RTS";
      break;
    case "UNEXPECTED_FORWARD":
      name = "UNEXPECTED FORWARD";
      break;
    case "CPD_CROSSED":
      name = "CPD Crossed";
      break;

    case "SUSPICIOUS_UNDEL":
      name = "SUSPICIOUS UNDEL";
      break;

    case "ESCALATED_CRITICAL":
      name = "ESCALATED";
      break;

    case "CPD_TOMORROW_CRITICAL":
      name = "CPD TOMORROW";
      break;

    case "CPD_BREEACHED_CRITICAL":
      name = "CPD BREACHED";
      break;

    case "CPD_TOMORROW":
      name = "CPD TOMORROW";
      break;

    case "REQUEST_FOR_RESCHEDULED_TODAY":
      name = "REQUEST FOR RESCHEDULED TODAY";
      break;

    case "REQUEST_FOR_RESCHEDULED_BREACHED":
      name = "REQUEST FOR RESCHEDULED BREACHED";
      break;

    case "REQUEST_FOR_RESCHEDULED_TOMMORROW":
      name = "REQUEST FOR RESCHEDULED TOMMORROW";
      break;

    case "NORMAL":
      name = "NORMAL";
      break;

    case "RTO_RETURN_TO_ORIGIN":
      name = "RTO";
      break;

    default:
      break;
  }
  return name;
}

export function scanResponseDataMap(scanResponseData) {
  const shipmentDays = Math.round(
    moment
      .duration(
        moment().diff(
          moment(new Date(scanResponseData.createdAt), "YYYY-MM-DD")
        )
      )
      .asDays()
  );
  return {
    locationCode: {
      key: "Pincode",
      value: scanResponseData.locationCode,
    },
    waybillNo: {
      key: "Waybill No",
      value: scanResponseData.waybillNo,
    },
    shipmentValue: {
      key: "Shipment Value",
      value: scanResponseData.shipmentValue,
    },
    cpd: {
      key: "CPD Date",
      value: scanResponseData.cpd,
    },
    shippmentDay: {
      key: "Age of Shipment",
      value: shipmentDays > 10 ? "> 10" : shipmentDays,
    },
    rescheduleDate: {
      key: "Rescheduled For",
      value: scanResponseData.rescheduleDate,
    },
    primaryBucket: {
      key: "Bucket",
      value: scanResponseData.bucket
        ? alterDescriptionName(scanResponseData.bucket.primaryBucket)
        : null,
      primaryBucketPriority: scanResponseData.bucket
        ? scanResponseData.bucket.primaryBucketPriority
        : null,
    },
    secondaryBucket: {
      key: "&nbsp;",
      value: scanResponseData.bucket
        ? alterDescriptionName(scanResponseData.bucket.secondaryBucket)
        : null,
      secondaryBucketPriority: scanResponseData.bucket
        ? scanResponseData.bucket.secondaryBucketPriority
        : null,
    },
    tertiaryBucket: {
      key: "&nbsp;",
      value: scanResponseData.bucket
        ? alterDescriptionName(scanResponseData.bucket.tertiaryBucket)
        : null,
      tertiaryBucketPriority: scanResponseData.bucket
        ? scanResponseData.bucket.tertiaryBucketPriority
        : null,
    },
    category: {
      key: "Category Value",
      value: scanResponseData.bucket ? scanResponseData.bucket.category : null,
    },
    message: {
      key: "Message",
      value: scanResponseData.bucket ? scanResponseData.bucket.message : null,
    },
    scanType: {
      key: "Scan Type",
      value: scanResponseData.scanType,
    },
    scanFormatType: {
      key: "Scan Format Type",
      value: scanResponseData.scanFormatType,
    },
    lastPodConStatusWithReason: {
      key: "Status",
      value: scanResponseData.consignmentStatusWithLastReason,
    },
    shipmentValueMsg: {
      key: "Shipment Value",
      value: scanResponseData.shipmentValueMsg,
    },
    isEbillRequired: {
      key: "Ebill Required?",
      value: scanResponseData.isEbillRequired,
    },
    shipperName: {
      key: "Shipper Name",
      value: scanResponseData.shipperName,
    },
    consigneeAddress: {
      key: "Address",
      value: scanResponseData.consigneeAddress,
    },
    isSuspiciousPod: {
      key: "Is Suspicious Pod",
      value: scanResponseData.bucket
        ? scanResponseData.bucket.isSuspiciousPod
        : null,
    },
    attemptCount: {
      key: "Attempt Count",
      value: scanResponseData.bucket
        ? scanResponseData.attemptCount.toString()
        : null,
    },
    lastAttemptDate: {
      key: "Last Attempt Date",
      value: scanResponseData.bucket ? scanResponseData.lastAttemptDate : null,
    },
  };
}
export function isEmpty(obj) {
  if (typeof obj === "number" || typeof obj === "boolean") return false;
  if (obj == null) return true;
  if (obj instanceof Date) {
    return isNaN(obj);
  }
  if (obj instanceof Set) {
    return obj.size === 0;
  }
  //if (typeof obj[index] === "undefined" && obj[index] === null) return true;
  if (obj.length === 0) return true;
  if (obj.length > 0) return false;
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }
  return true;
}
export function getOrgTimeZone() {
  try {
    return moment.tz.guess();
  } catch (error) {
    return moment.tz.guess();
  }
}
export function newMoment(dateLike, format) {
  var timeZone = getOrgTimeZone();
  return moment(dateLike, format).tz(timeZone);
}
export function newDateMoments(dateLike, format) {
  return newMoment(dateLike, format).toDate();
}
export function getTimeDifference(date1, date2, timeFormat) {
  var timeDifference = Math.abs(newDateMoments(date1) - newDateMoments(date2));
  if (timeFormat == "seconds" || timeFormat == "s") {
    return timeDifference / 1000;
  } else if (timeFormat == "minutes" || timeFormat == "m") {
    return timeDifference / (1000 * 60);
  } else if (timeFormat == "hours" || timeFormat == "h") {
    return timeDifference / (1000 * 60 * 60);
  } else {
    return timeDifference;
  }
}
export function getTimeAsDuration(time, format, returnFormat) {
  var formatMapper = {
    small: {
      days: "d",
      hours: "h",
      mins: "m",
    },
    long: {
      days: "days",
      hours: "hours",
      mins: "mins",
    },
  };

  time = Math.abs(time);
  format = format || "long";
  /**
   * Need isEmpty since 0 could be ignored when just using !
   **/
  if (isEmpty(time)) {
    return "";
  }
  if (time <= 0) {
    return "<1" + formatMapper[format]["mins"];
  }

  var days = Math.floor(time / (60 * 60 * 24 * 1000));
  var hours = Math.floor((time % (60 * 60 * 24 * 1000)) / (60 * 60 * 1000));
  var mins = Math.floor((time % (60 * 60 * 1000)) / (60 * 1000));

  var retStr = "";
  if (!isEmpty(returnFormat) && returnFormat == "dh") {
    if ((isEmpty(days) || days <= 0) && (isEmpty(hours) || hours <= 0)) {
      retStr = mins ? mins + " " + formatMapper[format]["mins"] + " " : "";
    } else {
      retStr =
        (days ? days + " " + formatMapper[format]["days"] + " " : "") +
        (hours ? hours + " " + formatMapper[format]["hours"] + " " : "");
    }
  } else {
    retStr =
      (days ? days + " " + formatMapper[format]["days"] + " " : "") +
      (hours ? hours + " " + formatMapper[format]["hours"] + " " : "") +
      (mins ? mins + " " + formatMapper[format]["mins"] + " " : "");
  }
  if (isEmpty(retStr)) {
    return "<1" + formatMapper[format]["mins"];
  } else {
    return retStr;
  }
}
export function vehicleTemp(obj) {
  if (
    !isEmpty(obj) &&
    !isEmpty(obj.temperature) &&
    obj.temperature != "3000" &&
    obj.temperature != "85" &&
    obj.temperature != "200"
  ) {
    return obj.temperature + " Celsius";
  } else {
    return "N/A";
  }
}
export function findArrayObjectIndex(key, value, array, path, dataType) {
  if (!isEmpty(array)) {
    for (var arrayIndex in array) {
      var a = array[arrayIndex];
      if (path) {
        for (var i = 0; i < path.length; i++) {
          if (a && typeof a == "object") {
            a = a[path[i]];
          }
        }
      }
      if (dataType == "date") {
        console.log("(new Date(a[key])).getTime()", new Date(a[key]).getTime());
        console.log("(new Date(value)).getTime()", new Date(value).getTime());
        if (
          a &&
          typeof a == "object" &&
          new Date(a[key]).getTime() == new Date(value).getTime()
        )
          return arrayIndex;
      } else {
        if (a && typeof a == "object" && a[key] == value) return arrayIndex;
      }
    }
  }
}

export function arrangeWaybill(data) {
  let trimedData = "";
  const groupedData = [];
  trimedData = data.trim();
  trimedData.split(",").map(function(waybill) {
    waybill = waybill.trim();
    waybill.split("\n").map(function(awb) {
      awb = awb.trim();
      awb.split(" ").map(function(item) {
        item = item.trim();
        if (item) {
          groupedData.push(item);
        }
      });
    });
  });
  return groupedData;
}

export function checkHttpStatus(status) {
  switch (status?.code) {
    case 403:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 400:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 429:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 401:
      notifyApiError(status?.message, "ERROR:");
      AuthenticationService.logoutUser();
      return false;
    case 500:
    case 503:
      notifyApiError(status?.message, "ERROR:");
      return true;
    case 200:
    case 201:
    case 206:
    case 202:
    // eslint-disable-next-line no-duplicate-case
    case 206:
      return true;
    default:
      return false;
  }
}

export function checkConsignments(drs, type) {
  let drsStatus = "CLOSED";
  let delCount = 0;
  let consignmentsCount = drs.consignments ? drs.consignments.length : 0;
  let shipmentsCount = 0;
  let drsPodAllowed = false;
  if (drs.consignments == null || drs.consignments.length === 0) {
    drsStatus = "OPEN";
  } else if (drs.drsStatus === "IN_TRANSIT") {
    drsStatus = "IN_TRANSIT";
  } else if (drs.drsStatus !== "CLOSED") {
    drsStatus = "COMPLETED";
  }
  if (drs.consignments) {
    consignmentsCount = drs.consignments.length;
    drs.consignments.forEach((waybill) => {
      shipmentsCount += waybill.totalShipmentCount;
      let podMapKey = "DRS_" + drs.id;
      let shipmentStatus = "DEL";
      if (type && type === "RTO") {
        podMapKey = type + "_" + podMapKey;
        shipmentStatus = type + "DEL";
      } else if (type && type === "RTS") {
        podMapKey = type + "_" + podMapKey;
        shipmentStatus = type + "DEL";
      }
      if (
        waybill &&
        waybill.consignmentPODBOMap &&
        waybill.consignmentPODBOMap[podMapKey]
      ) {
        if (
          waybill.consignmentPODBOMap[podMapKey]["shipmentStatus"] ===
          shipmentStatus
        ) {
          delCount++;
        }
      } else {
        drsStatus = "PENDING";
        drsPodAllowed = true;
      }
    });
  }
  return {
    status: drsStatus,
    delCount: delCount,
    consignmentsCount: consignmentsCount,
    shipmentsCount: shipmentsCount,
    totalUnDel: drs.totalUnDel,
    totalDel: drs.totalDel,
    // statusColor: StatusColors[drsStatus.toLowerCase()],
    drsPodAllowed: drsPodAllowed,
  };
}

export function getRouteName(flowType, page, pageType, id = 0) {
  let route;
  if (Array.isArray(flowType)) {
    if (flowType.indexOf(FlowTypes.RTO) > -1) {
      route = `/rto/${pageType}/${page}`;
    }
    if (route && route.length > 0) {
      route = id ? `${route}/${id}` : route;
      return route;
    }
  }
  switch (flowType) {
    case FlowTypes.RTO: {
      route = `/rto/${pageType}/${page}`;
      break;
    }
    case FlowTypes.RTS: {
      route = `/rts/${pageType}/${page}`;
      break;
    }
    default: {
      route = `/inventory/${pageType}/${page}`;
    }
  }
  route = id ? `${route}/${id}` : route;
  return route;
}

export function validateSyncData(data) {
  let allSyncStatus = true;
  let reason = "";
  data.forEach((element) => {
    if (element.sync === false) {
      allSyncStatus = false;
      if (element.reason) {
        reason = `${reason}${element.reason} \n`;
      }
    }
  });
  if (allSyncStatus) {
    notifyApiSuccess("", "Success");
  } else {
    notifyApiError(reason, "Error");
  }
  return allSyncStatus;
}

export function getCodeParam(flowType, type) {
  let code;
  switch (flowType) {
    case FlowTypes.RTO: {
      code = GenerateCodes[`${flowType}_${type}`];
      break;
    }
    case FlowTypes.RTS: {
      code = GenerateCodes[`${flowType}_${type}`];
      break;
    }
    default: {
      code = GenerateCodes[`${type}`];
    }
  }
  return code;
}

export function checkAPIHttpStatus(status) {
  switch (status?.code) {
    case 403:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 401:
      notifyApiError(status?.message, "ERROR:");
      // window.location.href = '/';
      return false;
    case 500:
    case 503:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 200:
    case 201:
    case 202:
      return true;
  }
}
