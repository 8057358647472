import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { Constants } from '../../../common/utils/constants';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';


const partnerId = localStorage.getItem('loggedPartnerId');


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getConfig = () => dispatch => {
    dispatch(getConfigBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/config/get`       
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(getConfigSuccess(response.data))
        })
        .catch(error => dispatch(getConfigFailure(error)));
}

function getConfigBegin() {
    return {
        type: CONSTANTS.FETCH_CUSTOM_CONFIG_BEGIN,
        payload: null
    }
}

function getConfigSuccess(response) {
    return {
        type: CONSTANTS.FETCH_CUSTOM_CONFIG_SUCCESS,
        payload: response
    }
}

function getConfigFailure(response) {
    return {
        type: CONSTANTS.FETCH_CUSTOM_CONFIG_FAILURE,
        payload: response
    }
}


export const updateConfig = (params) => dispatch => {
    dispatch(updateConfigBegin());
    apiUtils.apiRequest({
        url: `/b2b/v1/config/update`,
        method: 'PUT',
        data: params
    })
        .then(response => {
            if (checkHttpStatus(response.data?.status))
                dispatch(updateConfigSuccess(response.data))
        })
        .catch(error => dispatch(updateConfigFailure(error)));
}

function updateConfigBegin() {
    return {
        type: CONSTANTS.UPDATE_CUSTOM_CONFIG_BEGIN,
        payload: null
    }
}

function updateConfigSuccess(response) {
    return {
        type: CONSTANTS.UPDATE_CUSTOM_CONFIG_SUCCESS,
        payload: response
    }
}

function updateConfigFailure(response) {
    return {
        type: CONSTANTS.UPDATE_CUSTOM_CONFIG_FAILURE,
        payload: response
    }
}

export const resetUpdatedData = () => dispatch => {
    dispatch({
        type: CONSTANTS.RESET_UPDATED_DATA
    })
}