import * as _Constants from "./constants";
import { storePaginatedData } from "../../../common/utils/utils";

export const FleetReducer = (state = _Constants.initialState, action) => {
  switch (action.type) {
    case _Constants.GET_CITY_LIST_BEGIN:
      return fetchCityListBegin(state);
    case _Constants.GET_CITY_LIST_SUCCESS:
      return fetchCityListSuccess(state, action);
    case _Constants.GET_CITY_LIST_FAILURE:
      return fetchCityListFailure(state, action);
    case _Constants.GET_CITY_LIST_ID_BEGIN:
      return fetchCityByIdListBegin(state);
    case _Constants.GET_CITY_LIST_ID_SUCCESS:
      return fetchCityByIdListSuccess(state, action);
    case _Constants.GET_CITY_LIST_ID_FAILURE:
      return fetchCityByIdListFailure(state, action);
    case _Constants.GET_USERDATA_BEGIN:
      return userDataPageBegin(state);
    case _Constants.GET_USERDATA_SUCCESS:
      return userDataPageSuccess(state, action);
    case _Constants.GET_USERDATA_FAILURE:
      return userDataPageFailure(state, action);
    case _Constants.GET_ADD_PERMISSION_BEGIN:
      return userDataPageBegin(state);
    case _Constants.GET_ADD_PERMISSION_SUCCESS:
      return userDataPageSuccess(state, action);
    case _Constants.GET_ADD_PERMISSION_FAILURE:
      return userDataPageFailure(state, action);
    case _Constants.ZONE_OPTIONS_FETCH_BEGIN:
      return zonePageBegin(state);
    case _Constants.ZONE_OPTIONS_FETCH_SUCCESS:
      return zonePageSuccess(state, action);
    case _Constants.ZONE_OPTIONS_FETCH_FAILURE:
      return zoneFailure(state, action);
    case _Constants.HYPERLOCAL_ZONE_OPTIONS_FETCH_BEGIN:
      return hyperlocalZoneBegin(state);
    case _Constants.HYPERLOCAL_ZONE_OPTIONS_FETCH_SUCCESS:
      return hyperlocalZoneSuccess(state, action);
    case _Constants.HYPERLOCAL_ZONE_OPTIONS_FETCH_FAILURE:
      return hyperlocalZoneFailure(state, action);
    case _Constants.HYPERLOCAL_ELIGIBLE_ZONE_BEGIN:
      return eligibleZonesBegin(state);
    case _Constants.HYPERLOCAL_ELIGIBLE_ZONE_SUCCESS:
      return eligibleZonesSuccess(state, action);
    case _Constants.HYPERLOCAL_ELIGIBLE_ZONE_FAILURE:
      return eligibleZonesFailure(state, action);
    case _Constants.HYPERLOCAL_BOX_ZONE_BEGIN:
      return boxZonesBegin(state);
    case _Constants.HYPERLOCAL_BOX_ZONE_SUCCESS:
      return boxZonesSuccess(state, action);
    case _Constants.HYPERLOCAL_BOX_ZONE_FAILURE:
      return boxZonesFailure(state, action);
    case _Constants.HYPERLOCAL_BOX_CLIENT_BEGIN:
      return boxClientsBegin(state);
    case _Constants.HYPERLOCAL_BOX_CLIENT_SUCCESS:
      return boxClientsSuccess(state, action);
    case _Constants.HYPERLOCAL_BOX_CLIENT_FAILURE:
      return boxClientsFailure(state, action);
    case _Constants.HYPERLOCAL_GEOFENCE_BEGIN:
      return geoFenceBegin(state);
    case _Constants.HYPERLOCAL_GEOFENCE_SUCCESS:
      return geoFenceSuccess(state, action);
    case _Constants.HYPERLOCAL_GEOFENCE_FAILURE:
      return geoFenceFailure(state, action);
    case _Constants.HYPERLOCAL_GETALL_FE_BEGIN:
      return allFeBegin(state);
    case _Constants.HYPERLOCAL_GETALL_FE_SUCCESS:
      return allFeSuccess(state, action);
    case _Constants.HYPERLOCAL_GETALL_FE_FAILURE:
      return allFeFailure(state, action);
    case _Constants.HYPERLOCAL_GETORDER_BEGIN:
      return getOrdersBegin(state);
    case _Constants.HYPERLOCAL_GETORDER_SUCCESS:
      return getOrderSuccess(state, action);
    case _Constants.HYPERLOCAL_GETORDER_FAILURE:
      return getOrderFailure(state, action);
    case _Constants.HYPERLOCAL_GETALL_COUNT_BEGIN:
      return getAllCountBegin(state);
    case _Constants.HYPERLOCAL_GETALL_COUNT_SUCCESS:
      return getAllCountSuccess(state, action);
    case _Constants.HYPERLOCAL_GETALL_COUNT_FAILURE:
      return getAllCountFailure(state, action);
    case _Constants.HYPERLOCAL_RIDER_DETAILS_BEGIN:
      return getRiderDetailsBegin(state);
    case _Constants.HYPERLOCAL_RIDER_DETAILS_SUCCESS:
      return getRiderDetailsSuccess(state, action);
    case _Constants.HYPERLOCAL_RIDER_DETAILS_FAILURE:
      return getRiderDetailsFailure(state, action);
    case _Constants.GET_FETCH_TEMPLATE_NOTIFY_BEGIN:
      return templatesBegin(state);
    case _Constants.GET_FETCH_TEMPLATE_NOTIFY_SUCCESS:
      return templatesSuccess(state, action);
    case _Constants.GET_FETCH_TEMPLATE_NOTIFY_FAILURE:
      return templatesFailure(state, action);
    case _Constants.GET_CUSTOMER_LIST_BEGIN:
      return fetchCustomerListBegin(state);
    case _Constants.GET_CUSTOMER_LIST_SUCCESS:
      return fetchCustomerListSuccess(state, action);
    case _Constants.GET_CUSTOMER_LIST_FAILURE:
      return fetchCustomerListFailure(state, action);
    case _Constants.GET_STAFFDATA_BEGIN:
      return staffDataPageBegin(state);
    case _Constants.GET_STAFFDATA_SUCCESS:
      return staffDataPageSuccess(state, action);
    case _Constants.GET_STAFFDATA_FAILURE:
      return staffDataPageFailure(state, action);
    case _Constants.RESET_STAFFDATA:
      return resetStaffData(state, action);
    default:
      return state;
  }
};

function fetchCustomerListBegin(state, action) {
  return {
    ...state,
  };
}

function fetchCustomerListSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function fetchCustomerListFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function boxClientsBegin(state, action) {
  return {
    ...state,
  };
}

function boxClientsSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function boxClientsFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function boxZonesBegin(state, action) {
  return {
    ...state,
  };
}

function boxZonesSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function boxZonesFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function eligibleZonesBegin(state, action) {
  return {
    ...state,
  };
}

function eligibleZonesSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function eligibleZonesFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function templatesBegin(state, action) {
  return {
    ...state,
  };
}

function templatesSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function templatesFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function getRiderDetailsBegin(state, action) {
  return {
    ...state,
  };
}

function getRiderDetailsSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function getRiderDetailsFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function getAllCountBegin(state, action) {
  return {
    ...state,
  };
}

function getAllCountSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function getAllCountFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function getOrdersBegin(state, action) {
  return {
    ...state,
  };
}

function getOrderSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function getOrderFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function allFeBegin(state, action) {
  return {
    ...state,
  };
}

function allFeSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function allFeFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function geoFenceBegin(state, action) {
  return {
    ...state,
  };
}

function geoFenceSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function geoFenceFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function hyperlocalZoneBegin(state, action) {
  return {
    ...state,
  };
}

function hyperlocalZoneSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function hyperlocalZoneFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function zonePageBegin(state, action) {
  return {
    ...state,
  };
}

function zonePageSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function zoneFailure(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function fetchCityListBegin(state, action) {
  return {
    ...state,
  };
}

function fetchCityListSuccess(state, action) {
  return {
    ...state,
    cityList: { ...action.payload },
  };
}

function fetchCityListFailure(state, action) {
  return {
    ...state,
    error: action.payload,
    cityList: {},
  };
}

function fetchCityByIdListBegin(state, action) {
  return {
    ...state,
  };
}

function fetchCityByIdListSuccess(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function fetchCityByIdListFailure(state, action) {
  return {
    ...state,
    error: action.payload,
    locations: [],
  };
}

function userDataPageBegin(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function userDataPageSuccess(state, action) {
  return {
    ...state,
    loading: false,
    ...action.payload,
  };
}

function userDataPageFailure(state, action) {
  return {
    ...state,
    loading: false,
    error: action.payload,
  };
}

function staffDataPageBegin(state, action) {
  return {
    ...state,
    loading: true,
  };
}

function staffDataPageSuccess(state, action) {
  return {
    ...state,
    loading: false,
    staffStatus: { ...action.payload },
  };
}

function staffDataPageFailure(state, action) {
  return {
    ...state,
    loading: false,
    staffStatusError: { ...action.payload },
  };
}

function resetStaffData(state, action) {
  return {
    ...state,
    loading: false,
    staffStatus: {},
  };
}
