import React from 'react';
import './waybill-link.scss';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { openWaybillModal } from '../../shared/header/action';


const WaybillLink = (props) => {
  return (
    <span onClick={() => props.toggleWaybillModal(props.children, true)} className="waybill-link">{props.children}</span>
  )
}


const mapStateToProps = (state, ownProps) => ({
  isWaybillModalOpen: state.header.isWaybillModalOpen,
  waybillInput: state.header.waybill
});
const mapDispatchToProps = dispatch => ({
  toggleWaybillModal: (waybill, isOpen = false) => dispatch(openWaybillModal(waybill, isOpen))
});
export default connect(mapStateToProps, mapDispatchToProps)(WaybillLink);