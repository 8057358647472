import React, { Component } from 'react'
import { table as Table } from "../../ui/table/Table";
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Tooltip, Select, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { permissionTags } from '../../../configs/permissionTags';
import './payments.scss';
import { windowRedirect, canAllow, checkHttpStatus } from '../../../common/utils/utils';
import PaymentStatusService from '../../../services/digital-payment/payment-status.service';
// import { connect } from 'react-redux';
const { MonthPicker, RangePicker } = DatePicker;
const paymentService = new PaymentStatusService;
const dateFormat = 'YYYY/MM/DD';
const transactionStatusValues = [
    { label: 'Initiated', value: 'INITIATED' },
    { label: 'Success', value: 'SUCCESS' },
    { label: 'Authorized', value: 'AUTHORIZED'},
    { label: 'Failed', value: 'FAILED'},
  ];

class PaymentStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterVisible: false,
            waybills: [],
            checkedList : [],
            indeterminate: true,
            noDate : false,
            novalidDate : false,
            transactionFrom : moment(moment().subtract(29, 'days')),
            transactionTo : moment(moment()),
            columns: [
                {
                    title: 'Waybill Number',
                    dataIndex: 'waybillNo',
                    key: 'waybillNo',
                },
                {
                    title: 'Internal Transaction Id ',
                    dataIndex: 'paymentReferenceNumber',
                    key: 'paymentReferenceNumber',
                },
                {
                    title: 'Txn Amt',
                    dataIndex: 'amount',
                    key: 'amount',
                },
                {
                    title: 'Payment Type',
                    dataIndex: 'paymentType',
                    key: 'paymentType',
                },
                {
                    title: 'Transaction Status',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: 'Transaction Message',
                    dataIndex: 'errorMessage',
                    key: 'errorMessage',
                    render: data => data || 'NA'
                },
                {
                    title: 'FE Name',
                    dataIndex: 'userBO',
                    key: 'userBO',
                    render: data => data?.name || 'NA'
                },
                {
                    title: 'Transaction Time',
                    dataIndex: 'createdAt',
                    key: 'createdAt',
                    render: data => data ? (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>) : 'NA'
                },
                {
                    title: 'Updated Time',
                    dataIndex: 'updatedAt',
                    key: 'updatedAt',
                    render: data => data ? (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small> </div>) : 'NA'
                },
            ],
            paymentData: [],
            loading: false,
            paymentStatusPermission: {
                read: canAllow(permissionTags.digital_payment.payment_status.read),
            },
        }
        if (!this.state.paymentStatusPermission.read) {
            windowRedirect('digital-payment/dashboard/');
        }
    }

    fetchPaymentData = (payload = {}) => {
        this.setState({
            loading: true
        }, () => {
            paymentService.getPayments(payload).then(({ data }) => {
                if (checkHttpStatus(data.status)) {
                    this.setState({
                        loading: false,
                        paymentData: data.response?.paymentAttempts || []
                    })
                } else {
                    this.setState({
                        loading: false,
                        paymentData: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    paymentData: []
                })
            })
        })
    }

    onNameChnage = e =>{
        let awb = e.target.value.split(' ');
        if (awb[0] === "") {    
            awb = [];
         }
        this.setState({waybills : awb})
    }

    onSearch = val => {
        if (!val) {
            this.setState({
                loading: false,
                paymentData: []
            })
            return
        }
        let awb = val.split(' ');

        // let transactionTimeDateRange = {
        //     "from" : moment(moment().subtract(1, 'months'), dateFormat).valueOf(),
        //     "to": moment(moment(), dateFormat).valueOf()
        // }

        // let updateDateRange = {
        //     "from" : moment(moment().subtract(1, 'months'), dateFormat).valueOf(),
        //     "to": moment(moment(), dateFormat).valueOf()
        // }
        // if (awb[0]) {    
        //     awb = [];
        //  }
        let payload = {
            "waybillNos": awb,
            // "transactionStatus": [],
            // "transactionTimeDateRange": transactionTimeDateRange,
            // "updateTimeDateRange": updateDateRange,
            // "feName": '',
        }
        this.fetchPaymentData(payload)
    }

    showFilterModal = () => {
        this.setState({
          filterVisible: true,
        });
    };

    closeModal = () => {
        this.clearFilters()
        this.setState({
            filterVisible: false,
        });
    }

    onChangeTransactionTime = (dates, dateStrings) => {
        // console.log('From: ', dates[0], ', to: ', dates[1]);
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        if(dates[0]){
            console.log(moment(dates[1]).diff(moment(dates[0]), 'days'));
            if(moment(dates[1]).diff(moment(dates[0]), 'days') > 29){
                this.setState({ novalidDate : true, noDate : false, transactionFrom: dates[0], transactionTo: dates[1]})
            } else {
                this.setState({ novalidDate : false, noDate : false, transactionFrom: dates[0], transactionTo: dates[1]})
            }
        } else {
            this.setState({ noDate : true, novalidDate : false, transactionFrom : undefined, transactionTo: undefined})
        }

    }

    onChangeUpdate = (dates, dateStrings) => {
        // console.log('From: ', dates[0], ', to: ', dates[1]);
        // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
        // let updateDateRange = {
        //     "from" : dates[0].valueOf(),
        //     "to": dates[1].valueOf()
        // }
        this.setState({ updateFrom : dates[0], updateTo : dates[1]})
    }

    onChangeFeName = (e) => {
        this.setState({ feName : e.target.value})
    }

    handleFilterOk = () => {
        if(this.state.noDate === true || this.state.novalidDate === true){
            return
        } else {
            let transactionTimeDateRange = {
                "from" : this.state ?.transactionFrom ?.startOf("day") ?.valueOf(),
                "to": this.state ?.transactionTo ?.endOf("day") ?.valueOf()
            }
    
            let updateDateRange = {
                "from" : this.state ?.updateFrom ?.startOf("day") ?.valueOf(),
                "to": this.state ?.updateTo ?.endOf("day") ?.valueOf()
            }
            let payload = {
                "waybillNos": this.state.waybills,
                "transactionStatus": this.state.transactionStatus ? this.state.transactionStatus : [],
                "transactionTimeDateRange": transactionTimeDateRange,
                "updateTimeDateRange": updateDateRange,
                "feName": this.state.feName ? this.state.feName : '',
            }
            this.fetchPaymentData(payload);
            this.setState({
                filterVisible: false,
            });
        }
    }

    onChangeTransaction = (checkedList) =>{
        this.setState({ transactionStatus : checkedList, checkedList : checkedList, indeterminate: !!checkedList.length && checkedList.length < transactionStatusValues.length,})
    }

    clearFilters = () => {
        this.setState({
            checkedList: [],
            indeterminate: false,
            transactionStatus : [],
            updateFrom: undefined,
            updateTo : undefined,
            feName : '',
            noDate : false,
            novalidDate : false,
            transactionFrom : moment(moment().subtract(29, 'days')),
            transactionTo : moment(moment()),
          });
    }

    // disabledDate = current => {
    //     const { transactionFrom} = this.state;
    //     if (this.state.transactionFrom) {
    //         const dayStart = moment(transactionFrom).add(1, 'days');
    //         const dayEnd = moment(transactionFrom).add(1, 'month');
    //         console.log(dayStart);
    //         console.log(dayEnd);
    //         if (dayEnd.isAfter(moment().endOf('day'))) {
    //             console.log('1>>>>>>>>>>')
    //             return !(dayStart.isSameOrBefore(current) && current < moment().endOf('day'));
    //         } else {
    //             console.log('2>>>>>>>>>>')
    //             return !(dayStart.isSameOrBefore(current) && dayEnd.isAfter(current));
    //         }


    //     } else {
    //         // return current && current > moment().endOf('day');
    //     }

    // }


    render() {
        const { columns, paymentData, loading, filterVisible } = this.state;
        // const { form} = this.props;
        // const { getFieldDecorator } = form;
        const actionBtns = (
            <Row>
                <Col span={24}>
                <Input.Search size={'large'} placeholder="Enter Waybill Number" allowClear ref={this.InputSearchRef} onChange={this.onNameChnage} onSearch={value => this.onSearch(value)} enterButton />
                </Col>
            
                {/* <Col span={4}>
                <Tooltip placement="top" title={'Filter List'}>
                    <Button block size={'large'} onClick={this.showFilterModal} type="primary">
                    <Icon type="filter" />
                    </Button>
                </Tooltip>
                </Col> */}
            </Row>
          );
        return (
            <>

                <Card  className="CustomerDashBoard filterbox payment-status" title={<h2>Payment Status</h2>} extra={actionBtns}>
                            <Table
                                bordered
                                locale={{
                                    emptyText: (
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    )
                                }}
                                loading={loading}
                                // pagination={{
                                //     total: paymentData?.totalCount,
                                //     position: 'bottom',
                                //     current: pageNo,
                                //     pageSize: paymentData?.pageSize
                                // }}
                                dataSource={paymentData || []}
                                columns={columns} />
                </Card>
                <Row>
                    <Modal
                    title="Filters" 
                    visible={filterVisible}
                    onOk={this.handleFilterOk}
                    width={400}
                    onCancel={this.closeModal}
                    footer={[
                        <Button key="back" onClick={this.clearFilters}>
                        Clear
                        </Button>,
                        <Button key="submit" type="primary"  onClick={this.handleFilterOk}>
                        Apply Filters
                        </Button>,
                    ]}
                    >
                        <div className="filterTitles">Transaction Status: </div>
                        <Checkbox.Group
                            options={transactionStatusValues}
                            value={this.state.checkedList}
                            onChange={this.onChangeTransaction}
                        />
                        <div className="filterTitles">Transaction Time: </div>
                        <RangePicker
                            ranges={{}}
                            onChange={this.onChangeTransactionTime}
                            // disabledDate={this.disabledDate}
                            defaultValue={[this.state.transactionFrom ? this.state.transactionFrom : null, this.state.transactionTo ? this.state.transactionTo : null]}
                            value={[this.state.transactionFrom ? this.state.transactionFrom : null, this.state.transactionTo ? this.state.transactionTo : null]}
                            format={dateFormat}
                        />
                        {this.state.noDate && <div className="dateColor">Please enter a date range for the transaction</div>}
                        {this.state.novalidDate && <div className="dateColor">Max date range is one month</div>}
                        <div className="filterTitles">Update Time: </div>
                        <RangePicker
                            onChange={this.onChangeUpdate}
                            value={[this.state.updateFrom ? this.state.updateFrom : null, this.state.updateTo ? this.state.updateTo : null]}
                            format={dateFormat}
                        />
                        <div className="filterTitles">FE Name: </div>
                        <Input placeholder="FE Name" value={this.state.feName} onChange={this.onChangeFeName}/>
                    </Modal>
                </Row>
            </>
        )
    }
}

    // const mapStateToProps = state => ({
    // });
  
    // const mapDispatchToProps = dispatch => ({
    // });

// export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'paymentFilters', onValuesChange: (props, val) => console.log(props.form.getFieldsValue()) })(PaymentStatus));
export default PaymentStatus;