import * as CONSTANTS from './constants';

export const ReatilerCustomerReducer = (state = {
    validServiceables: {
        loading: true,
        data: {},
        metaData: null
    },
    customerCreation: {
        loading: true,
        data: {},
        metaData: null
    }
}, action) => {
    switch (action.type) {
        case CONSTANTS.CUSTOMER_CREATION_BEGIN: return CustomerCreationBegin(state);
        case CONSTANTS.CUSTOMER_CREATION_SUCCESS: return CustomerCreationSuccess(state, action);
        case CONSTANTS.CUSTOMER_CREATION_FAILURE: return CustomerCreationFailure(state);

        case CONSTANTS.VALIDATE_SERVICESABLE_BEGIN: return ValidateServicesableBegin(state);
        case CONSTANTS.VALIDATE_SERVICESABLE_SUCCESS: return ValidateServicesableSuccess(state, action);
        case CONSTANTS.VALIDATE_SERVICESABLE_FAILURE: return ValidateServicesableFailure(state);

        default:
            return state;
    }
}

function CustomerCreationBegin(state) {
    return {
        ...state,
        customerCreation: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function CustomerCreationSuccess(state, action) {
    return {
        ...state,
        customerCreation: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function CustomerCreationFailure(state, action) {
    return {
        ...state,
        customerCreation: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function ValidateServicesableBegin(state) {
    return {
        ...state,
        validServiceables: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function ValidateServicesableSuccess(state, action) {
    return {
        ...state,
        validServiceables: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function ValidateServicesableFailure(state, action) {
    return {
        ...state,
        validServiceables: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}