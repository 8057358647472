import React, { useState, useEffect } from "react"
import { Card, Button, Input, Row, Col, Form, Divider, Checkbox, Modal, Icon, Tooltip } from "antd";
import { validationRules } from "../../../common/utils/constants";
import { permissionTags } from "../../../configs/permissionTags";
import * as apiUtils from "../../../common/utils/apiGateway";
import { canAllow } from "../../../common/utils/utils";
import NumberFormat from "react-number-format";
import "./ClientOnboardingForm.scss"
import { customerData, isEditCustomer } from "./ClientDashboard";

function ClientOnboarding(props){
    const { getFieldDecorator } = props.form;
    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        labelAlign: "left",
        colon: false
      };

    const [clientOnboardingPermission, setClientOnboardingPermission] = useState({read: canAllow(permissionTags.settings.client_onboarding.read)})
    const [isChecked, setIsChecked] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [generatedSecretKey, setGeneratedSecretKey] = useState("")
    const [generatedClientCode, setGeneratedClientCode] = useState("")
    
    useEffect((prevProps) => { 
      if(isEditCustomer){
        props.form.setFieldsValue({
          "name": customerData.name,  
          "poc":customerData.poc,
          "contactNumber":customerData.contactNumber,
          "email":customerData.email,
          "addressLine1": customerData.address?.address1,
          "addressLine2": customerData.address?.address2,
          "city": customerData.address?.pincode.city?.name,
          "pincode": customerData.address?.pincode.zipcode,
          "state": customerData.address?.pincode.city?.state,
          "country": customerData.address?.pincode.city?.country || "India",
          "statusCallbackUrl": customerData.config?.callbackUrl || "",
          "auhtHeaderKey": customerData.config?.callbackAuthKey || "",
          "authHeaderValue": customerData.config?.callbackAuthValue || "",
          "generateSecrets": true,
          "secretKey": customerData.config?.secretKey,
          "customerCode": customerData.config?.uniqueIdentifier,
          "displayLogoUrl": customerData.config?.appDisplayLogo,
          "displayName": customerData.config?.appDisplayName,
        })}
        if(!isEditCustomer){
          props.form.setFieldsValue({
            "name": "",  
            "poc": "",
            "contactNumber": "",
            "email": "",
            "addressLine1": "",
            "addressLine2": "",
            "city": "",
            "pincode": "",
            "state": "",
            "country": "" || "India",
            "statusCallbackUrl": "",
            "auhtHeaderKey": "",
            "authHeaderValue": "",
            "generateSecrets": false,
            "secretKey": "",
            "customerCode": "",
            "displayLogoUrl": "",
            "displayName": "",
          })}
    }, [])

    const goBack = () => {
        props.history.push("/appv2/settings/dashboard/client-onboarding") 
    }

    const checkboxHandler = (e) => {
      setIsChecked(!isChecked)
    }

    const modalCloseHandler = () => {
      setIsModalVisible(false)
      props.history.push("/appv2/settings/dashboard/client-onboarding")
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                let clientName = values.name
                let address = {
                    "poc": values.poc,
                    "contactNumber" : values.contactNumber,
                    "email": values.email,
                    "addressLine1": values.addressLine1,
                    "addressLine2": values.addressLine2,
                    "city": values.city,
                    "pincode": values.pincode,
                    "state": values.state,
                    "country": values.country,
              }              
              let webhook = {
                  "statusCallbackUrl": values.statusCallbackUrl || "",
                  "authHeaderKey" : values.authHeaderKey || "",
                  "authHeaderValue": values.authHeaderValue || "",                  
              }
              let api = {
                "generateSecrets": isChecked,
                "secretKey" : values.secretKey || "",
                "customerCode": values.customerCode || "",                  
              }
              let app = {
                "displayLogoUrl": values.displayLogoUrl || "",
                "displayName" : values.displayName || "",                  
            }
        if(!isEditCustomer){   
        apiUtils.apiRequest({
            url: "/hyperlocal/customer/create",
            method: "POST",
            data: {
            "name": clientName,
            "address": address,
            "webhook": webhook,
            "api": api,
            "app": app
            }
        })
        .then(res => {
          setIsModalVisible(true)
          console.log(res.data.response)
          setGeneratedSecretKey(res.data.response.secretKey)
          setGeneratedClientCode(res.data.response.customerCode)          
        })
        .catch(err => console.log(err))       
      }

      else if(isEditCustomer){
        apiUtils.apiRequest({
          url: "/hyperlocal/customer/update",
          method: "PUT",
          data: {
          "name": clientName,
          "address": address,
          "webhook": webhook,
          "api": api,
          "app": app
          }
      })
      .then(res => {
        setIsModalVisible(true)
        console.log(res.data.response)
        setGeneratedSecretKey(api.secretKey)
        setGeneratedClientCode(api.customerCode)       
      })
      .catch(err => console.log(err))   
      }
    }})
}
             
    
  return (
    <Card className=" load-share-custom">
    <Form {...formItemLayout} onSubmit={handleSubmit} >
          <Row>
            <Col span={24}>
              <Card className="ClientOnboarding" title={<h3> <Icon onClick={goBack} type="arrow-left" /> {isEditCustomer ? "Update" :"Create"} Customer</h3>}>
                  <Row gutter={24}>
                    <Col span="12">
                      <Form.Item label="Client Name">
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: "Please input client name!",
                            },                         
                          ],
                        })(
                          <Input placeholder="Enter client name" style={{textTransform:"uppercase", whiteSpace: "nowrap"}}
                                disabled={isEditCustomer}
                          />
                        )}
                      </Form.Item>                      
                      <Form.Item label="Contact number">
                          {getFieldDecorator("contactNumber", {
                            rules: [
                              {
                                required: true,
                                message: "Please input the contact number!",
                              },
                              {
                                pattern: validationRules.mobile,
                                message: "Please enter valid contact number"
                              }
                            ],
                          })(
                            ( <NumberFormat                          
                              type="text"
                              mask=" "
                              placeholder="Contact Number"                                                  
                              className="pincode-client-onboarding"
                              format="##########"
                              min={10}
                      />
                      )
                          )}                    
                      </Form.Item>
                      <Form.Item label="Email">
                          {getFieldDecorator("email", {
                            rules: [
                              {
                                required: true,
                                message: "Please input primary email!",
                              },
                              {
                                pattern: validationRules.email,
                                message: "Please enter valid email"
                              }
                            ],
                          })(
                            <Input placeholder="Enter Email ID"/>
                          )}                     
                      </Form.Item>
                      <Form.Item label="Contact Person">
                        {getFieldDecorator("poc", {
                          rules: [
                            {
                              required: true,
                              message: "Please input contact name!",
                            },
                          ],
                        })(
                          <Input placeholder="Enter contact person name" />
                        )}
                      </Form.Item>
                      <Divider orientation="left">Head Office Address</Divider>
                      <Form.Item label="Address">
                          {getFieldDecorator("addressLine1", {
                            rules: [
                              { 
                                required: true,
                                message: "Please input address!",
                              },
                            ],
                          })(
                            <Input placeholder="Address1" />
                          )}
                        </Form.Item>
                      <Form.Item label="Locality/Area">
                          {getFieldDecorator("addressLine2", {
                            rules: [
                              {
                                required: true,
                                message: "Please input locality/area"
                              }
                            ],
                          })(
                            <Input placeholder="Locality/Area" />
                          )}
                      </Form.Item>                      
                      <Form.Item label="City">
                        {getFieldDecorator("city", {
                          rules: [
                            {
                              required: true,
                              message: "City can't be blank!",
                            },
                          ],
                        })(
                          <Input placeholder="City" />
                        )}
                      </Form.Item>
                      <Form.Item label="Pincode">
                      {getFieldDecorator("pincode", {
                          rules: [
                            {
                              required: true,
                              message: "Pincode can't be blank!",
                            },
                          ],
                        })(
                          ( <NumberFormat                          
                            type="text"
                            mask=" "
                            placeholder="Pincode"                                                  
                            className="pincode-client-onboarding"
                            format="######"
                            min={6}
                    />
                    )
                        )}
                      </Form.Item>                    
                      <Form.Item label="State">
                        {getFieldDecorator("state", {
                          rules: [
                            {
                              required: true,
                              message: "State can't be blank!",
                            },
                          ],
                        })(
                          <Input placeholder="State" />                        
                        )}
                      </Form.Item>                      
                      <Form.Item label="Country">
                        {getFieldDecorator("country", {
                          rules: [
                            {
                              required: true,
                              message: "Country can't be blank!",
                            },
                          ],
                        })(
                          <Input placeholder="Country" />
                        )}
                      </Form.Item>                     
                    </Col>  
                    <Col span={1}>
                      <Divider type="vertical" style={{height: "83vh"}}/>
                    </Col>
                    <Col span={10}>
                    <Divider orientation="left">Webhook</Divider>
                      <Form.Item label="Status Callback Url" name="url">
                        {getFieldDecorator("statusCallbackUrl", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Status Callback Url can't be blank!",
                            // },
                            // { 
                            //   type: "url", 
                            //   warningOnly: true,
                            //   message: "Not a valid Url!",
                            // }                                              
                          ],                                              
                        })(
                          <Input placeholder="https://somes3url.com/asaj/askas" />                          
                        )}
                      </Form.Item>
                      <Form.Item label="Auth Header Key">
                        {getFieldDecorator("auhtHeaderKey", {                        
                        })(
                          <Input placeholder="Auth Header Key"/>
                        )}
                      </Form.Item>
                      <Form.Item label="Auth Header Value">
                        {getFieldDecorator("authHeaderValue", {                          
                        })(
                          <Input placeholder="Auth Header Value" />
                        )}
                      </Form.Item>
                      <Divider orientation="left">API</Divider>
                      <Form.Item label="Generate Secrets">
                        {getFieldDecorator("generateSecrets", {
                          
                        })(
                          <Checkbox placeholder="Generate Secrets" onChange={checkboxHandler} disabled={isEditCustomer}/>
                        )}
                      </Form.Item>                
                      {isChecked ? 
                       <Form.Item label="Secret Key">                    
                          {getFieldDecorator("secretKey", {                            
                             })(                          
                          <Input placeholder="Secret Key" disabled={isEditCustomer || isChecked}/>                          
                          )}                          
                        </Form.Item>
                        : <Form.Item label="Secret Key">                    
                            {getFieldDecorator("secretKey", {
                              rules: [
                                {
                                  required: true,
                                  message: "Secret Key can't be blank!",
                                },
                              ],                            
                              })(                          
                            <Input placeholder="Secret Key" disabled={isEditCustomer || isChecked}/>                          
                              )}                          
                        </Form.Item>
                      }
                      {isChecked ? 
                        <Form.Item label="Customer Code">
                            {getFieldDecorator("customerCode", {
                              })(                          
                              <Input disabled={isChecked || isEditCustomer} placeholder="Customer Code" />
                            )}
                        </Form.Item>
                      : <Form.Item label="Customer Code">
                            {getFieldDecorator("customerCode", {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Customer Code can't be blank!",
                                    },
                                  ],                      
                              })(                          
                              <Input disabled={isChecked || isEditCustomer} placeholder="Customer Code" />
                            )}
                  </Form.Item>}
                      <Divider orientation="left">APP</Divider>
                      <Form.Item label="Display Logo Url" name="url">
                        {getFieldDecorator("displayLogoUrl", {
                          rules: [                            
                            // { 
                            //   type: "url", 
                            //   warningOnly: true,
                            //   message: "Not a valid Url!",
                            // },                           
                          ],                                              
                        })(                         
                            <Input placeholder="https://somes3url.com/asaj/askas" />
                        )}
                      </Form.Item>                      
                      <Form.Item label="Display Name">
                        {getFieldDecorator("displayName", {
                          
                        })(
                          <Input placeholder="Name to be displayed in the app"/>
                        )}
                      </Form.Item>
                      </Col>
                  </Row>                
              </Card>
            </Col>            
          </Row>

          <Card>
              <div style={{display:"flex", justifyContent: "center"}}>
                  <Button size="large" onClick={goBack}>CANCEL</Button>
                  <Button size="large" htmlType="submit" type="primary" style={{marginLeft: "20px"}}>{isEditCustomer ? "UPDATE" : "CREATE" }</Button>
              </div>
          </Card>
        </Form>
        <Modal            
            visible={isModalVisible}
            onCancel={modalCloseHandler}
            width={700}
            footer={[
              <Button key="back" type="default" onClick={modalCloseHandler}>
                Close
              </Button>,              
            ]}
          >
          <div>
            <div style={{display: "flex"}}><h4>Secret Key</h4> - {generatedSecretKey}</div>
            <div style={{display: "flex"}}><h4>Customer Code</h4> - {generatedClientCode}</div>
          </div>           
          </Modal>
    </Card>
  )
}

const ClientOnboardingForm = Form.create({ name: "client-onboarding" })(ClientOnboarding);

export default ClientOnboardingForm