export const GET_PARTNER_LOCATION_FETCH_SUCCESS = 'GET_PARTNER_LOCATION_FETCH_SUCCESS';
export const GET_PARTNER_LOCATION_FETCH_FAILURE = 'GET_PARTNER_LOCATION_FETCH_FAILURE';
export const GET_PARTNER_LOCATION_FETCH_BEGIN = 'GET_PARTNER_LOCATION_FETCH_BEGIN';

export const GET_CUSTOMER_LOCATION_FETCH_SUCCESS = 'GET_CUSTOMER_LOCATION_FETCH_SUCCESS';
export const GET_CUSTOMER_LOCATION_FETCH_FAILURE = 'GET_CUSTOMER_LOCATION_FETCH_FAILURE';
export const GET_CUSTOMER_LOCATION_FETCH_BEGIN = 'GET_CUSTOMER_LOCATION_FETCH_BEGIN';

export const GET_CITIES_FETCH_SUCCESS = 'GET_CITIES_FETCH_SUCCESS';
export const GET_CITIES_FETCH_FAILURE = 'GET_CITIES_FETCH_FAILURE';
export const GET_CITIES_FETCH_BEGIN = 'GET_CITIES_FETCH_BEGIN';


export const GET_PINCODES_FETCH_SUCCESS = 'GET_PINCODES_FETCH_SUCCESS';
export const GET_PINCODES_FETCH_FAILURE = 'GET_PINCODES_FETCH_FAILURE';
export const GET_PINCODES_FETCH_BEGIN = 'GET_PINCODES_FETCH_BEGIN';


export const GET_VENDORS_LIST_BEGIN = 'GET_VENDORS_LIST_BEGIN';
export const GET_VENDORS_LIST_SUCCESS = 'GET_VENDORS_LIST_SUCCESS';
export const GET_VENDORS_LIST_FAILURE = 'GET_VENDORS_LIST_FAILURE';

export const SET_VENDOR_CREATE_BEGIN = 'SET_VENDOR_CREATE_BEGIN';
export const SET_VENDOR_CREATE_SUCCESS = 'SET_VENDOR_CREATE_SUCCESS';
export const SET_VENDOR_CREATE_FAILURE = 'SET_VENDOR_CREATE_FAILURE';

export const SET_VENDOR_UPDATE_BEGIN = 'SET_VENDOR_UPDATE_BEGIN';
export const SET_VENDOR_UPDATE_SUCCESS = 'SET_VENDOR_UPDATE_SUCCESS';
export const SET_VENDOR_UPDATE_FAILURE = 'SET_VENDOR_UPDATE_FAILURE';


export const GET_VENDOR_DATA_BEGIN = 'GET_VENDOR_DATA_BEGIN';
export const GET_VENDOR_DATA_SUCCESS= 'GET_VENDOR_DATA_SUCCESS';
export const GET_VENDOR_DATA_FAILURE = 'GET_VENDOR_DATA_FAILURE';

export const GET_VENDOR_UNBLOCK_BEGIN = 'GET_VENDOR_UNBLOCK_BEGIN';
export const GET_VENDOR_UNBLOCK_SUCCESS= 'GET_VENDOR_UNBLOCK_SUCCESS';
export const GET_VENDOR_UNBLOCK_FAILURE = 'GET_VENDOR_UNBLOCK_FAILURE';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';