
export const initialState = {
    loading: false
}

export const FETCH_LOCATIONS_BEGIN = "FETCH_LOCATIONS_BEGIN";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";

export const FETCH_CUSTOMERS_BEGIN = "FETCH_CUSTOMERS_BEGIN";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const FETCH_VENDORS_BEGIN = "FETCH_VENDORS_BEGIN";
export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_FAILURE = "FETCH_VENDORS_FAILURE";

export const FETCH_LOCATIONBYID_BEGIN = "FETCH_LOCATIONBYID_BEGIN";
export const FETCH_LOCATIONBYID_SUCCESS = "FETCH_LOCATIONBYID_SUCCESS";
export const FETCH_LOCATIONBYID_FAILURE = "FETCH_LOCATIONBYID_FAILURE";

export const FETCH_AREAS_BEGIN = "FETCH_AREAS_BEGIN";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_FAILURE = "FETCH_AREAS_FAILURE";

export const LOCATION_MODIFICATION_BEGIN = "LOCATION_MODIFICATION_BEGIN";
export const LOCATION_MODIFICATION_SUCCESS = "LOCATION_MODIFICATION_SUCCESS";
export const LOCATION_MODIFICATION_FAILURE = "LOCATION_MODIFICATION_FAILURE";

export const PROMISE_TIME_MODIFICATION_BEGIN = "PROMISE_TIME_MODIFICATION_BEGIN";
export const PROMISE_TIME_MODIFICATION_SUCCESS = "PROMISE_TIME_MODIFICATION_SUCCESS";
export const PROMISE_TIME_MODIFICATION_FAILURE = "PROMISE_TIME_MODIFICATION_FAILURE";

export const GROUP_LOCATION_BEGIN = "GROUP_LOCATION_BEGIN";
export const GROUP_LOCATION_SUCCESS = "GROUP_LOCATION_SUCCESS";
export const GROUP_LOCATION_FAILURE = "GROUP_LOCATION_FAILURE";
