import React, { Component } from "react";
import { Layout, Menu, Icon as Hamburger } from 'antd';
import { menuconfig } from "../../../configs/menu-config";
import { reactUrl } from "../../../environments/node-react-environment";
import { icon as Icon } from '../../ui/icon/Icon';
import './Sidebar.scss';
const { Sider, Header } = Layout;

class Sidebar extends Component {
  state = {
    menuItems: [],
    selectedMenuKey: null,
    collapsed: false,
  };

  componentDidMount() {
    if (this.props.location.pathname !== "/appv2/") {
      this.selectMenuAndTabForValidUrl();
    }
  }

  selectMenuAndTabForValidUrl = () => {
    if (!this.props.history.location.pathname) {
      return;
    }
    const pathParts = this.props.history.location.pathname.split("/");
    const tabPath = pathParts[pathParts.length - 1]
    const menuPath = pathParts.slice(2, 3).join("/");
    const menuToBeSelected = menuconfig.find(menu => {
      return menu.path === menuPath;
    });
    if (menuToBeSelected) {
      menuToBeSelected.children.find((child, index) => {
        return child.path === tabPath;
      });
      const allowedMenus = menuconfig.filter(menu => {
        return menu.canAllow;
      });
      if (tabPath === '') {
        let isFirst = true;
        menuToBeSelected.children.map((child, index) => {
          if (child.canAllow && isFirst) {
            isFirst = false;
          }
        })
      }
      this.setState({
        selectedMenuKey: menuToBeSelected.key,
        menuItems: allowedMenus
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configurations !== this.props.configurations) {
      if (this.props.location.pathname !== "/appv2/") {
        this.selectMenuAndTabForValidUrl();
      }
    }
  }

  getUrlDirection = (path, type) => {
    if (type) {
      this.props.history.push(`/appv2/${path}/dashboard`);
    } else {
      window.location.replace(`${reactUrl}${path}/dashboard`);
    }
  }

  getMenuHtml = (data) => {
    return data.map((item, i) => {
      let redirectUrl;
      redirectUrl = item.isReact ? (reactUrl + "/appv2/" + item.path + "/dashboard") : (reactUrl + item.path + "/dashboard");
      let newTab = false;
      let isSelected = false;
      if (item.type === 'external-link') {
        newTab = true;
      }
      isSelected = (item.key === this.state.selectedMenuKey);
      let classes = ['menu-item-container'];
      let aHtml = null;
      if (item.isReact) {
        aHtml = <a className="menu-item-label" onClick={() => this.getUrlDirection(item.path, 'react')}  target={newTab ? '_blank' : '_self'}>{item.label}</a>;
      } else {
        aHtml = <a className="menu-item-label" onClick={() => this.getUrlDirection(item.path)}  target={newTab ? '_blank' : '_self'}>{item.label}</a>;
      }
      // let aHtml = <a className="menu-item-label" href={redirectUrl} target={newTab ? '_blank' : '_self'}>{item.label}</a>;
      if (isSelected) {
        classes.push('menu-item-container-active');
        aHtml = <span className="menu-item-label">{item.label}</span>
      }
      return (<Menu.Item
        key={`${item.path}${i}`}
        className={classes.join(' ')}>
        <Icon img={isSelected ? item.activeIcon : item.inactiveIcon} className="menu-item-img" />
        {aHtml}
      </Menu.Item>)
    });
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  
  render() {
    const { menuItems } = this.state;
    let menuItemHtml;
    if (menuItems) {
      menuItemHtml = this.getMenuHtml(menuItems);
    }

    return (
      <div className="outermost-container-sidebar">
      <Sider width={240} style={{ background: '#fff', height: '100%'}} 
        trigger={null}
        // collapsible={this.props.collapse}
        className="sidebar-custom"        
        collapsible collapsed={this.state.collapsed}               
        >                          
        <Menu
          mode="inline"
          defaultSelectedKeys={['pickup']}
          style={{ height: '100%', borderRight: 0 }}
        >
          {menuItemHtml}
        </Menu> 
        <Header style={{ background: '#fff', padding: 0,  display:"flex", justifyContent: "center", bottom: 0}}>
            <Hamburger
              className="trigger"
              type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
              onClick={this.toggle}
            />
          </Header>        
      </Sider> 
        
      </div>

    );
  }
}

export default Sidebar;