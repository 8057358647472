import React, { Component } from 'react';
import { Divider, Button, Row, Icon } from 'antd';
import * as utils from "../../../common/utils/utils";
import "./ShowDetails.scss";

class Stepper extends Component {
  constructor(props) {
    super(props)
    this.state = {
        stepList: null,
        stepKey: null,
    }
  }


  getDiscription = (name, sTime, eTime, halts, delay, key, circleClass, status, tileSet, setN) => {
      return (
          <>
          <Row key={key}>
            <div className="clearing">
                <div className="left_box">
                <div className={circleClass}>{setN ? <div className="whiteLetter">{String.fromCharCode(setN)}</div> : <div className="whiteLetter">{String.fromCharCode(32)}</div>}</div>
                    {status === "PENDING" && <div className={(circleClass === "redcircle") ? "lineset2" : "lineset4"}></div>}
                    {status === "ARRIVED" && <div className={(circleClass === "redcircle") ? "lineset2" : "lineset"}></div>}
                </div>
                <div className="right_box">
                    {delay ? <div className="top-row">
                        <div className="step-title">{name}</div>
                        <div className="delays">Delay : {this.getHours(delay)}</div>
                    </div> : <div className="step-title2">{name}</div>}
                    <div className="dateSet">{tileSet} : {utils.dateFormat(sTime)}</div>
                    <div className="dateSet">Scheduled : {utils.dateFormat(eTime)}</div>
                    {halts && halts.length > 0 && <Button type="dashed" className="buttondashed" shape="round" onClick={() => this.getHaltsRoute(halts, key)}>View Halts
                        {this.state.stepKey === key ? <Icon className="iconAdj" type="up" /> : <Icon className="iconAdj" type="down" />}
                    </Button>}
                </div>
            </div>
          </Row>
          <Row>
            <div className="greyBackground">
            {this.state.stepKey === key && this.state.stepList}
            </div>
        </Row>
        </>
      )
  }

  getHours =(n) => {
        let num = n;
        let hours = (num / 60);
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        let final = (rhours < 1) ? rminutes + "m" : rhours + "hr " + rminutes + "m";
        return final
  }

  getDiscriptionNext = (name, sTime, eTime, halts, key) => {
        return (
            <Row key={key}>
                <div className="clearing">
                <div className="left_box">
                    <div className="bluecircle"></div>
                    <div className="lineset3"></div>
                </div>
                <div className="right_box">
                    <div className="step-title2">{name}</div>
                    <Row>
                    <div className="dateSet floting">{this.getHours(halts)} Stop</div>
                    <div className="dateSet datime">{utils.dateFormat(sTime)} - {utils.dateFormat(eTime)}</div>
                    </Row>
                </div>
                </div>
            </Row>
        )
    }

    getHaltsRoute = (halts, key) => {
        if(this.state.stepList === null || key !== this.state.stepKey){
            let haltsData;
            haltsData = halts.map((x, key) => {
                return this.getDiscriptionNext(x.location, x.gridEnteredTimeStamp, x.gridLastKnownTimeStamp, x.gridTime, key)
            })
            this.setState({stepList : haltsData, stepKey: key});
        } else {
            this.setState({stepList : null, stepKey: null});
            return;
        }
    }

    getcurrent = (data) => {
        return (
            <>
                <Row key="currentLocation">
                    <div className="clearing">
                        <div className="left_box">
                            <div className="bluecirclebox"></div>
                            <div className="lineset4"></div>
                        </div>
                        <div className="right_box">
                            <div className="currentPosition">{data}</div>
                        </div>
                    </div>
            </Row>
            </>
        )
    }

    routingList(data, currentTrip, nextSt) {
      let setDummy = 64;
        return data.map((x, key) => {
            let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
                if(key === 0){
                    return (this.getDiscription(x[setConsignMent]['name'], x.actualEta, x.expectedEta, x.geohashEventsBOS, x.delay > 0 ? x.delay : null, key, "bluecircle", x.status, "Started at"))
                }else if(key === (data.length - 1)){
                    if(nextSt === x[setConsignMent]['name']){
                        return(
                            <>
                                {this.getcurrent(currentTrip.location)}
                                {this.getDiscription(x[setConsignMent]['name'], x.actualEta, x.expectedEta, x.geohashEventsBOS, x.delay > 0 ? x.delay : null, key, "redcircle", x.status, x.status === "ARRIVED" ? "Arrived at" : "Estimated")}
                            </>
                        )
                    } else {
                        return (this.getDiscription(x[setConsignMent]['name'], x.actualEta, x.expectedEta, x.geohashEventsBOS, x.delay > 0 ? x.delay : null, key, "redcircle", x.status, x.status === "ARRIVED" ? "Arrived at" : "Estimated"))
                    }

                } else if(nextSt === x[setConsignMent]['name']){
                    setDummy = setDummy + 1;
                        return(
                            <>
                                {this.getcurrent(currentTrip.location)}
                                {this.getDiscription(x[setConsignMent]['name'], x.actualEta, x.expectedEta, x.geohashEventsBOS, x.delay > 0 ? x.delay : null, key, "blueboxcircle", x.status, "Arrived at", setDummy)}
                            </>
                        )
                } else {
                    setDummy = setDummy + 1;
                    return (this.getDiscription(x[setConsignMent]['name'], x.actualEta, x.expectedEta, x.geohashEventsBOS, x.delay > 0 ? x.delay : null, key, "blueboxcircle", x.status, "Arrived at", setDummy))}
        })
    }

    getNextStop = (data) => {
        let nextStop;
          data.some(x => {
            let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
            if(x.status === "PENDING") {
                return nextStop = x[setConsignMent]['name'];
            }
          })
          return nextStop;
    }

  render() {
      const { tripData } = this.props;
      let routeList;
      if(tripData){
        let nextStop = this.getNextStop(tripData.thcRouteMappingBOS);
        routeList = this.routingList(tripData.thcRouteMappingBOS, tripData.vehicleTrackerBO, nextStop);
      }
    return (
        <div>
        {routeList &&
            <div>
                {routeList}
            </div>
        }
      </div>
    );
  }
}

export default Stepper;
