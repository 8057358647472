import React, { PureComponent } from 'react'
import { canAllow } from '../../common/utils/utils';
import { permissionTags } from '../../configs/permissionTags';
import PaymentStatus from './payment-status';


class DigitalPaymentModule extends PureComponent {

    constructor(props) {
        super(props)
    }
    state = {
        digitalPaymentPermissions: {
            paymentStatusRead: canAllow(permissionTags.digital_payment.payment_status.read),
        },
    }
    render() {

        if (this.state.digitalPaymentPermissions.paymentStatusRead) {
            return <PaymentStatus />
        }



        return (
            <></>
        )
    }
}

export default DigitalPaymentModule;