import React, { Component } from 'react';
import {
    Card, Row, Col, Icon, Tooltip,
    Button, Form, Input, Select, Switch, Spin, Collapse
}
    from 'antd';
import FileUpload from '../../ui/file-upload/file.upload';
import { connect } from 'react-redux';
import * as actions from '../../reports/ingest/action';
import * as appActions from '../../../app/appAction';
import * as vendorActions from './action';
import { removeDuplicateLocations, decrypt } from '../../../common/utils/utils';
import { USER_LOGGEDIN_INFO, validationRules, vendorTypes as vTypes } from '../../../common/utils/constants';

import { notifyApiSuccess } from '../../../common/utils/utils';
import _ from 'lodash';
import { Mixpanel } from '../../../utils/mixpanel';
import * as moment from 'moment';

const { Panel } = Collapse;

class VendorCreate extends Component {

    constructor(props) {
        super(props);

        this.changeGST = this.changeGST.bind(this);
        this.onPartnerChange = this.onPartnerChange.bind(this);
        this.onCustomerChange = this.onCustomerChange.bind(this);
        this.changeCity = this.changeCity.bind(this);
    }

    state = {
        acceptTypes: [".pdf", ".jpg", ".jpeg"],
        initialVendors: [],
        gstcheck: false,
        pincodes: [],
        multiFiles: {},
        isEdit: false,
        editLoading: false,
        panFile: '',
        gstFile: '',
        tdsFile: '',
        bankFile: '',
        vendorFiles: [],
        disableGST: false,
        confirmDirty: false
    }

    componentDidMount() {
        const user = JSON.parse(atob(localStorage.getItem('user')));
        const loggedPartnerId = localStorage.getItem('loggedPartnerId');
        Mixpanel.identify(loggedPartnerId);
        Mixpanel.people.set({
            $first_name: user?.name,
            $email: user?.email,
            $phone_number: user?.number,
            $partner_id: user?.partner?.id,
            $partner_name: user?.partner?.name,
        });
        if (this.props?.location?.state?.vendorId) {
            this.setState({ isEdit: true, editLoading: true });
            this.props.getVendorData(this.props?.location?.state?.vendorId);
        }

        this.props.getCustomerList();
        this.props.getCities();
        this.props.getPincodes();
        let initialVendors = [...this.state.initialVendors];
        vTypes.map(v => {
            if (v.checked) {
                initialVendors.push({ "key": v.value, "label": v.name });
                this.setState({ initialVendors });
                v.disabled = true;
            } else {
                v.disabled = false;
            }
        })


    }

    componentWillUnmount() {
        this.setState({ isEdit: false });
        this.props.setInitialState();
        this.props.form.resetFields();
    }


    getFilteredValue(toBeModifiedData, listToBeInserted) {
        let modifiedData = [];
        if (!toBeModifiedData) {
            return modifiedData;
        }
        modifiedData = toBeModifiedData.map((data) => {
            const currentValue = data.linkPartnerBO ? data.linkPartnerBO.id : data.id,
                currentName = data.linkPartnerBO ? data.linkPartnerBO.name : (data.name || 'partner name not available');

            if (listToBeInserted && listToBeInserted.indexOf(currentValue === -1)) {
                listToBeInserted.push(currentValue);
            }

            return {
                value: currentValue,
                label: currentName,
                name: currentName,
                id: currentValue,
                key: currentValue,
                entityId: data.entityId ? data.entityId : ''
            };
        });
        return removeDuplicateLocations(modifiedData, { nameWithRole: 'name' })
        // return modifiedData;
    }


    componentDidUpdate(oldProps) {
        if (this.props.vendorCreateResp && oldProps.vendorCreateResp !== this.props.vendorCreateResp) {
            if (this.props.vendorCreateResp.length > 0) {
                notifyApiSuccess("SUCCESS: ", "Vendor Created Successfully");
                this.props.history.push('/appv2/settings/dashboard/vendor', { isReset: true })
            }
        }
        if (this.props.vendorUpdateData && this.props.vendorUpdateData.id) {
            notifyApiSuccess("SUCCESS: ", "Vendor Updated Successfully");
            this.props.history.push('/appv2/settings/dashboard/vendor', { isReset: true })
            // this.goBack();
        }

        const { setFieldsValue } = this.props.form;
        const { vendorData } = this.props;
        if (vendorData && oldProps.vendorData?.id != this.props.vendorData.id) {


            var promise = new Promise((resolve, reject) => {
                const { name, email, contactNumber, secondaryEmail,
                    secondaryContactNumber, gstNumber, locations,
                    contactPerson, identityDocuments, partnerBO,
                    partnerLocation, customerBO, customerLocation,
                    bankName, accountNumber, ifscCode, bankBranchName
                } = vendorData;
                if (gstNumber) {
                    this.changeGST(true);
                    this.setState({ disableGST: true })
                }
                const locationname = locations[0].name;
                const address1 = locations[0].address.address1;
                const address2 = locations[0].address.address2;
                const landmark = locations[0].address.landmark;
                const city = locations[0].address.pincode.city.id;
                const pincode = locations[0].address.pincode.zipcode;
                const newVal = [];
                partnerBO.map(p => {
                    newVal.push(p.id)
                })

                const params = {
                    'entityType': 'PARTNER',
                    'entityId': JSON.parse(localStorage.getItem(USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID)),
                    'partnerList': newVal.join(),
                    'loggedInUserTokken': true
                };
                this.props.getPartnerLocations(params);

                const params1 = {
                    "filters": {
                        'entityType': 'CUSTOMER',
                        'loggedInUserTokken': true
                    }
                }
                this.props.getCustomerLocations(params1)



                let panproof, pan, gstproof, tdsproof, tds, vendorcontract = [], vendor = [];
                if (_.hasIn(this.props.configurations, 'vendor_kyc_enable') && this.props.configurations.vendor_kyc_enable === 'true') {
                    if (identityDocuments && identityDocuments.length > 0) {
                        identityDocuments.map((doc, index) => {

                            switch (doc.documentType) {
                                case "PAN_CARD":
                                    pan = doc['documentValue'];
                                    panproof = doc['s3URL'];
                                    this.setState({ panFile: doc['documentType'] });
                                    break;
                                case "OTHERS":
                                    gstproof = doc['s3URL'];
                                    this.setState({ gstFile: 'gst_file' });
                                    break;
                                case "TDS_PROOF":
                                    tdsproof = doc['s3URL'];
                                    tds = doc['documentValue'];
                                    this.setState({ tdsFile: doc['documentType'] });
                                    break;
                                // case "BANK_DOCUMENTS":
                                //     bankproof = doc['s3URL'];
                                //     // console.log(decrypt(doc['documentValue']), this.IsJsonString(decrypt(doc['documentValue'])))
                                //     let bank = {};
                                //     if (this.IsJsonString(decrypt(doc['documentValue']))) {
                                //         bank = doc['documentValue'] && JSON.parse(decrypt(doc['documentValue']));
                                //         if (bank) {
                                //             bankname = bank.bankname;
                                //             ifsc = bank.ifsc;
                                //             accountNumber = bank.accountNumber;
                                //         }

                                //         this.setState({ bankFile: doc['documentType'] });
                                //     }

                                //     break;
                                case "VENDOR_CONTRACT":
                                    vendorcontract.push({ s3URL: doc['s3URL'], "documentType": "VENDOR_CONTRACT", documentValue: null });
                                    vendor.push(this.state.vendorFiles.concat(doc['documentType'] + "_" + index));
                                    this.setState({ vendorFiles: vendor });
                                    break;
                                default:
                                    void (0);
                            }

                        })
                    }
                }


                let partnername = [], partnerlocations = [], customername = [], customerlocations = [];

                // partnerBO.map(v => {
                //     if (partnername.indexOf(v.id) == -1)
                //         partnername.push(v.id === 268 ? v.id.toString() : v.id)
                // });

                partnername = this.getFilteredValue(partnerBO, []);

                // customerBO.map(v => {
                //     if (customername.indexOf(v.id) == -1)
                //         customername.push(v.id)
                // })

                customername = this.getFilteredValue(customerBO, []);

                // partnerLocation.map(v => {
                //     // this.onPartnerChange(partnername);
                //     if (partnerlocations.indexOf(v.id) == -1)
                //         partnerlocations.push(v.id)
                // });
                partnerlocations = this.getFilteredValue(partnerLocation, []);

                // customerLocation.map(v => {
                //     // this.onCustomerChange(customername);
                //     if (customerlocations.indexOf(v.id) == -1)
                //         customerlocations.push(v.id)
                //     resolve({ editngLoadingDone: true });
                // });

                customerlocations = this.getFilteredValue(customerLocation, []);


                let vendorTypeSelect = [...vTypes];
                let vendorSelectTypes = [];
                // let initialVendors = [...this.state.initialVendors];
                // initialVendors = [];
                this.props.vendorData.vendorTypes.map(vendor => {
                    vendorTypeSelect.map(vType => {
                        if (vType.value == vendor) {
                            let disabled = { ...vType };
                            disabled = true;
                            vType['disabled'] = disabled;
                            vType['key'] = vType.value;
                            vType['label'] = vType['name'];
                            vendorSelectTypes.push(vType);
                        }

                    });

                })
                setFieldsValue({
                    name,
                    email,
                    contactNumber,
                    secondaryEmail,
                    secondaryContactNumber,
                    gst: gstNumber,
                    contactPerson,
                    vendorTypes: vendorSelectTypes,
                    locationname,
                    address1,
                    address2,
                    landmark,
                    city,
                    pincode,
                    pan,
                    panproof,
                    gstproof,
                    tds,
                    tdsproof,
                    accountNumber,
                    confirmAccountNumber: accountNumber,
                    ifscCode,
                    bankName,
                    bankBranchName,
                    // bankproof,
                    vendorcontract,
                    partnername,
                    partnerlocations,
                    customername,
                    customerlocations
                });



            });

            promise.then(resp => {
                if (resp.editngLoadingDone) {
                    this.setState({ editLoading: false })
                }
            })


        }
    }

    goBack() {
        // this.props.history.goBack();
        this.props.history.push('/appv2/settings/dashboard/vendor')
    }

    onUploadFinish = (e, type, name, mode = "single", docType = "BANK_DOCUMENTS") => {

        switch (type) {
            case 'bankproof':
                this.setState({ bankFile: '' });
                break;
            case 'tdsproof':
                this.setState({ tdsFile: '' });
                break;
            case 'gstproof':
                this.setState({ gstFile: '' });
                break;
            case 'panproof':
                this.setState({ panFile: '' });
                break;
            case 'vendorcontract':
                this.setState({ vendorFiles: [] });
                break;
            default:
                void (0);
                break;

        }

        if (mode === "multiple") {
            let { multiFiles } = { ...this.state };
            if (!multiFiles[type]) {
                multiFiles[type] = [];
            }

            multiFiles[type].push({ s3URL: e, documentType: docType, documentValue: docType })

            this.setState({ multiFiles });
        }
        else {
            const { multiFiles } = { ...this.state };
            this.setState({ multiFiles: { [type]: e } });
        }
        this.props.form.setFieldsValue(this.state.multiFiles)
    }
    IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    handleUpdate() {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) return;
            let { vendorData } = this.props;
            let data = { ...values };


            // console.log("data", data, vendorData)
            let { name, contactNumber, email, secondaryEmail, secondaryContactNumber, gst, contactPerson, vendorTypes, bankName, accountNumber, ifscCode, bankBranchName } = { ...data };
            vendorTypes = vendorTypes.map(v => v.key)
            // console.log("customerlocations->>",  vendorData)
            // delete vendorData['partnerBO'];
            // delete vendorData['customerBO'];
            // delete vendorData['partnerLocation'];    
            // delete vendorData['customerLocation'];


            // console.log("data->>", vendorData)

            let customerLocations = [];
            if (data['customerlocations']) {
                customerLocations = data['customerlocations'].map(v => v.key);
            }

            // console.log("customerLocations", customerLocations)

            let partnerlocations = [];

            if (data['partnerlocations']) {
                partnerlocations = data['partnerlocations'].map(v => v.key);
            }

            // console.log("partnerlocations", partnerlocations , this.props.partnerLocationDetails)


            // console.log("cus",this.props.customerLocations)

            const linkedLocations = {
                "customer": customerLocations && customerLocations.length > 0 ? this.getMappedData(this.props.customerLocations, customerLocations) : {},
                "partner": partnerlocations && partnerlocations.length > 0 ? this.getMappedData(this.props.partnerLocationDetails, partnerlocations) : {}
            };

            const location = {
                id: vendorData['locations'][0]['id'],
                role: 'BILLING',
                entityId: vendorData['id'],
                entityType: "VENDOR",
                name: data['locationname'],
                address: {
                    address1: data['address1'],
                    address2: data['address2'],
                    landmark: data['landmark'],
                    pincode: {
                        id: this.props.pincodeDetails[data['pincode']]['id'],
                        city: {
                            id: data['city']
                        }
                    }
                }
            };

            let gstproof = {}, tdsproof = {};

            if (data['gst']) {
                gstproof = {
                    "s3URL": data['gstproof'],
                    "documentType": "OTHERS",
                    "documentValue": data['gst']
                }
            };

            if (data['tds']) {
                tdsproof = {
                    "s3URL": data['tdsproof'],
                    "documentType": "TDS_PROOF",
                    "documentValue": data['tds']
                }
            };

            // let bankproof = {};
            // if (data['bankproof']) {
            //     bankproof = {
            //         "s3URL": data['bankproof'],
            //         "documentType": "BANK_DOCUMENTS",
            //         "documentValue": JSON.stringify({ accountNumber: data['accountNumber'], ifsc: data['ifsc'], bankname: data['bankname'] })
            //     }
            // }
            // console.log("bank->>", _.hasIn(data, 'accountNumber') &&_.hasIn(data, 'bankname') && _.hasIn(data, 'bankname'))
            // console.log("bankproof", bankproof)
            // if (_.hasIn(data, 'accountNumber') && _.hasIn(data, 'bankname') && _.hasIn(data, 'bankname')) {
            //     bankproof = {
            //         ...bankproof,
            //         "documentValue": JSON.stringify({ accountNumber: data['accountNumber'], ifsc: data['ifsc'], bankname: data['bankname'] })
            //     }
            // }

            // console.log("bankproof", bankproof)



            let identityDocuments = [];
            if (_.hasIn(this.props.configurations, 'vendor_kyc_enable') && this.props.configurations.vendor_kyc_enable === 'true') {
                identityDocuments = [
                    {
                        "s3URL": data['panproof'],
                        "documentType": "PAN_CARD",
                        "documentValue": data['pan']
                    },
                    gstproof,
                    tdsproof,
                    // bankproof
                ];
                if (!data['panproof']) {
                    identityDocuments.splice(0, 1);
                }

                identityDocuments = identityDocuments.concat(data['vendorcontract']);
                identityDocuments = identityDocuments.filter(value => Object.keys(value).length !== 0);

                // console.log("prams->>", identityDocuments)
                let merge = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);
                if (vendorData['identityDocuments'] && vendorData['identityDocuments'].length > 0)
                    identityDocuments = merge(identityDocuments, vendorData['identityDocuments'], "s3URL");
            }


            if (!this.state.gstcheck) {
                delete vendorData['gstNumber'];
            }

            // delete vendorData['locations'];



            const params = {
                ...vendorData,
                name,
                contactNumber,
                email,
                secondaryEmail,
                secondaryContactNumber,
                ...(this.state.gstcheck && { gst: gst }),
                contactPerson,
                vendorTypes,
                linkedLocations,
                location,
                bankName,
                accountNumber,
                ifscCode,
                bankBranchName,
                identityDocuments,
            }

            // console.log("params->>", params)
            Mixpanel.track("Vendor Page Update Initiated", { 'ERPVendorUpdated': moment().valueOf(), vendor_update_id: params.id, gst: gst, vendor_type: vendorTypes, linked_locations: linkedLocations, location: location })




            this.props.updateVendor(params, params.id);


        });
    }


    handleSubmit() {
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (err) return;
            let customerLocations = [];
            if (values['customerlocations']) {
                customerLocations = values['customerlocations'].map(v => v.key);
            }

            let partnerlocations = [];

            if (values['partnerlocations']) {
                partnerlocations = values['partnerlocations'].map(v => v.key);
            }


            const { name, contactNumber, email, secondaryEmail, secondaryContactNumber, gst, contactPerson, vendorTypes, bankName, accountNumber, ifscCode, bankBranchName } = { ...values };
            let vTypes = vendorTypes.map(v => v["key"]);
            let linkedLocations;
            linkedLocations = {
                "customer": customerLocations ? this.getMappedData(this.props.customerLocations, customerLocations) : {},
                "partner": partnerlocations ? this.getMappedData(this.props.partnerLocationDetails, partnerlocations) : {}
            }

            const location = {
                role: 'BILLING',
                name: values['locationname'],
                address: {
                    address1: values['address1'],
                    address2: values['address2'],
                    landmark: values['landmark'],
                    pincode: {
                        id: this.props.pincodeDetails[values['pincode']]['id'],
                        city: {
                            id: values['city']
                        }
                    }
                }
            };
            let gstproof = {}, tdsproof = {};
            if (values['gst']) {
                gstproof = {
                    "s3URL": values['gstproof'],
                    "documentType": "OTHERS",
                    "documentValue": values['gst']
                }
            };

            if (values['tds']) {
                tdsproof = {
                    "s3URL": values['tdsproof'],
                    "documentType": "TDS_PROOF",
                    "documentValue": values['tds']
                }
            };
            // new way of setting bank
            // const bankproof = {
            //     "s3URL": values['bankproof'],
            //     "documentType": "BANK_DOCUMENTS",
            //     "documentValue": JSON.stringify({ accountNumber: values['accountNumber'], ifsc: values['ifsc'], bankname: values['bankname'] })
            // }
            let identityDocuments = [];
            if (_.hasIn(this.props.configurations, 'vendor_kyc_enable') && this.props.configurations.vendor_kyc_enable === 'true') {
                identityDocuments = [
                    {
                        "s3URL": values['panproof'],
                        "documentType": "PAN_CARD",
                        "documentValue": values['pan']
                    },
                    gstproof,
                    tdsproof,
                    // bankproof
                ];
                // console.log("prams->>", values)


                identityDocuments = identityDocuments.concat(values['vendorcontract']);
                identityDocuments = identityDocuments.filter(value => value != undefined && Object.keys(value).length !== 0);
            }


            const params = {
                name,
                contactNumber,
                email,
                secondaryEmail,
                secondaryContactNumber,
                gst,
                contactPerson,
                vendorTypes: vTypes,
                linkedLocations,
                location,
                bankName,
                accountNumber,
                ifscCode,
                bankBranchName,
                identityDocuments
            }
            // console.log("prams->>", params)
            Mixpanel.track("Vendor Page Create Initiated", { 'ERPVendorCreated': moment().valueOf(), gst: gst, vendor_type: vendorTypes, linked_locations: linkedLocations, location: location })

            this.props.createVendor(params);
        });


    }

    changeGST(checked) {
        this.setState({ gstcheck: checked });
        if (!checked) {
            this.props.form.resetFields(["gst"])
        }
    }

    changeCity(city) {
        const pincodes = this.props.allPincodes[city];
        this.setState({ pincodes })
    }

    onPartnerChange(val) {
        let newVal = val.map(v => v.key);
        const params = {
            'entityType': 'PARTNER',
            'entityId': JSON.parse(localStorage.getItem(USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID)),
            'partnerList': newVal.join(),
            'loggedInUserTokken': true
        };
        this.props.getPartnerLocations(params)
    }

    onCustomerChange(val) {
        // console.log("val->>", val)
        let newVal = val.map(v => v.key);
        const params = {
            "filters": {
                'entityType': 'CUSTOMER',
                'entityIds': newVal
            }
        }
        this.props.getCustomerLocations(params)
    }

    getMappedData(source, dest) {
        const list = {};
        if (source) {
            source.map((val) => {
                dest.map((v, k) => {
                    if (v == val["id"]) {
                        if (!list[val["entityId"]]) list[val["entityId"]] = [];
                        list[val["entityId"]].push(v)
                    }
                })
            })
        }


        return list;
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('accountNumber')) {
            callback('bank account number that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmAccountNumber'], { force: true });
        }
        callback();
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };

        const { getFieldDecorator } = this.props.form;
        const { Option } = Select;

        return (
            <>

                <Form {...formItemLayout}>
                    <Spin spinning={this.props.vendorUpdateLoading || this.props.vendorCreateLoading}>
                        <Row gutter={12}>
                            <h2><Icon type="arrow-left" onClick={() => this.goBack()} />  Vendor Management</h2>
                            <Col span={14}>
                                <Card>
                                    <Form.Item label="Vendor Name">
                                        {getFieldDecorator('name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input vendor name!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Enter vendor name"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Phone number">
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('contactNumber', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input vendor number!',
                                                    },
                                                    {
                                                        pattern: validationRules.mobile,
                                                        message: "Please enter valid mobile number"

                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Phone number"
                                                />
                                            )}
                                        </div>
                                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('secondaryContactNumber', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: "Please enter something"
                                                    },
                                                    {
                                                        pattern: validationRules.mobile,
                                                        message: "Please enter valid mobile number"

                                                    }
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Secondary number"
                                                />
                                            )}
                                        </div>
                                    </Form.Item>
                                    <Form.Item label="Email" >

                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'Please input valid email!',
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input email!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Enter Email ID"
                                                />
                                            )}
                                        </div>
                                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('secondaryEmail')(
                                                <Input
                                                    placeholder="Secondary Email"
                                                />
                                            )}
                                        </div>


                                    </Form.Item>
                                    <Form.Item label="Location Name" >
                                        {getFieldDecorator('locationname', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input location name!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Location name"
                                            />
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Head Office Address">
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('address1', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input address!',
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="Address1"
                                                />
                                            )}
                                        </div>
                                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('address2')(
                                                <Input
                                                    placeholder="Address2"
                                                />
                                            )}
                                        </div>

                                    </Form.Item>
                                    <Form.Item label="Landmark">
                                        {getFieldDecorator('landmark', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input Landmark!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Landmark"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="City">
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('city', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select city',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select City"
                                                    showSearch
                                                    onChange={this.changeCity}
                                                    loading={this.props.cityloading}
                                                    filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {this.props?.cities?.map(city => (
                                                        <Select.Option
                                                            key={city.id}
                                                            value={city.id}
                                                            label={city.name}
                                                        >
                                                            {city.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </div>
                                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                            {getFieldDecorator('pincode', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select pincode',
                                                    },
                                                ],
                                            })(
                                                <Select
                                                    placeholder="Select Pincode"
                                                    showSearch
                                                    loading={this.props.pincodeloading}
                                                    filterOption={(input, option) =>
                                                        option.props.children.indexOf(input) >= 0
                                                    }
                                                >
                                                    {this.state?.pincodes?.map(pincode => (
                                                        <Select.Option
                                                            key={pincode}
                                                            value={pincode}
                                                            label={pincode}
                                                        >
                                                            {pincode}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </div>


                                    </Form.Item>
                                    <Form.Item label="Contact Person">
                                        {getFieldDecorator('contactPerson', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "contact person can't be blank"
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="Contact Person"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <>
                                                <span>
                                                    GSTIN {" "}
                                                    <Tooltip
                                                        title="E-waybills can be transferred to this GSTIN during the start of coloader trips."
                                                        placement="rightTop"
                                                    >
                                                        <Icon type="info-circle" />
                                                    </Tooltip>
                                                </span>
                                            </>
                                        }
                                    >
                                        <Switch checkedChildren="Yes" disabled={this.state.disableGST} unCheckedChildren="No" checked={this.state.gstcheck} onChange={this.changeGST} /> <span>GSTIN Applicable</span>

                                        <Form.Item>
                                            {getFieldDecorator('gst', {
                                                rules: [
                                                    {
                                                        validator: async (rule, value) => {
                                                            if (this.state.gstcheck) {
                                                                if (!value) {
                                                                    throw new Error("Field can't be blank");
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        pattern: validationRules.gstNumber,
                                                        message: "Please enter valid GSTIN number"

                                                    }
                                                ]
                                            })(

                                                <Input
                                                    placeholder="Enter GSTIN"
                                                    disabled={!this.state.gstcheck}
                                                />
                                            )}

                                        </Form.Item>



                                    </Form.Item>
                                    <Form.Item label="Vendor Type" >
                                        {getFieldDecorator('vendorTypes', {
                                            initialValue: this.state.initialVendors
                                        })(
                                            <Select mode="multiple" labelInValue={true} placeholder="Select Vendor Type" showArrow>
                                                {vTypes.map(vendor => (
                                                    <Select.Option

                                                        key={vendor.value}
                                                        disabled={vendor.disabled}
                                                    >
                                                        {vendor.name}
                                                    </Select.Option>
                                                ))}

                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Collapse>
                                        <Panel header="Advanced" key="1">
                                            <Form.Item label="Partner" >
                                                {getFieldDecorator('partnername')(
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Select Partners"
                                                        showArrow
                                                        labelInValue
                                                        onChange={this.onPartnerChange}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.props?.partnerList?.map(partner => (
                                                            <Select.Option
                                                                key={partner.linkPartnerBO.id}
                                                                value={partner.linkPartnerBO.id}
                                                                label={partner.linkPartnerBO.name}
                                                            >
                                                                {partner.linkPartnerBO.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Partner Locations" >
                                                {getFieldDecorator('partnerlocations')(
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Select Partner location"
                                                        showArrow
                                                        labelInValue
                                                        loading={this.props.partnerLocationLoading}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.props?.partnerLocationDetails?.map(location => (
                                                            <Select.Option
                                                                key={location.id}
                                                                value={location.id}
                                                                label={location.name}
                                                            >
                                                                {location.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Customer" >
                                                {getFieldDecorator('customername')(
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Select Customers"
                                                        showArrow
                                                        labelInValue
                                                        onChange={this.onCustomerChange}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.props?.customerList?.map(customer => (
                                                            <Select.Option
                                                                key={customer.id}
                                                                value={customer.id}
                                                                label={customer.name}
                                                            >
                                                                {customer.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Customer Locations" >
                                                {getFieldDecorator('customerlocations')(
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Select Customer locations"
                                                        showArrow
                                                        labelInValue
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {this.props?.customerLocations?.map(location => (
                                                            <Select.Option
                                                                key={location.id}
                                                                value={location.id}
                                                                label={location.name}
                                                            >
                                                                {location.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )}
                                            </Form.Item>                                                                                                                        </Panel>
                                    </Collapse>

                                </Card>
                            </Col>

                            <Col span={10}>
                                <Card>
                                    <h3>KYC Uploads</h3>
                                    <p>Upload only duly filled & signed forms. We supoort only .jpg & pdf formats</p>
                                    <Form.Item label="Account number" >
                                        {getFieldDecorator('accountNumber', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input your bank account number!',
                                                },
                                                {
                                                    validator: this.validateToNextPassword,
                                                },
                                            ]
                                        })(
                                            <Input
                                                type="password"
                                                placeholder="Bank Account number"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Confirm Account number" >
                                        {getFieldDecorator('confirmAccountNumber', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input your bank account number!',
                                                },
                                                {
                                                    validator: this.compareToFirstPassword,
                                                },
                                            ]
                                        })(
                                            <Input
                                                placeholder="Bank Account number"
                                                onBlur={this.handleConfirmBlur}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="IFSC" >
                                        {getFieldDecorator('ifscCode', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input your bank ifsc number!',
                                                },
                                                // {
                                                //     pattern: validationRules.ifsc,
                                                //     message: "Please enter valid IFSC"

                                                // }
                                            ],
                                        })(
                                            <Input
                                                placeholder="IFSC"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Bank Name" >
                                        {getFieldDecorator('bankName', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input your bank name!',
                                                }
                                            ],
                                        })(
                                            <Input
                                                placeholder="Bank Name"
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Branch Name" >
                                        {getFieldDecorator('bankBranchName', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Please input your bank branch name!',
                                                }
                                            ]
                                        })(
                                            <Input
                                                placeholder="Branch Name"
                                            />
                                        )}
                                    </Form.Item>
                                    {_.hasIn(this.props.configurations, 'vendor_kyc_enable') && this.props.configurations.vendor_kyc_enable === 'true' &&
                                        <>
                                            <Form.Item label="Bank Proof" >
                                                {getFieldDecorator('bankproof')(
                                                    <>
                                                        <FileUpload
                                                            acceptTypes={this.state.acceptTypes}
                                                            directUpload={true}
                                                            disableUploadButton={true}
                                                            onUploadFinish={($events, name) => this.onUploadFinish($events, "bankproof", name)}
                                                            path="vendor"
                                                            objKey="vendorcreate"
                                                            clearFileAfterUpload={false}
                                                        />
                                                        <span>{this.state.bankFile ? this.state.bankFile : ''}</span>
                                                    </>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="PAN Number" >
                                                {getFieldDecorator('pan', {
                                                    rules: [
                                                        {
                                                            pattern: validationRules.pan,
                                                            message: "Please enter valid PAN number"

                                                        }
                                                    ],
                                                })(
                                                    <Input
                                                        placeholder="PAN"
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="PAN Copy" >
                                                {getFieldDecorator('panproof')(
                                                    <>
                                                        <FileUpload
                                                            acceptTypes={this.state.acceptTypes}
                                                            directUpload={true}
                                                            disableUploadButton={true}
                                                            onUploadFinish={($events, name) => this.onUploadFinish($events, "panproof", name)}
                                                            path="vendor"
                                                            objKey="vendorcreate"
                                                            clearFileAfterUpload={false}
                                                        />
                                                        <span>{this.state.panFile ? this.state.panFile : ''}</span>
                                                    </>
                                                )}
                                            </Form.Item>

                                            {
                                                this.state.gstcheck ?
                                                    <Form.Item label="GST Proof" >
                                                        {getFieldDecorator('gstproof')(
                                                            <>
                                                                <FileUpload
                                                                    acceptTypes={this.state.acceptTypes}
                                                                    directUpload={true}
                                                                    disableUploadButton={true}
                                                                    onUploadFinish={($events, name) => this.onUploadFinish($events, "gstproof", name)}
                                                                    path="vendor"
                                                                    objKey="vendorcreate"
                                                                    clearFileAfterUpload={false}
                                                                />
                                                                <span>{this.state.gstFile ? this.state.gstFile : ''}</span>
                                                            </>
                                                        )}
                                                    </Form.Item>
                                                    : <></>
                                            }
                                            <Form.Item label="TDS Number" >
                                                {getFieldDecorator('tds', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "TDS number can't be blank",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        placeholder="TDS Number"
                                                    />
                                                )}
                                            </Form.Item>
                                            <Form.Item label="TDS Proof" >
                                                {getFieldDecorator('tdsproof')(
                                                    <>
                                                        <FileUpload
                                                            acceptTypes={this.state.acceptTypes}
                                                            directUpload={true}
                                                            disableUploadButton={true}
                                                            onUploadFinish={($events, name) => this.onUploadFinish($events, "tdsproof", name)}
                                                            path="vendor"
                                                            objKey="vendorcreate"
                                                            clearFileAfterUpload={false}
                                                        />
                                                        <span>{this.state.tdsFile ? this.state.tdsFile : ''}</span>
                                                    </>
                                                )}
                                            </Form.Item>
                                            <Form.Item label="Vendor Contract" >
                                                {getFieldDecorator('vendorcontract')(
                                                    <>
                                                        <FileUpload
                                                            acceptTypes={this.state.acceptTypes}
                                                            directUpload={true}
                                                            disableUploadButton={true}
                                                            onUploadFinish={($events, name) => this.onUploadFinish($events, "vendorcontract", name, "multiple", "VENDOR_CONTRACT")}
                                                            path="vendor"
                                                            multiple={true}
                                                            objKey="vendorcreate"
                                                            showUploadList
                                                            clearFileAfterUpload={false}
                                                        />
                                                        <span>{this.state.vendorFiles.length > 0 ? this.state.vendorFiles.map(v => <>{v}<br /></>) : ''}</span>
                                                    </>
                                                )}
                                            </Form.Item>
                                        </>
                                    }
                                </Card>

                            </Col>





                        </Row>
                        <Row>
                            <Card>
                                <Spin spinning={this.props.vendorUpdateLoading || this.props.vendorCreateLoading}>
                                    <Row type="flex" justify="end">
                                        <Col span={4}>
                                            <Button size="large" onClick={() => this.goBack()}>CANCEL</Button>
                                        </Col>
                                        <Col span={4}>
                                            {this.state.isEdit ?
                                                <Button size="large" loading={this.props.vendorUpdateLoading} disabled={this.props.vendorUpdateLoading} type="primary" onClick={() => this.handleUpdate()}>UPDATE</Button> :
                                                <Button size="large" loading={this.props.vendorCreateLoading} disabled={this.props.vendorCreateLoading} type="primary" onClick={() => this.handleSubmit()}>CREATE</Button>
                                            }

                                        </Col>
                                    </Row>
                                </Spin>
                            </Card>
                        </Row>
                    </Spin>
                </Form>
            </>


        )
    }
}

const mapStateToProps = state => ({
    customerList: state.ingest.customerList,
    partnerList: state.header?.partnerHeaderDetails?.partners,
    locationList: state.ingest.locationList,
    partnerLocationDetails: state.vendor?.partnerLocationDetails,
    customerLocations: state.vendor?.customerLocationDetails?.locations,
    cities: state.vendor.cities,
    allPincodes: state.vendor.allPincodes,
    pincodeDetails: state.vendor.pincodeDetails,
    partnerLocationLoading: state.vendor?.partnerLocationLoading,
    cityloading: state.vendor?.cityloading,
    pincodeloading: state.vendor?.pincodeloading,
    vendorCreateResp: state.vendor?.vendorDetails,
    vendorData: state.vendor?.vendorData,
    vendorUpdateData: state.vendor?.vendorUpdateData,
    vendorCreateLoading: state?.vendor?.vendorCreateLoading ? state?.vendor?.vendorCreateLoading : false,
    vendorUpdateLoading: state?.vendor?.vendorUpdateLoading ? state?.vendor?.vendorUpdateLoading : false,
    configurations: state?.app?.configurations,
});

const mapDispatchToProps = dispatch => ({
    setS3SyncStatus: (status) => dispatch(appActions.setS3SyncStatus(status)),
    getCustomerList: () => dispatch(actions.getCustomerList()),
    getPartnerLocations: (params) => dispatch(vendorActions.getPartnerLocatinDetailsV2(params)),
    getCustomerLocations: (params) => dispatch(vendorActions.fetchCustomerLocatinDetails(params)),
    getLocationList: (val) => dispatch(actions.getLocationList(val)),
    getCustomApiCall: (url, method, payload) => dispatch(actions.customUrlApiCall(url, method, payload)),
    getEmplyeesList: () => dispatch(actions.getEmplyeesList()),
    getCities: () => dispatch(vendorActions.fetchCities()),
    getPincodes: () => dispatch(vendorActions.fetchPincodes()),
    createVendor: (params) => dispatch(vendorActions.createVendor(params)),
    updateVendor: (params, id) => dispatch(vendorActions.updateVendor(params, id)),
    getVendorData: (id) => dispatch(vendorActions.getVendorData(id)),
    setInitialState: () => dispatch(vendorActions.setInitialState()),

});


export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'vendorCreate' })(VendorCreate));