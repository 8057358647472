import React from 'react'
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
const pinkicon = L.icon({
    iconUrl:
      "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
    iconSize: [30, 30],
    iconAnchor: [10, 30],
  });
  
class MapToFleet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        lat: 12.934186,
        lng: 77.629908,
        maxZoom: 18,
        minZoom: 0,
        zoom: 5,
        draggable: true,
        latBox: null,
        lngBox: null,
      }
    this.fleetMap = React.createRef()
  }

  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable })
  }

  getLatlogMarker = (e) => {
      this.props.mapData(e.latlng.lat, e.latlng.lng);
      this.setState({latBox : e.latlng.lat, lngBox: e.latlng.lng});
  }


  render() {
    return (
      <LeafletMap
        maxZoom={18}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        center={[this.state.lat, this.state.lng]} 
        zoom={this.state.zoom}
        dragging={true}
        animate={true}
        ref={ref => (this.fleetMap = ref)}
        attributionControl={false}
        easeLinearity={0.35}
        onClick={this.getLatlogMarker}
      >
        <TileLayer
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
        />
        {this.state.latBox && <Marker position={[this.state.latBox, this.state.lngBox]} icon={pinkicon}>
        </Marker>}
      </LeafletMap>
    );
  }
}

export default MapToFleet