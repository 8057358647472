import React from 'react';
import { Button } from 'antd';
import './Button.scss';

export const button = (props) => {
  return (
    <Button
      {...props}
    >{props.children}</Button>
  )
}
