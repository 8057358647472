import React, { Component } from 'react';
import { Card, Row, Col, Select, Tag, Table, Empty, Input, Tooltip, Icon, Button, Modal, Alert, Radio, Spin, Popover, Checkbox } from 'antd';
import './kyc.scss';
import * as actions from './action';
import { connect } from 'react-redux';
import * as utils from "../../../common/utils/utils";
import moment from 'moment';
import { canAllow } from '../../../common/utils/utils';
import { USER_LOGGEDIN_INFO } from '../../../common/utils/constants';
import { permissionTags } from '../../../configs/permissionTags';
import aesjs from 'aes-js';
import SuperSelect from "../../ui/SuperSelect/SuperSelect";
const Option = Select.Option;


const colorSet = {
  "NOT_SUBMITTED": "red",
  "VERIFICATION_PENDING": "blue",
  "VERIFIED": "green",
  "REJECTED": "volcano",
  "ALL": "geekblue"
}

const typeSet = {
  "NOT_SUBMITTED": "Not Submitted",
  "VERIFICATION_PENDING": "Verification Pending",
  "VERIFIED": "Verified",
  "REJECTED": "Rejected",
  "ALL": "All"
}

const checklistMap = {
  "BANK_ACCOUNT_NUMBER": 3,
  "IFSC_CODE": 4,
  "ADHAR_CARD_NUMBER": 5,
  "DRIVERS_LICENCE": 6,
  "USER_ADDITIONAL_PERSONAL_DETAILS": 0,
  "USER_CONTACT_REFERENCES": 1,
  "PAN_CARD": 2,
}

const namesMap = {
  "BANK_ACCOUNT_NUMBER": 'Bank Account',
  "IFSC_CODE": 'Ifsc Code',
  "ADHAR_CARD_NUMBER": 'Adhar',
  "DRIVERS_LICENCE": 'Drivers License',
  "USER_ADDITIONAL_PERSONAL_DETAILS": 'Father’s Name',
  "USER_CONTACT_REFERENCES": 'Reference',
  "PAN_CARD": 'PAN Card',
}

class KycDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      pageNo: 1,
      pageSize: 10,
      PaginatedKycsummaryData: {},
      locationIds: [],
      objectData: {},
      checkList: [
        { is0Verified: false, is0notVerified: false },
        { is1Verified: false, is1notVerified: false },
        { is2Verified: false, is2notVerified: false },
        { is3Verified: false, is3notVerified: false },
        { is4Verified: false, is4notVerified: false },
        { is5Verified: false, is5notVerified: false },
        { is6Verified: false, is6notVerified: false },
      ],
      statusValue: {
        "NOT_SUBMITTED": 0,
        "VERIFICATION_PENDING": 0,
        "VERIFIED": 0,
        "REJECTED": 0,
        "ALL": 0
      },
      kycPermission: {
        read: canAllow(permissionTags.manage_fleet.kyc.read),
        update: canAllow(permissionTags.manage_fleet.kyc.update),
        create: canAllow(permissionTags.manage_fleet.kyc.create),
        delete: canAllow(permissionTags.manage_fleet.kyc.delete),
        view: canAllow(permissionTags.manage_fleet.kyc.view),
      },
    };
    this.InputSearchRef = React.createRef();
  }

  componentDidMount() {
    this.props.getCityList();
    // this.decrypt2();
    this.totalfilter(["ALL", "NOT_SUBMITTED", "VERIFICATION_PENDING", "REJECTED", "VERIFIED"], 1);
    this.setState(state => ({
      columns: [
        {
          title: 'Name',
          dataIndex: 'userName',
          key: 'userName',
        },
        {
          title: 'Contact',
          dataIndex: 'contactNumber',
          key: 'contactNumber',
        },
        {
          title: 'Joined',
          dataIndex: 'dateOfJoin',
          key: 'dateOfJoin',
          render: (data, row) => (<div>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> <small>{moment(data).fromNow()}</small></div>),
        },
        {
          title: 'Status',
          key: 'kycStatus',
          dataIndex: 'kycStatus',
          render: (data, row) => (
            <Row>
              <Tag color={colorSet[data]} key={data}>{typeSet[data]}</Tag>
            </Row >)
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'action',
          render: (data, row) => (
            <Row>
              {
                (data.kycStatus === "VERIFICATION_PENDING") &&
                <Col span={12}>
                  <Tooltip placement="top" title={'Edit'}>
                    <Icon type="edit" onClick={() => this.showModal(row.userId, 'edit')} />
                  </Tooltip>
                </Col>
              }
              {
                (data.kycStatus !== "VERIFICATION_PENDING") &&
                <Col span={12}><Tooltip placement="top" title={'View'}>
                  <Icon type="eye" onClick={() => this.showModal(row.userId, 'view')} /></Tooltip>
                </Col>
              }
            </Row >
          )
        }
      ]
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cityList !== this.props.cityList) {
      this.setState({ cityList: this.props.cityList });
    }

    if (prevProps.cityListById !== this.props.cityListById) {
      this.setState({ cityListById: this.props.cityListById });
    }

    if (prevProps.userData !== this.props.userData) {
      if (this.props.userData.identityDocuments.length < 5 && this.props.userData.identityDocuments.length !== 0) {
        let userdataUsage = [];
        userdataUsage = this.improveData(this.props.userData);
        if (userdataUsage) {
          this.applychecksOnData(userdataUsage)
        }
        this.setState({ userData: userdataUsage, visible: true });
      } else {
        if (this.props.userData.identityDocuments.length > 0) {
          this.applychecksOnData(this.props.userData)
        }
        this.setState({ userData: this.props.userData, visible: true });
      }
    }

    if (prevState.userData !== this.state.userData) {
      this.getdynamicListing();
    }

    if (prevProps.kycSummaryData !== this.props.kycSummaryData) {
      if (this.props.kycSummaryData) {
        this.getSelectedValues(this.props.kycSummaryData, true)
      } else {
        this.getSelectedValues(this.props.kycSummaryData, false)
      }
      this.totalfilter(["ALL", "NOT_SUBMITTED", "VERIFICATION_PENDING", "REJECTED", "VERIFIED"], 1);
    }

    if (this.props.PaginatedKycsummaryData !== prevProps.PaginatedKycsummaryData) {
      let PaginatedKycsummaryData = {};
      PaginatedKycsummaryData[this.props.kycData.currentPageNo] = this.props.PaginatedKycsummaryData
      this.setState({
        PaginatedKycsummaryData: this.props.PaginatedKycsummaryData,
      })
    }

  }

  improveData = (data) => {
    let dataset = [{ documentType: "USER_ADDITIONAL_PERSONAL_DETAILS", isActive: false, isVerified: false, id: 1 }, { documentType: "USER_CONTACT_REFERENCES", isActive: false, isVerified: false, id: 2 }, { documentType: "PAN_CARD", isActive: false, isVerified: false, id: 3 }].concat(data.identityDocuments);
    data.identityDocuments = dataset;
    return data;
  }

  getSelectedValues = (data, type) => {
    let statusValue = {};
    if (type === false) {
      statusValue = {
        "NOT_SUBMITTED": 0,
        "VERIFICATION_PENDING": 0,
        "VERIFIED": 0,
        "REJECTED": 0,
        "ALL": 0
      }
    } else {
      data.map(x => {
        if (x.tagName) {
          statusValue[x.tagName] = x.value;
        }
      })
    }
    this.setState({ statusValue: statusValue })
  }

  applychecksOnData = (userdataValue) => {
    let newCheckList = [...this.state.checkList];
    newCheckList.map((x, key) => {
      newCheckList[key][`is${key}Verified`] = userdataValue?.identityDocuments[key]?.isVerified;
    })
    this.setState({ checkList: newCheckList });
  }

  showModal = (id, type) => {
    this.props.getUserDetails(id);
    this.setState({
      enableBtn: false,
      buttonBox: type,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    const boxcheckList = [
      { is0Verified: false, is0notVerified: false },
      { is1Verified: false, is1notVerified: false },
      { is2Verified: false, is2notVerified: false },
      { is3Verified: false, is3notVerified: false },
      { is4Verified: false, is4notVerified: false },
      { is5Verified: false, is5notVerified: false },
      { is6Verified: false, is6notVerified: false },
    ]
    this.setState({
      visible: false,
      checkList: boxcheckList,
    });
  };

  getdynamicListing = () => {
    const { userData } = this.state;
    const jsxArray = [];
    if (userData && userData?.kycStatus !== "NOT_SUBMITTED") {
      if (userData && userData?.identityDocuments.length > 0) {
        userData.identityDocuments.forEach((val, index) => {
          switch (val?.documentType) {
            case "USER_ADDITIONAL_PERSONAL_DETAILS": {
              jsxArray.push((
                <Col span={24} key={val.id} className="tableRow">
                  <Col span={16} className="tableCol">
                    <div className="titles">{namesMap[val?.documentType]}: </div>
                    <div className="title_desc">{val?.documentValue ? this.decryptObject(val?.documentValue)?.fathersName : "Not Submitted"}</div>
                  </Col>
                  <Col span={6} className="radioArrageSet">
                    <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                    <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                  </Col>
                </Col>
              ))
              break;
            }
            case "USER_CONTACT_REFERENCES": {
              jsxArray.push((
                <Col span={24} key={val.id} className="tableRow2">
                  <Col span={16} className="tableCol">
                    <Col span={24}>
                      <div className="titles">Reference 1: </div>
                      <div className="title_desc">{val?.documentValue ? this.decryptObject(val?.documentValue)?.referenceName1 : "Not Submitted"}</div>
                      <div className="titles">Reference Number 1: </div>
                      <div className="title_desc">{val?.documentValue ? this.decryptObject(val?.documentValue)?.referenceNumber1 : "Not Submitted"}</div>
                    </Col>
                    <Col span={24}>
                      <div className="titles">Reference 2: </div>
                      <div className="title_desc">{val?.documentValue ? this.decryptObject(val?.documentValue)?.referenceName2 : "Not Submitted"}</div>
                      <div className="titles">Reference Number 2: </div>
                      <div className="title_desc">{val?.documentValue ? this.decryptObject(val?.documentValue)?.referenceNumber2 : "Not Submitted"}</div>
                    </Col>
                  </Col>
                  <Col span={6} className="radioArrage2">
                    <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                    <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                  </Col>
                </Col>
              ))
              break;
            }
            case "PAN_CARD": {
              jsxArray.push((
                <Col span={24} key={val.id} className="tableRow">
                  <Col span={16} className="tableCol">
                    <div className="titles"> {namesMap[val?.documentType]}: </div>
                    <div className="title_desc">{this.decryptObject(val?.documentValue)?.submitted ? this.decryptObject(val?.documentValue)?.PAN : "User does not have PAN Card"}  <Popover content={this.getbankDoc(val?.s3URL, 0)} trigger="click"><Icon type="file-image" style={{ color: '#2f54eb', marginLeft: 15 }} /> {val?.s3URL ? "" : <Icon className="imageIcon" type="exclamation" />}</Popover></div>
                  </Col>
                  <Col span={6} className="radioArrageSet">
                    <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                    <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                  </Col>
                </Col>
              ))
              break;
            }
            case "BANK_ACCOUNT_NUMBER": {
              if (this.state.checkList?.length > index) {
                jsxArray.push((
                  <Col span={24} key={val.id} className="tableRow">
                    <Col span={16} className="tableCol">
                      <div className="titles">{namesMap[val?.documentType]}: </div>
                      <div className="title_desc">{val?.documentValue ? this.decrypt(val?.documentValue) : "Not Submitted"} <Popover content={this.getbankDoc2(val?.s3URL)} trigger="click"><Icon type="file-image" style={{ color: '#2f54eb', marginLeft: 15 }} /> {val?.s3URL ? "" : <Icon className="imageIcon" type="exclamation" />}</Popover></div>
                    </Col>
                    <Col span={6} className="radioArrageSet">
                      <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                      <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                    </Col>
                  </Col>
                ))
              }

              break;
            }
            case "IFSC_CODE": {
              if (this.state.checkList?.length > index) {
                jsxArray.push((
                  <Col span={24} key={val.id} className="tableRow">
                    <Col span={16} className="tableCol">
                      <div className="titles">{namesMap[val?.documentType]}: </div>
                      <div className="title_desc">{val?.documentValue ? this.decrypt(val?.documentValue) : "Not Submitted"}</div>
                    </Col>
                    <Col span={6} className="radioArrageSet">
                      <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                      <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                    </Col>
                  </Col>
                ))
              }
              break;
            }
            case "ADHAR_CARD_NUMBER": {
              jsxArray.push((
                <Col span={24} key={val.id} className="tableRow">
                  <Col span={16} className="tableCol">
                    <div className="titles">{namesMap[val?.documentType]}: </div>
                    {this.state?.userData && <div className="title_desc">{val?.documentValue ? this.decrypt(val?.documentValue) : "Not Submitted"}  <Popover content={this.getbankDoc(val?.s3URL, 0)} trigger="click"><Icon type="file-image" style={{ color: '#2f54eb', marginLeft: 15 }} /> {val?.s3URL ? "" : <Icon className="imageIcon" type="exclamation" />}</Popover> <Popover content={this.getbankDoc(val?.s3URL, 1)} trigger="click"><Icon type="file-image" style={{ color: '#2f54eb', marginLeft: 15 }} /> {val?.s3URL ? "" : <Icon className="imageIcon" type="exclamation" />}</Popover></div>}
                  </Col>
                  <Col span={6} className="radioArrageSet">
                    <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                    <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                  </Col>
                </Col>
              ))
              break;
            }
            case "DRIVERS_LICENCE": {
              jsxArray.push((
                <Col span={24} key={val.id} className="tableRow">
                  <Col span={16} className="tableCol">
                    <div className="titles">{namesMap[val?.documentType]}: </div>
                    <div className="title_desc">{val?.documentValue ? this.decrypt(val?.documentValue) : "Not Submitted"} <Popover content={this.getbankDoc(val?.s3URL, 0)} trigger="click"><Icon type="file-image" style={{ color: '#2f54eb', marginLeft: 15 }} /> {val?.s3URL ? "" : <Icon className="imageIcon" type="exclamation" />} </Popover> <Popover content={this.getbankDoc(val?.s3URL, 1)} trigger="click"><Icon type="file-image" style={{ color: '#2f54eb', marginLeft: 15 }} /> {val?.s3URL ? "" : <Icon className="imageIcon" type="exclamation" />}</Popover></div>
                  </Col>
                  <Col span={6} className="radioArrageSet">
                    <Checkbox checked={this.state.checkList[index][`is${index}Verified`]} onChange={(e) => this.onChangeCheckList(index, "v", e)}><Icon type="check" /></Checkbox>
                    <Checkbox checked={this.state.checkList[index][`is${index}notVerified`]} onChange={(e) => this.onChangeCheckList(index, "nv", e)}><Icon type="close" /></Checkbox>
                  </Col>
                </Col>
              ))
              break;
            }
            default: {
              break;
            }
          }
        })
      }
    } else {
      jsxArray.push((
        <Col span={24} key={-122}>
          <Alert message="Document has not been submitted" type="error" />
        </Col>
      ))
    }
    return jsxArray;
  };

  onLocationChange = (e) => {
    if (e) {
      let statusValue = {
        "NOT_SUBMITTED": 0,
        "VERIFICATION_PENDING": 0,
        "VERIFIED": 0,
        "REJECTED": 0,
        "ALL": 0
      }
      this.props.getCityListById(e);
      this.setState({ locationIds: [], PaginatedKycsummaryData: {}, statusValue: statusValue })
    } else {
      this.setState({ locationIds: [] })
    }
  }

  onpartnerChange = (e) => {
    if (e) {
      this.props.getkycSummary({
        "locationIds": e
      });
      this.setState({ locationIds: e })
    } else {
      this.setState({ locationIds: [] })
    }
  }

  totalfilter = (data, key) => {
    let payload = {
      "locationIds": this.state.locationIds,
      "kycStatuses": data,
      "pageNo": 1,
      "pageSize": 10
    }
    if (this.state.locationIds.length > 0) {
      this.props.getkycSummarypagination(payload, true);
    }
    this.setState({ kycStatus: data, selectedButton: key })
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedKycsummaryData } = this.props;
    if (!PaginatedKycsummaryData ? !PaginatedKycsummaryData : !PaginatedKycsummaryData[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  applyPagination = (pageNo, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    let payload = {
      "locationIds": this.state.locationIds,
      "kycStatuses": this.state.kycStatus,
      "pageSize": 10,
    }
    if (pageNo.partialContentSearch) {
      payload.searchKey = pageNo.partialContentSearch;
    } else {
      payload.pageNo = pageNo.pageNo;
    }
    if (this.state.locationIds.length > 0) {
      this.props.getkycSummarypagination(payload, isReset);
    }
  }

  getbankDoc = (data = '', key) => {
    if (data) {
      let dataset = this.decrypt(data).split(",")[key];
      return (<div className="setImageBox"><img className="popoverImage" src={dataset} alt="Details"></img></div>);
    }
  }

  getbankDoc2 = (data = '') => {
    if (data) {
      let dataset = this.decrypt(data);
      return (<div className="setImageBox"><img className="popoverImage" src={dataset} alt="Details"></img></div>);
    }
  }

  onChangeCheckList = (data, type, e) => {
    let buttonEnabler;
    let enabbler = [];
    let newCheckList = [...this.state.checkList];
    if (type === "v") {
      newCheckList[data][`is${data}Verified`] = e.target.checked;
      newCheckList[data][`is${data}notVerified`] = false;
    } else {
      newCheckList[data][`is${data}notVerified`] = e.target.checked;
      newCheckList[data][`is${data}Verified`] = false;
    }
    newCheckList.map((x, key) => {
      if ([3, 4, 5, 6].includes(key)) {
        if (x[`is${key}Verified`] === false) {
          enabbler.push(x);
        }
      }
    })
    if (enabbler.length > 0) {
      buttonEnabler = false
    } else {
      buttonEnabler = true
    }
    this.setState({ checkList: newCheckList, enableBtn: buttonEnabler })
  }

  approveRejectUser = (data) => {
    const { userData } = this.props;
    let checkList = [...this.state.checkList];
    let identityDocuments = [];
    userData.identityDocuments.map(x => {
      if (x.id) {
        let createObject = {
          id: x.id,
          documentType: x.documentType,
          documentValue: x.documentValue,
          isVerified: checkList[checklistMap[x.documentType]][`is${checklistMap[x.documentType]}Verified`],
        }
        if (x.documentType !== "IFSC_CODE") {
          createObject.s3URL = x.s3URL;
        }
        identityDocuments.push(createObject);
      }
    })
    let payload = {
      "action": "UPDATE",
      "entityId": userData?.entityId,
      "entityType": userData?.entityType,
      "user": {
        "id": userData?.user?.id,
        "dob": userData?.user?.dob,
        "address": {
          "id": userData?.user?.address?.id,
        },
        "profilePicture": userData?.user?.profilePicture,
      },
      "kycStatus": data,
      "identityDocuments": identityDocuments
    }
    this.props.userApproval(payload, this.state.locationIds);
    const boxcheckList = [
      { is0Verified: false, is0notVerified: false },
      { is1Verified: false, is1notVerified: false },
      { is2Verified: false, is2notVerified: false },
      { is3Verified: false, is3notVerified: false },
      { is4Verified: false, is4notVerified: false },
      { is5Verified: false, is5notVerified: false },
      { is6Verified: false, is6notVerified: false },
    ]
    this.setState({ visible: false, checkList: boxcheckList });
  }

  hexToBase64 = (hexstring) => {
    return btoa(hexstring.match(/\w{2}/g).map(function (a) {
      return String.fromCharCode(parseInt(a, 16));
    }).join(""));
  }

  base64ToHex = (str) => {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }


  encrypt = (message = '') => {
    let keys = localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).substr(localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).length - 16);
    let key = aesjs.utils.utf8.toBytes(keys);
    let str2 = (16 - ((message.length) % 16)) + message.length;
    let textbox = message.padEnd(str2, '_');
    let textBytes = aesjs.utils.utf8.toBytes(textbox);
    let aesEcb = new aesjs.ModeOfOperation.ecb(key);
    let encryptedBytes = aesEcb.encrypt(textBytes);
    let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return this.hexToBase64(encryptedHex);
  }

  decrypt = (message = '') => {
    let keys = localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).substr(localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).length - 16);
    let encryptedHex = this.base64ToHex(message);
    let key = aesjs.utils.utf8.toBytes(keys);
    let aesEcb = new aesjs.ModeOfOperation.ecb(key);
    let encryptedBytess = aesjs.utils.hex.toBytes(encryptedHex);
    let decryptedBytes = aesEcb.decrypt(encryptedBytess);
    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return decryptedText;
  }

  decryptObject = (message = '') => {
    let keys = localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).substr(localStorage.getItem(USER_LOGGEDIN_INFO.TOKEN_ID).length - 16);
    let encryptedHex = this.base64ToHex(message);
    let key = aesjs.utils.utf8.toBytes(keys);
    let aesEcb = new aesjs.ModeOfOperation.ecb(key);
    let encryptedBytess = aesjs.utils.hex.toBytes(encryptedHex);
    let decryptedBytes = aesEcb.decrypt(encryptedBytess);
    let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    if (decryptedText) {
      let lastData = JSON.parse(decryptedText.slice(0, decryptedText.lastIndexOf("}") + 1));
      return lastData;
    }
  }

  setLocationEndData = (data) => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push((<Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>));
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  }

  setLocationpartnerData = (data) => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push((<Option key={x.id} value={x.id} label={x.alias}>{x.alias} {x?.entity?.custObject?.name ? <span>({x?.entity?.custObject?.name})</span> : <span></span>}</Option>));
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  }

  render() {
    const { PaginatedKycsummaryData, kycData } = this.props;
    return (
      <>
        <Card
          title="KYC Dashboard"
          className=''
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6}>
              {/* <Select placeholder="City Name" style={{ width: 200 }} showSearch filterOption={(input, option) =>
                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } showArrow onChange={(e) => this.onLocationChange(e)}>
                      {this.state ?.cityList ?.map(loc => (
                        <Select.Option
                          key={loc.id}
                          value={loc.id}
                          label={loc.name}
                        >
                          {loc.name}
                        </Select.Option>
                      ))}
                    </Select> */}
              <SuperSelect
                showSearch
                allowClear
                placeholder="City Name"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.onLocationChange(e)}
                // onSearch={this.onSearchsef}
                style={{ width: 200 }}
              >
                {this.setLocationEndData(this.state?.cityList)}
              </SuperSelect>
            </Col>
            <Col className="gutter-row" span={10}>
              {/* <Select placeholder="Location (Partner Name)" value={this.state.locationIds} mode="multiple" style={{ width: 320 }} showSearch filterOption={(input, option) =>
                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } showArrow onChange={(e) => this.onpartnerChange(e)}>
                      {this.state ?.cityListById ?.map(loc => (
                        <Select.Option
                          key={loc.id}
                          value={loc.id}
                          label={loc.alias}
                        >
                          {loc ?.alias} ({loc ?.entity ?.custObject ?.name})
                        </Select.Option>
                      ))}
                    </Select> */}
              <SuperSelect
                showSearch
                allowClear
                value={this.state.locationIds}
                mode="multiple"
                placeholder="Location (Partner Name)"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.onpartnerChange(e)}
                style={{ width: 320 }}
              >
                {this.setLocationpartnerData(this.state?.cityListById)}
              </SuperSelect>
            </Col>
            <Col className="gutter-row" span={2}></Col>
            <Col className="gutter-row" span={6}></Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
            {this.state.statusValue && <Col className="gutter-row" span={16}>
              <div className="table-operations">
                <Button type={this.state.selectedButton === 1 ? "primary" : "default"} onClick={() => this.totalfilter(["ALL", "NOT_SUBMITTED", "VERIFICATION_PENDING", "REJECTED", "VERIFIED"], 1)}>All: {this.state.statusValue['ALL']}</Button>
                <Button type={this.state.selectedButton === 2 ? "primary" : "default"} onClick={() => this.totalfilter(["NOT_SUBMITTED"], 2)}>Not Submitted: {this.state.statusValue['NOT_SUBMITTED']}</Button>
                <Button type={this.state.selectedButton === 3 ? "primary" : "default"} onClick={() => this.totalfilter(["VERIFICATION_PENDING"], 3)}>Pending: {this.state.statusValue['VERIFICATION_PENDING']}</Button>
                <Button type={this.state.selectedButton === 4 ? "primary" : "default"} onClick={() => this.totalfilter(["REJECTED"], 4)}>Rejected: {this.state.statusValue['REJECTED']}</Button>
                <Button type={this.state.selectedButton === 5 ? "primary" : "default"} onClick={() => this.totalfilter(["VERIFIED"], 5)}>Verified: {this.state.statusValue['VERIFIED']}</Button>
              </div>
            </Col>}
            <Col span={8}>
              <Input.Search placeholder="Search" allowClear ref={this.InputSearchRef} onSearch={value => this.applyPagination({ partialContentSearch: value }, true)} enterButton />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
            <Col className="gutter-row" span={24}>
              {this.state.PaginatedKycsummaryData && Object.keys(this.state.PaginatedKycsummaryData) > 0 && <Table
                bordered
                locale={{
                  emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                loading={kycData?.loading}
                pagination={{
                  onChange: this.getPaginatedData,
                  total: kycData?.totalCount,
                  position: 'bottom',
                  current: kycData.currentPageNo,
                  pageSize: kycData?.pageSize,
                }}
                dataSource={this.state.PaginatedKycsummaryData ? this.state.PaginatedKycsummaryData[kycData.currentPageNo] : []}
                columns={this.state.columns} />}
            </Col>
          </Row>
          <Row>
            <Modal
              title="Review"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={null}
              width={920}
            >
              <Row>
                <Col span={24}>
                  <Col span={12}>
                    <div className="titles">Name: </div>
                    <div className="title_desc">{this.state?.userData?.user?.name ? this.state?.userData?.user?.name : "Not Submitted"}</div>
                  </Col>
                  <Col span={12}>
                    <div className="titles">Phone Number: </div>
                    <div className="title_desc">{this.state?.userData?.user?.contactNumber ? this.state?.userData?.user?.contactNumber : "Not Submitted"}</div>
                  </Col>
                </Col>
                <Col span={24}>
                  <Col span={12}>
                    <div className="titles">Alternate Phone Number: </div>
                    <div className="title_desc">{this.state?.userData?.user?.secondaryContactNumber ? this.state?.userData?.user?.secondaryContactNumber : "Not Submitted"}</div>
                  </Col>
                  <Col span={12}>
                    <div className="titles">DOB: </div>
                    <div className="title_desc">{this.state?.userData?.user?.dob ? moment(this.state?.userData?.user?.dob).format('DD/MMM/YYYY') : "Not Submitted"}</div>
                  </Col>
                </Col>
                <Col span={24}>
                  <Col span={12}>
                    <div className="titles">Address: </div>
                    <div className="title_desc_address">{this.state?.userData?.user?.address?.address1 ? this.state?.userData?.user?.address?.address1 : "Not Submitted"}</div>
                  </Col>
                  <Col span={12}>
                    <div className="titles"> PIN Code: </div>
                    <div className="title_desc">{this.state?.userData?.user?.address?.pincode?.zipcode ? this.state?.userData?.user?.address?.pincode?.zipcode : "Not Submitted"}</div>
                  </Col>
                </Col>
                {this.state?.userData && this.getdynamicListing()}
                <Col span={24}>
                  <Col span={24} className={this.state.buttonBox === "edit" ? "buttoncontainer" : "buttonHide"}>
                    {this.state.enableBtn && <Button className="item" type="primary" onClick={() => this.approveRejectUser("VERIFIED")}>Approve</Button>}
                    {!this.state.enableBtn && <Button className="item" onClick={() => this.approveRejectUser("REJECTED")} style={{ background: "red", borderColor: "red", color: "#fff" }}>Reject</Button>}
                  </Col>
                </Col>
              </Row>
            </Modal>
          </Row>
        </Card>
      </>
    )
  }
}

const mapStateToProps = state => ({
  kycData: state.manageFleet,
  PaginatedKycsummaryData: state.manageFleet?.PaginatedKycsummaryData,
  kycSummaryData: state.manageFleet?.kycSummary?.data,
  cityList: state.manageFleet?.cityList?.citiesDetails,
  cityListById: state.manageFleet?.locations,
  userData: state.manageFleet?.userData?.data,
});

const mapDispatchToProps = dispatch => ({
  getkycSummary: (data) => dispatch(actions.getkycSummary(data)),
  getCityList: () => dispatch(actions.getCityList()),
  getCityListById: (cityId) => dispatch(actions.getCityListById(cityId)),
  getkycSummarypagination: (data, isReset = false) => dispatch(actions.getkycSummarypagination(data, isReset)),
  getUserDetails: (id) => dispatch(actions.getUserDetails(id)),
  userApproval: (payload, locationId) => dispatch(actions.userApproval(payload, locationId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(KycDashboard);
