import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Freezer from 'freezer-js';
import Editor from './Editor/Editor';
import { getConfig, updateConfig, resetUpdatedData } from './actions';
import { notifyApiSuccess, notifyApiError } from '../../../common/utils/utils';
import './config.scss';
import _ from 'lodash';
import { Card, Button, Spin, Table, Row, Col, Tooltip, Icon } from 'antd';
class ConfigEditor extends PureComponent {

    state = {
        data: {},
        loading: false
    }

    componentDidMount() {

        const data = this.props?.location?.state?.config;
        this.setState({ data })
    }

    componentDidUpdate(prevProps) {

        if (this.props.updatedConfig && this.props.updatedConfig?.configData) {
            this.props.resetUpdatedData();
            this.setState({loading: false});
            notifyApiSuccess("Config Data Updated", "SUCCESS")
            this.props.history.goBack();
        }
    }

    updateNewConfig(newData) {        
        // if (_.isEqual(newData, this.state.data?.config)) {
        //     notifyApiError("No Changes in Updated Data", "Error")
        //     return;
        // } else {
        //     if (this.isJson(newData)) {
        //         const request = {
        //             "id": this.state.data?.id,
        //             "config": JSON.stringify(newData)
        //         }
        //         this.props.updateConfig(request)
        //     }

        // }
        this.setState({loading: true});
        if (this.isJson(newData)) {
            const request = {
                "id": this.state.data?.id,
                "config": JSON.stringify(newData)
            }
            this.props.updateConfig(request)
        }

        // }
    }

    updateUIChanges = newData => {
        // console.log("daatta", JSON.stringify(data))

        let data = {
            config: JSON.parse(newData),
            id: this.state.data.id
        }
        this.setState({ data: [] }, () => {
            this.setState({ data })
        })


    }


    download = () => {
        if (this.isJson(this.state.data)) {
            let filename = "export.json";
            let contentType = "application/json;charset=utf-8;";
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(this.state.data)))], { type: contentType });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                var a = document.createElement('a');
                a.download = filename;
                a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(this.state.data));
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }

    }

    isValidJson = data => {
        try {
            JSON.parse(data);
        } catch (e) {
            notifyApiError("Invalid JSON!", "Error")
            return false;
        }
        return true;
    }

    isJson = (str) => {
        // console.log(str)
        try {
            JSON.parse(JSON.stringify(str));
        } catch (e) {
            notifyApiError("Invalid JSON!", "Error")
            return false;
        }
        return true;
    }



    render() {
        if (!_.isEmpty(this.state.data)) {
            const fr = new Freezer({ json: this.state.data?.config });
            return (
                <div className="App">
                    <Card

                        bodyStyle={{ height: 530 }}
                    >

                        <Editor 
                            {...this.props} 
                            loading={this.state.loading}
                            isValidJson={(data) => this.isValidJson(data)} 
                            updateUIChanges={this.updateUIChanges} 
                            data={fr.get()} 
                            updateNewConfig={(data) => this.updateNewConfig(data)} 
                        />
                    </Card>

                </div >
            )
        }
        return (
            <div className="App">
                <Card
                    bodyStyle={{ height: 530 }}
                >
                    <Spin />
                </Card>

            </div>
        )

    }
}

// export default Config;


const mapStateToProps = (state, ownProps) => ({
    customConfig: state?.customConfig?.config,
    updatedConfig: state?.customConfig?.updatedConfig,
});
const mapDispatchToProps = dispatch => ({
    getConfig: () => dispatch(getConfig()),
    updateConfig: (params) => dispatch(updateConfig(params)),
    resetUpdatedData: () => dispatch(resetUpdatedData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigEditor);
