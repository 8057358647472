import React, { PureComponent } from "react";
import * as actions from "./actions";
import "./dashboard.scss";
import { connect } from "react-redux";
import {
  Card,
  Button,
  Input,
  Row,
  Col,
  Empty,
  Icon,
  Modal,
  Tooltip,
  Tag,
  Spin,
  Switch,
} from "antd";
import { table as Table } from "../../ui/table/Table";
import {
  canAllow,
  windowRedirect,
  notifyApiSuccess,
  notifyApiError,
} from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import moment from "moment";
import { Mixpanel } from "../../../utils/mixpanel";
import * as apiUtils from "../../../common/utils/apiGateway";
import { Constants } from "../../../common/utils/constants";

const { confirm } = Modal;

function checkHttpStatus(status) {
  switch (status?.code) {
    case 403:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 401:
      notifyApiError(status?.message, "ERROR:");
      // window.location.href = '/';
      return false;
    case 500:
    case 503:
      notifyApiError(status?.message, "ERROR:");
      return false;
    case 200:
    case 201:
    case 202:
      return true;
  }
}

class UserDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      userPermission: {
        read: canAllow(permissionTags.settings.user.read),
        update: canAllow(permissionTags.settings.user.update),
        create: canAllow(permissionTags.settings.user.create),
        delete: canAllow(permissionTags.settings.user.delete),
        view: canAllow(permissionTags.settings.user.view),
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          userTypes: ["USER"],
          fetchObjects: [
            "PARTNERS",
            "LOCATIONS",
            "ADDRESSES",
            "PINCODES",
            "CITIES",
          ],
        },
      },
      partialContentSearch: "",
      statusLoading: false,
    };
    this.InputSearchRef = React.createRef();
    if (!this.state.userPermission.read) {
      windowRedirect("settings/dashboard/");
    }
  }

  componentDidMount() {
    const user = JSON.parse(atob(localStorage.getItem("user")));
    const loggedPartnerId = localStorage.getItem("loggedPartnerId");
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name,
    });
    Mixpanel.track("User Page Initiated", {
      ERPUserIntiatted: moment().valueOf(),
    });
    this.setState((state) => ({
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          render: (data, row) => (
            <div>
              {row.name}
              <br /> <small>User Name: {row.username}</small>{" "}
            </div>
          ),
        },
        {
          title: "Location",
          dataIndex: "location",
          key: "location",
          render: (data, row) => (row.location ? row.location?.name : "NA"),
        },
        {
          title: "Contact",
          dataIndex: "contactNumber",
          key: "contactNumber",
        },
        {
          title: "User Level",
          dataIndex: "userLevel",
          key: "userLevel",
        },
        {
          title: "Active Since",
          dataIndex: "updatedAt",
          key: "updatedAt",
          render: (data) => (
            <div>
              {moment(data).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data).fromNow()}</small>{" "}
            </div>
          ),
        },
        {
          title: "Created On",
          dataIndex: "createdAt",
          key: "createdAt",
          render: (data) => (
            <div>
              {moment(data).format("DD/MM/YYYY HH:mm")} <br />{" "}
              <small>{moment(data).fromNow()}</small>{" "}
            </div>
          ),
        },
        {
          title: "Action",
          dataIndex: "",
          key: "action",
          render: (data, row) => (
            <Row gutter={[2, 4]}>
              {state.userPermission.update &&
                row.userLevel !== "CUSTOMER" &&
                row.userLevel !== "CUSTOMER_USER" &&
                row.userLevel !== "CUSTOMER_ADMIN" &&
                row.userLevel !== "CUSTOMER_SUPER_ADMIN" && (
                  <Col span={8}>
                    <Tooltip placement="top"  title={"Edit"}>
                      <Icon
                        type="edit"
                        onClick={() => {
                          this.showErrorPopup("edit")
                          // Mixpanel.track("User Page edit clicked", {
                          //   ERPUserEditClick: moment().valueOf(),
                          // });
                          // this.props.history.push(
                          //   `/appv2/settings/form/user/${row.id}`
                          // );
                        }}
                      />
                    </Tooltip>
                  </Col>
                )}
              {state.userPermission.update &&
                (row.userLevel === "CUSTOMER" ||
                  row.userLevel === "CUSTOMER_USER" ||
                  row.userLevel === "CUSTOMER_ADMIN" ||
                  row.userLevel === "CUSTOMER_SUPER_ADMIN") && (
                  <Col span={8}>
                    <Tooltip placement="top" title={"Customer User"}>
                      <Icon
                        type="edit"
                        style={{ opacity: 0.5, cursor: "not-allowed" }}
                      />
                    </Tooltip>
                  </Col>
                )}
              {!state.userPermission.update && state.userPermission.view && (
                <Col span={4}>
                  <Tooltip placement="top" title={"View"}>
                    <Icon
                      type="eye"
                      onClick={() =>
                        this.props.history.push(
                          `/appv2/settings/form/user/${row.id}`
                        )
                      }
                    />
                  </Tooltip>
                </Col>
              )}
              {state.userPermission.delete && (
                <Col span={4}>
                  <Tooltip placement="top" title={"Delete"}>
                    <Icon
                      type="delete"
                      onClick={() => 
                        this.showErrorPopup("delete")
                        // this.showConfirm(row.id)
                      }
                    />
                  </Tooltip>
                </Col>
              )}
              {row.userLevel !== "ADMIN" && (
                <Col span={4}>
                  <Tooltip placement="top" title={"Force Logout"}>
                    <Icon
                      style={{ color: "red" }}
                      type="poweroff"
                      onClick={() => 
                        this.showErrorPopup("force logout")
                        // this.showForceLogout(row.id)
                      }
                    />
                  </Tooltip>
                </Col>
              )}
              {state.userPermission.update && (
                <Col span={12}>
                  <Switch
                    className="user-status"
                    checked={row?.status}
                    onChange={(event) => 
                      this.showErrorPopup("inactivate/activate")
                      // this.changeStatus(event, row)
                    }
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                  />
                </Col>
              )}
            </Row>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: (data, row) => (
            <>
              <Tag color={!data ? "#e74c3c" : "#2ecc71"}>
                {data ? "Active User" : "Not Active User"}
              </Tag>
              <br />
              <Tag
                style={{ marginTop: 10 }}
                color={!row.verified ? "#e74c3c" : "#2ecc71"}
              >
                {row.verified ? "Verified User" : "Not Verified User"}
              </Tag>
            </>
          ),
        },
      ],
    }));
    // if (!this.props?.userData?.users)
    this.applyPagination({ pageNo: this.state.pageNo }, true);
  }

  changeStatus = (event, row) => {
    const { confirm } = Modal;
    const _self = this;
    confirm({
      title: "Update Status",
      content: (
        <>
          You want to{" "}
          {event ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
          the status of user <b>{row?.name}</b>?
        </>
      ),
      onOk: () => {
        _self.setState({ statusLoading: true });

        apiUtils
          .apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/users/${row?.id}`,

            method: "GET",
          })
          .then((response) => {
            if (checkHttpStatus(response.data?.status)) {
              const payload = response?.data?.response;
              const params = {
                id: payload.id,
                code: payload.code,
                name: payload.name,
                contactNumber: payload.contactNumber,
                email: payload.email,
                secondaryContactNumber: payload.secondaryNumber,
                secondaryEmail: payload.secondaryEmail,
                locationId: payload.locationId,
                status: event,
                userLevel: payload.userLevel,
                accessExportData: payload.accessExportData,
                entityType: payload.entityType,
                entityId: payload.entityId,
                rolesToLocationsMap: payload.roleIdsToLocationsMap,
              };
              console.log("user", params);
              apiUtils
                .apiRequest({
                  url: `/b2b/v1/partners/${Constants.PARTNER_ID}/users/${row?.id}`,
                  method: "PUT",
                  data: params,
                })
                .then((res) => {
                  if (checkHttpStatus(res.data?.status)) {
                    _self.setState({ statusLoading: false });
                    notifyApiSuccess("User Status Updated", "SUCCESS");
                    this.applyForcePagination(
                      { pageNo: _self.state.pageNo },
                      _self.state.pageNo,
                      true
                    );
                  } else {
                    _self.setState({ statusLoading: false });
                  }
                });
            }
          })
          .catch((error) => {});
      },
    });
  };

  componentDidUpdate(prevProps) {
    const { PaginatedUsers, userData, isForceLogout } = this.props;
    if (PaginatedUsers !== prevProps.PaginatedUsers) {
      this.setState({
        customerList: PaginatedUsers[userData.currentPageNo],
      });
    }
    if (isForceLogout !== prevProps.isForceLogout) {
      if (isForceLogout === "SUCCESS")
        notifyApiSuccess("Successfully Logout.", "SUCCESS");
    }
  }
  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1,
      });
    }
    this.props.fetchUsers(
      { filters: { ...this.state.filterPayload.filters, ...filters } },
      isReset
    );
  };

  applyForcePagination = (filters, page, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: page,
      });
    }
    this.props.fetchUsers(
      { filters: { ...this.state.filterPayload.filters, ...filters } },
      isReset
    );
  };

  showErrorPopup = (actionType) =>{
    confirm({
      centered: true,
      content : "Please use staff pay portal to " + actionType + " user",
      onOk : () =>{},
      okCancel : ()=>{},
    })
  }

  showConfirm = (selectedUser) => {
    confirm({
      centered: true,
      title: "Are you sure?",
      content: "Do you want to delete this customer!!!",
      onOk: () => {
        this.props.deleteUser(selectedUser, {
          filters: { ...this.state.filterPayload.filters },
        });
      },
      onCancel: () => {},
    });
  };

  showForceLogout = (id) => {
    Mixpanel.track("User Page Force Logout", {
      ERPUserForceLogout: moment().valueOf(),
      forced_user_id: id,
    });

    confirm({
      centered: true,
      title: "Are you sure?",
      content: "Do you want to forcefully logout this user!!!",
      onOk: () => {
        this.props.userForceLogout(id);
      },
      onCancel: () => {},
    });
  };

  reloadUserList = () => {
    Mixpanel.track("User Page Reload", { ERPUserReload: moment().valueOf() });

    this.setState({
      pageNo: 1,
    });
    // this.InputSearchRef.current.input.state.value = '';
    this.applyPagination(
      { pageNo: 1, partialContentSearch: this.state.partialContentSearch },
      true
    );
  };

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedUsers } = this.props;
    this.applyPagination({
      pageNo,
      partialContentSearch: this.state.partialContentSearch,
    });
    this.setState({
      pageNo,
    });
  };

  onSearch = (value) => {
    Mixpanel.track("User Page Search", {
      ERPUserSearchUser: moment().valueOf(),
    });
    this.setState({
      partialContentSearch: value,
    });
    this.applyPagination({ partialContentSearch: value.trim() }, true);
  };

  render() {
    const { columns, userPermission, pageNo, statusLoading } = this.state;
    const { PaginatedUsers, userData } = this.props;
    const actionBtns = (
      <Spin
        spinning={userData?.loading || statusLoading}
        indicator={<div></div>}
      >
        <Row>
          <Col span={userPermission.create ? 13 : 21}>
            <Input.Search
              size={"large"}
              placeholder="Search"
              allowClear
              ref={this.InputSearchRef}
              onSearch={(value) => this.onSearch(value)}
              enterButton
            />
          </Col>
          <Col span={2}>
            <Tooltip placement="top" title={"Reload User List"}>
              <Button
                size={"large"}
                onClick={this.reloadUserList}
                type="primary"
                style={{ marginLeft: 2 }}
              >
                <Icon type="reload" />
              </Button>
            </Tooltip>
          </Col>
          {userPermission.create && (
            <Col span={9}>
              <Button
                size={"large"}
                onClick={() => {
                  Mixpanel.track("User Page create clicked", {
                    ERPUserCreateClick: moment().valueOf(),
                  });
                  this.props.history.push(`/appv2/settings/form/user`);
                }}
                type="primary"
                style={{ marginLeft: 17, width: 170 }}
              >
                CREATE USER
              </Button>
            </Col>
          )}
        </Row>
      </Spin>
    );
    return (
      <>
        <Card
          className="UserDashBoard"
          title={<h2>User List</h2>}
          extra={actionBtns}
        >
          <Table
            bordered
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
            }}
            loading={userData?.loading || statusLoading}
            pagination={{
              onChange: this.getPaginatedData,
              total: userData?.totalCount,
              position: "bottom",
              current: pageNo,
              pageSize: userData?.pageSize,
            }}
            dataSource={PaginatedUsers ? PaginatedUsers[pageNo] : []}
            columns={columns}
            scroll={{ x: 1250 }}
          />
        </Card>
        <Modal
          title="Force Logout"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <h5>Are you sure?</h5>
          <p>Do you want to forcefully loggout this user!!!</p>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userData: state.settingsUsers,
  PaginatedUsers: state.settingsUsers?.PaginatedUsers,
  isForceLogout: state.settingsUsers?.isForceLogout,
});
const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (param, isReset = false) =>
    dispatch(actions.fetchUsers(param, isReset)),
  deleteUser: (id, payload) => dispatch(actions.deleteUser(id, payload)),
  userForceLogout: (id) => dispatch(actions.userForceLogout(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
