import * as CONSTANTS from './constants';
import { func } from 'prop-types';

export const lsnalertReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSTANTS.CONFIG_DATA: return getConfigData(state, action);
    default: return state
  }
}

function getConfigData(state, action) {
  return {
    ...state,
    configData : action.payload
  }
}
