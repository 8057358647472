import React, { Component } from 'react';
import { button as Button } from '../../../components/ui/button/Button';
import { MarkDelUndelForm } from './MarkDelUndelForm/MarkDelUndelForm';
import { icon as Icon } from '../../ui/icon/Icon';
import { Form } from 'antd';
/* Style imports */
import './TrackingFooter.scss';

export class TrackingFooter extends Component {
    state = {
        showPopup: false,
        formValid: false
    }
    handleCancel = (e) => {
        this.setState({
            showPopup: false,
        });
    }
    handleOk = (e) => {
        this.setState({
            showPopup: false,
        });
    }
    showPopup = (valid) => {
        this.setState({
            showPopup: true,
            formValid: valid
        });
    }
    render() {
        let actionButtons;
        if (!this.props.selectedStatus.includes('VALID') && this.props.shouldResolve) {
            actionButtons = [
                <Button className="btn-danger" onClick={this.showPopup.bind(this, false)}>Mark as Invalid</Button>,
                <Button className="btn-success" onClick={this.showPopup.bind(this, true)}>Mark as Valid</Button>
            ]
        }
        let feHtml;
        let consigneeHtml;
        let identifierHtml;
        if (this.props.fe) {
            feHtml = (<div className="contact-container">
                <p className="contact-number">{this.props ?.fe ?.contactNumber}</p>
                <p className="contact-name">Call FE ({this.props ?.fe ?.name})</p>
            </div>
            )
        }
        if (this.props.consignee) {
            consigneeHtml = (<div className="contact-container">
                <p className="contact-number">{this.props ?.consignee ?.contactNumber}</p>
                <p className="contact-name">Call Consignee ({this.props ?.consignee ?.name})</p>
            </div>)
        }
        if (this.props.consignee.key) {
            identifierHtml = (
                <div className="contact-container">
                    <p className="contact-number">{this.props ?.consignee ?.key}</p>
                    <p className="contact-name">Call Code</p>
                </div>)
        }

        return (
            <div className="TrackingFooter">
                <div className="footer-left">
                    <Icon type="phone" className="phone-icon" theme="filled" />
                    {feHtml}
                    {consigneeHtml}
                    {identifierHtml}
                </div>
                <div className="footer-right">
                    {actionButtons}
                </div>
                <div className="del-undel-form">
                    <MarkDelUndelForm display={this.state.showPopup}
                        onCancel={this.handleCancel}
                        valid={this.state.formValid}
                        selectOptions={this.props.reasons}
                        onSubmit={this.props.onMarkClick}
                        selectedReasonId={this.props.selectedReasonId}
                    />
                </div>
            </div>
        )
    }
};
