import React, { PureComponent } from 'react';
import * as actions from './actions';
import './dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Icon, Modal, Tooltip, Select, notification, Spin, Tag } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { Constants } from '../../../common/utils/constants';
import debounce from 'lodash/debounce';
import _ from 'lodash';

const { confirm } = Modal;
class RouteDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      routevisible: false,
      disabledRefresh: false,
      selectedRoute: {
        loading: false,
        data: []
      },
      source: [],
      sourceLocations: [],
      pageNo: 1,
      routePermission: {
        read: canAllow(permissionTags.settings.route.read),
        update: canAllow(permissionTags.settings.route.update),
        create: canAllow(permissionTags.settings.route.create),
        delete: canAllow(permissionTags.settings.route.delete),
        view: canAllow(permissionTags.settings.route.view),
        refresh: canAllow(permissionTags.settings.route.refresh),
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          isActive: true,
          fetchObjects: [],
          canAllowEmptyFetchObjects: true
        }
      }
    }
    this.InputSearchRef = React.createRef();
    if (!this.state.routePermission.read) {
      windowRedirect('settings/dashboard/route');
    }
    this.fetchSourceLocation = debounce(this.fetchSourceLocation, 800);
  }

  componentDidMount() {
    this.setState(state => ({
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: 300
        },
        // {
        //   title: 'Route Type',
        //   dataIndex: 'routeType',
        //   key: 'routeType',
        // },
        // {
        //   title: 'Source',
        //   dataIndex: 'sourceLocation',
        //   key: 'sourceLocation',
        //   render: (data, row) => (<div>{row?.sourceLocation?.name || row?.sourceZone?.name}</div>),
        //   width: 200
        // },
        // {
        //   title: 'Destination',
        //   dataIndex: 'destinationLocation',
        //   key: 'destinationLocation',
        //   render: (data, row) => (<div>{row?.destinationLocation?.name || row?.destinationZone?.name}</div>),
        //   width: 200
        // },
        // {
        //   title: 'Mapping Type',
        //   dataIndex: 'routeMappingType',
        //   key: 'routeMappingType',

        // },
        // {
        //   title: 'Transit Time',
        //   dataIndex: 'transitTime',
        //   key: 'transitTime',
        //   render: (data, row) => (<div>{row.transitTime} hr</div>),

        // },
        {
          title: 'Created On',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (data, row) => <div>{moment(data).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.updatedAt).fromNow()}</small></div>,
          width: 300
        },
        {
          title: 'Actions',
          dataIndex: '',
          key: 'action',
          width: 200,
          render: (data, row) => (<Row gutter={12}>
            {
              state.routePermission.update && <Col span={8}>
                <Tag color="blue" style={{ cursor: 'pointer' }} onClick={() => this.getRouteDetils(`${row.id}`)}>View More</Tag>
              </Col>
            }
            {/* {
              state.routePermission.update && <Col span={12}><Tooltip placement="top" title={'Edit'}>
                <Icon type="edit" onClick={() => this.props.history.push(`/appv2/settings/form/route/${row.id}`)} /></Tooltip>
              </Col>
            } */}
            {
              state.routePermission.update && <Col span={6}>
                <Tag color="blue" style={{ cursor: 'pointer' }} onClick={() => this.getInfoUrl(`${row.id}`, row.routeMappingType)}>Edit</Tag>
              </Col>
            }
            {
              (!state.routePermission.update && state.routePermission.view) && <Col span={6}><Tooltip placement="top" title={'View'}>
                <Icon type="eye" onClick={() => this.props.history.push(`/appv2/settings/form/route/${row.id}`)} /></Tooltip>
              </Col>
            }
            {
              state.routePermission.delete && <Col span={6}><Tooltip placement="top" title={'Delete'}><Icon type="delete" onClick={() => this.showConfirm(row.id)} /></Tooltip></Col>
            }
          </Row >),

        },
      ]
    }));
    if (this.props?.location?.state?.isReset) {
      this.reloadRouteList();
    } else {
      this.getPaginatedData(this.state.pageNo);
    }

  }

  getInfoUrl = (selectedId, type) => {
    console.log(selectedId);
    console.log(type);
    this.props.setZoneSet(type);
    this.setState({ selectedId })
    // this.props.history.push(`/appv2/settings/form/route/${row.id}`)
  }

  componentDidUpdate(prevProps, prevState) {
    const { PaginatedRoutes, routeData } = this.props;
    const { selectedId } = this.state;
    if (PaginatedRoutes !== prevProps.PaginatedRoutes) {
      this.setState({
        routeList: PaginatedRoutes[routeData.currentPageNo]
      })
    }
    if (selectedId !== prevState.selectedId) {
      this.props.history.push(`/appv2/settings/form/route/${selectedId}`)
    }
  }

  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    this.props.fetchRoutes({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
    this.setState({ partialContentSearch: filters.partialContentSearch })
  }

  showConfirm = (selectedRoute) => {
    confirm({
      title: 'Are you sure?',
      content: 'Do you want to delete this Route!!!',
      onOk() {
        this.props.deleteRoute(selectedRoute, { filters: { ...this.state.filterPayload.filters } })
      },
      onCancel() { },
    });
  }

  reloadRouteList = () => {
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedRoutes } = this.props;
    if (!PaginatedRoutes ? !PaginatedRoutes : !PaginatedRoutes[pageNo]) {
      let filter = {
        pageNo: pageNo
      };
      if (this.state?.partialContentSearch) {
        filter['partialContentSearch'] = this.state?.partialContentSearch;
      }
      this.applyPagination(filter)
    }
    this.setState({
      pageNo
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    const source = [...this.state.source];
    if (source.length > 0) {
      let payload = {
        "filters":
          { "locationIds": this.state.source }
      }
      this.setState({ disabledRefresh: true })
      this.props.refreshserviceability(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
          notification['success']({
            message: 'SUCCESS',
            description: "Location Cache Successfully Refreshed.",
            placement: 'bottomRight',
          });
          this.setState({ disabledRefresh: false })
        } else {
          notification['error']({
            message: 'ERROR',
            description: "Something Went Wrong.",
            placement: 'bottomRight',
          });
          this.setState({ disabledRefresh: false })
        }
      });
      this.setState({
        visible: false,
        source: []
      });
    }
  };

  handleCancel = e => {
    this.setState({
      visible: false,
      source: []
    });
  };


  getRouteDetils = (id) => {
    this.props.getRoutebyId(id).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        notification['success']({
          message: 'SUCCESS',
          description: res?.data?.status?.message,
          placement: 'bottomRight',
        });
        this.setState({
          routevisible: true,
          selectedRoute: res?.data?.response
        });
      } else {
        notification['error']({
          message: 'ERROR',
          description: res?.data?.status?.message,
          placement: 'bottomRight',
        });
        this.setState({
          routevisible: false,
        });
      }
    });
  };

  handleRouteOk = e => {
    this.setState({
      routevisible: false,
    });
  };

  handleRouteCancel = e => {
    this.setState({
      routevisible: false,
    });
  };

  fetchSourceLocation = (value) => {
    if (value) {
      this.setState({ fetchingSource: true, sourceLocations: [] })
      let filterLocationPayload = {
        filters: {
          partnerIds: [Constants.PARTNER_ID],
          status: true,
          fetchObjects: [],
          loggedInUserTokken: true,
          partialContentSearch: value,
          pageNo: 1,
          pageSize: 10,
        }
      }
      this.props.fetchLocation(filterLocationPayload).then(res => {
        this.getNewLocation(res);
      })
    }
  }

  getNewLocation = (res) => {
    this.setState({ sourceLocations: res?.data?.response?.locations, fetchingSource: false });
  }

  handleSource = source => {
    if (source.length === 0) {
      this.setState({ sourceLocations: [] })
    }
    this.setState({ source })
  }


  render() {
    const { columns, routePermission, pageNo, fetchingSource, sourceLocations, disabledRefresh } = this.state;
    const { PaginatedRoutes, routeData } = this.props;
    const actionBtns = (
      <Row>
        <Col span={routePermission.create && !routePermission.refresh ? 13 : 21}>
          <Input.Search size={'large'} placeholder="Search by name" allowClear ref={this.InputSearchRef} onSearch={value => this.applyPagination({ partialContentSearch: value.trim() }, true)} enterButton />
        </Col>
        <Col span={3} className="xzy">
          <Tooltip placement="top" title={'Reload Route List'}>
            <Button className="gsggs" block size={'large'} onClick={this.reloadRouteList} type="primary" style={{ marginLeft: 2 }}>
              <Icon type="reload" />
            </Button>
          </Tooltip>
        </Col>
        {
          routePermission.create &&
          <Col span={6}>
            <Button size={'large'} onClick={() => this.props.history.push(`/appv2/settings/form/route`)} type="primary" style={{ marginLeft: 12 }}>CREATE ROUTE</Button>
          </Col>
        }
        {
          routePermission.refresh &&
          <Col span={6}>
            <Button size={'large'} disabled={disabledRefresh} onClick={this.showModal} type="primary" style={{ marginLeft: 12 }}>REFRESH CACHE</Button>
          </Col>
        }

      </Row>
    );
    return (
      <>
        <Card className="CustomerDashBoard" title={<h2>Route List</h2>} extra={actionBtns}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            loading={routeData?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: routeData?.totalCount,
              position: 'bottom',
              current: pageNo,
              pageSize: routeData?.pageSize
            }}
            dataSource={PaginatedRoutes ? PaginatedRoutes[pageNo] : []}
            columns={columns} />
          <Modal
            title="Refresh Location Cache"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Row>
              <Col span={24} style={{ marginBottom: 10 }}>Select Location</Col>
              <Col span={24}>
                <Select
                  style={{ width: 400 }}
                  placeholder="All Locations"
                  mode="multiple"
                  value={this.state.source}
                  notFoundContent={fetchingSource ? <Spin size="small" /> : <div>Search Location</div>}
                  maxTagCount={1}
                  onSearch={this.fetchSourceLocation}
                  onChange={this.handleSource}
                  // filterOption={(input, option) => {
                  //   return option?.props?.children?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {sourceLocations?.map(loc => (
                    <Select.Option
                      key={loc.id}
                      value={loc.id}
                      label={loc.name}
                    >
                      {loc.name} ({loc.entityType})
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Modal>
          {this.state?.selectedRoute && <Modal
            title={this.state?.selectedRoute.name}
            visible={this.state.routevisible}
            onOk={this.handleRouteOk}
            onCancel={this.handleRouteCancel}
            footer={null}
          >
            <Row>
              <Col span={24}>
                <ul className="swal2-progresssteps">
                  {this.state?.selectedRoute?.routeMappingType !== "ZONE" && this.state?.selectedRoute?.routeMapping?.map((route, index) => (
                    <div key={"starte" + index}>
                      <li className={"swal2-progresscircle " + (index === 0 ? 'start' : '') + (index === this.state.selectedRoute.routeMapping.length - 1 ? 'end' : '')}>
                        <label><span>{route.location.name} - ( {route.location.entityType} )</span></label>
                      </li>
                      {this.state.selectedRoute?.routeMapping[index + 1]?.transitTime && <li className={index === this.state.selectedRoute.routeMapping.length - 1 ? "" : "swal2-progressline"}>
                        <div className="line"></div>
                        <label>{this.state.selectedRoute?.routeMapping[index + 1]?.transitTime}hrs</label>
                      </li>}
                    </div>
                  ))}
                </ul>
                <ul className="swal2-progresssteps">
                  {this.state?.selectedRoute?.routeMappingType === "ZONE" && this.state?.selectedRoute?.routeMapping?.map((route, index) => (
                    <div key={"startew" + index}>
                      <li className={"swal2-progresscircle " + (index === 0 ? 'start' : '') + (index === this.state?.selectedRoute?.routeMapping.length - 1 ? 'end' : '')}>
                        <label><span>{route.zone.name}</span></label>
                      </li>
                      {this.state?.selectedRoute?.routeMapping[index + 1]?.transitTime && <li className={index === this.state?.selectedRoute?.routeMapping.length - 1 ? "" : "swal2-progressline"}>
                        <div className="line"></div>
                        <label>{this.state?.selectedRoute?.routeMapping[index + 1]?.transitTime}hrs</label>
                      </li>}
                    </div>
                  ))}
                </ul>

              </Col>
              <Col span={24}>
                <ul className="swal2-progresssteps">
                  <li>
                    Mapping Type: <b>{this.state?.selectedRoute?.routeMappingType}</b>
                  </li>
                  <li>
                    Transit time: <b>{this.state?.selectedRoute?.transitTime} hrs</b>
                  </li>
                </ul>
              </Col>
              <Col span={24} className="modal-footer">
                <i className="start-loc"></i><label>Source Location</label>
                <i className="inter-loc"></i><label>Intermediate Location</label>
                <i className="end-loc"></i><label>Destination Location</label>
              </Col>
            </Row>
          </Modal>}
        </Card>

      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  routeData: state.settingsRoute,
  PaginatedRoutes: state.settingsRoute?.PaginatedRoutes,
  selectedRoute: state.settingsRoute.selectedRoute,
});
const mapDispatchToProps = dispatch => ({
  fetchLocation: (param, isReset = false) => dispatch(actions.fetchLocation(param, isReset)),
  fetchRoutes: (param, isReset = false) => dispatch(actions.fetchRoutes(param, isReset)),
  deleteRoute: (id, payload) => dispatch(actions.deleteRoute(id, payload)),
  refreshserviceability: (payload) => dispatch(actions.refreshserviceability(payload)),
  getRoutebyId: (id) => dispatch(actions.getRoutebyId(id)),
  setZoneSet: (type) => dispatch(actions.setZoneSet(type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RouteDashboard);