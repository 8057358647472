import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Icon,
  Button,
  Table,
  Tag,
  Modal,
  Select,
  Tooltip
} from "antd";
import * as moment from "moment";
import LocationActive from "../../../assets/img/icons/location_active.svg";
import LocationInactive from "../../../assets/img/icons/location_inactive.svg";
import { permissionTags } from '../../../configs/permissionTags';
import { canAllow } from '../../../common/utils/utils';


import "./asset.scss";
import { Mixpanel } from "../../../utils/mixpanel";

class VendorCardTitle extends Component {
  render() {
    return (
      <>
        <Row gutter={24}>
          <Col md={4}>
            <h2>{this.props.title}</h2>
          </Col>
          <Col md={20}>{this.props.children}</Col>
        </Row>
      </>
    );
  }
}

class AssetList extends Component {
  state = {
    locationModal: false,
    selectedLocation: undefined,
    locationFilter: {},
    assetData: undefined,
    orignalLocation: undefined,
    vehiclePermission: {
      read: canAllow(permissionTags.settings.vehicle.read),
      update: canAllow(permissionTags.settings.vehicle.update),
      create: canAllow(permissionTags.settings.vehicle.create)
    },
  };

  constructor(props) {
    super(props);
    this.searchHandler = this.searchHandler.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(atob(localStorage.getItem("user")));
    const loggedPartnerId = localStorage.getItem("loggedPartnerId");
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name
    });
    Mixpanel.track("Asset Page Initiated", {
      ERPAssetIntiatted: moment().valueOf()
    });
  }

  componentDidUpdate() {
    if (this.props.assetLocation) {
      this.setState(
        {
          locationModal: false,
          selectedLocation: undefined,
          locationFilter: {},
          assetData: undefined,
          orignalLocation: undefined
        },
        () => {
          this.props.resetLocation();
          setTimeout(() => {
            this.props.refreshList(1, 10);
          }, 1000);
        }
      );
    }
  }

  goToAssetCreate() {
    Mixpanel.track("Asset Page Create Clicked", {
      ERPAssetCreateClick: moment().valueOf()
    });
    this.props.history.push("/appv2/settings/form/asset");
  }

  searchHandler(event) {
    Mixpanel.track("Asset Page Searched", {
      ERPAssetSearchAsset: moment().valueOf()
    });
    this.props.searchAssets(event);
  }

  getTitle() {
    return (
      <VendorCardTitle title="Asset List">
        <Row gutter={10}>
          <Col md={10} offset={3} push={1}>
            <Input.Search
              size={"large"}
              placeholder="Search"
              allowClear
              onSearch={value => this.searchHandler(value.trim())}
              enterButton
            />

            {/* <Input
                            placeholder="Search"
                            size="large"
                            suffix={<Icon type="search" />}
                            onChange={this.searchHandler}
                        /> */}
          </Col>
          <Col md={2} push={1} >
            <Button
              block
              size={"large"}
              onClick={() => {
                Mixpanel.track("Asset Page Reload", {
                  ERPAssetReload: moment().valueOf()
                });
                this.props.refreshList(1, 10);
              }}
              type="primary"
            >
              <Icon type="reload" />
            </Button>
          </Col>
          <Col md={4} push={1}>
            <Button
              block
              type="primary"
              size="large"
              onClick={() => this.goToAssetCreate()}
            >
              Create Asset
            </Button>
          </Col>
          <Col md={4} push={1}>
            <Button
              disabled={!this.state.vehiclePermission?.read}
              onClick={() => this.props.history.push("/appv2/settings/dashboard/asset/categories")}
              block
              type="primary"
              size="large"
            // onClick={() => this.goToAssetCreate()}
            >
              Asset Category
            </Button>
          </Col>
        </Row>
      </VendorCardTitle>
    );
  }

  editVendor(asset) {
    Mixpanel.track("Asset Page Edit Clicked", {
      ERPAssetEditClick: moment().valueOf()
    });
    this.props.history.push(`/appv2/settings/form/asset`, { assetId: asset.id });
  }

  onChange = (page, pageSize) => {
    this.props.refreshList(page, pageSize);
  };

  openLocationsModal = record => {
    Mixpanel.track("Asset Page Change location Initiated", {
      ERPAssetChangeLocationInitiated: moment().valueOf(),
      locationChangeID: record.id
    });
    let { locationFilter } = this.state;
    locationFilter["assetId"] = record.id;
    !this.props.assetLocationsList && this.props.getLocationsList();
    this.setState({
      locationModal: true,
      locationFilter,
      assetData: record.vehicleNumber,
      orignalLocation: record.originLocationId
    });
  };

  closeLocationModal = () => {
    this.setState({ locationModal: false }, () => {
      this.setState({
        selectedLocation: undefined,
        locationFilter: {},
        assetData: undefined,
        orignalLocation: undefined
      });
    });
  };

  selectLocation = value => {
    let { locationFilter } = this.state;
    locationFilter = {
      ...locationFilter,
      locationId: value
    };
    this.setState({ selectedLocation: value, locationFilter });
  };

  okHandleLocation = () => {
    Mixpanel.track("Asset Page Change location Submitted", {
      ERPAssetChangeLocationSuccessful: moment().valueOf(),
      locationFilter: this.state.locationFilter
    });
    this.props.submitLocation(this.state.locationFilter);
  };

  render() {
    const columns = [
      {
        title: "Vehicle",
        dataIndex: "vehicleNumber",
        key: "vehicleNumber"
      },
      {
        title: (
          <>
            Owner/ <br /> Contact Person
          </>
        ),
        dataIndex: "ownerName",
        key: "ownerName",
        render: (data, row) => (
          <>
            {data} <br /> {row.contactPersonMobile}
          </>
        )
      },
      {
        title: "Type (Capacity)",
        dataIndex: "vehicleType",
        key: "vehicleType",
        render: (text, row) => (
          <span>
            {text} ({row.capacity} {row.capacityUnit})
          </span>
        )
      },

      {
        title: "Registration",
        dataIndex: "registrationNumber",
        key: "registrationNumber"
      },
      // {
      //     title: 'Contact Person',
      //     dataIndex: 'contactPersonMobile',
      //     key: 'contactPersonMobile'
      // },
      {
        title: "Current Location",
        key: "location",
        render: (data) => data?.originLocation?.name
      },
      {
        title: "Status",
        key: "status",
        render: (text, row) => (
          <>
            {row.isAvailable ? (
              <Tag color="#87d068">Available</Tag>
            ) : (
                <Tag color="#f50">Busy</Tag>
              )}
          </>
        )
      },
      {
        title: "Created On",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, row) => (
          <div>
            {moment(text).format("DD/MM/YYYY h:mm a")} <br />{" "}
            <small>{moment(row.updatedAt).fromNow()}</small>
          </div>
        )
      },
      {
        title: "Actions",
        key: "action",
        render: (text, record) => (
          <>
            <Row gutter={6}>
              {record.isAvailable ? (
                <Col span={12}>
                  {" "}
                  <Tooltip
                    overlayClassName="ant-asset-tooltip"
                    placement="top"
                    title={"Edit"}
                  >
                    <Icon type="edit" onClick={() => this.editVendor(record)} />
                  </Tooltip>
                </Col>
              ) : (
                  <Col span={12}>
                    {" "}
                    <Tooltip
                      overlayClassName="ant-asset-tooltip"
                      placement="top"
                      title={"Edit"}
                    >
                      <Icon type="edit" style={{ cursor: "not-allowed" }} />
                    </Tooltip>
                  </Col>
                )}
              {record.isAvailable ? (
                <Col span={12}>
                  <Tooltip
                    overlayClassName="ant-asset-tooltip"
                    placement="top"
                    title={"Change Location"}
                  >
                    <img
                      src={LocationActive}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.openLocationsModal(record)}
                    />
                  </Tooltip>
                </Col>
              ) : (
                  <Col span={12}>
                    <Tooltip
                      overlayClassName="ant-asset-tooltip"
                      placement="top"
                      title={"Change Location"}
                    >
                      <img
                        src={LocationInactive}
                        style={{ cursor: "not-allowed" }}
                      />
                    </Tooltip>
                  </Col>
                )}
            </Row>
          </>
        )
      }
    ];
    let newAssets = this.props.assets;
    let assets = this.props.assets;
    // console.log("newAssets", newAssets)
    // if (assets && assets.length > 0) {
    //   newAssets = assets.map(asset => {
    //     this.props.locationsList.map(location => {
    //       if (location.id == asset.originLocationId) {
    //         asset["currentLocation"] = location.name;
    //       }
    //     });
    //     return asset;
    //   });
    // }

    return (
      <>
        <Card title={this.getTitle()} className="vendor-list-wrapper">
          <Table
            columns={columns}
            bordered
            dataSource={newAssets}
            pagination={{
              total: this.props.assetsList?.totalCount,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: this.props.page,
              pageSize: 10,
              onChange: this.onChange
            }}
          />

          <Modal
            title={`Change Location for ${this.state.assetData}`}
            visible={this.state.locationModal}
            onCancel={this.closeLocationModal}
            footer={[
              <Button key="back" onClick={this.closeLocationModal}>
                Cancel
              </Button>,
              <Button
                key="submit"
                loading={this.props.assetLocationUpdateLoading}
                type="primary"
                onClick={this.okHandleLocation}
              >
                Submit
              </Button>
            ]}
          >
            <div className="form-label-text">Select Location</div>
            <Select
              placeholder="Select Location"
              showArrow
              style={{ width: "100%" }}
              showSearch
              value={this.state.selectedLocation}
              loading={this.props.assetLocationslistloading}
              onSelect={this.selectLocation}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.props?.assetLocationsList?.map(location => (
                <Select.Option
                  key={location.id}
                  value={location.id}
                  label={location.name}
                  disabled={location.id == this.state.orignalLocation}
                >
                  {location.name}
                </Select.Option>
              ))}
            </Select>
          </Modal>
        </Card>
      </>
    );
  }
}

export default AssetList;
