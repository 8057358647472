import { apiRequest } from "../../common/utils/apiGateway";
import { Constants } from "../../common/utils/constants";

const FleetService = {
  cityList: () => {
    return apiRequest({
      url: "/b2b/v1/cities?view=LITE_CITY_LIST_WITH_STATE_NAMES",
      method: "GET",
    });
  },

  getCustomer: () => {
    return apiRequest({
      url: "/v1/hyperlocal-fe-map-view/customers",
      method: "GET",
    });
  },

  cityListbyId: (cityId) => {
    return apiRequest({
      url: `b2b/v2/city/${cityId}/locations`,
      method: "GET",
    });
  },

  fetchUsersData: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getByFilter",
      method: "POST",
      data: payload,
    });
  },

  setPermission: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/performStatusAction",
      method: "POST",
      data: payload,
    });
  },

  hyperlocalServicableAreas: (payload = {}) => {
    return apiRequest({
      url: "/v1/hyperlocal-serviceable-areas/fetch",
      method: "POST",
      data: payload,
    });
  },

  fetchZones: (payload = {}) => {
    const url = "/b2b/v1/zone/fetch";
    return apiRequest({
      url,
      method: "POST",
      data: payload,
    });
  },

  zoneToggle: (payload = {}) => {
    const url = "/v1/hyperlocal-serviceable-areas/kill-switch";
    return apiRequest({
      url,
      method: "PUT",
      data: payload,
    });
  },

  disabledUsers: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getByFilter",
      method: "POST",
      data: payload,
    });
  },

  enabledUsers: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getByFilter",
      method: "POST",
      data: payload,
    });
  },

  geoFence: (payload = {}) => {
    return apiRequest({
      url: "/v1/hyperlocal-fe-map-view/fetch",
      method: "POST",
      data: payload,
    });
  },

  allOrderData: (payload = {}) => {
    return apiRequest({
      url: "/v1/hyperlocal-fe-map-view/hotspots",
      method: "POST",
      data: payload,
    });
  },

  feCountDetails: (payload = {}) => {
    return apiRequest({
      url: "/v1/hyperlocal-fe-map-view/feCountInFilterPopUp",
      method: "POST",
      data: payload,
    });
  },

  allActiveFes: (payload = {}) => {
    return apiRequest({
      url: "/b2b/v1/fe/getAllLiveFELocation",
      method: "POST",
      data: payload,
    });
  },

  riderDetails: (id) => {
    return apiRequest({
      url: `/b2b/v1/fe/getAllLiveFELocation/${id}`,
      method: "GET",
    });
  },

  getTemplates: () => {
    return apiRequest({
      url: "/communication/v1/pushNotification/template/fetch",
      method: "GET",
    });
  },

  cancellation: (data) => {
    return apiRequest({
      url: `b2c/v1/hyperlocal/orders/${data.code}/${data.orderid}/cancel`,
      method: "POST",
      data: {
        reason: data.cancelreason,
      },
    });
  },

  getCustomerName: (data) => {
    return apiRequest({
      url: `/b2c/v1/hyperlocal/orders/${data.orderid}/customer`,
      method: "GET",
    });
  },

  getEligibleZones: (userId) => {
    return apiRequest({
      url: `/hyperlocal/v1/users/${userId}/getEligibleZones`,
      method: "GET",
    });
  },

  getEligibleClients: (userId) => {
    return apiRequest({
      url: `/hyperlocal/v1/users/${userId}/getEligibleCustomers`,
      method: "GET",
    });
  },

  saveZones: (userId, payload = {}) => {
    return apiRequest({
      url: `/hyperlocal/v1/users/${userId}/saveEligibleZones`,
      method: "POST",
      data: payload,
    });
  },

  saveClients: (userId, payload = {}) => {
    return apiRequest({
      url: `/hyperlocal/v1/users/${userId}/saveTaggedCustomers`,
      method: "POST",
      data: payload,
    });
  },

  getCustomers: () => {
    return apiRequest({
      url: "/v1/hyperlocal-fe-map-view/customers",
      method: "GET",
    });
  },

  getZoneData: (payload = {}) => {
    return apiRequest({
      url: "/v1/hyperlocal-serviceable-areas/fetch",
      method: "POST",
      data: payload,
    });
  },
  getMultiTaggedEligibility: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getmultitaggedeligibility",
      method: "POST",
      data: payload,
    });
  },
  saveMultiTaggedEligibility: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/savemultitaggedeligibility",
      method: "PUT",
      data: payload,
    });
  },
  getMultiTaggedZones: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getmultitaggedzones",
      method: "POST",
      data: payload,
    });
  },
  saveMultiTaggedZones: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/savemultitaggedzones",
      method: "PUT",
      data: payload,
    });
  },
  getMultiTaggedCustomers: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getmultitaggedcustomers",
      method: "POST",
      data: payload,
    });
  },
  saveMultiTaggedCustomers: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/savemultitaggedcustomers",
      method: "PUT",
      data: payload,
    });
  },
  setRainSurge: (payload = {}, isActivate) => {
    console.log("setRainSurge was called from fleet.service");
    return apiRequest({
      url: `/hyperlocal/v1/surge/${isActivate ? "activate" : "deactivate"}`,
      method: "POST",
      data: payload,
    });
  },
  setDemandSurge: (payload = {}, isActivate) => {
    console.log("setDemandSurge was called from fleet.service");
    return apiRequest({
      url: `/hyperlocal/v1/surge/${isActivate ? "activate" : "deactivate"}`,
      method: "POST",
      data: payload,
    });
  },

  getCodEligibility: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getmultitaggedcodeligibility",
      method: "POST",
      data: payload,
    });
  },
  saveCodEligibility: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/savemultitaggedcodeligibility",
      method: "PUT",
      data: payload,
    });
  },

  fetchStaffsData: (payload = {}) => {
    return apiRequest({
      url: "/hyperlocal/v1/users/getUserStatus",
      method: "POST",
      data: payload,
    });
  },
};

export default FleetService;
