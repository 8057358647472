import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import * as utils from "../../../common/utils/utils";
import "./VehicleTracking.scss";
import { getTrackingData } from '../action';
import ClusterMap from '../../maps/ClusterMap';
import { Input, Icon } from 'antd';
import img from '../../../assets/img/spinner.svg';
import { USER_LOGGEDIN_INFO, Constants } from "../../../common/utils/constants";

class VehicleTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicletrack: null,
        };
    }
    componentDidMount() {
        const partnerIds = localStorage.getItem('loggedPartnerId');
        let customerIds;
        if (Constants.sessionUser ?.location ?.entityType === USER_LOGGEDIN_INFO.CUSTOMER) {
            customerIds = Constants.sessionUser ?.location ?.entityId
        }
        if (partnerIds) {
            this.props.dispatch(getTrackingData({ partnerIds, customerIds }));
        }
    }

    childHandler = (dataFromChild) => {
        this.props.history.push(`/appv2/tracking/dashboard/route-tracking/${dataFromChild}`);
    }

    childHandlerData = (dataFromChild) => {
        this.props.history.push(`/appv2/tracking/dashboard/route-tracking/${dataFromChild}`);
    }

    componentDidUpdate() {
    }

    onChangeVehicle = (e) => {
        let selectedVehicle = e.target.value.replace(/ /g, '');
        this.setState({ vehicletrack: `${selectedVehicle}` });
    }

    searchVehicle = (data) => {
        return data.filter(x => x.assetBO.vehicleNumber.toLowerCase().includes(this.state.vehicletrack.toLowerCase()));
    }

    lapsList(data) {
        return data.map((x, key) => {
            return (
                <div key={key}>
                    <div className="vehicle_box" onClick={() => this.childHandlerData(x.tripId)}>
                        <Row>
                            <div className="vehicleNumber">{x.assetBO.vehicleNumber.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</div>
                            {/* <div className="vehicleTag">Mobile App</div> */}
                        </Row>
                        <div className="vehicleAddr">{x.location}</div>
                        <div className="vehicleDate">{utils.dateDiffFormat(x.lastKnownTime)}</div>
                        <div className={x.isLatest ? 'statusRunning' : 'statusStopped'}>{x.isLatest ? "Running" : "stopped"}</div>
                    </div>
                    <div className="divider"></div>
                </div>
            )
        })
    }
    render() {
        const { getTrackingData } = this.props;
        let filterData;
        let lapsList;
        if (getTrackingData) {
            filterData = this.state.vehicletrack ? this.searchVehicle(getTrackingData.vehicleTrackerBOS) : getTrackingData.vehicleTrackerBOS;
            lapsList = this.lapsList(filterData);
        }
        let isShowLoader = !lapsList;
        if (isShowLoader) {
            return (<Row><div className="noData"><img className="loading" src={img} alt="Loading" /></div></Row>)
        }
        return (
            <>
                <Row>
                    <Col xs={9}>
                        <div className="vehicleTitlebox">Vehicle Tracking</div>
                        {lapsList && lapsList.length > 0 &&
                            <>
                                <div className="vehicle-card">
                                    <Row className="vehicleListBox">
                                        <div className="vehicle_list">Vehicle List</div>
                                        {lapsList && <div className="bluecircle2">{lapsList.length}</div>}
                                    </Row>
                                    <div className="divider"></div>
                                    <div className="vehiclescroll">
                                        {lapsList}
                                    </div>
                                </div>
                            </>
                        }
                    </Col>
                    <Col xs={1}></Col>
                    <Col xs={14}>
                        <Row className="pb_20">
                            <Input
                                prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Search Vehicle"
                                onChange={(e) => this.onChangeVehicle(e)}
                            />
                        </Row>
                        <Row className="pt_10">
                            <Col className="gutter-row" span={24}>
                                <div className="gutter-box">
                                    {filterData && filterData.length > 0 && <div><ClusterMap {...this.props} geojson={filterData} action={this.childHandler} /></div>}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {lapsList && lapsList.length <= 0 &&
                    <Row><div className="noData">No Data Available</div></Row>}
            </>
        );
    }
}
// const mapStateToProps = state => {console.log(state)}
const mapStateToProps = state => ({
    getTrackingData: state.tracking.vehicleData,
    loggedInUser: state.header ?.loggedInUser,
});

export default connect(mapStateToProps)(VehicleTracking);
