
export const initialState = {
    loading: false
}

export const FETCH_ROUTE_BEGIN = "FETCH_ROUTE_BEGIN";
export const FETCH_ROUTE_SUCCESS = "FETCH_ROUTE_SUCCESS";
export const FETCH_ROUTE_FAILURE = "FETCH_ROUTE_FAILURE";

export const FETCH_ROUTER_BEGIN = "FETCH_ROUTER_BEGIN";
export const FETCH_ROUTER_SUCCESS = "FETCH_ROUTER_SUCCESS";
export const FETCH_ROUTER_FAILURE = "FETCH_ROUTER_FAILURE";

export const FETCH_LOCATION_ZONE_SUCCESS = "FETCH_LOCATION_ZONE_SUCCESS"

export const RESET_DATA = "RESET_DATA";