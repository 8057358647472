import {MapLayer, Polyline} from 'react-leaflet';
import L from 'leaflet';

export default class FuturePolylines extends MapLayer {
  createLeafletElement() {
    const { map, road } = this.props;
    let leafletElement = L.polyline(road, {color: '#7f7f80', weight: 3, dashArray: "4"}).addTo(map.leafletElement);
    return leafletElement;
  }
}
