import React, { Component } from "react";
import { Row, Col,Input, Icon, Popover, Button,Radio } from "antd";
import { connect } from "react-redux";
import * as utils from "../../../common/utils/utils";
import "./GmapVehicleTracking.scss";
import { setTrackingTypeData,getBeeconTelematicTrackingData,getBeeconLiveTelematicTrackingData,getBeeconSimTrackingData } from '../action';
import GoogleMapLeaflet from '../../maps/GoogleMapLeafLet';
import img from '../../../assets/img/spinner.svg';
const axios = require('axios');

class GmapVehicleTracking extends Component {
    constructor(props){
        super(props);
        this.state = {
          vehicleTelematicData: null,
          vehicleRegNo:null,
          address:{}
        };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.listType="ALL"
    }

  componentDidMount() {
        const partnerIds = localStorage.getItem('loggedPartnerId');
        if(partnerIds){
            if(this.props.statusValue == "ALL"){
              this.listType="ALL"
            }else if(this.props.statusValue == "ONTRIP"){
              this.listType="ONTRIP"
            }
            if(this.props.statusValue=="ALL" && !utils.isEmpty(this.props.getBeeconTelematicTrackingData)){
              this.forceUpdateHandler()
            }
            else if(!utils.isEmpty(this.props.statusValue) && this.props.statusValue=="ONTRIP" && !utils.isEmpty(this.props.getBeeconOnTripTelematicTrackingData)){
              this.forceUpdateHandler()
            }else{
              this.props.dispatch(getBeeconTelematicTrackingData(this.props.statusValue));
            }
            
            //this.props.dispatch(getBeeconSimTrackingData());
        }
    }

    childHandler = (dataFromChild) => {
      this.props.history.push(`/appv2/tracking/dashboard/show-details/${dataFromChild}`);
    }
    onVehicleChangesHandler = (status) => {
      const partnerIds = localStorage.getItem('loggedPartnerId');
      /*this.setState({
        statusValue: status,
      });*/
      this.listType = status;
      // const allvehicleData = this.getBeeconTelematicTrackingData
      // const ontripVehicleData = this.getBeeconOnTripTelematicTrackingData
      if(status=="ALL" && !utils.isEmpty(this.props.getBeeconTelematicTrackingData)){
        this.props.dispatch(setTrackingTypeData(status));
      }
      else if(!utils.isEmpty(status) && status=="ONTRIP" && !utils.isEmpty(this.props.getBeeconOnTripTelematicTrackingData)){
        this.props.dispatch(setTrackingTypeData(status));
      }else{
        this.props.dispatch(getBeeconTelematicTrackingData(status,partnerIds));
      }
      
    }
    reloadData = () => {
      //this.props.dispatch(getBeeconTelematicTrackingData());
      window.location.reload();
    }

    childHandlerData = (dataFromChild) => {
        this.props.history.push(`/appv2/tracking/dashboard/show-details/${dataFromChild}`);
      }
      forceUpdateHandler(){
        this.forceUpdate();
      };
    componentDidUpdate(){
      console.log("INside Componet udate")
      //getBeeconLiveTelematicTrackingData(this.props.getBeeconTelematicTrackingData)();
    }

    onGetAddress = (regNo,lat,long) => {
      var url = "https://nominatim.openstreetmap.org/reverse?lat="+parseFloat(lat)+"&lon="+parseFloat(long)+"&addressdetails=1&format=json";
      var display_name;
      var _this = this;
      axios.get(url)
      .then(function (response) {
          if(!utils.isEmpty(response) && !utils.isEmpty(response.data)){
              display_name = response.data.display_name
          }
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          if(!utils.isEmpty(display_name)){
              _this.state.address = {
                  ..._this.state.address,
                  [regNo] : display_name
              }
              _this.setState({ address: _this.state.address});
          }
      });
  }
    /*componentWillReceiveProps(nextProps){
      if (nextProps.location.pathname !== this.props.location.pathname) {
        const partnerIds = localStorage.getItem('loggedPartnerId');
        if(partnerIds){
            this.props.dispatch(getBeeconTelematicTrackingData());
            //this.props.dispatch(getBeeconSimTrackingData());
        }
      }
    }*/
    onChangeVehicle = (e) =>{
        let selectedVehicle = e.target.value;
        this.setState({vehicleRegNo : `${selectedVehicle}`});
    }

    searchVehicle = (data) => {
        return data.filter(x => x.regNo.toLowerCase().includes(this.state.vehicleRegNo.toLowerCase()));
    }
  vehicleList(data) {
        console.log("the data is",data)
        return data.map((x, key) => {
          let url = x.id+'-'+x.regNo+(!utils.isEmpty(x.sim) && x.sim ? '-simtrack':'')+(!utils.isEmpty(x.contractId)? '-'+x.contractId:'');
          if(x.onTrip){
            url = x.id+'-'+x.regNo+(!utils.isEmpty(x.simTracker) && x.simTracker ? '-ontripsimtrack':'-ontriptrack')+(!utils.isEmpty(x.tripId)? '-'+x.tripId:'');
          }
            return (
              <div key={key}>
    
              <div className="vehicle_box" onClick={() => this.childHandlerData(url)}>
                  <Row>
                      <div className="vehicleNumber">{x.regNo}</div>
                      <div className="vehicleIcon">
                          {!utils.isEmpty(x.sim) && x.sim && <img className="simIcon" src={require('../../../assets/img/sim-card.png')} />}
                      </div>
                      <div className={ (x.telematicData.ignition != 0) ? 'statusRunning1' : 'statusStopped1' }>({(x.telematicData.ignition != 0) ? "Running" : "Stopped"})</div>
                  </Row>
                  
                  <div ><label>Speed&nbsp;&nbsp;&nbsp;:&nbsp;</label><span>{x.telematicData && x.telematicData.gps && x.telematicData.gps.speed ? x.telematicData.gps.speed : 0 } Km/hr</span><span>{x.telematicData.staticStateChangeTime && ' ( '+utils.getTimeAsDuration(utils.getTimeDifference(x.telematicData.staticStateChangeTime), "small")+' ) '}</span><br/></div>
                  <div ><label>Last Updated:&nbsp;</label><span>&nbsp;{!utils.isEmpty(x.statusChangeDate)? utils.dateFormat(x.statusChangeDate):utils.dateFormat(x.telematicData.unixTimeStamp)}</span><br/></div>     
              </div>
              {!utils.isEmpty(this.state.address[x.regNo]) ? <div className="vehicleAddr">
                                {this.state.address[x.regNo]}
                            </div>: x.telematicData && x.telematicData.gps && x.telematicData.gps.location &&
                            <div className="vehicleAddr">
                            <Button
                            onClick={() => this.onGetAddress(x.regNo,x.telematicData.gps.location.lat,x.telematicData.gps.location.long)}
                                size={"small"}
                                shape={"round"}
                                style={{
                                    height: "auto",
                                    font: "Roboto",
                                    fontSize : "12px",
                                    backgroundImage: "linear-gradient(to top, #1665d8, #1f6fe5)",
                                    color: "white",
                                    width: "auto"
                                }}
                            >
                            Get Address
                            </Button>
                            </div>
                }
              <div className="divider"></div>
              </div>
            )
          })
    }
  render() {
      const { getBeeconTelematicTrackingData,getBeeconOnTripTelematicTrackingData,statusValue } = this.props;
      let filterData;
      let vehicleList;

      if (statusValue == "ALL"){
        if(getBeeconTelematicTrackingData){
          filterData  = getBeeconTelematicTrackingData;
        }
      }else{
        if(getBeeconOnTripTelematicTrackingData){
          filterData  = getBeeconOnTripTelematicTrackingData;
        }
      }
      console.log("the filter data",this.state);
      console.log("the filter filterData",filterData);
      if(filterData){
        let data = [];
        for (const key in filterData) {
          if (filterData.hasOwnProperty(key)) {
            const element = filterData[key];
            data.push(element);
          }
        };
        vehicleList = this.vehicleList(this.state.vehicleRegNo ? this.searchVehicle(data) : data);
      };
      let isShowLoader = !vehicleList;
      if(isShowLoader) {
        return (<Row><div className="noData"><img className="loading" src={img} alt="Loading"/></div></Row>)
      }
    return (
        <>
        {!utils.isEmpty(filterData) && this.listType =='ALL' && <GoogleMapLeaflet {...this.props} status={this.listType} geojson={filterData} action={this.childHandler} onVehicleStatusChange={this.onVehicleChangesHandler}/>}
        {!utils.isEmpty(filterData) && this.listType =='ONTRIP' && <GoogleMapLeaflet {...this.props} status={this.listType} geojson={filterData} action={this.childHandler} onVehicleStatusChange={this.onVehicleChangesHandler}/>}
        {utils.isEmpty(filterData) && <Row>
          <Col xs={7}>
            <div className="vehicleTitlebox">Vehicle Tracking</div>
            <Row><div className="noData">No Data Available</div></Row>
            </Col>
            <Col xs={1}></Col>
            <Col xs={16}>
            <Row className="pt_10">
              <Col className="gutter-row" span={24}>
                <div className="gutter-box">
                  <div className="boxgooglemap">
                   <Radio.Group onChange={(e)=>this.onVehicleChangesHandler(e.target.value)} value={statusValue}>
                    <Radio value={'ALL'}>All Vehicles</Radio>
                    <Radio value={'ONTRIP'}>On Trip Vehicles</Radio>
                    </Radio.Group>
                  <Row><div className="noData">No Data Available</div></Row>
                  </div>
                </div>
            </Col>
            </Row>
            </Col>
        </Row>}
        </>
    );
  }
}
//const mapStateToProps = state => {console.log(state)}
const mapStateToProps = state => ({
  getBeeconTelematicTrackingData : state.tracking.vehicleTelematicData,
  getBeeconOnTripTelematicTrackingData : state.tracking.vehicleOnTripTelematicData,
  statusValue : state.tracking.tripStatus,
  //getBeeconSimTrackingData : state.tracking.vehicleSimData,
});

export default connect(mapStateToProps)(GmapVehicleTracking);
