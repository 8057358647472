import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import * as actions from './action';
import { Constants } from '../../../common/utils/constants';
import { connect } from 'react-redux';
import {
  Map,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  LayerGroup,
  Marker,
  Tooltip,
  Popup
} from 'react-leaflet';
import moment from 'moment';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import LeafletEventForwarder from 'leaflet-event-forwarder';
import Promise from 'promise';
import FullscreenControl from 'react-leaflet-fullscreen';
import yellowMarker from '../../../assets/img/icons/Location-Pin.png';
import './fleet.scss';
const blueMarker = L.icon({
    iconUrl: yellowMarker,
    iconSize: [16, 31],
    iconAnchor: [10, 30],
  });

  const gradient = {
    "1.0": "#F20000",
    "0.8": "#FF7000",
    "0.6": "#FCFE81",
    "0.4": "#E00000"
  }

  const greenIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const yellowIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const blueIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const redIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const greyIcon = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const typeSet = {
    "AVAILABLE": greenIcon,
    "ON_DUTY": yellowIcon,
    "OFFLINE": greyIcon,
    "NA": redIcon,
  }
const colorLightArray = ["#F9EBEA", "#F5EEF8", "#EAF2F8", "#E8F8F5", "#E9F7EF", "#FEF9E7", "#FDF2E9", "#FDFEFE", "#F4F6F6", "EBEDEF", "#FDEDEC ", "#F4ECF7", "#EBF5FB", "#E8F6F3", "#EAFAF1", "#FEF5E7", "#FBEEE6 ", "#F8F9F9", "#F2F4F4", "#EAECEE"];
const colorDarkArray = ["#641E16", "#512E5F", "#154360", "#0E6251", "#145A32 ", "#7D6608 ", "#784212", "#7B7D7D", "#4D5656 ", "#1B2631", "#78281F", "#4A235A ", "#1B4F72 ", "#0B5345", "#186A3B ", "#7E5109", "#6E2C00 ", "#626567", "#424949", "#17202A"];


class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxZoom: 18,
      minZoom: 0,
      popupDisplay: false,
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.liveFe !== this.props.liveFe){
      this.fixBoundingBox();
    }
  }

  componentDidMount() {
    this.prepareEventBubbler();
    this.fixBoundingBox();
  }

  zoomToBounds(mapInstance, ...rest) {
    var promise = new Promise((resolve, reject) => {
      if (mapInstance) {
        window.map = mapInstance;
        mapInstance.once('zoomend', function() {
          setTimeout(function() {
            resolve();
          }, 10);
        });
      }
    });

    mapInstance.fitBounds.apply(mapInstance, rest);
    return promise;
  }

  fixBoundingBox() {
    const { liveFe } = this.props;
    let jsonData = [];
    const mapInstance = this.map && this.map.leafletElement;
    if(liveFe && liveFe.length > 0){
      liveFe.map(x => {
          if(x.latitude){
            let coordinates = L.marker([Number(x.latitude), Number(x.longitude)], {icon: blueMarker});
            jsonData.push(coordinates);
          }
        })
      } else{
        let arrayBox = [[20.5937, 78.9629], [8.0883, 77.5385], [23.713, 68.032], [28.2815, 97.0162], [35.4639, 77.0439]]
        arrayBox.map(x => {
          if(x){
            let coordinates = L.marker(x, {icon: blueMarker});
            jsonData.push(coordinates);
          }
        })
      }
      var featureGroup = L.featureGroup(jsonData).addTo(mapInstance);
      mapInstance.fitBounds(featureGroup.getBounds());
      featureGroup.clearLayers();
    // mapInstance && mapInstance.setMaxBounds(featureGroup.getBounds());
    // this.zoomToBounds(mapInstance, featureGroup.getBounds()).then(() => {
    //   mapInstance && mapInstance.setMinZoom(mapInstance.getZoom());
    // });
  }

  highlightFeature(e) {
    var layer = e.target;
    layer.setStyle(
      Object.assign({}, layer.options.style, { weight: 5, dashArray: 4 })
    );

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight = (e) => {
    var layer = e.target;
    const defaultOptions = Object.assign({}, layer.defaultOptions.style);
    delete defaultOptions.options;
    this.GeoFenceJson &&
      this.GeoFenceJson.leafletElement &&
      this.GeoFenceJson.leafletElement.resetStyle(e.target);
  }

  bindGeoFenceName = (feature, layer) => {
    layer.bindTooltip(
      feature.properties.name,
      { sticky: true }
    );
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight
    });
  }

  renderDifferentLayers() {
    const { geofenceBoundary } = this.props;
    let geoFenceOArray = [];
    geofenceBoundary.map(x =>{
        let geoFenceObject = {
            "type": "Feature",
            "properties": {"name": x._key},
            "geometry": {
                "type": x.geometry.type,
                "coordinates": x.geometry.coordinates
            }
        }
        geoFenceOArray.push(geoFenceObject);
    })
    return (
      <>
        {geoFenceOArray.map((item,i) => 
            <GeoJSON
            key={i}
            data={item}
            onEachFeature={this.bindGeoFenceName}
            style={{
                // stroke: "red",
                // "stroke-width": 2,
                weight: 1,
                opacity: 1,
                color: colorLightArray[i],
                // dashArray: "3",
                fillOpacity: 0.4,
                fillColor: colorDarkArray[i],
            }}
            ref={ref => (this.GeoFenceJson = ref)}
            />
        )}
      </>
    );
  }

  getColor = (d) => {
    return d > 1000 ? '#800026' :
           d > 500  ? '#BD0026' :
           d > 200  ? '#E31A1C' :
           d > 100  ? '#FC4E2A' :
           d > 50   ? '#FD8D3C' :
           d > 20   ? '#FEB24C' :
           d > 10   ? '#FED976' :
                      '#FFEDA0';
}

  // handleSurveyClick = (feature, layer) => {
  //   return e => {
  //     // this.props.updateMapLevel(mapLevelConstants.SURVEY);
  //     // this.props.updateSelectedSurveyId('alandur_ward_f');
  //   };
  // }

  // bindsurveyGeoJson = (feature, layer) => {
  //   layer.bindTooltip(
  //     `fix this later ${feature.properties.village_na} i - click to view detailed map`,
  //     { sticky: true }
  //   );
  //   layer.on({
  //     click: this.handleSurveyClick(feature, layer),
  //     mouseover: this.highlightFeature,
  //     mouseout: this.resetHighlight
  //   });
  // }

  prepareEventBubbler() {
    const map = this.map.leafletElement;
    if (map) {
    //   const container = map.createPane('stack-container');
      const myEventForwarder = new L.eventForwarder({
        map: map,
        events: {
          click: true,
          mousemove: true
        },
        throttleMs: 100,
        throttleOptions: {
          leading: true,
          trailing: false
        }
      });
      myEventForwarder.enable();
    }
  }

  addpop = (item) => {
    // const _this = this;
    this.props.getRidersDetails(item.userId).then(res => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
        let popupContent = `<div className="popcontent">Name : ${item.name}</br>
        Number : ${item.number}</br>
        Order Details : <br/>
        OrderID : ${res ?.data ?.response ?.orderBO ?.waybillNo}</br>
        Status : ${res ?.data ?.response ?.orderBO ?.status}<br/>
        Geofence Details : ${res ?.data ?.response ?.geofenceDetails}</br>
        Tagged Geofences : ${res ?.data ?.response ?.taggedGeofence ?.join(', ')}</br>
        Last Location Ping : ${moment(res ?.data ?.response ?.lastLocationping).fromNow()} (<small>${moment(res ?.data ?.response ?.lastLocationping).format('DD/MM/YYYY h:mm a')}</small>)</br>
        Order Completed : ${res ?.data ?.response ?.completedOrders}</br>
        Orders Ignored : ${res ?.data ?.response ?.ignoredOrders}</br></br>
        <button id="button-submit" type="primary">Notify</button></div>`;
        this.markerPoint.leafletElement.bindPopup(popupContent).openPopup().setLatLng([item.latitude,item.longitude]);
        this.markerPoint.leafletElement.setIcon(typeSet[item.status]);
        var buttonSubmit = L.DomUtil.get('button-submit');
        L.DomEvent.addListener(buttonSubmit, 'click', (e) => {
          L.DomEvent.preventDefault(e);
          L.DomEvent.stopPropagation(e);
          this.handleClick1(item);
        });
      } else {
        return;
      }
    });
  }

  handleClick1 = (id) => {
    this.props.getNotify(id);
  }

  getIcon = (data) => {
    return typeSet[data];
  }

  renderDifferentMarkers =() =>{
    const { liveFe } = this.props;
    return (
        <>
          {liveFe.map((item,i) => 
            <Marker
            key={i}
            className=""
            position={[item ?.latitude, item ?.longitude]}
            interactive={true}
            icon={typeSet[item.status]}
            keyboard={true}
            ref={ref => (this.markerPoint = ref)}
            onClick={() => this.addpop(item)}>
            <Tooltip width={150} direction="auto" className="leadPopform">
            <div className="tooltiplsn">
            <div className="tooltipLeads"><b>{item.name}</b></div>
            <div className="tooltipLeads"><b>{item.number}</b></div>
            <div className="tooltipLeads"><b>{item.status}</b></div>
            </div>
            </Tooltip>
            </Marker>
          )}
        </>
      );
  }

  renderHeatLayesrs = () => {
    const { hotspots } = this.props;
    let heatArray = [];
    hotspots.map(x =>{
        heatArray.push([x.pickup_latitude, x.pickup_longitude]);
    })
    return (
        <>
                    <HeatmapLayer
                    key="heat"
                    fitBoundsOnLoad
                    fitBoundsOnUpdate
                    points={heatArray}
                    longitudeExtractor={m => m[1]}
                    latitudeExtractor={m => m[0]}
                    gradient={gradient}
                    radius={10}
                    blur={5}
                    intensityExtractor={m => parseFloat(m[2])}
                  />
        </>
      );
  }
  

  render() {
    return (
      <Map
        onClick={this.onClick}
        ref={ref => (this.map = ref)}
        attributionControl={true}
        className="mapControlsbox"
      >
        <FullscreenControl position="topleft"/>
        <LayersControl position='topright'>
            <LayersControl.BaseLayer name='Map View' checked>
                <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name="FE" checked={this.props.currentstatus.fe}>
              <FeatureGroup>
              {this.props ?.liveFe &&
                    this.renderDifferentMarkers()
                }
              </FeatureGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Hotspot" checked={this.props.currentstatus.hotspot}>
              <FeatureGroup>
              {this.props ?.hotspots &&
                    this.renderHeatLayesrs()
                }
              </FeatureGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay name="Geofence" checked={this.props.currentstatus.geofence}>
              <LayerGroup>
                  {this.props ?.geofenceBoundary &&
                  this.renderDifferentLayers()}
              </LayerGroup>
            </LayersControl.Overlay>
        </LayersControl>
      </Map>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getRidersDetails: (id) => dispatch(actions.getRidersDetails(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapComponent);
