export const initialState = {
    loading: false
}

export const GET_FE_SUMMARY_BEGIN = 'GET_FE_SUMMARY_BEGIN';
export const GET_FE_SUMMARY_SUCCESS = 'GET_FE_SUMMARY_SUCCESS';
export const GET_FE_SUMMARY_FAILURE = 'GET_FE_SUMMARY_FAILURE';

export const GET_FE_SUMMARY_PAGINATION_BEGIN = 'GET_FE_SUMMARY_PAGINATION_BEGIN';
export const GET_FE_SUMMARY_PAGINATION_SUCCESS = 'GET_FE_SUMMARY_PAGINATION_SUCCESS';
export const GET_FE_SUMMARY_PAGINATION_FAILURE = 'GET_FE_SUMMARY_PAGINATION_FAILURE';

export const GET_CITY_LIST_BEGIN = 'GET_CITY_LIST_BEGIN';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_FAILURE = 'GET_CITY_LIST_FAILURE';

export const GET_CITY_LIST_ID_BEGIN = 'GET_CITY_LIST_ID_BEGIN';
export const GET_CITY_LIST_ID_SUCCESS = 'GET_CITY_LIST_ID_SUCCESS';
export const GET_CITY_LIST_ID_FAILURE = 'GET_CITY_LIST_ID_FAILURE';

export const GET_KYC_SUMMARY_BEGIN = 'GET_KYC_SUMMARY_BEGIN';
export const GET_KYC_SUMMARY_SUCCESS = 'GET_KYC_SUMMARY_SUCCESS';
export const GET_KYC_SUMMARY_FAILURE = 'GET_KYC_SUMMARY_FAILURE';

export const GET_KYC_SUMMARY_PAGINATION_BEGIN = 'GET_KYC_SUMMARY_PAGINATION_BEGIN';
export const GET_KYC_SUMMARY_PAGINATION_SUCCESS = 'GET_KYC_SUMMARY_PAGINATION_SUCCESS';
export const GET_KYC_SUMMARY_PAGINATION_FAILURE = 'GET_KYC_SUMMARY_PAGINATION_FAILURE';

export const GET_USER_DATA_BEGIN = 'GET_USER_DATA_BEGIN';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const GET_USER_APPROV_BEGIN = 'GET_USER_APPROV_BEGIN';
export const GET_USER_APPROV_SUCCESS = 'GET_USER_APPROV_SUCCESS';
export const GET_USER_APPROV_FAILURE = 'GET_USER_APPROV_FAILURE';

export const GET_ONDUTY_USER_DATA_BEGIN = 'GET_ONDUTY_USER_DATA_BEGIN';
export const GET_ONDUTY_USER_DATA_SUCCESS = 'GET_ONDUTY_USER_DATA_SUCCESS';
export const GET_ONDUTY_USER_DATA_FAILURE = 'GET_ONDUTY_USER_DATA_FAILURE';

export const GET_FETCH_TEMPLATE_BEGIN = 'GET_FETCH_TEMPLATE_BEGIN';
export const GET_FETCH_TEMPLATE_SUCCESS = 'GET_FETCH_TEMPLATE_SUCCESS';
export const GET_FETCH_TEMPLATE_FAILURE = 'GET_FETCH_TEMPLATE_FAILURE';

export const GET_RUNSHEET_LIST_BEGIN = 'GET_RUNSHEET_LIST_BEGIN';
export const GET_RUNSHEET_LIST_SUCCESS = 'GET_RUNSHEET_LIST_SUCCESS';
export const GET_RUNSHEET_LIST_FAILURE = 'GET_RUNSHEET_LIST_FAILURE';