import * as CONSTANTS from './constants';
import * as apiUtils from '../../../../../common/utils/apiGateway';
import { notifyApiError } from '../../../../../common/utils/utils';

const partnerId = localStorage.getItem('loggedPartnerId');

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

function validateServiceableBegin() {
    return {
        type: CONSTANTS.VALIDATE_SERVICESABLE_BEGIN,
        payload: null
    }
}

function validateServiceableSuccess(response) {
    return {
        type: CONSTANTS.VALIDATE_SERVICESABLE_SUCCESS,
        payload: response
    }
}

function validateServiceableFailure(response) {
    return {
        type: CONSTANTS.VALIDATE_SERVICESABLE_FAILURE,
        payload: response
    }
}

function CustomerCreationBegin() {
    return {
        type: CONSTANTS.CUSTOMER_CREATION_BEGIN,
        payload: null
    }
}

function CustomerCreationSuccess(response) {
    return {
        type: CONSTANTS.CUSTOMER_CREATION_SUCCESS,
        payload: response
    }
}

function CustomerCreationFailure(response) {
    return {
        type: CONSTANTS.CUSTOMER_CREATION_FAILURE,
        payload: response
    }
}

function CustomerUpdationBegin() {
    return {
        type: CONSTANTS.CUSTOMER_CREATION_BEGIN,
        payload: null
    }
}

function CustomerUpdationSuccess(response) {
    return {
        type: CONSTANTS.CUSTOMER_CREATION_SUCCESS,
        payload: response
    }
}

function CustomerUpdationFailure(response) {
    return {
        type: CONSTANTS.CUSTOMER_CREATION_FAILURE,
        payload: response
    }
}

export const createCustomers = (data) => dispatch => {
    dispatch(CustomerCreationBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `/b2b/v1/partners/${partnerId}/customers`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(CustomerCreationSuccess(response.data.response))
        })
        .catch(error => dispatch(CustomerCreationFailure(error)));
}

export const updateCustomers = (data, id) => dispatch => {
    dispatch(CustomerUpdationBegin);
    apiUtils.apiRequest({
        method: 'put',
        url: `/b2b/v1/partners/${partnerId}/customers/${id}`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(CustomerUpdationSuccess(response.data.response))
        })
        .catch(error => dispatch(CustomerUpdationFailure(error)));
}

export const validateServiceable = (data) => dispatch => {
    dispatch(validateServiceableBegin);
    apiUtils.apiRequest({
        method: 'post',
        url: `b2b/v1/locations/validate/serviceable`,
        data
    })
        .then(response => {
            if (checkHttpStatus(response.data ?.status))
                dispatch(validateServiceableSuccess(response.data.response.responses[0]))
        })
        .catch(error => dispatch(validateServiceableFailure(error)));
}