import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../settingAction';
import { isPermitted } from '../../../common/utils/utils';

// Third party plugins
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';
import { modal as Modal } from '../../ui/modal/Modal';
import { select as Select } from '../../ui/select/Select';
import { throttle, debounce } from "throttle-debounce";
import { Card, Icon, Divider } from 'antd';

// CSS
import cx from 'classnames';
import './Pincode.scss';

class Pincode extends Component {
    constructor(props){
        super(props);
        this.state = this.generateinitialStateData();
        this.autocompleteSearchDebounced = debounce(200, this.newPincodeEntry);
    }

    componentDidMount(){
        // getting the initial state object from API
        this.props.getStateDetails();
    }

    componentDidUpdate(){

        if(this.props.resetForm){
            // if the pincode has been updated or added, reset the enitre form and call the state object API again
            this.props.getStateDetails();
            this.setState(this.generateinitialStateData());
            this.closeModal();
            notifyApiSuccess('Successfully Updated', undefined, null);
        }
    }

    generateinitialStateData = () => ({
        selectedState: 'Search & Select State',
        selectedCity: 'Search & Select City',
        seletedPincode: 'Search & Select or Add Pincode',
        cityList: null,
        pincodeList: null,
        city: null,
        pincode: null,
        disableNewEntry: true,
        invalidInput: false,
        errorText: '',
        validSearchPinCode: null,
        newPincode: false,
        cityId: null,
        enableButton: false,
        disableInputs: false
    })

    handleProvinceChange = (value) => {
        const cityList = this.props?.statesDetails.map((stateDetail)=>{
            if(stateDetail.stateName && stateDetail.stateName === value){
                this.setState({
                    selectedState: value,
                    cityList: stateDetail['cities'],
                    selectedCity: 'Search & Select City',
                    seletedPincode: 'Search & Select or Add Pincode',
                    pincodeList: null,
                    pincode: null,
                    city: null,
                    cityId: null,
                    enableButton: false,
                    disableInputs: false
                })
            }
        })
    }

    generateCityPincodeStructure = (overideValue) => {
        return {
            "cityPincodeMapping" : {
                [this.state.cityId] : [this.state.seletedPincode]
            },
            'override': overideValue
        }
    }
    
    onCityChange = (value) => {
        const data = this.state?.cityList.map((city)=>{
            if(city.cityName && city.cityName === value){
                this.setState({
                    cityId: city.id,
                    pincodeList: city['pincodes'],
                    selectedCity: value
                });
                return
            }
        });

        this.setState({enableButton: true})
    }

    onPincodeChange = (value) => {
        this.newPincodeEntry(value, true)
    }

    newPincodeEntry = (data, isDirectSelectionFromDropDown) => {
        const pincodeLength = 6;

        if(!data || !data.length ){
            this.setState({
                disableNewEntry: true,
                invalidInput: false,
                validSearchPinCode: null,
                errorText: null,
                seletedPincode: null
            });
            return
        }
        
        if(!Number(data)){
            this.setState({
                invalidInput: true,
                disableNewEntry: true,
                errorText: 'Invalid Search',
                validSearchPinCode: null,
                seletedPincode: null
            });
        }else if(data.length > 6 || data.length < 6){
            this.setState({
                invalidInput: true,
                disableNewEntry: true,
                errorText: 'Pincode length must be equal to 6',
                validSearchPinCode: null,
                seletedPincode: null
            });
        }else if(data.length === pincodeLength){
            let pincodeExistInPincodeList = true ? isDirectSelectionFromDropDown: false;
            
            if(!isDirectSelectionFromDropDown){
                this.state.pincodeList.map(pincode => {
                    pincodeExistInPincodeList = 
                        pincode.zipcode && (Number(data) === Number(pincode.zipcode));
                });
                this.setState({
                    disableNewEntry: pincodeExistInPincodeList,
                    invalidInput: false,
                    errorText: '',
                    validSearchPinCode: Number(data),
                    newPincode: !pincodeExistInPincodeList
                })
            }else{
                this.setState({
                    disableNewEntry: pincodeExistInPincodeList,
                    seletedPincode: Number(data),
                    invalidInput: false,
                    errorText: '',
                    validSearchPinCode: Number(data),
                    newPincode: !pincodeExistInPincodeList
                })
            }
        }
    }

    updatePincode = () => {
        // To update pincode under another city
        this.props.updatePincodeReq(this.generateCityPincodeStructure(true));
        this.setState({disableInputs: true})
    }

    addPincode = () => {
        // call the API to check whether the entered PINCODE exist in other states
        this.props.checkForDuplicatePincode(this.generateCityPincodeStructure(false));
        this.setState({disableInputs: true})
    }

    onPincodeSearch = e => {
        this.autocompleteSearchDebounced(e);
    }

    addNewPincode = (e) => {
        if(!this.state.validSearchPinCode ||
            Number(this.state.validSearchPinCode) === Number(this.state.seletedPincode)){
            return
        }
        this.setState({
            seletedPincode: this.state.validSearchPinCode,
            disableNewEntry: true,
            invalidInput: false
        })
    }

    closeModal = () => {
        this.props.checkForDuplicatePincode(null);
        this.setState({disableInputs: false})
    }

    render() {
        const Option = Select.Option;

        let pincodeClass = cx({
            'pincode-select-search': true,
            'disable-new-entry': this.state.disableNewEntry,
            'valid-input': !this.state.disableNewEntry,
            'invalid-input': this.state.invalidInput
        }),
        invalidSearch = cx({
            'pincode-select-search-invalid': true,
            'invalid-input': this.state.invalidInput
        });

        return (
            <>
                <Card
                title="Pincode Management"
                className="pincode-select-wrapper load-share-custom"
                >
                    <div>
                        <label>STATE <span>*</span></label>
                        <Select
                        placeholder="select state"
                        showSearch={true}
                        autoFocus={true}
                        dropdownClassName='select-dropdown'
                        disabled={!this.props ?.statesDetails?.length || this.state.disableInputs}
                        className="god-select pincode-select"
                        value={this.state.selectedState}
                        onSelect={this.handleProvinceChange}
                        >
                        {this.props ?.statesDetails?.map((state) => <Option key={state?.stateName} value={state?.stateName}>{state?.stateName}</Option>)}
                        </Select>
                    </div>

                    <div>
                        <label>
                            CITY <span>*</span>
                        </label>
                        <Select
                        placeholder="select city"
                        showSearch={true}
                        dropdownClassName='select-dropdown'
                        disabled={!this.state ?.cityList?.length || this.state.disableInputs}
                        className="god-select pincode-select"
                        value={this.state.selectedCity}
                        onChange={this.onCityChange}
                        >
                        {this.state ?.cityList?.map((city) => <Option key={city?.cityName} value={city?.cityName}>{city?.cityName}</Option>)}
                        </Select>
                    </div>
                                
                    <div>
                        <label>PINCODE <span>*</span></label>
                        <Select
                        placeholder="select pincode"
                        showSearch={true}
                        dropdownClassName='select-dropdown'
                        onSearch={(e, menu) => {this.onPincodeSearch(e, menu)}}
                        disabled={!this.state ?.pincodeList?.length || this.state.disableInputs}
                        className="god-select pincode-select"
                        onChange={this.onPincodeChange}
                        value={this.state.seletedPincode}
                        dropdownRender={menu => (
                            <div>
                            <div
                                className={pincodeClass}
                                onMouseDown={(e)=>{this.addNewPincode(e)}}
                                style={{ padding: '8px', cursor: 'pointer' }}>
                                <Icon type="plus" /> Update Pincode Under Selected City?
                            </div>

                            <div
                                className={invalidSearch}
                                style={{ padding: '8px', cursor: 'pointer', color: 'red' }}>
                                {this.state.errorText}
                            </div>
                                {this.state.invalidInput ? <Divider style={{ margin: '4px 0' }} />: null} 
                            {menu}
                            </div>
                            )}
                        >
                        {this.state ?.pincodeList?.map((pincode) => <Option key={pincode?.zipcode} value={pincode?.zipcode}>{pincode?.zipcode}</Option>)}
                        </Select>
                    </div>
                    <div className="action-items">
                    {this.state.newPincode ? 
                                <button 
                                disabled={!this.state.enableButton || this.state.disableInputs}
                                onClick={this.addPincode}
                                className="primary-btn float-right cursor-pointer">
                                    Update
                                </button> : 
                                <button 
                                disabled={!this.state.enableButton || this.state.disableInputs}
                                onClick={this.updatePincode}
                                className="primary-btn float-right cursor-pointer">
                                    Add
                                </button>} 
                    </div>
                </Card>
                <Modal
                    title="Pincode Overwrite"
                    visible={this.props.showPincodeUpdateModal}
                    width="30vw"
                    onCancel={this.closeModal}
                    centered
                    className="load-share-custom"
                    destroyOnClose={true}
                    footer={<div class="clearfix">
                    <button 
                    onClick={this.closeModal}
                    className="cursor-pointer transparent-btn">Cancel Update</button>
                    <button 
                    onClick={this.updatePincode}
                    className="primary-btn float-right cursor-pointer">Confirm Update</button> </div>}
                >
                    <div>
                        Pincode <span className={'text-blue'}>{this.state.seletedPincode}</span> is mapped to 
                        <span className={'text-bold'}>“{this.props.pincodeDetails
                        && this.props.pincodeDetails['cityLinkedPincode']
                        && this.props.pincodeDetails['cityLinkedPincode']['city']
                        && this.props.pincodeDetails['cityLinkedPincode']['city']['name']}”</span>. 
                        By confirming, this pincode will be removed and updated to 
                        “<span className={'text-bold'}>{this.state.selectedCity}</span>“
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => ({
    statesDetails: state.settingsReducer.statesDetails,
    pincodeDetails: (state.settingsReducer.pincodeDetails 
        && state.settingsReducer.pincodeDetails['cityLinkedPincodeResponse']
        && state.settingsReducer.pincodeDetails['cityLinkedPincodeResponse'][0]),
    showPincodeUpdateModal: state.settingsReducer.metaData?.showPincodeUpdateModal,
    resetForm: (state.settingsReducer.pincodeDetails 
        && state.settingsReducer.pincodeDetails['cityLinkedPincodeResponse']
        && state.settingsReducer.pincodeDetails['cityLinkedPincodeResponse'][0]
        && state.settingsReducer.pincodeDetails['cityLinkedPincodeResponse'][0]['isCityPincodeUpdated'])
});

const mapDispatchToProps = dispatch => ({
    getStateDetails: () => dispatch(actions.getStateDetails()),
    checkForDuplicatePincode: (params) => dispatch(actions.pincodeUpdate(params)),
    updatePincodeReq: (params) => dispatch(actions.pincodeUpdate(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pincode);