import { apiRequest } from "../../common/utils/apiGateway";
import { BASE_URL_TYPES, Constants } from "../../common/utils/constants";

export default class RateCardService {

  // new api for sumary v3
  getUserRateCard() {
    const url = `trule/user/${localStorage.getItem("feuserId")}/rate-card`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  getUserRateCardV1() {
    const url = `trule/user/${localStorage.getItem("felocationId")}/${localStorage.getItem("feClusterId")}/${localStorage.getItem("feOrgId")}/rate-card`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
}
