import { combineReducers } from "redux";
import { trackingReducer } from "../components/tracking/reducer";
import { trackingPopupReducer } from "../components/TrackingPopup/reducer";
import { suspiciousReducer } from "../components/suspicious/reducer";
import { appReducer } from "../app/appReducer";
import { auditReducer } from "../components/inventory/audit/reducer";
import { headerReducer } from "../components/shared/header/reducer";
import { trackingListingReducer } from "../components/tracking/TrackingListing/reducer";
import { settingsReducer } from "../components/settings/settingsReducer";
import { ingestReducer } from "../components/reports/ingest/reducer";
import { lsnalertReducer } from "../components/shared/Lsnalert/reducer";
import { ReatilerCustomerReducer } from "../components/inbound/inbound-create/b2b-lite-booking/customer-management/reducer";
import { b2bLiteBookingReducer } from "../components/inbound/inbound-create/b2b-lite-booking/reducer";
import { CustomerReducer } from "../components/settings/customer/reducer";
import { ZoneReducer } from "../components/shared-components/zone/reducer";
import { ZoneMappingReducer } from "../components/shared-components/zone-mapping/reducer";
import { UserReducer } from "../components/settings/user/reducer";
import { RoleReducer } from "../components/settings/role/reducer";
import { LocationReducer } from "../components/settings/location/reducer";
import { PromiseTimeReducer } from "../components/shared-components/customer-promise-time/reducer";
import { ManageFleetReducer } from "../components/manage-fleet/map/reducer";
import { ScanReducer } from "../components/inbound/scan/reducer";
import { assetsReducer } from "../components/settings/asset/reducer";
import { PartnerCustomerUserReducer } from "../components/settings/customer-invite/reducer";
import { FleetReducer } from "../components/hyperlocal/fleet/reducer";
import { vendorCreateReducer } from "../components/settings/vendor/reducer";
import { CustomConfigReducer } from "../components/settings/config/reducer";
import { B2cBookingReducer } from "../components/inbound/inbound-create/b2c-booking/reducer";
import { RouteReducer } from "../components/settings/route/reducer";
import { codReducer } from "../components/cod/reducer";

const rootReducer = combineReducers({
  tracking: trackingReducer,
  trackingDetails: trackingPopupReducer,
  trackingListing: trackingListingReducer,
  suspicious: suspiciousReducer,
  app: appReducer,
  audit: auditReducer,
  header: headerReducer,
  settingsReducer: settingsReducer,
  ingest: ingestReducer,
  lsnalert: lsnalertReducer,
  codReducer: codReducer,
  RetailerCustomer: ReatilerCustomerReducer,
  settingsCustomers: CustomerReducer,
  zone: ZoneReducer,
  zoneMapper: ZoneMappingReducer,
  settingsUsers: UserReducer,
  settingsRoles: RoleReducer,
  settingLocation: LocationReducer,
  promiseTime: PromiseTimeReducer,
  manageFleet: ManageFleetReducer,
  scan: ScanReducer,
  assets: assetsReducer,
  partnerCustomer: PartnerCustomerUserReducer,
  FleetReducer: FleetReducer,
  vendor: vendorCreateReducer,
  customConfig: CustomConfigReducer,
  settingsRoute: RouteReducer,
  b2bLiteBooking: b2bLiteBookingReducer,
  b2cBooking: B2cBookingReducer,
});

export default rootReducer;
