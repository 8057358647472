import axios from 'axios';
import {getToken} from './storageUtil'
import store from './store';
import {BASE_URL_TYPES, Constants} from '../common/utils/constants';

export function fetch(url, endpoint) {
    store.dispatch({
        type: 'API_PROCESS'
    });
    return axios
        .get(url + endpoint, {
            headers: Constants.HTTP_HEADERS,
            timeout: 1000 * Constants.HEADER_TIMEOUT_MS
        })
}

export function fetch_telematic_data_from_beecon(url, data) {
    store.dispatch({
        type: 'API_PROCESS'
    });
    return axios
        .post(url,data, {
            headers: Constants.HTTP_HEADERS,
            timeout: 1000 * Constants.HEADER_TIMEOUT_MS
        })
}
export function fetch_data_for_helios_post(url, data) {
  store.dispatch({
      type: 'API_PROCESS'
  });
  return axios
      .post(url,data, {
          headers: Constants.HTTP_HEADERS,
          timeout: 1000 * Constants.HEADER_TIMEOUT_MS
      })
}
export function fetch_sim_data_from_beecon(url, data) {
  store.dispatch({
      type: 'API_PROCESS'
  });
  return axios
      .post(url,data, {
          headers: Constants.HTTP_HEADERS,
          timeout: 1000 * Constants.HEADER_TIMEOUT_MS
      })
}
export function set(url, data) {
    store.dispatch({
        type: 'API_PROCESS'
    });
    return axios
        .post(url, data, {
            headers: Constants.HTTP_HEADERS,
            timeout: 1000 * Constants.HEADER_TIMEOUT_MS
        });
}

export function update(url, data) {
    store.dispatch({
        type: 'API_PROCESS'
    });
    return axios
        .put(url, data, {
            headers: Constants.HTTP_HEADERS,
            timeout: 1000 * Constants.HEADER_TIMEOUT_MS
        });
}

export function destroy(url, endpoint) {
    store.dispatch({
        type: 'API_PROCESS'
    });
    return axios
        .delete(url + endpoint, {
            headers: Constants.HTTP_HEADERS
        });
}
