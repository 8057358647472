import React, { Component } from 'react';
import { Card, Row, Col, Select, Input, Button, Modal, List, Switch, Icon, Popover, Form, Tooltip, DatePicker, Radio, notification, Checkbox, Table, Empty, Divider } from 'antd';
import './fleet.scss';
import * as actions from './action';
import { connect } from 'react-redux';
import * as utils from "../../../common/utils/utils";
import MapComponent from './map-component';
import moment from 'moment';
import { canAllow, windowRedirect } from '../../../common/utils/utils';
import { USER_LOGGEDIN_INFO } from '../../../common/utils/constants';
import { permissionTags } from '../../../configs/permissionTags';
import { object } from 'prop-types';
import { Constants } from '../../../common/utils/constants';
import NotifyImage from '../../../assets/img/icons/notify.png';
import MapToFleet from './MapToFleet';
import SuperSelect from "../../ui/SuperSelect/SuperSelect";
import { apiRequest } from '../../../common/utils/apiGateway';
const { confirm } = Modal;
const Option = Select.Option;
// const colorSet = {
//   "AVAILABLE": "green",
//   "ON_DUTY": "orange",
//   "OFFLINE": "red",
//   "NA": "grey",
// }

// const typeSet = {
//   "AVAILABLE": "Available",
//   "ON_DUTY": "On Duty",
//   "OFFLINE": "Offline",
//   "NA": "NA",
// }

const hotSpotsdata = [
  { id: 0, name: "Last 7 Days", createdAtFrom: moment().subtract(7, 'd').startOf("day").add(4, 'hours').valueOf(), createdAtTo: moment().valueOf() },
  { id: 1, name: `Last ${moment().format('dddd').substring(0, 3)}`, createdAtFrom: moment().subtract(7, 'd').startOf("day").add(4, 'hours').valueOf(), createdAtTo: moment().subtract(6, 'd').startOf("day").add(4, 'hours').valueOf() },
  { id: 2, name: "Last 1 Hour", createdAtFrom: moment().subtract(1, 'hours').valueOf(), createdAtTo: moment().valueOf() },
]

const setinitialFilters = {
  attendanceStatuses: [
    { id: 0, name: "Available", value: false, storedValue: "AVAILABLE", apiValue: "userStatusAvailableCount", disabled: false },
    { id: 1, name: "On Duty", value: false, storedValue: "ON_DUTY", apiValue: "userStatusOnDutyCount", disabled: false },
    { id: 2, name: "Offline", value: false, storedValue: "OFFLINE", apiValue: "userStatusOfflineCount", disabled: false },
    { id: 3, name: "NA", value: false, storedValue: "NA", apiValue: "userStatusNACount", disabled: true },
  ],
  hyperlocalPermission: [
    { id: 0, name: "Enabled", value: false, storedValue: "ENABLED", apiValue: "hyperlocalPermissionEnabledCount", disabled: false },
    { id: 1, name: "Disabled", value: false, storedValue: "DISABLED", apiValue: "hyperlocalPermissionDisabledCount", disabled: false },
  ],
  hyperlocalEligibility: [
    { id: 0, name: "Eligible", value: false, storedValue: "ELIGIBLE", apiValue: "hyperlocalEligibilityEligibleCount", disabled: false },
    { id: 1, name: "In-Eligible", value: false, storedValue: "INELIGIBLE", apiValue: "hyperlocalEligibilityInEligibleCount", disabled: false },
  ],
  hyperlocalAvailability: [
    { id: 0, name: "Available", value: true, storedValue: "ONLINE", apiValue: "hyperlocalUserAvailabilityAvailableCount", disabled: false },
    { id: 1, name: "Not Available", value: false, storedValue: "OFFLINE", apiValue: "hyperlocalUserAvailabilityNotAvailableCount", disabled: false },
  ],
  orderStatus: [
    { id: 0, name: "Idle FE", value: false, storedValue: "NO_ORDER", apiValue: "hyperlocalUserIdleCount", disabled: false },
    { id: 1, name: "Out for pickup", value: false, storedValue: "OUT_FOR_PICKUP", apiValue: "orderStatusOutForPickUp", disabled: false },
    { id: 2, name: "At pickup", value: false, storedValue: "AT_PICKUP", apiValue: "orderStatusAtPickUp", disabled: false },
    { id: 3, name: "Out for delivery", value: false, storedValue: "OUT_FOR_DELIVERY", apiValue: "orderStatusOutForDelivery", disabled: false },
    { id: 4, name: "At delivery", value: false, storedValue: "AT_DELIVERY", apiValue: "orderStatusAtDelivery", disabled: false },
  ],
}


class MapviewDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      listView: false,
      initalFilters: setinitialFilters,
      initalFiltersBackup: {},
      notifyModal: false,
      templateSelected: [],
      selectedFeNames: [],
      selectedFe: [],
      customerId: [],
      listfiltered: false,
      allchecked: true,
      outsidechecked: true,
      applyfilterButton: false,
      outsideUserIds: [],
      hotspotValue: hotSpotsdata[2].id,
      currentstatus: {
        "fe": false,
        "geofence": true,
        "hotspot": false,
      },
      legendBox: false,
      fleetPermission: {
        read: canAllow(permissionTags.hyperlocal.mapview.read),
        update: canAllow(permissionTags.hyperlocal.mapview.update),
        create: canAllow(permissionTags.hyperlocal.mapview.create),
        delete: canAllow(permissionTags.hyperlocal.mapview.delete),
      },
      groupedZones: [],
      inZonesTotal: 0,
      outSideZonesTotal: 0,
      taggedFesTotal: 0,
      unTaggedFesTotal: 0,
      totalZones: 0,
      groupedZoneData: [],
      selectedZone: '',
      zoneLoading: false,
      geofence: [],
      iconLoading: false
    };
    this.InputSearchRef = React.createRef();
    this.InputSearchdtaRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.props.locationIds &&
      this.props.cityId
    ) {
      this.setState(
        { locationIds: this.props.locationIds, cityId: this.props.cityId },
        () => {
          this.ApplyOk();
        }
      );
    }
    this.checkDateRange();
    this.props.getTemplates();
    this.props.getCustomer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cityList !== this.props.cityList) {
      this.setState({ cityList: this.props.cityList });
    }

    if (prevProps.customerList !== this.props.customerList) {
      const customerListing = this.getNewCustomerList(this.props.customerList);
      this.setState({ customerList: customerListing, customerId: [0] });
    }

    if (prevProps.cityListById !== this.props.cityListById) {
      this.setState({ cityListById: this.props.cityListById });
    }

    if (prevProps.geofence !== this.props.geofence) {
      this.setCheckVariable(this.props.geofence);
      // this.setState({ geofence : this.props.geofence})
    }

    if (prevProps.hotspots !== this.props.hotspots) {
      this.setState({ hotspots: this.props.hotspots })
    }

    if (prevProps.liveFEsonMap !== this.props.liveFEsonMap) {
      let filterFeNew = this.getFilterNewData(this.props.liveFEsonMap)
      this.insidePolygonCount(filterFeNew);
      this.setState({ liveFEsonMap: filterFeNew, liveFEsonMap2: filterFeNew, filteredfes: filterFeNew })
    }

    if (prevProps.templatesData !== this.props.templatesData) {
      this.setState({ templatesData: this.props.templatesData })
    }

  }

  getNewCustomerList = (data) => {
    data.sort((a, b) => {
      let textA = a.code.toUpperCase();
      let textB = b.code.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    let defaultValue = [{ "id": 0, "code": "Customers (All)" }];
    let finalArray = [...defaultValue, ...data];
    return finalArray
  }

  getFilterNewData = (data) => {
    let finalArray = [];
    if (data.length > 0) {
      data.map(x => {
        if (x?.latitude) {
          finalArray.push(x);
        }
      })
    }
    return finalArray
  }

  setCheckVariable = (data) => {
    data.map(x => {
      x.checkStatus = true;
    })
    this.setState({ geofence: data })
  }

  resetFilters = e => {
    let resetFiltersData = { ...this.state.initalFilters };
    Object.keys(resetFiltersData).map(x => {
      resetFiltersData[x].map(y => {
        if (y.name === "Enabled") {
          y.value = true;
        } else {
          y.value = false;
        }

      })
    })
    this.setState({
      visible: true,
      initalFilters: resetFiltersData
    });
  }


  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  onLocationChange = (e) => {
    if (e) {
      this.props.getCityListById(e);
      this.setState({ locationIds: [], cityId: e, geofence: [], hotspots: [], filteredfes: [], legendBox: false })
    } else {
      this.setState({ locationIds: [], cityId: undefined, geofence: [], hotspots: [], filteredfes: [], legendBox: false })
    }
  }

  onpartnerChange = (e) => {
    if (e) {
      this.setState({ locationIds: e })
    } else {
      this.setState({ locationIds: [] })
    }
  }

  showModal = () => {
    let setinitial = { ...this.state.initalFilters };
    let applyfilterButton = true;
    Object.keys(setinitial).map(x => {
      setinitial[x].map(y => {
        if (y.value) {
          applyfilterButton = false;
        }
      })
    })
    this.setState({
      visible: true,
      applyfilterButton: applyfilterButton
    });
  };

  applySearch = () => {
    let checkgeoJson = this.state?.liveFEsonMap;
    if (checkgeoJson?.length > 0) {
      this.setState({
        filteredfes: [...this.state.filteredfes],
        listView: !this.state.listView
      })
    }
  }

  showList = (e) => {
    let value;
    let checkgeoJson = this.state?.liveFEsonMap;
    let filtergeoJson = [];
    if (checkgeoJson?.length > 0) {
      if (e.target.value.length >= 0 && e.target.value !== "") {
        value = true;
      } else {
        value = false;
      }
      if (e.target.value && e.target.value !== "") {
        let filtergeoJson1 = checkgeoJson?.filter(x => x.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
        let filtergeoJson2 = checkgeoJson?.filter(x => x.number.indexOf(e.target.value.toLowerCase()) !== -1);
        filtergeoJson = filtergeoJson1.concat(filtergeoJson2);
      } else {
        filtergeoJson = checkgeoJson;
      }
      this.setState({
        listView: value,
        filteredfes: filtergeoJson,
        listfiltered: false,
        searchName: e.target.value
      })
    }
  }

  onMapViewChange = (value) => {
    let current = { ...this.state.currentstatus }
    current[value] = !current[value];
    this.setState({
      currentstatus: current
    })
  }

  filterValuesChnages = (data, id, value) => {
    let setinitial = { ...this.state.initalFilters };
    let initalFilter = { ...this.state.initalFiltersBackup };
    let idsArray = [];
    let applyfilterButton = true;
    Object.keys(initalFilter).map(t => {
      if (t === data) {
        initalFilter[t].map(u => {
          idsArray.push(u.id);
        })
      }
    })
    if (Object.keys(initalFilter).length > 0 && Object.keys(initalFilter).includes(data)) {
      Object.keys(initalFilter).map(x => {
        if (x === data) {
          if (!idsArray.includes(id)) {
            initalFilter[data].push({
              id: id,
              value: value
            })
          }
        }
      })
    } else {
      initalFilter[data] = [{
        id: id,
        value: value
      }]
    }
    setinitial[data].map(x => {
      if (x.id === id) {
        x.value = !x.value;
      }
    })
    Object.keys(setinitial).map(x => {
      setinitial[x].map(y => {
        if (y.value) {
          applyfilterButton = false;
        }
      })
    })
    this.setState({ initalFilters: setinitial, initalFiltersBackup: initalFilter, applyfilterButton: applyfilterButton })
  }

  getInsideIds = (dataArray) => {
    let array = [];
    dataArray.map(x => {
      array.push(x.id);
    })
    return array;
  }

  getValue = (dataArray, id) => {
    let value;
    dataArray.map(x => {
      if (x.id === id) {
        value = x.value;
      }
    })
    return value;
  }

  handleCancelVisible = e => {
    let setinitial = { ...this.state.initalFilters };
    let initalFilter = { ...this.state.initalFiltersBackup };
    let cancelArray = Object.keys(initalFilter);
    Object.keys(setinitial).map(x => {
      if (cancelArray.includes(x)) {
        let ids = this.getInsideIds(initalFilter[x]);
        setinitial[x].map(y => {
          if (ids.includes(y.id)) {
            y.value = this.getValue(initalFilter[x], y.id);
          }
        })
      }
    })
    this.setState({
      visible: false,
      initalFilters: setinitial,
      initalFiltersBackup: {}
    });
  };

  getZoneData = data => {
    this.setState({ zoneLoading: true });
    apiRequest({
      url: `/v1/hyperlocal-fe-map-view/zones/${data}`,

    }).then(res => {
      const zone = res?.data?.response?.zone;
      this.setState({
        groupedZoneData: zone,
        selectedZone: data,
        zoneLoading: false
      })
    })
  }

  handleOk = () => {
    let customerId = [...this.state.customerId];
    const custIndex = customerId.indexOf(0);
    if (custIndex > -1) {
      customerId.splice(custIndex, 1);
    }
    let payload = {};
    let selectedFiltersData = { ...this.state.initalFilters };
    Object.keys(selectedFiltersData).map(x => {
      let arrystat = [];
      selectedFiltersData[x].map(y => {
        if (y.value === true) {
          arrystat.push(y.storedValue);
        }
      })
      payload[x] = arrystat;
    })
    payload.cityId = this.state.cityId;
    payload.locationIds = this.state.locationIds;
    payload.dateRange = { ...this.state.dateRange };
    payload.customerIds = customerId;
    this.props.allActiveFes(payload);
    this.setState({
      visible: false,
      initalFilters: selectedFiltersData,
      initalFiltersBackup: {},
      groupedZoneData: [],
      selectedZone: '',
      groupedZones: []
    });
    const request = {
      "cityId": this.state.cityId,
      locationIds: this.state.locationIds
    }

    // console.log("request", request)


    apiRequest({
      url: `/v1/hyperlocal-fe-map-view/zones`,
      method: 'POST',
      data: request
    }).then(res => {
      // this.getPaginatedData(this.state.pageNo)
      let zones = res?.data?.response?.zones;
      let data = [];
      let totalZones = 0,
        inZonesTotal = 0,
        outSideZonesTotal = 0,
        taggedFesTotal = 0,
        unTaggedFesTotal = 0;
      if (zones?.length > 0) {
        totalZones = res?.data?.response?.totalFesInAllZones?.totalCount;
        inZonesTotal = res?.data?.response?.inZoneFesCountForAllZones;
        unTaggedFesTotal = res?.data?.response?.unTaggedFesCountForAllZones;
        zones.map(zone => {


          // totalZones += zone?.inZoneFes?.busyCount + zone?.inZoneFes?.freeCount + zone?.outsideZoneFes?.busyCount + zone?.outsideZoneFes?.freeCount + zone?.unTaggedFes?.busyCount + zone?.unTaggedFes?.freeCount;
          // inZonesTotal += zone?.inZoneFes?.busyCount + zone?.inZoneFes?.freeCount;
          // outSideZonesTotal += zone?.outsideZoneFes?.busyCount + zone?.outsideZoneFes?.freeCount;
          // taggedFesTotal = zone?.taggedFes?.busyCount + zone?.taggedFes?.freeCount;
          // unTaggedFesTotal += zone?.unTaggedFes?.busyCount + zone?.unTaggedFes?.freeCount;
          data.push({
            totalZones,
            key: zone.key,
            inZoneFes_busy: zone?.inZoneFes?.busyCount,
            inZoneFes_free: zone?.inZoneFes?.freeCount,
            // outsideZoneFes_busy: zone?.outsideZoneFes?.busyCount,
            // outsideZoneFes_free: zone?.outsideZoneFes?.freeCount,
            // taggedFes_busy: zone?.taggedFes?.busyCount,
            // taggedFes_free: zone?.taggedFes?.freeCount,
            unTaggedFes_busy: zone?.unTaggedFes?.busyCount,
            unTaggedFes_free: zone?.unTaggedFes?.freeCount,
          })
        })



        this.setState({
          groupedZones: data,
          totalZones,
          inZonesTotal,
          outSideZonesTotal,
          taggedFesTotal,
          unTaggedFesTotal,
          iconLoading: false
        })
      }


    })
  };

  ApplyOk = (e) => {
    let payload = {
      "cityId": this.state.cityId,
      "locationIds": this.state.locationIds,
    };
    this.props.getGeoFence(payload);
    this.allOrderData();
    this.handleOk();
    this.allCountDetails();
  };

  allOrderData = () => {
    let customerId = [...this.state.customerId];
    const custIndex = customerId.indexOf(0);
    if (custIndex > -1) {
      customerId.splice(custIndex, 1);
    }
    let payload = {
      "cityId": this.state.cityId,
      "locationIds": this.state.locationIds,
      "createdAtFrom": moment().subtract(1, 'hours').valueOf(),
      "createdAtTo": moment().valueOf(),
      "customerIds": customerId,
    };
    this.props.allOrderData(payload);
  }

  checkDateRange = () => {
    let a = moment('04:00:00', 'hh:mm:ss');
    let b = moment(moment().format("hh:mm:ss"), 'hh:mm:ss');
    let from;
    let to;
    // if (a.isAfter(b)) {
    //   from = moment().startOf("day").add(4, 'hours');
    //   to = moment().add(1, 'days').startOf("day").add(4, 'hours');
    // } else {
    //   from = moment().subtract(1, 'd').startOf("day").add(4, 'hours');
    //   to = moment().startOf("day").add(4, 'hours');
    // }
    from = moment('04:00:00', 'hh:mm:ss');
    to = moment('04:00:00', 'hh:mm:ss').add(1, 'd');
    let dateRange = {
      "from": from.valueOf(),
      "to": to.valueOf()
    }
    this.setState({
      dateRange: dateRange
    })
  }

  allCountDetails = () => {
    let customerId = [...this.state.customerId];
    const custIndex = customerId.indexOf(0);
    if (custIndex > -1) {
      customerId.splice(custIndex, 1);
    }
    let payload = {
      "cityId": this.state.cityId,
      "locationIds": this.state.locationIds,
      "dateRange": { ...this.state.dateRange },
      "customerIds": customerId,
    };
    this.props.allCountDetails(payload);
  }

  reloadList = () => {
    let payload = {
      "cityId": this.state.cityId,
      "locationIds": this.state.locationIds,
    };
    this.setState({ iconLoading: true })
    this.props.getGeoFence(payload);
    this.allOrderData();
    this.handleOk();
    this.allCountDetails()
  }

  showMessage = (e) => {
    let dispayData;
    if (e === 1) {
      dispayData = (
        <div className="boxmeHOver">
          <p>{hotSpotsdata[e].name} : {moment(this.createdFromDate(e)).format('DD/MM/YYYY h:mm:ss')} to {moment(this.createdToDate(e)).format('DD/MM/YYYY h:mm:ss')}</p>
        </div>
      );
    } else {
      dispayData = (
        <div className="boxmeHOver">
          <p>{hotSpotsdata[e].name} : {moment(this.createdFromDate(e)).format('DD/MM/YYYY h:mm:ss')} to now</p>
        </div>
      );
    }
    this.setState({ dispayData: dispayData })
  }

  onhotSpotChange = (e) => {
    let customerId = [...this.state.customerId];
    const custIndex = customerId.indexOf(0);
    if (custIndex > -1) {
      customerId.splice(custIndex, 1);
    }
    let payload = {
      "cityId": this.state.cityId,
      "locationIds": this.state.locationIds,
      "createdAtFrom": this.createdFromDate(e),
      "createdAtTo": this.createdToDate(e),
      "customerIds": customerId,
    };
    this.props.allOrderData(payload);
    this.setState({ hotspotValue: hotSpotsdata[e].id })
  }

  createdFromDate = (value) => {
    let DateValue;
    switch (value) {
      case 0:
        DateValue = moment().subtract(7, 'd').startOf("day").add(4, 'hours').valueOf();
        break;
      case 1:
        DateValue = moment().subtract(7, 'd').startOf("day").add(4, 'hours').valueOf();
        break;
      case 2:
        DateValue = moment().subtract(1, 'hours').valueOf();
        break;
      default:
        DateValue = moment().subtract(1, 'hours').valueOf();
    }
    return DateValue;
  }

  createdToDate = (value) => {
    let DateValue;
    switch (value) {
      case 0:
        DateValue = moment().valueOf();
        break;
      case 1:
        DateValue = moment().subtract(6, 'd').startOf("day").add(4, 'hours').valueOf();
        break;
      case 2:
        DateValue = moment().valueOf();
        break;
      default:
        DateValue = moment().valueOf();
    }
    return DateValue;
  }

  riderInfo = (item) => {
    this.setState({
      notifyModal: true,
      selectedFe: [item.userId],
      selectedFeNames: [item.name],
    });
  }

  handleLegendBox = () => {
    this.setState({ legendBox: !this.state.legendBox })
  }

  setTite = () => {
    return (<div><img src={NotifyImage} style={{ marginRight: 4 }} alt="notifymodal" />Notify</div>);
  }

  showNotify = () => {
    const { filteredfes } = this.state;
    let selectedFeNames = [];
    let selectedFe = [];
    filteredfes.map(x => {
      selectedFe.push(x.userId);
      selectedFeNames.push(x.name)
    })
    this.setState({
      notifyModal: true,
      selectedFe,
      selectedFeNames
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const _this = this;
    let templates = [...this.props.templatesData];
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let payload = {};
        let templateAttributes = {};
        let data = templates.filter(x => x.id === this.state.templateSelectedId);
        data[0].input.map(x => {
          templateAttributes[x.id] = typeof values[x.id] === 'object' ? values[x.id].label : values[x.id];
        })

        // let me = {"userIds":[17007,17177,7565],"resolveUserTokensFromIds":true,"templateAttributes":{"heading":"sds","content":"adasdasd"},"templateId":1,"serviceProvider":"ONESIGNAL","appName":"ATLAS_LAST_MILE","scheduleTime":1603196653705}
        // let too = {"userIds":[17007],"resolveUserTokensFromIds":true,"templateAttributes":{"heading":"aasd","lat":14.604847155053898,"lon":77.38769531250001},"templateId":2,"serviceProvider":"ONESIGNAL","appName":"ATLAS_LAST_MILE","scheduleTime":1602851211664}

        payload = {
          userIds: this.state.selectedFe,
          resolveUserTokensFromIds: true,
          templateAttributes: templateAttributes,
          templateId: this.state.templateSelectedId,
          serviceProvider: data[0].serviceProvider,
          appName: "ATLAS_LAST_MILE",
        }
        if (values['date-picker']) {
          payload.scheduleTime = values['date-picker'] ? values['date-picker'].valueOf() : null;
        }
        this.props.sendPushNotification(payload).then(res => {
          if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
            notification['success']({
              message: 'SUCCESS',
              description: res?.data?.status?.message,
              placement: 'bottomRight',
            });
            this.reset();
            this.setState({ notifyModal: false });
          } else {
            notification['error']({
              message: 'ERROR',
              description: res?.data?.status?.message,
              placement: 'bottomRight',
            });
            return;
          }
        });
      }
    })
  }

  handleCancel = e => {
    this.reset();
    this.setState({
      notifyModal: false,
    });
  };

  reset = () => {
    this.props.form.resetFields()
  }

  onChangeTemplate = (id) => {
    let mapboxShow;
    let templateData = [...this.props.templatesData];
    let selectedTemplate = templateData.filter(x => x.id === id);
    this.getFormattedData(selectedTemplate[0].input);
    templateData.map(x => {
      if (x.id === id) {
        if (x.deeplink) {
          mapboxShow = true
        } else {
          mapboxShow = false
        }
      }
    })
    this.setState({
      templateSelected: selectedTemplate[0].input,
      templateSelectedId: id,
      mapShow: mapboxShow,
    })
  }

  getFormattedData = (formList) => {
    let boxData = { ...this.state.boxData }
    formList.map(x => {
      if (x.endPoint) {
        this.props.callDropDownApi_v2(x.endPoint).then(res => {
          boxData[x.id] = res.data.response.batchId
          // boxData[x.id] = [{key : 1, label : "set 1"}, {key : 2, label : "set 2"}]
        })
      }
    })
    this.setState({ boxData: boxData })
  }

  capitalize = (comp) => {
    return comp[0].toUpperCase() + comp.substring(1, comp.length);
  }

  formHtmlCreator = (list) => {
    let showSchemas = [];
    const { getFieldDecorator } = this.props.form;
    return list.map((item, key) => {
      switch (item.type) {
        case 'text':
          return <Row key={key}>
            <Col span={24}>
              <Form.Item label={this.capitalize(item.title)}>
                {getFieldDecorator(item.payloadProperty, {
                  rules: [
                    {
                      required: item.validations[0].required,
                      message: `Please enter your ${item.title}`
                    },
                  ]
                })(
                  <Input type={item.type} placeholder={item.title} />
                )}
              </Form.Item>
            </Col>
          </Row>;

        case 'select':
          if (showSchemas.length == 0 || showSchemas.includes(item.id)) {
            let defaultValue = [];
            if (item?.defaultValue.length > 0) {
              item.defaultValue.map(v => {
                if (v['key'] instanceof Array) {
                  const partnerId = Constants.PARTNER_ID.toString();
                  let index = v["key"].indexOf(partnerId);
                  if (index >= 0) {
                    defaultValue.push({ "key": partnerId, "label": v["label"] });
                  }
                } else {
                  defaultValue.push(v);
                }
              })
            }
            return <Row key={key}>
              <Col span={24}>
                <Form.Item label={this.capitalize(item.title)}>
                  {getFieldDecorator(item.payloadProperty, {
                    initialValue: defaultValue ? defaultValue : [],
                    rules: item.validations[0].required
                  })(
                    <Select
                      onChange={(val) => this.onChange(val, item.fetchFor)}
                      showSearch
                      labelInValue={true}
                      mode={item.multiple ? 'multiple' : 'single'}
                      placeholder={`Select ${item.title}`}
                      style={{ width: '100%' }}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        this.optionHtmlCreator(item?.id, item?.endPoint, item?.values)
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>

          } else {
            return <></>;
          }


        case 'radio':
          return <Row key={key}>
            <Col span={24}>
              <Form.Item label={this.capitalize(item.title)}>
                {getFieldDecorator(item.payloadProperty, {
                  initialValue: item?.default,
                  rules: item.validations[0].required
                })(
                  <Radio.Group>
                    {
                      this.radioCreator(item.radioOptions)
                    }
                  </Radio.Group>

                )}
              </Form.Item>
            </Col>
          </Row>;
        default:
          return null;
      }
    })
  }

  showfiteredValues = () => {
    this.setState({
      listfiltered: !this.state.listfiltered,
      listView: false
    })
  }

  maplatlngData = (lat, lng) => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({
      'lat': lat,
      'lon': lng,
    });
  }

  inside = (point, vs) => {
    var x = point[0], y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];

      var intersect = ((yi > y) != (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }

    return inside;
  };

  outsidePolygon = () => {
    const { mergedArray, liveFEsonMap2 } = this.state;
    let insidePolygon = [];
    let outsidePolygon = [];
    let outsideUserIds = [];
    mergedArray.map(x => {
      insidePolygon.push(x.userId);
    })
    liveFEsonMap2.map(y => {
      if (!insidePolygon.includes(y.userId)) {
        outsidePolygon.push(y);
        outsideUserIds.push(y.userId);
      }
    })
    this.setState({ filteredfes: outsidePolygon, outsideUserIds: outsideUserIds })
  }

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  insidePolygon = (geofence) => {
    const { liveFEsonMap2 } = this.state;
    let insidePolygon = [];
    liveFEsonMap2.map(y => {
      if (y.latitude) {
        let markerOne = [Number(y.longitude), Number(y.latitude)];
        let coordinates = this.inside(markerOne, geofence);
        if (coordinates) {
          insidePolygon.push(y);
        }
      }
    })
    this.setState({ filteredfes: insidePolygon })
  }

  insidePolygonTest = (geofence, data) => {
    let insidePolygon = [];
    data.map(y => {
      if (y.latitude) {
        let markerOne = [Number(y.longitude), Number(y.latitude)];
        let coordinates = this.inside(markerOne, geofence);
        if (coordinates) {
          insidePolygon.push(y);
        }
      }
    })
    return insidePolygon;
  }

  insidePolygonCount = (data) => {
    const { geofence } = this.state;
    let objectCounts = {};
    let mergedArray = [];
    geofence.map(x => {
      objectCounts[x._key] = this.insidePolygonTest(x.geometry.coordinates[0], data);
    });
    Object.keys(objectCounts).map(x => {
      mergedArray = [...mergedArray, ...objectCounts[x]]
    })
    this.setState({ allzoneCounts: objectCounts, mergedArray: mergedArray })
  }

  allPolygon = () => {
    this.setState({ filteredfes: this.state.liveFEsonMap2 })
  }

  onLayerAdd = (e, fence, totalData) => {
    let liveFEsonMap2 = [...this.state.liveFEsonMap2]
    let insidePolygon = [...this.state.filteredfes];
    let geofence = [...this.state.geofence];
    if (e.target.checked === true) {
      liveFEsonMap2.map(y => {
        if (y.latitude) {
          let markerOne = [Number(y.longitude), Number(y.latitude)];
          let coordinates = this.inside(markerOne, fence);
          if (coordinates) {
            insidePolygon.push(y);
          }
        }
      })
      geofence.map(x => {
        if (x._key === totalData._key) {
          x.checkStatus = e.target.checked;
        }
      })
      let allchecked = this.checkAllStatus(geofence, this.state.outsidechecked);
      this.setState({ filteredfes: insidePolygon, geofence: geofence, allchecked: allchecked })
    } else {
      liveFEsonMap2.map(y => {
        if (y.latitude) {
          let markerOne = [Number(y.longitude), Number(y.latitude)];
          let coordinates = this.inside(markerOne, fence);
          if (coordinates) {
            insidePolygon.map((x, index) => {
              if (x.userId === y.userId) {
                insidePolygon.splice(index, 1);
              }
            })
          }
        }
      })
      geofence.map(x => {
        if (x._key === totalData._key) {
          x.checkStatus = e.target.checked;
        }
      })
      let allchecked = this.checkAllStatus(geofence, this.state.outsidechecked);
      this.setState({ filteredfes: insidePolygon, geofence: geofence, allchecked: allchecked })
    }
  }

  checkAllStatus = (data, value) => {
    let isSame = true;
    if (value) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].checkStatus === false) {
          isSame = false;
          break;
        }
      }
    } else {
      isSame = false;
    }
    return isSame;
  }

  allLayer = (e) => {
    let geofence = [...this.state.geofence]
    if (e.target.checked === true) {
      geofence.map(x => {
        x.checkStatus = true;
      })
      this.setState({ filteredfes: this.state.liveFEsonMap2, allchecked: e.target.checked, geofence: geofence, outsidechecked: true })
    } else {
      geofence.map(x => {
        x.checkStatus = false;
      })
      this.setState({ filteredfes: [], allchecked: e.target.checked, geofence: geofence, outsidechecked: false })
    }
  }

  outsideLayer = (e) => {
    let liveFEsonMap2 = [...this.state.liveFEsonMap2]
    let filteredPolygon = [...this.state.filteredfes];
    let mergedArray = [...this.state.mergedArray];
    let geofence = [...this.state.geofence];
    if (e.target.checked === true) {
      let insidePolygon = [];
      let outsidePolygon = [];
      mergedArray.map(x => {
        insidePolygon.push(x.userId);
      })
      liveFEsonMap2.map(y => {
        if (!insidePolygon.includes(y.userId)) {
          outsidePolygon.push(y);
        }
      })
      let allchecked = this.checkAllStatus(geofence, true);
      this.setState({ filteredfes: outsidePolygon.concat(filteredPolygon), outsidechecked: true, allchecked: allchecked })
    } else {
      let insidePolygon = [];
      let outsidePolygon = [];
      let filteredFes = [];
      let outsideUserIds = [];
      mergedArray.map(x => {
        insidePolygon.push(x.userId);
      })
      liveFEsonMap2.map(y => {
        if (!insidePolygon.includes(y.userId)) {
          outsidePolygon.push(y);
          outsideUserIds.push(y.userId);
        }
      })
      filteredPolygon.map(x => {
        if (!outsideUserIds.includes(x.userId)) {
          filteredFes.push(x);
        }
      })
      let allchecked = this.checkAllStatus(geofence, false);
      this.setState({ filteredfes: filteredFes, outsidechecked: false, allchecked: allchecked })
    }
  }

  setListFe = (data) => {
    let fearray = [];
    fearray.push(data);
    this.setState({ filteredfes: fearray, listView: false, searchName: data.name })
  }

  onCustomerChange = (e) => {
    this.setState({ customerId: e });
  }

  setLocationEndData = (data) => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push((<Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>));
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  }

  setLocationpartnerData = (data) => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push((<Option key={x.id} value={x.id} label={x.alias}>{x.alias}</Option>));
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
    const { getFieldDecorator } = this.props.form;
    let grouppedZoneColumns = [
      {
        title: 'Zone Filter',
        children: [
          {
            title: 'Tagged FE Name',
            key: 'name',
            dataIndex: 'name'
          }
        ]
      },
      {
        title: `${this.state.selectedZone}`,
        children: [
          {
            title: 'FE PHONE',
            dataIndex: 'contactNumber'
          }, {
            title: 'Status [Free/Busy/Offline]',
            dataIndex: 'status'
          }, , {
            title: 'Location Status [Inside Tagged Zone/Outside Tagged Zone] ',
            dataIndex: 'zoneStatus'
          }, {
            title: 'Current Location',
            dataIndex: 'currentLocation'
          }, {
            title: 'Last Location Update At',
            dataIndex: 'lastRecordedAt',
            render: (data) => <>{data ? <>{moment(data).format('DD/MM/YYYY HH:mm')} <br /> {moment(data).fromNow()}</> : ''} </>
          }
        ]
      }
    ]


    let groupedColumns = [
      {
        title: 'Zone',
        dataIndex: 'key',
        key: 'key',
        render: (data, row) => <Button style={{ color: '#40a9ff' }} type="link" onClick={() => this.getZoneData(data)}>{data}</Button>
        // width: 100,
        // fixed: 'left'
      },
      {
        title: `Total FEs (${this.state.totalZones})`,

        children: [
          {
            title: `WITHIN ZONE (${this.state.inZonesTotal})`,
            dataIndex: 'inZoneFes',
            children: [
              {
                title: 'Busy',
                dataIndex: 'inZoneFes_busy',
              },
              {
                title: 'Free',
                dataIndex: 'inZoneFes_free',
              }
            ]
          },
          // {
          //   title: `OUTSIDE ZONE (${this.state.outSideZonesTotal})`,
          //   dataIndex: 'outsideZoneFes',
          //   children: [
          //     {
          //       title: 'Busy',
          //       dataIndex: 'outsideZoneFes_busy',
          //     },
          //     {
          //       title: 'Free',
          //       dataIndex: 'outsideZoneFes_free',
          //     }
          //   ]
          // },
          {
            title: `UNTAGGED FEs in Zone (${this.state.unTaggedFesTotal})`,
            dataIndex: 'unTaggedFes',
            children: [
              {
                title: 'Busy',
                dataIndex: 'unTaggedFes_busy',
              },
              {
                title: 'Free',
                dataIndex: 'unTaggedFes_free',
              }
            ]
          },
          // {
          //   title: `TAGGED FEs in Zone (${this.state.taggedFesTotal})`,
          //   dataIndex: 'taggedFes',
          //   children: [
          //     {
          //       title: 'Busy',
          //       dataIndex: 'taggedFes_busy',
          //     },
          //     {
          //       title: 'Free',
          //       dataIndex: 'taggedFes_free',
          //     }
          //   ]
          // }
        ]
      }
    ]
    return (
      <>
        <Card title="Map View" className="">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {/* <Col className="gutter-row" span={6}>
              {/* <Select
                placeholder="City Name"
                style={{ width: 200 }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showArrow
                onChange={(e) => this.onLocationChange(e)}
              >
                {this.state?.cityList?.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id} label={loc.name}>
                    {loc.name}
                  </Select.Option>
                ))}
              </Select>
              <SuperSelect
                showSearch
                allowClear
                placeholder="City Name"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.onLocationChange(e)}
                // onSearch={this.onSearchsef}
                style={{ width: 200 }}
              >
                {this.setLocationEndData(this.state?.cityList)}
              </SuperSelect>
            </Col> */}
            {/* <Col className="gutter-row" span={6}>
              {/* <Select
                placeholder="Location (Partner Name)"
                value={this.state.locationIds}
                mode="multiple"
                style={{ width: 200 }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showArrow
                onChange={(e) => this.onpartnerChange(e)}
              >
                {this.state?.cityListById?.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id} label={loc.alias}>
                    {loc.alias}
                  </Select.Option>
                ))}
              </Select>
              <SuperSelect
                showSearch
                allowClear
                value={this.state.locationIds}
                mode="multiple"
                placeholder="Location (Partner Name)"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => this.onpartnerChange(e)}
                style={{ width: 200 }}
              >
                {this.setLocationpartnerData(this.state?.cityListById)}
              </SuperSelect>
            </Col> */}
            <Col className="gutter-row" span={6}>
              <Select
                placeholder="Select Customers"
                value={this.state.customerId}
                mode="multiple"
                style={{ width: 200 }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showArrow
                onChange={(e) => this.onCustomerChange(e)}
              >
                {this.state?.customerList?.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id} label={loc.code}>
                    {loc.code}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            {/* <Col className="gutter-row" span={2}>
              <Button
                type="primary"
                disabled={this.state.cityId ? false : true}
                onClick={this.ApplyOk}
              >
                Apply
                </Button>
            </Col> */}
            <Col span={2}>
              <Tooltip placement="top" title={"Reload"}>
                <Button
                  block
                  onClick={this.reloadList}
                  type="primary"
                  disabled={this.state.cityId ? false : true}
                  style={{ marginLeft: 2 }}
                  loading={this.state.iconLoading}
                >
                  <Icon type="reload" />
                </Button>
              </Tooltip>
            </Col>
            <Col className="gutter-row" span={4}></Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
            <Col span={7}>
              <Input.Search
                placeholder="Search"
                allowClear
                ref={this.InputSearchRef}
                onChange={this.showList}
                disabled={this.state?.filteredfes ? false : true}
                onSearch={(value) => this.applySearch()}
                enterButton
              />
            </Col>
            <Col span={2}>
              <Button onClick={this.showModal} disabled={this.props?.fleetStatus?.userStatusAvailableCount >= 0 ? false : true}>
                <Icon type="filter" />
              </Button>
            </Col>
            <Col span={4}>
              Geofence{" "}
              <Switch
                defaultChecked
                disabled={this.state?.geofence?.length > 0 ? false : true}
                className="switchColorpink"
                onChange={() => this.onMapViewChange("geofence")}
              />
            </Col>
            <Col span={3}>
              FE{" "}
              <Switch
                // defaultChecked
                disabled={this.state?.filteredfes?.length > 0 ? false : true}
                className="switchColorpink"
                onChange={() => this.onMapViewChange("fe")}
              />
            </Col>
            <Col span={4}>
              Hotspot{" "}
              <Switch
                // defaultChecked
                className="switchColorpink"
                disabled={this.state?.hotspots?.length > 0 ? false : true}
                onChange={() => this.onMapViewChange("hotspot")}
              />
            </Col>
            <Col span={4}>
              <Select
                placeholder="Hotspot"
                value={this.state.hotspotValue}
                style={{ width: 128 }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showArrow
                onChange={(e) => this.onhotSpotChange(e)}
              >
                {hotSpotsdata?.map((loc) => (
                  <Select.Option key={loc.id} value={loc.id} label={loc.name}>
                    {loc.name}
                    <Tooltip placement="top" onMouseEnter={() => this.showMessage(loc.id)} title={this.state.dispayData} trigger="hover" overlayStyle={{
                      color: '#333', background: '#fff'
                    }}>
                      <Icon type="info-circle" className="fitbox" />
                    </Tooltip>
                    {/* <Popover onMouseEnter={() => this.showMessage(loc.id)} content={this.state.dispayData} trigger="hover" placement="left" className="sethoverBox">
                      <Icon type="info-circle" />
                    </Popover> */}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              {this.state.listView && (
                <List
                  size="small"
                  className="listindexLevel"
                  header={
                    <div className="listHeader">
                      <div className="leftPanel">Name</div>
                      <div className="rightPanel">Number</div>
                    </div>
                  }
                  bordered
                  dataSource={this.state?.filteredfes}
                  renderItem={(item, index) => (
                    <List.Item key={index} className="listPointer" onClick={() => this.setListFe(item)}>
                      <div className="leftPanel">{item.name}</div>
                      <div className="rightPanel">{item.number}</div>
                    </List.Item>
                  )}
                />
              )}
            </Col>
            <Col span={this.state.legendBox ? 9 : 13}></Col>
            <Col span={1}>
              <div className="boxhider" onClick={this.handleLegendBox}>
                <Icon type={this.state.legendBox ? "menu-fold" : "menu-unfold"} />
              </div>
            </Col>
            <Col span={this.state.legendBox ? 6 : 0}>
              <div className="legendsBox">
                <div className="MainTitle">Legend</div>
                <div className="mainBox">
                  <div className="clearFix"></div>
                  <div className="SubTitles">Rider Status(Count)</div>
                  <div className="clearFix"></div>
                  <div className="listDetails">
                    Available (
                    {this.props?.fleetStatus?.userStatusAvailableCount || 0})
                  </div>
                  <div className="listDetails">
                    On Duty ({this.props?.fleetStatus?.userStatusOnDutyCount || 0})
                  </div>
                  <div className="listDetails">
                    Offline ({this.props?.fleetStatus?.userStatusOfflineCount || 0})
                  </div>
                  <div className="clearFix"></div>
                  <div className="SubTitles">Heat Map Scale</div>
                  <div className="clearFix"></div>
                  <div className="listDetails"></div>
                  <div className="clearFix"></div>
                  <div className="SubTitles">FEs in Zone(Rider Count)</div>
                  <div className="clearFix"></div>
                  {this.state?.liveFEsonMap2 && (
                    <Row>
                      <Checkbox checked={this.state.allchecked} className="addCheck" key="all" onChange={this.allLayer}><Tooltip placement="top" title="All">All</Tooltip></Checkbox>
                      ({this.state?.liveFEsonMap2.length})
                    </Row>
                  )}
                  {this.state?.liveFEsonMap2 && this.state?.mergedArray && (
                    <Row>
                      <Checkbox checked={this.state.outsidechecked} className="addCheck" key="outside" onChange={this.outsideLayer}><Tooltip placement="top" title="Outside">Outside</Tooltip></Checkbox>
                      ({this.state?.liveFEsonMap2.length - this.state?.mergedArray.length || 0})
                    </Row>
                  )}
                  {this.state?.geofence &&
                    this.state?.allzoneCounts &&
                    this.state?.geofence?.map(x => {
                      return (
                        <>
                          <Row>
                            <Checkbox key={x.__key} checked={x.checkStatus} className="addCheck" onChange={(e, data, totalData) => this.onLayerAdd(e, x.geometry.coordinates[0], x)}>
                              <Tooltip placement="top" title={x._key}>
                                {x._key}
                              </Tooltip>
                            </Checkbox>
                            ({this.state?.allzoneCounts[x._key]?.length})
                          </Row>
                        </>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 10 }}>
            <Col className="gutter-row" span={24}>
              <MapComponent
                geofenceBoundary={this.state?.geofence}
                hotspots={this.state?.hotspots}
                liveFe={this.state?.filteredfes}
                currentstatus={this.state.currentstatus}
                getNotify={this.riderInfo}
              />
            </Col>
            <Col className="gutter-row filteredView2" span={24}>
              {this.state.listfiltered && (
                <Col span={8}>
                  <List
                    size="small"
                    className="listindexLevel"
                    header={
                      <div className="listHeader">
                        <div className="leftPanel">Name</div>
                        <div className="rightPanel">Number</div>
                      </div>
                    }
                    bordered
                    dataSource={this.state?.filteredfes}
                    renderItem={(item, index) => (
                      <List.Item key={index}>
                        <div className="leftPanel">{item.name}</div>
                        <div className="rightPanel">{item.number}</div>
                      </List.Item>
                    )}
                  />
                </Col>
              )}
            </Col>
            <Col className="buttonBox">
              {this.state?.liveFEsonMap2 && (
                <Button onClick={() => this.showfiteredValues()}>
                  <Icon type={this.state.listfiltered ? "caret-down" : "caret-up"} />{" "}
                  Filtered FEs({this.state?.filteredfes.length || 0})
                </Button>
              )}
              {this.state?.liveFEsonMap2 && (
                <Button
                  className={
                    this.state?.filteredfes.length > 0
                      ? "notifyBox notify-btn-primary"
                      : "notifyBox"
                  }
                  disabled={this.state?.filteredfes.length > 0 ? false : true}
                  onClick={() => this.showNotify()}
                >
                  <img
                    src={NotifyImage}
                    style={{ width: 26, marginRight: 4 }}
                    alt="notify"
                  />
                  Notify
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Modal
              title="Filters"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancelVisible}
              maskClosable={false}
              className="hyperlocalModalFooter"
              footer={[
                <Button key="back" onClick={this.resetFilters}>
                  Reset filter
                </Button>,
                <Button key="submit" type="primary" disabled={this.state.applyfilterButton ? true : false} onClick={this.handleOk}>
                  Apply filter
                </Button>,
              ]}
              width={720}
            >
              <Row>
                <Col span={24}>
                  <div className="boxusertitles">FE status</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.attendanceStatuses.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false ? (item.disabled === true ? "boxLittle boxNewDis" : "boxLittle") : "boxLittleSelected"
                          }
                          onClick={(data, id, value) =>
                            this.filterValuesChnages("attendanceStatuses", index, item.value)
                          }
                        >
                          {item.name} : {this.props?.fleetStatus[item.apiValue]}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Hyperlocal Permission</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.hyperlocalPermission.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false ? "boxLittle" : "boxLittleSelected"
                          }
                          onClick={(data, id, value) =>
                            this.filterValuesChnages("hyperlocalPermission", index, item.value)
                          }
                        >
                          {item.name} : {this.props?.fleetStatus[item.apiValue]}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Hyperlocal Eligibility</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.hyperlocalEligibility.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false ? "boxLittle" : "boxLittleSelected"
                          }
                          onClick={(data, id, value) =>
                            this.filterValuesChnages("hyperlocalEligibility", index, item.value)
                          }
                        >
                          {item.name} : {this.props?.fleetStatus[item.apiValue]}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">Hyperlocal user Availability</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.hyperlocalAvailability.map(
                      (item, index) => (
                        <div
                          key={index}
                          className={
                            item.value === false ? "boxLittle" : "boxLittleSelected"
                          }
                          onClick={(data, id, value) =>
                            this.filterValuesChnages("hyperlocalAvailability", index, item.value)
                          }
                        >
                          {item.name} : {this.props?.fleetStatus[item.apiValue]}
                        </div>
                      )
                    )}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <div className="boxusertitles">FE order status</div>
                  <div className="boxfilterTitles">
                    {this.state.initalFilters.orderStatus.map((item, index) => (
                      <div
                        key={index}
                        className={
                          item.value === false ? "boxLittle" : "boxLittleSelected"
                        }
                        onClick={(data, id, value) =>
                          this.filterValuesChnages("orderStatus", index, item.value)
                        }
                      >
                        {item.name} : {this.props?.fleetStatus[item.apiValue]}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Modal>
            <Row className="notifyModalbox">
              <Modal
                title={this.setTite()}
                visible={this.state.notifyModal}
                onOk={this.handleSubmit}
                onCancel={this.handleCancel}
                width={720}
                className="notifyModal"
                footer={[
                  <Button key="submit" type="primary" onClick={this.handleSubmit}>
                    Send Notification
                  </Button>,
                ]}
              >
                <Row>
                  <Col span={14}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                      <Select
                        placeholder="Select Template"
                        style={{ width: 200 }}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showArrow
                        onChange={(e) => this.onChangeTemplate(e)}
                      >
                        {this.state?.templatesData?.map((temp) => (
                          <Select.Option
                            key={temp.id}
                            value={temp.id}
                            label={temp.name}
                          >
                            {temp.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Col span={24} style={{ marginTop: 20 }}>
                        Preview
                      </Col>
                      <Row>
                        {this.formHtmlCreator(this.state?.templateSelected)}
                        <Row key="dateData">
                          <Col span={24}>
                            <Form.Item label="Scheduled Time">
                              {getFieldDecorator("date-picker", {
                                rules: [
                                  {
                                    required: false,
                                    message: `Please enter your Date`,
                                  },
                                ],
                              })(<DatePicker showTime format="YYYY-MM-DD h:mm:ss" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                    </Form>
                  </Col>
                  <Col span={10}>
                    {this.state.mapShow && (
                      <MapToFleet
                        lat={this.state?.lat}
                        lng={this.state?.lng}
                        mapData={this.maplatlngData}
                      ></MapToFleet>
                    )}
                  </Col>
                  {this.state?.selectedFeNames &&
                    this.state?.selectedFeNames.length > 0 && (
                      <Col span={24} style={{ marginTop: 20 }}>
                        Send to : {this.state?.selectedFeNames.join(", ")}
                      </Col>
                    )}
                </Row>
              </Modal>
            </Row>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
            <Col span={24}>
              <Table
                locale={{
                  emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                bordered
                loading={this.props.fleetStatus?.loading}
                dataSource={this.state.groupedZones}
                columns={groupedColumns}
              />


            </Col>

            <Col className="gutter-row" span={24}>
              <Divider />
            </Col>

            <Col span={24}>
              <Table
                bordered
                locale={{
                  emptyText: (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }}
                loading={this.props.fleetStatus?.loading || this.state.zoneLoading}
                dataSource={this.state.groupedZoneData}
                columns={grouppedZoneColumns}
              />


            </Col>
          </Row>
        </Card>
      </>
    )
  }
}

const mapStateToProps = state => ({
  fleetStatus: state.FleetReducer,
  cityList: state.FleetReducer?.cityList?.citiesDetails,
  cityListById: state.FleetReducer?.locations,
  geofence: state.FleetReducer?.zones,
  hotspots: state.FleetReducer?.hotspots,
  liveFEsonMap: state.FleetReducer?.liveFEsOnMap,
  templatesData: state.FleetReducer?.notificationTemplates,
  customerList: state.FleetReducer?.customersBO,
});

const mapDispatchToProps = dispatch => ({
  getCustomer: () => dispatch(actions.getCustomer()),
  getCityList: () => dispatch(actions.getCityList()),
  getCityListById: (cityId) => dispatch(actions.getCityListById(cityId)),
  getGeoFence: (data) => dispatch(actions.getGeoFence(data)),
  allActiveFes: (data) => dispatch(actions.allActiveFes(data)),
  allOrderData: (data) => dispatch(actions.allOrderData(data)),
  allCountDetails: (data) => dispatch(actions.allCountDetails(data)),
  riderDetails: (id) => dispatch(actions.riderDetails(id)),
  getTemplates: () => dispatch(actions.getTemplates()),
  callDropDownApi_v2: (endPoint) => dispatch(actions.callDropDownApi_v2(endPoint)),
  sendPushNotification: (payload) => dispatch(actions.sendPushNotification(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'templateNotify', onValuesChange: (props, val) => console.log(props.form.getFieldsValue()) })(MapviewDashboard));
