import * as CONSTANTS from './constants';
import { LOCAL_STORAGE_STATE, USER_LOGGEDIN_INFO, SELF } from '../../../common/utils/constants';
import { localStorageUtils, removeDuplicateLocations } from '../../../common/utils/utils';

export const headerReducer = (state = { isWaybillModalOpen: false, waybill: '' }, action) => {
  switch (action.type) {
    case CONSTANTS.GET_LOGGEDIN_USER_DATA: return getLoggedInUserData(state, action);

    case CONSTANTS.GET_PARTNER_FETCH_BEGIN: return getPartnerFetchBegin(state, action);

    case CONSTANTS.GET_PARTNER_FETCH_SUCCESS: return getPartnerFetchSuccess(state, action);

    case CONSTANTS.GET_PARTNER_FETCH_FAILURE: return getPartnerFetchFailure(state, action);

    case CONSTANTS.GET_LOCATION_FETCH_BEGIN: return getLocationFetchBegin(state, action);

    case CONSTANTS.GET_LOCATION_FETCH_SUCCESS: return getLocationFetchSuccess(state, action);

    case CONSTANTS.GET_LOCATION_FETCH_FAILURE: return getLocationFetchFailure(state, action);

    case CONSTANTS.APP_LOGIN_BEGIN: return appLoginBegin(state, action);

    case CONSTANTS.APP_LOGIN_SUCCESS: return appLoginSuccess(state, action);

    case CONSTANTS.APP_LOGIN_FAILURE: return appLoginFailure(state, action);

    case CONSTANTS.GET_SET_LOCALITEM_REDUX_SUCCESS: return getSetLocalItemReduxSuccess(state, action);

    case CONSTANTS.UPDATE_LOCALITEM_REDUX_SUCCESS: return updateLocalItemReduxSuccess(state, action);

    case CONSTANTS.LOGGED_OUT_BEGIN: return appLogoutBegin(state, action);

    case CONSTANTS.LOGGED_OUT_SUCCESS: return appLogoutSuccess(state, action);

    case CONSTANTS.LOGGED_OUT_FAILURE: return appLogoutFailure(state, action);

    case CONSTANTS.TOGGLE_WAYBILL_MODAL: return toggleWaybillModal(state, action);

    default: return state
  }
}

function getLoggedInUserData(state, action) {
  return {
    ...state,
    loggedInUser: action.payload
  }
}

function getPartnerFetchBegin(state, action) {
  return {
    ...state,
    partnerLoading: true,
  }
}

function getPartnerFetchSuccess(state, action) {
  const loggedInPartnerId = localStorageUtils({
    key: USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID,
    type: LOCAL_STORAGE_STATE.GET,
  });
  action.payload.partners.unshift({
    id: loggedInPartnerId,
    linkPartnerBO: {
      name: SELF,
      id: loggedInPartnerId
    }
  });
  return {
    ...state,
    partnerLoading: false,
    partnerHeaderDetails: action.payload
  }
}

function getPartnerFetchFailure(state, action) {
  return {
    ...state,
    partnerLoading: false
  }
}
function getLocationFetchBegin(state, action) {
  return {
    ...state,
    locationLoading: true,
  }
}
function getLocationFetchSuccess(state, action) {
  return {
    ...state,
    locationLoading: false,
    locationHeaderDetails: removeDuplicateLocations(action.payload.locations, { nameWithRole: 'name' })
  }
}
function getLocationFetchFailure(state, action) {
  return {
    ...state,
    locationLoading: false
  }
}

function appLoginBegin(state, action) {
  return {
    ...state,
    loading: true,
  }
}
function appLoginSuccess(state, action) {

  let newState = {
    ...state,
    loading: false,
    login: action.payload.data,
  }
  if (!action.payload.auto) {
    newState = {
      ...newState,
      refreshApp: true
    }
  }
  return newState;
}
function appLoginFailure(state, action) {
  return {
    ...state,
    loading: false
  }
}
function updateLocalItemReduxSuccess(state, action) {
  return {
    ...state,
    loading: false,
    userInfo: action.payload
  }
}
function getSetLocalItemReduxSuccess(state, action) {
  return {
    ...state,
    loading: false,
    userInfo: action.payload
  }
}
function appLogoutBegin(state, action) {
  return {
    ...state,
    logoutResp: null
  }
}
function appLogoutSuccess(state, action) {
  return {
    ...state,
    logoutResp: action.payload
  }
}
function appLogoutFailure(state, action) {
  return {
    ...state,
    logoutResp: null
  }
}

function toggleWaybillModal(state, action) {
  return {
    ...state,
    isWaybillModalOpen: action.toggleVal,
    waybill: action.waybill
  }
}
