import * as React from 'react';
import { CircleMarker } from 'react-leaflet';
import './map-widget.css';
import { tooltip } from 'leaflet';

class CirclePins extends React.Component {
  render() {
    return (
      <CircleMarker
        className="circle"
        center={this.props.center}
        fillOpacity={this.props.opacity}
        weight={0}
        permanent={true}
    {...this.props}></CircleMarker>
    );
  }
}

export default CirclePins;