import React, { useState } from 'react'
import { Form, Card, Input, Button, Select, Modal, Tooltip } from "antd";
import { permissionTags } from "../../../configs/permissionTags";
import { apiRequest } from "../../../common/utils/apiGateway";
import FormItem from 'antd/lib/form/FormItem';

function ZoneCreationDashboard(props) {
    const { getFieldDecorator } = props.form;
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        labelAlign: "left",
        colon: false
    };

    const [jsonData, setJsonData] = useState(null)
    const [errorAlert, setErrorAlert] = useState(false)
    const [confirmationAlert, setConfirmationAlert] = useState(false)

    const jsonChangeHandler = (e) => {
        setJsonData(e.target.value)
    }

    function isJson(str) {
        let obj;
        try {
            obj = JSON.parse(str);
            console.log("It's JSON Obj", str);
        } catch (e) {
            setErrorAlert(true)
            console.log(e);
            return false;
        }
        setErrorAlert(false)
        return obj;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                let jsonDataHandler = isJson(jsonData)
                apiRequest({
                    url: `/v1/hyperlocal-serviceable-areas/create`,
                    method: "POST",
                    data: {
                        "zones": [{
                            "name": values.name.replace("-", ""),
                            "bookingOfficeLocId": values.bookingOfficeLocId,
                            "entityType": "PARTNER",
                            "geoJson": true,
                            "hyperlocalZoneClusterId": values.hyperlocalZoneClusterId,
                            "cityId": values.cityId,
                            "partnerId": values.partnerId,
                            "serviceableAreaType": "PICKUP",
                            "killSwitchStatus": false,
                            "geometry": jsonDataHandler
                        }]
                    }
                }).then(res => {
                    props.form.resetFields()
                    setConfirmationAlert(true)
                    setJsonData(null)
                    setErrorAlert(null)
                })
            }
        })
    }

    const handleErrorAlert = () => {
        setErrorAlert(false)
    }

    const handleConfirmationAlert = () => {
        setConfirmationAlert(false)
    }

    return (
        <>
            <Card className="CustomerDashBoard" title={<h4>Zone Creation Dashboard</h4>}>
                <Form {...formItemLayout}
                    autoComplete='off'
                    initialValues={{
                        remember: true,
                    }}
                    onSubmit={handleSubmit}
                >
                    <Form.Item label="Name">
                        <Tooltip placement="left" title={"Please enter name ONLY without city name, partner id and delimiter(-)"}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input name!',
                                    },
                                ],
                            })(
                                <Input placeholder="Please enter name" />
                            )}
                        </Tooltip>
                    </Form.Item>
                    <Form.Item label="Booking Office Location Id">
                        {getFieldDecorator('bookingOfficeLocId', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input booking office Loc Id!',
                                },
                            ],
                        })(
                            <Input placeholder="Booking Office Location Id" />
                        )}
                    </Form.Item>
                    <Form.Item label="City Id">
                        {getFieldDecorator('cityId', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter City Id',
                                },
                            ],
                        })(
                            <Input placeholder="City Id" />
                        )}
                    </Form.Item>
                    <Form.Item label="Hyperlocal Zone Cluster Id">
                        {getFieldDecorator('hyperlocalZoneClusterId', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Hyperlocal Zone Cluster Id',
                                },
                            ],
                        })(
                            <Input placeholder="Hyperlocal Zone Cluster Id" />
                        )}
                    </Form.Item>
                    <Form.Item label="Partner Id">
                        {getFieldDecorator('partnerId', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Partner Id',
                                },
                            ],
                        })(
                            <Input placeholder="Partner Id" />
                        )}
                    </Form.Item>
                    <Form.Item label="Geometry">
                        {getFieldDecorator('geometry', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please enter Geometry JSON here',
                                },
                            ],
                        })(
                            <Input.TextArea
                                placeholder="Paste JSON here"
                                onChange={jsonChangeHandler}
                                allowClear
                                style={{ height: "45vh" }}
                            />
                        )}
                    </Form.Item>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button size="large" onClick={handleErrorAlert}>CANCEL</Button>
                        <Button size="large" htmlType="submit" type="primary" style={{ marginLeft: "20px" }}>Submit</Button>
                    </div>

                    < Modal
                        visible={errorAlert}
                        onCancel={handleErrorAlert}
                        footer={
                            < Button key="submit" type="primary" onClick={handleErrorAlert} > Ok </Button>}
                    >
                        Please enter a valid JSON object.
                    </Modal>
                    < Modal
                        visible={confirmationAlert}
                        onCancel={handleConfirmationAlert}
                        footer={
                            < Button key="submit" type="primary" onClick={handleConfirmationAlert}>Ok</Button>}
                    >
                        Zone Successfully Created!
                    </Modal>
                </Form>
            </Card>
        </>
    )
}
const ZoneCreationForm = Form.create({ name: "client-onboarding" })(ZoneCreationDashboard);

export default ZoneCreationForm