import * as React from 'react';
import L from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet';
import './map-widget.css';


class CircleMarkerpins extends React.Component {

  render() {
    const { position, numberSet } = this.props;
    let numberIcon = L.divIcon({
        className: "number-icon-lsn",
        iconSize: [17, 17],
        iconAnchor: [13, 15],
        popupAnchor: [0, 0],
        html: String.fromCharCode(numberSet)
    });
    return (
      <>
      <Marker
        className="setCircle"
        position={position}
        icon={numberIcon}
        interactive={true}
        keyboard={true}
        >
      {/* <Tooltip width={150} direction="auto" permanent={true}>
        <>
          <div class="tooltipName"><b>4</b></div>
        </>
      </Tooltip> */}
      </Marker>
      </>
    );
  }
}

export default CircleMarkerpins;