import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { Constants, BASE_URL_TYPES } from '../../../common/utils/constants'
import { notifyApiError } from '../../../common/utils/utils';

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getReportTypes = (params) => dispatch => {
    dispatch(reportTypeBegin());
    apiUtils.apiRequest({
        method: 'post',
        baseUrlType: BASE_URL_TYPES.REPORTS,
        url: `/b2b/v1/reports/getConfig`,
        data: params
    })
        .then(response => {
            dispatch(reportTypeSuccess(response.data.response))
        })
        .catch(error => dispatch(reportTypeFailure(error)));
}
export const getCustomerList = () => dispatch => {
    dispatch(customerListBegin());
    apiUtils.apiRequest({
        method: 'get',
        baseUrlType: BASE_URL_TYPES.REPORTS,
        url: `b2b/v1/partners/${Constants.PARTNER_ID}/shipper-customers?loggedInUserTokken=true`,
    })
        .then(response => {
            dispatch(customerListSuccess(response?.data?.response))
        })
        .catch(error => dispatch(customerListFailure(error)));
}

// export const getLocationList = (partnerIds, entityType) => dispatch => {

//     dispatch(locationListBegin());
//     apiUtils.apiRequest({
//         method: 'get',
//         baseUrlType: BASE_URL_TYPES.REPORTS,
//         url: `b2b/v1/partners/${Constants.PARTNER_ID}/locations/?partnerList=${partnerIds}&entityType=${entityType}&view=LITE_LOCATION_LIST`,
//     })
//         .then(response => {
//             dispatch(locationListSuccess(response.data.viewResponse.viewResponse))
//         })
//         .catch(error => dispatch(locationListFailure(error)));
// }

export const getLocationList = (data) => dispatch => {

    dispatch(locationListBegin());
    apiUtils.apiRequest({
        method: 'POST',
        baseUrlType: BASE_URL_TYPES.REPORTS,
        data,
        url: `b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
    })
        .then(response => {
            dispatch(locationListSuccess(response.data.response.locations))
        })
        .catch(error => dispatch(locationListFailure(error)));
}

export const getAllLocations = (data) => {
    const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`;
    return apiUtils.apiRequest({
        url,
        method: "POST",
        data
    });
}

export const getPartnerList = () => dispatch => {
    dispatch(partnerListBegin());
    dispatch(partnerListSuccess(JSON.parse(atob(localStorage.getItem('_partnerDetails')))))
}

export const customUrlApiCall = (url, method, data) => dispatch => {
    dispatch(customApiCallBeign());
    apiUtils.apiRequest({
        method: method,
        url: url,
        data,
        baseUrlType: BASE_URL_TYPES.REPORTS

    })
        .then(response => {

            if (checkHttpStatus(response?.data?.status)) {
                dispatch(customApiCallSuccess(response.data))
            } else {

                dispatch(customApiCallFailure(response.data));
            }
        })
        .catch(error => dispatch(customApiCallFailure(error)));
}

export const uploadIngestFile = (report_type, data) => dispatch => {
    dispatch(uploadReportFileBegin());
    apiUtils.apiRequest({
        method: 'post',
        url: `b2b/v1/uploads/ingest/${report_type}/execute`,
        data
    })
        .then(response => {
            dispatch(uploadReportFileSucces(response.data.response))
        })
        .catch(error => dispatch(uploadReportFileFailure(error)));
}

export const getEmplyeesList = () => dispatch => {

    dispatch(employeesListBegin());
    apiUtils.apiRequest({
        method: 'get',
        baseUrlType: BASE_URL_TYPES.REPORTS,
        url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/${Constants.userLocationId}/users`
    })
        .then(response => {
            dispatch(employeesListSuccess(response.data.response.users))
        })
        .catch(error => dispatch(employeesListFailure(error)));
}

function reportTypeBegin() {
    return {
        type: CONSTANTS.GET_REPORT_TYPE_BEGIN,
        payload: null
    }
}

function reportTypeSuccess(response) {
    return {
        type: CONSTANTS.GET_REPORT_TYPE_SUCCESS,
        payload: response.reportTypes
    }
}

function reportTypeFailure(response) {
    return {
        type: CONSTANTS.GET_REPORT_TYPE_FAILURE,
        payload: response
    }
}

function uploadReportFileBegin() {
    return {
        type: CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_BEGIN,
        payload: null
    }
}

function uploadReportFileSucces(resp) {
    return {
        type: CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_SUCCESS,
        payload: resp
    }
}

function uploadReportFileFailure(resp) {
    return {
        type: CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_FAILURE,
        payload: resp
    }
}

function customerListBegin() {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_BEGIN,
        payload: null
    }
}

function customerListSuccess(resp) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_SUCCESS,
        payload: resp
    }
}

function customerListFailure(resp) {
    return {
        type: CONSTANTS.GET_CUSTOMER_LIST_FAILURE,
        payload: null
    }
}

function locationListBegin() {
    return {
        type: CONSTANTS.GET_LOCATION_LIST_BEGIN,
        payload: null
    }
}

function locationListSuccess(resp) {
    return {
        type: CONSTANTS.GET_LOCATION_LIST_SUCCESS,
        payload: resp
    }
}

function locationListFailure(resp) {
    return {
        type: CONSTANTS.GET_LOCATION_LIST_FAILURE,
        payload: null
    }
}

function customApiCallBeign() {
    return {
        type: CONSTANTS.GET_CUSTOM_API_CALL_BEGIN,
        payload: null
    }
}

function customApiCallSuccess(resp) {
    return {
        type: CONSTANTS.GET_CUSTOM_API_CALL_SUCCESS,
        payload: resp
    }
}

function customApiCallFailure(resp) {

    return {
        type: CONSTANTS.GET_CUSTOM_API_CALL_FAILURE,
        payload: resp
    }
}

function partnerListBegin() {
    return {
        type: CONSTANTS.GET_PARTNER_LIST_BEGIN,
        payload: null
    }
}

function partnerListSuccess(resp) {
    return {
        type: CONSTANTS.GET_PARTNER_LIST_SUCCESS,
        payload: resp
    }
}

function employeesListBegin() {
    return {
        type: CONSTANTS.GET_EMPLOYEES_LIST_BEGIN,
        payload: null
    }
}

function employeesListSuccess(resp) {
    return {
        type: CONSTANTS.GET_EMPLOYEES_LIST_SUCCESS,
        payload: resp
    }
}

function employeesListFailure(resp) {
    return {
        type: CONSTANTS.GET_EMPLOYEES_LIST_FAILURE,
        payload: null
    }
}