import React, { PureComponent } from 'react';
import * as actions from './actions';
import './form.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, Form, PageHeader, Divider, Select, Checkbox, Radio, Spin, Switch } from 'antd';
import { canAllow, dataGet, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { validationRules, Constants } from '../../../common/utils/constants';
import * as appAction from '../../../app/appAction';
import { Mixpanel } from '../../../utils/mixpanel';
import _ from 'lodash';

const { confirm } = Modal;

class CustomerForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          customerTypes: ['CUSTOMER'],
          status: ["ACTIVE"],
          fetchMode: 'BASIC'
        }
      },
      customerPermission: {
        read: canAllow(permissionTags.settings.customer.read),
        update: canAllow(permissionTags.settings.customer.update),
        create: canAllow(permissionTags.settings.customer.create),
        delete: canAllow(permissionTags.settings.customer.delete),
        view: canAllow(permissionTags.settings.customer.view),
      },
      customerId: props.match.params.id,
      isEdit: !(props.match.params.id > 0),
      isGstRequire: true
    }
    if (!this.state.customerPermission.read) {
      windowRedirect('settings/dashboard/');
    }
  }

  componentDidMount() {
    if (!this.state.isEdit) {
      this.props.getCustomer(this.state.customerId)
    }
    if (!this.props.cities)
      this.props.getCities();
    this.props.getOrganisations();
    const user = JSON.parse(atob(localStorage.getItem('user')));
    const loggedPartnerId = localStorage.getItem('loggedPartnerId');
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      if (this.props.customer) {
        this.props.fetchCustomers(this.state.filterPayload);
        this.props.history.push('/appv2/settings/dashboard/customer')
      }
    }

    if (this.props.pincode !== prevProps.pincode) {
      if (this.state.pincodeType === 'ho') {
        this.setState({
          hoPincodes: this.props.pincode
        });
      } else {
        this.setState({
          billingPincodes: this.props.pincode
        });
      }
    }

    if (this.props.selectedCustomer !== prevProps.selectedCustomer) {
      const { selectedCustomer, form } = this.props
      const pickupLoc = (selectedCustomer.locations.PICKUP) ? selectedCustomer.locations.PICKUP[0] : {};
      const billingLocation = (selectedCustomer.locations.BILLING) ? selectedCustomer.locations.BILLING[0] : pickupLoc;
      const headOfc = (selectedCustomer.locations.HEAD_OFFICE) ? selectedCustomer.locations.HEAD_OFFICE[0] : {};
      this.hoCityChange(dataGet(headOfc, 'address.pincode.city.id'));
      this.billingCityChange(dataGet(billingLocation, 'address.pincode.city.id'));
      this.setState({
        isGstRequire: selectedCustomer['gstin'] ? true : false
      })
      form.setFieldsValue({
        'customerName': selectedCustomer.name,
        'code': selectedCustomer.code,
        'contactNumber': selectedCustomer.phoneNumber,
        'secondaryContactNumber': dataGet(selectedCustomer, 'secondaryContactNumber'),
        'email': selectedCustomer.emailId,
        'secondaryEmail': dataGet(selectedCustomer, 'secondaryEmail'),
        'tinNumber': selectedCustomer.tin,
        'gstNumber': selectedCustomer.gstin,
        'gstChecked': selectedCustomer.gstin ? true : false,
        'contactPerson': selectedCustomer.contactPerson,
        'insurance': selectedCustomer.insurance,
        'hoLocationName': headOfc.name,
        'hoAddress1': dataGet(headOfc, 'address.address1'),
        'hoAddress2': dataGet(headOfc, 'address.address2'),
        'hoLandmark': dataGet(headOfc, 'address.landmark'),
        'hoCityId': dataGet(headOfc, 'address.pincode.city.id'),
        'hoPincodeId': dataGet(headOfc, 'address.pincode.zipcode'),
        'billingLocationName': billingLocation.name,
        'billingAddress1': dataGet(billingLocation, 'address.address1'),
        'billingAddress2': dataGet(billingLocation, 'address.address2'),
        'billingLandmark': dataGet(billingLocation, 'address.landmark'),
        'billingCityId': dataGet(billingLocation, 'address.pincode.city.id'),
        'billingPincodeId': dataGet(billingLocation, 'address.pincode.zipcode'),
        'organisationId': dataGet(selectedCustomer, 'organisation.id'),
        'paymentTypes': selectedCustomer.paymentTypes,
        'plDocket': _.hasIn(selectedCustomer, 'attributes') && _.hasIn(selectedCustomer.attributes, 'threePlReferenceNumber') && selectedCustomer.attributes.threePlReferenceNumber === 'true' ? true :false
      }, () => {
        if (this.props.appConfig?.customer_attributes) {
          if (selectedCustomer?.attributes) {
            _.forIn(selectedCustomer?.attributes, (value, key) => {
              selectedCustomer.attributes[key] = value === "true" ? true : false;
            });
            form.setFieldsValue({
              'attributes': selectedCustomer?.attributes
            })
          }

        }
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const _this = this;
    this.props.form.validateFieldsAndScroll((err, values) => {
      const customer = { ...values };
      if (!err) {
        const payload = { ...values };
        delete payload['plDocket'];
        let customerId = 0;
        const { attributes } = payload;
        let billingLocation = {};
        let headOfficeLocation = {};
        payload.attributes = {
          ...attributes,
          'threePlReferenceNumber': values['plDocket']
        };
        if (!_this.state.isEdit) {
          const pickupLoc = (_this.props.selectedCustomer.locations.PICKUP) ? _this.props.selectedCustomer.locations.PICKUP[0] : {};
          billingLocation = (_this.props.selectedCustomer.locations.BILLING) ? _this.props.selectedCustomer.locations.BILLING[0] : pickupLoc;
          headOfficeLocation = (_this.props.selectedCustomer.locations.HEAD_OFFICE) ? _this.props.selectedCustomer.locations.HEAD_OFFICE[0] : {};
          customerId = _this.props.selectedCustomer.id;
        }

        if (_this.isHoAdnBiSame(customer)) {
          payload.locations = [_this.getLocationObject(customer, 'ho', ['PICKUP', 'DELIVERY', 'HEAD_OFFICE', 'BOOKING_OFFICE', 'BILLING'], headOfficeLocation)];
        } else {
          payload.locations = [
            _this.getLocationObject(customer, 'ho', ['PICKUP', 'DELIVERY', 'HEAD_OFFICE', 'BOOKING_OFFICE'], headOfficeLocation),
            _this.getLocationObject(customer, 'billing', ['BILLING'], billingLocation)
          ];
        }
        if (!_this.state.isEdit) {
          payload.id = customerId
          Mixpanel.track("Customer Updation Initiated", { 'ERPCustomerUpdatedtomer': moment().valueOf(), 'GST': payload.gstNumber, 'payment_type': payload.paymentTypes, 'pincode': payload.hoPincodeId, customer_id: customerId })
          _this.props.updateCustomer(customerId, payload);
        } else {
          Mixpanel.track("Customer Creation Initiated", { 'ERPCustomerCreated': moment().valueOf(), 'GST': payload.gstNumber, 'payment_type': payload.paymentTypes, 'pincode': payload.hoPincodeId })
          _this.props.createCustomer(payload);
        }
      }
    });
  }

  isHoAdnBiSame(customer) {
    const locationObjects = ['LocationName', 'Address1', 'Address2', 'Landmark', 'PincodeId', 'CityId'];
    let isSame = true;
    for (let i = 0; i < locationObjects.length; i++) {
      if (customer['ho' + locationObjects[i]] !== customer['billing' + locationObjects[i]]) {
        isSame = false;
        break;
      }
    }
    return isSame;
  }

  getLocationObject = (customer, type, roles, location) => {
    const pincode = type === 'ho' ? 'hoPincode' : 'BPincode';
    return {
      'id': dataGet(location, 'id'),
      'name': customer[type + 'LocationName'],
      'roles': roles,
      'address': {
        'name': customer[type + 'LocationName'],
        'address1': customer[type + 'Address1'],
        'address2': customer[type + 'Address2'],
        'landmark': customer[type + 'Landmark'],
        'pincode': {
          'id': this.props[pincode].pincodedetails[customer[type + 'PincodeId']].id,
          'city': {
            'id': customer[type + 'CityId']
          }
        }
      },
      serviableAreaMapping: [this.getServiableAreaMappingObject(customer[type + 'PincodeId'], type)]
    };
  }

  getServiableAreaMappingObject = (pincodeId, type) => {
    const pincode = type === 'ho' ? 'hoPincode' : 'BPincode';
    return {
      fromEntityId: this.props[pincode].pincodedetails[pincodeId].id,
      fromEntityType: 'PINCODE',
      toEntityId: this.props[pincode].pincodedetails[pincodeId].id,
      toEntityType: 'PINCODE',
      partnerId: Constants.PARTNER_ID,
      userId: Constants.sessionUser.id
    };
  }

  billingCityChange = (val) => {
    if (!val) return;
    const cityId = val;
    this.props.getPincodesByCity(cityId, 'billling');
  }

  hoCityChange = (val) => {
    if (!val) return;
    const cityId = val;
    this.props.getPincodesByCity(cityId, 'ho');
  }


  copyHOAddress = () => {
    const { setFieldsValue, getFieldsValue } = this.props.form;
    const formData = getFieldsValue();
    setFieldsValue({
      'billingLocationName': formData.hoLocationName,
      'billingAddress1': formData.hoAddress1,
      'billingAddress2': formData.hoAddress2,
      'billingLandmark': formData.hoLandmark,
      'billingCityId': formData.hoCityId,
      'billingPincodeId': formData.hoPincodeId,
    });
    this.billingCityChange(formData.hoCityId);
  }

  sameAsHOChange = (event) => {
    if (event.target.checked) {
      this.copyHOAddress();
    }
  }

  goBack = () => {
    this.props.history.goBack()
    this.props.fetchCustomers(this.state.filterPayload)
  }

  gstCheckRequired = (val, e) => {
    this.setState({
      isGstRequire: val
    })
    if (!val) {
      this.props.form.resetFields(["gstNumber"])
    }
  }

  render() {
    const { isEdit, customerPermission } = this.state;
    const { form, isCustomerLoading, appConfig } = this.props
    const { getFieldDecorator, getFieldValue } = form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelAlign: "left",
      colon: false
    };


    return (
      <>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <Row>
            <Col span={24}>
              <Card className="CustomerDashBoard" title={<h3> <Icon onClick={this.goBack} style={{ margin: 15, marginLeft: 0 }} type="arrow-left" /> {isEdit ? 'Create' : 'Update'} Customer</h3>}>
                <Spin spinning={isCustomerLoading}>
                  <Row gutter={24}>
                    <Col span="12">

                      <Form.Item label="Customer Name">
                        {getFieldDecorator('customerName', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input customer name!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Enter customer name"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Customer Code">
                        {getFieldDecorator('code', {
                          rules: [
                            {
                              required: false,
                              message: 'Please input customer code!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Enter customer code"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Phone number">
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('contactNumber', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input mobile number!',
                              },
                              {
                                pattern: validationRules.mobile,
                                message: "Please enter valid mobile number"

                              }
                            ],
                          })(
                            <Input
                              placeholder="Enter Phone number"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('secondaryContactNumber', {
                            rules: [
                              {
                                required: false,
                                message: "Please input mobile number"
                              },
                              {
                                pattern: validationRules.mobile,
                                message: "Please enter valid mobile number"

                              }
                            ],
                          })(
                            <Input
                              placeholder="Secondary number"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                      </Form.Item>
                      <Form.Item label="Email">
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('email', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input primary email!',
                              },
                              {
                                pattern: validationRules.email,
                                message: "Please enter valid email"

                              }
                            ],
                          })(
                            <Input
                              placeholder="Enter Email ID"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('secondaryEmail', {
                            rules: [
                              {
                                required: false,
                                message: "Please input secondary email"
                              },
                              {
                                pattern: validationRules.email,
                                message: "Please enter valid email"

                              }
                            ],
                          })(
                            <Input
                              placeholder="Secondary Email"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                      </Form.Item>
                      <Form.Item label="Contact Person">
                        {getFieldDecorator('contactPerson', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input contact name!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Enter contact person name"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Divider orientation="left">Head Office Address</Divider>
                      <Form.Item label="Location Name">
                        {getFieldDecorator('hoLocationName', {
                          rules: [
                            {
                              required: true,
                              message: 'Location name can\'t be blank!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Location name"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Address">
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('hoAddress1', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input address!',
                              },
                            ],
                          })(
                            <Input
                              placeholder="Address1"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('hoAddress2', {
                            rules: [
                              {
                                required: false,
                                message: "Please input secondary address"
                              }
                            ],
                          })(
                            <Input
                              placeholder="Address2"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                      </Form.Item>
                      <Form.Item label="Landmark">
                        {getFieldDecorator('hoLandmark', {
                          rules: [
                            {
                              required: true,
                              message: 'Landmark can\'t be blank!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Landmark"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="City">
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('hoCityId', {
                            rules: [
                              {
                                required: true,
                                message: 'Primary email can\'t be blank!',
                              }
                            ],
                          })(
                            <Select
                              placeholder="Select City"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                              showSearch
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              showArrow
                              onChange={this.hoCityChange}>
                              {this.props?.cities?.citiesDetails?.map(city => (
                                <Select.Option
                                  key={city.id}
                                  value={city.id}
                                  label={city.name}
                                >
                                  {city.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('hoPincodeId', {
                            rules: [
                              {
                                required: false,
                                message: "Secondary email can\'t be blank!"
                              }
                            ],
                          })(
                            <Select disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))} placeholder="Select Pincode" showSearch filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } showArrow onChange={this.onPartnerChange}>
                              {this.props?.hoPincode?.pincodes?.map(pin => (
                                <Select.Option
                                  key={pin}
                                  value={pin}
                                  label={pin}
                                >
                                  {pin}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                      </Form.Item>
                      <Divider orientation="left">
                        Billing Address
                      <div className="ant-divider ant-divider-horizontal" style={{ display: 'inline-block', minWidth: 'auto', width: 150 }}></div>
                        <Checkbox disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))} onChange={this.sameAsHOChange}>Same As Head Office</Checkbox>
                      </Divider>
                      <Form.Item label="Location Name">
                        {getFieldDecorator('billingLocationName', {
                          rules: [
                            {
                              required: true,
                              message: 'Location name can\'t be blank!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Location name"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Address">
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('billingAddress1', {
                            rules: [
                              {
                                required: true,
                                message: 'Address can\'t be blank!!',
                              },
                            ],
                          })(
                            <Input
                              placeholder="Address1"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('billingAddress2', {
                            rules: [
                              {
                                required: false,
                                message: "Secondary address can\'t be blank!"
                              }
                            ],
                          })(
                            <Input
                              placeholder="Address2"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                            />
                          )}
                        </div>
                      </Form.Item>
                      <Form.Item label="Landmark">
                        {getFieldDecorator('billingLandmark', {
                          rules: [
                            {
                              required: true,
                              message: 'Landmark can\'t be blank!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Landmark"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="City">
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('billingCityId', {
                            rules: [
                              {
                                required: true,
                                message: 'City can\'t be blank!!',
                              }
                            ],
                          })(
                            <Select
                              placeholder="Select City"
                              disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                              showSearch
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              showArrow
                              onChange={this.billingCityChange}>
                              {this.props?.cities?.citiesDetails?.map(city => (
                                <Select.Option
                                  key={city.id}
                                  value={city.id}
                                  label={city.name}
                                >
                                  {city.name}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                        <span style={{ display: 'inline-block', width: '24px' }}></span>
                        <div style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                          {getFieldDecorator('billingPincodeId', {
                            rules: [
                              {
                                required: true,
                                message: "Pincode can\'t be blank!"
                              }
                            ],
                          })(
                            <Select disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))} placeholder="Select Pincode" showSearch filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } showArrow onChange={this.onPartnerChange}>
                              {this.props?.BPincode?.pincodes?.map(pin => (
                                <Select.Option
                                  key={pin}
                                  value={pin}
                                  label={pin}
                                >
                                  {pin}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                      </Form.Item>
                      {/* <Divider /> */}

                    </Col>
                    <Col span={1}>
                      <Divider className="customer-divider" type="vertical" />
                    </Col>
                    <Col span={10}>

                      <Form.Item label="Tin">
                        {getFieldDecorator('tinNumber', {
                          rules: [
                            {
                              required: true,
                              message: 'Tin can\'t be blank!',
                            },
                          ],
                        })(
                          <Input
                            placeholder="Enter Tin"
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="GST">
                        <Switch checkedChildren="Yes" disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))} unCheckedChildren="No" checked={this.state.isGstRequire} onChange={this.gstCheckRequired} /> <span>GST Applicable</span>
                        {getFieldDecorator('gstNumber', {
                          rules: [
                            {
                              validator: async (rule, value) => {
                                const that = this;
                                if (that.state.isGstRequire) {
                                  if (typeof value == undefined && value.length <= 0) {
                                    throw new Error("GST can\'t be blank!");
                                  }
                                }
                              }
                            },
                            {
                              pattern: validationRules.gstNumber,
                              message: "Please enter valid GST number"

                            }
                          ],
                        })(
                          <Input
                            placeholder="Enter GST"
                            disabled={((isEdit ? false : (!customerPermission.update && customerPermission.view)) || (!this.state.isGstRequire))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Payment Type">
                        {getFieldDecorator('paymentTypes', {
                          rules: [
                            {
                              required: true,
                              message: "Atleast One Payment Type should be selected"
                            }
                          ],
                        })(
                          <Checkbox.Group
                            options={Constants.paymentTypes}
                            disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Insurance">
                        {getFieldDecorator('insurance', {
                          initialValue: 'yes',
                          rules: [
                            {
                              required: false,
                              message: "Insurance can\'t be blank!"
                            }
                          ],
                        })(
                          <Radio.Group disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))}>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'no'}>No</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                      {
                        appConfig?.customer_attributes &&
                        appConfig?.customer_attributes.map(attr => (
                          <Form.Item label={attr}>
                            {getFieldDecorator(`attributes.${attr}`, {
                              initialValue: false,
                              valuePropName: 'checked',
                              rules: [
                                {
                                  required: true,
                                  message: `Please input ${attr}`
                                }
                              ],
                            })(
                              <Switch

                              />
                            )}
                          </Form.Item>
                        ))
                      }

                      <Form.Item label={"3PL Docket No. Required"}>
                        {getFieldDecorator('plDocket', {
                          initialValue: false,
                          valuePropName: 'checked',
                          rules: [
                            {
                              required: false,
                              message: `Please input`
                            }
                          ],
                        })(
                          <Switch
                            checkedChildren="Yes" unCheckedChildren="No"
                          />
                        )}
                      </Form.Item>

                      <Form.Item label="Organisation">
                        {getFieldDecorator('organisationId', {
                          rules: [
                            {
                              required: false,
                              message: "Please input Organisation"
                            }
                          ],
                        })(
                          <Select disabled={(isEdit ? false : (!customerPermission.update && customerPermission.view))} placeholder="Select Organisation" showSearch filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          } showArrow onChange={this.onPartnerChange}>
                            {this.props?.organisations?.map(org => (
                              <Select.Option
                                key={org.id}
                                value={org.id}
                                label={org.name}
                              >
                                {org.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>

                    </Col>

                  </Row>
                </Spin>
              </Card>

            </Col>

          </Row>

          <Card>
            <Spin spinning={isCustomerLoading}>
              <Row>
                <Col span={3} offset={(isEdit ? false : (!customerPermission.update && customerPermission.view)) ? 21 : 18} >
                  <Button size="large" onClick={this.goBack} >CANCEL</Button>
                </Col>
                {
                  (isEdit ? true : !(!customerPermission.update && customerPermission.view))
                    ? <Col span={3}>
                      <Button size="large" htmlType="submit" type="primary">{isEdit ? 'CREATE' : 'UPDATE'}</Button>
                    </Col>
                    : null
                }
              </Row>
            </Spin>
          </Card>
        </Form>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  cities: state.app.cities,
  hoPincode: state.settingsCustomers?.hoPincode,
  BPincode: state.settingsCustomers?.BPincode,
  organisations: state.settingsCustomers.organisations,
  isCustomerLoading: state.settingsCustomers.loading,
  selectedCustomer: state.settingsCustomers.selectedCustomer,
  customer: state.settingsCustomers.customer,
  appConfig: state?.app?.configurations,
});
const mapDispatchToProps = dispatch => ({
  getCities: () => dispatch(appAction.getCities()),
  getPincodesByCity: (cityId, type) => dispatch(actions.getPincodesByCity(cityId, type)),
  getOrganisations: () => dispatch(actions.getOrganisations()),
  getCustomer: (id) => dispatch(actions.getCustomer(id)),
  createCustomer: (customer) => dispatch(actions.createCustomer(customer)),
  updateCustomer: (id, customer) => dispatch(actions.updateCustomer(id, customer)),
  fetchCustomers: (param, isReset = true) => dispatch(actions.fetchCustomers(param, isReset)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Customer', onValuesChange: (props, val) => { } })(CustomerForm));