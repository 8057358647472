import { apiRequest } from "../../common/utils/apiGateway";
import { Constants } from '../../common/utils/constants';

const CustomerService = {
    getCustomersFetch: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/post/fetch`,
            method: "POST",
            data: {
                filters: {
                    ...payload ?.filters,
                    partnerIds: [Constants.PARTNER_ID]
                }
            }
        })
    },
    getShippers: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/customer/shipper-mapping/fetchAll`,
            method: "POST",
            data: payload
        })
    },    
    getCustomers: (params = {}) => {
        if (!params.hasOwnProperty('loggedInUserTokken')) {
            params['loggedInUserTokken'] = true;
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers`;
        return apiRequest({
            url,
            method: "GET",
            params
        })
    },

    getCustomersNew: (params = {}) => {
        if (!params.hasOwnProperty('loggedInUserTokken')) {
            params['loggedInUserTokken'] = true;
        }
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/shipper-customers-trimmed`;
        return apiRequest({
            url,
            method: "GET",
            params
        })
    },

    fetchCustomers: (params = {}) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/fetch`;
        return apiRequest({
            url,
            method: "GET",
            params
        })
    },

    createCustomer: (customer) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers`;
        return apiRequest({
            url,
            method: "POST",
            data: customer
        })
    },

    updateCustomer: (customerId, customer) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/${customerId}`;
        return apiRequest({
            url,
            method: "PUT",
            data: customer
        })
    },

    deleteCustomer: (customerId) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/${customerId}`;
        return apiRequest({
            url,
            method: "DELETE",
        })

    },

    getCustomer: (customerId) => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/customers/${customerId}`;
        return apiRequest({
            url,
            method: "GET",
        })
    },

    getOrganisations: () => {
        const url = `/b2b/v1/organisations/`;
        return apiRequest({
            url,
            method: "GET",
        })
    },

    getShipperDetails: () => {
        const url = `/b2b/v1/partners/${Constants.PARTNER_ID}/shipper-customers`;
        return apiRequest({
            url,
            method: "GET",
            params: { 'loggedInUserTokken': true }
        })
    },

    getShipperDetailsByChildParnterId: (param) => {
        const url = `/b2b/v1/partners/${param.child_partner_id}/shipper-customers`;
        return apiRequest({
            url,
            method: "GET",
            params: { 'loggedInUserTokken': true }
        })
    },

    getAccessKeys: (id) => {
        const url = `/b2b/v1/users/access-keys?customer_id=${id}`;
        return apiRequest({
            url,
            method: "GET"            
        });
    }
}

export default CustomerService