import React, { Component } from 'react';
import { Button, Input, Select, Row, Col } from 'antd';
const DEFAULT_VALUES = {
    string: '',
    object: {},
    array: [],
    number: 0,
    boolean: false
}
const { Option } = Select;
class SchemaCreate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            create: false,
            elKey: this.props.elKey,
            elType: 'string'
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({ elKey: newProps.elKey });
    }

    handleCreate = (e) => {
        e.preventDefault();
        this.setState({ create: true, value: undefined });
    }

    handleCancel = (e) => {
        e.preventDefault();
        this.setState({ create: false });
    }

    changeType = (e) => {
        this.setState({ elType: e });
    }

    changeKey = (e) => {        
        this.setState({ elKey: e.target.value, value: e.target.value });
    }

    createAttribute = () => {
        this.setState({ create: false });
        let parent = this.props.parent;
        let val = DEFAULT_VALUES[this.state.elType]

        if (parent.constructor == Array)
            parent.push(val)
        else
            parent.set(this.state.elKey, val);
    }

    render() {
        if (!this.state.create)
            return <a href="#" onClick={this.handleCreate}>+ Add {this.props.elType}</a>;

        let elName;
        if (typeof this.props.elKey != 'undefined')
            elName = <span className="elName">{this.props.elKey}:</span>;
        else {
            elName = [
                <Col span={7}>
                <Input placeholder="Enter Key" style={{ width: 140 }} ref="keyInput" type="text" value={this.state.value} onChange={this.changeKey} />
                {/* <span>:</span> */}
                </Col>
            ];
        }

        return (<div className="element"><Row>

            {elName}

            <Col span={5}>
                <Select style={{ width: 100 }} value={this.state.elType} onChange={this.changeType} ref="typeSelector">
                    <Option key={"string"} value="string" key={elName + "string"}>String</Option>
                    <Option key={"number"} value="number" key={elName + "number"}>Number</Option>
                    <Option key={"boolean"} value="boolean" key={elName + "boolean"}>Boolean</Option>
                    <Option key={"array"} value="array" key={elName + "array"}>Array</Option>
                    <Option key={"object"} value="object" key={elName + "object"}>Object</Option>
                </Select>
            </Col>
            <Col span={4}>
                <Button type="primary" onClick={this.createAttribute}>Create</Button>
            </Col>
            <Col span={4}>
                <a href="#" className="canElement" onClick={this.handleCancel}>Cancel</a>
            </Col>
        </Row></div>);
    }
}

export default SchemaCreate;