import React from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";

const RouteWithTitle = ({ component, path, name, exact }) => {
  document.title = getDocumentTitle(name);
  /**
     * Start
     *
     * Reason: This piece of code was written to remove the localStorage ["hyperlocalCityId", "hyperlocalLocationIds"] on unloading of hyperlocal module.
     *         It is used to maintain the filters throughout the hyperlocal routes.
     * Note: We need to revisit this code and think about better way of handling any logic at module level in this case we need Filters at module level, whatever the filters are applied, are used in all submodules.
     */
  if (!window.location.pathname.includes("/appv2/hyperlocal/")) {
    localStorage.removeItem("hyperlocalCityId");
    localStorage.removeItem("hyperlocalLocationIds");
  }
  /** end */
  return <Route component={withRouter(component)} path={path} exact={exact} />;
};
function getDocumentTitle(name) {
  let titleParts = document.title.split("|");
  titleParts[0] = "Loadshare Networks";
  titleParts[1] = ` ${name} `;
  return titleParts.join(" |");
}

export default RouteWithTitle;
