import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Input,
  Row,
  Col,
  Icon,
  Modal,
  Tooltip,
  Select,
} from "antd";
import { table as Table } from "../ui/table/Table";
import * as apiUtils from "../../common/utils/apiGateway";
import { canAllow } from "../../common/utils/utils";
import { permissionTags } from "../../configs/permissionTags";
import { apiRequest } from "../../common/utils/apiGateway";
import "./common.scss";
import NumberFormat from "react-number-format";

function FMLMPanIndiaDashboard(props) {
  const { Option } = Select;
  const [fmlmUpdatePermission] = useState({
    read: canAllow(permissionTags.fm_lm_config.fm_lm_country.read),
    create: canAllow(permissionTags.fm_lm_config.fm_lm_country.create),
    update: canAllow(permissionTags.fm_lm_config.fm_lm_country.update),
  });

  const [isInputModalVisible, setIsInputModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(undefined);
  const [selectedPersona, setSelectedPersona] = useState(undefined);

  const [oldMinFM, setOldMinFM] = useState(null);
  const [oldMaxFM, setOldMaxFM] = useState(null);
  const [oldMinLM, setOldMinLM] = useState(null);
  const [oldMaxLM, setOldMaxLM] = useState(null);

  const [newMinFM, setNewMinFM] = useState(undefined);
  const [newMaxFM, setNewMaxFM] = useState(undefined);
  const [newMinLM, setNewMinLM] = useState(undefined);
  const [newMaxLM, setNewMaxLM] = useState(undefined);

  const [flagMinFM, setFlagMinFM] = useState(false);
  const [flagMaxFM, setFlagMaxFM] = useState(false);
  const [flagMinLM, setFlagMinLM] = useState(false);
  const [flagMaxLM, setFlagMaxLM] = useState(false);

  const [isInputAmountErrorModal, setIsInputAmountErrorModal] = useState(false);

  const personaTypes = ["Short", "Medium", "Long"];

  const [rowVal, setRowVal] = useState({});

  const getCustomerList = () => {
    const data = {
      clusterId: null,
      hyperlocalServiceableAreaCode: null,
      configType: "PAN_INDIA",
    };
    apiRequest({
      url: "/v1/hyperlocal-zone-config/getAllocConfig",
      method: "POST",
      data,
    }).then((res) => {
      setCustomerList(res.data.response.zoneConfigs);
    });
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  useEffect(() => {
    if (!flagMinFM && oldMinFM !== newMinFM) {
      setNewMinFM(oldMinFM);
    }
    if (!flagMaxFM && oldMaxFM !== newMaxFM) {
      setNewMaxFM(oldMaxFM);
    }
    if (!flagMinLM && oldMinLM !== newMinLM) {
      setNewMinLM(oldMinLM);
    }
    if (!flagMaxLM && oldMaxLM !== newMaxLM) {
      setNewMaxLM(oldMaxLM);
    }
  }, [
    oldMinFM,
    oldMaxFM,
    oldMinLM,
    oldMaxLM,
    flagMinFM,
    flagMaxFM,
    flagMinLM,
    flagMaxLM,
  ]);

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "name",
      render: (name, row) => {
        return `${name}`;
      },
      width: "15%",
    },
    {
      title: "Distance Persona",
      dataIndex: "distance-persona",
      key: "distance-persona",
      width: "15%",
      render: (data, row) => {
        return (
          <Select
            showSearch
            allowClear
            placeholder="Distance Persona"
            onChange={(val) => distancePersonaChangeHandler(row.id, val)}
            style={{ width: 150 }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={row?.selectedPersona}
          >
            {personaTypes.map((persona, index) => {
              return (
                <Option key={index} value={persona}>
                  {persona}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => {
        if (!startTime) {
          return <>Not Available</>;
        } else {
          return `${startTime}`;
        }
      },
      width: "10%",
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (endTime) => {
        if (!endTime) {
          return <>Not Available</>;
        } else {
          return `${endTime}`;
        }
      },
      width: "10%",
    },
    {
      title: "Min FM Distance",
      dataIndex: "metaData.minDistanceToTravelForPickup",
      key: "metaData.minDistanceToTravelForPickup",
      render: (data, row) => {
        switch (row.selectedPersona) {
          case "Short":
            setOldMinFM(row.metaData.minDistanceToTravelForPickupCyclist);
            return <>{row.metaData.minDistanceToTravelForPickupCyclist}</>;
          case "Medium":
            setOldMinFM(row.metaData.minDistanceToTravelForPickup);
            return <>{row.metaData.minDistanceToTravelForPickup}</>;
          case "Long":
            setOldMinFM(row.metaData.minDistanceToTravelForPickupSuperBike);
            return <>{row.metaData.minDistanceToTravelForPickupSuperBike}</>;
          default:
            return null;
        }
      },
    },
    {
      title: "Max FM Distance",
      dataIndex: "maxFM",
      key: "maxFM",
      render: (data, row) => {
        switch (row.selectedPersona) {
          case "Short":
            setOldMaxFM(row.metaData.maxDistanceToTravelForPickupCyclist);
            return <>{row.metaData.maxDistanceToTravelForPickupCyclist}</>;
          case "Medium":
            setOldMaxFM(row.metaData.maxDistanceToTravelForPickup);
            return <>{row.metaData.maxDistanceToTravelForPickup}</>;
          case "Long":
            setOldMaxFM(row.metaData.maxDistanceToTravelForPickupSuperBike);
            return <>{row.metaData.maxDistanceToTravelForPickupSuperBike}</>;
          default:
            return null;
        }
      },
    },
    {
      title: "Min LM Distance",
      dataIndex: "minLM",
      key: "minLM",
      render: (data, row) => {
        switch (row.selectedPersona) {
          case "Short":
            setOldMinLM(row.metaData.minDistanceToTravelForDropCyclist);
            return <>{row.metaData.minDistanceToTravelForDropCyclist}</>;
          case "Medium":
            setOldMinLM(row.metaData.minDistanceToTravelForDrop);
            return <>{row.metaData.minDistanceToTravelForDrop}</>;
          case "Long":
            setOldMinLM(row.metaData.minDistanceToTravelForDropSuperBike);
            return <>{row.metaData.minDistanceToTravelForDropSuperBike}</>;
          default:
            return null;
        }
      },
    },
    {
      title: "Max LM Distance",
      dataIndex: "maxLM",
      key: "maxLM",
      render: (data, row) => {
        switch (row.selectedPersona) {
          case "Short":
            setOldMaxLM(row.metaData.maxDistanceToTravelForDropCyclist);
            return <>{row.metaData.maxDistanceToTravelForDropCyclist}</>;
          case "Medium":
            setOldMaxLM(row.metaData.maxDistanceToTravelForDrop);
            return <>{row.metaData.maxDistanceToTravelForDrop}</>;
          case "Long":
            setOldMaxLM(row.metaData.maxDistanceToTravelForDropSuperBike);
            return <>{row.metaData.maxDistanceToTravelForDropSuperBike}</>;
          default:
            return null;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: "10%",
      render: (row) => (
        <Row>
          {fmlmUpdatePermission.update && (
            <Col span={8}>
              <Tooltip placement="top" title={"Edit"}>
                <Button
                  disabled={!row.selectedPersona}
                  style={{ border: "none", background: "none" }}
                  onClick={() => openEditCustomer(row)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  const distancePersonaChangeHandler = (id, value) => {
    setSelectedPersona(value);
    setCustomerList([
      ...customerList.map((item) => {
        return { ...item, selectedPersona: item.id == id ? value : undefined };
      }),
    ]);
  };

  const openEditCustomer = (row) => {
    setSelectedCustomer(row.customerName);
    setIsInputModalVisible(true);
    setRowVal(row);
    setFlagMinFM(false);
    setFlagMaxFM(false);
    setFlagMinLM(false);
    setFlagMaxLM(false);
  };

  const handleUpdateConfig = () => {
    if (newMaxFM >= 1 && newMinFM >= 1 && newMaxLM >= 1 && newMinLM >= 1) {
      setIsInputAmountErrorModal(false);
      setIsUpdateModalVisible(true);
    } else {
      setIsUpdateModalVisible(false);
      setIsInputAmountErrorModal(true);
    }
  };

  const cancelHandler = () => {
    setIsInputModalVisible(false);
    setIsUpdateModalVisible(false);
    setNewMinFM(oldMinFM);
    setNewMaxFM(oldMaxFM);
    setNewMinLM(oldMinLM);
    setNewMaxLM(oldMaxLM);
  };

  const closeInputAmountError = () => {
    setIsInputAmountErrorModal(false);
  };
  const getPayload = () => {
    // let payload = _.cloneDeep(customerList)
    let payload = customerList.find((item) => item.id == rowVal.id); //_.cloneDeep(zoneData)
    switch (rowVal.selectedPersona) {
      case "Short":
        payload.metaData["minDistanceToTravelForPickupCyclist"] = newMinFM;
        payload.metaData["maxDistanceToTravelForPickupCyclist"] = newMaxFM;
        payload.metaData["minDistanceToTravelForDropCyclist"] = newMinLM;
        payload.metaData["maxDistanceToTravelForDropCyclist"] = newMaxLM;
        return payload;

      case "Medium":
        payload.metaData["minDistanceToTravelForPickup"] = newMinFM;
        payload.metaData["maxDistanceToTravelForPickup"] = newMaxFM;
        payload.metaData["minDistanceToTravelForDrop"] = newMinLM;
        payload.metaData["maxDistanceToTravelForDrop"] = newMaxLM;
        return payload;

      case "Long":
        payload.metaData["minDistanceToTravelForPickupSuperBike"] = newMinFM;
        payload.metaData["maxDistanceToTravelForPickupSuperBike"] = newMaxFM;
        payload.metaData["minDistanceToTravelForDropSuperBike"] = newMinLM;
        payload.metaData["maxDistanceToTravelForDropSuperBike"] = newMaxLM;
        return payload;
      default:
        return null;
    }
  };

  const submitHandler = () => {
    const payloadData = getPayload();
    apiUtils
      .apiRequest({
        url: "/v1/hyperlocal-zone-config/saveAllocConfigs",
        method: "POST",
        data: payloadData,
      })
      .then((res) => {
        console.log(res);
        setIsInputModalVisible(false);
        setIsUpdateModalVisible(false);
        setNewMinFM(undefined);
        setNewMaxFM(undefined);
        setNewMinLM(undefined);
        setNewMaxLM(undefined);
        props.history.push("/appv2/config/dashboard/country");
      });
  };

  function newMinFMChangeHandler(e) {
    setFlagMinFM(true);
    return setNewMinFM(e.target.value);
  }

  function newMaxFMChangeHandler(e) {
    setFlagMaxFM(true);
    return setNewMaxFM(e.target.value);
  }

  function newMinLMChangeHandler(e) {
    setFlagMinLM(true);
    return setNewMinLM(e.target.value);
  }
  function newMaxLMChangeHandler(e) {
    setFlagMaxLM(true);
    return setNewMaxLM(e.target.value);
  }

  return (
    <div>
      <Modal
        visible={isInputModalVisible}
        width={800}
        footer={null}
        onCancel={cancelHandler}
      >
        <h4 style={{ textAlign: "center", fontSize: "14px" }}>
          Editing Pan-India Allocation Config for {selectedCustomer}
        </h4>
        <div className="outer-div-fmlm">
          <div style={{ display: "flex" }}>
            <h3 style={{ fontWeight: 500 }}>
              {selectedPersona} Distance Profile
            </h3>
          </div>
          <div>
            <div className="upper-inputModal-fmlm">
              <h4 style={{ color: "blue" }}>First Mile Distances(in m)</h4>
              <div className="input-div-inputModal-fmlm">
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Minimum FM
                </label>
                <NumberFormat
                  format="#####"
                  type="text"
                  mask=" "
                  style={{ marginRight: "10px" }}
                  className="inputModal-number-format"
                  value={newMinFM}
                  onChange={newMinFMChangeHandler}
                />

                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Maximum FM
                </label>

                <NumberFormat
                  format="#####"
                  type="text"
                  mask=" "
                  style={{ marginRight: "10px" }}
                  className="inputModal-number-format"
                  value={newMaxFM}
                  onChange={newMaxFMChangeHandler}
                />
              </div>
            </div>
            <div className="lower-inputModal-fmlm">
              <h4 style={{ color: "#7305c3" }}>Last Mile Distances(in m)</h4>
              <div
                className="input-div-inputModal-fmlm"
                style={{ display: "flex" }}
              >
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Minimum LM
                </label>

                <NumberFormat
                  format="#####"
                  type="text"
                  mask=" "
                  style={{ marginRight: "10px" }}
                  className="inputModal-number-format"
                  value={newMinLM}
                  onChange={newMinLMChangeHandler}
                />
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Maximum LM
                </label>

                <NumberFormat
                  format="#####"
                  type="text"
                  mask=" "
                  className="inputModal-number-format"
                  value={newMaxLM}
                  onChange={newMaxLMChangeHandler}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleUpdateConfig}
                className="submit-inputModal-fmlm"
              >
                Update Configs
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isInputAmountErrorModal}
        onCancel={closeInputAmountError}
        onOk={closeInputAmountError}
        maskClosable={false}
        width={300}
      >
        Please enter valid values.
      </Modal>
      <Modal
        visible={isUpdateModalVisible}
        width={900}
        footer={null}
        onCancel={cancelHandler}
      >
        <h5 style={{ textAlign: "center", fontSize: "14px" }}>
          Editing Pan-India Allocation Config for {selectedCustomer}
        </h5>
        <div className="outer-div-fmlm">
          <h3 style={{ fontWeight: 600 }}>
            {selectedPersona} Distance Profile
          </h3>

          <div className="upper-inputModal-fmlm" style={{ height: "20vh" }}>
            <h4 style={{ color: "blue" }}>First Mile Distance (in m)</h4>
            <div>
              <div className="input-div-inputModal-fmlm">
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Old Min FM
                </label>
                <Input
                  type="text"
                  style={{
                    width: "30%",
                    marginRight: "20px",
                    marginLeft: "10px",
                  }}
                  disabled
                  value={oldMinFM}
                />
                <label
                  style={{
                    marginRight: "13px",
                    marginLeft: "10px",
                    paddingTop: "5px",
                    color: "#F27F36",
                  }}
                >
                  New Min FM
                </label>
                <Input style={{ width: "30%" }} disabled value={newMinFM} />
              </div>
              <div
                className="input-div-inputModal-fmlm"
                style={{ marginTop: "10px" }}
              >
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Old Max FM
                </label>
                <Input
                  type="text"
                  style={{
                    width: "30%",
                    marginRight: "20px",
                    marginLeft: "7px",
                  }}
                  disabled
                  value={oldMaxFM}
                />
                <label
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    paddingTop: "5px",
                    color: "#F27F36",
                  }}
                >
                  New Max FM
                </label>
                <Input style={{ width: "30%" }} disabled value={newMaxFM} />
              </div>
            </div>
          </div>
          <div className="lower-inputModal-fmlm" style={{ height: "20vh" }}>
            <h4 style={{ color: "#7305c3" }}>First Mile Distance (in m)</h4>
            <div>
              <div className="input-div-inputModal-fmlm">
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Old Min LM
                </label>
                <Input
                  type="text"
                  style={{
                    width: "30%",
                    marginRight: "20px",
                    marginLeft: "10px",
                  }}
                  disabled
                  value={oldMinLM}
                />
                <label
                  style={{
                    marginRight: "13px",
                    marginLeft: "10px",
                    paddingTop: "5px",
                    color: "#F27F36",
                  }}
                >
                  New Min LM
                </label>
                <Input style={{ width: "30%" }} disabled value={newMinLM} />
              </div>
              <div
                className="input-div-inputModal-fmlm"
                style={{ marginTop: "10px" }}
              >
                <label style={{ marginRight: "10px", paddingTop: "5px" }}>
                  Old Max LM
                </label>
                <Input
                  type="text"
                  style={{
                    width: "30%",
                    marginRight: "20px",
                    marginLeft: "7px",
                  }}
                  disabled
                  value={oldMaxLM}
                />
                <label
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    paddingTop: "5px",
                    color: "#F27F36",
                  }}
                >
                  New Max LM
                </label>
                <Input style={{ width: "30%" }} disabled value={newMaxLM} />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "7vw", marginTop: "10px", fontSize: "11px" }}>
          <h3>
            Are you sure you want to update these values for the allocation
            engine?
          </h3>
          <h3>
            Please double check as this will directly impact FE orders & their
            earnings.
          </h3>
        </div>
        <div className="lower-div-fmlm">
          <Button
            style={{
              marginRight: "30px",
              color: "red",
              border: "1px solid red",
            }}
            onClick={cancelHandler}
          >
            Cancel Changes
          </Button>
          <Button
            onClick={submitHandler}
            style={{ background: "#02875A", color: "white" }}
          >
            Confirm & Save Changes
          </Button>
        </div>
      </Modal>

      <Card className="panIndiaConfig">
        <Table
          bordered
          columns={columns}
          dataSource={customerList}
          rowKey={(record) => record._key}
        />
      </Card>
    </div>
  );
}

export default FMLMPanIndiaDashboard;
