import React from 'react';
import { Collapse } from 'antd';
import './Accordian.scss';

export const Panel = Collapse.Panel;
export const accordian = (props) => {
    return (
        <Collapse 
        accordion
        defaultActiveKey={props.defaultActiveKey}
        >
            {props.children}
        </Collapse>
    );
}