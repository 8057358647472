import React, { Component } from "react";
import "./Lsnalert.scss";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import * as actions from "./action";

var system_timer;
class Lsnalert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message : '',
    };
    // this.loadData = this.loadData.bind(this);
    // this.updateConfig = this.updateConfig.bind(this);
  }

  // loadData(){
    // this.props.getConfig().then(configInfo => {
    //     if(configInfo && configInfo.type !== "CONFIG_DATA_FAILURE"){
    //       system_timer = configInfo.interval * 1000;
    //       this.interval = setInterval(this.updateConfig, system_timer);
    //     } else {
    //       system_timer = 30000;
    //       this.interval = setInterval(this.updateConfig, system_timer);
    //     }
    // });
  // }

  // updateConfig(){
    // const partnerId = localStorage.getItem('loggedPartnerId');
    // this.props.getConfig().then(configdata => {
    //   if(configdata && configdata.type !== "CONFIG_DATA_FAILURE"){
    //     configdata.alerts[0].partnerIds.map(x =>{
    //       if(x == partnerId || x == 0){
    //         configdata.alerts[0].status ? this.setState({message : configdata.alerts[0].message}) : this.setState({message : ''});
    //       }
    //     });
    //     system_timer = configdata.interval * 1000;
    //   } else {
    //     this.setState({message : ''});
    //   }
    // });
  // }

    // componentDidMount() {
    //     this.loadData();
    // }

    // componentWillUnmount() {
    //   clearInterval(this.interval);
    // }

  render() {
    const { message } = this.state;
    return (
      <>
      {message &&
      <div className="errorLog">
            <div className="blinking">{message}</div>
      </div>
      }
      </>
    );
  }
}

const mapStateToProps = state => ({
  // configData : state.lsnalert.configData,
});
const mapDispatchToProps = dispatch => ({
  // getConfig: () => dispatch(actions.getConfig())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Lsnalert));
