import {MapLayer, Polyline} from 'react-leaflet';
import L from 'leaflet';
import "leaflet-polylinedecorator";

export default class Polylines extends MapLayer {
  createLeafletElement() {
    const { map, road } = this.props;
    let leafletElement = L.polyline(road, {color: '#0058d0', weight: 3}).addTo(map.leafletElement);
    return leafletElement;
  }
}

