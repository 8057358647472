import React, { PureComponent } from "react";
import { withCookies } from "react-cookie";
import { Button, Col, Layout, Row, Select } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsPane from "../../components/shared/TabsPane/TabsPane";
import Lsnalert from "../../components/shared/Lsnalert/Lsnalert";
import Sidebar from "../../components/shared/sidebar/Sidebar";
import Header from "../../components/shared/header/Header";
import RouteWithTitle from "../RouteWithTitle";
import "../app.scss";
import AuditNotice from "../../components/inventory/audit/audit-notice";
import SuperSelect from "../../components/ui/SuperSelect/SuperSelect";
import {
  getCityList,
  getCityListById
} from "../../components/hyperlocal/fleet/action";

const Option = Select.Option;

function withExtraProp(WrappedComponent, props = {}) {
  return class extends React.Component {
    render() {
      // Wraps the input component in a container, without mutating it. Good!
      return <WrappedComponent {...this.props} {...props} />;
    }
  };
}

class HyperlocalMainLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newProps: {
        locationIds: Number(localStorage.getItem("hyperlocalLocationIds"))
            ? [Number(localStorage.getItem("hyperlocalLocationIds"))]
            : [],
        cityId: Number(localStorage.getItem("hyperlocalCityId")) || undefined
      }
    };
  }

  onApply = newProps => {
    this.setState({
      newProps
    });
  };

  render() {
    const { path, component, name } = this.props;
    return (
      <>
        <Header {...this.props}></Header>
        <Layout>
          <Sidebar {...this.props} />
          <Layout className="padding-container">
            <AuditNotice />
            <Lsnalert></Lsnalert>
            <DowndropComponent {...this.props} onApply={this.onApply}/>
            <Layout className="tabs-container">
              <TabsPane {...this.props} />
            </Layout>
            <Layout>
              {path && component && (
                <RouteWithTitle
                  key={name}
                  exact={true}
                  path={path}
                  component={withExtraProp(component, {
                    ...this.state?.newProps
                  })}
                  name={name}
                />
              )}
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withCookies(HyperlocalMainLayout))
);

class DowndropComponentContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      locationIds: [],
      cityId: undefined,
      locationIdError: false,
      newProps: {
        locationIds: [],
        cityId: undefined
      }
    };
    this.cityId = undefined;
    this.locationIds = [];
    this.props.getCityList();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cityList !== this.props.cityList) {
      this.setState({
        cityList: this.props.cityList,
        cityId: Number(localStorage.getItem("hyperlocalCityId")) || undefined
      });
      if (Number(localStorage.getItem("hyperlocalCityId"))) {
        this.onLocationChange(Number(localStorage.getItem("hyperlocalCityId")));
      }
    }

    if (prevProps.cityListById !== this.props.cityListById) {
      const isNewCity =
        Number(this.state.cityId) ===
        Number(localStorage.getItem("hyperlocalCityId"));
      this.setState({
        cityListById: this.props.cityListById,
        locationIds:
          Number(localStorage.getItem("hyperlocalLocationIds")) && isNewCity
            ? [Number(localStorage.getItem("hyperlocalLocationIds"))]
            : [],
        newProps: {
          locationIds: Number(localStorage.getItem("hyperlocalLocationIds"))
            ? [Number(localStorage.getItem("hyperlocalLocationIds"))]
            : [],
          cityId: Number(localStorage.getItem("hyperlocalCityId")) || undefined
        }
      });
    }
  }
  onLocationChange = e => {
    if (e) {
      this.props.getCityListById(e);
      this.setState({ locationIds: [], cityId: e });
    } else {
      this.setState({ locationIds: [] });
    }
  };

  onpartnerChange = e => {
    if (e) {
      this.setState({ locationIds: [e] });
    } else {
      this.setState({ locationIds: [] });
    }
  };

  setLocationEndData = data => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push(
          <Option key={x.id} value={x.id} label={x.name}>
            {x.name}
          </Option>
        );
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  };

  setLocationpartnerData = data => {
    const endLocationsData = [];
    if (data && data.length > 0) {
      data.forEach(x => {
        endLocationsData.push(
          <Option key={x.id} value={x.id} label={x.alias}>
            {x.alias}
          </Option>
        );
      });
      return endLocationsData;
    } else {
      return endLocationsData;
    }
  };
  handleOk = e => {
    this.setState(
      state => ({
        allowRendering: true,
        newProps: {
          locationIds: state.locationIds,
          cityId: state.cityId
        }
      }),
      () => {
        this.props.onApply(this.state.newProps);
        localStorage.setItem("hyperlocalCityId", this.state.cityId);
        localStorage.setItem("hyperlocalLocationIds", [this.state.locationIds]);
      }
    );
  };
  render() {
    return (
      <Row gutter={{ xs: 8, sm: 16, md: 18, lg: 18 }} style={{ marginTop: 20 }}>
        <Col className="gutter-row" span={5}>
          <SuperSelect
            showSearch
            allowClear
            placeholder={"City Name"}
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={this.state.cityId}
            onChange={e => this.onLocationChange(e)}
            // onSearch={this.onSearchsef}
            style={{ width: 200 }}
          >
            {this.setLocationEndData(this.props.cityList)}
          </SuperSelect>
        </Col>
        <Col className="gutter-row" span={5}>
          <SuperSelect
            showSearch
            allowClear
            value={this.state.locationIds}
            defaultValue={this.state.locationIds}
            className={
              this.state?.locationIdError
                ? this.state.locationIds.length > 0
                  ? ""
                  : "has-error"
                : ""
            }
            placeholder="Location (Partner Name)"
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={e => this.onpartnerChange(e)}
            style={{ width: 200 }}
          >
            {this.setLocationpartnerData(this.state?.cityListById)}
          </SuperSelect>
        </Col>
        <Col className="gutter-row" span={4}>
          <Button
            type="primary"
            disabled={this.state.locationIds.length > 0 ? false : true}
            onClick={this.handleOk}
            loading={this.props.fleetStatus?.loading}
          >
            Apply
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProp = state => ({
  cityList: state.FleetReducer?.cityList?.citiesDetails,
  cityListById: state.FleetReducer?.locations,
  fleetStatus: state.FleetReducer,
});

const mapDispatchToProp = dispatch => ({
  getCityList: () => dispatch(getCityList()),
  getCityListById: cityId => dispatch(getCityListById(cityId))
});

 const DowndropComponent = withRouter(
  connect(
    mapStateToProp,
    mapDispatchToProp
  )(withCookies(DowndropComponentContainer)))
