import { Component } from "react";
import io from "socket.io-client";
import {liveFeedToken} from "../../environments/node-react-environment"
class VehicleSocket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
    };
  }
  componentDidMount() {
    const options = {
      forceNew: true,
      autoConnect: true,
      pingInterval: 6000,
      pingTimeout: 10000,
      query: "token=" + liveFeedToken + "&deviceId=" + "profile_organization_J46SFFAE",
      };
      const socket = io.connect("https://www.beecon.in" + "/webSocket", options);
      socket.on('connect', function (data){
          console.log("ON WEBSOCKET");
      });
      socket.on('error', function (data){
      console.log(data);
      });
      socket.on("vehicleTelematicEvent", function (data) {
          //console.log("CONNECTED");
          if(data){
            console.log(data);
              /*this.setState({
                vehicles: data,
              });*/
              /*const vehicle_data = vehicleTrackingData[data.id]
              if(!isEmpty(vehicle_data)){
                  //console.log("Updated vehicle "+vehicle_data.id+" from location "+vehicle_data.telematicData+" to location"+data.telematicData )
                  vehicle_data.telematicData = data.telematicData
              }*/
          }
      });
  }

  render() {
    return this.props.children(this.state.vehicles);
  }
}
export default VehicleSocket;
