
import ZoneService from '../../../services/billing/zone.service';
import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import MiscService from '../../../services/misc.service';

const zoneService = new ZoneService();

export const fetchZones = (payload, isReset) => dispatch => {
    dispatch(fetchZonesBegin());
    zoneService.fetchZones(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchZonesSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchZonesFailure(res ?.data ?.response));
        }
    });
};

function fetchZonesBegin() {
    return {
        type: _Constants.FETCH_ZONES_BEGIN
    };
}

function fetchZonesSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_ZONES_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchZonesFailure(error) {
    return {
        type: _Constants.FETCH_ZONES_FAILURE,
        payload: error
    };
}

export const fetchAreas = () => dispatch => {
    dispatch(fetchAreasBegin());
    MiscService.getAreas().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchAreasSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchAreasFailure(res ?.data ?.response));
        }
    });
};

function fetchAreasBegin() {
    return {
        type: _Constants.FETCH_AREAS_BEGIN
    };
}

function fetchAreasSuccess(payload) {
    return {
        type: _Constants.FETCH_AREAS_SUCCESS,
        payload: payload
    };
}

function fetchAreasFailure(error) {
    return {
        type: _Constants.FETCH_AREAS_FAILURE,
        payload: error
    };
}