// get tracking waybill info
export const GET_DETAILED_WAYBILL_INFO = 'GET_DETAILED_WAYBILL_INFO';

// update consignment pod
export const UPDATE_CONSIGNMENT_POD_BEGIN = 'UPDATE_CONSIGNMENT_POD_BEGIN';
export const UPDATE_CONSIGNMENT_POD_SUCCESS = 'UPDATE_CONSIGNMENT_POD_SUCCESS';
export const UPDATE_CONSIGNMENT_POD_FAILURE = 'UPDATE_CONSIGNMENT_POD_FAILURE';

// reset notification data
export const RESET_NOTIFICATION_DATA = 'RESET_NOTIFICATION_DATA';

// get contact number
export const GET_CUSTOMER_CONTACT_NUMBER_BEGIN = 'GET_CUSTOMER_CONTACT_NUMBER_BEGIN';
export const GET_CUSTOMER_CONTACT_NUMBER_SUCCESS = 'GET_CUSTOMER_CONTACT_NUMBER_SUCCESS';
export const GET_CUSTOMER_CONTACT_NUMBER_FAILURE = 'GET_CUSTOMER_CONTACT_NUMBER_FAILURE';
export const GET_CUSTOMER_CONTACT_NUMBER_RESET = 'GET_CUSTOMER_CONTACT_NUMBER_RESET';
