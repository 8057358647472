import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './zone-mapping.scss';
import { Card, Button, Input, Row, Col, Icon, Modal, Form, PageHeader, Divider, Select, Checkbox, Radio, Spin, Switch, Tree } from 'antd';
import { canAllow, notifyApiError } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import { fetchAreas } from './actions';

const { TreeNode } = Tree;

class ZoneMapping extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pageLoading: true,
            pendingAreas: [],
            selectedAreas: [],
            pendingLoading: false,
            pendingSearch: '',
            selectedSearch: ''
        }


    }

    componentDidMount() {
        this.props.fetchAreas();
    }

    componentDidUpdate(prevProps) {
        if (this.props.areaList !== prevProps.areaList) {
            this.setState({
                pageLoading: true
            }, () => {
                this.prepareSelectedTreeStructure(this.props.areaList)
            })
        }

        if (this.props.selectedPincodes.length !== prevProps.selectedPincodes.length) {
            this.setState({
                pageLoading: true
            }, () => {
                this.prepareSelectedTreeStructure(this.props.areaList)
            })
        }
    }

    isSelectedStateExisted() {
        const { selectedState, pendingAreas, selectedAreas } = this.state;
        return (pendingAreas.find((state => state.stateName === selectedState)) || selectedAreas.find((state => state.stateName === selectedState)));
    }

    prepareSelectedTreeStructure = (areas) => {
        if (!areas) return
        const { selectedPincodes } = this.props;
        const newSelectedList = [], newPendingList = []
        let cities = [], pins = [], citiesP = [], pinsP = []
        areas.map(state => {
            cities = []; citiesP = []
            state.cities.map(city => {
                pins = []; pinsP = []
                city.pincodes.map(code => {
                    if (selectedPincodes.indexOf(code.id) !== -1) {
                        pins.push({ ...code })
                    }
                })
                if (pins.length > 0)
                    cities.push({ ...city, pincodes: pins })
            })
            if (cities.length > 0)
                newSelectedList.push({ ...state, cities: [...cities].filter(city => city.pincodes.length > 0) })
        })

        newSelectedList.map(state => {
            let newState = areas.find(s => s.stateName === state.stateName)

            newState.cities = newState.cities.map(city => {
                const pincodes = city.pincodes.filter(code => selectedPincodes.indexOf(code.id) === -1)

                return { ...city, pincodes }
            }).filter(city => city.pincodes.length > 0)


            newPendingList.push({ ...newState });
        })
        this.setState({
            pendingAreas: newPendingList.filter(state => state.cities.length > 0),
            selectedAreas: newSelectedList.filter(state => state.cities.length > 0),
            pageLoading: false
        }, () => {
            this.props.onSelectedAreaAdd(this.state.selectedAreas);
        })
    }

    prepareTreeSturture = () => {
        const { selectedState, pendingAreas, selectedAreas } = this.state;
        const { areaList } = this.props;

        if (!selectedState) {
            notifyApiError('State can\'t be empty!', 'Error')
            return;
        }

        if (this.isSelectedStateExisted()) {
            notifyApiError('State Already Added!', 'Error')
            return;
        }

        const newState = areaList.filter(state => state.stateName === selectedState);

        this.setState({
            pendingAreas: [...pendingAreas, ...newState]
        })
    }

    onNodeSelected = (selectedKeys, { selectedNodes, node }) => {
        this.setState({
            selectedKeys,
            selectedNodes,
            node
        })
    }

    handleNodeSelection(data, type, event) {
        this.setState({
            pendingLoading: true
        }, () => {
            switch (type) {
                case 'STATE':
                    this.handerState(data, event);
                    break;
                case 'CITY':
                    this.handlerCity(data, event);
                    break;
                case 'PINCODE':
                    this.handlerPincode(data, event);
                    break;
                default:
                    this.setState({
                        pendingLoading: false
                    });
                    break;
            }
        });
    }

    handerState = (name, event) => {
        if (!this.props.disabled) {
            const { pendingAreas, selectedAreas } = this.state;
            const addAreasSet = event === 'ADD' ? [...pendingAreas] : [...selectedAreas];
            const removeAreasSet = event !== 'REMOVE' ? [...selectedAreas] : [...pendingAreas];

            const selectedState = addAreasSet.find(state => state.stateName === name)
            let newAreas = [];
            newAreas = removeAreasSet.map(state => {
                if (state.stateName === name) {
                    return { ...state, cities: [...state.cities, ...selectedState.cities] }
                }
                return state
            })
            if (!newAreas.find(state => state.stateName === name)) {
                newAreas.push(selectedState)
            }

            if (event === 'ADD') {
                this.setState({
                    selectedAreas: [...newAreas],
                    pendingAreas: [...pendingAreas.filter(state => state.stateName !== name)],
                    pendingLoading: false
                }, () => {
                    this.props.onSelectedAreaAdd(this.state.selectedAreas);
                })
            } else {
                this.setState({
                    pendingAreas: [...newAreas],
                    selectedAreas: [...selectedAreas.filter(state => state.stateName !== name)],
                    pendingLoading: false
                }, () => {
                    this.props.onSelectedAreaAdd(this.state.selectedAreas);
                })
            }
        } else {
            this.setState({ pendingLoading: false })
        }
    }

    handlerCity = (newState, newCity, event) => {
        
        if (!this.props.disabled) {


            let { pendingAreas, selectedAreas } = this.state;
            if (event === 'ADD') {
                if (selectedAreas.find(state => state.stateName === newState.stateName)) {
                    selectedAreas = selectedAreas.map(state => {
                        if (state.stateName === newState.stateName) {
                            if (state.cities.find(city => city.id === newCity.id)) {
                                let cityNew = state.cities.map(city => {
                                    if (city.id === newCity.id) {
                                        city.pincodes = [...city.pincodes, ...newCity.pincodes]
                                    }
                                    return city
                                })
                                state.cities = [...cityNew]
                            } else {
                                state.cities.push({ ...newCity })
                            }
                        }

                        return state;
                    }).filter(state => state.cities.length > 0)
                } else {
                    selectedAreas.push({ ...newState, cities: [{ ...newCity }] })
                }

                pendingAreas = pendingAreas.map(state => {
                    if (state.stateName === newState.stateName) {
                        let cityNew = state.cities.map(city => {
                            if (city.id === newCity.id) {
                                return { ...city, pincodes: [] }
                            }

                            return city;
                        })

                        return { ...state, cities: cityNew.filter(city => city.pincodes.length > 0) }
                    }

                    return state;
                }).filter(state => state.cities.length > 0)

            } else {
                if (pendingAreas.find(state => state.stateName === newState.stateName)) {
                    pendingAreas = pendingAreas.map(state => {
                        if (state.stateName === newState.stateName) {
                            if (state.cities.find(city => city.id === newCity.id)) {
                                let cityNew = state.cities.map(city => {
                                    if (city.id === newCity.id) {
                                        city.pincodes = [...city.pincodes, ...newCity.pincodes]
                                    }
                                    return city
                                })
                                state.cities = [...cityNew]
                            } else {
                                state.cities.push({ ...newCity })
                            }
                        }

                        return state;
                    }).filter(state => state.cities.length > 0)
                } else {
                    pendingAreas.push({ ...newState, cities: [{ ...newCity }] })
                }

                selectedAreas = selectedAreas.map(state => {
                    if (state.stateName === newState.stateName) {
                        let cityNew = state.cities.map(city => {
                            if (city.id === newCity.id) {
                                return { ...city, pincodes: [] }
                            }

                            return city;
                        })

                        return { ...state, cities: cityNew.filter(city => city.pincodes.length > 0) }
                    }

                    return state;
                }).filter(state => state.cities.length > 0)

            }
            this.setState({
                pendingAreas,
                selectedAreas,
                pendingLoading: false
            }, () => {
                this.props.onSelectedAreaAdd(this.state.selectedAreas);
            })
        }
    }

    handlerPincode = (newState, newCity, newPincode, event) => {
        if (!this.props.disabled) {


            let { pendingAreas, selectedAreas } = this.state;

            if (event === 'ADD') {
                if (selectedAreas.find(state => state.stateName === newState.stateName)) {
                    selectedAreas = selectedAreas.map(state => {
                        if (state.stateName === newState.stateName) {
                            if (state.cities.find(city => city.id === newCity.id)) {
                                let cityNew = state.cities.map(city => {
                                    if (city.id === newCity.id) {
                                        city.pincodes.push(newPincode);
                                    }
                                    return city
                                })
                            } else {
                                state.cities.push({ ...newCity, pincodes: [newPincode] })
                            }
                        }

                        return state;
                    }).filter(state => state.cities.length > 0)
                } else {
                    selectedAreas.push({ ...newState, cities: [{ ...newCity, pincodes: [newPincode] }] })
                }

                pendingAreas = pendingAreas.map(state => {
                    if (state.stateName === newState.stateName) {
                        let cityNew = state.cities.map(city => {
                            if (city.id === newCity.id) {
                                return { ...city, pincodes: city.pincodes.filter(pin => pin.id !== newPincode.id) }
                            }

                            return city;
                        })

                        return { ...state, cities: cityNew.filter(city => city.pincodes.length > 0) }
                    }

                    return state;
                })
            } else {
                if (pendingAreas.find(state => state.stateName === newState.stateName)) {
                    pendingAreas = pendingAreas.map(state => {
                        if (state.stateName === newState.stateName) {
                            if (state.cities.find(city => city.id === newCity.id)) {
                                let cityNew = state.cities.map(city => {
                                    if (city.id === newCity.id) {
                                        city.pincodes.push(newPincode);
                                    }
                                    return city
                                })
                            } else {
                                state.cities.push({ ...newCity, pincodes: [newPincode] })
                            }
                        }

                        return state;
                    }).filter(state => state.cities.length > 0)
                } else {
                    pendingAreas.push({ ...newState, cities: [{ ...newCity, pincodes: [newPincode] }] })
                }

                selectedAreas = selectedAreas.map(state => {
                    if (state.stateName === newState.stateName) {
                        let cityNew = state.cities.map(city => {
                            if (city.id === newCity.id) {
                                return { ...city, pincodes: city.pincodes.filter(pin => pin.id !== newPincode.id) }
                            }

                            return city;
                        })

                        return { ...state, cities: cityNew.filter(city => city.pincodes.length > 0) }
                    }

                    return state;
                }).filter(state => state.cities.length > 0)
            }
            this.setState({
                pendingAreas,
                selectedAreas,
                pendingLoading: false
            }, () => {
                this.props.onSelectedAreaAdd(this.state.selectedAreas);
            })
        }
    }

    areaSorting(areaType, a, b) {
        switch (areaType) {
            case "STATE":
                if (a.stateName.toLowerCase() < b.stateName.toLowerCase())
                    return -1
                if (a.stateName.toLowerCase() > b.stateName.toLowerCase())
                    return 1
                return 0;
            case "CITY":
                if (a.cityName.toLowerCase() < b.cityName.toLowerCase())
                    return -1
                if (a.cityName.toLowerCase() > b.cityName.toLowerCase())
                    return 1
                return 0;
            case "PINCODE":
                if (a.zipcode < b.zipcode)
                    return -1
                if (a.zipcode > b.zipcode)
                    return 1
                return 0;
        };
    }

    getPendingTreeNode(state) {
        return (<TreeNode
            style={{ lineHeight: state.stateName.length > 16 ? 3 : 1.5, whiteSpace: state.stateName.length > 16 ? 'normal' : 'nowrap' }}
            title={(
                <Row>
                    <Col span={21}>{state.stateName}</Col>
                    <Col span={1} onClick={() => this.handleNodeSelection(state.stateName, 'STATE', 'ADD')} >
                        <Icon type="plus" />
                    </Col>
                </Row>
            )}
            key={state.stateName}>
            {state.cities.sort((a, b) => this.areaSorting('CITY', a, b)).map(city =>
                <TreeNode
                    style={{ lineHeight: city.cityName.length > 16 ? 3 : 1.5, whiteSpace: city.cityName.length > 16 ? 'normal' : 'nowrap' }}
                    title={(
                        <Row>
                            <Col span={22}>{city.cityName}</Col>
                            <Col span={2} onClick={() => this.handlerCity(state, city, 'ADD')} >
                                <Icon type="plus" />
                            </Col>
                        </Row>)}
                    key={city.id}>
                    {city.pincodes.sort((a, b) => this.areaSorting('PINCODE', a, b)).map(code =>
                        <TreeNode
                            style={{ lineHeight: code.zipcode.length > 16 ? 3 : 1.5, whiteSpace: code.zipcode.length > 16 ? 'normal' : 'nowrap' }}
                            title={<Row>
                                <Col span={22}>{code.zipcode}</Col>
                                <Col span={2} onClick={() => this.handlerPincode(state, city, code, 'ADD')} >
                                    <Icon type="plus" />
                                </Col>
                            </Row>}
                            key={code.id}>
                        </TreeNode>)}
                </TreeNode>)}
        </TreeNode>)
    }

    getSelectedTreeNode(state) {
        
        return (<TreeNode
            disabled={this.props.disabled}
            title={(
                <Row>
                    <Col span={22}>{state.stateName}</Col>
                    <Col span={2} onClick={() => this.handleNodeSelection(state.stateName, 'STATE', 'REMOVE')} >
                        <Icon type="delete" />
                    </Col>
                </Row>
            )}
            key={state.stateName}>
            {state.cities.sort((a, b) => this.areaSorting('CITY', a, b)).map(city =>
                <TreeNode
                    disabled={this.props.disabled}
                    title={(
                        <Row>
                            <Col span={22}>{city.cityName}</Col>
                            <Col span={2} onClick={() => this.handlerCity(state, city, 'REMOVE')} >
                                <Icon type="delete" />
                            </Col>
                        </Row>)}
                    key={city.id}>
                    {city.pincodes.sort((a, b) => this.areaSorting('PINCODE', a, b)).map(code =>
                        <TreeNode
                            disabled={this.props.disabled}
                            title={<Row>
                                <Col span={22}>{code.zipcode}</Col>
                                <Col span={2} onClick={() => this.handlerPincode(state, city, code, 'REMOVE')} >
                                    <Icon type="delete" />
                                </Col>
                            </Row>}
                            key={code.id}>
                        </TreeNode>)}
                </TreeNode>)}
        </TreeNode>)
    }

    onSearch = (val, type) => {
        this.setState({
            [type]: val
        })
    }

    filterSearchedValue = (Areas, search) => {
        const tempArr = []

        Areas.map(state => {
            if (!search) return tempArr.push(state);
            const cities = []
            state.cities.map(city => {
                let pincodes = []
                if (!isNaN(search)) {
                    pincodes = city.pincodes.filter(pin => pin.zipcode.includes(Number(search)));

                    if (pincodes.length > 0) cities.push({ ...city, pincodes })
                }
                if (city.cityName.toLowerCase().includes(search.toLowerCase()))
                    cities.push(city)
            }
            )

            if (cities.length > 0)
                tempArr.push({ ...state, cities })
            else {
                if (state.stateName.toLowerCase().includes(search.toLowerCase())) {
                    tempArr.push(state)
                }
            }
        })

        return tempArr;
    }

    render() {
        const { pendingAreas, selectedAreas, pendingLoading, pendingSearch, selectedSearch, pageLoading } = this.state;
        const { form, isZoneLoading } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const filteredPendingAreas = this.filterSearchedValue([...pendingAreas], pendingSearch)
        const filteredSelectedAreas = this.filterSearchedValue([...selectedAreas], selectedSearch)
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            labelAlign: "left",
            colon: false
        };
        return (
            <>
                <Card bordered={false} className="zone-mapping">
                    <Spin spinning={pageLoading}>
                        <Row>
                            <Form.Item {...formItemLayout} label="States">
                                <div style={{ display: 'inline-block', width: 'calc(80% - 12px)' }}>
                                    {getFieldDecorator('stateId', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select State!',
                                            }
                                        ],
                                    })(
                                        <Select
                                            style={{ width: "65%" }}
                                            placeholder="Select State"
                                            disabled={this.props.disabled}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            showArrow
                                            onChange={(val) => this.setState({ selectedState: val })}>
                                            {this.props?.areaList?.map(state => (
                                                <Select.Option
                                                    key={state.stateName}
                                                    value={state.stateName}
                                                    label={state.stateName}
                                                >
                                                    {state.stateName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                                <span style={{ display: 'inline-block', width: '24px' }}></span>
                                <div style={{ display: 'inline-block', width: 'calc(20% - 12px)' }}>
                                    <Button disabled={this.props.disabled} type="primary" onClick={this.prepareTreeSturture} >Add</Button>
                                </div>
                            </Form.Item>
                            <Form.Item wrapperCol={{span: 24}} className="flex-box">
                                <div className="flex-box flex-gap-xl">
                                    {
                                        !this.props.readOnly && 
                                        <div className="flex-1">
                                            <Card ghost="true" title="Available Pincodes" style={this.props.style} bodyStyle={{ height: '300px', overflow: 'scroll' }}>
                                                <Spin spinning={pendingLoading}>
                                                    {pendingAreas.length > 0 ? <Input.Search style={{ marginBottom: 8 }} allowClear placeholder="Search" onChange={(e) => this.onSearch(e.target.value, 'pendingSearch')} /> : null}
                                                    <Tree
                                                        disabled={this.props.disabled}
                                                        switcherIcon={<Icon type="down" />}
                                                        onSelect={this.onNodeSelected}
                                                    >
                                                        {
                                                            filteredPendingAreas && filteredPendingAreas?.sort((a, b) => this.areaSorting('STATE', a, b))?.map(state =>
                                                                this.getPendingTreeNode(state))
                                                        }
                                                    </Tree>
                                                </Spin>
                                            </Card>
                                        </div>
                                    }
                                <div className="flex-1">
                                    <Card ghost="true" title="Selected Pincodes" style={this.props.style} bodyStyle={{ height: '300px', overflow: 'scroll' }}>
                                        <Spin spinning={pendingLoading}>
                                            {selectedAreas.length ? <Input.Search style={{ marginBottom: 8 }} allowClear placeholder="Search State, City or Pincode" onChange={(e) => this.onSearch(e.target.value.trim(), 'selectedSearch')} /> : null}
                                            <Tree
                                                disabled={this.props.disabled}
                                                switcherIcon={<Icon type="down" />}
                                                onSelect={this.onSelect}
                                            >
                                                {
                                                    filteredSelectedAreas && filteredSelectedAreas.length > 0 ? filteredSelectedAreas.sort((a, b) => this.areaSorting('STATE', a, b))?.map(state => (
                                                        this.getSelectedTreeNode(state)
                                                    )) :
                                                    <TreeNode title={
                                                        <div className={"grey-text"}>
                                                            No State, City or Pincode found.
                                                        </div>
                                                    }/>
                                                }
                                            </Tree>
                                        </Spin>
                                    </Card>
                                </div>
                                </div>
                            </Form.Item>
                        </Row>
                    </Spin>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    areaList: state.zoneMapper?.areas?.stateAreas,
});
const mapDispatchToProps = dispatch => ({
    fetchAreas: () => dispatch(fetchAreas())
});
const formWarppedComponent = Form.create({ name: 'Zone', onValuesChange: (props, val) => { } })(ZoneMapping)
export default connect(mapStateToProps, mapDispatchToProps)(formWarppedComponent);