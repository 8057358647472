export const initialState = {
    loading: false
}

export const FETCH_ZONES_BEGIN = "FETCH_ZONES_BEGIN";
export const FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";
export const FETCH_ZONES_FAILURE = "FETCH_ZONES_FAILURE";

export const FETCH_ROUTES_BEGIN = "FETCH_ROUTES_BEGIN";
export const FETCH_ROUTES_SUCCESS = "FETCH_ROUTES_SUCCESS";
export const FETCH_ROUTES_FAILURE = "FETCH_ROUTES_FAILURE";

export const FETCH_ZONE_BEGIN = "FETCH_ZONE_BEGIN";
export const FETCH_ZONE_SUCCESS = "FETCH_ZONE_SUCCESS";
export const FETCH_ZONE_FAILURE = "FETCH_ZONE_FAILURE";

export const FETCH_AREAS_BEGIN = "FETCH_AREAS_BEGIN";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_FAILURE = "FETCH_AREAS_FAILURE";

export const MODIFY_ZONE_BEGIN = "MODIFY_ZONE_BEGIN";
export const MODIFY_ZONE_SUCCESS = "MODIFY_ZONE_SUCCESS";
export const MODIFY_ZONE_FAILURE = "MODIFY_ZONE_FAILURE";

export const RESET_ZONE_SUCCESS = "RESET_ZONE_SUCCESS";