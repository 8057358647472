import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../settingAction";
import { isPermitted } from "../../../common/utils/utils";

// Third party plugins
import { notifyApiError, notifyApiSuccess } from "../../../common/utils/utils";
import { Card, Upload, Icon, Divider, Table } from "antd";

// CSS
import cx from "classnames";
import "./PartnersOnboarding.scss";
import {
  Constants,
  UPLOAD_BUTTON_TYPE
} from "./../../../common/utils/constants";
import FileUpload from "./../../ui/file-upload/file.upload";

const { Dragger } = Upload;

class PartnersOnboarding extends Component {
  sampleDownloadLink = `${
    Constants.s3BucketLink
    }/documents/templates/xlsx/AtlasParnterOnBoardingCommon-sample+(1).xlsx`;
  state = {
    clearFile: false,
    acceptTypes: ["xlsx", ".xlsx", ".xls"],
    columns: [
      {
        title: "Partners Name",
        dataIndex: "partnerCompanyName",
        key: "partnerCompanyName"
      },
      {
        title: "Message",
        dataIndex: "processingMessage",
        key: "processingMessage"
      }
    ]
  };

  componentDidUpdate(oldProps) {
    if (
      this.props.partnersDetails &&
      oldProps.partnersDetails !== this.props.partnersDetails
    ) {
      this.setState({
        clearFile: true
      })
      notifyApiSuccess("Partners Created Successfully.", "SUCCESS:");
    }
  }

  componentDidMount() {
  }

  onUploadFinish(e) {
    let bodyFormData = new FormData();
    bodyFormData.append("file", e);
    this.props.partnersFileUpload(bodyFormData);
  }

  render() {
    return (
      <>
        <Card title="Partner Onboarding" className=" load-share-custom">
          <div className="download-sample-data">
            <h5>For accurate results</h5>
            <p>
              Download sample upload format
              <a href={this.sampleDownloadLink} target="_blank">
                {" "}
                here
              </a>
            </p>
          </div>
          <FileUpload
            fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
            directUpload={false}
            disableUploadButton={false}
            onUploadFinish={$events => this.onUploadFinish($events)}
            path="partners"
            acceptTypes={this.state.acceptTypes}
            objKey="partners"
            multiple={false}
            clearFileAfterUpload={this.state.clearFile}
          />
          <br />
          <br />
          {this.props ?.partnersDetails ?.response ?.partnerOnboardingResponse
            .length > 0 ? (
              <Table
                dataSource={
                  this.props.partnersDetails.response.partnerOnboardingResponse
                }
                columns={this.state.columns}
              />
            ) : (
              ""
            )}
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  partnersDetails: state.settingsReducer.partnersDetails
});

const mapDispatchToProps = dispatch => ({
  partnersFileUpload: params => dispatch(actions.partnersFileUpload(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersOnboarding);
