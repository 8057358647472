import React, { PureComponent } from 'react';
import * as actions from './actions';
import './dashboard.scss';
import { connect } from 'react-redux';
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, PageHeader, Tooltip, Spin } from 'antd';
import { table as Table } from "../../ui/table/Table";
import { canAllow, windowRedirect } from '../../../common/utils/utils';
import { permissionTags } from '../../../configs/permissionTags';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { openNotification } from '../../ui/notification/Notification';
import _ from 'lodash';
import { Mixpanel } from '../../../utils/mixpanel';
import { truncateSync } from 'fs';


const { confirm } = Modal;

class CustomerDashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.copyArea = React.createRef();
    this.state = {
      pageNo: 1,
      text: 'Public URL',
      customerPermission: {
        read: canAllow(permissionTags.settings.customer.read),
        update: canAllow(permissionTags.settings.customer.update),
        create: canAllow(permissionTags.settings.customer.create),
        delete: canAllow(permissionTags.settings.customer.delete),
        view: canAllow(permissionTags.settings.customer.view),
        invite: canAllow(permissionTags.settings.customer.invite),
        shippers: canAllow(permissionTags.settings.customer.shippers),
        contract_view: canAllow(permissionTags.settings.customer.contract_view),
      },
      filterPayload: {
        filters: {
          pageNo: 1,
          pageSize: 10,
          totalCount: 0,
          customerTypes: ['CUSTOMER'],
          status: ["ACTIVE"],
          fetchMode: 'BASIC'
        }
      }
    }
    this.InputSearchRef = React.createRef();
    if (!this.state.customerPermission.read) {
      windowRedirect('settings/dashboard/');
    }
  }

  getAccessKeys = (data) => {
    Mixpanel.track("Customer Public Tracking Clicked.", { 'ERPCustomerPublicURL': moment().valueOf() });
    console.log("onClick->", data)
    this.props.resetAccessKeys();
    this.setState({ text: '' })
    this.props.getAccessKeys(data.id)
  }

  notifyCopyEvent = () => {
    openNotification({
      message: 'Copied',
      description: 'Tracking number has been copied to clipboard',
      className: 'warning',
    })
  }

  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.copyArea.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copySuccess: 'Copied!' }, () => {
      setTimeout(() => {
        this.setState({ copySuccess: '' });
      }, 1000)
    });
  };

  showMessage = () => {
    // console.log("Show somethi")

  }

  componentDidMount() {
    const user = JSON.parse(atob(localStorage.getItem('user')));
    const loggedPartnerId = localStorage.getItem('loggedPartnerId');
    Mixpanel.identify(loggedPartnerId);
    Mixpanel.people.set({
      $first_name: user?.name,
      $email: user?.email,
      $phone_number: user?.number,
      $partner_id: user?.partner?.id,
      $partner_name: user?.partner?.name,
    });
    Mixpanel.track("Customer Page Initiated", { 'ERPCustomerIntiatted': moment().valueOf() })
    
    this.setState(state => ({
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Code',
          dataIndex: 'code',
          key: 'code',
        },
        {
          title: 'Contact Info',
          dataIndex: 'contactInfo',
          key: 'contactInfo',
          render: (data, row) => (<div>{row.phoneNumber}<br />{row.emailId}</div>)
        },
        {
          title: 'TIN/GST',
          dataIndex: 'tin',
          key: 'gst',
          render: (data, row) => (<div>{row.tin} <br /> {row.gstin} </div>)
        },
        {
          title: 'Created On',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (data, row) => <div>{moment(data).format('DD/MM/YYYY h:mm a')} <br /> <small>{moment(row.updatedAt).fromNow()}</small></div>
        },
        {
          title: 'Action',
          dataIndex: '',
          key: 'action',
          render: (data, row) => (<Row>
            {
              state.customerPermission.update && <Col span={8}><Tooltip placement="top" title={'Edit'}>
                <Icon type="edit" onClick={() => { Mixpanel.track("Customer Edit Clicked.", { 'ERPCustomerEditClick': moment().valueOf() }); this.props.history.push(`/appv2/settings/form/customer/${row.id}`) }} /></Tooltip>
              </Col>
            }
            {
              (!state.customerPermission.update && state.customerPermission.view) && <Col span={8}><Tooltip placement="top" title={'View'}>
                <Icon type="eye" onClick={() => this.props.history.push(`/appv2/settings/form/customer/${row.id}`)} /></Tooltip>
              </Col>
            }
            {
              state.customerPermission.delete && <Col span={12}><Tooltip placement="top" title={'Delete'}><Icon type="delete" onClick={() => this.showConfirm(row.id)} /></Tooltip></Col>
            }
            {/* {
              <Col span={8}>
                <Tooltip title="Public URL">
                  <Tooltip trigger="click" onMouseEnter={this.showMessage} onClick={() => this.getAccessKeys(row)} placement="leftTop" title={this.state.text}>
                    <Icon style={{ fontSize: '20px' }} type="info-circle" />
                  </Tooltip>
                </Tooltip>
              </Col>
            } */}
            {
              state.customerPermission.shippers && <Col span={8}><Tooltip placement="top" title={'Shippers'}>
                <Icon type="team" onClick={() => { Mixpanel.track("Customer Shippers Initialized.", { 'ERPCustomerEditClick': moment().valueOf(), 'customerId': row.id }); this.props.history.push(`/appv2/settings/dashboard/customer/shippers`, {id: row.id, name: row?.name}) }} /></Tooltip>
              </Col>
            }
            {/* {
              Need to add permission for contract
            } */}
            {
              state.customerPermission.contract_view && 
              <Col span={8}><Tooltip placement="top" title={'Contracts'}>
                <Icon type="book" onClick={() => { 
                  Mixpanel.track("Clicking Contracts Icon.", { 'ERPCustomerContractsClick': moment().valueOf()})
                  this.props.history.push(`/appv2/settings/dashboard/customer/contract`, {customerId: row.id, name: row?.name}) 
                }} /></Tooltip>
              </Col>
            }
          </Row >)
        },
      ]
    }));
    // if (!this.props?.customerData?.customers)
      // this.getPaginatedData(this.state.pageNo)
      this.applyPagination({ pageNo: this.state.pageNo }, true)
  }

  componentDidUpdate(prevProps) {
    const { PaginatedCustomers, customerData } = this.props;
    if (PaginatedCustomers !== prevProps.PaginatedCustomers) {
      this.setState({
        customerList: PaginatedCustomers[customerData.currentPageNo]
      })
    }

    // console.log("this.props->>", this.props.accessKeys, this.props.accessKeys && !this.state.text)

    if (this.props.accessKeys && !this.state.text) {
      let partnerId = this.props?.accessKeys?.accessKeys[0]['accessKey'];
      let clientId = _.hasIn(this.props?.accessKeys?.accessKeys[1], 'accessKey') ? this.props?.accessKeys?.accessKeys[1]['accessKey'] : undefined;
      // <p>Contact: <b><span className="lsn-textarea" ref={this.copyArea}>{routingData.vehicleTrackerBO.assetBO.contactNumber} </span></b><Icon onClick={this.copyToClipboard} type="copy" /><span className="grren">{this.state.copySuccess}</span></p> 
      this.setState({
        text: <Card>
          {clientId ?
            <CopyToClipboard text={`https://tracking.loadshare.net/partner/${partnerId}/client/${clientId}`}
              onCopy={this.notifyCopyEvent}>
              <div>
                <a target="_blank" href={`https://tracking.loadshare.net/partner/${partnerId}/client/${clientId}`}>https://tracking.loadshare.net/partner/{partnerId}/client/{clientId}</a>
                <Icon type="copy" />
              </div>
            </CopyToClipboard> :
            <CopyToClipboard text={`https://tracking.loadshare.net/partner/${partnerId}`}
              onCopy={this.notifyCopyEvent}>
              <div>
                <a target="_blank" href={`https://tracking.loadshare.net/partner/${partnerId}`}>https://tracking.loadshare.net/partner/{partnerId}</a>
                <Icon type="copy" />
              </div>
            </CopyToClipboard>

          }

        </Card>
      })
    }
  }
  applyPagination = (filters, isReset = false) => {
    if (isReset) {
      this.setState({
        pageNo: 1
      })
    }
    Mixpanel.track("Customer Page Search done.", { 'ERPCustomerSearchCustomer': moment().valueOf() })
    this.props.fetchCustomers({ filters: { ...this.state.filterPayload.filters, ...filters } }, isReset);
  }

  showConfirm = (selectedCustomer) => {
    confirm({
      title: 'Are you sure?',
      content: 'Do you want to delete this customer!!!',
      onOk() {
        this.props.deleteCustomer(selectedCustomer, { filters: { ...this.state.filterPayload.filters } })
      },
      onCancel() { },
    });
  }

  reloadCustomerList = () => {
    Mixpanel.track("Customer Reload Clicked.", { 'ERPCustomerReload': moment().valueOf() });
    this.setState({
      pageNo: 1
    })
    this.InputSearchRef.current.input.state.value = '';
    this.applyPagination({ pageNo: 1 }, true);
  }

  getPaginatedData = (pageNo, paginatedData) => {
    const { PaginatedCustomers, } = this.props;
    if (!PaginatedCustomers ? !PaginatedCustomers : !PaginatedCustomers[pageNo]) {
      this.applyPagination({ pageNo })
    }
    this.setState({
      pageNo
    });
  }

  render() {
    const { columns, customerPermission, pageNo } = this.state;
    const { PaginatedCustomers, customerData } = this.props;
    const actionBtns = (
      <Row>
        <Col span={customerPermission.create ? (customerPermission.invite ? 10 : 13) : 18}>
          <Input.Search size={'large'} placeholder="Search" allowClear ref={this.InputSearchRef} onSearch={value => this.applyPagination({ partialContentSearch: value.trim() }, true)} enterButton />
        </Col>
        <Col span={customerPermission.create ? (customerPermission.invite ? 2 : 2) : 5}>
          <Tooltip placement="top" title={'Reload Customer List'}>
            <Button block size={'large'} onClick={this.reloadCustomerList} type="primary" style={{ marginLeft: 2 }}>
              <Icon type="reload" />
            </Button>
          </Tooltip>
        </Col>
        {
          customerPermission.create && <Col span={customerPermission.invite ? 7 : 9}>
            <Button size={'large'} onClick={() => { Mixpanel.track("Create Customer Clicked", { 'ERPCustomerCreateClick': moment().valueOf() }); this.props.history.push(`/appv2/settings/form/customer`) }} type="primary" style={{ marginLeft: 12 }}>CREATE CUSTOMER</Button>
          </Col>
        }
        {
          customerPermission.invite && <Col span={3}>
            <Button size={'large'} onClick={() => { Mixpanel.track("Invite Users from Customer Initiated", { 'ERPCustomerInviteUserInitiated': moment().valueOf() }); this.props.history.push(`/appv2/settings/dashboard/customer/invite-user`) }} type="primary" style={{ marginLeft: 12 }}>Invite Users</Button>
          </Col>
        }
      </Row>
    );
    return (
      <>
        <Card className="CustomerDashBoard" title={<h2>Customer List</h2>} extra={actionBtns} >
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }}
            loading={customerData?.loading}
            pagination={{
              onChange: this.getPaginatedData,
              total: customerData?.totalCount,
              position: 'bottom',
              current: pageNo,
              pageSize: customerData?.pageSize
            }}
            dataSource={PaginatedCustomers ? PaginatedCustomers[pageNo] : []}
            columns={columns} />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerData: state.settingsCustomers,
  PaginatedCustomers: state.settingsCustomers?.PaginatedCustomers,
  accessKeysLoading: state?.settingsCustomers?.accessKeysLoading,
  accessKeys: state?.settingsCustomers?.accessKeys,
  header: state?.header,
});
const mapDispatchToProps = dispatch => ({
  fetchCustomers: (param, isReset = false) => dispatch(actions.fetchCustomers(param, isReset)),
  deleteCustomer: (id, payload) => dispatch(actions.deleteCustomer(id, payload)),
  getAccessKeys: (id) => dispatch(actions.getAccessKeys(id)),
  resetAccessKeys: () => dispatch(actions.resetAccessKeys()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashboard);