export const initialState = {
    loading: false
}

export const FETCH_ROLES_BEGIN = "FETCH_ROLES_BEGIN";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";

export const FETCH_ROLE_BEGIN = "FETCH_ROLE_BEGIN";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAILURE = "FETCH_ROLE_FAILURE";

export const FETCH_PERMISSIONS_BEGIN = "FETCH_PERMISSIONS_BEGIN";
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSIONS_FAILURE = "FETCH_PERMISSIONS_FAILURE";

export const MODIFY_ROLE_BEGIN = "MODIFY_ROLE_BEGIN";
export const MODIFY_ROLE_SUCCESS = "MODIFY_ROLE_SUCCESS";
export const MODIFY_ROLE_FAILURE = "MODIFY_ROLE_FAILURE";

export const RESET_ROLES = "RESET_ROLES";