
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const UserReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_USERS_BEGIN: return fetchUsersBegin(state);
        case _Constants.FETCH_USERS_SUCCESS: return fetchUsersSuccess(state, action);
        case _Constants.FETCH_USERS_FAILURE: return fetchUsersFailure(state, action);
        case _Constants.FETCH_USER_BEGIN: return fetchUserBegin(state);
        case _Constants.FETCH_USER_SUCCESS: return fetchUserSuccess(state, action);
        case _Constants.FETCH_USER_FAILURE: return fetchUserFailure(state, action);
        case _Constants.DO_FORCE_LOGOUT_BEGIN: return doForceLogoutBegin(state);
        case _Constants.DO_FORCE_LOGOUT_SUCCESS: return doForceLogoutSuccess(state, action);
        case _Constants.DO_FORCE_LOGOUT_FAILURE: return doForceLogoutFailure(state, action);
        case _Constants.USER_MODIFICATION_BEGIN: return userModificationBegin(state);
        case _Constants.USER_MODIFICATION_SUCCESS: return userModificationSuccess(state, action);
        case _Constants.USER_MODIFICATION_FAILURE: return userModificationFailure(state, action);
        case _Constants.FETCH_USERS_LOCATIONS_BEGIN: return fetchLocationsBegin(state);
        case _Constants.FETCH_USERS_LOCATIONS_SUCCESS: return fetchLocationsSuccess(state, action);
        case _Constants.FETCH_USERS_LOCATIONS_FAILURE: return fetchLocationsFailure(state, action);

        case _Constants.RESET_USER_CREATE: return resetUser(state, action);
        default:
            return state;
    }
}

function resetUser(state, action) {
    return {
        ...state,
        user: {},
        selectedUser: {},
    }
}


function fetchUsersBegin(state, action) {
    return {
        ...state,
        loading: true
        
    };
}

function fetchUsersSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedUsers: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedUsers ? state.PaginatedUsers : {}, action ?.payload ?.users , action.isReset),
        ...action.payload
    };
}

function fetchUsersFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        customers: []
    };
}

function fetchUserBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchUserSuccess(state, action) {
    return {
        ...state,
        loading: false,
        selectedUser: action.payload
    };
}

function fetchUserFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        selectedUser: []
    };
}

function doForceLogoutBegin(state, action) {
    return {
        ...state,
        isForceLogout: ''
    };
}

function doForceLogoutSuccess(state, action) {
    return {
        ...state,
        isForceLogout: action.payload
    };
}

function doForceLogoutFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        isForceLogout: false
    };
}

function userModificationBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function userModificationSuccess(state, action) {
    return {
        ...state,
        loading: false,
        user: { ...action.payload }
    };
}

function userModificationFailure(state, action) {
    return {
        ...state,
        loading: false,
        user: {},
        error: action.payload
    };
}

function fetchLocationsBegin(state, action) {
    return {
        ...state,
        locationloading: true
    };
}

function fetchLocationsSuccess(state, action) {
    return {
        ...state,
        locationloading: false,
        locationList: action.payload
    };
}

function fetchLocationsFailure(state, action) {
    return {
        ...state,
        locationloading: false,
        error: action.payload,
        locationList: []
    };
}