import { Button, Card, Modal, DatePicker, Icon, Spin, Empty } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../common/utils/utils";
import PaymentStatusService from "../../../../services/digital-payment/payment-status.service";
import "../payouts.scss";

const { MonthPicker } = DatePicker;
const paymentService = new PaymentStatusService();

function DailyEarnings(props) {
  const [loading, toggleLoading] = useState(false);
  const [modalloading, toggleModalLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [modalResponse, setModalResponse] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    fetchEarningsInfo(moment(selectedDate).format("MMMM-YYYY"));
  }, []);

  const fetchEarningsInfo = async date => {
    await toggleLoading(true);
    paymentService
      .getEarnings(date)
      .then(resp => {
        if (resp?.data?.response) {
          const { response } = resp?.data;
          toggleLoading(false);
          setResponse(response);
        } else {
          toggleLoading(false);
        }
      })
      .catch(err => {
        console.error(err);
        toggleLoading(false);
      });
  };
  const disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  const handleOpenModal = async date => {
    await toggleModalLoading(true);
    await setisOpen(true);
    paymentService
      .getEarnignsBreakup(moment(date).format("YYYYMMDD"))
      .then(resp => {
        if (resp?.data?.response) {
          const { response } = resp?.data;
          toggleModalLoading(false);
          setModalResponse(response);
        } else {
          toggleModalLoading(false);
        }
      })
      .catch(err => {
        console.error(err);
        toggleModalLoading(false);
      });
  };
  const handleCancel = () => {
    setisOpen(false);
  };
  return (
    <div
      style={{
        margin: 16,
        minHeight: "95vh"
      }}
      className="payout-summary"
    >
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Button onClick={props.closeAllPage} shape="circle" icon="left" />
          Go Back
        </div>
        <Card style={{ marginTop: 8, borderRadius: 8 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <MonthPicker
              suffixIcon={<Icon type="caret-down" />}
              style={{ width: "100%" }}
              allowClear={false}
              disabledDate={disabledDate}
              value={moment(new Date(), "YYYY/MM")}
              format={"MMM YYYY"}
              onChange={async val => {
                await setSelectedDate(val);
                fetchEarningsInfo(moment(val).format("MMMM-YYYY"));
              }}
            />
            <div
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.23,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#3b5a98",
                margin: "8px 0"
              }}
            >
              {formatCurrency(response?.totalBalance)}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.17,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#0b1323",
                marginBottom: 8
              }}
            >
              Total Balance
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 16
              }}
            >
              <div
                style={{
                  marginTop: 15,
                  height: "74px",
                  flexGrow: 0,
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "#f1f3f6",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "142px"
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.5,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#3b5a98"
                  }}
                >
                  {formatCurrency(response?.totalEarnings)}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.17,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#0b1323"
                  }}
                >
                  Delivery Earnings
                </div>
              </div>
              <div
                style={{
                  marginTop: 15,
                  height: "74px",
                  flexGrow: 0,
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "#f1f3f6",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "142px"
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.5,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#3b5a98"
                  }}
                >
                  {formatCurrency(response?.totalIncentiveAndSurge)}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.17,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#0b1323"
                  }}
                >
                  Incentive + Surge
                </div>
              </div>
              <div
                style={{
                  marginTop: 15,
                  height: "74px",
                  flexGrow: 0,
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "#f1f3f6",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "142px"
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.5,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#3b5a98"
                  }}
                >
                  {response?.totalDeliveries || 0}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.17,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#0b1323"
                  }}
                >
                  Total Deliveries
                </div>
              </div>
              <div
                style={{
                  marginTop: 15,
                  height: "74px",
                  flexGrow: 0,
                  padding: "16px",
                  borderRadius: "8px",
                  backgroundColor: "#f1f3f6",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "142px"
                }}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.5,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#3b5a98"
                  }}
                >
                  {response?.daysPresent || 0}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.17,
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#0b1323"
                  }}
                >
                  Days Present
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card style={{ marginTop: 8, borderRadius: 8 }}>
          {!response?.lineItems?.length && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Empty />
            </div>
          )}
          {!!response?.lineItems?.length &&
            response?.lineItems?.map(item => {
              return (
                <div
                  style={{
                    padding: "8px 16px",
                    borderRadius: "8px",
                    backgroundColor: "#f1f3f6",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 8,
                    cursor: "pointer"
                  }}
                  onClick={() => handleOpenModal(item?.dateEpochMillis)}
                >
                  <div
                    style={{
                      width: "45%",
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.43,
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#000000"
                      }}
                    >
                      {item?.dateDisplay || "-"}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.5,
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#8b8b8b"
                      }}
                    >
                      {item?.displayText || "-"}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.13,
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#5faa59"
                    }}
                  >
                    {formatCurrency(item?.amount)}
                  </div>
                </div>
              );
            })}
        </Card>
        <Modal
          visible={isOpen}
          onCancel={handleCancel}
          bodyStyle={{ padding: 14 }}
          centered
          footer={false}
        >
          <Spin spinning={modalloading}>
            <div
              style={{
                fontSize: "16px",
                fontWeight: 400,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "0.14px",
                textAlign: "left",
                color: "#14112d"
              }}
            >
              {`${modalResponse?.displayText ||
                "-"} : ${modalResponse?.amount || "0"}`}
            </div>
            <div
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "0.14px",
                textAlign: "left",
                color: "rgba(20, 17, 45, 0.72)",
                marginBottom: 10
              }}
            >
              {modalResponse?.description || "-"}
            </div>
            {modalResponse?.lineItems?.map(item => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 8
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.14px",
                        textAlign: "left",
                        color: "#000"
                      }}
                    >
                      {item.displayText || "-"}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.14px",
                        textAlign: "right",
                        color: "#000"
                      }}
                    >
                      {formatCurrency(item?.amount)}
                    </div>
                  </div>
                  {!!item?.lineItems?.length &&
                    item?.lineItems?.map(subItem => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: 8,
                            marginLeft: 16
                          }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.14px",
                              textAlign: "left"
                            }}
                          >
                            {subItem?.displayText || "-"}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.14px",
                              textAlign: "right"
                            }}
                          >
                            {formatCurrency(subItem?.amount)}
                          </div>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </Spin>
        </Modal>
      </Spin>
    </div>
  );
}

export default DailyEarnings;
