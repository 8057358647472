import React, { useState } from "react";
import { apiRequest } from "../../../common/utils/apiGateway";
import { Constants } from "../../../common/utils/constants";
import {
  Card,
  Row,
  Col,
  Input,
  Modal,
  Form,
  Button,
  Tabs,
  Alert,
  List,
  Radio,
  Divider,
} from "antd";
import _ from "lodash";
const { confirm } = Modal;
const { TextArea } = Input;
const { TabPane } = Tabs;

function Reallocate({
  orderid,
  currentStatus,
  getOrderDetails,
  orderDetails,
  clientCode,
  setConfirmButton,
  isConfirmButton,
}) {
  // const {reallocationReason, setReallocationReason} = useState(undefined)
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(undefined);
  const [reallocateCategories, setReallocateCategories] = useState({});
  const [activeKey, setActiveKey] = useState(undefined);
  const [selectReason, setSelectReason] = useState(false);
  const [selectedReason, setSelectedReason] = useState(undefined);
  const [changeCategory, setChangeCategory] = useState(undefined);
  // const [orderDetails, setOrderDetails] = useState({})
  // const [clientCode, setClientCode] = useState(undefined)

  const reallocateHandler = () => {
    apiRequest({
      url: `/hyperlocal/v2/order/reAllocationReasons`,
      method: "GET",
    }).then((res) => {
      console.log(res);
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        console.log("res", res.data.response.orderCancellationReasons);
        let categories = _.groupBy(
          res?.data?.response?.orderCancellationReasons,
          "reasonCategoryText"
        );
        setVisible(true);
        setError(undefined);
        setConfirmButton(false);
        setReallocateCategories(categories);
        setActiveKey(undefined);
        // clientcode = undefined;
        // setReallocationReason(categories)
      }
    });
  };

  const changeCategories = (e) => {
    setSelectedReason(e.target.value);
    setConfirmButton(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
    // setReallocationReason(undefined)
    setError(undefined);
    setConfirmButton(false);
    setActiveKey(undefined);
    setReallocateCategories({});
    setSelectReason(undefined);
    // setClientCode(undefined)
  };

  const handleOk = (e) => {
    if (selectedReason) {
      setConfirmButton(true);
      console.log(isConfirmButton);
      const data = {
        wayBillNo: orderid,
        clientCode: clientCode,
      };

      apiRequest({
        url: `/hyperlocal/v2/order/${data.wayBillNo}/reallocate`,
        method: "POST",
        data: {
          reasonId: selectedReason,
          customerCode: clientCode,
        },
      }).then((res) => {
        console.log(res);
        if (
          Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1
        ) {
          setConfirmButton(false);
          setVisible(false);
          handleCancel();
          getOrderDetails("wayBillNo", orderid);
        } else {
          setConfirmButton(false);
        }
      });
    } else {
      setError("Reason is Mandatory");
    }
  };

  return (
    <div>
      <Button
        style={{ background: "#17A770", color: "white" }}
        onClick={reallocateHandler}
      >
        Reallocate Order
      </Button>
      <Modal
        title="Select Reason for Reallocation"
        visible={visible}
        width={700}
        onCancel={handleCancel}
        footer={[
          <div style={{ textAlign: "left" }}>
            <p>
              <span style={{ color: "red" }}>Note: </span>We will try our best
              to find an eligible Delivery Agent to serve this order.
            </p>
            <p style={{ marginTop: "-10px" }}>
              If we can't find anyone, then this{" "}
              <span>order will be cancelled.</span>
            </p>
          </div>,
          <Button key="back" type="default" onClick={handleCancel}>
            Back
          </Button>,
          <Button
            key="submit"
            disabled={!isConfirmButton}
            type="primary"
            onClick={handleOk}
          >
            Reallocate
          </Button>,
        ]}
      >
        <div className="order-active">
          <Radio.Group onChange={changeCategories} value={selectedReason}>
            <Tabs tabPosition={"left"} type="card">
              {[...Object.keys(reallocateCategories)].map((i) => (
                <TabPane tab={`${i}`} key={i}>
                  <List
                    bordered
                    dataSource={reallocateCategories[i]}
                    renderItem={(item) => (
                      <List.Item>
                        <Radio value={item?.id}>{item?.reasonValue}</Radio>
                      </List.Item>
                    )}
                  />
                </TabPane>
              ))}
            </Tabs>
          </Radio.Group>
          <div style={{ marginTop: 10 }}>
            {error && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Reallocate;
