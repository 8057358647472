import React, { Component } from 'react';
import { Card, Row, Col, Button, Select, Input, Icon, Table,  Empty, Tooltip, Popover, Pagination, Modal, Checkbox, DatePicker, Radio, Form, notification} from 'antd';
import './manageFleet.scss';
import * as actions from './action';
import { connect } from 'react-redux';
// import MapComponent from './MapComponent'
import * as utils from "../../../common/utils/utils";
import { hypertrack } from '../../../environments/node-react-environment';
import moment from 'moment';
import NotifyImage from '../../../assets/img/icons/notify.png';
import { Constants } from '../../../common/utils/constants';
import MapToFleet from './MapToFleet';
import SuperSelect from "../../ui/SuperSelect/SuperSelect";
const Option = Select.Option;

const data = [
  {
    key: '1',
    duration: '10',
    disatnce: '15km',
    detail: 'Ten rounds of koramangla',
  }
];

const statusType = {
  AVAILABLE: 'Available',
  OFFLINE: 'Offline',
  ON_DUTY: 'On-Duty',
  ALL: 'All'
}

let selectBox;
let inputBox;

class Managefleet extends Component {
      constructor(props) {
          super(props);
          this.state = {
            arrayFe: [],
            allpageNo : 1,
            pageNo: 1,
            pageSize: 10,
            checked: false,
            client : 'Client',
            location : 'Location',
            notifyModal: false,
            hideInitial1: true,
            hideInitial2: false,
            hideBackInitial : false,
            selectedFe : [],
            selectedFeNames : [],
            apiData: true,
            boxData: {},
            templateSelected : [],
            mapShow: false,
            selectallbox : false,
            locationIds : [],
            completeData : {},
            allselectedData : {},
            defaultProperty : "COMPLETED",
            statusValue: {
              AVAILABLE: 0,
              OFFLINE: 0,
              ON_DUTY: 0,
              ALL: 0
            }
        }
        this.InputSearchRef = React.createRef();
        this.InputRef = React.createRef();
      }

    componentDidMount() {
      this.props.getTemplates();
      this.props.getCityList();
      this.onfilterInput();
      this.setState(state => ({
        columns: [
            {
              title: 'Online Duration',
              dataIndex: 'duration',
              key: 'duration',
              render: text => <a>{text}</a>,
            },
            {
              title: 'Distance Covered',
              dataIndex: 'disatnce',
              key: 'disatnce',
            },
            {
              title: 'Task Details',
              dataIndex: 'detail',
              key: 'detail',
            },
            {
                title: 'Action',
                dataIndex: '',
                key: 'action',
                render: (data, row) => (
                <Row> 
                    <Col span={12}><Tooltip placement="top" title={'Edit'}>
                      <Icon type="close" onClick={() => this.showModal(row.id)} /></Tooltip>
                    </Col>
                </Row >)
              }
          ]
      }));
    }

    componentDidUpdate(prevProp, prevState, snapShot) {
      if (prevProp.cityList !== this.props.cityList) {
          this.setState({cityList: this.props.cityList});
      }
      if (prevProp.useronDutyData !== this.props.useronDutyData) {
        this.getOndutySelect(this.props.useronDutyData);
        this.setState({useronDutyData: this.props.useronDutyData});
      }
      if(prevProp.cityListById !== this.props.cityListById){
        this.setState({cityListById: this.props.cityListById});
      }
      if(prevProp.feSummaryData !== this.props.feSummaryData){
        if(this.props.feSummaryData){
          this.getSelectedValues(this.props.feSummaryData, true)
        } else {
          this.getSelectedValues(this.props.feSummaryData, false)
        }
        this.totalfilter(["AVAILABLE", "OFFLINE", "ON_DUTY", "ALL"], 1);
      }
      if(prevProp.PaginatedRunsheetData !== this.props.PaginatedRunsheetData){
        this.runsheetTrackerId(1);
        this.setState({hideInitial : true, hideBackInitial : true})
      }

      if(prevProp.templatesData !== this.props.templatesData){
        // this.setState({
        //   apiData: false
        //   }, () => {
        //     this.getFormattedData(formList);
        //   })
        this.setState({templatesData : this.props.templatesData})
      }
    }

    runsheetTrackerId = (value) => {
      const { PaginatedRunsheetData } = this.props;
      this.handlerhypertrackembed(0, 0);
      if(Object.keys(PaginatedRunsheetData).length > 0){
        let data = PaginatedRunsheetData[value];
        for (let i = 0; i < PaginatedRunsheetData[value].length; i++) {
          if(data[i] ?.hypertrackTrip ?.embedURL){
            this.handlerhypertrackembed(data[i].hypertrackTrip.embedURL, i);
            break;
          }
        }
      } else {
        this.handlerhypertrackembed(0, 0);
      }
    }

    handlerhypertrackembed = (data, index) => {
      if(data){
        this.setState({ hypertrackEmbedLink : data, selectedblockToColored : index, hypertrackdummyView : false})
      }else{
        this.setState({ hypertrackdummyView : true, hypertrackEmbedLink : null, selectedblockToColored : null})
      }
    }

    handlerhypertrack = (data, index) => {
      if(data){
        this.setState({ hypertrackDeviceId : data, blockToColored : index, hypertackMapFe: false})
      }else{
        this.setState({ hypertrackDeviceId : null, blockToColored : null, hypertackMapFe: true})
      }
    }

    setDefaultTrackerId = (completeData, value) => {
      this.handlerhypertrack(0, 0);
      if(Object.keys(completeData).length > 0){
        let data = completeData[value];
        if(data){
          for (let i = 0; i < data.length; i++) {
            if(data[i].trackingDeviceID){
              this.handlerhypertrack(data[i].trackingDeviceID, i);
              break;
            }
          }
        }
      } else {
        this.handlerhypertrack(0, 0);
      }
    }

    getSelectedValues = (data, type) => {
      let statusValue = {};
      if(type === false) {
        statusValue = {
          AVAILABLE: 0,
          OFFLINE: 0,
          ON_DUTY: 0,
          ALL: 0
        }
      } else {
        data.map(x => {
          if(x.tagName){
            statusValue[x.tagName] = x.value;
          }
        })
      }
      this.setState({statusValue :  statusValue})
    }

    getFeData = (fes) => {
      if(!this.state.arrayFe.includes(fes)){
        this.setState(prevState => ({
          arrayFe: [...prevState.arrayFe, fes]
        }))
      }
    }

    totalfilter = (data, key) => {
      let payload = {
        "locationIds": this.state.locationIds,
        "entityStatuses": data,
        "pageNo": 1,
        "pageSize": 2000,
      }
      if(data[0] === "ON_DUTY"){
        payload.customerIds = this.state.onDutyIds;
      }
      let locationOnDuty = {
        "filters" : {
          "locationIds" : this.state.locationIds
        }
      }
      this.props.getonDutyUsers(locationOnDuty);
      this.getallSummaryDataOnce(payload, data, key);
    }

    getallSummaryDataOnce = (payload, data, key) => {
        this.props.getAllFeSummarypagination(payload).then(res => {
          if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            let completeData = {};
            notification['success']({
              message: 'SUCCESS',
              description: res ?.data ?.status ?.message,
              placement: 'bottomRight',
            });
            if(res ?.data ?.response ?.data){
              res.data.response.data.map(x => {
                x.selectStatus = false
              })
            }
            if(res ?.data ?.response ?.totalCount > 0){
              let noPages = Math.ceil(res ?.data ?.response ?.totalCount/10);
              for(let i = 1; i <= noPages; i++){
                let x;
                let y;
                if(i === 1){
                  x = 0;
                  y = 10;
                } else {
                  x = 10 * (i - 1) + 0;
                  y = 10 * (i - 1) + 9;
                }
                completeData[i] = res ?.data ?.response ?.data.slice(x, y);
              }
            }
            this.setDefaultTrackerId(completeData, this.state.allpageNo);
            this.setState({userStatus : data, selectedButton : key, completeData : completeData, allselectedData : res ?.data ?.response, totaldataSet : res ?.data ?.response ?.data, totaldataSet2 : res ?.data ?.response ?.data});
          } else {
            notification['error']({
              message: 'ERROR',
              description: res ?.data ?.status ?.message,
              placement: 'bottomRight',
            });
            this.setState({userStatus : data, selectedButton : key, completeData : {}, allselectedData : {}});
            return;
          }
        });
    }

    applyPagination = (pageNo) => {
        let totaldataSet = [...this.state.totaldataSet2];
        let completeData = {};
        let allselectedData = {};
        let selectallbox;
          if(totaldataSet.length > 0){
            if(pageNo.partialContentSearch){
              totaldataSet = totaldataSet.filter(x => x.entityName.toLowerCase().indexOf(pageNo.partialContentSearch) !== -1);
              for(let i = 0; i < totaldataSet.length; i++){
                selectallbox = true;
                if(totaldataSet[i].selectStatus === false){
                  selectallbox = false;
                  break;
                }
              }
            }
          }
          allselectedData.totalCount = totaldataSet.length;
          if(totaldataSet.length  > 0){
            let noPages = Math.ceil(totaldataSet.length/10);
            for(let i = 1; i <= noPages; i++){
              let x;
              let y;
              if(i === 1){
                x = 0;
                y = 10;
              } else {
                x = 10 * (i - 1) + 0;
                y = 10 * (i - 1) + 9;
              }
              completeData[i] = totaldataSet.slice(x, y);
            }
          }
          this.setDefaultTrackerId(completeData, this.state.allpageNo);
          if(pageNo.partialContentSearch !== ""){
            this.setState({ completeData : completeData, selectedSearch : pageNo.partialContentSearch, allpageNo: 1, allselectedData : allselectedData, selectallbox : selectallbox});
          } else{
            this.setState({ completeData : completeData, selectedSearch : pageNo.partialContentSearch, allpageNo: 1, allselectedData : allselectedData, selectallbox: selectallbox});
          }
          
    }

    onLocationChange = (e) => {
      if(e){
        let statusValue = {
          AVAILABLE: 0,
          OFFLINE: 0,
          ON_DUTY: 0,
          ALL: 0
        }
        this.props.getCityListById(e);
        this.setState({locationIds : [], completeData : {}, statusValue : statusValue})
      } else {
        this.setState({locationIds : []})
      }
    }

    onpartnerChange = (e) => {
      if(e) {
      this.props.getFeSummary({
        "locationIds" : e
      });
      this.setState({locationIds : e})
      } else {
        this.setState({locationIds : []})
      }
    }

    onDutyChangelist = (e) => {
      this.setState({onDutyIds : e})
    }

    getOndutySelect = (data) => {
        selectBox = <Select mode="multiple" placeholder="Search Name" style={{ width: 200 }} showSearch filterOption={(input, option) =>
        option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        } showArrow onChange={(e) => this.onDutyChangelist(e)}>
        {data ?.map(loc => (
          <Select.Option
            key={loc.id}
            value={loc.id}
            label={loc.name}
          >
            {loc.name}
          </Select.Option>
        ))}
      </Select>
      return selectBox;
    }

    reloadnewList = () => {
      let newArray = [...this.state.completeData[this.state.pageNo]];
      newArray.filter(x => moment(moment().format('MM/DD/YYYY HH:mm:ss A'), moment(x.lastUpdateAt).format('MM/DD/YYYY HH:mm:ss A')).isAfter(moment().subtract(this.InputRef.current.state.value, 'minutes')));
      let dataArray = {
        1 : newArray
      }
      this.setState({completeData : dataArray})
    }

    onfilterInput = () => {
      inputBox = <Row><Col span={16}>Last Ping > <Input placeholder="minutes" ref={this.InputRef} size="small" className="inputBox" width={10}/> minutes ago</Col>
                      <Col span={3}>
                          <Button block size={'small'} onClick={this.applyPagination}>
                            <Icon type="reload" />
                          </Button>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={3}>
                          <Button block size={'small'} onClick={this.reloadnewList}>
                            <Icon type="check" />
                          </Button>
                      </Col>        
                </Row>
      return inputBox;
    }

    getRunsheetList = (entityId, property, date) => {
      let payload = {
        "pageNo": 1,
        "pageSize": 10,
        "runsheetStatus": [
          property
        ],
        "createdAt": {
            "from": date.startOf('day').valueOf(),
            "to": date.endOf('day').valueOf(),
        },
        "tripUserId": entityId,
        "fetchHypertrackTrips": "true"
      }
      this.props.getRunsheetList(payload, true);
      this.setState({hideInitial1 : false, hideInitial2 : true, entityValue : entityId, defaultProperty : property, selectedDate: date});
    }

    getRunsheetDetail = (entityId) =>{
      if(entityId){
        this.props.getRunsheetDetail(entityId).then(res => {
          if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            if(res ?.data ?.response ?.hypertrackTrip ?.deviceId){
              this.handlerhypertrack(res ?.data ?.response ?.hypertrackTrip ?.deviceId, 0);
            } else {
              this.handlerhypertrack(0, 0);
            }
            this.setState({hideInitial1 : false, hideInitial2 : false, setlastBoxData : res ?.data ?.response});
          } else {
            return;
          }
        });
      }
    }

    showNotify = () => {
      this.setState({
        notifyModal: true,
      });
    }

    handleOk = e => {
      this.setState({
        notifyModal: false,
      });
    };
  
    handleCancel = e => {
      this.reset();
      this.setState({
        notifyModal: false,
        // selectedSearch : "",
      });
    };

    setTite = () => {
      return (<div><img src={NotifyImage} style={{ marginRight: 4 }} alt="notifymodal" />Notify</div>);
    }

    onChangeTemplate = (id) => {
      let mapboxShow;
      let templateData = [...this.props.templatesData];
      let selectedTemplate =  templateData.filter(x => x.id === id);
      this.getFormattedData(selectedTemplate[0].input);
      templateData.map(x => {
        if(x.id === id){
          if(x.deeplink){
            mapboxShow = true 
          } else{
            mapboxShow = false
          }
        }
      })
      this.setState({
        templateSelected : selectedTemplate[0].input,
        templateSelectedId : id,
        mapShow : mapboxShow,
      })
    }

    goBack = () =>{
      if(this.state.hideInitial1){
        this.setState({hideInitial1 : true, hideInitial2 : false})
      } else if(this.state.hideInitial2){
        this.setState({hideInitial1 : true, hideInitial2 : false})
      } else {
        this.setState({hideInitial1 : true, hideInitial2 : false})
      }
    }

    getdefaultProperty = (property, date) => {
      this.getRunsheetList(this.state.entityValue, property, date);
    }

    optionHtmlCreator = (id, endPoint, values) => {   
        if(endPoint) {
              return (
                this.state ?.boxData[id] ?.map(x => {
                    if(x.name) {
                        return (
                            <Select.Option 
                            key={x.id} 
                            value={x.id} 
                            label={x.name}                             
                        >
                            {x.name}
                            </Select.Option>
                        )
                    }
                })
            )
        } else {
          return (
            values ?.map((x, key) => {
                    return (
                        <Select.Option 
                          key={key} 
                          value={x} 
                          label={x}                             
                        >
                        {x}
                        </Select.Option>
                    )
            })
        )
        }
    }

    capitalize = (comp) =>{
        return comp[0].toUpperCase() + comp.substring(1, comp.length);
    }

    radioCreator = options => {
        let radios = [];
        let option = options[0];
        let keys = Object.keys(option);
        keys.map((v, i) => {
            radios.push(
                <Radio value={keys[i]} key={v+i}>{option[v]['value']}</Radio>
            )
        })

        return radios;
    }

    onChange = (val, fetchFor) => {        
    }

    formHtmlCreator = (list) => {
        let showSchemas = [];
        const { getFieldDecorator, getFieldValue, getFieldsValue } = this.props.form;
        return list.map((item, key) => {
            switch (item.type) {
                case 'text':
                    return <Row key={key}>
                        <Col span={24}>
                            <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    rules: [
                                        {
                                            required: item.validations[0].required,
                                            message: `Please enter your ${item.title}`
                                        },
                                    ]
                                })(
                                    <Input type={item.type} placeholder={item.title} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>;

                case 'select':                                        
                    if(showSchemas.length == 0 || showSchemas.includes(item.id)) {  
                        let defaultValue = [];
                        if(item?.defaultValue.length > 0) {                            
                            item.defaultValue.map(v => {                                
                                if(v['key'] instanceof Array) {
                                    const partnerId = Constants.PARTNER_ID.toString();
                                    let index = v["key"].indexOf(partnerId);
                                    if(index >= 0) {
                                        defaultValue.push({"key": partnerId, "label": v["label"]});
                                    }
                                } else {
                                    defaultValue.push(v);
                                }
                            })
                        }                                                                                  
                        return <Row key={key}>
                            <Col span={24}>
                                <Form.Item label={this.capitalize(item.title)}>
                                    {getFieldDecorator(item.payloadProperty, {                                           
                                        initialValue: defaultValue ? defaultValue : [],                                                                 
                                        rules: item.validations[0].required
                                    })(
                                        <Select
                                            onChange={(val) => this.onChange(val, item.fetchFor)}
                                            showSearch
                                            labelInValue={true}
                                            mode={item.multiple ? 'multiple' : 'single'}
                                            placeholder={`Select ${item.title}`}
                                            style={{ width: '100%' }}
                                            filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0                                                   
                                            }
                                        >
                                            {
                                                this.optionHtmlCreator(item?.id, item?.endPoint, item?.values)
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        
                    } else {
                        return <></>;
                    }                    
                    
                    
                case 'radio':                    
                    return <Row key={key}>
                        <Col span={24}>
                        <Form.Item label={this.capitalize(item.title)}>
                                {getFieldDecorator(item.payloadProperty, {
                                    initialValue: item?.default,                                    
                                    rules: item.validations[0].required
                                })(
                                    <Radio.Group>
                                        {
                                            this.radioCreator(item.radioOptions)
                                        }
                                    </Radio.Group>
                                    
                                )}
                            </Form.Item>
                        </Col>
                    </Row>;
                default:
                    return null;
            }
        })
    }

    getFormattedData = (formList) =>{
      let boxData = {...this.state.boxData}
      formList.map(x => {
        if(x.endPoint) {
          this.props.callDropDownApi_v2(x.endPoint).then(res =>{
            boxData[x.id] = res.data.response.batchId
            // boxData[x.id] = [{key : 1, label : "set 1"}, {key : 2, label : "set 2"}]
          })
        }
      })
      this.setState({boxData: boxData})
    }

    handleSubmit = e => {
      e.preventDefault();
      const _this = this;
      let templates = [...this.props.templatesData];
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          let payload = {};
          let templateAttributes = {};
          let data = templates.filter(x => x.id === this.state.templateSelectedId);
          data[0].input.map(x =>{
            templateAttributes[x.id] = typeof values[x.id] === 'object' ? values[x.id].label : values[x.id];
          })

            payload = {
              userIds : this.state.selectedFe,
              resolveUserTokensFromIds: true,
              templateAttributes : templateAttributes,
              templateId : this.state.templateSelectedId,
              serviceProvider : data[0].serviceProvider,
              appName : "ATLAS_LAST_MILE",
            }
            if(values['date-picker']){
              payload.scheduleTime = values['date-picker'] ? values['date-picker'].valueOf() : null;
            }
            this.props.sendPushNotification(payload).then(res => {
              if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
                notification['success']({
                  message: 'SUCCESS',
                  description: res ?.data ?.status ?.message,
                  placement: 'bottomRight',
                });
                this.reset();
                this.setState({notifyModal: false});
              } else {
                notification['error']({
                  message: 'ERROR',
                  description: res ?.data ?.status ?.message,
                  placement: 'bottomRight',
                });
                return;
              }
            });
        }
      })
    }

    maplatlngData = (lat, lng) => {
      const { setFieldsValue } = this.props.form;
      setFieldsValue({
        'lat': lat,
        'lon': lng,
      });
    }

    reset = () => {
      this.props.form.resetFields()
    }

    getnextpagination = (e) => {
      this.setDefaultTrackerId(this.state.completeData, e);
      this.setState({allpageNo : e})
    }

    pickedDate = (date) =>{
      this.getRunsheetList(this.state.entityValue, this.state.defaultProperty, date);
    }

    getRunsheetBox = (feData, index) =>{
      if(feData ?.hypertrackTrip ?.embedURL){
        this.handlerhypertrackembed(feData ?.hypertrackTrip ?.embedURL, index);
      }
    }

    onfeallChecked = (e) => {
      let totaldataSet = [...this.state.totaldataSet2];
      let selectedFeList = [...this.state.selectedFe];
      let selectedFeList2 = [...this.state.selectedFe];
      let selectedFeName = [...this.state.selectedFeNames];
      let completeData = {};
      if(e.target.checked) {
          if(this.state.selectedSearch){
            totaldataSet = totaldataSet.filter(x => x.entityName.toLowerCase().indexOf(this.state.selectedSearch) !== -1);
            totaldataSet.map(x => {
              x.selectStatus = e.target.checked;
              if(!selectedFeList.includes(x.entityId)){
                selectedFeList.push(x.entityId)
                selectedFeName.push(x.entityName);
              }
            })
          } else {
            if(totaldataSet.length > 0){
              totaldataSet.map(x => {
                if(x.selectStatus !== true){
                  x.selectStatus = e.target.checked;
                  selectedFeList.push(x.entityId)
                  selectedFeName.push(x.entityName);
                }
              })
            }
          }
            if(totaldataSet.length > 0){
              let noPages = Math.ceil(totaldataSet.length/10);
              for(let i = 1; i <= noPages; i++){
                let x;
                let y;
                if(i === 1){
                  x = 0;
                  y = 10;
                } else {
                  x = 10 * (i - 1) + 0;
                  y = 10 * (i - 1) + 9;
                }
                completeData[i] = totaldataSet.slice(x, y);
              }
            }
            this.setDefaultTrackerId(completeData, this.state.allpageNo);
            this.setState({ completeData : completeData, selectedFe : selectedFeList, selectedFeNames : selectedFeName, selectallbox : e.target.checked,  totaldataSet: totaldataSet});
      } else {
            let selectedFeRemove = [];
            if(this.state.selectedSearch){
              totaldataSet = totaldataSet.filter(x => x.entityName.toLowerCase().indexOf(this.state.selectedSearch) !== -1);
                totaldataSet.map(x => {
                    selectedFeRemove.push(x.entityId)
                    x.selectStatus = e.target.checked;
                })
                if(selectedFeRemove.length > 0){
                  selectedFeList2.forEach(x => {
                    let index = selectedFeList.indexOf(x);
                    if(selectedFeRemove.includes(x)){
                      selectedFeList.splice(index, 1);
                      selectedFeName.splice(index, 1)
                    }
                  })
                }
              } else {
                selectedFeList = [];
                selectedFeName = [];
                if(totaldataSet.length > 0){
                  totaldataSet.map(x => {
                      x.selectStatus = e.target.checked;
                  })
                }
              }
                if(totaldataSet.length  > 0){
                  let noPages = Math.ceil(totaldataSet.length/10);
                  for(let i = 1; i <= noPages; i++){
                    let x;
                    let y;
                    if(i === 1){
                      x = 0;
                      y = 10;
                    } else {
                      x = 10 * (i - 1) + 0;
                      y = 10 * (i - 1) + 9;
                    }
                    completeData[i] = totaldataSet.slice(x, y);
                  }
                }
                this.setDefaultTrackerId(completeData, this.state.allpageNo);
                this.setState({ completeData : completeData, selectedFe : selectedFeList, selectedFeNames : selectedFeName, selectallbox : e.target.checked, totaldataSet: totaldataSet });
      }
    }

    onfeDataChanged = (feData) => {
      let selectedFeList = [...this.state.selectedFe];
      let selectedFeName = [...this.state.selectedFeNames];
      let totaldataSet = [...this.state.totaldataSet2];
      let selectallbox;
      let completeData = {};
      if(!selectedFeList.includes(feData.entityId)){
        selectedFeList.push(feData.entityId)
        selectedFeName.push(feData.entityName);
      } else {
        selectedFeList = selectedFeList.filter(item => !(item === feData.entityId))
        selectedFeName = selectedFeName.filter(item => !(item === feData.entityId) && !(item === feData.entityName))
      }
          if(totaldataSet.length > 0){
            totaldataSet.map(x => {
              if(x.entityId === feData.entityId){
                x.selectStatus = !x.selectStatus;
              }
            })
          }
          if(this.state.selectedSearch){
            totaldataSet = totaldataSet.filter(x => x.entityName.toLowerCase().indexOf(this.state.selectedSearch) !== -1);
            selectallbox = true;
            for(let i = 0; i < totaldataSet.length; i++){
              if(totaldataSet[i].selectStatus === false){
                selectallbox = false;
                break;
              }
            }
          } else {
            selectallbox = selectedFeList.length !== totaldataSet.length ? false : true;
          }
          if(totaldataSet.length  > 0){
            let noPages = Math.ceil(totaldataSet.length/10);
            for(let i = 1; i <= noPages; i++){
              let x;
              let y;
              if(i === 1){
                x = 0;
                y = 10;
              } else {
                x = 10 * (i - 1) + 0;
                y = 10 * (i - 1) + 9;
              }
              completeData[i] = totaldataSet.slice(x, y);
            }
          }
          this.setDefaultTrackerId(completeData, this.state.allpageNo);
          this.setState({ completeData : completeData, selectedFe : selectedFeList, selectedFeNames : selectedFeName, selectallbox: selectallbox});
    };

    setLocationEndData = (data) => {
      const endLocationsData = [];
      if(data && data.length > 0){
        data.forEach(x => {
          endLocationsData.push((<Option key={x.id} value={x.id} label={x.name}>{x.name}</Option>));
        });
        return endLocationsData;
      } else {
        return endLocationsData;
      }
    }
  
    setLocationpartnerData = (data) => {
      const endLocationsData = [];
      if(data && data.length > 0){
        data.forEach(x => {
          endLocationsData.push((<Option key={x.id} value={x.id} label={x.alias}>{x.alias} {x ?.entity ?.custObject ?.name ? <span>({x ?.entity ?.custObject ?.name})</span> : <span></span>}</Option>));
        });
        return endLocationsData;
      } else {
        return endLocationsData;
      }
    }

    render() {
      const formItemLayout = {
          labelCol: { span: 8 },
          wrapperCol: { span: 14 },
      };
      const { getFieldDecorator } = this.props.form;

        return (
            <>
                <Card
                    title="Live Tracking"
                    className=''
                >
                  <Row gutter={24}>
                    <Col span={6}>
                    {/* <Select placeholder="City Name" style={{ width: 200 }} showSearch filterOption={(input, option) =>
                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } showArrow onChange={(e) => this.onLocationChange(e)}>
                      {this.state ?.cityList ?.map(loc => (
                        <Select.Option
                          key={loc.id}
                          value={loc.id}
                          label={loc.name}
                        >
                          {loc.name}
                        </Select.Option>
                      ))}
                    </Select> */}
                      <SuperSelect
                      showSearch
                      allowClear
                      placeholder="City Name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => this.onLocationChange(e)}
                      // onSearch={this.onSearchsef}
                      style={{ width: 200 }}
                    >
                      {this.setLocationEndData(this.state?.cityList)}
                    </SuperSelect>
                    </Col>
                    <Col span={10}>
                    {/* <Select placeholder="Location (Partner Name)" value={this.state.locationIds} mode="multiple" style={{ width: 320 }} showSearch filterOption={(input, option) =>
                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      } showArrow onChange={(e) => this.onpartnerChange(e)}>
                      {this.state ?.cityListById ?.map(loc => (
                        <Select.Option
                          key={loc.id}
                          value={loc.id}
                          label={loc.alias}
                        >
                          {loc ?.alias} ({loc ?.entity ?.custObject ?.name})
                        </Select.Option>
                      ))}
                    </Select> */}
                      <SuperSelect
                      showSearch
                      allowClear
                      value={this.state.locationIds}
                      mode="multiple"
                      placeholder="Location (Partner Name)"
                      filterOption={(input, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(e) => this.onpartnerChange(e)}
                      style={{ width: 320 }}
                    >
                      {this.setLocationpartnerData(this.state?.cityListById)}
                    </SuperSelect>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={6}></Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginTop : 20}}>
                    <Col className="gutter-row" span={16}>
                    {this.state.statusValue && <div className="table-operations">
                            <Button type={this.state.selectedButton === 1 ? "primary" : "default"} onClick={() => this.totalfilter(["AVAILABLE", "OFFLINE", "ON_DUTY", "ALL"], 1)}>All: {this.state.statusValue['ALL']}</Button>
                            <Button type={this.state.selectedButton === 2 ? "primary" : "default"} onClick={() => this.totalfilter(["AVAILABLE"], 2)} >Available: {this.state.statusValue['AVAILABLE']}</Button>
                            <Button type={this.state.selectedButton === 3 ? "primary" : "default"} onClick={() => this.totalfilter(["ON_DUTY"], 3)} className="onDuty">On-duty: {this.state.statusValue['ON_DUTY']}</Button>
                            <Popover content={selectBox} title="" trigger="click">
                              <Button className="onFilter" onClick={this.getOnDutyFilter}><Icon type="filter" /></Button>
                            </Popover>
                            <Button type={this.state.selectedButton === 4 ? "primary" : "default"} onClick={() => this.totalfilter(["OFFLINE"], 4)}>Offline: {this.state.statusValue['OFFLINE']}</Button>
                        </div>}
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginTop : 20}}>
                          <Col span={10}>
                          {this.state.hideInitial1 === true && <Row>
                              <Col span={2} className="manageChecks"><Checkbox onChange={this.onfeallChecked} checked={this.state.selectallbox}></Checkbox></Col>
                              <Col span={16}>
                                <Input.Search placeholder="Search" className="manageSearch" allowClear ref={this.InputSearchRef} onSearch={value => this.applyPagination({ partialContentSearch: value.toLowerCase() })} enterButton />
                              </Col>
                              {this.state ?.selectedFeNames && <Col span={6}>
                                <button className={this.state ?.selectedFeNames.length > 0 ? "notifyBox notify-btn-primary" : "notifyBox"} disabled={this.state ?.selectedFeNames.length > 0 ? false : true} onClick={() => this.showNotify()}>
                                <img src={NotifyImage} style={{ width: 26, marginRight: 4 }} alt="notify" />Notify
                                </button>
                              </Col>}
                            </Row>}
                            {this.state.hideBackInitial === true && this.state.hideInitial1 === false && <Row style={{paddingTop: 10}}>
                              <Col span={2} className="manageChecks" onClick={this.goBack}><Icon type="arrow-left" /></Col>
                              <Col className={this.state.defaultProperty === "COMPLETED" ? 'headColor feStatusValue' : 'feStatusValue'} onClick={() => this.getdefaultProperty("COMPLETED", moment())} span={11}>Completed</Col>
                              <Col className={this.state.defaultProperty === "ONGOING" ? 'headColor feStatusValue' : 'feStatusValue'} onClick={() =>this.getdefaultProperty("ONGOING", moment())} span={11}>Ongoing</Col>
                            </Row>}
                            {this.state.hideBackInitial === true && this.state.hideInitial1 === false && this.state.defaultProperty === "COMPLETED" && <Row style={{paddingTop: 10}}>
                                <DatePicker onChange={this.pickedDate} defaultValue={moment(moment(), 'YYYY-MM-DD')}/>
                              </Row>}
                            {this.state.hideInitial1 === true && 
                            <>
                            <Row style={{paddingTop: 10}}>
                            {this.state ?.allselectedData && this.state ?.completeData[this.state.allpageNo] ?.map((feData, index) => (
                              <div className={this.state.blockToColored === index ? "blueColor feList" : "feList" } key={index}>
                                <Row>
                                  <Col span={4}></Col>
                                  <Col span={12} onClick={() => this.getRunsheetList(feData.entityId, "COMPLETED", moment())}>
                                    <div className="feTitles">Name</div>
                                    <div className="feDescription">{feData.entityName}</div>
                                  </Col>
                                  <Col span={8} onClick={() => this.getRunsheetList(feData.entityId, "COMPLETED", moment())}>
                                  <div className="feTitles">Phone</div>
                                    <div className="feDescription">{feData.contactNumber}</div>
                                  </Col>
                                </Row>
                                <Row style={{marginTop: 10}}>
                                  <Col span={4}><Checkbox onChange={() =>this.onfeDataChanged(feData)} checked={feData.selectStatus}></Checkbox></Col>
                                  <Col span={12} onClick={() => this.getRunsheetList(feData.entityId, "COMPLETED", moment())}>
                                    <div className="feTitles">Last Ping</div>
                                    <div className="feDescription">{utils.dateDiffFormat(feData.lastUpdateAt)}</div>
                                    <div className="feTitles">{utils.dateFormat(feData.lastUpdateAt)}</div>
                                  </Col>
                                  <Col span={8} onClick={() => this.getRunsheetList(feData.entityId, "COMPLETED", moment())}>
                                  <div className="feTitles">Status</div>
                                  <div className="feDescription">{statusType[feData.entityStatus]} {feData.trackingDeviceID && <Icon style={{ fontSize: '16px', color: '#08c' }} type="environment" />}</div>
                                  </Col>
                                </Row>
                            </div>
                            ))}
                              <Row style={{paddingTop: 20}}>
                                {this.state ?.allselectedData && this.state.completeData && this.state.completeData[this.state.allpageNo] && <Pagination current={this.state.allpageNo} total={this.state ?.allselectedData ?.totalCount} onChange={this.getnextpagination} pageSize={10} />}
                              </Row>
                              </Row>
                            </>}
                            {this.state.hideInitial2 === true && <Row style={{paddingTop: 10}}>
                              {this.props ?.PaginatedRunsheetData && this.props ?.PaginatedRunsheetData[1] ?.map((feData, index) => (
                                <div className={this.state.selectedblockToColored === index ? "blueColor feList" : "feList" } key={index} onClick={() => this.getRunsheetBox(feData, index)}>
                                  <Row>
                                    <Col span={12}>
                                      <div className="feTitles">Runsheet ID</div>
                                      <div className="feDescription">{feData.code}</div>
                                    </Col>
                                    <Col span={12}>
                                    <div className="feTitles">Assigned</div>
                                      <div className="feDescription">{feData.assignedConsignmentsCount} {feData ?.hypertrackTrip ?.deviceId && <Icon style={{ fontSize: '16px', color: '#08c' }} type="environment" />}</div>
                                    </Col>
                                  </Row>
                                  <Row style={{marginTop: 10}}>
                                    <Col span={12}>
                                      <div className="feTitles">Started</div>
                                      <div className="feDescription">{utils.dateDiffFormat(feData.createdAt)}</div>
                                      <div className="feTitles">{utils.dateFormat(feData.createdAt)}</div>
                                    </Col>
                                    <Col span={6}>
                                    <div className="feTitles">Delivered</div>
                                    <div className="feDescription">{feData.completedConsignmentsCount}</div>
                                    </Col>
                                    <Col span={6}>
                                    <div className="feTitles">Distance</div>
                                    <div className="feDescription">{feData ?.hypertrackTrip ?  (!isNaN((feData.hypertrackTrip.distance/1000).toFixed(1)) ? (feData.hypertrackTrip.distance/1000).toFixed(1) : 0) : "-"} km</div>
                                    </Col>
                                  </Row>
                              </div>
                              ))}
                            </Row>}
                          </Col>
                          <Col span={14}>
                            <div className="gutter-box">
                            {this.state.hideInitial1 === true && this.state.completeData && this.state.completeData[this.state.allpageNo] && this.state.hypertackMapFe === false &&
                              <iframe width="100%" height="530px" title="hyperTrackmap" src={`${hypertrack.url}/${this.state.hypertrackDeviceId}?publishable_key=${hypertrack.key}&{var1}={foo}&{var2}={bar}`} />
                            }
                            {this.state.hideInitial1 === true && this.state.hypertackMapFe === true &&
                              <div className="runsheetnoMap">NO MAP VIEW</div>
                            }
                            {this.state.hideInitial2 === true &&  this.props ?.PaginatedRunsheetData && this.props ?.PaginatedRunsheetData[1] && this.state.hypertrackdummyView === false &&
                              <iframe width="100%" height="530px" title="hyperTrackmap" src={this.state.hypertrackEmbedLink} />
                            }
                            {this.state.hideInitial2 === true && this.state.hypertrackdummyView === true &&
                              <div className="runsheetnoMap">NO MAP VIEW FOR RUNSHEET</div>
                            }
                            </div>
                            <div className="gutter-box">
                              {this.state.arrayFe && this.state.arrayFe.length > 0 &&
                                  <Table
                                  bordered
                                  locale={{
                                  emptyText: (
                                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                  )
                                  }}
                                  loading={false}
                                  pagination={false}
                                  dataSource={data}
                                  columns={this.state.columns} />
                              }
                            </div>
                          </Col>
                          <Modal
                            title={this.setTite()}
                            visible={this.state.notifyModal}
                            onOk={this.handleSubmit}
                            onCancel={this.handleCancel}
                            width={720}
                            footer={[
                              <Button key="submit" type="primary"  onClick={this.handleSubmit}>
                                Send Notification
                              </Button>
                            ]}
                            >
                            <Row>
                                <Col span={14}>
                                  <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                    <Select placeholder="Select Template" style={{ width: 200 }} showSearch filterOption={(input, option) =>
                                      option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      } showArrow onChange={(e) => this.onChangeTemplate(e)}>
                                      {this.state ?.templatesData ?.map(temp => (
                                        <Select.Option
                                          key={temp.id}
                                          value={temp.id}
                                          label={temp.name}
                                        >
                                          {temp.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                    <Col span={24} style={{marginTop : 20}}>Preview</Col>
                                    <Row>
                                    {
                                        this.formHtmlCreator(this.state ?.templateSelected)
                                    }
                                    <Row key="dateData">
                                      <Col span={24}>
                                          <Form.Item label="Scheduled Time">
                                              {getFieldDecorator('date-picker', {
                                                  rules: [
                                                      {
                                                          required: false,
                                                          message: `Please enter your Date`
                                                      },
                                                  ]
                                              })(
                                                <DatePicker showTime format="YYYY-MM-DD h:mm:ss" />
                                              )}
                                          </Form.Item>
                                      </Col>
                                  </Row>
                                    </Row>
                                  </Form>
                                </Col>
                                <Col span={10}>
                                    {this.state.mapShow && <MapToFleet lat={this.state ?.lat} lng={this.state ?.lng}  mapData={this.maplatlngData}></MapToFleet>}
                                </Col>
                                  {this.state ?.selectedFeNames && this.state ?.selectedFeNames.length > 0 && <Col span={24} style={{marginTop : 20}}>Send to : {this.state ?.selectedFeNames.join(', ')}</Col>}
                            </Row>
                          </Modal>
                  </Row>
                </Card>
        </>
        )
    }
}

const mapStateToProps = state => ({
  userDataList : state.manageFleet,
  feSummaryData: state.manageFleet ?.feSummary ?.data,
  cityList: state.manageFleet ?.cityList ?.citiesDetails,
  cityListById: state.manageFleet ?.locations,
  useronDutyData : state.manageFleet ?.useronDutyData ?.customers,
  templatesData : state.manageFleet ?.notificationTemplates,
  PaginatedRunsheetData : state.manageFleet ?.PaginatedRunsheetData,
});

const mapDispatchToProps = dispatch => ({
  getFeSummary:(data) => dispatch(actions.getFeSummary(data)),
  getCityList:() => dispatch(actions.getCityList()),
  getCityListById:(cityId) => dispatch(actions.getCityListById(cityId)),
  getonDutyUsers: (data) => dispatch(actions.getonDutyUsers(data)),
  getTemplates: () => dispatch(actions.getTemplates()),
  getRunsheetList: (payload, isReset = false) => dispatch(actions.getRunsheetList(payload, isReset)),
  getRunsheetDetail : (entityId) => dispatch(actions.getRunsheetDetail(entityId)),
  callDropDownApi_v2 : (endPoint) => dispatch(actions.callDropDownApi_v2(endPoint)),
  createTemplate : (payload) => dispatch(actions.createTemplate(payload)),
  getAllFeSummarypagination : (payload) => dispatch(actions.getAllFeSummarypagination(payload)),
  sendPushNotification : (payload) => dispatch(actions.sendPushNotification(payload)),
});
// export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'templateCreate' })(Managefleet));
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'templateCreate', onValuesChange: (props, val) =>  console.log(props.form.getFieldsValue()) })(Managefleet));