import React, { Component } from 'react';
import { Map, Marker, TileLayer, Polyline , Popup } from 'react-leaflet';
import L from 'leaflet';
//import RoutingMachine from './RoutingMachine';
import Polylines from './Polylines';
import FuturePolylines from './FuturePolylines';
import CirclePins from './CirclePins';
import CircleMarkerpins from './CircleMarkerpins';
import {carIcon,truckIcon,createCustomMarker,createCustomMarkerHtml,greenMarkerIcon,redMarkerIcon,blueMarkerIcon,orangeMarkerIcon} from './CustomIcon'
import img from '../../assets/img/truck_0.svg';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet-polylinedecorator";
import 'leaflet/dist/leaflet.css';
import * as utils from "../../common/utils/utils";

import './map-widget.css';
   const pinkicon = L.icon({
      iconUrl:
        "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
      iconSize: [40, 40],
      iconAnchor: [20, 40],
    });

const markerStyle = {
    color: '#091c9b',
    fillColor: "#091c9b",
    opacity: 1,
    radius: 8,
  };

  const markerStyle2 = {
    color: '#3b5a98',
    opacity: 1,
    radius: 8,
    fill: "#fff",
    fillRule: "a"
  };


//   const redMarker = L.divIcon({
//     iconUrl: img,
//     iconSize: [40, 40],
//     iconAnchor: [20, 40],
//   });

  const redMarker = L.divIcon({
    iconSize: [20, 20],
    iconAnchor: [10, 30],
    className: `u-turn-icon`,
    html: `<img src=${img} />`
  });



export default class AtlasHelipsMapRoute extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMapInit: false,
        };
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.routing !== nextProps.routing;
    }

    getBounds = () => {
        const { routing } = this.props;
        let jsonData = [];
        if(routing){
            routing.mapLocationPoints.map((x,key) => {
                let coordinates = [x['latitude'], x['longitude']];
                if(coordinates !== null){
                    jsonData.push(coordinates);
                }
            })
        }
        return jsonData;
    }

    getGPSBounds = () => {
      const { routing } = this.props;
      let jsonData = [];
      if(routing){
          routing.mapLocationPoints.map((x,key) => {
              let coordinates = [x['latitude'], x['longitude']];
              if(coordinates !== null){
                  jsonData.push(coordinates);
              }
          })
      }
      return jsonData;
  }



    markerBounds = () => {
        const { routing } = this.props;
        let setDummy = 65;
        let runningKey = 0;
        let customerMarkerLocations = [];
      if (routing) {

        if (routing.startingLocation) {
          runningKey = runningKey + 1;
          let coordinates = [Number(routing.startingLocation['latitude']), Number(routing.startingLocation['longitude'])];
          customerMarkerLocations.push(<>
            <Marker key={runningKey} position={coordinates} interactive={true} keyboard={true} numberSet={setDummy} icon={greenMarkerIcon}>
            <Popup>
                    Location: {routing.startingLocation.address} <br />
                    Started at: {utils.dateFormat(routing.startingLocation.timeStamp)}
            </Popup>
            </Marker>
          </>)
        }

        routing.timeLineData.forEach((x) => {
          let coordinates = [Number(x['latitude']), Number(x['longitude'])];
          runningKey = runningKey +1;
          if (coordinates !== null && !x.customerLocation) {
            setDummy = setDummy + 1;
            customerMarkerLocations.push(
                <>
                  <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={orangeMarkerIcon}>
                  </Marker>
                </>
              )
          }else if (coordinates !== null && x.customerLocation) {
            setDummy = setDummy + 1;
            customerMarkerLocations.push(
                <>
                  <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true}  icon={blueMarkerIcon}>
                  <Popup>
                    Location: {x.location} <br />
                    Arrived at: {utils.dateFormat(x.startTime)}
                  </Popup>
                  </Marker>
                </>
              )
          }

        })

        let temp = routing?.customerLocations?.forEach((x) => {
          let coordinates = [Number(x['latitude']), Number(x['longitude'])];
          runningKey = runningKey +1;
          if (coordinates !== null && !x.reached) {
            setDummy = setDummy + 1;
            customerMarkerLocations.push(
                <>
                  <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true}  icon={redMarkerIcon}>
                  <Popup>
                    Location: {x.name} <br />
                  </Popup>
                  </Marker>
                </>
              )
          }else if (coordinates !== null && x.reached && routing.simTracking) {
            setDummy = setDummy + 1;
            customerMarkerLocations.push(
              <>
              <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true}  icon={blueMarkerIcon}>
              <Popup>
                Location: {x.name} <br />
                Arrived at: {utils.dateFormat(x.arriveAt)}
              </Popup>
              </Marker>
            </>
              )
          }

        })

        if (routing.lastKnownLocation) {
          runningKey = runningKey + 1;
          setDummy = setDummy + 1;
          let coordinates = [Number(routing.lastKnownLocation['latitude']), Number(routing.lastKnownLocation['longitude'])];
          customerMarkerLocations.push(<>
            <Marker key={runningKey} position={coordinates} interactive={true} numberSet={setDummy} keyboard={true} icon={redMarker} >
            <Popup>
                    Location: {routing.lastKnownLocation.address} <br />
                    Arrived at: {utils.dateFormat(routing.lastKnownLocation.timeStamp)}
            </Popup>
            </Marker>
          </>)
        }
        return customerMarkerLocations;
      }
    }



    saveMap = (map) => {
        this.map = map;
        this.setState({
            isMapInit: true
        })
    }

     urlDecode = (encoded) => {
        var points=[ ]
        var index = 0, len = encoded.length;
        var lat = 0, lng = 0;
        while (index < len) {
            var b, shift = 0, result = 0;
            do {

        b = encoded.charAt(index++).charCodeAt(0) - 63;
                  result |= (b & 0x1f) << shift;
                  shift += 5;
                 } while (b >= 0x20);


           var dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
           lat += dlat;
          shift = 0;
          result = 0;
         do {
            b = encoded.charAt(index++).charCodeAt(0) - 63;
            result |= (b & 0x1f) << shift;
           shift += 5;
             } while (b >= 0x20);
         var dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
         lng += dlng;

       points.push([( lat / 1E5),( lng / 1E5)])

      }
      return points
    }


  render() {
      const { routing } = this.props;
      let position = [];
      let lastPosition = [];
      let markerlet = [];
      let markerlist;
      if(routing){
        if(routing.simTracking){
          markerlet = this.getBounds();
          position = markerlet;
          markerlist = this.markerBounds();
        }else{
          markerlet = this.getGPSBounds();
          position = markerlet;
          markerlist = this.markerBounds();
        }


      }

    return(
          <div className="directions">
           {routing &&  position  && markerlet &&
           <div>
            <Map
                ref={this.saveMap}
                id='map'
                animate={true}
                onZoomEnd={this.updateZoom}
                onClick={this.updatePosition}
                bounds={markerlet}
                boundsOptions={{padding: [50, 50]}} style={{height:598+'px'}}>
                 {/* <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'roadmap'} /> */}
                 <TileLayer
                    url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
                />
                {/* {this.state.isMapInit && <RoutingMachine map={this.map} road={markerlet} />} */}
                {routing &&<Polyline positions={position} />}
                {routing && routing.futurePolyLine && <Polyline color='#7f7f80'  weight="3" dashArray="4" positions={this.urlDecode(routing.futurePolyLine)}/>}
                {markerlist}
                </Map>
              </div>}
          </div>
    )
  }
}
