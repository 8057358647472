import * as CONSTANTS from './constants';

export const b2bLiteBookingReducer = (state = {
    bookingEdit: {
        loading: true,
        data: {},
        metaData: null
    }
}, action) => {
    switch (action.type) {
        case CONSTANTS.GET_CUSTOMER_LIST_BEGIN: return getCustomerListBegin(state);
        case CONSTANTS.GET_CUSTOMER_LIST_SUCCESS: return getCustomerListSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_LIST_FAILURE: return getCustomerListFailure(state);

        case CONSTANTS.GET_CUSTOMER_SHIPPER_BOOKING_LIST_BEGIN: return getShipperListBegin(state);
        case CONSTANTS.GET_CUSTOMER_SHIPPER_BOOKING_LIST_SUCCESS: return getShipperListSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_SHIPPER_BOOKING_LIST_FAILURE: return getShipperListFailure(state);


        case CONSTANTS.GET_BOOKING_OFFICE_BEGIN: return getBookingOfficeBegin(state);
        case CONSTANTS.GET_BOOKING_OFFICE_SUCCESS: return getBookingOfficeSuccess(state, action);
        case CONSTANTS.GET_BOOKING_OFFICE_FAILURE: return getBookingOfficeFailure(state);

        case CONSTANTS.GENERATE_WAYBILL_BEGIN: return generateWaybillBegin(state);
        case CONSTANTS.GENERATE_WAYBILL_SUCCESS: return generateWaybillSuccess(state, action);
        case CONSTANTS.GENERATE_WAYBILL_FAILURE: return generateWaybillFailure(state);

        case CONSTANTS.ORIGIN_LOCATION_BEGIN: return originLocationBegin(state);
        case CONSTANTS.ORIGIN_LOCATION_SUCCESS: return originLocationSuccess(state, action);
        case CONSTANTS.ORIGIN_LOCATION_FAILURE: return originLocationFailure(state);

        case CONSTANTS.DESIGNATION_LOCATION_BEGIN: return designationLocationBegin(state);
        case CONSTANTS.DESIGNATION_LOCATION_SUCCESS: return designationLocationSuccess(state, action);
        case CONSTANTS.DESIGNATION_LOCATION_FAILURE: return designationLocationFailure(state);

        case CONSTANTS.SHIPMENT_DIMENSIONS_BEGIN: return shipmentDimensionsBegin(state);
        case CONSTANTS.SHIPMENT_DIMENSIONS_SUCCESS: return shipmentDimensionsSuccess(state, action);
        case CONSTANTS.SHIPMENT_DIMENSIONS_FAILURE: return shipmentDimensionsFailure(state);

        case CONSTANTS.GOODS_TYPES_BEGIN: return goodsTypesBegin(state);
        case CONSTANTS.GOODS_TYPES_SUCCESS: return goodsTypesSuccess(state, action);
        case CONSTANTS.GOODS_TYPES_FAILURE: return goodsTypesFailure(state);

        case CONSTANTS.PICKUP_BEGIN: return getPickupListBegin(state);
        case CONSTANTS.PICKUP_SUCCESS: return getPickupListSuccess(state, action);
        case CONSTANTS.PICKUP_FAILURE: return getPickupListFailure(state);

        case CONSTANTS.BOOKING_CREATION_BEGIN: return BookingCreationBegin(state);
        case CONSTANTS.BOOKING_CREATION_SUCCESS: return BookingCreationSuccess(state, action);
        case CONSTANTS.BOOKING_CREATION_FAILURE: return BookingCreationFailure(state);

        case CONSTANTS.BOOKING_EDIT_BEGIN: return BookingEditBegin(state);
        case CONSTANTS.BOOKING_EDIT_SUCCESS: return BookingEditSuccess(state, action);
        case CONSTANTS.BOOKING_EDIT_FAILURE: return BookingEditFailure(state);

        default:
            return state;
    }
}

function getCustomerListBegin(state) {
    return {
        ...state,
        customerList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function getCustomerListSuccess(state, action) {
    return {
        ...state,
        customerList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function getCustomerListFailure(state, action) {
    return {
        ...state,
        customerList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}


function getShipperListBegin(state) {
    return {
        ...state,
        shipperList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function getShipperListSuccess(state, action) {
    return {
        ...state,
        shipperList: {
            loading: false,
            data: action ?.payload?.mappingBOs,
            metaData: null
        }
    }
}
function getShipperListFailure(state, action) {
    return {
        ...state,
        shipperList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}


function getBookingOfficeBegin(state) {
    return {
        ...state,
        bookingOfficeList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function getBookingOfficeSuccess(state, action) {
    return {
        ...state,
        bookingOfficeList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function getBookingOfficeFailure(state, action) {
    return {
        ...state,
        bookingOfficeList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function generateWaybillBegin(state) {
    return {
        ...state,
        genaratedWaybill: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function generateWaybillSuccess(state, action) {
    return {
        ...state,
        genaratedWaybill: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function generateWaybillFailure(state, action) {
    return {
        ...state,
        genaratedWaybill: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function originLocationBegin(state) {
    return {
        ...state,
        originLocationList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function originLocationSuccess(state, action) {
    return {
        ...state,
        originLocationList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function originLocationFailure(state, action) {
    return {
        ...state,
        originLocationList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function designationLocationBegin(state) {
    return {
        ...state,
        desigationLocationList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function designationLocationSuccess(state, action) {
    return {
        ...state,
        desigationLocationList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function designationLocationFailure(state, action) {
    return {
        ...state,
        desigationLocationList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function shipmentDimensionsBegin(state) {
    return {
        ...state,
        shipmentDimensionsList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function shipmentDimensionsSuccess(state, action) {
    return {
        ...state,
        shipmentDimensionsList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function shipmentDimensionsFailure(state, action) {
    return {
        ...state,
        shipmentDimensionsList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function goodsTypesBegin(state) {
    return {
        ...state,
        goodsTypesList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function goodsTypesSuccess(state, action) {
    return {
        ...state,
        goodsTypesList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function goodsTypesFailure(state, action) {
    return {
        ...state,
        goodsTypesList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function getPickupListBegin(state) {
    return {
        ...state,
        pickupList: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function getPickupListSuccess(state, action) {
    return {
        ...state,
        pickupList: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function getPickupListFailure(state, action) {
    return {
        ...state,
        pickupList: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function BookingCreationBegin(state) {
    return {
        ...state,
        bookingCreation: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function BookingCreationSuccess(state, action) {
    return {
        ...state,
        bookingCreation: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function BookingCreationFailure(state, action) {
    return {
        ...state,
        bookingCreation: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}

function BookingEditBegin(state) {
    return {
        ...state,
        bookingEdit: {
            loading: true,
            data: [],
            metaData: null
        }
    }
}

function BookingEditSuccess(state, action) {
    return {
        ...state,
        bookingEdit: {
            loading: false,
            data: action ?.payload,
            metaData: null
        }
    }
}
function BookingEditFailure(state, action) {
    return {
        ...state,
        bookingEdit: {
            loading: false,
            data: action && action.payload,
            metaData: null
        }
    }
}