export const GET_CUSTOMER_LIST_BEGIN = 'GET_CUSTOMER_LIST_BEGIN';
export const GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS';
export const GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE';

export const GET_CUSTOMER_SHIPPER_BOOKING_LIST_BEGIN = 'GET_CUSTOMER_SHIPPER_BOOKING_LIST_BEGIN';
export const GET_CUSTOMER_SHIPPER_BOOKING_LIST_SUCCESS = 'GET_CUSTOMER_SHIPPER_BOOKING_LIST_SUCCESS';
export const GET_CUSTOMER_SHIPPER_BOOKING_LIST_FAILURE = 'GET_CUSTOMER_SHIPPER_BOOKING_LIST_FAILURE';

export const GET_BOOKING_OFFICE_BEGIN = 'GET_BOOKING_OFFICE_BEGIN';
export const GET_BOOKING_OFFICE_SUCCESS = 'GET_BOOKING_OFFICE_SUCCESS';
export const GET_BOOKING_OFFICE_FAILURE = 'GET_BOOKING_OFFICE_FAILURE';

export const GENERATE_WAYBILL_BEGIN = 'GENERATE_WAYBILL_BEGIN';
export const GENERATE_WAYBILL_SUCCESS = 'GENERATE_WAYBILL_SUCCESS';
export const GENERATE_WAYBILL_FAILURE = 'GENERATE_WAYBILL_FAILURE';

export const ORIGIN_LOCATION_BEGIN = 'ORIGIN_LOCATION_BEGIN';
export const ORIGIN_LOCATION_SUCCESS = 'ORIGIN_LOCATION_SUCCESS';
export const ORIGIN_LOCATION_FAILURE = 'ORIGIN_LOCATION_FAILURE';

export const DESIGNATION_LOCATION_BEGIN = 'DESIGNATION_LOCATION_BEGIN';
export const DESIGNATION_LOCATION_SUCCESS = 'DESIGNATION_LOCATION_SUCCESS';
export const DESIGNATION_LOCATION_FAILURE = 'DESIGNATION_LOCATION_FAILURE';

export const SHIPMENT_DIMENSIONS_BEGIN = 'SHIPMENT_DIMENSIONS_BEGIN';
export const SHIPMENT_DIMENSIONS_SUCCESS = 'SHIPMENT_DIMENSIONS_SUCCESS';
export const SHIPMENT_DIMENSIONS_FAILURE = 'SHIPMENT_DIMENSIONS_FAILURE';

export const GOODS_TYPES_BEGIN = 'GOODS_TYPES_BEGIN';
export const GOODS_TYPES_SUCCESS = 'GOODS_TYPES_SUCCESS';
export const GOODS_TYPES_FAILURE = 'GOODS_TYPES_FAILURE';

export const PICKUP_BEGIN = 'PICKUP_BEGIN';
export const PICKUP_SUCCESS = 'PICKUP_SUCCESS';
export const PICKUP_FAILURE = 'PICKUP_FAILURE';

export const BOOKING_CREATION_BEGIN = 'BOOKING_CREATION_BEGIN';
export const BOOKING_CREATION_SUCCESS = 'BOOKING_CREATION_SUCCESS';
export const BOOKING_CREATION_FAILURE = 'BOOKING_CREATION_FAILURE';

export const BOOKING_EDIT_BEGIN = 'BOOKING_EDIT_BEGIN';
export const BOOKING_EDIT_SUCCESS = 'BOOKING_EDIT_SUCCESS';
export const BOOKING_EDIT_FAILURE = 'BOOKING_EDIT_FAILURE';
