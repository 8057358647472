export const GET_SUSPICIOUS_WAYBILLS_BEGIN = 'GET_SUSPICIOUS_WAYBILLS_BEGIN';
export const GET_SUSPICIOUS_WAYBILLS_SUCCESS = 'GET_SUSPICIOUS_WAYBILLS_SUCCESS';
export const GET_SUSPICIOUS_WAYBILLS_FAILURE = 'GET_SUSPICIOUS_WAYBILLS_FAILURE';

export const SEARCH_SUSPICIOUS_WAYBILL_BEGIN = 'SEARCH_SUSPICIOUS_WAYBILL_BEGIN';
export const SEARCH_SUSPICIOUS_WAYBILL_SUCCESS = 'SEARCH_SUSPICIOUS_WAYBILL_SUCCESS';
export const SEARCH_SUSPICIOUS_WAYBILL_FAILURE = 'SEARCH_SUSPICIOUS_WAYBILL_FAILURE';
// get suspicious waybill info
export const GET_SUSPICIOUS_WAYBILL_DETAILS_BEGIN = 'GET_SUSPICIOUS_WAYBILL_DETAILS_BEGIN';
export const GET_SUSPICIOUS_WAYBILL_DETAILS_SUCCESS = 'GET_SUSPICIOUS_WAYBILL_DETAILS_SUCCESS';
export const GET_SUSPICIOUS_WAYBILL_DETAILS_FAILURE = 'GET_SUSPICIOUS_WAYBILL_DETAILS_FAILURE';
