import React, { Component } from "react";
import Script from 'react-load-script';
import L from 'leaflet';
import { Map, TileLayer, LayersControl,Marker, Popup,Tooltip,Polyline } from 'react-leaflet'
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Row, Col, Icon, Spin, Button, Popover,DatePicker } from "antd";
import {createCustomPolyLineMarker} from '../../maps/CustomIcon'
import { connect } from "react-redux";
import Stepper from "./Stepper"
import * as utils from "../../../common/utils/utils";
import { getVehicleHistoricalTrackingData } from '../action';
import MapRoute from '../../maps/MapRoute';
import PolylineDecorator from '../../maps/PolylineDecorator';
import moment from 'moment';
import "./ShowDetails.scss";
import img from '../../../assets/img/spinner.svg';
import {beeconGoogleMapKey} from "../../../environments/node-react-environment";
import HeliosStepper from "./HeliosStepper";
import HeliosGoogleMapRoute from '../../maps/HeliosGoogleMapRoute';
class ShowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: '',
      startDate:new Date(),
      endDate:new Date(),
      regNo:'',
      simTrack:false,
      contractId:'',
      polyline:[]
    }
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onRefreshButtonClick = this.onRefreshButtonClick.bind(this);
    this.copyArea = React.createRef();
  }

  componentDidMount() {
    let activePath = this.props.location.pathname;
    if(activePath){
      let pathValue = activePath.split('/');
      let data = pathValue[pathValue.length -1];
      let dataSplit = data.split('-')
      console.log("the data",dataSplit)
      this.setState({
        regNo:dataSplit[1]
      });
      let simTrack = false;
      let simOnTripTrack = false;
      let OnTripTrack = false;
      let contractId = '';
      if (!utils.isEmpty(dataSplit[2]) && dataSplit[2] == 'simtrack') {
        simTrack = true
      }else if (!utils.isEmpty(dataSplit[2]) && dataSplit[2] == 'ontripsimtrack') {
        simOnTripTrack = true
      }else if (!utils.isEmpty(dataSplit[2]) && dataSplit[2] == 'ontriptrack') {
        OnTripTrack = true
      }
      if (!utils.isEmpty(dataSplit[3])) {
        contractId = dataSplit[3];
      }
      let startDate=new Date();
      let endDate=new Date();
      startDate.setHours(0,0,0,0);
      endDate.setHours(23,59,59,999);
      this.props.dispatch(getVehicleHistoricalTrackingData(dataSplit[0],'profile_organization_JFTB2QLH7793', startDate.toISOString(),endDate.toISOString(),simTrack,contractId,simOnTripTrack,OnTripTrack));
    }
  }
  onStartDateChange(date, dateString) {
    this.setState({startDate: new Date(dateString)});
    console.log("Start Date",date, dateString);
  }
  onEndDateChange(date, dateString) {
    console.log("End Date",date, dateString);
    this.setState({endDate: new Date(dateString)});
  }
  copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = this.copyArea.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.setState({ copySuccess: 'Copied!' }, ()=>{
      setTimeout(() => {
        this.setState({copySuccess: ''});
      }, 1000)
    });
  };
  googleReverseGeoCode = (lat, long, callback) => {

		/*let geocoder = new google.maps.Geocoder();
		let latlng = new google.maps.LatLng(lat, long);

		let geocodeUtil = function (attemptCount) {
			geocoder.geocode({
				"latLng": latlng
			}, geocoderResponse(attemptCount));
		}

		let geocoderResponse = function (attemptCount) {
			return function (results, status) {
				if (status == google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						callback(null, results[0]);
					} else {
						callback("Location not found", undefined);
					}
				} else if (status == google.maps.GeocoderStatus.OVER_QUERY_LIMIT && attemptCount < 0) {
					attemptCount = attemptCount + 1;
					$log.debug("Retrying geocoder due to OVER_QUERY_LIMIT", lat, long, attemptCount);
					$timeout(function () {
						geocodeUtil(attemptCount);
					}, 2 * 1000);
				} else {
					callback("Geocoder failed due to: " + status, undefined);
				}
			}
		}
		geocodeUtil(0);*/
	}
  getNextStop = (data) => {
      let nextStop;
        data.some(x => {
          let setConsignMent = x.locationBO ? "locationBO" : "consignmentContactBO";
          if(x.status === "PENDING") {
            return nextStop = x[setConsignMent]['name'];
          }
        })
      return nextStop;
  }
  commaSeperatedServerDataParser = (point) =>{
		if (typeof point == "string") {
			var pointArr = point.split(",");
			return pointArr.map(function (item, index) {
				var parsedItem = parseFloat(item);
				// do not take float value if it is string;
				// location sometimes start with number and parseFloat returns junk in that case
				// hence validate with index than just parseFloat
				return (isNaN(parsedItem) || index == (pointArr.length - 1)) ? item : parsedItem;
			});
		} else {
			return point;
		}
	}

  reloadApp = () => {
    window.location.reload();
  }
  onRefreshButtonClick = () => {
    let activePath = this.props.location.pathname;
    if(activePath){
      let pathValue = activePath.split('/');
      let data = pathValue[pathValue.length -1];
      let dataSplit = data.split('-')
      let simTrack = false;
      let contractId = '';
      if (!utils.isEmpty(dataSplit[2]) && dataSplit[2] == 'simtrack') {
        simTrack = true
      }
      if (!utils.isEmpty(dataSplit[3])) {
        contractId = dataSplit[3];
      }
      this.state.startDate.setHours(0,0,0,0);
      this.state.endDate.setHours(23,59,59,999);
      this.props.dispatch(getVehicleHistoricalTrackingData(dataSplit[0],'profile_organization_JFTB2QLH7793',this.state.startDate.toISOString(), this.state.endDate.toISOString(),simTrack,contractId));
    }
  }
  vehicleTracking = () => {
    //this.props.history.goBack()
    this.props.history.push(`/appv2/tracking/dashboard/g-map-vehicle-tracking?hold`);
    //window.location.reload();
  }

  render() {
      const {showDetailsData } = this.props;
      const position = [20.5937,78.9629];
      const today = moment();
      const defaultBounds = [[23.63936, 68.14712],[28.20453, 97.34466]]
      let startPoint;
      let endPoint;
      let simData=false;
      let onTripData=false;
      const arrow = [
        {
          offset: '100%',
          repeat: 5,
          symbol: L.Symbol.arrowHead({
            pixelSize: 15,
            polygon: false,
            pathOptions: { stroke: true }
          })
        }
      ];
      let nextStop;
      let content;
      if(showDetailsData){
        content = showDetailsData;
      }
      if (!utils.isEmpty(content) && !utils.isEmpty(content.simData)) {
        simData = content.simData;
      }
      if (!utils.isEmpty(content) && !utils.isEmpty(content.onTrip)) {
        onTripData = content.onTrip;
      }
      console.log("the show Details Data",content)
      let totalSpeed=0;
      if(!utils.isEmpty(content) && !utils.isEmpty(content.locationPoints)){
        this.state.polyline = [];
        content.locationPoints.filter(function (point) {
          return !utils.isEmpty(point) && (point.latitude > -90 && point.latitude < 90 && point.latitude != 0) && (point.longitude > -90 && point.longitude < 90 && point.longitude != 0)
        }).sort(function (a, b) {
          return a.deviceTimeEpoch - b.deviceTimeEpoch;
        });
        for (const key in content.locationPoints) {
          if (content.locationPoints.hasOwnProperty(key)) {
            const element = content.locationPoints[key];
            if (element && element.latitude && element.longitude && element.latitude !=0 && element.longitude != 0) {
              if (key == 0) {
                startPoint = element;
              }else if (key == content.locationPoints.length-1) {
                endPoint = element;
              }
              if(element.speed){
                totalSpeed+=element.speed;
              }
              this.state.polyline.push([element.latitude,element.longitude]);
            }
          }
        }
      }else{
        this.state.polyline = [];
      }
      console.log("the show Details Data startPoint",startPoint)
      console.log("the show Details Data endPoint",endPoint)
      let parsed = (content && content.idleLocationPoints && !simData) ? content.idleLocationPoints.map(this.commaSeperatedServerDataParser):[];
      let stoppagePoints = [];
      if (!simData) {
        stoppagePoints = parsed.map(function (array) {
          var startTime =array[0];
          var endTime = array[1];
          return {
            id: array[0],
            time: {
              startTime: startTime,
              endTime: endTime
            },
            gps: {
              lat: array[2],
              long: array[3],
              distance: (!isNaN(array[4]) ? array[4] : ""),
              location: isNaN(array[array.length - 1]) ? array[array.length - 1].replace(/@!@/g, ', ') : "Not Available"
            },
            fuel: {
              startLevel: array[5],
              endLevel: array[6]
            }
          }
        });
      }else{
          for (const key in content.locationPoints) {
            if (content.locationPoints.hasOwnProperty(key)) {
              const element = content.locationPoints[key];
              if (key != 0 || key != (content.locationPoints.length-1)) {
                stoppagePoints.push(element);
              }
            }
          }
      }
      console.log("the show Details Data endPoint",stoppagePoints)
      let isShowLoader = !content;
      if(isShowLoader) {
        return (<Row><div className="noData"><img className="loading" src={img} alt="Loading"/></div></Row>)
      }

    return (
        <>
        <Row>
            <Col xs={14}>
                <Row>
                  {<div className="arrowLeft"><Icon type="arrow-left" onClick={this.vehicleTracking} /></div>}
                  {!onTripData && <div className="vehicleTitle"> {this.state.regNo}</div>}
                  {/* {<div className="vehicleDes">● ETA : {}</div>} */}
                  {onTripData && showDetailsData && showDetailsData.simTracking && !utils.isEmpty(showDetailsData.response)  && <div className="vehicleTitle"> {showDetailsData.response.vehicleTrackingContractVO.vehicle.regNo.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</div>}
                  {onTripData && showDetailsData && showDetailsData.simTracking && !utils.isEmpty(showDetailsData.response) && <div className="vehicleDes">● Started at: {utils.dateFormat(new Date(showDetailsData.response.vehicleTrackingContractVO.startDate).getTime())}</div>}
                  {onTripData && showDetailsData && !showDetailsData.simTracking && !utils.isEmpty(showDetailsData.response)  &&<div className="vehicleTitle"> {showDetailsData.response.vehicle.regNo.replace(/^(.{2})(.{2})(.{2})(.*)$/, "$1 $2 $3 $4")}</div>}
                  {onTripData && showDetailsData && !showDetailsData.simTracking && !utils.isEmpty(showDetailsData.response)  && <div className="vehicleDes">● Started at: {utils.dateFormat(parseInt(showDetailsData.response.tripStart.startTime))}</div>}
                </Row>
                <Row className="pt_20">
                    <Col className="gutter-row" span={24}>
                        {!onTripData && <div className="gutter-box">
                        {showDetailsData && !utils.isEmpty(showDetailsData.response) && (showDetailsData.response.reportResponse?.length > 0 || showDetailsData.response.locationPoints?.length > 0) ?<div><HeliosGoogleMapRoute routing={showDetailsData}/></div>:
                          <div>
                              <h5 className="no-data-display">No Data to Display</h5>
                          </div>
                        }
                          {/* { !utils.isEmpty(this.state.polyline) &&
                          <Map bounds={!utils.isEmpty(this.state.polyline)?this.state.polyline:[defaultBounds]}  style={{height:600+'px'}}>
                            <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'roadmap'} />
                              {startPoint && <Marker position={this.state.polyline[0]} interactive={true} keyboard={true} icon={createCustomPolyLineMarker(0,this.state.polyline)}>
                              </Marker>}
                              {endPoint && <Marker position={this.state.polyline[this.state.polyline.length-1]} interactive={true} keyboard={true} icon={createCustomPolyLineMarker(this.state.polyline.length-1,this.state.polyline)}>
                              </Marker>}
                              {!utils.isEmpty(this.state.polyline) && <PolylineDecorator color="red" patterns={arrow} positions={this.state.polyline} />}
                              {!simData && stoppagePoints && stoppagePoints.map((x,key) => {
                                return <Marker key={key} position={[x.gps.lat,x.gps.long]} interactive={true} keyboard={true} icon={createCustomPolyLineMarker(key,[],true)}>
                                      </Marker>
                              })}
                              {simData && stoppagePoints && stoppagePoints.map((x,key) => {
                                return <Marker key={key} position={[x.latitude,x.longitude]} interactive={true} keyboard={true} icon={createCustomPolyLineMarker(key,[],true)}>
                                      </Marker>
                              })}
                          </Map>} */}
                          {/* {utils.isEmpty(this.state.polyline) && <div>
                              <h5 className="no-data-display">No Data to Display</h5>
                          </div>} */}
                        </div>}
                        {onTripData && <div className="gutter-box">
                        {showDetailsData && !utils.isEmpty(showDetailsData.response) && (showDetailsData.response.reportResponse?.length > 0 || showDetailsData.response.locationPoints?.length > 0) ?<div><HeliosGoogleMapRoute routing={showDetailsData}/></div>:
                        <div>
                              <h5 className="no-data-display">No Data to Display</h5>
                          </div>
                    }
                        </div>}
                    </Col>
                </Row>
            </Col>
            <Col xs={1}></Col>
            <Col xs={9}>
            {
            <>
            <div className="route-card">
                <div className="Route-Activity">Route Activity</div>
                <div className="divider"></div>
                {!onTripData && <>
                <div className="tripdetails">
                  <div className="detail_box">
                    <div className="start-time">Start Date</div>
                    <div className="start_date">
                        <DatePicker defaultValue={today} name="startDate" onChange={this.onStartDateChange} />
                    </div>
                  </div>
                  <div className="detail_box">
                    <div className="start-time">End Date</div>
                    <div className="start_date">
                      <DatePicker defaultValue={today} name="endDate" onChange={this.onEndDateChange} />
                    </div>
                  </div>
                  <div className="detail_box">
                    <div className="start-time"></div>
                    <Button onClick={this.onRefreshButtonClick} className="top-mar-btn" icon="reload" >Refresh</Button>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="tripdetails">
                  <div className="detail_box">
                    <div className="start-time">Total Distance Covered</div>
                    <div className="start_date">{!simData ? parseFloat(content.response?.aggDeviceOdoDistance).toFixed(2)+" Km":'N/A'}</div>
                  </div>
                  {/* {
                  <div className="detail_box">
                    <div className="start-time">Average Speed</div>
                    <div className="start_date truncate">{totalSpeed != 0 && !simData ? parseFloat(totalSpeed/this.state.polyline.length).toFixed(2)+' Km/hr':'N/A'}</div>
                  </div>
                  } */}
                </div>
                <div className="divider"></div>
                <div className="stepper">
                  {showDetailsData && !utils.isEmpty(showDetailsData.response) && (showDetailsData.response.reportResponse?.length > 0 || showDetailsData.response.locationPoints?.length > 0) && <HeliosStepper tripData={showDetailsData}></HeliosStepper>}
                  {/* {startPoint && <Row>
                      <div className="clearing">
                      <div className="left_box">
                          <div className="greencircle"></div>
                          <div className="lineset5"></div>
                      </div>
                      {!simData && <div className="right_box">
                              <div className="dateSet">{utils.dateFormatWithMin(startPoint.deviceTimeEpoch)}</div>
                              <div className="dateSet">{stoppagePoints[0] && stoppagePoints[0].gps && stoppagePoints[0].gps.location ? stoppagePoints[0].gps.location :''}</div>
                      </div>}
                      {simData && <div className="right_box">
                              <div className="dateSet">{utils.dateFormatWithMin(startPoint.deviceTimeEpoch)}</div>
                              <div className="dateSet">Location : {!utils.isEmpty(startPoint.address)?startPoint.address:'N/A'}</div>
                      </div>}
                      </div>
                  </Row>} */}
                  {/* {stoppagePoints && stoppagePoints.map((x,key) => {
                      return   <Row key={key}>
                                <div className="clearing">
                                    <div className="left_box">
                                        <div className="orangecirclebox"></div>
                                        <div className="lineset5"></div>
                                    </div>
                                    {!simData && <div className="right_box">
                                            <div className="dateSet"><span className="sno">{utils.dateFormatWithMin(x.time.startTime)}({key+1})</span></div>
                                            <div className="dateSet">Moved {parseFloat(x.gps.distance).toFixed(2)} Km to {x.gps.location}</div>
                                            <div className="dateSet">Left at {utils.dateFormatWithMin(x.time.endTime)}</div>

                                    </div>}
                                    {simData && <div className="right_box">
                                            <div className="dateSet"><span className="sno">{utils.dateFormatWithMin(x.deviceTimeEpoch)}({key+1})</span></div>
                                            <div className="dateSet">Location : {!utils.isEmpty(x.address)?x.address:'N/A'}</div>
                                    </div>}
                                </div>
                            </Row>
                    })} */}
                    {/* {endPoint && <Row>
                      <div className="clearing">
                          <div className="left_box">
                              <div className='redcircle'></div>
                              <div className="lineset2"></div>
                          </div>
                          {!simData && <div className="right_box">
                                <div className="dateSet">{utils.dateFormatWithMin(endPoint.deviceTimeEpoch)}</div>
                                <div className="dateSet">{stoppagePoints[stoppagePoints.length-1] && stoppagePoints[stoppagePoints.length-1].gps && stoppagePoints[stoppagePoints.length-1].gps.location?stoppagePoints[stoppagePoints.length-1].gps.location:''}</div>
                          </div>}
                          {simData && <div className="right_box">
                                <div className="dateSet">{utils.dateFormatWithMin(endPoint.deviceTimeEpoch)}</div>
                                <div className="dateSet">Location : {!utils.isEmpty(endPoint.address)?endPoint.address:'N/A'}</div>
                          </div>}
                      </div>
                    </Row>} */}
                </div>
                </>}
                {onTripData &&<div className="stepper">
                  {showDetailsData && !utils.isEmpty(showDetailsData.response) && (showDetailsData.response.reportResponse?.length > 0 || showDetailsData.response.locationPoints?.length > 0) && <HeliosStepper tripData={showDetailsData}></HeliosStepper>
                  }
              </div>
            }
            </div>
            </>}
            </Col>
        </Row>
        </>
    );
  }
}
//const mapStateToProps = state => {console.log("adcadsasdasd",state)}
const mapStateToProps = state => ({
    showDetailsData: state.tracking.historicvehicleTrackingData,
});
export default connect(mapStateToProps)(ShowDetails);
