import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import ZohoService from '../../../services/settings/zoho.service';
import MiscService from '../../../services/misc.service';
import { notifyApiError } from '../../../common/utils/utils';

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getZohoList = (type, isReset) => dispatch => {
    dispatch(fetchZohoListBegin());
    ZohoService.getZohoDashboardList(type).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchZohoListSuccess(res?.data?.response, isReset));
        } else {
            dispatch(fetchZohoListFailure(res?.data?.response));
        }
    });
};



function fetchZohoListBegin() {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_LIST_BEGIN
    };
}

function fetchZohoListSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_LIST_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchZohoListFailure(error) {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_LIST_FAILURE,
        payload: error
    };
}


export const updateZohoStatus = (params) => dispatch => {
    dispatch(updateZohoStatusBegin());
    ZohoService.updateZohoStatus(params).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(updateZohoStatusSuccess(res?.data));
        } else {
            dispatch(updateZohoStatusFailure(res?.data?.response));
        }
    });
};



function updateZohoStatusBegin() {
    return {
        type: _Constants.FETCH_ZOHO_STATUS_BEGIN
    };
}

function updateZohoStatusSuccess(payload) {
    console.log("pay load", payload)
    return {
        type: _Constants.FETCH_ZOHO_STATUS_SUCCESS,
        payload: payload
    };
}

function updateZohoStatusFailure(error) {
    return {
        type: _Constants.FETCH_ZOHO_STATUS_FAILURE,
        payload: error
    };
}


export const createZoho = (params) => dispatch => {
    dispatch(createZohoBegin());
    ZohoService.createZohoDashboard(params).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(createZohoSuccess(res?.data?.response));
        } else {
            dispatch(createZohoFailure(res?.data?.response));
        }
    });
};



function createZohoBegin() {
    return {
        type: _Constants.FETCH_ZOHO_CREATE_BEGIN
    };
}

function createZohoSuccess(payload) {
    console.log("pay load", payload)
    return {
        type: _Constants.FETCH_ZOHO_CREATE_SUCCESS,
        payload: payload
    };
}

function createZohoFailure(error) {
    return {
        type: _Constants.FETCH_ZOHO_CREATE_FAILURE,
        payload: error
    };
}


export const getZohoByID = (id) => dispatch => {
    dispatch(fetchZohoByIDBegin());
    ZohoService.getZohoDashboardById(id).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchZohoByIDSuccess(res?.data?.response));
        } else {
            dispatch(fetchZohoByIDFailure(res?.data?.response));
        }
    });
};



function fetchZohoByIDBegin() {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_BYID_BEGIN
    };
}

function fetchZohoByIDSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_BYID_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchZohoByIDFailure(error) {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_BYID_FAILURE,
        payload: error
    };
}

export const getZohoPermissions = (payload) => dispatch => {
    dispatch(fetchZohoPermissionsBegin());
    ZohoService.getZohoDashboardPermissions(payload).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(fetchZohoPermissionsSuccess(res?.data?.response));
        } else {
            dispatch(fetchZohoPermissionsFailure(res?.data?.response));
        }
    });
};



function fetchZohoPermissionsBegin() {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_LIST_BEGIN
    };
}

function fetchZohoPermissionsSuccess(payload) {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_LIST_SUCCESS,
        payload: payload
    };
}

function fetchZohoPermissionsFailure(error) {
    return {
        type: _Constants.FETCH_ZOHO_DASHBOARD_LIST_FAILURE,
        payload: error
    };
}



export const updateZoho = (params) => dispatch => {
    dispatch(updateZohoBegin());
    ZohoService.updateZohoDashboard(params).then(res => {
        if (checkHttpStatus(res?.data?.status)) {
            dispatch(updateZohoSuccess(res?.data?.response));
        } else {
            dispatch(updateZohoFailure(res?.data?.response));
        }
    });
};



function updateZohoBegin() {
    return {
        type: _Constants.FETCH_ZOHO_UPDATE_BEGIN
    };
}

function updateZohoSuccess(payload) {
    console.log("pay load", payload)
    return {
        type: _Constants.FETCH_ZOHO_UPDATE_SUCCESS,
        payload: payload
    };
}

function updateZohoFailure(error) {
    return {
        type: _Constants.FETCH_ZOHO_UPDATE_FAILURE,
        payload: error
    };
}