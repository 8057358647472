import * as CONSTANTS from './constants';
import * as apiUtils from '../../../common/utils/apiGateway';
import { USER_LOGGEDIN_INFO, Constants } from '../../../common/utils/constants';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';


function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const getPartnerLocatinDetailsV2 = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_PARTNER_LOCATION_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/b2b/v1/partners/${JSON.parse(localStorage.getItem(USER_LOGGEDIN_INFO.LOGGEDIN_PARTNER_ID))}/location_by_entityid`,
            params: params,
            overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_PARTNER_LOCATION_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_PARTNER_LOCATION_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const fetchCustomerLocatinDetails = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'post',
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
            data: params,
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_CUSTOMER_LOCATION_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const fetchCities = () => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_CITIES_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/b2b/v1/cities`,
            overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_CITIES_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_CITIES_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const fetchPincodes = () => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_PINCODES_FETCH_BEGIN
        });
        return apiUtils.apiRequest({
            url: `/b2b/v1/pincodes`,
            overrideHeader: Constants.HTTP_HEADERS_LOGGED_IN
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_PINCODES_FETCH_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_PINCODES_FETCH_FAILURE,
                payload: error
            }));
    }
}

export const createVendor = (params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.SET_VENDOR_CREATE_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'post',
            url: `/b2b/v2/partners/${Constants.PARTNER_ID}/vendors/create`,
            data: params,
        })
            .then(response => {
                if (checkHttpStatus(response.data?.status)) {
                    dispatch({
                        type: CONSTANTS.SET_VENDOR_CREATE_SUCCESS,
                        payload: response.data.response
                    })
                    return response.response;
                } else {
                    dispatch({
                        type: CONSTANTS.SET_VENDOR_CREATE_FAILURE                        
                    })
                }
            })
            .catch(error => dispatch({
                type: CONSTANTS.SET_VENDOR_CREATE_FAILURE,
                payload: error
            }));
    }
}


export const updateVendor = (params, id) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.SET_VENDOR_UPDATE_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'put',
            url: `/b2b/v2/partners/${Constants.PARTNER_ID}/vendors/${id}`,
            data: params,
        })
            .then(response => {
                if (checkHttpStatus(response.data?.status)) {
                    dispatch({
                        type: CONSTANTS.SET_VENDOR_UPDATE_SUCCESS,
                        payload: response.data.response
                    })
                    return response.response;
                } else {
                    dispatch({
                        type: CONSTANTS.SET_VENDOR_UPDATE_FAILURE                        
                    })
                }
            })
            .catch(error => dispatch({
                type: CONSTANTS.SET_VENDOR_UPDATE_FAILURE,
                payload: error
            }));
    }
}

export const getVendors = () => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_VENDORS_LIST_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'get',
            url: `/b2b/v1/partners/${Constants.PARTNER_ID}/vendors?fetch=STRICT_BASIC&showActualData=true`
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_VENDORS_LIST_SUCCESS,
                    payload: response.data.response
                })
                return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_VENDORS_LIST_FAILURE,
                payload: error
            }));
    }
}


export const getUnblockVendor = (vendorID, params) => {
    return dispatch => {
        dispatch({
            type: CONSTANTS.GET_VENDOR_UNBLOCK_BEGIN
        });
        return apiUtils.apiRequest({
            method: 'put',
            url: `/b2b/v1/vendors/${vendorID}/unblock`,
            data: params
        })
            .then(response => {
                dispatch({
                    type: CONSTANTS.GET_VENDOR_UNBLOCK_SUCCESS,
                    payload: response?.data?.response
                })
                // return response.response;
            })
            .catch(error => dispatch({
                type: CONSTANTS.GET_VENDOR_UNBLOCK_FAILURE,
                payload: error
            }));
    }
}
export const setInitialState = () => dispatch => {
    dispatch({
        type: CONSTANTS.SET_INITIAL_STATE
    })
}

export const getVendorData = (id) => dispatch => {
    dispatch({
        type: CONSTANTS.GET_VENDOR_DATA_BEGIN
    });
    apiUtils.apiRequest({
        method: 'get',
        url: `/b2b/v2/partners/${Constants.PARTNER_ID}/vendors/${id}?fetchMode=BASIC_WITH_IDENTITY_DOCUMENTS&fetchBankDetails=true&fetchFromCache=false`,
    })
        .then(response => {
            dispatch({
                type: CONSTANTS.GET_VENDOR_DATA_SUCCESS,
                payload: response.data.response
            })
        })
        .catch(error => dispatch({
            type: CONSTANTS.GET_VENDOR_DATA_FAILURE,
            payload: error
        }));
}