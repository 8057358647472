import React from "react";
import { Card } from "antd";

export const card = props => {
  return (
    <Card style={props.style} className={props.className}>
      {props.children}
    </Card>
  );
};
