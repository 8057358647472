
import ZoneService from '../../../services/billing/zone.service';
import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import MiscService from '../../../services/misc.service';
import { notifyApiError } from '../../../common/utils/utils';

const zoneService = new ZoneService();

function checkHttpStatus(status) {
    switch (status ?.code) {
        case 403:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status ?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status ?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchZones = (payload, isReset) => dispatch => {
    dispatch(fetchZonesBegin());
    zoneService.fetchZones(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchZonesSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchZonesFailure(res ?.data ?.response));
        }
    });
};



function fetchZonesBegin() {
    return {
        type: _Constants.FETCH_ZONES_BEGIN
    };
}

function fetchZonesSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_ZONES_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchZonesFailure(error) {
    return {
        type: _Constants.FETCH_ZONES_FAILURE,
        payload: error
    };
}

export const getAllRoutes = (payload) => dispatch => {
    dispatch(getRoutesBegin());
    zoneService.fetchRoutes(payload).then(res => {        
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(getRoutesSuccess(res.data ?.response));
        } else {
            dispatch(getRoutesFailure(res ?.data ?.response));
        }
    });
};


function getRoutesBegin() {
    return {
        type: _Constants.FETCH_ROUTES_BEGIN
    };
}

function getRoutesSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_ROUTES_SUCCESS,
        payload: payload        
    };
}

function getRoutesFailure(error) {
    return {
        type: _Constants.FETCH_ROUTES_FAILURE,
        payload: error
    };
}

export const fetchAreas = () => dispatch => {
    dispatch(fetchAreasBegin());
    MiscService.getAreas().then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchAreasSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchAreasFailure(res ?.data ?.response));
        }
    });
};

function fetchAreasBegin() {
    return {
        type: _Constants.FETCH_AREAS_BEGIN
    };
}

function fetchAreasSuccess(payload) {
    return {
        type: _Constants.FETCH_AREAS_SUCCESS,
        payload: payload
    };
}

function fetchAreasFailure(error) {
    return {
        type: _Constants.FETCH_AREAS_FAILURE,
        payload: error
    };
}

export const getZone = (id) => dispatch => {
    dispatch(fetchZoneBegin());
    zoneService.getZone(id).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(fetchZoneSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchZoneFailure(res ?.data ?.response));
        }
    });
};


export const resetZone = () => dispatch => {
    dispatch(
        {
            "type": _Constants.RESET_ZONE_SUCCESS
        }
    )    
};

function fetchZoneBegin() {
    return {
        type: _Constants.FETCH_ZONE_BEGIN
    };
}

function fetchZoneSuccess(payload) {
    return {
        type: _Constants.FETCH_ZONE_SUCCESS,
        payload: payload
    };
}

function fetchZoneFailure(error) {
    return {
        type: _Constants.FETCH_ZONE_FAILURE,
        payload: error
    };
}

export const createZone = (payload) => dispatch => {
    dispatch(zoneModifyBegin());
    zoneService.createZone(payload).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(zoneModifySuccess(res ?.data ?.response));
        } else {
            dispatch(zoneModifyFailure(res ?.data ?.response));
        }
    });
}
    ;
export const updateZone = (id, payload, bool) => dispatch => {
    dispatch(zoneModifyBegin());
    zoneService.updateZone(id, payload, bool).then(res => {
        if (checkHttpStatus(res ?.data ?.status)) {
            dispatch(zoneModifySuccess(res ?.data ?.response));
        } else {
            dispatch(zoneModifyFailure(res ?.data ?.response));
        }
    });
};

function zoneModifyBegin() {
    return {
        type: _Constants.MODIFY_ZONE_BEGIN
    };
}

function zoneModifySuccess(payload) {
    return {
        type: _Constants.MODIFY_ZONE_SUCCESS,
        payload: payload
    };
}

function zoneModifyFailure(error) {
    return {
        type: _Constants.MODIFY_ZONE_FAILURE,
        payload: error
    };
}