export const initialState = {
    loading: false
}

export const FETCH_ZONES_BEGIN = "FETCH_ZONES_BEGIN";
export const FETCH_ZONES_SUCCESS = "FETCH_ZONES_SUCCESS";
export const FETCH_ZONES_FAILURE = "FETCH_ZONES_FAILURE";

export const FETCH_AREAS_BEGIN = "FETCH_AREAS_BEGIN";
export const FETCH_AREAS_SUCCESS = "FETCH_AREAS_SUCCESS";
export const FETCH_AREAS_FAILURE = "FETCH_AREAS_FAILURE";