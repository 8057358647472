import React from 'react';
import { card as Card } from '../../../../components/ui/card/Card';
// import { form as Form } from '../../../../components/ui/form/Form';
import { Form, Divider } from 'antd';
import { input as Input, InputApi } from '../../../../components/ui/input/input';
import { button as Button } from '../../../../components/ui/button/Button';
import { select as Select } from '../../../../components/ui/select/Select'
import './MarkDelUndelForm.scss';
const Option = Select.Option;
const { TextArea } = InputApi;

const appMarkDelUndelForm = (props) => {
    if (!props.display) {
        return null;
    }
    const { getFieldDecorator } = props.form;
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
            // update consignment with selected reasons
            if (values.reason && values.remarks) {
                if (props.valid) {
                    values.reason = props.selectedReasonId;
                }
                props.onSubmit(values, props.valid);
                props.onCancel();
            }
        });

    }
    let optionsHtml;
    let selectedReasonDescription;
    if (props.valid) {
        const reason = props.selectOptions.find(option => {
            return option.reasonId === props.selectedReasonId;
        });
        if (reason) {
            optionsHtml = (<Option value={reason.reasonId} key={reason.reasonId} > {reason.reasonDescription}</Option >);
            selectedReasonDescription = reason.reasonDescription;
        }
    } else {
        optionsHtml = props ?.selectOptions ?.map(option => {
            return (<Option value={option.reasonId} key={option.reasonId} > {option.reasonDescription}</Option >)
        })
    }
    return (
        < Card className="app-popup" >
            <h2>Mark as {props.valid ? 'Valid' : 'Invalid'}</h2>
            <Divider />
            <Form onSubmit={handleSubmit}>
                <p className="input-label">Reason</p>
                <Form.Item>
                    {getFieldDecorator('reason', {
                        initialValue: selectedReasonDescription,
                        rules: [{ required: true, message: 'Please input your reason!' }],
                    })(
                        <Select defaultValue={props.selectedReasonId} placeholder="Select Reason">
                            {optionsHtml}
                        </Select>

                    )}
                </Form.Item>
                <Form.Item>
                    <p className="input-label">Remarks (Mandatory)</p>
                    {getFieldDecorator('remarks', {
                        rules: [{ required: true, message: 'Please input your Remarks!' }],
                    })(
                        <TextArea rows={4} placeholder="Provide your final remark here." />
                    )}
                </Form.Item>
                <Button className="btn" onClick={props.onCancel}>Cancel</Button>
                <Button htmlType="submit" className={props.valid ? 'btn-success' : 'btn-danger'}>Confirm</Button>
            </Form>
        </Card >
    )
};
export const MarkDelUndelForm = Form.create({ name: 'delUndel_form' })(appMarkDelUndelForm);
