
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const CustomerReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_CUSTOMERS_BEGIN: return fetchCustomersBegin(state);
        case _Constants.FETCH_CUSTOMERS_SUCCESS: return fetchCustomersSuccess(state, action);
        case _Constants.FETCH_CUSTOMERS_FAILURE: return fetchCustomersFailure(state, action);

        case _Constants.FETCH_SHIPPER_BEGIN: return fetchShippersBegin(state);
        case _Constants.FETCH_SHIPPER_SUCCESS: return fetchShippersSuccess(state, action);
        case _Constants.FETCH_SHIPPER_FAILURE: return fetchShippersFailure(state, action);


        case _Constants.FETCH_CUSTOMER_BEGIN: return fetchCustomerBegin(state);
        case _Constants.FETCH_CUSTOMER_SUCCESS: return fetchCustomerSuccess(state, action);
        case _Constants.FETCH_CUSTOMER_FAILURE: return fetchCustomerFailure(state, action);
        case _Constants.FETCH_ORGANISATION_BEGIN: return fetchOrganitionBegin(state);
        case _Constants.FETCH_ORGANISATION_SUCCESS: return fetchOrganitionSuccess(state, action);
        case _Constants.FETCH_ORGANISATION_FAILURE: return fetchOrganitionFailure(state, action);
        case _Constants.CUSTOMER_MODIFICATION_BEGIN: return customerModificationBegin(state);
        case _Constants.CUSTOMER_MODIFICATION_SUCCESS: return customerModificationSuccess(state, action);
        case _Constants.CUSTOMER_MODIFICATION_FAILURE: return customerModificationFailure(state, action);
        case _Constants.GET_ACCESS_KEYS_BEGIN: return getAccessKeysBegin(state);
        case _Constants.GET_ACCESS_KEYS_SUCCESS: return getAccessKeysSuccess(state, action);
        case _Constants.GET_ACCESS_KEYS_FAILURE: return getAccessKeysFailure(state, action);
        case _Constants.GET_RESET_ACCESS_KEYS: return getResetAccessKeys(state, action);
        case _Constants.GET_HOPINCODE_BEGIN:
            return {
                ...state,
                hoPincode: {
                    loading: true,
                }
            };
        case _Constants.GET_HOPINCODE_SUCCESS:
            return {
                ...state,
                hoPincode: {
                    loading: false,
                    ...action.payload
                }
            };
        case _Constants.GET_HOPINCODE_FAILURE:
            return {
                ...state,
                hoPincode: {
                    loading: false,
                    ...action.payload
                }
            }
        case _Constants.GET_BPINCODE_BEGIN:
            return {
                ...state,
                BPincode: {
                    loading: true,
                }
            };
        case _Constants.GET_BPINCODE_SUCCESS:
            return {
                ...state,
                BPincode: {
                    loading: false,
                    ...action.payload
                }
            };
        case _Constants.GET_BPINCODE_FAILURE:
            return {
                ...state,
                BPincode: {
                    loading: false,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}


function fetchCustomersBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchCustomersSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedCustomers: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedCustomers ? state.PaginatedCustomers : {}, action ?.payload ?.customers , action.isReset),
        ...action.payload
    };
}

function fetchCustomersFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        customers: []
    };
}


function fetchShippersBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchShippersSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedShippers: action.payload?.entityShipperMappingBOList,
        // PaginatedShippers: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedCustomers ? state.PaginatedCustomers : {}, action ?.payload ?.mappingBOs , action.isReset),
        ...action.payload
    };
}

function fetchShippersFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        customers: []
    };
}

function fetchCustomerBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function fetchCustomerSuccess(state, action) {
    return {
        ...state,
        loading: false,
        selectedCustomer: action.payload
    };
}

function fetchCustomerFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        selectedCustomer: []
    };
}


function getAccessKeysBegin(state, action) {
    return {
        ...state,
        accessKeysLoading: true
    };
}

function getAccessKeysSuccess(state, action) {
    return {
        ...state,
        accessKeysLoading: false,
        accessKeys: action.payload
    };
}

function getAccessKeysFailure(state, action) {
    return {
        ...state,
        accessKeysLoading: false,
        error: action.payload,        
    };
}

function getResetAccessKeys(state, action) {
    return {
        ...state,
        accessKeys: undefined
    }
}

function fetchOrganitionBegin(state, action) {
    return {
        ...state
    };
}

function fetchOrganitionSuccess(state, action) {
    return {
        ...state,
        ...action.payload
    };
}

function fetchOrganitionFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        organition: []
    };
}

function customerModificationBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function customerModificationSuccess(state, action) {
    return {
        ...state,
        loading: false,
        customer: { ...action.payload }
    };
}

function customerModificationFailure(state, action) {
    return {
        ...state,
        loading: false,
        customer: {},
        error: action.payload
    };
}