import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetList from './Asset.list';
import * as assetActions from './action';
import { Constants } from '../../../common/utils/constants';
import { Spin } from 'antd';

class Asset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assets: [],
            isSearch: false,
            loading: false,
            page: 1,
            setAssigned: false,
            locationParams: {
                "filters": {                    
                    "status": true,
                    "isConsiderCreatorId": false,
                    "fetchObjects": [],
                    "entityTypes": ["PARTNER"],
                    "entityIds": [Constants.PARTNER_ID]
                }
            }

        }        

        this.searchAssets = this.searchAssets.bind(this);
    }

    componentDidMount() {
        let filters = {
            filters: {
                pageNo: this.state.page,
                pageSize: 10,
                totalCount: 0,
                fetchObjects: ['ASSET_LOCATION'],
                partnerIds: [Constants.PARTNER_ID],
                
            }


        }
        this.setState({ loading: true });
        this.props.getAssets(filters);
        // this.props.getLocations();
    }

    componentDidUpdate(oldProps) {        
        if (!this.state.isSearch &&  !this.state.setAssigned &&
             (this.state.assets?.length <= 0 && this.props.assetsList?.assets) 
            || oldProps?.assetsList?.assets && oldProps?.assetsList?.currentPageNo != this.props?.assetsList?.currentPageNo) {
            if(this.props?.assetsList?.assets) {
                this.setState({ assets: this.props?.assetsList?.assets, loading: false, setAssigned: true })
            }
            
        }
        
    }

    searchAssets(searchQuery) {
        // const { assetsList } = { ...this.props };
        // console.log("assets->>", assetsList)
        if (searchQuery) {
            let filters = {
                filters: {

                    totalCount: 0,
                    fetchObjects: ['ASSET_LOCATION'],
                    partnerIds: [Constants.PARTNER_ID],
                    partialContentSearch: searchQuery
                }


            }
            this.props.getAssets(filters);
            this.setState({ isSearch: true, loading: true });
        } else {
            //    this.refreshList(1, 10);
            this.setState({ isSearch: false, assets: [], setAssigned: false }, () => {
                this.refreshList(this.state.page, 10);
            });
        }

        // let assets = assetsList.assets.filter((el) => {
        //     console.log("el->>", el)
        //     let searchValue = el.vehicleNumber;                        
        //     return searchValue.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        // });



        // this.setState({ assets, isSearch: true });
    }

    refreshList = (page, pageSize) => {
        this.setState({ isSearch: false, assets: [], page, loading: true }, () => {
            let filters = {
                filters: {
                    pageNo: page,
                    pageSize: pageSize,
                    totalCount: 0,
                    fetchObjects: ['ASSET_LOCATION'],
                    partnerIds: [Constants.PARTNER_ID]
                }


            }
            this.props.getAssets(filters);
        });

    }

    getLocationsList = () => {
        this.props.getAssetLocations(this.state.locationParams);
    }

    submitLocation = (params) => {
        this.setState({loading: true});
        this.props.updateLocation(params);

    }

    render() {
       

        return (
            <>
                <Spin spinning={this.props.assetLoading}>
                    <AssetList  {...this.props} page={this.state.page} getLocationsList={this.getLocationsList} submitLocation={this.submitLocation} refreshList={this.refreshList} assets={this.props?.assetsList?.assets} searchAssets={this.searchAssets} />
                </Spin>
            </>
        )
    }
}

const mapStateToProps = state => ({
    assetsList: state.assets?.assetsList,
    assetLoading: state.assets?.assetslistloading,
    locationsList: state?.assets?.locationsList,
    locationslistloading: state?.assets?.locationslistloading,
    assetLocation: state?.assets?.assetLocation,
    assetLocationUpdateLoading: state?.assets?.assetLocationUpdateLoading,
    assetLocationslistloading: state?.assets?.assetLocationslistloading,
    assetLocationsList: state?.assets?.assetLocationsList,
});

const mapDispatchToProps = dispatch => ({
    getAssets: (filters) => dispatch(assetActions.getAssets(filters)),
    getLocations: () => dispatch(assetActions.getLocations()),
    updateLocation: (params) => dispatch(assetActions.updateLocation(params)),
    getAssetLocations: (params) => dispatch(assetActions.getAssetLocations(params)),
    resetLocation: () => dispatch(assetActions.resetLocation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Asset);