import { apiRequest } from "../../common/utils/apiGateway";
import { Constants } from '../../common/utils/constants';

const ManageFleetService = {
    feSummaryFetch: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/USER/field/summary`,
            method: "POST",
            data: payload
        })
    },

    cityList: () => {
        return apiRequest({
            url: `/b2b/v1/cities?view=LITE_CITY_LIST_WITH_STATE_NAMES`,
            method: "GET",
        })
    },

    cityListbyId: (cityId) => {
        return apiRequest({
            url: `/b2b/v2/city/${cityId}/locations?fetchObject=LOCATION_ENTITY`,
            method: "GET",
        })
    },

    feSummaryPaginationFetch: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/USER/field/summaryData`,
            method: "POST",
            data: payload
        })
    },

    kycSummaryFetch: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/kyc/summary`,
            method: "POST",
            data: payload
        })
    },

    kycSummaryPaginationFetch: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/kyc/summaryData`,
            method: "POST",
            data: payload
        })
    },

    getUserDetails: (userId) => {
        return apiRequest({
            url: `/b2b/v2/kyc/fetch/USER/${userId}`,
            method: "GET",
        })
    },

    userApproval: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v2/kyc/update`,
            method: "POST",
            data: payload
        })
    },

    getonDutyUsers: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/natr/onDutyCustomers`,
            method: "POST",
            data: payload
        })
    },

    getTemplates: () => {
        return apiRequest({
            url: `/communication/v1/pushNotification/template/fetch`,
            method: "GET",
        })
    },

    getRunsheetList: (payload = {}) => {
        return apiRequest({
            url: `/b2c/v2/runsheet/list`,
            method: "POST",
            data: payload
        })
    },

    getRunsheetDetail: (enitiyId) => {
        return apiRequest({
            url: `/b2c/v1/runsheet/${enitiyId}/DRS/details?fetchHypertrackTrips=true`,
            method: "GET",
        })
    },

    createTemplate: (payload = {}) => {
        return apiRequest({
            url: `/communication/v1/pushNotification/template/create`,
            method: "POST",
            data: payload
        })
    },

    sendPushNotification: (payload = {}) => {
        return apiRequest({
            url: `/communication/v1/pushNotification/send`,
            method: "POST",
            data: payload
        })
    },

    callDropDownApi_v2 : (endpoint) =>{
        if(endpoint.method === "POST"){
            return apiRequest({
                url: endpoint.url,
                method: endpoint.method,
                data: endpoint.payload,
            })
        } else {
            return apiRequest({
                url: endpoint.url,
                method: endpoint.method,
            })
        }
    },

    getAllFeSummarypagination: (payload = {}) => {
        return apiRequest({
            url: `/b2b/v1/USER/field/summaryData`,
            method: "POST",
            data: payload
        })
    },
}

export default ManageFleetService