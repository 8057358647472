import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const ZoneReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_ZONES_BEGIN: return fetchZonesBegin(state);
        case _Constants.FETCH_ZONES_SUCCESS: return fetchZonesSuccess(state, action);
        case _Constants.FETCH_ZONES_FAILURE: return fetchZonesFailure(state, action);
        case _Constants.FETCH_ROUTES_BEGIN: return fetchRoutesBegin(state);
        case _Constants.FETCH_ROUTES_SUCCESS: return fetchRoutesSuccess(state, action);
        case _Constants.FETCH_ROUTES_FAILURE: return fetchRoutesFailure(state, action);
        case _Constants.FETCH_ZONE_BEGIN: return fetchZoneBegin(state);
        case _Constants.FETCH_ZONE_SUCCESS: return fetchZoneSuccess(state, action);
        case _Constants.FETCH_ZONE_FAILURE: return fetchZoneFailure(state, action);
        case _Constants.MODIFY_ZONE_BEGIN: return modifyZoneBegin(state);
        case _Constants.MODIFY_ZONE_SUCCESS: return modifyZoneSuccess(state, action);
        case _Constants.MODIFY_ZONE_FAILURE: return modifyZoneFailure(state, action);

        case _Constants.RESET_ZONE_SUCCESS: return resetZone(state, action);
        default:
            return state;
    }
}

function fetchZonesBegin(state) {
    return {
        ...state,
        loading: true
    };
}

function fetchZonesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedZones: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedZones ? state.PaginatedZones : {}, action ?.payload ?.zones , action.isReset),
        ...action.payload
    };
}

function fetchZonesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}



function fetchRoutesBegin(state) {
    return {
        ...state,
        loading: true
    };
}

function fetchRoutesSuccess(state, action) {    
    return {
        ...state,
        loading: false,
        routes: action.payload,
        ...action.payload
    };
}

function fetchRoutesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

function fetchZoneBegin(state) {
    return {
        ...state,
        loading: true,
        zone:{}
    };
}

function fetchZoneSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function fetchZoneFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

function resetZone(state, action) {
    return {
        ...state,
        zone: undefined
    }
}

function modifyZoneBegin(state) {
    return {
        ...state,
        loading: true
    };
}

function modifyZoneSuccess(state, action) {
    console.log("zone->>",action)
    return {
        ...state,
        zoneUpdateResp: {
            loading: false,
            ...action.payload
        }
    };
}

function modifyZoneFailure(state, action) {
    return {
        ...state,
        zoneUpdateResp: {
            loading: false,
            error: action.payload,
        }
    };
}