import L from 'leaflet';
import redTruckIcon from '../../assets/mapicons/truck-red.svg';
import greenTruckIcon from '../../assets/mapicons/truck-green.svg';
import truckDefaultIcon from '../../assets/mapicons/truck.svg';
import stopIcon from '../../assets/mapicons/stop.png';
import shopIcon from '../../assets/mapicons/start.png';
import endIcon from '../../assets/mapicons/finish.png';
import partnerIcon from '../../assets/mapicons/shop.png';
import customerIcon from '../../assets/mapicons/customer.png';
//import truckIconSvgHtml from '../../assets/mapicons/truck.svg.html';
function getFormattedAngle(angle) { return (angle - 90 || 0) };
function getMarkerIconClass(status) {
  if (status == 'ON') {
    return 'svg-green'
  } else if (status =='OFF') {
    return 'svg-red'
  }
};
function getMarkerIconClassHelios(status) {
  if (status) {
    return 'svg-green'
  } else if (!status) {
    return 'svg-red'
  }
};
function getMarginBetweenLabelAndIcon(angle) {
  var formattedAngleAbs = Math.abs(angle);
  var formattedAngleMod = formattedAngleAbs % 180;
  formattedAngleMod = ((formattedAngleMod > 90) ? (180 - formattedAngleMod) : (formattedAngleMod));
  if (formattedAngleMod >= 0 && formattedAngleMod <= 30) {
    return -10;
  } else if (formattedAngleMod > 30 && formattedAngleMod <= 60) {
    return -5;
  } else {
    return 0;
  }
}
export const carIcon = new L.Icon({
    iconUrl: require('../../assets/mapicons/car.svg'),
    iconRetinaUrl: require('../../assets/mapicons/car.svg'),
    iconAnchor: [0, 0],
    popupAnchor: [0, 0],
    iconSize: [35, 55],
});
export const truckIcon = new L.Icon({
iconUrl: require('../../assets/mapicons/truck.svg'),
iconRetinaUrl: require('../../assets/mapicons/truck.svg'),
iconAnchor: [20, 40],
popupAnchor: [0, -35],
iconSize: [40, 40],
shadowUrl: require('../../assets/mapicons/marker-shadow.png'),
shadowSize: [0, 0],
shadowAnchor: [7, 40],
});
export const createCustomMarkerHtml =(angle,status,label)=>{
  let innerHTML = "";
  // console.log("getMarkerIconClass", getMarkerIconClass(vehicle));
  innerHTML = innerHTML + "<div class=\"leaflet-marker-icon-wrapper\">";
  innerHTML = innerHTML + "<div name=\"icon-el\" style=\"transform: rotate(" + getFormattedAngle(angle) + "deg); width: 35px\" class=\"" + getMarkerIconClass(status) + "\">";
  innerHTML = innerHTML + `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 844 480" style="enable-background:new 0 0 844 480;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#191919;fill-opacity:0.9961;enable-background:new    ;}
	.st1{fill-opacity:0.9961;stroke:#191919;stroke-width:6;}
	.st2{fill:#262626;}
	.st3{fill:#191919;stroke:#262626;stroke-width:5;}
	.st4{fill:#262626;fill-opacity:0.9961;stroke:#191919;stroke-width:5;enable-background:new    ;}
	.st5{opacity:0.5;fill:none;stroke:#292929;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;enable-background:new    ;}
	.st6{fill:#FFFFFF;}
</style>
<g id="Layer_3">
	<path class="st0" d="M560.2,97.1H45.7c-9.7,0-17.7,6.4-17.7,14.2V240v128.8c0,7.8,7.9,14.2,17.7,14.2h514.6
		c9.7,0,17.7-6.4,17.7-14.2V240V111.2C577.9,103.4,569.9,97.1,560.2,97.1z"/>
	<path class="st1" d="M766,108.1c-58.7,0-188.1,6.3-188.1,6.3V240v125.6c0,0,129.4,6.3,188.1,6.3S821.3,240,821.3,240
		S824.7,108.1,766,108.1z"/>
	<path class="st2" d="M658,122.2c0,0-16.2,18.9-47,18.9c0,0-23.7,1.2-23.7-15.3L658,122.2z"/>
	<path class="st3" d="M670.7,123.1l13.3-4c0,0-4-42.7-22.7-37.3L670.7,123.1z"/>
	<path class="st2" d="M658,357.8c0,0-16.2-18.9-47-18.9c0,0-23.7-1.2-23.7,15.3L658,357.8z"/>
	<path class="st3" d="M670.7,356.9l13.3,4c0,0-4,42.7-22.7,37.3L670.7,356.9z"/>
	<path class="st4" d="M688.5,134.9c0,0-69.5,12-67,17s5.5,88,5.5,88s-3,83-5.5,88c-2.5,5,67,17,67,17s18-48,18-105
		C706.5,182.9,688.5,134.9,688.5,134.9z"/>
	<g id="Layer_4">
		<rect x="255.1" y="110.4" width="101.3" height="254.7"/>
		<rect x="372.2" y="110.7" width="87.6" height="254.7"/>
		<rect x="151.7" y="110.7" width="87.6" height="254.7"/>
		<path d="M135.9,365.5H62.5c-7.8,0-14.2-6.3-14.2-14.2V124.9c0-7.8,6.3-14.2,14.2-14.2h73.4V365.5z"/>
		<path d="M475.6,110.7H549c7.8,0,14.2,6.3,14.2,14.2v226.4c0,7.8-6.3,14.2-14.2,14.2h-73.4V110.7z"/>
	</g>
</g>
<g id="Layer_5">
	<line class="st5" x1="713.5" y1="175.9" x2="802" y2="167.4"/>
	<line class="st5" x1="713.5" y1="303.9" x2="802" y2="312.4"/>
</g>
<g id="Layer_7">
	<g>
		<path class="st6" d="M451.2,258.7l-195.5,89.6v-43.2l151.1-64.8v-0.8l-151.1-64.8v-43.2l195.5,89.6V258.7z"/>
	</g>
</g>
</svg>`;
  innerHTML = innerHTML + "</div>";
  innerHTML = innerHTML + "<span name=\"label-el\" class=\"map-label-with-text-shadow\" style=\"visibility: " +''+ "; margin-top: " + getMarginBetweenLabelAndIcon(angle) + "px;\"><b>" +label.slice(label.length - 4)+ "</b>"+ "</span>";
  innerHTML = innerHTML + "</div>";
  return new L.divIcon({
    className: "",
    html:innerHTML
  });
}
export const createCustomMarkerHtmlHelios =(angle,status,label)=>{
  let innerHTML = "";
  // console.log("getMarkerIconClass", getMarkerIconClass(vehicle));
  innerHTML = innerHTML + "<div class=\"leaflet-marker-icon-wrapper\">";
  innerHTML = innerHTML + "<div name=\"icon-el\" style=\"transform: rotate(" + getFormattedAngle(angle) + "deg); width: 35px\" class=\"" + getMarkerIconClassHelios(status) + "\">";
  innerHTML = innerHTML + `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 844 480" style="enable-background:new 0 0 844 480;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#191919;fill-opacity:0.9961;enable-background:new    ;}
	.st1{fill-opacity:0.9961;stroke:#191919;stroke-width:6;}
	.st2{fill:#262626;}
	.st3{fill:#191919;stroke:#262626;stroke-width:5;}
	.st4{fill:#262626;fill-opacity:0.9961;stroke:#191919;stroke-width:5;enable-background:new    ;}
	.st5{opacity:0.5;fill:none;stroke:#292929;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;enable-background:new    ;}
	.st6{fill:#FFFFFF;}
</style>
<g id="Layer_3">
	<path class="st0" d="M560.2,97.1H45.7c-9.7,0-17.7,6.4-17.7,14.2V240v128.8c0,7.8,7.9,14.2,17.7,14.2h514.6
		c9.7,0,17.7-6.4,17.7-14.2V240V111.2C577.9,103.4,569.9,97.1,560.2,97.1z"/>
	<path class="st1" d="M766,108.1c-58.7,0-188.1,6.3-188.1,6.3V240v125.6c0,0,129.4,6.3,188.1,6.3S821.3,240,821.3,240
		S824.7,108.1,766,108.1z"/>
	<path class="st2" d="M658,122.2c0,0-16.2,18.9-47,18.9c0,0-23.7,1.2-23.7-15.3L658,122.2z"/>
	<path class="st3" d="M670.7,123.1l13.3-4c0,0-4-42.7-22.7-37.3L670.7,123.1z"/>
	<path class="st2" d="M658,357.8c0,0-16.2-18.9-47-18.9c0,0-23.7-1.2-23.7,15.3L658,357.8z"/>
	<path class="st3" d="M670.7,356.9l13.3,4c0,0-4,42.7-22.7,37.3L670.7,356.9z"/>
	<path class="st4" d="M688.5,134.9c0,0-69.5,12-67,17s5.5,88,5.5,88s-3,83-5.5,88c-2.5,5,67,17,67,17s18-48,18-105
		C706.5,182.9,688.5,134.9,688.5,134.9z"/>
	<g id="Layer_4">
		<rect x="255.1" y="110.4" width="101.3" height="254.7"/>
		<rect x="372.2" y="110.7" width="87.6" height="254.7"/>
		<rect x="151.7" y="110.7" width="87.6" height="254.7"/>
		<path d="M135.9,365.5H62.5c-7.8,0-14.2-6.3-14.2-14.2V124.9c0-7.8,6.3-14.2,14.2-14.2h73.4V365.5z"/>
		<path d="M475.6,110.7H549c7.8,0,14.2,6.3,14.2,14.2v226.4c0,7.8-6.3,14.2-14.2,14.2h-73.4V110.7z"/>
	</g>
</g>
<g id="Layer_5">
	<line class="st5" x1="713.5" y1="175.9" x2="802" y2="167.4"/>
	<line class="st5" x1="713.5" y1="303.9" x2="802" y2="312.4"/>
</g>
<g id="Layer_7">
	<g>
		<path class="st6" d="M451.2,258.7l-195.5,89.6v-43.2l151.1-64.8v-0.8l-151.1-64.8v-43.2l195.5,89.6V258.7z"/>
	</g>
</g>
</svg>`;
  innerHTML = innerHTML + "</div>";
  innerHTML = innerHTML + "<span name=\"label-el\" class=\"map-label-with-text-shadow\" style=\"visibility: " +''+ "; margin-top: " + getMarginBetweenLabelAndIcon(angle) + "px;\"><b>" +label.slice(label.length - 4)+ "</b>"+ "</span>";
  innerHTML = innerHTML + "</div>";
  return new L.divIcon({
    className: "",
    html:innerHTML
  });
}
export const createCustomMarker =(angle,status,label)=>{
  let icon = truckDefaultIcon;
  if (status == 'ON') {
      icon = greenTruckIcon;
  } else if (status =='OFF') {
    icon = redTruckIcon;
  }
  return new L.divIcon({
    className: "truck-icon1",
    iconSize: [35, 42],
    iconAnchor: [0, 0],
    popupAnchor: [0, 0],
    html: `<div>
              <img style='transform: rotate(${getFormattedAngle(angle)}deg);' src='${icon}' />
              <span class='map-label'>${label.slice(label.length - 4)}</span>
          </div>`
  });
}
export const createCustomPolyLineMarker =(key,data,stoppage)=>{
  let icon = stopIcon;
  let width = 20;
  let height = 20;
  if (key == 0) {
    icon =shopIcon;
  }else if (key == data.length-1) {
    icon =endIcon;

  }
  return new L.divIcon({
    className: "truck-icon",
    html: `<div>
              <img src='${icon}' />
              <label>${stoppage?key+1:''}</label>
          </div>`
  });
}
export const createPartnerMarker =()=>{
  let width = 20;
  let height = 20;
  return new L.divIcon({
    className: "truck-icon",
    html: `<div class="partner-marker">
              <img src='${partnerIcon}' />
          </div>`
  });
}
export const createCustomerMarker =()=>{
  let width = 20;
  let height = 20;
  return new L.divIcon({
    className: "truck-icon",
    html: `<div class="partner-marker">
              <img src='${customerIcon}' />
          </div>`
  });
}
export const greenMarkerIcon = new L.Icon({
  iconUrl: require('../../assets/img/icons/greenMarker.svg'),
  iconRetinaUrl: require('../../assets/img/icons/greenMarker.svg'),
  iconSize: [30, 30],
    // iconAnchor: [10, 20],
    className: `u-turn-icon`
});

export const redMarkerIcon = new L.Icon({
  iconUrl: require('../../assets/img/icons/redMarker.svg'),
  iconRetinaUrl: require('../../assets/img/icons/redMarker.svg'),
  iconSize: [30, 30],
    // iconAnchor: [10, 20],
    className: `u-turn-icon`
});

export const blueMarkerIcon = new L.Icon({
  iconUrl: require('../../assets/img/icons/blueMarker.svg'),
  iconRetinaUrl: require('../../assets/img/icons/blueMarker.svg'),
  iconSize: [30, 30],
    // iconAnchor: [10, 20],
    className: `u-turn-icon`
});

export const orangeMarkerIcon = new L.Icon({
  iconUrl: require('../../assets/img/icons/orangeMarker.svg'),
  iconRetinaUrl: require('../../assets/img/icons/orangeMarker.svg'),
  iconSize: [30, 30],
  // iconAnchor: [10, 20],
    className: `u-turn-icon`
});
