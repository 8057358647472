import { fetch, fetch_telematic_data_from_beecon, fetch_sim_data_from_beecon, fetch_data_for_helios_post } from "../../utils/httpUtil";
import {
  apiUrl,
  beeconEntityURL,
  beeconTransactionURL
} from "../../environments/node-react-environment";

export function getTrackingValues(payload) {
  let url = `b2b/v1/vehicleTracker/get?partnerIds=${payload.partnerIds}`;
  if (payload.customerIds) {
    url += `&customerIds=${payload.customerIds}`;
  }
  return fetch(apiUrl, url);
}

export function getRouteValues(tripID) {
  return fetch(apiUrl, `b2b/v1/thcRouteMapping/${tripID}`);
}

export function getTelematicTrackingValues() {
  const data = {
    action: "getOrgTelematicVehicles",
    bo: {
      id: "bo_trade_fleet_management"
    },
    data: {
      type: "profile",
      org: {
        id: "profile_organization_K5P102MC8189"
      },
      subtype: "vehicle"
    },
    resolveDeps: false
  };
  return fetch_telematic_data_from_beecon(apiUrl + "b2b/v1/vehicle/telematic", data);
}
export function getOnTripTrackingValues(partnerIds) {
  console.log("the partner id is", partnerIds)
  const data = {
    action: "getOrgTelematicVehicles",
    bo: {
      id: "bo_trade_fleet_management"
    },
    data: {
      type: "profile",
      org: {
        id: "profile_organization_K5P102MC8189"
      },
      subtype: "vehicle"
    },
    resolveDeps: false
  };
  return fetch(apiUrl, "b2b/v1/vehicleTracker/v2/get?partnerIds=4");
}
export function getSimTrackingValues() {
  const data = {
    "action": "getVehicleTrackingReport",
    "bo": {
      "id": "bo_contract_vehicle_tracking",
      "filters": [{
        "displayName": "Vehicle",
        "mapkey": "id",
        "name": "vehicle.id",
        "operator": "in",
        "type": "reportFilter",
        "value": "",
        "valueType": "string"
      }, {
        "displayName": "mobileNumber",
        "name": "vehicle.telematicData.imei",
        "operator": "equals",
        "type": "reportFilter",
        "value": "",
        "valueType": "string"
      }, {
        "displayName": "status",
        "mapkey": "value",
        "name": "status",
        "operator": "in",
        "type": "reportFilter",
        "value": ["location_polling"],
        "valueType": "string"
      }]
    },
    "data": {
      "type": "contract",
      "subtype": "vehicleTracking",
      "org": {
        "id": "profile_organization_K5P102MC8189"
      }
    },
    "resolveDeps": false
  }
  return fetch_sim_data_from_beecon(apiUrl + "b2b/v1/vehicle/tracking/report", data);
}

export function getVehicleHistoricTrackingData(vehicleId, orgId, startTime, endTime) {
  const data = {
    action: "getTripRouteDetails",
    data: {
      type: "contract",
      subtype: "trip",
      vehicle: { id: vehicleId },
      org: { id: "profile_organization_K5P102MC8189" },
      tripStart: { startTime: startTime },
      tripClosure: { endTime: endTime }
    },
    timeOffset: 330,
    resolveDeps: false
  };
  return fetch_telematic_data_from_beecon(apiUrl + "b2b/v1/vehicle/trip/route/details", data);
}
export function getTripTrackingData(tripID) {
  const data = {
  };
  return fetch(apiUrl, `b2b/v1/thcRouteMapping/v2/${Number(tripID)}`);
}
export function getPartnerMappedLocationData(partnerID) {
  const data = {
  };
  return fetch(apiUrl, `b2b/v1/tracking/asset/partners/${Number(partnerID)}/locations`);
}
export function getHeliosVehicleHistoryTripData(tripID) {
  const data = {
  };
  return fetch(apiUrl, `b2b/v1/thcRouteMapping/v2/${Number(tripID)}`);
}
export function getPartnerCustomersMappedLocationData(partnerID, partners, locations) {
  const data = {
    "locationIds": locations,
    "partnerIds": partners
  };
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/${Number(partnerID)}/locations/customers`, data);
}
export function getPartnerCustomersAllLocationData(partnerID, partners, locations) {
  const data = {
    "partnerIds": partners
  };
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/${Number(partnerID)}/customers/locations/all`, data);
}
export function getDistanceLocationData(partnerID, range) {
  const data = {
    "trackingRange": range,
  };
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/partners/${Number(partnerID)}/fetch/vehicles/list`, data);
}
export function getFilteredHeliosTrackingData(partnerID, filters) {
  const data = filters
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/partners/${Number(partnerID)}/fetch/vehicles/filters`, data);
}
export function getHeliosVehicleHistoryTrackingData(partnerID, filters) {
  const data = filters
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/history`, data);
}
export function getSearchedTrackingData(partnerID, data) {
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/partners/${Number(partnerID)}/fetch/vehicles/trips`, data);
}

/* Revamped Tracking Start */
export function getSearchByVehcile(data) {
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/fetch`, data);
}

export function getAtlasHeliosTrackingHistory(filters) {
  const data = filters
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/fetch/history`, data);
}

export function getSearchByTrip(data) {
  return fetch_data_for_helios_post(apiUrl + `b2b/v1/tracking/asset/fetch/history`, data);
}
/* Revamped Tracking End */


export function getVehicleSimHistoricTrackingData(vehicleId, orgId, startTime, endTime, contractId) {
  const data = {
    "action": "fetchSimTrackingHistory",
    "data": {
      "type": "contract",
      "subtype": "vehicleTracking",
      "id": contractId,
      "org": {
        "id": "profile_organization_K5P102MC8189"
      },
      "startDate": startTime,
      "endDate": endTime,
      "vehicle": {
        "id": vehicleId
      }
    }
  }
  return fetch_sim_data_from_beecon(apiUrl + "b2b/v1/vehicle/sim/tracking/history", data);
}

// export function listenTelematicSocketData(){
//     const options = {
//                forceNew: true,
//                autoConnect: true,
//                pingInterval: 6000,
//                pingTimeout: 10000,
//                query: "token=" + token + "&deviceId=" + "profile_organization_J46SFFAE",
//                //  "transports": ["polling"]  or "transports": ["websocket"]
//            };
//     const socket = io.connect("https://www.beecon.in" + "/webSocket", options);

//     //self.socket.on('websocket', function (data){
//     socket.on('connect', function (data){
//     console.log("ON WEBSOCKET");
//     });

//     //{
//     //message: "jwt expired", code: "invalid_token", type: "UnauthorizedError"}
//     //code: "invalid_token"
//     //message: "jwt expired"
//     //type: "UnauthorizedError"
//     //}
//     //IF this come use login api to regenerate the token
//     socket.on('error', function (data){
//     console.log(data);
//     //return data;
//     });

//     //Save vehicle and check this log
//     socket.on("vehicleTelematicEvent", function (data) {
//     console.log("CONNECTED");
//     //console.log(data);
//     return data;
//     });
// }
