
import LocationService from '../../../services/settings/location.service';
import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
const locationService = new LocationService;

export const fetchLocationGroup = (data) => dispatch => {
    dispatch(fetchLocationGroupBegin());
    // let payload = {
    //     filters:
    //     {
    //         roles : ["PICKUP"],
    //         entityTypes : ["CUSTOMER"],
    //         deviceFilter : {"deviceType":"MOBILE","view":"LITE_LOCATION_LIST"},
    //         entityIds : [data],
    //         status : true
    //     }
    // }
    let params = { "filters": { "entityTypes": ["CUSTOMER"], "entityIds": [data], "isConsiderCreatorId": false, "fetchObjects": [], "status": true } }
    locationService.fetchLocations(params).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchLocationGroupSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchLocationGroupFailure(res ?.data ?.response));
        }
    });
    // locationService.getlocationByGroup(payload).then(res => {
    //     if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
    //         dispatch(fetchLocationGroupSuccess(res ?.data ?.response));
    //     } else {
    //         dispatch(fetchLocationGroupFailure(res ?.data ?.response));
    //     }
    // });
};

function fetchLocationGroupBegin() {
    return {
        type: _Constants.FETCH_LOCATIONGROUP_BEGIN
    };
}

function fetchLocationGroupSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_LOCATIONGROUP_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchLocationGroupFailure(error) {
    return {
        type: _Constants.FETCH_LOCATIONGROUP_FAILURE,
        payload: error
    };
}