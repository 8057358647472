import { Button, Card, Tag } from "antd";
import React from "react";
import { formatCurrency } from "../../../../common/utils/utils";

function Summary({ data, ...props }) {
    const {earningSummary, walletSummary} = data;
  return (
    <div
      style={{
        margin: 16,
        minHeight: "95vh"
      }}
      className="payout-summary"
    >
      <Card style={{ marginTop: 16, borderRadius: 8 }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.57,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#000000"
          }}
        >
          Wallet Summary
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.17,
              letterSpacing: "normal",
              textAlign: "center",
              color: "#0b1323",
              marginTop: 12
            }}
          >
            Total Balance
          </div>
          <div
            style={{
              fontSize: "26px",
              fontWeight: "bold",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.23,
              letterSpacing: "normal",
              textAlign: "center",
              color: "#3b5a98",
              margin: "8px 0"
            }}
          >
            {formatCurrency(walletSummary?.totalBalance)}
          </div>
          <Tag style={{ marginBottom: 20 }} color="red">
            {walletSummary?.displayText || "N/A"}
          </Tag>
          <Button
            onClick={props.openPassbook}
            style={{ width: "220px" }}
            type="primary"
          >
            View Passbook
          </Button>
        </div>
      </Card>
      <Card style={{ marginTop: 16, borderRadius: 8, textAlign: "center" }}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.57,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#000000"
          }}
        >
          Current earning Summary
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 16
          }}
        >
          <div
            style={{
              marginTop: 15,
              height: "74px",
              flexGrow: 0,
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f1f3f6",
              justifyContent: "center",
              alignItems: "center",
              width: "142px"
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#3b5a98"
              }}
            >
             {formatCurrency(earningSummary?.todayEarnings)}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.17,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#0b1323"
              }}
            >
              Today’s Earnings
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              height: "74px",
              flexGrow: 0,
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f1f3f6",
              justifyContent: "center",
              alignItems: "center",
              width: "142px"
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#3b5a98"
              }}
            >
              {earningSummary?.todayDeliveries || "0"}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.17,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#0b1323"
              }}
            >
              Today’s Deliveries
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              height: "74px",
              flexGrow: 0,
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f1f3f6",
              justifyContent: "center",
              alignItems: "center",
              width: "142px"
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#3b5a98"
              }}
            >
              {formatCurrency(earningSummary?.yesterdayEarnings)}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.17,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#0b1323"
              }}
            >
              Yesterday’s Earnings
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              height: "74px",
              flexGrow: 0,
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#f1f3f6",
              justifyContent: "center",
              alignItems: "center",
              width: "142px"
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.5,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#3b5a98"
              }}
            >
              {earningSummary?.todayDeliveries || "0"}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.17,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#0b1323"
              }}
            >
              Yesterday’s Deliveries
            </div>
          </div>
        </div>
        <Button
          onClick={props.openDailyEarnings}
          style={{ width: "220px" }}
          type="primary"
        >
          View Earnings
        </Button>
      </Card>
    </div>
  );
}

export default Summary;
