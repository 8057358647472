import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";

class NoPermission extends Component {
    constructor(props){
        super(props);
        this.state = {
            NoData: null,
        };
    }

  render() {
      var style = {
        fontWeight: 700,
        fontSize: 16,
        textAlign: 'center',
        paddingTop: 23, 
      };
    return (
        <>
        <Row>
            <Col xs={24}>
                <div style={style}>Thanks for using atlas platform. You don't have required permission to access the atlas. Please contact admin</div>
            </Col>
        </Row>
        </>
    );
  }
}
const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(NoPermission);