import React, { Component } from "react";
import "./Header.scss";
import Logo from "../../../assets/img/logo.png";
import { Spin, Icon, Dropdown, Menu, Form, Avatar, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  filterSelectOptions,
  compareObjectsByKeys,
  reloadApp
} from "../../../common/utils/utils";
import { select as Select } from "../../ui/select/Select";
import { typography as Typography } from "../../ui/typography/Typography";
import * as actions from "./action";
import { getPermissions } from '../../../app/appAction';
import {
  USER_LOGGEDIN_INFO,
  USER_LEVEL,
  SELF
} from "../../../common/utils/constants";
import { AuthenticationService } from "../../../services/authentication.service";
import { Permissions } from "../../../services/permissions.service";
import { openNotification } from "../../ui/notification/Notification";
import { button as Button } from "../../ui/button/Button";
import * as trackingActions from "../../tracking/action";
import TrackingPopup from "../../TrackingPopup/TrackingPopup";
import { modal as Modal } from "../../ui/modal/Modal";
import { Constants } from "../../../common/utils/constants";
import { notifyApiError } from "../../../common/utils/utils";
const { Option } = Select;

class NopermissionHeader extends Component {
  configFlag = false;
  showAdminOptions = false;
  state = {
    selectedPartner: null,
    selectedPartnerLocation: null,
    searchedWaybill: "",
    showTrackingPopup: false,
    customLogo: null,
    showHubModal: false,
    confirmModalVisible: false,
    selectedConfirmModal: '',
    confirmLoader: false,
    hubLoader: false,
    configFetched: false,
    permissionFetched: false
  };
  
  componentDidMount() {
    Constants.HEADER_CLASSNAME = this.constructor.name;
    this.props.getLoggedInUserInfo();
    this.props.storeGetDataLocalStorage();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.login !== this.props.login) {
      Permissions.storeLoginData({
        token: this.props.login.token,
        currentLocationName: this.props.login.user.location.name,
        currentPartnerName: this.props.login.user.partner.name
      });
      openNotification({
        message: "Information",
        description: "Latest data has been set",
        className: "warning"
      });
    }
    if (prevProps.userInfo !== this.props.userInfo && !compareObjectsByKeys(prevProps.userInfo, this.props.userInfo)) {
      if (
        this.props.loggedInUser ?.userLevel === USER_LEVEL.ADMIN ||
          this.props.loggedInUser ?.userLevel === USER_LEVEL.LSN ||
            this.props.loggedInUser ?.userLevel === USER_LEVEL.PARTNER_HUB
      ) {
        this.setState({showAdminOptions: true});

        if (
          this.props.loggedInUser ?.location ?.entityType !==
            USER_LOGGEDIN_INFO.CUSTOMER
        ) {
          this.props.getPartnerDetails(this.props.userInfo.loggedPartnerId);
        }

        // this.props.cookies.set(
        //   USER_LOGGEDIN_INFO.LOGGED_IN,
        //   true,
        //   USER_LOGGEDIN_INFO.COOKIEBASEPATH
        // );
      }
    }

    if (prevProps.partners !== this.props.partners && !compareObjectsByKeys(prevProps.partners, this.props.partners)) {
      this.partnerHandleChange(
        this.props ?.userInfo ?.currentPartnerId,
        undefined,
        true
      );
    }

    if (prevProps.partnerLocations !== this.props.partnerLocations) {
      this.partnerLocationhandleChange(
        this.props ?.userInfo ?.currentPartnerLocationId,
        undefined,
        true
      );
    }

    if (this.props.refreshApp) {
      reloadApp(this.props.login);
    }

    if (this.props.waybillDetails !== prevProps.waybillDetails && this.props.source === Constants.HEADER_CLASSNAME) {
      if (this.props.waybillDetails ?.tracking ?.length > 0) {
        this.showPopup();
      } else {
        notifyApiError(
          `No Tracking details found for ${this.state.searchedWaybill}`,
          "Data not found"
        );
      }
    }

    if (this.props.permissions && this.props.permissions !== prevProps.permissions) {
      this.setState({
        permissionFetched: true
      });
    }

    if (this.props.configurations !== prevProps.configurations && this.configFlag) {
      this.configFlag = false;
      this.setState({
        configFetched: true
      });
    }

    if (this.state.configFetched && this.state.permissionFetched) {
      this.setState({
        confirmLoader: false,
        confirmModalVisible: false
      })
      reloadApp();
    }
  }
  getPartnerNameByID = partnerId => {
    const selectedPartner = this.props.partners.find(partner => {
      return partner.linkPartnerBO.id === partnerId;
    });
    if (selectedPartner) {
      return selectedPartner ?.linkPartnerBO ?.name;
    }
    return SELF;
  };

  getLocationNameById = locationId => {
    const selectedLocation = this.props ?.partnerLocations ?.find(location => {
      return location.id === locationId;
    });
    if (selectedLocation) {
      return selectedLocation.name;
    }
    return "Select Location";
  };
  partnerHandleChange = (partnerId, event, auto = false) => {
    let partnerToDisplay = partnerId;
    if (auto) {
      partnerToDisplay = this.getPartnerNameByID(partnerId);
    }
    this.setState({
      selectedPartner: partnerToDisplay,
      selectedPartnerLocation: "Select Location"
    });

    this.fetchPartnerLocations(event ?.key || partnerId);
  };
  partnerLocationhandleChange = (locationId, event, auto = false) => {
    this.setState({
      hubLoader: !auto
    }, () => {
      if (!isNaN(this.state.selectedPartner)) {
        // Update in redux store as well
        this.props.updateLocalItemInRedux({
          key: "currentPartnerId",
          value: this.state.selectedPartner
        });
      }
      this.props.updateLocalItemInRedux({
        key: "currentPartnerLocationId",
        value: locationId
      });
      let locationToDisplay = locationId;
      if (auto) {
        locationToDisplay = this.getLocationNameById(locationId);
      }
      this.setState({
        selectedPartnerLocation: locationToDisplay,
        refreshLocalData: true,
      }, () => this.props.appLogin({ locationId: locationId, auto }));
    })
  };

  fetchPartnerLocations = (partnerId = this.state.selectedPartner) => {
    if (isNaN(partnerId)) {
      partnerId = this.props.userInfo.currentPartnerId;
    }
    const req = {
      filters: {
        entityTypes: [this.props.loggedInUser ?.location ?.entityType],
        entityIds:
          this.props.loggedInUser ?.location ?.entityTypes ===
            USER_LOGGEDIN_INFO.CUSTOMER
            ? [this.props.loggedInUser ?.location ?.entityId]
            : [partnerId],
        isConsiderCreatorId: false,
        roles: [],
        "fetchObjects": [],
        status: true
      }
    };
    this.props.fetchPartnerLocatinDetails(req, partnerId);
  };

  logout = () => {
    this.props.userLogout()
    AuthenticationService.logoutUser();
  };
  onWaybillChange = event => {
    this.setState({
      searchedWaybill: event.target.value
    });
  };
  onSearchWaybill = () => {
    if (!this.state.searchedWaybill.trim()) {
      return;
    }
    this.props.getWaybillTrackingDetails({
      waybillNo: this.state.searchedWaybill,
      source: Constants.HEADER_CLASSNAME
    });
  };
  showPopup = () => {
    this.setState({
      showTrackingPopup: true
    });
  };

  handleCancel = e => {
    this.setState({
      showTrackingPopup: false,
      showHubModal: false,
      confirmModalVisible: false
    });
  };
  onKeyPress = e => {
    if (e.key === "Enter") {
      this.onSearchWaybill();
    }
  };
  getAvatar = name => {
    let avatar = '';
    const names = name.split(' ');
    names.forEach(item => {
      if (item)
        avatar += item[0].toUpperCase();
    })

    return avatar
  }
  loadConfig = () => {
    this.configFlag = true;
    this.setState({
      confirmModalVisible: true,
      selectedConfirmModal: 'Config & Permissions'
    })
  }
  confirmed = () => {
    this.setState({
      confirmLoader: true
    }, () => {
      this.props.getPermissions();
      this.props.getConfigurations();
    })
  }

  render() {
    const { configurations, userInfo } = this.props;
    let customLogo;
    if(configurations && userInfo && userInfo.loggedPartnerId){
      let logoData = `https://loadshare-v2.s3.ap-south-1.amazonaws.com/prod/partner-logo/${this.props.userInfo.loggedPartnerId}/partner-logo.jpg`;
      customLogo = this.props.configurations["logoURL"] ? this.props.configurations["logoURL"] : Logo ;
    }
    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    };
    const profileMenu = (
      <Menu>
        <Menu.Item key="4">
          <Row style={{ textAlign: 'center' }}>
            <Col span={24}>
              <Avatar size={50} style={{ backgroundColor: '#3b5a98' }}>
                {this.getAvatar(this.props.loggedInUser ?.name ? this.props.loggedInUser ?.name : ' ')}
              </Avatar>
            </Col>
            <Col span={24}>
              <Typography
                styles={{
                  textAlign: "center",
                  fontSize: "16px",
                  display: "inline-block",
                  margin: " 0 15px",
                  textTransform: "capitalize",
                }}
              >
                {this.props.loggedInUser ?.name}
                <br />
                {this.props ?.loggedInUser ?.partner ?.name} (
            {this.props ?.loggedInUser ?.location ?.entityType})
          </Typography>
            </Col>
          </Row>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={this.logout}>
          <Icon type="poweroff" className="icon" /> Logout
        </Menu.Item>
      </Menu>
    )
    return (
      <div className="Header">
        <div className="lhs">
          {customLogo ? (
            <img
              src={customLogo}
              alt="partner logo"
              className={`${customLogo === Logo ? 'logo-img' : 'img-fluid img-size'}`}
            />
          ) : (
              null
            )}
        </div>
        <div className="rhs">
          <Dropdown overlay={profileMenu} trigger={['hover', 'click']}>
            <Typography
              styles={{
                color: "#fff",
                textAlign: "center",
                fontSize: "16px",
                display: "inline-block",
                margin: " 0 15px",
                textTransform: "capitalize",
                lineHeight: "70px"
              }}
            >
              {this.props.loggedInUser ?.name}
              <span className="name-splitter"><Icon type="down" /></span>
            </Typography>
          </Dropdown>
        </div>
        <Modal
          onCancel={this.handleCancel}
          visible={this.state.showHubModal}
          footer={false}
          title="Switch Hub"
          centered
          destroyOnClose={true}
          wrapClassName={'hub-modal'}
          maskClosable={false}
        >
          <Spin spinning={this.state.hubLoader} indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
            <Form className="login-form" {...formItemLayout}>
              <Form.Item label="Partner">
                <Select
                  loading={this.props.partnerLoading}
                  notFoundContent="Partner List not present"
                  filterOption={filterSelectOptions}
                  className="header-select-drop"
                  value={this.state.selectedPartner}
                  onChange={this.partnerHandleChange}
                  showSearch={true}
                  style={{ width: '100%' }}
                >
                  {this.props ?.partners ?.map(partner => (
                    <Option key={partner.linkPartnerBO.id}>
                      {partner.linkPartnerBO.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Partner Location">
                <Select
                  loading={this.props.locationLoading}
                  notFoundContent="Partner Location List not present"
                  filterOption={filterSelectOptions}
                  className="header-select-drop"
                  value={this.state.selectedPartnerLocation}
                  onChange={this.partnerLocationhandleChange}
                  showSearch={true}
                  style={{ width: '100%' }}
                >
                  {this.props ?.partnerLocations ?.map(location => (
                    <Option key={location.id}>{location.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
        <Modal
          onCancel={this.handleCancel}
          visible={this.state.confirmModalVisible}
          title="Confirmation"
          maskClosable={false}
          footer={[
            <>
              <Button
                key="no"
                onClick={this.handleCancel}
                className="secondary-btn"
              >
                NO
            </Button>
              <Button
                onClick={this.confirmed}
                key="yes"
                className="primary-btn"
              >
                YES <Spin spinning={this.state.confirmLoader} indicator={<Icon type="loading" style={{ fontSize: 24, color: '#fff', marginLeft: 10 }} spin />} />
              </Button>
            </>
          ]}
          centered
          destroyOnClose={true}
        >
          <h3>Are you sure?</h3>
          <p>Do you want to Reload {this.state.selectedConfirmModal} of the system?</p>
        </Modal>
        <Modal
          onCancel={this.handleCancel}
          visible={this.state.showTrackingPopup}
          width="85vw"
          footer={<div />}
          centered
          destroyOnClose={true}
        >
          <TrackingPopup
            getDetailInput={this.state.searchedWaybill}
            waybillNo={this.state.searchedWaybill}
            source={Constants.TRACKING_CLASSNAME}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedInUser: state.header ?.loggedInUser,
  partners: state.header ?.partnerHeaderDetails ?.partners,
  partnerLocations: state.header ?.locationHeaderDetails,
  login: state.header ?.login,
  partnerLoading: state.header ?.partnerLoading,
  locationLoading: state.header ?.locationLoading,
  userInfo: state.header ?.userInfo,
  refreshApp: state.header ?.refreshApp,
  waybillDetails: state.tracking ?.waybillDetails,
  source: state.tracking ?.source,
  configurations: state.app.configurations,
  permissions: state.app.permissions
});
const mapDispatchToProps = dispatch => ({
  getLoggedInUserInfo: () => dispatch(actions.getLoggedInUserInfo()),
  getPartnerLocatinDetails: params =>
    dispatch(actions.getPartnerLocatinDetails(params)),
  getPartnerLocatinDetailsV2: params =>
    dispatch(actions.getPartnerLocatinDetailsV2(params)),
  fetchPartnerLocatinDetails: (params, partnerId) => dispatch(actions.fetchPartnerLocatinDetails(params, partnerId)),
  getPartnerDetails: params => dispatch(actions.getPartnerDetails(params)),
  appLogin: param => dispatch(actions.appLogin(param)),
  updateLocalItemInRedux: param =>
    dispatch(actions.updateLocalItemInRedux(param)),
  storeGetDataLocalStorage: () => dispatch(actions.storeGetDataLocalStorage()),
  getWaybillTrackingDetails: param =>
    dispatch(
      trackingActions.getWaybillTrackingDetails(param.waybillNo, param.source)
    ),
  userLogout: () => dispatch(actions.userLogout()),
  getPermissions: () => dispatch(getPermissions()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NopermissionHeader);
