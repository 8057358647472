export const pModules = {
  tracking: {
    title: "TRACKING",
    subModule: {
      linehaul: "LINEHAUL",
      intracity: "INTRACITY",
      tracking: "TRACKING",
      oldTracking: "OLD_TRACKING",
      newTracking: "VEHICLE_TRACKING",
      gmaptracking: "BEECON_MAPS",
      heliosTracking: "HELIOS_TRACKING",
      heliosVehicleTracking: "HELIOS_VEHICLE_TRACKING",
    },
  },

  dashboard: {
    title: "DASHBOARD",
    subModule: {
      BRANCH_SUMMARY: "BRANCH_SUMMARY",
      SLA: "SLA",
      COOLING_PERIOD: "COOLING_PERIOD",
      POD: "POD",
      WAYBILL_DATA: "WAYBILL_DATA",
      CSAT_FEEDBACK: "CSAT_FEEDBACK",
      ESCAL_CSAT: "ESCAL_CSAT",
      REVENUE: "REVENUE",
      GPS_TRACKING: "GPS_TRACKING",
      SERVICEABILITY: "SERVICEABILITY",
      CFT: "CFT",
      EwaybillAvailability: "EwaybillAvailability",
      OPSKPI: "OPSKPI",
      LASTMILE_USAGE: "LASTMILE_USAGE",
      REALTIME_USAGE: "REALTIME_USAGE",
      USER_MISTAKES: "USER_MISTAKES",
      POD_HEALTH: "POD_HEALTH",
    },
  },

  settings: {
    title: "SETTINGS",
    subModule: {
      customer: "CUSTOMER",
      vendor: "VENDOR",
      route: "ROUTE",
      user: "USER",
      location: "LOCATION",
      asset: "ASSET",
      vehicle: "VEHICLE",
      zone: "ZONE",
      pincode: "PINCODE",
      slaRules: "CUSTOMER_SLAS",
      customer_invite: "CUSTOMER_INVITE",
      zoho: "ZOHO",
      custom_config: {
        title: "PARTNERS ONBOARDING",
        configKey: "authorizedUserIdsToUpdateApplicationConfig",
      },
      ratecard_config: "RATECARD_CONFIG",
      partners_onboarding: {
        title: "PARTNERS ONBOARDING",
        configKey: "partnerCreation",
      },
      client_onboarding: "CLIENT_ONBOARDING",
      zone_creation: "ZONE_CREATION",
    },
  },

  manage_fleet: {
    title: "MANAGE_FLEET",
    subModule: {
      map: "MAP",
      kyc: "KYC",
    },
  },
  hyperlocal: {
    title: "HYPERLOCAL",
    subModule: {
      fleet: "MANAGE_FE",
      zones: "MANAGE_ZONE",
      mapview: "MANAGE_FE_ON_MAP",
      order_details: "CANCEL_ORDER",
      hyperlocal_order: "HYPERLOCAL_ORDER_DETAILS",
      hyperlocal_markdel: "MARK_DEL_HYPERLOCAL_ORDER",
      fe_status: "FE_STATUS",
    },
  },

  digital_payment: {
    title: "DIGITAL_PAYMENT",
    subModule: {
      payment_status: "PAYMENT_STATUS",
    },
  },
  fm_lm_config: {
    title: "FM_LM_CONFIG",
    subModule: {
      fm_lm_country: "FM_LM_COUNTRY",
      fm_lm_zone: "FM_LM_ZONE",
      fm_lm_city: "FM_LM_CITY",
      fm_lm_default: "FM_LM_DEFAULT",
    },
  },
};

export const paTags = {
  read: "READ",
  create: "CREATE",
  update: "UPDATE",
  delete: "DELETE",
  view: "VIEW",
  bulkCreate: "BULK_CREATE",
  bulkUpdate: "BULK_UPDATE",
  waybill_scan: "WAYBILL_SCAN",
  barcode_scan: "BARCODE_SCAN",
  orn_scan: "ORN_SCAN",
  bulk_booking: "BULK_BOOKING",
  bulk_inscan: "BULK_INSCAN",
  flipkart_inscan_api: "FLIPKART_INSCAN_API",
  print_waybill: "PRINT_WAYBILL",
  locationSelection: "LOCATION_SELECTION",
  bulk_pod: "BULK_POD",
  readERP: "READ_ERP",
  invite: "INVITE",
  hpod: "HPOD",
  view_unblock: "VIEW_UNBLOCK",
  view_shippers: "SHIPPERS_VIEW",
  create_shippers: "SHIPPERS_CREATE",
  update_shippers: "SHIPPERS_UPDATE",
  status_shippers: "SHIPPERS_STATUS",
  ratecard_shippers: "SHIPPERS_RATECARD",
  undel_return: "UNDEL_RETRUN",
  coloader: "COLOADER",
  deactivate: "DEACTIVATE",
  refresh: "REFRESH",
  reverify: "REVERIFY",
  upload: "UPLOAD",
  bulk_permission: "BULK_PERMISSION",
  toggle_permission: "TOGGLE_PERMISSION",
  cod_permission: "COD_PERMISSION",
  cod_enable_fe_permission: "COD_ENABLE_FE_PERMISSION",
  role_update_permission: "ROLE_UPDATE_PERMISSION",
  role_create_permission: "ROLE_CREATE_PERMISSION",
  role_change_permission: "ROLE_CHANGE_PERMISSION",
  bulk_user_edit_permission: "BULK_USER_EDIT_PERMISSION",
  bulk_zone_tagging_permission: "BULK_ZONE_TAGGING_PERMISSION",
  bulk_client_tagging_permission: "BULK_CLIENT_TAGGING_PERMISSION",
  central_team: "CENTRAL_TEAM",
  zonal_team: "ZONAL_TEAM",
  city_team: "CITY_TEAM",
  super_user: "SUPER_USER",
  zone_surge: "ZONE_SURGE",
  json_redash_permission: "JSON_REDASH_PERMISSION",
  rain_surge: "RAIN_SURGE",
};

export const permissionTags = {
  tracking: {
    module: { module: pModules.tracking.title },

    tracking: {
      subModule: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.tracking,
      },
      read: {
        module: pModules.tracking.title,
        subModule: pModules.tracking.subModule.tracking,
        action: paTags.read,
      },
    },
  },

  settings: {
    module: { module: pModules.settings.title },
    customer: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.view,
      },
      invite: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.invite,
      },
      shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.view_shippers,
      },
      create_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.create_shippers,
      },
      update_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.update_shippers,
      },
      status_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.status_shippers,
      },
      ratecard_shippers: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer,
        action: paTags.ratecard_shippers,
      },
    },
    vendor: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.read,
      },
      unblock: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vendor,
        action: paTags.view_unblock,
      },
    },
    route: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.view,
      },
      refresh: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.route,
        action: paTags.refresh,
      },
    },
    user: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.view,
      },
      role_update_permission: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.role_update_permission,
      },
      role_create_permission: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.role_create_permission,
      },
      role_change_permission: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.role_change_permission,
      },
      central_team: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.central_team,
      },
      zonal_team: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.zonal_team,
      },
      city_team: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.city_team,
      },
      super_user: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.user,
        action: paTags.super_user,
      },
    },
    location: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.location,
        action: paTags.view,
      },
    },
    asset: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.asset,
        action: paTags.read,
      },
    },
    vehicle: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.vehicle,
        action: paTags.view,
      },
    },
    zone: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.read,
      },
      view: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone,
        action: paTags.view,
      },
    },
    zoho: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
        action: paTags.update,
      },

      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zoho,
        action: paTags.read,
      },
    },
    pincode: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.pincode,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.pincode,
        action: paTags.update,
      },
    },
    partners_onboarding: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.partners_onboarding,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.partners_onboarding,
        action: paTags.create,
      },
    },
    customer_invite: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.customer_invite,
        action: paTags.read,
      },
    },
    custom_config: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.update,
      },
      delete: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.delete,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.custom_config,
        action: paTags.read,
      },
    },
    ratecard_config: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.ratecard_config,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.ratecard_config,
        action: paTags.update,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.ratecard_config,
        action: paTags.read,
      },
    },
    slaRules: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.read,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.create,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.update,
      },
      deactivate: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.slaRules,
        action: paTags.deactivate,
      },
    },
    client_onboarding: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.client_onboarding,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.client_onboarding,
        action: paTags.create,
      },
      read: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.client_onboarding,
        action: paTags.read,
      },
      update: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.client_onboarding,
        action: paTags.update,
      },
    },
    zone_creation: {
      subModule: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone_creation,
      },
      create: {
        module: pModules.settings.title,
        subModule: pModules.settings.subModule.zone_creation,
        action: paTags.create,
      },
    },
  },

  manage_fleet: {
    module: { module: pModules.manage_fleet.title },
    map: {
      subModule: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.map,
      },
      read: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.map,
        action: paTags.read,
      },
    },
    kyc: {
      subModule: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
      },
      read: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
        action: paTags.read,
      },
      create: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
        action: paTags.create,
      },
      update: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
        action: paTags.update,
      },
      delete: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
        action: paTags.delete,
      },
      view: {
        module: pModules.manage_fleet.title,
        subModule: pModules.manage_fleet.subModule.kyc,
        action: paTags.view,
      },
    },
  },

  hyperlocal: {
    module: { module: pModules.hyperlocal.title },
    fleet: {
      //refers to Manage FE
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.create,
      },
      update: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.update,
      },
      delete: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.delete,
      },
      view: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.view,
      },
      bulk_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.bulk_permission,
      },
      toggle_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.toggle_permission,
      },
      cod_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.cod_permission,
      },
      cod_enable_fe_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.cod_enable_fe_permission,
      },
      bulk_user_edit_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.bulk_user_edit_permission,
      },
      bulk_zone_tagging_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.bulk_zone_tagging_permission,
      },
      bulk_client_tagging_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fleet,
        action: paTags.bulk_client_tagging_permission,
      },
    },
    zones: {
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.create,
      },
      update: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.update,
      },
      delete: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.delete,
      },
      view: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.view,
      },
      zone_surge: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.zone_surge,
      },
      json_redash_permission: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.json_redash_permission,
      },
      rain_surge: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.zones,
        action: paTags.rain_surge,
      },
    },
    mapview: {
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.mapview,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.mapview,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.mapview,
        action: paTags.create,
      },
      update: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.mapview,
        action: paTags.update,
      },
      delete: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.mapview,
        action: paTags.delete,
      },
      view: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.mapview,
        action: paTags.view,
      },
    },
    order_details: {
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.order_details,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.order_details,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.order_details,
        action: paTags.create,
      },
      update: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.order_details,
        action: paTags.update,
      },
      delete: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.order_details,
        action: paTags.delete,
      },
      view: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.order_details,
        action: paTags.view,
      },
    },
    hyperlocal_order: {
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.hyperlocal_order,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.hyperlocal_order,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.hyperlocal_order,
        action: paTags.create,
      },
    },
    hyperlocal_markdel: {
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.hyperlocal_markdel,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.hyperlocal_markdel,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.hyperlocal_markdel,
        action: paTags.create,
      },
    },
    fe_status: {
      //refers to Manage FE
      subModule: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fe_status,
      },
      read: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fe_status,
        action: paTags.read,
      },
      create: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fe_status,
        action: paTags.create,
      },
      update: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fe_status,
        action: paTags.update,
      },
      delete: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fe_status,
        action: paTags.delete,
      },
      view: {
        module: pModules.hyperlocal.title,
        subModule: pModules.hyperlocal.subModule.fe_status,
        action: paTags.view,
      },
    },
  },

  digital_payment: {
    module: { module: pModules.digital_payment.title },
    payment_status: {
      subModule: {
        module: pModules.digital_payment.title,
        subModule: pModules.digital_payment.subModule.payment_status,
      },
      read: {
        module: pModules.digital_payment.title,
        subModule: pModules.digital_payment.subModule.payment_status,
        action: paTags.read,
      },
    },
  },

  fm_lm_config: {
    module: { module: pModules.fm_lm_config.title },
    fm_lm_country: {
      subModule: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_country,
      },
      read: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_country,
        action: paTags.read,
      },
      create: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_country,
        action: paTags.create,
      },
      update: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_country,
        action: paTags.update,
      },
      delete: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_country,
        action: paTags.delete,
      },
      view: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_country,
        action: paTags.view,
      },
    },
    fm_lm_zone: {
      subModule: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_zone,
      },
      read: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_zone,
        action: paTags.read,
      },
      create: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_zone,
        action: paTags.create,
      },
      update: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_zone,
        action: paTags.update,
      },
      delete: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_zone,
        action: paTags.delete,
      },
      view: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_zone,
        action: paTags.view,
      },
    },
    fm_lm_city: {
      subModule: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_city,
      },
      read: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_city,
        action: paTags.read,
      },
      create: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_city,
        action: paTags.create,
      },
      update: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_city,
        action: paTags.update,
      },
      delete: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_city,
        action: paTags.delete,
      },
      view: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_city,
        action: paTags.view,
      },
    },
    fm_lm_default: {
      subModule: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_default,
      },
      read: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_default,
        action: paTags.read,
      },
      create: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_default,
        action: paTags.create,
      },
      update: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_default,
        action: paTags.update,
      },
      delete: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_default,
        action: paTags.delete,
      },
      view: {
        module: pModules.fm_lm_config.title,
        subModule: pModules.fm_lm_config.subModule.fm_lm_default,
        action: paTags.view,
      },
    },
  },
};
export const LsnModules = {
  TRACKING: "TRACKING",
  SETTINGS: "SETTINGS",
  MANAGE_FLEET: "MANAGE_FLEET",
  HYPERLOCAL: "HYPERLOCAL",
  DIGITAL_PAYMENT: "DIGITAL_PAYMENT",
  FM_LM_CONFIG: "FM_LM_CONFIG",
};
