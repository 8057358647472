import * as CONSTANTS from './constant';

export const trackingListingReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_BEGIN: return downloadWaybillsBegin(state);
        case CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_SUCCESS: return downloadWaybillsSuccess(state, action);
        case CONSTANTS.DOWNLOAD_SEARCHED_WAYBILLS_FAILURE: return downloadWaybillsFailure(state);
        case CONSTANTS.FILTER_WAYBILLS: return filterWaybills(state, action);
        default: return state;
    }
}

function downloadWaybillsBegin(state) {
    return {
        ...state,
        downloadWaybillsLoading: true
    }
}

function downloadWaybillsSuccess(state, action) {
    return {
        ...state,
        downloadWaybillsLoading: false,
        downloadWaybillsUrl: action.payload,
        downloadWaybillError: undefined
    }
}

function downloadWaybillsFailure(state, action) {
    return {
        ...state,
        downloadWaybillsLoading: false,
        downloadWaybillError: action.payload
    }
}

function filterWaybills(state, action) {
    let val = action.payload.searchInput.toLowerCase();
    if (val) {
        val = val.trim();
    }
    // filter our data
    const filteredTableData = action.payload.fullTableData.filter(function (d) {
        return d.waybillNo.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return {
        ...state,
        filteredTableData
    }
}