/*
props needed : 
values : array of objects having following properties :: 
        [{
            id: generateID(),
            label: <text/component to display/render>,
            value: 'value to get after selecting option'
        }]

onChange: function to call on radio option change
selectedValue: initial selected value
*/
import React from "react";
import { Radio } from "antd";
import * as Constants from "../../../common/utils/constants";
const RadioGroup = Radio.Group;

const RadioButton = props => {
  let radioValues = props.values.map(radio => {
    return (
      <Radio
        key={radio.id || radio.value}
        className={props.radioClassName}
        value={radio.value}
      >
        {radio.label}
      </Radio>
    );
  });
  if (props.type === Constants.FILTER_INPUT_TYPE.RADIO_BUTTON) {
    radioValues = props.values.map(radioBtn => {
      return (
        <Radio.Button
          key={radioBtn.id || radioBtn.value}
          className={props.radioClassName}
          value={radioBtn.value}
        >
          {radioBtn.label}
        </Radio.Button>
      );
    });
  }
  let radioHtml = (
    <RadioGroup onChange={props.onChange} value={props?.defaultValue}>
      {radioValues}
    </RadioGroup>
  );
  if (props.type === Constants.FILTER_INPUT_TYPE.RADIO_BUTTON) {
    radioHtml = (
      <RadioGroup buttonStyle={props?.buttonStyle} onChange={props.onChange} defaultValue={props?.defaultValue}>
        {radioValues}
      </RadioGroup>
    );
  }
  return radioHtml;
};

export default RadioButton;
