import * as CONSTANTS from './constants';
import { removeDuplicateLocations } from '../../../common/utils/utils';

export const ingestReducer = (state = {}, action) => {
    switch (action.type) {
        case CONSTANTS.GET_REPORT_TYPE_BEGIN: return getReportTypeBegin(state);
        case CONSTANTS.GET_REPORT_TYPE_SUCCESS: return getReportTypeSuccess(state, action);
        case CONSTANTS.GET_REPORT_TYPE_FAILURE: return getReportTypeFailure(state);
        case CONSTANTS.GET_CUSTOMER_LIST_BEGIN: return getCustomerListBegin(state);
        case CONSTANTS.GET_CUSTOMER_LIST_SUCCESS: return getCustomerListSuccess(state, action);
        case CONSTANTS.GET_CUSTOMER_LIST_FAILURE: return getCustomerListFailure(state);
        case CONSTANTS.GET_LOCATION_LIST_BEGIN: return getLocationListBegin(state);
        case CONSTANTS.GET_LOCATION_LIST_SUCCESS: return getLocationListSuccess(state, action);
        case CONSTANTS.GET_LOCATION_LIST_FAILURE: return getLocationListFailure(state);
        case CONSTANTS.GET_CUSTOM_API_CALL_BEGIN: return getCustomApiRespBegin(state);
        case CONSTANTS.GET_CUSTOM_API_CALL_SUCCESS: return getCustomApiRespSuccess(state, action);
        case CONSTANTS.GET_CUSTOM_API_CALL_FAILURE: return getCustomApiRespFailure(state, action);
        case CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_BEGIN: return uploadReportFileBegin(state);
        case CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_SUCCESS: return uploadReportFileSuccess(state, action);
        case CONSTANTS.POST_UPLOAD_REPORT_TYPE_FILE_FAILURE: return uploadReportFileFailure(state);
        case CONSTANTS.GET_EMPLOYEES_LIST_BEGIN : return getEmployeeBegin(state);
        case CONSTANTS.GET_EMPLOYEES_LIST_SUCCESS : return getEmployeeSuccess(state, action) ;
        case CONSTANTS.GET_EMPLOYEES_LIST_FAILURE : return getEmployeeFailure(state);

        default:
            return state;
    }
}

function uploadReportFileBegin(state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}
function uploadReportFileSuccess(state, action) {
    return {
        ...state,
        loading: false,
        uploadedFileResp: action.payload,
        metaData: null
    }
}
function uploadReportFileFailure(state) {
    return {
        ...state,
        loading: false,
        reportType: undefined,
        metaData: null
    }
}

function getReportTypeBegin(state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function getReportTypeFailure(state) {
    return {
        ...state,
        loading: false,
        reportType: undefined,
        metaData: null
    }
}
function getReportTypeSuccess(state, action) {
    return {
        ...state,
        loading: false,
        reportType: action.payload,
        metaData: null
    }
}

function getCustomerListBegin(state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function getCustomerListFailure(state) {
    return {
        ...state,
        loading: false,
        customerList: undefined,
        metaData: null
    }
}

function getCustomerListSuccess(state, action) {
    return {
        ...state,
        loading: false,
        customerList: action.payload.customers,
        metaData: null
    }
}

function getLocationListBegin(state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function getLocationListFailure(state) {
    return {
        ...state,
        loading: false,
        locationList: undefined,
        metaData: null
    }
}

function getLocationListSuccess(state, action) {
    return {
        ...state,
        loading: false,
        locationList: removeDuplicateLocations(action.payload, { nameWithRole: 'name' }),
        metaData: null
    }
}


function getEmployeeBegin(state) {
    return {
        ...state,
        loading: true,
        metaData: null
    }
}

function getEmployeeFailure(state) {
    return {
        ...state,
        loading: false,
        employeeList: undefined,
        metaData: null
    }
}

function getEmployeeSuccess(state, action) {
    return {
        ...state,
        loading: false,
        employeeList: removeDuplicateLocations(action.payload, { nameWithRole: 'name' }),
        metaData: null
    }
}

function getCustomApiRespBegin(state) {
    return {
        ...state,
        loading: true,
        customerError: {},
        metaData: null
    }
}

function getCustomApiRespFailure(state, action) {
    
    return {
        ...state,
        loading: false,
        customApiResp: undefined,
        customerError: action?.payload,
        metaData: null
    }
}

function getCustomApiRespSuccess(state, action) {
    return {
        ...state,
        loading: false,
        customApiResp: action.payload,
        metaData: null
    }
}