import * as CONSTANTS from './constants';

export const B2cBookingReducer = (state = {
    bookingEdit: {
        loading: true,
        data: {},
        metaData: null
    }
}, action) => {
    switch (action.type) {
        default: return state
    }
}