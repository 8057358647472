import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const ZoneMappingReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        // case _Constants.FETCH_ZONES_BEGIN: return fetchZonesBegin(state);
        // case _Constants.FETCH_ZONES_SUCCESS: return fetchZonesSuccess(state, action);
        // case _Constants.FETCH_ZONES_FAILURE: return fetchZonesFailure(state, action);
        case _Constants.FETCH_AREAS_BEGIN: return fetchAreasBegin(state);
        case _Constants.FETCH_AREAS_SUCCESS: return fetchAreasSuccess(state, action);
        case _Constants.FETCH_AREAS_FAILURE: return fetchAreasFailure(state, action);
        default:
            return state;
    }
}

function fetchZonesBegin(state) {
    return {
        ...state,
        loading: true
    };
}

function fetchZonesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        PaginatedZones: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedZones ? state.PaginatedZones : {}, action ?.payload ?.zones , action.isReset),
        ...action.payload
    };
}

function fetchZonesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}

function fetchAreasBegin(state) {
    return {
        ...state,
        areas : {
            loading: true
        }
    };
}

function fetchAreasSuccess(state, action) {
    return {
        ...state,
        areas: {
            loading: false,
            ...action.payload
        }
    };
}

function fetchAreasFailure(state, action) {
    return {
        ...state,
        areas: {
            loading: false,
            error: action.payload,
        }
    };
}