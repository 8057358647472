import mixpanel from "mixpanel-browser";
import moment from "moment";

let env_check = process.env.NODE_ENV === "dummy";
// let env_check = true;
if (env_check) mixpanel.init("12345"); //dummy token to stop calls

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    const time = moment().valueOf();
    props.timestamp = moment(time).format("DD/MM/YYYY hh:mm:ss A");
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
