import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import RouteService from '../../../services/settings/route.service';
import { notifyApiError, notifyApiSuccess } from '../../../common/utils/utils';

function checkHttpStatus(status) {
    switch (status?.code) {
        case 403:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 401:
            notifyApiError(status?.message, "ERROR:");
            // window.location.href = '/';
            return false;
        case 500:
        case 503:
            notifyApiError(status?.message, "ERROR:");
            return false;
        case 200:
        case 201:
        case 202:
            return true
    }
}

export const fetchRoutes = (payload, isReset) => dispatch => {
    dispatch(fetchRouteBegin());
    RouteService.getRouteFetch(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchRouteSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(fetchRouteFailure(res ?.data ?.response));
        }
    });
};

export const deleteRoute = (id, payload) => dispatch => {
    dispatch(fetchRouteBegin());
    RouteService.deleteRoute(id).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            fetchRoutes(payload);
        } else {
            dispatch(fetchRouteFailure(res ?.data ?.response));
        }
    });
}

function fetchRouteBegin() {
    return {
        type: _Constants.FETCH_ROUTE_BEGIN
    };
}

function fetchRouteSuccess(payload, isReset) {
    return {
        type: _Constants.FETCH_ROUTE_SUCCESS,
        payload: payload,
        isReset
    };
}

function fetchRouteFailure(error) {
    return {
        type: _Constants.FETCH_ROUTE_FAILURE,
        payload: error
    };
}

export const getRoute = (id) => dispatch => {
    dispatch(fetchRouterBegin());
    RouteService.getRoute(id).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchRouterSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchRouterFailure(res ?.data ?.response));
        }
    });
}

function fetchRouterBegin() {
    return {
        type: _Constants.FETCH_ROUTER_BEGIN
    };
}

function fetchRouterSuccess(payload) {
    return {
        type: _Constants.FETCH_ROUTER_SUCCESS,
        payload: payload
    };
}

function fetchRouterFailure(error) {
    return {
        type: _Constants.FETCH_ROUTER_FAILURE,
        payload: error
    };
}


export const getReset = () => dispatch => {
    dispatch({
        type: _Constants.RESET_DATA
    });
   
}


export const refreshserviceability = (payload) => dispatch => {
    return RouteService.refreshserviceability(payload).then(res => {
        return res;
    });
}; 

export const getRoutebyId = (id) => dispatch => {
    return RouteService.getRoute(id).then(res => {
        return res;
    });
};

export const fetchLocation = (payload, isReset) => dispatch => {
    return RouteService.getLocationsFetch(payload).then(res => {
        return res;
    });
};

export const getStartLocationData = (param, payload) => dispatch => {
    return RouteService.getStartLocation(param, payload).then(res => {
        return res;
    });
};

export const getZones = () => dispatch => {
    let payload = {
        filters: {
            fetchObjects: ["ZONE"],
            locationSpecific: false,
        }
    };
    return RouteService.getZones(payload).then(res => {
        return res;
    });
}

export const fetchRoutesForm = (payload, isReset) => dispatch => {
    return RouteService.getRouteFetch(payload).then(res => {
        return res;
    });
};

export const updateRoute = (id, payload) => dispatch => {
    return RouteService.updateRoute(id, payload).then(res => {
        return res;
    });
}

export const createRoute = (payload) => dispatch => {
    return RouteService.createRoute(payload).then(res => {
        return res;
    });
}

export const setZoneSet = (type) => dispatch => {
    dispatch(fetchSetZoneSuccess(type));
}

function fetchSetZoneSuccess(payload) {
    return {
        type: _Constants.FETCH_LOCATION_ZONE_SUCCESS,
        payload: payload
    };
}