export const RATE_CARD_FETCH_CUSTOM_CONFIG_BEGIN = "RATE_CARD_FETCH_CUSTOM_CONFIG_BEGIN";
export const RATE_CARD_FETCH_CUSTOM_CONFIG_SUCCESS = "RATE_CARD_FETCH_CUSTOM_CONFIG_SUCCESS";
export const RATE_CARD_FETCH_CUSTOM_CONFIG_FAILURE = "RATE_CARD_FETCH_CUSTOM_CONFIG_FAILURE";


export const RATE_CARD_UPDATE_CUSTOM_CONFIG_BEGIN = "RATE_CARD_UPDATE_CUSTOM_CONFIG_BEGIN";
export const RATE_CARD_UPDATE_CUSTOM_CONFIG_SUCCESS = "RATE_CARD_UPDATE_CUSTOM_CONFIG_SUCCESS";
export const RATE_CARD_UPDATE_CUSTOM_CONFIG_FAILURE = "RATE_CARD_UPDATE_CUSTOM_CONFIG_FAILURE";

export const RATE_CARD_RESET_UPDATED_DATA = 'RATE_CARD_RESET_UPDATED_DATA';
