import React, { Component } from "react";
import './HeliosHistoryTracking.scss';
import { Row, Col,Tabs, Icon, Spin,Card, Button, Popover,DatePicker,Form,Modal } from "antd";
import { connect } from "react-redux";
import * as utils from "../../../common/utils/utils";
import moment from 'moment';
import HeliosStepper from "../ShowDetails/HeliosStepper";
import img from '../../../assets/img/spinner.svg';
import HeliosMapRouteV1 from '../../maps/HeliosGoogleMapRouteV1';
import { Constants, validationRules } from '../../../common/utils/constants';
import {getHeliosVehicleHistoryData,getHeliosVehicleHistoryTripData} from '../action';
import LeafletReactTrackPlayer from "leaflet-react-track-player";
import demo from "./demo";
import { Map, TileLayer } from "react-leaflet";
import HeliosTracking from "../HeliosTracking/HeliosTracking";
var defaultStartDate = new Date();
//defaultStartDate = new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth(), 1);
defaultStartDate.setHours(0,0,0,0);
const defaultEndDate = new Date();
defaultEndDate.setHours(23,59,59,999);
const dateFormat = "DD-MM-YYYY HH:mm";
const dateFormatCard = "DD-MM-YYYY HH:mm A";
class HeliosHistoryTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess: '',
      startDate:new Date(),
      endDate:new Date(),
      regNo:'',
      simTrack:false,
      contractId:'',
      vehicleTrack:false,
      tripTrack:false,
      tripId:'',
      activeKey:'1',
      trackingType:'',
      polyline:[],
      startDate:defaultStartDate,
      endDate:defaultEndDate,
      playModalVisible:false,
      demo:demo,
      zoom: 15,
      type: "default",
    }
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onGoToHistory = this.onGoToHistory.bind(this);
    this.reloadData =this.reloadData.bind(this);
    this.onTabChange = this.onTabChange(this);
    this.defaultPartnerId=Constants.PARTNER_ID;
    this.activePath = this.props.location.pathname;
    this.playDataModified = [];
  }
  onStartDateChange(date, dateString) {
    this.setState({startDate: new Date(dateString)});
    //console.log("Start Date",date, dateString);
  }
  onEndDateChange(date, dateString) {
    //console.log("End Date",date, dateString);
    this.setState({endDate: new Date(dateString)});
  }
  goToHeliosTracking = () => {
    this.props.history.push(`/appv2/tracking/dashboard/helios-tracking`);
  }
  onTabChange(key){
    //console.log("the tab key",key);
  }
  onGoToHistory(query){
    this.props.history.push(`/appv2/tracking/dashboard/helios-history-tracking/${query}`);
  }
  loadVehicleHistory(regNo) {
    const filters = {
      "trackingRange":{"from":this.state.startDate.getTime(),"to":this.state.endDate.getTime()},
      "vehicleNumber":regNo,
      "partnerId":this.defaultPartnerId
    }
    if (this.defaultPartnerId) {
      this.props.getHeliosVehicleHistoryData({partnerId:this.defaultPartnerId,filters:filters});
    }
  }
  loadVehicleTripHistory(tripId) {
    this.props.getHeliosVehicleHistoryTripData(tripId);
  }
  onSelectDateRange(range,dateStrings){
    if (range[0] && range[1]) {
      const valueOfInput1 = range[0].format();
      const valueOfInput2 = range[1].format();
      this.setState({startDate:new Date(valueOfInput1),endDate:new Date(valueOfInput2)})
      //console.log('start date',valueOfInput1);
      //console.log("end date",valueOfInput2);
    }

  }
  showPlayModal = () => {
    this.setState({
      playModalVisible: true,
    });
  };

  handleOk = e => {
    //console.log(e);
    this.setState({
      playModalVisible: false,
    });
  };

  handleCancel = e => {
    //console.log(e);
    this.setState({
      playModalVisible: false,
    });
  };
  onTrackProgress = (p,a) => {
  };
  onTrackNext = (p,a) => {
    //console.log("the onTrackNext",p,a)
  };
  onTrackPrev = (p,a) => {
    //console.log("the onTrackNext",p,a)
  };
  reloadData(){
    let activePath = this.props.location.pathname;
    if(activePath){
      let pathValue = activePath.split('/');
      let data = pathValue[pathValue.length -1];
      let dataSplit = data.split('-')
      //console.log("the data",dataSplit)
      if (dataSplit && dataSplit[0] && dataSplit[0]=='vehicle') {
        if (dataSplit[1]) {
          this.setState({
            vehicleTrack:true,
            regNo:dataSplit[1],
            tripTrack:false,
            activeKey:'1',
            tripId:'',
            trackingType:dataSplit[2]?dataSplit[2]:''
          });
          this.loadVehicleHistory(dataSplit[1]);
        }
      }else if (dataSplit && dataSplit[0] && dataSplit[0]=='trip') {
        if (dataSplit[1]) {
          this.setState({
            vehicleTrack:false,
            regNo:'',
            tripTrack:true,
            activeKey:'2',
            tripId:dataSplit[1],
            trackingType:dataSplit[2]?dataSplit[2]:''
          });
          this.loadVehicleTripHistory(dataSplit[1]);
        }
      }
    }
  }
  componentDidMount() {
    this.reloadData()
  }
  render() {
    const { TabPane } = Tabs;
    const { MonthPicker, RangePicker } = DatePicker;
    const {HeliosHistoryTrackingData,onDataLoad } = this.props;
    //console.log("the HeliosHistoryTrackingData",HeliosHistoryTrackingData);
    let startPoint;
    let endPoint;
    let content;
    let simData=false;
    let playData = [];
    this.playDataModified = [];
    let playDataObject={}
    let sim = (!utils.isEmpty(HeliosHistoryTrackingData) && !utils.isEmpty(HeliosHistoryTrackingData.simTracker))?'sim':'gps';
    if(HeliosHistoryTrackingData && HeliosHistoryTrackingData.response){
      content = HeliosHistoryTrackingData.response;
      if (content && content.reportResponse && Array.isArray(content.reportResponse)) {
        content.reportResponse.forEach(element => {
          let play ={
            status:1,
            time:new Date(element.time).getTime(),
            course:0,
            lat:element.latitude,
            lng:element.longitude
          }
          //let cloned = {...element};
          //cloned.trackingId = `${element.latitude}-${element.longitude}`;
          //this.playDataModified.push(cloned);
          playData.push(play)
        });
      }
      if (HeliosHistoryTrackingData && content && !HeliosHistoryTrackingData.simTracking && content.locationPoints && Array.isArray(content.locationPoints)) {
        playData = [];
        content.locationPoints.forEach(element => {
          let play ={
            status:1,
            time:element.deviceTimeEpoch,
            course:element.angle,
            lat:element.latitude,
            lng:element.longitude
          }
          // let cloned = {...element};
          // cloned.trackingId = `${element.latitude}-${element.longitude}`;
          // this.playDataModified.push(cloned);
          playData.push(play)
        });
      }
    }
    if (!utils.isEmpty(content) && !utils.isEmpty(content.simTracking)) {
      simData = content.simTracking;
    }
    const position = [20.5937,78.9629];
    let isShowLoader = !content;
    if(onDataLoad) {
      return (<Row><div className="noData"><img className="loading" src={img} alt="Loading"/></div></Row>)
    }
    return (
      <div className="helios-history-container">
        <Modal
          title="Play Route"
          visible={this.state.playModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={'100%'}
          centered
          maskClosable={false}
        >
           {/* <Row>
             <Col xs={6} className="track-card">
                <Row>
                  <Card size="small" title="Running Date and Time" style={{ width: 300 }}>
                    <p className="track-card-content">Card content</p>
                  </Card>
                </Row>
             </Col>
             <Col xs={6} className="track-card">
                  <Card size="small" title="Speed of the vehicle" style={{ width: 300 }}>
                    <p className="track-card-content">Card content</p>
                  </Card>
             </Col>
             <Col xs={6} className="track-card">
                <Row>
                  <Card size="small" title="Ignition" style={{ width: 300 }}>
                    <p className="track-card-content">Card content</p>
                  </Card>
                </Row>
             </Col>
           </Row> */}
           <Row>
            <Col xs={24}>
                {this.state.playModalVisible && playData && playData.length > 0 &&  <Map center={[playData[0].lat,playData[0].lng]} zoom={this.state.zoom}>
                    {playData && <LeafletReactTrackPlayer
                      track={playData}
                      optionMultyIdxFn={function(p) {
                        return p.status;
                      }}
                      optionsMulty={[
                        { color: "#b1b1b1" },
                        { color: "#06a9f5" },
                        { color: "#202020" },
                        { color: "#D10B41" },
                        { color: "#78c800" }
                      ]}
                      useControl={true}
                      progressFormat={this.state.type}
                      customMarker={true}
                      autoplay={false}
                      defaultSpeed={10}
                      streamData={false}
                      customCourse={true}
                      showDots={true}
                      changeCourseCustomMarker={true}
                      callbackFly={this.onTrackProgress}
                      callbackPrev={this.onTrackPrev}
                      callbackNext={this.onTrackNext}
                      iconCustomMarker={require("../../../assets/img/truck_90.svg")}
                    />}
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                  />
                </Map>}
             </Col>
           </Row>
        </Modal>
        <Row>
          <Col xs={12}>
            <Row style={{padding:"15px"}}>
              <div className="helios-arrow-left"><Icon type="arrow-left" onClick={this.goToHeliosTracking} /></div>
              <div className="helios-vehicle-title">{this.state.vehicleTrack?this.state.regNo:HeliosHistoryTrackingData&&HeliosHistoryTrackingData.vehicleNumber?HeliosHistoryTrackingData.vehicleNumber:'N/A'}<span className="helios-trip-started-date">● {HeliosHistoryTrackingData && HeliosHistoryTrackingData.vehicleData && HeliosHistoryTrackingData.vehicleData.driverName?HeliosHistoryTrackingData.vehicleData.driverName:'N/A'}</span><span className="helios-trip-started-date">● {HeliosHistoryTrackingData && HeliosHistoryTrackingData.vehicleData && HeliosHistoryTrackingData.vehicleData.driverNumber?HeliosHistoryTrackingData.vehicleData.driverNumber:'N/A'}</span></div>
              <div className="helios-route">
               {HeliosHistoryTrackingData && HeliosHistoryTrackingData.vehicleData && HeliosHistoryTrackingData.vehicleData.routeName?HeliosHistoryTrackingData.vehicleData.routeName:'N/A'}
              </div>
            </Row>
          </Col>
          <Col xs={12} style={{padding:"15px"}}>
            <div className="buttongroup">
              <Button className="refresh-helios" icon="reload"  onClick={this.reloadData}>Refresh</Button>
              <Button className="play-helios" disabled={playData && playData.length > 0?false:true} icon="play-circle" onClick={this.showPlayModal}>Play</Button>
              {/* <Button className="share-helios" icon="share-alt" onClick={this.reloadData}>Share</Button> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={16}>
            <div className="helios-map-container">
            {HeliosHistoryTrackingData && !utils.isEmpty(HeliosHistoryTrackingData.response) && (HeliosHistoryTrackingData.response.reportResponse?.length > 0 || HeliosHistoryTrackingData.response.locationPoints?.length > 0) ?<div><HeliosMapRouteV1 routing={HeliosHistoryTrackingData}/></div>:
              <div>
                  <h5 className="no-data-display">No Data to Display</h5>
              </div>
              }
            </div>
          </Col>
          <Col xs={8} className="helios-history-container">
              <Tabs className="history-tabs-container"  activeKey={this.state.activeKey} onChange={this.onTabChange}>
                <TabPane  tab="History Tracking" key="1" className="helios-history-tab">
                  {this.state.vehicleTrack && <Row>
                    <Col xs={24} className="helios-border-bottom">
                      <Form.Item label="Date and Time" className="helios-date-picker" labelCol={{ span: 24 }}>
                        <RangePicker className="helios-range-picker"  showTime size='large' onChange={this.onSelectDateRange.bind(this)} defaultValue={[moment(defaultStartDate, dateFormat), moment(defaultEndDate, dateFormat)]} format={dateFormat} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} className="helios-border-bottom">
                      <div className="helios-his-container">
                        <h6 className="trip-list-label">Last 3 Trip Ids</h6>
                        <ul id="trip-list" className="helios-last-trip-list">
                            {HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripIds && HeliosHistoryTrackingData.tripIds.map((x)=>{
                              let tripUrl = 'trip-'+x+'-'+sim;
                              return (<li onClick={(event)=>{this.onGoToHistory(tripUrl)}} key={x}>{x}</li>)
                            })}
                        </ul>
                      </div>
                      <Row className="helios-his-container">
                        <Col xs={12}>
                          <h6 className="trip-list-label ">Avg Speed</h6>
                          <p>{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.avgSpeed ? HeliosHistoryTrackingData.tripData.avgSpeed+' Km':'N/A'}</p>
                        </Col>
                        <Col xs={12}>
                          <h6 className="trip-list-label helios-right">Idle Time</h6>
                          <p className="helios-right">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.idleTime?utils.getTimeAsDuration(HeliosHistoryTrackingData.tripData.idleTime, "small"):'N/A'}</p>
                        </Col>
                      </Row>
                      <Row className="helios-his-container">
                        <Col xs={8} className="helios-info-container">
                          <h6 className="trip-list-label ">Run Time</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.runTime?utils.getTimeAsDuration(HeliosHistoryTrackingData.tripData.runTime, "small"):'N/A'}</p>
                        </Col>
                        <Col xs={8} className="helios-info-container">
                          <h6 className="trip-list-label ">Stopping Time</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.idleTime?utils.getTimeAsDuration(HeliosHistoryTrackingData.tripData.idleTime, "small"):'N/A'}</p>
                        </Col>
                        <Col xs={8} className="helios-info-container">
                          <h6 className="trip-list-label ">Distance</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.totalDistance?HeliosHistoryTrackingData.tripData.totalDistance+' Km':'N/A'}</p>
                        </Col>
                      </Row>
                      <div className="helios-his-container last-known-location">
                          <p>{HeliosHistoryTrackingData &&  HeliosHistoryTrackingData.lastKnownLocation && HeliosHistoryTrackingData.lastKnownLocation.address?HeliosHistoryTrackingData.lastKnownLocation.address:'N/A'}</p>
                      </div>
                    </Col>
                    <Col xs={24} className="helios-border-bottom">
                      <div className="stepper">
                        {HeliosHistoryTrackingData && !utils.isEmpty(HeliosHistoryTrackingData.response) && (HeliosHistoryTrackingData.response.reportResponse?.length > 0 || HeliosHistoryTrackingData.response.locationPoints?.length > 0) && <HeliosStepper tripData={HeliosHistoryTrackingData}></HeliosStepper>}
                      </div>
                    </Col>
                  </Row>}
                </TabPane>
                <TabPane tab="Trip ID" key="2" className="helios-trip-tab">
                  <Row>
                    <Col xs={24} className="helios-border-bottom">
                      <div className="helios-his-container">
                        <h6 className="helios-history-trip-id">Trip ID: {this.state.tripId}</h6>
                      </div>
                      <Row className="helios-his-container">
                        <Col xs={8} className="helios-info-container helios-border-right">
                          <h6 className="trip-list-label ">Pickup Time</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.pickUpDate?moment(HeliosHistoryTrackingData.tripData.pickUpDate).format(dateFormatCard):'N/A'}</p>
                        </Col>
                        <Col xs={8} className="helios-info-container helios-border-right">
                          <h6 className="trip-list-label ">Create Time</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.createdDate?moment(HeliosHistoryTrackingData.tripData.createdDate).format(dateFormatCard):'N/A'}</p>
                        </Col>
                        <Col xs={8} className="helios-info-container">
                          <h6 className="trip-list-label ">Start Time</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.response && HeliosHistoryTrackingData.response.tripStart && HeliosHistoryTrackingData.response.tripStart.startTime?moment(Number(HeliosHistoryTrackingData.response.tripStart.startTime)).format(dateFormatCard):'N/A'}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} className="helios-border-bottom">
                      <Row className="helios-his-container">
                        <Col xs={8} className="helios-info-container helios-border-right">
                          <h6 className="trip-list-label ">Closure Time</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.response && HeliosHistoryTrackingData.response.tripClosure && HeliosHistoryTrackingData.response.tripClosure.endTime?moment(Number(HeliosHistoryTrackingData.response.tripClosure.endTime)).format(dateFormatCard):'N/A'}</p>
                        </Col>
                        <Col xs={8} className="helios-info-container helios-border-right">
                          <h6 className="trip-list-label ">Total Km</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.totalDistance?HeliosHistoryTrackingData.tripData.totalDistance+' Km':'N/A'}</p>
                        </Col>
                        <Col xs={8} className="helios-info-container">
                          <h6 className="trip-list-label ">Trip Status</h6>
                          <p className="helios-info-value">{HeliosHistoryTrackingData && HeliosHistoryTrackingData.tripData && HeliosHistoryTrackingData.tripData.onTrip?'On Trip':'Off Trip'}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} className="helios-border-bottom">
                      {/* <Form.Item label="Date and Time" className="helios-date-picker" labelCol={{ span: 24 }}>
                        <RangePicker className="helios-range-picker"  showTime size='large' onChange={this.onSelectDateRange.bind(this)} defaultValue={[moment(defaultStartDate, dateFormat), moment(defaultEndDate, dateFormat)]} format={dateFormat} />
                      </Form.Item> */}
                      <div className="helios-his-container last-known-location-trip">
                        <p>{HeliosHistoryTrackingData &&  HeliosHistoryTrackingData.lastKnownLocation && HeliosHistoryTrackingData.lastKnownLocation.address?HeliosHistoryTrackingData.lastKnownLocation.address:'N/A'}</p>
                      </div>
                    </Col>
                    <Col xs={24} className="helios-border-bottom">
                      <div className="stepper">
                        {HeliosHistoryTrackingData && !utils.isEmpty(HeliosHistoryTrackingData.response) && (HeliosHistoryTrackingData.response.reportResponse?.length > 0) && <HeliosStepper tripData={HeliosHistoryTrackingData}></HeliosStepper>}
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  HeliosHistoryTrackingData: state.tracking.HeliosHistoryTrackingData,
  onDataLoad:state.tracking.loadingHeliosTrack,
});
const mapDispatchToProps = dispatch => ({
  getHeliosVehicleHistoryData: param => dispatch(getHeliosVehicleHistoryData(param)),
  getHeliosVehicleHistoryTripData: param => dispatch(getHeliosVehicleHistoryTripData(param)),
});
export default connect(mapStateToProps,mapDispatchToProps)(HeliosHistoryTracking);
