
import { initialState, CONSIGMENTS_SCAN_BEGIN, CONSIGMENTS_SCAN_SUCCESS, CONSIGMENTS_SCAN_FAILURE, UPLOAD_BOOKING_BEGIN, UPLOAD_BOOKING_SUCCESS, UPLOAD_BOOKING_FAILURE, UPLOAD_INSCAN_BEGIN } from './constants';

export const ScanReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSIGMENTS_SCAN_BEGIN: return ConsigmentScanBegin(state);
        case CONSIGMENTS_SCAN_SUCCESS: return ConsigmentScanSuccess(state, action);
        case CONSIGMENTS_SCAN_FAILURE: return ConsigmentScanFailure(state, action);
        
        case UPLOAD_BOOKING_BEGIN: return uploadBookingBegin(state);
        case UPLOAD_BOOKING_SUCCESS: return uploadBookingSuccess(state, action);
        case UPLOAD_BOOKING_FAILURE: return uploadBookingFailure(state, action);

        default:
            return state;
    }
}

function uploadBookingBegin(state, action) {
    return {
        ...state,
        bulkOperationLoading: true,
        bulkOperation: undefined
    };
}

function uploadBookingSuccess(state, action) {
    return {
        ...state,
        bulkOperationLoading: false,
        bulkOperation: action.payload
    };
}

function uploadBookingFailure(state, action) {
    return {
        ...state,
        bulkOperationLoading: false,
        error: action.payload
    };
}

function ConsigmentScanBegin(state, action) {
    return {
        ...state,
        loading: true
    };
}

function ConsigmentScanSuccess(state, action) {
    return {
        ...state,
        loading: false,
        scanedInfo: action.payload.responses
    };
}

function ConsigmentScanFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
        scanedInfo: []
    };
}
