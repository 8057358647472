import React, { useEffect, useState } from 'react'
import { Card, Button, Input, Row, Col, Empty, Pagination, Icon, Modal, PageHeader, Tooltip, Switch, Select } from "antd";
import { table as Table } from "../ui/table/Table";
import * as apiUtils from "../../common/utils/apiGateway";
import { canAllow } from "../../common/utils/utils";
import { permissionTags } from "../../configs/permissionTags";
import { apiRequest } from "../../common/utils/apiGateway";
import "./common.scss"
import _ from 'lodash';

function DefaultFMLMConfigs({ props }) {
    const { Option } = Select;
    const [fmlmUpdatePermission, setFmlmUpdatePermission] = useState({
        read: canAllow(permissionTags.fm_lm_config.fm_lm_country.read),
        create: canAllow(permissionTags.fm_lm_config.fm_lm_country.create),
        update: canAllow(permissionTags.fm_lm_config.fm_lm_country.update)
    })

    const [defaultConfig, setDefaultConfig] = useState([])

    const getDefaultData = () => {
        apiRequest({
            url: "/b2b/v1/hyperlocal-properties/get/default",
            method: "GET",
        })
            .then(res => {
                console.log(res);
                setDefaultConfig(res.data.response.properties)
            })
    }

    useEffect(() => {
        getDefaultData()
    }, [])

    const columns = [
        {
            title: "Default Values",
            dataIndex: "key",
            key: "key",
            render: (key, row) => `${row.key}`,
        },
        {
            title: 'Value',
            dataIndex: "value",
            key: "value",
            render: (value) => `${value}`,
        },
    ]


    return (
        <div >
            <Card className="panIndiaConfig" >
                <Table
                    bordered
                    columns={columns}
                    dataSource={defaultConfig}
                    rowKey={record => record._key}
                />
            </Card>
        </div >
    )
}

export default DefaultFMLMConfigs