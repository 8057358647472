import React from 'react';
import { Table } from 'antd';
import './Table.scss';

export const table = (props) => {
    let _props = { ...props }
    if (_props.pagination) {
        _props['pagination'] = {
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            ..._props.pagination
        }
    }
    return (
        <Table {..._props} />
    );
}