import React, { Component } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Modal,
  Form,
  Button,
  Tabs,
  Alert,
  List,
  Radio,
  Divider,
  Typography,
} from "antd";
import "./fleet.scss";
import { connect } from "react-redux";
import * as actions from "./action";
import { canAllow } from "../../../common/utils/utils";
import { permissionTags } from "../../../configs/permissionTags";
import { Constants } from "../../../common/utils/constants";
import {
  getDeliveryAgentDetailsColumn,
  getOrderDetailsColumn,
  getPickupDetailsColumn,
  getDeliveryDetailsColumn,
} from "./order.column.d";
import { dataGet } from "../../../common/utils/utils";
import { apiRequest } from "../../../common/utils/apiGateway";
import Reallocate from "./Reallocate";
import _ from "lodash";

import { validationRules } from "../../../common/utils/constants";
const { confirm } = Modal;
const { TextArea } = Input;
const { TabPane } = Tabs;

class RenderTapBar extends Component {
  render() {
    return <div>{this.props.children}</div>;
  }
}

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderPermission: {
        read: canAllow(permissionTags.hyperlocal.order_details.read),
        update: canAllow(permissionTags.hyperlocal.order_details.update),
        create: canAllow(permissionTags.hyperlocal.order_details.create),
        delete: canAllow(permissionTags.hyperlocal.order_details.delete),
      },
      updateStatusPermission: {
        create: canAllow(permissionTags.hyperlocal.hyperlocal_markdel.create),
      },
      consigneeNumberPermission: {
        create: canAllow(permissionTags.hyperlocal.hyperlocal_order.create),
      },
      visible: false,
      confirmButton: false,
      orderDetails: {},
      error: undefined,
      cancellationReason: undefined,
      showDetails: {
        showContactNumber: false,
        showNumber: undefined,
      },
      currentStatus: undefined,
      allowedStatus: [
        "ASSIGNED",
        "ARRIVED_AT_PICKUP_LOCATION",
        "PICKEDUP",
        "ARRIVED_AT_DELIVERY_LOCATION",
      ],
      renderStatus: {
        ASSIGNED: {
          label: "Update as Arrived at Pickup",
          nextStatus: "ARRIVED_AT_PICKUP_LOCATION",
        },
        ARRIVED_AT_PICKUP_LOCATION: {
          label: "Update as PickedUp",
          nextStatus: "PICKEDUP",
        },
        PICKEDUP: {
          label: "Update as Arrived at Delivery Location",
          nextStatus: "ARRIVED_AT_DELIVERY_LOCATION",
        },
        ARRIVED_AT_DELIVERY_LOCATION: {
          label: "Update as Delivered",
          nextStatus: "DELIVERED",
        },
        DELIVERED: {
          label: "Update Status",
        },
        CANCELLED: {
          label: "Update Status",
        },
        BOOKED: {
          label: "Update Status",
        },
        REJECTED: {
          label: "Update Status",
        },
        INITIATED: {
          label: "Update Status",
        },
        RTO_INITIATED: {
          label: "Update Status",
        },
      },
      updateReason: undefined,
      updateError: undefined,
      loadUpdate: false,
      reasonBo: {},
      consignmentId: undefined,
      trackingId: undefined,
      clientCode: undefined,
      cancelCategories: {},
      selectedReason: undefined,
      activeKey: undefined,
      isReAllocationAllowed: false,
      undeliveredReasons: {},
      undeliveredCategories: {},
      undeliveredModalVisible: false,
      undeliveredConfirmButton: false,
      undeliveredSelectedReason: undefined,
      isMarkedUndelivered: false,
      rtoValue: undefined,
      isRTOVerified: undefined,
      isRTOErrorMessage: undefined,
      isRTOCodeSent: false,
      isRTODelivered: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  getOrderDetails = (type, value) => {
    const data = {
      [type]: value,
    };
    apiRequest({
      url: `/v1/hyperlocal-ops/orderDetails`,
      method: "POST",
      data,
    }).then((res) => {
      // console.log(res);
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        this.setState({
          orderDetails: res?.data?.response,
          reasonBo: res?.data?.response?.reasonBO,
          currentStatus: res.data.response?.orderDetails?.orderStatus,
          clientCode: res.data.response?.orderDetails?.clientCode,
          consignmentId: res?.data?.response?.orderDetails?.consignmentId,
          trackingId: res?.data?.response?.orderDetails?.trackingId,
          isReAllocationAllowed: res?.data?.response?.isReAllocationAllowed,
          isRTOVerified: false,
          isRTOErrorMessage: undefined,
          isRTOCodeSent: false,
          isRTODelivered: false,
        });

        if (this.state.currentStatus === "RTO_INITIATED") {
          this.setState({ isMarkedUndelivered: true });
        }
      }
    });
  };

  setConfirmButton = (flag) => {
    this.setState({
      ...this.state,
      confirmButton: flag,
    });
  };

  handleSubmit = () => {
    // e.preventDefault();
    let { showDetails } = this.state;
    showDetails = {
      ...showDetails,
      showContactNumber: false,
      contactNumber: undefined,
    };

    this.setState(
      {
        orderDetails: {},
        showDetails,
      },
      () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
          if (err) return;
          if (values["orderid"]) {
            this.getOrderDetails("wayBillNo", values["orderid"]);
          } else if (values["contactNumber"]) {
            this.getOrderDetails("contactNumber", values["contactNumber"]);
          } else {
            Modal.error({
              title: "One of the field is mandatory",
            });
          }
          // this.getOrderDetails(values[this.state.OrderDetails.wayBillNo]);
        });
      }
    );
  };

  reset = () => {
    this.props.form.resetFields();
  };

  showModal = (payload, name) => {
    this.setState({
      visible: true,
      orderData: payload,
      NameOfClient: name,
      error: null,
      success: null,
      confirmButton: false,
    });
  };

  handleOk = (e) => {
    const { selectedReason } = this.state;
    if (selectedReason) {
      this.setState({ confirmButton: true });
      const data = {
        wayBillNo: this.state.orderDetails.orderDetails.wayBillNo,
        clientCode: this.state.clientCode,
      };

      apiRequest({
        url: `/b2c/v1/hyperlocal/orders/${data.clientCode}/${data.wayBillNo}/cancel`,
        method: "POST",
        data: {
          reasonId: selectedReason,
        },
      }).then((res) => {
        if (
          Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1
        ) {
          this.setState(
            {
              confirmButton: false,
              visible: false,
              orderDetails: res?.data?.response,
            },
            () => {
              this.handleCancel();
              this.getOrderDetails("wayBillNo", data?.wayBillNo);
            }
          );
        } else {
          this.setState({ confirmButton: false });
        }
      });
    } else {
      this.setState({ error: "Reason is Mandatory" });
    }
  };

  handleUndeliveredOk = (e) => {
    const { undeliveredSelectedReason } = this.state;
    if (undeliveredSelectedReason) {
      this.setState({ undeliveredConfirmButton: true });
      const data = {
        waybillNo: this.state.orderDetails.orderDetails.wayBillNo,
        reason: undeliveredSelectedReason,
        customer: this.state.clientCode,
      };

      apiRequest({
        url: "/v1/hyperlocal-ops/status/undelivered",
        method: "POST",
        data,
      }).then((res) => {
        if (
          Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1
        ) {
          this.setState(
            {
              undeliveredConfirmButton: false,
              isMarkedUndelivered: true,
              undeliveredModalVisible: false,
              orderDetails: res?.data?.response,
              isRTOCodeSent: true,
            },
            () => {
              this.handleCancel();
              this.getOrderDetails("wayBillNo", data?.waybillNo);
            }
          );
        } else {
          this.setState({
            undeliveredConfirmButton: false,
            isMarkedUndelivered: false,
          });
        }
      });
    } else {
      this.setState({ error: "Reason is Mandatory" });
    }
  };

  undeliveredChangeCategories = (e) => {
    this.setState({ undeliveredSelectedReason: e.target.value });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      cancellationReason: undefined,
      error: undefined,
      confirmButton: false,
      clientCode: undefined,
      cancelCategories: {},
      selectedReason: undefined,
      activeKey: undefined,
    });
  };

  handleCancelZepto = (e) => {
    this.setState({
      visible: false,
      cancellationReason: undefined,
      error: undefined,
      confirmButton: false,
      clientCode: "ZEPTO", //SHIPSY
      cancelCategories: {},
      selectedReason: undefined,
      activeKey: undefined,
    });
  };

  handleUndeliveredCancel = (e) => {
    this.setState({
      undeliveredModalVisible: false,
      undeliveredCategories: undefined,
      error: undefined,
      undeliveredConfirmButton: false,
      // clientCode: undefined,
      undeliveredReasons: {},
      undeliveredSelectedReason: undefined,
      // activeKey: undefined,
    });
  };

  getConsigneeDetails = () => {
    const data = {
      wayBillNo: this.state.orderDetails.orderDetails.wayBillNo, //this.props.form.getFieldValue("orderid"),
      referenceNo: "",
    };
    apiRequest({
      url: `/v1/hyperlocal-ops/consigneeNumber`,
      method: "POST",
      data,
    }).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        let { showDetails } = this.state;
        if (res.data.response?.consignmentContact?.phoneNumber) {
          showDetails = {
            ...showDetails,
            showContactNumber: true,
            contactNumber: res.data.response?.consignmentContact?.phoneNumber,
          };
        } else {
          showDetails = {
            ...showDetails,
            showContactNumber: true,
            contactNumber: res.data.response?.message,
          };
        }
        this.setState({ showDetails });
      }
    });
  };

  handleCancellation = (e) => {
    const cancellationReason = e.target.value;
    if (cancellationReason) {
      this.setState({
        cancellationReason,
        error: undefined,
      });
    } else {
      this.setState({
        cancellationReason,
        error: "Reason is Mandatory",
      });
    }
  };

  openCancelModal = () => {
    apiRequest({
      url: "/hyperlocal/v2/order/orderCancellationReasons",
      method: "GET",
    }).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        let categories = _.groupBy(
          res?.data?.response?.orderCancellationReasons,
          "reasonCategoryText"
        );
        this.setState({
          cancellationReason: categories,
          visible: true,
          error: undefined,
          confirmButton: false,
          cancelCategories: categories,
          activeKey: undefined,
        });
      }
    });
  };

  openUndeliveredModal = () => {
    apiRequest({
      url: `/hyperlocal/v2/order/rtoUnDeliverReasons`,
      method: "GET",
    }).then((res) => {
      if (Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1) {
        let categories = _.groupBy(
          res?.data?.response?.orderCancellationReasons,
          "reasonCategoryText"
        );
        this.setState({
          undeliveredReasons: categories,
          undeliveredModalVisible: true,
          // error: undefined,
          // confirmButton: false,
          // cancelCategories: categories,
          // activeKey: undefined,
        });
      }
    });
  };

  openUpdateModal = () => {
    this.setState({
      visibleUpdate: true,
      updateReason: _.isEmpty(this.state.reasonBo)
        ? undefined
        : this.state.reasonBo?.reason,
      updateError: undefined,
      loadUpdate: false,
    });
  };

  cancelUpdate = () => {
    this.setState({
      visibleUpdate: false,
      updateReason: undefined,
      updateError: undefined,
      loadUpdate: false,
    });
  };

  handleUpdateReason = (e) => {
    const updateReason = e.target.value;
    if (updateReason) {
      this.setState({
        updateReason,
        updateError: undefined,
      });
    } else {
      this.setState({
        updateReason,
        updateError: "Reason is Mandatory",
      });
    }
  };

  updateStatus = () => {
    let {
      updateReason,
      currentStatus,
      renderStatus,
      consignmentId,
      trackingId,
    } = this.state;
    if (updateReason) {
      this.setState({ loadUpdate: true });
      const nextStatus = renderStatus[currentStatus]?.nextStatus;
      const data = [
        {
          consignmentId: consignmentId,
          action: nextStatus,
          reason: updateReason,
          trackingId: trackingId,
        },
      ];

      apiRequest({
        url: `/b2b/v1/consignmentpod/updateOps`,
        method: "POST",
        data,
      }).then((res) => {
        if (
          Constants.SUCCESS_HTTP_CODES.indexOf(res?.data?.status?.code) > -1
        ) {
          this.cancelUpdate();
          // this.getOrderDetails(this.props.form.getFieldValue("orderid"));
          this.getOrderDetails(
            "wayBillNo",
            this.state.orderDetails.orderDetails.wayBillNo
          );
        } else {
          this.cancelUpdate();
        }
      });
    } else {
      this.setState({
        updateError: "Reason is Mandatory",
      });
    }
  };

  changeCategories = (e) => {
    this.setState({ selectedReason: e.target.value });
  };

  changeActiveKey = (key) => {
    this.setState({ activeKey: key });
  };

  RTOCodeChangeHandler = (e) => {
    this.setState({ rtoValue: e.target.value });
  };

  verifyRTOHandler = () => {
    const wayBill = this.state.orderDetails.orderDetails.wayBillNo;
    apiRequest({
      url: `/v1/hyperlocal-ops/${wayBill}/rto_delivered`,
      method: "POST",
      data: {
        orderCodeEvent: "RTO_DELIVERED",
        code: this.state.rtoValue || null,
        customerCode: this.state.clientCode,
      },
    }).then((res) => {
      if (res?.data?.response?.isValid) {
        this.setState({
          isRTOVerified: true,
          isRTOErrorMessage: false,
          isRTOCodeSent: false,
          isRTODelivered: true,
        });
        this.getOrderDetails(
          "wayBillNo",
          this.state.orderDetails.orderDetails.wayBillNo
        );
      } else {
        this.setState({
          isRTOVerified: false,
          isRTOErrorMessage: true,
          isRTODelivered: false,
        });
      }
    });
  };

  resendRTOHandler = () => {
    apiRequest({
      url: `/v1/hyperlocal/orders/RTO_DELIVERED/${this.state.orderDetails.orderDetails.wayBillNo}/code`,
      method: "POST",
      data: {
        customerCode: this.state.clientCode,
      },
    }).then((res) => {
      // console.log("resend otp", res?.data?.response?.isValid);
      if (res?.data?.response?.isValid) {
        this.setState({ isRTOCodeSent: true });
      }
    });
  };

  render() {
    const formItemLayout = {
      formLayout: "vertical",
    };
    const { getFieldDecorator } = this.props.form;
    const {
      orderDetails,
      showDetails,
      cancellationReason,
      error,
      allowedStatus,
      renderStatus,
      currentStatus,
      updateReason,
      updateError,
      updateStatusPermission,
      consigneeNumberPermission,
    } = this.state;

    const updateDetails = {
      getConsigneeDetails: this.getConsigneeDetails,
    };

    return (
      <>
        <Card className="">
          <Row gutter={[24, 24]} style={{ bottom: 20 }}>
            <Col span={24}>
              <Form {...formItemLayout}>
                <Row gutter={[6, 6]}>
                  <Col span={4}>
                    <Form.Item colon={false} label="Order ID">
                      {getFieldDecorator("orderid", {
                        // rules: [
                        //   {
                        //     required: false,
                        //     message: "Please input Order ID!",
                        //   },
                        // ],
                      })(<Input placeholder="Enter Order ID" />)}
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Typography style={{ padding: "50px 50px 50px 35px" }}>
                      Or
                    </Typography>
                  </Col>
                  <Col span={4}>
                    <Form.Item colon={false} label="FE Phone Number">
                      {getFieldDecorator("contactNumber", {
                        rules: [
                          {
                            pattern: validationRules.mobile,
                            message: "Please enter valid mobile number",
                          },
                        ],
                      })(<Input placeholder="Enter FE Phone Number" />)}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item colon={false} label=" ">
                      <Button
                        // disabled={!this.orderid || !this.contactNumber}
                        onClick={this.handleSubmit}
                        type="primary"
                        style={{ marginLeft: "20px" }}
                      >
                        Get Details
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          {!_.isEmpty(orderDetails) && (
            <>
              <Row gutter={[24, 24]} style={{ bottom: 30 }}>
                <Col span={12}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <div className="order-list">
                        <h3>Order Details</h3>
                        {[getOrderDetailsColumn(false)].map((data, key) => (
                          <Col key={key} span={24}>
                            <List
                              size="large"
                              bordered
                              dataSource={data}
                              renderItem={(item) => (
                                <List.Item>
                                  {!item.render ? (
                                    <>
                                      <Col span={10}>
                                        {!!item.title && (
                                          <h4>
                                            <b>{item.title}</b>
                                            <br />
                                          </h4>
                                        )}
                                        <b>{item.label}</b>
                                      </Col>
                                      <Col span={1}>
                                        <Divider
                                          type="vertical"
                                          orientation="left"
                                        />
                                      </Col>
                                      <Col span={13}>
                                        {!!item.title && <br />}
                                        <div style={{ wordWrap: "break-word" }}>
                                          {dataGet(
                                            this.state.orderDetails
                                              ?.orderDetails,
                                            item.dataIndex
                                          ) || "-"}
                                        </div>
                                      </Col>
                                    </>
                                  ) : (
                                    item?.render(
                                      item.dataIndex,
                                      this.state.orderDetails?.orderDetails
                                    )
                                  )}
                                </List.Item>
                              )}
                            />
                          </Col>
                        ))}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="order-list">
                        <h3>Delivery Agent Details</h3>
                        {[getDeliveryAgentDetailsColumn(false)].map(
                          (data, key) => (
                            <Col key={key} span={24}>
                              <List
                                size="large"
                                bordered
                                dataSource={data}
                                renderItem={(item) => (
                                  <List.Item>
                                    {!item.render ? (
                                      <>
                                        <Col span={13}>
                                          {!!item.title && (
                                            <h4>
                                              <b>{item.title}</b>
                                              <br />
                                            </h4>
                                          )}
                                          <b>{item.label}</b>
                                        </Col>
                                        <Col span={1}>
                                          <Divider
                                            type="vertical"
                                            orientation="left"
                                          />
                                        </Col>
                                        <Col span={10}>
                                          {!!item.title && <br />}
                                          <div
                                            style={{ wordWrap: "break-word" }}
                                          >
                                            {dataGet(
                                              this.state.orderDetails
                                                ?.deliveryAgentDetails,
                                              item.dataIndex
                                            ) || "-"}
                                          </div>
                                        </Col>
                                      </>
                                    ) : (
                                      item?.render(
                                        item.dataIndex,
                                        this.state.orderDetails
                                          ?.deliveryAgentDetails
                                      )
                                    )}
                                  </List.Item>
                                )}
                              />
                            </Col>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <div className="order-list">
                        <h3>Pickup Details</h3>
                        {[getPickupDetailsColumn(false)].map((data, key) => (
                          <Col key={key} span={24}>
                            <List
                              size="large"
                              bordered
                              dataSource={data}
                              renderItem={(item) => (
                                <List.Item>
                                  {!item.render ? (
                                    <>
                                      <Col span={10}>
                                        {!!item.title && (
                                          <h4>
                                            <b>{item.title}</b>
                                            <br />
                                          </h4>
                                        )}
                                        <b>{item.label}</b>
                                      </Col>
                                      <Col span={1}>
                                        <Divider
                                          type="vertical"
                                          orientation="left"
                                        />
                                      </Col>
                                      <Col span={13}>
                                        {!!item.title && <br />}
                                        <div style={{ wordWrap: "break-word" }}>
                                          {dataGet(
                                            this.state.orderDetails
                                              ?.pickUpDetails,
                                            item.dataIndex
                                          ) || "-"}
                                        </div>
                                      </Col>
                                    </>
                                  ) : (
                                    item?.render(
                                      item,
                                      this.state.orderDetails?.pickUpDetails
                                    )
                                  )}
                                </List.Item>
                              )}
                            />
                          </Col>
                        ))}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="order-list">
                        <h3>Delivery Details</h3>
                        {[
                          getDeliveryDetailsColumn(
                            !consigneeNumberPermission?.create ||
                              !allowedStatus.includes(currentStatus),
                            updateDetails,
                            showDetails
                          ),
                        ].map((data, key) => (
                          <Col key={key} span={24}>
                            <List
                              size="large"
                              bordered
                              dataSource={data}
                              renderItem={(item) => (
                                <List.Item>
                                  {!item.render ? (
                                    <>
                                      <Col span={9} style={{ padding: 5 }}>
                                        {!!item.title && (
                                          <h4>
                                            <b>{item.title}</b>
                                            <br />
                                          </h4>
                                        )}
                                        <b>{item.label}</b>
                                      </Col>
                                      <Col span={1}>
                                        <Divider
                                          type="vertical"
                                          orientation="left"
                                        />
                                      </Col>
                                      <Col span={14}>
                                        {!!item.title && <br />}
                                        <div style={{ wordWrap: "break-word" }}>
                                          {dataGet(
                                            this.state.orderDetails
                                              ?.deliveryDetails,
                                            item.dataIndex
                                          ) || "-"}
                                        </div>
                                      </Col>
                                    </>
                                  ) : (
                                    item?.render(
                                      item,
                                      this.state.orderDetails?.deliveryDetails
                                    )
                                  )}
                                </List.Item>
                              )}
                            />
                          </Col>
                        ))}
                      </div>
                    </Col>
                    {(this.state.clientCode === "MYNTRA_HL" || this.state.clientCode === "APOLLO") &&
                    (this.state.currentStatus === "UNDELIVERED" ||
                      this.state.currentStatus === "RTO_INITIATED") ? (
                      <>
                        {(this.state.isMarkedUndelivered ||
                          !this.state.isRTOVerified) && (
                          <Col span={24}>
                            <div className="order-undel-list">
                              <div className="order-Undel-msg">
                                <p style={{ margin: 0 }}>
                                  Order has been marked as UNDEL.Please standby
                                  to verify RTO code.
                                </p>
                                <p>
                                  Please inform the FE to refresh app & proceed
                                  for RTO.
                                </p>
                              </div>
                              <div style={{ marginTop: "5vh" }}>
                                <h3>RTO Code Details</h3>
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #C2CFD9",
                                    height: "5vh",
                                    marginBottom: "10px",
                                  }}
                                  className="order-list"
                                >
                                  <h4
                                    style={{ padding: "10px", width: "10vw" }}
                                  >
                                    Code Status
                                  </h4>
                                  <Col span={1}>
                                    <Divider
                                      type="vertical"
                                      orientation="left"
                                    />
                                  </Col>
                                  {this.state.isRTOVerified ? (
                                    <p
                                      style={{
                                        background: "#80c3ad",
                                        padding: "10px",
                                        width: "100%",
                                        height: "100% ",
                                      }}
                                    >
                                      Code verified Successfully!
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                  {this.state.isRTOErrorMessage && (
                                    <p
                                      style={{
                                        background: "#EC8F8E",
                                        padding: "10px",
                                        width: "50%",
                                        height: "100% ",
                                      }}
                                    >
                                      Incorrect Code Submitted!
                                    </p>
                                  )}
                                  {(this.state.isRTOCodeSent ||
                                    this.state.currentStatus ===
                                      "RTO_INITIATED") && (
                                    <span style={{ padding: "10px" }}>
                                      Code Sent
                                    </span>
                                  )}
                                </div>
                                {this.state.isRTOVerified ? (
                                  <></>
                                ) : (
                                  <div style={{ display: "flex" }}>
                                    <Input
                                      placeholder="Enter RTO Delivery Code"
                                      style={{ padding: "15px" }}
                                      onChange={this.RTOCodeChangeHandler}
                                    />
                                    <Button
                                      type="primary"
                                      onClick={this.verifyRTOHandler}
                                      disabled={!this.state.rtoValue}
                                    >
                                      Verify RTO Code
                                    </Button>
                                    <Button
                                      type="primary"
                                      onClick={this.resendRTOHandler}
                                    >
                                      Resend RTO Code
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                      </>
                    ) : this.state.clientCode === "ZEPTO" && //SHIPSY
                      (this.state.currentStatus === "UNDELIVERED" ||
                        this.state.currentStatus === "RTO_INITIATED") ? (
                      <>
                        {!this.state.isRTODelivered && (
                          <Col span={24}>
                            <div className="order-undel-list">
                              <div className="order-Undel-msg">
                                <p style={{ margin: 0 }}>
                                  Order has been marked as UNDEL.
                                </p>
                                <p>
                                  Please inform the FE to refresh app & proceed
                                  for RTO.
                                </p>
                              </div>
                            </div>
                          </Col>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Col>
              </Row>

              <Row
                gutter={[24, 24]}
                style={{ bottom: 30 }}
                // type="flex"
                justify="center"
                align="center"
              >
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {(this.state.clientCode === "MYNTRA_HL" || this.state.clientCode === "APOLLO") &&
                    this.state.currentStatus ===
                      "ARRIVED_AT_DELIVERY_LOCATION" ? (
                      <Button
                        type="danger"
                        disabled={
                          !updateStatusPermission?.create ||
                          !allowedStatus.includes(currentStatus)
                        }
                        onClick={this.openUndeliveredModal}
                        style={{ marginRight: "10px" }}
                      >
                        Update Order as Undelivered
                      </Button>
                    ) : this.state.clientCode === "ZEPTO" && //use SHIPSY for staging
                      (this.state.currentStatus ===
                        "ARRIVED_AT_DELIVERY_LOCATION" ||
                        this.state.currentStatus === "PICKEDUP") ? (
                      <>
                        <p
                          style={{
                            display: "inline-block",
                            width: "24%",
                            marginLeft: "-10%",
                          }}
                        >
                          PLEASE USE UNDELIVERED FOR CUSTOMER RELATED REASONS
                          ONLY. REST, USE CANCELLATION ONLY
                        </p>
                        <Button
                          disabled={
                            !updateStatusPermission?.create ||
                            !allowedStatus.includes(currentStatus)
                          }
                          onClick={this.openCancelModal}
                          style={{
                            marginRight: "10px",
                            background: "#f27f36",
                            color: "white",
                          }}
                        >
                          Update Order as Cancellation
                        </Button>
                        <Button
                          disabled={
                            !updateStatusPermission?.create ||
                            !allowedStatus.includes(currentStatus)
                          }
                          onClick={this.openUndeliveredModal}
                          style={{
                            marginRight: "10px",
                            border: "1px solid #f1aead",
                            color: "#d71e1d",
                          }}
                        >
                          Update Order as Undelivered
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="danger"
                        disabled={
                          !updateStatusPermission?.create ||
                          !allowedStatus.includes(currentStatus)
                        }
                        onClick={this.openCancelModal}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel Order
                      </Button>
                    )}
                    {this.state.clientCode === "ZEPTO" && //use SHIPSY for staging
                    (this.state.currentStatus === "UNDELIVERED" ||
                      this.state.currentStatus === "RTO_INITIATED") ? (
                      <Button
                        type="primary"
                        onClick={this.verifyRTOHandler}
                        style={{ marginRight: "10px" }}
                        disabled={this.state.isRTODelivered}
                      >
                        Update as RTO-Delivered
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        onClick={this.openUpdateModal}
                        disabled={
                          !updateStatusPermission?.create ||
                          !allowedStatus.includes(currentStatus)
                        }
                        style={{ marginRight: "10px" }}
                      >
                        {renderStatus[currentStatus]?.label}
                      </Button>
                    )}

                    {this.state.isReAllocationAllowed && false &&  (
                      <Reallocate
                        currentStatus={this.currentStatus}
                        orderDetails={this.state.orderDetails}
                        clientCode={this.state.clientCode}
                        orderid={this.props.form.getFieldValue("orderid")}
                        contactNumber={this.props.form.getFieldValue(
                          "contactNumber"
                        )}
                        getOrderDetails={this.getOrderDetails}
                        setConfirmButton={this.setConfirmButton}
                        isConfirmButton={this.state.confirmButton}
                      />
                    )}
                  </div>
                </div>
              </Row>
            </>
          )}

          <Modal
            title="Select Reason for Cancellation"
            visible={this.state.visible}
            width={700}
            onCancel={
              this.state.clientCode === "ZEPTO" //SHIPSY
                ? this.handleCancelZepto
                : this.handleCancel
            }
            footer={[
              <Button
                key="back"
                disabled={this.state?.confirmButton}
                type="default"
                onClick={
                  this.state.clientCode === "ZEPTO" //SHIPSY
                    ? this.handleCancelZepto
                    : this.handleCancel
                }
              >
                Back
              </Button>,
              <Button
                key="submit"
                loading={this.state?.confirmButton}
                type="primary"
                onClick={this.handleOk}
              >
                Submit
              </Button>,
            ]}
          >
            <div className="order-active">
              <Radio.Group
                onChange={this.changeCategories}
                value={this.state.selectedReason}
              >
                <Tabs tabPosition={"left"} type="card">
                  {[...Object.keys(this.state.cancelCategories)].map((i) => (
                    <TabPane tab={`${i}`} key={i}>
                      <List
                        bordered
                        dataSource={this.state.cancelCategories[i]}
                        renderItem={(item) => (
                          <List.Item>
                            <Radio value={item?.id}>{item?.reasonValue}</Radio>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ))}
                </Tabs>
              </Radio.Group>
              <div style={{ marginTop: 10 }}>
                {error && <Alert message={error} type="error" />}
              </div>
            </div>
          </Modal>

          <Modal
            title={`Are you sure you want to manually update this order status as ${renderStatus[currentStatus]?.label}`}
            visible={this.state.visibleUpdate}
            width={400}
            onCancel={this.cancelUpdate}
            footer={[
              <Button
                onClick={this.updateStatus}
                key="submit"
                loading={this.state?.loadUpdate}
                type="primary"
              >
                Submit
              </Button>,
            ]}
          >
            <div>
              <p>
                Reason for manual Update<sup>*</sup>
              </p>
              <TextArea
                rows={4}
                onChange={this.handleUpdateReason}
                value={updateReason}
              />
              <div style={{ marginTop: 10 }}>
                {updateError && <Alert message={updateError} type="error" />}
              </div>
            </div>
          </Modal>

          <Modal
            title="Select Reason for UNDELIVERY"
            visible={this.state.undeliveredModalVisible}
            width={700}
            onCancel={this.handleUndeliveredCancel}
            footer={[
              <Button
                key="back"
                disabled={this.state?.undeliveredConfirmButton}
                type="default"
                onClick={this.handleUndeliveredCancel}
              >
                Back
              </Button>,
              <Button
                key="submit"
                loading={this.state?.undeliveredConfirmButton}
                type="primary"
                onClick={this.handleUndeliveredOk}
              >
                Submit
              </Button>,
            ]}
          >
            <div className="order-active">
              <Radio.Group
                onChange={this.undeliveredChangeCategories}
                value={this.state.undeliveredSelectedReason}
              >
                <Tabs tabPosition={"left"} type="card">
                  {[...Object.keys(this.state.undeliveredReasons)].map((i) => (
                    <TabPane tab={`${i}`} key={i}>
                      <List
                        bordered
                        dataSource={this.state.undeliveredReasons[i]}
                        renderItem={(item) => (
                          <List.Item>
                            <Radio value={item?.id}>{item?.reasonValue}</Radio>
                          </List.Item>
                        )}
                      />
                    </TabPane>
                  ))}
                </Tabs>
              </Radio.Group>
              <div style={{ marginTop: 10 }}>
                {error && <Alert message={error} type="error" />}
              </div>
            </div>
          </Modal>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  cancellation: (payload) => dispatch(actions.cancellation(payload)),
  getCustomerName: (payload) => dispatch(actions.getCustomerName(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "orderDetails",
    onValuesChange: (props, val) => console.log(props.form.getFieldsValue()),
  })(OrderDetails)
);
