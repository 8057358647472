
import { apiRequest } from '../../common/utils/apiGateway';
export default class ZoneService {
  getAllZones() {
    const url = '/b2b/v1/zone';
    return apiRequest({
      url,
      method: "GET"
    });
  }

  getZone(id) {
    const url = '/b2b/v1/zone/' + id;
    return apiRequest({
      url,
      method: "GET"
    });
  }

  deleteZone(id) {
    const url = '/b2b/v1/zone/' + id;
    return apiRequest({
      url,
      method: "DELETE"
    });
  }

  updateZone(zoneId, payload, bool) {
    let url = `/b2b/v1/zone/routecacheclear/${zoneId}`;
    if (bool) {
      url = `/b2b/v1/zone/routecacheclear/${zoneId}`;
    } else {
      url = `/b2b/v1/zone/${zoneId}`;
    }

    return apiRequest({
      url,
      method: "PUT",
      data: payload
    });
  }

  createZone(payload) {
    const url = '/b2b/v1/zone';
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  fetchZones(payload) {
    const url = '/b2b/v1/zone/fetch';
    return apiRequest({
      url,
      method: "POST",
      data: payload
    });
  }

  fetchRoutes(data) {
    const url = `/b2b/v1/partners/${localStorage.getItem('loggedPartnerId')}/routes/fetch`;
    return apiRequest({
      url,
      method: 'POST',
      data
    });
  }
}