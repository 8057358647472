import { Constants } from '../../../common/utils/constants';
import * as _Constants from "./constants";
import ManageFleetService from '../../../services/fleetmanage/fleetmanage.service';


export const getFeSummary = (payload, isReset) => dispatch => {
    dispatch(feSummaryBegin());
    ManageFleetService.feSummaryFetch(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(feSummarySuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(feSummaryFailure(res ?.data ?.response));
        }
    });
};

function feSummaryBegin() {
    return {
        type: _Constants.GET_FE_SUMMARY_BEGIN,
    }
}

function feSummarySuccess(payload, isReset) {
    return {
        type: _Constants.GET_FE_SUMMARY_SUCCESS,
        payload: payload,
        isReset
    }
}

function feSummaryFailure(error) {
    return {
        type: _Constants.GET_FE_SUMMARY_FAILURE,
        payload: error
    }
}

export const getFeSummarypagination = (payload, isReset) => dispatch => {
    dispatch(feSummaryPageBegin());
    ManageFleetService.feSummaryPaginationFetch(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(feSummaryPageSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(feSummaryPageFailure(res ?.data ?.response));
        }
    });
};

function feSummaryPageBegin() {
    return {
        type: _Constants.GET_FE_SUMMARY_PAGINATION_BEGIN,
    }
}

function feSummaryPageSuccess(payload, isReset) {
    return {
        type: _Constants.GET_FE_SUMMARY_PAGINATION_SUCCESS,
        payload: payload,
        isReset
    }
}

function feSummaryPageFailure(error) {
    return {
        type: _Constants.GET_FE_SUMMARY_PAGINATION_FAILURE,
        payload: error
    }
}

export const getCityList = () => dispatch => {
    dispatch(cityListBegin());
    ManageFleetService.cityList().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(cityListSuccess(res ?.data ?.response));
        } else {
            dispatch(cityListFailure(res ?.data ?.response));
        }
    });
};

function cityListBegin() {
    return {
        type: _Constants.GET_CITY_LIST_BEGIN,
    }
}

function cityListSuccess(payload) {
    return {
        type: _Constants.GET_CITY_LIST_SUCCESS,
        payload: payload
    }
}

function cityListFailure(error) {
    return {
        type: _Constants.GET_CITY_LIST_FAILURE,
        payload: error
    }
}

export const getCityListById = (cityId) => dispatch => {
    dispatch(cityListByIdBegin());
    ManageFleetService.cityListbyId(cityId).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(cityListByIdSuccess(res ?.data ?.response));
        } else {
            dispatch(cityListByIdFailure(res ?.data ?.response));
        }
    });
};

function cityListByIdBegin() {
    return {
        type: _Constants.GET_CITY_LIST_ID_BEGIN,
    }
}

function cityListByIdSuccess(payload) {
    return {
        type: _Constants.GET_CITY_LIST_ID_SUCCESS,
        payload: payload
    }
}

function cityListByIdFailure(error) {
    return {
        type: _Constants.GET_CITY_LIST_ID_FAILURE,
        payload: error
    }
}

export const getkycSummary = (payload, isReset) => dispatch => {
    dispatch(kycSummaryBegin());
    ManageFleetService.kycSummaryFetch(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(kycSummarySuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(kycSummaryFailure(res ?.data ?.response));
        }
    });
};

function kycSummaryBegin() {
    return {
        type: _Constants.GET_KYC_SUMMARY_BEGIN,
    }
}

function kycSummarySuccess(payload, isReset) {
    return {
        type: _Constants.GET_KYC_SUMMARY_SUCCESS,
        payload: payload,
        isReset
    }
}

function kycSummaryFailure(error) {
    return {
        type: _Constants.GET_KYC_SUMMARY_FAILURE,
        payload: error
    }
}

export const getkycSummarypagination = (payload, isReset) => dispatch => {
    dispatch(kycSummaryPageBegin());
    ManageFleetService.kycSummaryPaginationFetch(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(kycSummaryPageSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(kycSummaryPageFailure(res ?.data ?.response));
        }
    });
};

function kycSummaryPageBegin() {
    return {
        type: _Constants.GET_KYC_SUMMARY_PAGINATION_BEGIN,
    }
}

function kycSummaryPageSuccess(payload, isReset) {
    return {
        type: _Constants.GET_KYC_SUMMARY_PAGINATION_SUCCESS,
        payload: payload,
        isReset
    }
}

function kycSummaryPageFailure(error) {
    return {
        type: _Constants.GET_KYC_SUMMARY_PAGINATION_FAILURE,
        payload: error
    }
}

export const getUserDetails = (id) => dispatch => {
    dispatch(userDataPageBegin());
    ManageFleetService.getUserDetails(id).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(userDataPageSuccess(res ?.data ?.response));
        } else {
            dispatch(userDataPageFailure(res ?.data ?.response));
        }
    });
};

function userDataPageBegin() {
    return {
        type: _Constants.GET_USER_DATA_BEGIN,
    }
}

function userDataPageSuccess(payload) {
    return {
        type: _Constants.GET_USER_DATA_SUCCESS,
        payload: payload,
    }
}

function userDataPageFailure(error) {
    return {
        type: _Constants.GET_USER_DATA_FAILURE,
        payload: error
    }
}


export const userApproval = (payload, locationId) => dispatch => {
    let payloadtwo = {
        "locationIds":locationId,
        "kycStatuses": ["ALL", "NOT_SUBMITTED", "VERIFICATION_PENDING", "REJECTED", "VERIFIED"],
        "pageNo": 1,
        "pageSize": 10
    }
    dispatch(userApproveBegin());
    ManageFleetService.userApproval(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(userApproveSuccess(res ?.data ?.response));
            dispatch(getkycSummarypagination(payloadtwo, true));
            dispatch(getkycSummary({
                "locationIds" : locationId
              }));
        } else {
            dispatch(userApproveFailure(res ?.data ?.response));
        }
    });
};

function userApproveBegin() {
    return {
        type: _Constants.GET_USER_APPROV_BEGIN,
    }
}

function userApproveSuccess(payload) {
    return {
        type: _Constants.GET_USER_APPROV_SUCCESS,
        payload: payload,
    }
}

function userApproveFailure(error) {
    return {
        type: _Constants.GET_USER_APPROV_FAILURE,
        payload: error
    }
}

export const getonDutyUsers = (id) => dispatch => {
    dispatch(userOndutyPageBegin());
    ManageFleetService.getonDutyUsers(id).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(userOndutyPageSuccess(res ?.data ?.response));
        } else {
            dispatch(userOndutyPageFailure(res ?.data ?.response));
        }
    });
};

function userOndutyPageBegin() {
    return {
        type: _Constants.GET_ONDUTY_USER_DATA_BEGIN,
    }
}

function userOndutyPageSuccess(payload) {
    return {
        type: _Constants.GET_ONDUTY_USER_DATA_SUCCESS,
        payload: payload,
    }
}

function userOndutyPageFailure(error) {
    return {
        type: _Constants.GET_ONDUTY_USER_DATA_FAILURE,
        payload: error
    }
}

export const getTemplates = () => dispatch => {
    dispatch(fetchTemplateBegin());
    ManageFleetService.getTemplates().then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(fetchTemplateSuccess(res ?.data ?.response));
        } else {
            dispatch(fetchTemplateFailure(res ?.data ?.response));
        }
    });
};

function fetchTemplateBegin() {
    return {
        type: _Constants.GET_FETCH_TEMPLATE_BEGIN,
    }
}

function fetchTemplateSuccess(payload) {
    return {
        type: _Constants.GET_FETCH_TEMPLATE_SUCCESS,
        payload: payload,
    }
}

function fetchTemplateFailure(error) {
    return {
        type: _Constants.GET_FETCH_TEMPLATE_FAILURE,
        payload: error
    }
}


export const getRunsheetList = (payload, isReset) => dispatch => {
    dispatch(runsheetListBegin());
    ManageFleetService.getRunsheetList(payload).then(res => {
        if (Constants.SUCCESS_HTTP_CODES.indexOf(res ?.data ?.status ?.code) > -1) {
            dispatch(runsheetListSuccess(res ?.data ?.response, isReset));
        } else {
            dispatch(runsheetListFailure(res ?.data ?.response));
        }
    });
};

function runsheetListBegin() {
    return {
        type: _Constants.GET_RUNSHEET_LIST_BEGIN,
    }
}

function runsheetListSuccess(payload, isReset) {
    return {
        type: _Constants.GET_RUNSHEET_LIST_SUCCESS,
        payload: payload,
        isReset
    }
}

function runsheetListFailure(error) {
    return {
        type: _Constants.GET_RUNSHEET_LIST_FAILURE,
        payload: error
    }
}


export const getRunsheetDetail = (enitiyId) => dispatch => {
    return ManageFleetService.getRunsheetDetail(enitiyId).then(res => {
        return res;
    });
};

export const callDropDownApi_v2 = (endpoint) => dispatch => {
    return ManageFleetService.callDropDownApi_v2(endpoint).then(res => {
        return res;
    });
};

export const createTemplate = (payload) => dispatch => {
    return ManageFleetService.createTemplate(payload).then(res => {
        return res;
    });
};

export const getAllFeSummarypagination = (payload) => dispatch => {
    return ManageFleetService.getAllFeSummarypagination(payload).then(res => {
        return res;
    });
};

export const sendPushNotification = (payload) => dispatch => {
    return ManageFleetService.sendPushNotification(payload).then(res => {
        return res;
    });
};