import { Button, Card, Empty, Modal, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../common/utils/utils";
import PaymentStatusService from "../../../../services/digital-payment/payment-status.service";
import "../payouts.scss";
const paymentService = new PaymentStatusService();

function Passbook(props) {
  const [loading, toggleLoading] = useState(false);
  const [modalloading, toggleModalLoading] = useState(false);
  const [response, setResponse] = useState({})
  const [modalResponse, setModalResponse] = useState({});
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    fetchPassbookInfo();
  }, []);

  const fetchPassbookInfo = async () => {
    await toggleLoading(true);
    paymentService
      .getPassbook()
      .then(resp => {
        if (resp?.data?.response) {
          const { response } = resp?.data;
          toggleLoading(false)
          setResponse(response)
        } else {
            toggleLoading(false)
        }
      })
      .catch(err => {
        console.error(err);
        toggleLoading(false)
      });
  };

  const handleOpenModal = async id => {
    await toggleModalLoading(true);
    await setisOpen(true);
    paymentService
      .getPassbookBreakup(id)
      .then(resp => {
        if (resp?.data?.response) {
          const { response } = resp?.data;
          toggleModalLoading(false);
          setModalResponse(response);
        } else {
          toggleModalLoading(false);
        }
      })
      .catch(err => {
        console.error(err);
        toggleModalLoading(false);
      });
  };

  const handleCancel = () => {
    setisOpen(false);
  };

  return (
    <div
      style={{
        margin: 16,
        minHeight: "95vh"
      }}
      className="payout-summary"
    >
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Button onClick={props.closeAllPage} shape="circle" icon="left" />
          Go Back
        </div>
        <Card style={{ marginTop: 8, borderRadius: 8 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.23,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#3b5a98",
                margin: "8px 0"
              }}
            >
              {formatCurrency(response.totalBalance)}
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.17,
                letterSpacing: "normal",
                textAlign: "center",
                color: "#0b1323"
              }}
            >
              {response.displayText || "-"}
            </div>
          </div>
        </Card>
        <Card style={{ marginTop: 8, borderRadius: 8 }}>
            {
                !response?.lineItems?.length && <div style={{
                    display:"flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Empty />
                </div>
            }
          {!!response?.lineItems?.length && response?.lineItems?.map(item => {
            return (
              <div
                style={{
                  padding: "8px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#f1f3f6",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 8
                }}
                onClick={() => handleOpenModal(item?.id)}
              >
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.43,
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#000000"
                    }}
                  >
                    {item.displayDate || "-"}
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.5,
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#8b8b8b"
                    }}
                  >
                    {item.description || "-"}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.13,
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#ff4053"
                  }}
                >
                  { item.type === "DEBIT" && formatCurrency(item?.amount)}
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.13,
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#5faa59"
                  }}
                >
                  { item.type === "CREDIT" && formatCurrency(item?.amount)}
                </div>
              </div>
            );
          })}
        </Card>
        <Modal
          visible={isOpen}
          onCancel={handleCancel}
          bodyStyle={{ padding: 14 }}
          centered
          footer={false}
        >
          <Spin spinning={modalloading}>
            <div
              style={{
                fontSize: "16px",
                fontWeight: 400,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "0.14px",
                textAlign: "left",
                color: "#14112d"
              }}
            >
              {`${modalResponse?.displayText ||
                "-"} : ${modalResponse?.amount || "0"}`}
            </div>
            <div
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "0.14px",
                textAlign: "left",
                color: "rgba(20, 17, 45, 0.72)",
                marginBottom: 10
              }}
            >
              {modalResponse?.description || "-"}
            </div>
            {modalResponse?.lineItems?.map(item => {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 8
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.14px",
                        textAlign: "left",
                        color: "#000"
                      }}
                    >
                      {item.displayText || "-"}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "0.14px",
                        textAlign: "right",
                        color: "#000"
                      }}
                    >
                      {formatCurrency(item?.amount)}
                    </div>
                  </div>
                  {!!item?.lineItems?.length &&
                    item?.lineItems?.map(subItem => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: 8,
                            marginLeft: 16
                          }}
                        >
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.14px",
                              textAlign: "left"
                            }}
                          >
                            {subItem?.displayText || "-"}
                          </div>
                          <div
                            style={{
                              fontSize: "12px",
                              fontWeight: 400,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "0.14px",
                              textAlign: "right"
                            }}
                          >
                            {formatCurrency(subItem?.amount)}
                          </div>
                        </div>
                      );
                    })}
                </>
              );
            })}
          </Spin>
        </Modal>
      </Spin>
    </div>
  );
}

export default Passbook;
