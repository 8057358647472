
export const initialState = {
    loading: false
}

export const FETCH_CUSTOMERS_BEGIN = "FETCH_CUSTOMERS_BEGIN";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const FETCH_CUSTOMER_BEGIN = "FETCH_CUSTOMER_BEGIN";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";

export const FETCH_ORGANISATION_BEGIN = "FETCH_ORGANISATION_BEGIN";
export const FETCH_ORGANISATION_SUCCESS = "FETCH_ORGANISATION_SUCCESS";
export const FETCH_ORGANISATION_FAILURE = "FETCH_ORGANISATION_FAILURE";

export const CUSTOMER_MODIFICATION_BEGIN = "CUSTOMER_MODIFICATION_BEGIN";
export const CUSTOMER_MODIFICATION_SUCCESS = "CUSTOMER_MODIFICATION_SUCCESS";
export const CUSTOMER_MODIFICATION_FAILURE = "CUSTOMER_MODIFICATION_FAILURE";

export const GET_HOPINCODE_BEGIN = "GET_HOPINCODE_BEGIN";
export const GET_HOPINCODE_SUCCESS = "GET_HOPINCODE_SUCCESS";
export const GET_HOPINCODE_FAILURE = "GET_HOPINCODE_FAILURE";

export const GET_BPINCODE_BEGIN = "GET_BPINCODE_BEGIN";
export const GET_BPINCODE_SUCCESS = "GET_BPINCODE_SUCCESS";
export const GET_BPINCODE_FAILURE = "GET_BPINCODE_FAILURE";

export const GET_ACCESS_KEYS_BEGIN = "GET_ACCESS_KEYS_BEGIN";
export const GET_ACCESS_KEYS_SUCCESS = "GET_ACCESS_KEYS_SUCCESS";
export const GET_ACCESS_KEYS_FAILURE = "GET_ACCESS_KEYS_FAILURE";
export const GET_RESET_ACCESS_KEYS = "GET_RESET_ACCESS_KEYS";


export const FETCH_SHIPPER_BEGIN = "FETCH_SHIPPER_BEGIN";
export const FETCH_SHIPPER_SUCCESS = "FETCH_SHIPPER_SUCCESS";
export const FETCH_SHIPPER_FAILURE = "FETCH_SHIPPER_FAILURE";
