
import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const RouteReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_ROUTE_BEGIN: return fetchRouteBegin(state);
        case _Constants.FETCH_ROUTE_SUCCESS: return fetchRouteSuccess(state, action);
        case _Constants.FETCH_ROUTE_FAILURE: return fetchRouteFailure(state, action);
        case _Constants.FETCH_ROUTER_BEGIN: return fetchRouterBegin(state);
        case _Constants.FETCH_ROUTER_SUCCESS: return fetchRouterSuccess(state, action);
        case _Constants.FETCH_ROUTER_FAILURE: return fetchRouterFailure(state, action);
        case _Constants.RESET_DATA: return fetchReset(state, action);
        case _Constants.FETCH_LOCATION_ZONE_SUCCESS: return fetchlocationZone(state, action);
        default:
            return state;
    }
}

function fetchlocationZone(state, action){
    return {
        ...state,
    }
}

function fetchReset(state, action) {
    return {
        ...state,
        route: {},
        selectedRoute: undefined
    }
}


function fetchRouteBegin(state, action) {
    return {
        ...state,
        loading : true,  
    };
}

function fetchRouteSuccess(state, action) {
    return {
        ...state,
        PaginatedRoutes: storePaginatedData(action ?.payload ?.currentPageNo, state.PaginatedRoute ? state.PaginatedRoute : {}, action ?.payload ?.routes , action.isReset),
        ...action.payload,
        loading : false,
    };
}

function fetchRouteFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        routes: [],
        loading : false,
    };
}

function fetchRouterBegin(state, action) {
    return {
        ...state,
    };
}

function fetchRouterSuccess(state, action) {
    return {
        ...state,
        selectedRoute: action.payload
    };
}

function fetchRouterFailure(state, action) {
    return {
        ...state,
        error: action.payload,
        selectedRoute: []
    };
}