import React from "react";
import { Input } from "antd";
import * as Constants from "../../../common/utils/constants";
const { TextArea } = Input;
export const input = props => {
  const size = props.size ? props.size : "";
  const placeholder = props.placeholder ? props.placeholder : "Enter Input";

  switch (props.type) {
    case Constants.INPUT_TYPE.TEXT:
      return <Input size={size?size:'default'} placeholder={placeholder} {...props} />;
    case Constants.INPUT_TYPE.TEXTAREA:
      const minRows = props.minRows ? props.minRows : "2";
      const maxRows = props.maxRows ? props.maxRows : "6";
      return (
        <TextArea
          placeholder={placeholder}
          autosize={{ minRows: minRows, maxRows: maxRows }}
          {...props}
        />
      );
    default:
      return <Input size={size?size:'default'} placeholder={placeholder} {...props} />;
  }
  return <Input size={size?size:'default'} placeholder={placeholder} {...props} />;
};

export const InputApi = Input;
