import * as CONSTANTS from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const auditReducer = (state = {}, action) => {
  switch (action.type) {
    case CONSTANTS.AUDIT_CHECK_BEGIN:
      return {
        ...state,
        auditStatus: {
          loading: true,

        }
      }
    case CONSTANTS.AUDIT_CHECK_SUCCESS:
      return {
        ...state,
        auditStatus: {
          loading: false,
          ...action.payload
        }
      }
    case CONSTANTS.AUDIT_CHECK_FAILURE:
      return {
        ...state,
        auditStatus: {
          loading: false,
        }
      }
    case CONSTANTS.FETCH_AUDITS_BEGIN: return fetchUsersBegin(state);
    case CONSTANTS.FETCH_AUDITS_SUCCESS: return fetchUsersSuccess(state, action);
    case CONSTANTS.FETCH_AUDITS_FAILURE: return fetchUsersFailure(state, action);
    default: return state;
  }
}

function fetchUsersBegin(state, action) {
  return {
    ...state,
    loading: true

  };
}

function fetchUsersSuccess(state, action) {
  return {
    ...state,
    loading: false,
    PaginatedAudits: storePaginatedData(action?.payload?.currentPageNo, state.PaginatedAudits ? state.PaginatedAudits : {}, action?.payload?.auditInfos, action.isReset),
    ...action.payload
  };
}

function fetchUsersFailure(state, action) {
  return {
    ...state,
    loading: false,
    error: action.payload,
    customers: []
  };
}


