import * as _Constants from './constants';
import { storePaginatedData } from '../../../common/utils/utils';

export const PromiseTimeReducer = (state = _Constants.initialState, action) => {
    switch (action.type) {
        case _Constants.FETCH_LOCATIONGROUP_BEGIN: return fetchZonesBegin(state);
        case _Constants.FETCH_LOCATIONGROUP_SUCCESS: return fetchZonesSuccess(state, action);
        case _Constants.FETCH_LOCATIONGROUP_FAILURE: return fetchZonesFailure(state, action);
        default:
            return state;
    }
}

function fetchZonesBegin(state) {
    return {
        ...state,
        loading: true
    };
}

function fetchZonesSuccess(state, action) {
    return {
        ...state,
        loading: false,
        ...action.payload
    };
}

function fetchZonesFailure(state, action) {
    return {
        ...state,
        loading: false,
        error: action.payload,
    };
}