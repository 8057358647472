import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer';
import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { taskMiddleware } from 'react-palm/tasks';
import axios from 'axios';
import history from './history';
import { userLogout } from '../components/shared/header/action';

const middleware = routerMiddleware(history);

const customMiddleWare = store => next => action => {
    if (action.type === 'API_PROCESS') {
        axios.interceptors.response.use((response) => {
            return response ? response : next(action);
        }, (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    store.dispatch(userLogout());
                    history.push('/');
                }
            }
        });
    };
    next(action);
}

/**
 * Create redux store that holds the app state.
 */
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunkMiddleware, customMiddleWare, middleware, taskMiddleware)
));

export default store;
