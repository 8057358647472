import React from 'react'
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import GooglePlaceAutoComplete from '../ui/google-place-autocomplete/google.place-autocomplete';
const pinkicon = L.icon({
    iconUrl:
      "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
    iconSize: [30, 30],
    iconAnchor: [10, 30],
  });
  
class LocationMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draggable: true,
    }
    this.refmarker = React.createRef();
  }

  componentDidMount() {
    this.setState({lat : this.props.lat, lng : this.props.lng})
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.lat !== prevProps.lat) {
      this.setState({lat : this.props.lat})
    }
    if (this.props.lng !== prevProps.lng) {
      this.setState({lng : this.props.lng})
    }
  }

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      this.setState({
        lat: marker.leafletElement.getLatLng().lat,
        lng: marker.leafletElement.getLatLng().lng,
      })
      this.props.updateMarker({ lat : marker.leafletElement.getLatLng().lat, lng: marker.leafletElement.getLatLng().lng});
    }
  
  }

  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable })
  }


  render() {
    let position;
    if(this.state.lat && this.state.lng){
      position = [this.state.lat, this.state.lng];
    }
    return (
      <LeafletMap
        center={position}
        zoom={18}
        maxZoom={18}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer
          url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
        />
        {this.state.lat && <Marker ref={this.refmarker} position={position} icon={pinkicon} draggable={this.state.draggable}
          onDragend={this.updatePosition}>
          <Popup width={150} direction="auto" className="locationPopform">
            <div className="tooltiplsn">
                <div className="tooltipLocation">{this.props.selectedAddress}</div>
            </div>
          </Popup>
        </Marker>}
      </LeafletMap>
    );
  }
}

export default LocationMap