import { notification } from 'antd';
import './Notification.scss';

export const openNotification = (props) => {
  const config = {
    message: `${props.message || ''}`,
    description: `${props.description}`,
    placement: `${props.placement || 'bottomRight'}`,
    onClose: () => {
      if (typeof props.onClose === "function") {
        props.onClose();
      }
    },
    className: props.className
  }
  if (props.error) {
    notification.error(config);
  } else if (props.warning) {
    notification.warning(config)
  } else {
    notification.open(config)
  }
};

export const destroyNotification = () => notification.destroy();
