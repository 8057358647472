import { Button, Divider, Row, Col, Alert } from "antd";
import React from "react";
import { dataGet } from "../../../common/utils/utils";
import moment from "moment";

export const formatDate = (date) => moment(date).format("DD MMM YY hh:mm:ss");
export function getOrderDetailsColumn(isBool) {
  return [
    {
      label: "Order ID",
      dataIndex: "wayBillNo",
      key: 1,
    },
    {
      label: "Client",
      dataIndex: "client",
      key: 2,
    },
    {
      label: "Order Value",
      dataIndex: "orderValue",
      key: 4,
    },
    {
      label: "Location",
      dataIndex: "location",
      key: 5,
    },
    {
      label: "Order Accepted At",
      dataIndex: "acceptedAt",
      key: 6,
      render: (key, value) => (
        <>
          <Col span={10}>
            <b>Order Accepted At</b>
          </Col>
          <Col span={1}>
            <Divider type="vertical" orientation="left" />
          </Col>
          <Col span={13}>{formatDate(dataGet(value, key))}</Col>
        </>
      ),
    },
    {
      label: "Current Order Status",
      dataIndex: "orderStatus",
      key: 7,
    },
    {
      label: "Current Status Updated At",
      dataIndex: "statusUpdatedAt",
      key: 8,
      render: (key, value) => (
        <>
          <Col span={10}>
            <b>Current Status Updated At</b>
          </Col>
          <Col span={1}>
            <Divider type="vertical" orientation="left" />
          </Col>
          <Col span={13}>{formatDate(dataGet(value, key))}</Col>
        </>
      ),
    },
    {
      label: "Was GeoFake Triggered?",
      dataIndex: "geoFakeTriggered",
      key: 9,
      render: (key, value) => (
        <>
          <Col span={10}>
            <b>Was GeoFake Triggered?</b>
          </Col>
          <Col span={1}>
            <Divider type="vertical" orientation="left" />
          </Col>
          <Col span={13}>
            {dataGet(value, key) ? (
              <Alert type="error" message={dataGet(value, key)} />
            ) : (
              "No"
            )}
          </Col>
        </>
      ),
    },
    {
      label: "Payment Type",
      dataIndex: "paymentType",
      key: 10,
    },

    {
      label: "Payable Amount",
      dataIndex: "payableAmount",
      key: 11,
    },

    {
      label: "Notes",
      dataIndex: "notes",
      key: 12,
    },
  ];
}
export function getPickupDetailsColumn(isBool) {
  return [
    {
      label: "Pickup Location Name",
      dataIndex: "locationName",
      key: 1,
    },
    {
      label: "Pickup Location Address",
      dataIndex: "address",
      key: 2,
    },
    {
      label: "Pickup Location Contact Number",
      dataIndex: "contactNumber",
      key: 4,
    },
  ];
}

export function getDeliveryDetailsColumn(isBool, updateDetails, showDetails) {
  return [
    {
      label: "Consignee Name",
      dataIndex: "consigneeName",
      key: 1,
    },
    {
      label: "Delivery Address",
      dataIndex: "address",
      key: 2,
    },
    {
      label: "Consignee Contact Number",
      dataIndex: "contactNumber",
      key: 4,
      render: (data) => (
        <>
          <Col span={9} style={{ padding: 5 }}>
            <b>Consignee Contact Number</b>
          </Col>
          <Col span={1}>
            <Divider type="vertical" orientation="left" />
          </Col>
          <Col span={14}>
            <Row gutter={[6, 6]}>
              <Col span={10}>
                {!showDetails?.showContactNumber ? (
                  <Button
                    disabled={isBool}
                    type="primary"
                    onClick={updateDetails?.getConsigneeDetails}
                  >
                    Get Details
                  </Button>
                ) : (
                  <b>{showDetails?.contactNumber}</b>
                )}
              </Col>
              <Col span={12}>
                <b style={{ fontSize: 11 }}>
                  Note: This request will be logged
                </b>
              </Col>
            </Row>
          </Col>
        </>
      ),
    },
  ];
}

export function getDeliveryAgentDetailsColumn(isBool) {
  return [
    {
      label: "Delivery Agent Name",
      dataIndex: "name",
      key: 1,
    },
    {
      label: "Delivery Agent Contact Number",
      dataIndex: "contactNumber",
      key: 2,
    },
    {
      label: "Active Since",
      dataIndex: "activeSince",
      key: 4,
      render: (key, value) => (
        <>
          <Col span={13}>
            <b>Active Since</b>
          </Col>
          <Col span={1}>
            <Divider type="vertical" orientation="left" />
          </Col>
          <Col span={10}>{formatDate(dataGet(value, key))}</Col>
        </>
      ),
    },
  ];
}

export function getCancelReasons() {
  return {
    orderCancellationReasons: [
      {
        id: 24,
        reasonValue: "Order Cancelled by client but no update sent",
        reasonCategory: "CLIENT_ISSUE",
      },
      {
        id: 23,
        reasonValue: "Already picked by different rider ",
        reasonCategory: "CLIENT_ISSUE",
      },
      {
        id: 22,
        reasonValue: "No response from Client Care Helpline",
        reasonCategory: "CLIENT_ISSUE",
      },
      {
        id: 21,
        reasonValue: "Rude behaviour at Restaurant",
        reasonCategory: "RESTAURANT_ISSUE",
      },
      {
        id: 20,
        reasonValue: "Restaurant Incorrect Address",
        reasonCategory: "RESTAURANT_ISSUE",
      },
      {
        id: 19,
        reasonValue: "Items Out of Stock",
        reasonCategory: "RESTAURANT_ISSUE",
      },
      {
        id: 18,
        reasonValue: "Delay by Restaurant",
        reasonCategory: "RESTAURANT_ISSUE",
      },
      {
        id: 17,
        reasonValue: "Customer asking for delivery to a different location",
        reasonCategory: "CUSTOMER_END_ISSUE",
      },
      {
        id: 16,
        reasonValue: "Customer Requested Order to be Cancelled",
        reasonCategory: "CUSTOMER_END_ISSUE",
      },
      {
        id: 15,
        reasonValue: "Customer Not Responding",
        reasonCategory: "CUSTOMER_END_ISSUE",
      },
      {
        id: 14,
        reasonValue: "Customer Address Incorrect",
        reasonCategory: "CUSTOMER_END_ISSUE",
      },
      {
        id: 13,
        reasonValue: "Rude Customer",
        reasonCategory: "CUSTOMER_END_ISSUE",
      },
      {
        id: 12,
        reasonValue: "Delivery Agent met with an accident",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 11,
        reasonValue: "Fraud Delivery Agent",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 10,
        reasonValue: "Delivery Agent Mobile Issues",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 9,
        reasonValue: "Delivery Agent denied servicing the order",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 8,
        reasonValue: "Delivery Agent not responding to calls",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 7,
        reasonValue: "Rude Behaviour",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 6,
        reasonValue: "Bike Issue ",
        reasonCategory: "DELIVERY_AGENT_END_ISSUE",
      },
      {
        id: 5,
        reasonValue: "Delivery Agent recieved multiple orders",
        reasonCategory: "TECHNICAL_ISSUES",
      },
      {
        id: 4,
        reasonValue: "Tech Issues",
        reasonCategory: "TECHNICAL_ISSUES",
      },
      {
        id: 3,
        reasonValue: "Delivery Agent stopped by local authority / Police",
        reasonCategory: "MISCELLANEOUS",
      },
      {
        id: 2,
        reasonValue: "Bad Weather ",
        reasonCategory: "MISCELLANEOUS",
      },
      {
        id: 1,
        reasonValue: "Road Blocked",
        reasonCategory: "MISCELLANEOUS",
      },
    ],
  };
}
