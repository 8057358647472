import React, { Component } from 'react';
import {
    Card, Row, Col, Input, Icon, Form, Radio, Result, Alert,
    Button, Table, Spin, Tooltip, Modal, DatePicker, Select, Divider
}
    from 'antd';

import WaybillLink from "../../shared-components/waybill-link/index";

import { canAllow, notifyApiError } from '../../../common/utils/utils';
import * as apiUtils from '../../../common/utils/apiGateway';
import "./Vendor.scss";
import _ from 'lodash';

import EPOD from '../../../assets/img/icons/epod.svg';
import HPOD from '../../../assets/img/icons/hpod.svg';


class VendorCardTitle extends Component {
    goBacktoVendor = () => {
        const { state } = this.props.location;
        this.props.history.push('/appv2/settings/dashboard/vendor', { ...state })
    }
    render() {
        return (
            <>
                <Row>
                    <Col md={8}>
                        <div><Icon type="arrow-left" onClick={() => this.goBacktoVendor()} /> {this.props.title}</div>
                    </Col>
                    <Col md={16}>
                        {this.props.children}
                    </Col>
                </Row>
            </>
        )
    }
}

class VendorBlock extends Component {

    state = {
        loading: false,
        vendorBlock: [],
        vendorName: ''
    }

    constructor(props) {
        super(props);

    }
    checkHttpStatus = (status) => {
        switch (status?.code) {
            case 403:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 401:
                notifyApiError(status?.message, "ERROR:");
                // window.location.href = '/';
                return false;
            case 500:
            case 503:
                notifyApiError(status?.message, "ERROR:");
                return false;
            case 200:
            case 201:
            case 202:
                return true
        }
    }


    componentDidMount() {
        // console.log("this", this.props.location)
        if (this.props.match.params.id) {
            const id = this.props.match.params.id;
            this.setState({ loading: true });
            apiUtils.apiRequest({
                url: `/b2b/v1/vendors/${id}/blocked?fetchMode=STRICT_BASIC&getConsignment=true`,
            }).then(response => {
                if (this.checkHttpStatus(response.data?.status)) {
                    if (response?.data?.response) {
                        const data = response?.data?.response;
                        let vendorBlock = [];
                        // const maxLen = Math.max(data?.hpodNotSubmittedCount, data?.epodNotSubmittedCount);
                        // vendorBlock = new Array(maxLen);
                        let ewaybills = [];
                        for (let i = 0; i < data?.epodNotSubmitted?.length; i++) {
                            ewaybills.push(data?.epodNotSubmitted[i]?.waybillNo)
                            vendorBlock.push({
                                "customer": data?.epodNotSubmitted[i]?.customer?.name,
                                "waybill": data?.epodNotSubmitted[i]?.waybillNo,
                                "bookingOffice": data?.epodNotSubmitted[i]?.bookingOfficeLoc?.name,
                                "source": data?.epodNotSubmitted[i]?.customerPickupLoc?.name,
                                "destination": data?.epodNotSubmitted[i]?.destinationShortCode?.favLocationBO?.name,
                                "breachedFor": <>
                                    <Tooltip title={"EPOD"}>
                                        <img src={EPOD} width="40" />
                                    </Tooltip>
                                </>
                            })


                        }
                        for (let i = 0; i < data?.hpodNotSubmitted?.length; i++) {
                            if (ewaybills.includes(data?.hpodNotSubmitted[i]?.waybillNo)) {
                                const waybill = data?.hpodNotSubmitted[i]?.waybillNo;
                                let index = vendorBlock.findIndex(v => v.waybill === waybill);
                                vendorBlock.splice(index, 1);
                                vendorBlock.push({
                                    "customer": data?.hpodNotSubmitted[i]?.customer?.name,
                                    "waybill": data?.hpodNotSubmitted[i]?.waybillNo,
                                    "bookingOffice": data?.hpodNotSubmitted[i]?.bookingOfficeLoc?.name,
                                    "source": data?.hpodNotSubmitted[i]?.customerPickupLoc?.name,
                                    "destination": data?.hpodNotSubmitted[i]?.destinationShortCode?.favLocationBO?.name,
                                    "breachedFor": <>
                                        <Tooltip title={"EPOD"}>
                                            <img src={EPOD} width="40" />
                                        </Tooltip>
                                        <Divider type="vertical" style={{border: "1px solid"}} />
                                        <Tooltip title={"HPOD"}>
                                            <img src={HPOD} width="40" />
                                        </Tooltip>

                                    </>
                                })

                            } else {
                                vendorBlock.push({
                                    "customer": data?.hpodNotSubmitted[i]?.customer?.name,
                                    "waybill": data?.hpodNotSubmitted[i]?.waybillNo,
                                    "bookingOffice": data?.hpodNotSubmitted[i]?.bookingOfficeLoc?.name,
                                    "source": data?.hpodNotSubmitted[i]?.customerPickupLoc?.name,
                                    "destination": data?.hpodNotSubmitted[i]?.destinationShortCode?.favLocationBO?.name,
                                    "breachedFor": <>
                                        <Tooltip title={"HPOD"}>
                                            <img src={HPOD} width="40" />
                                        </Tooltip>
                                    </>
                                })
                            }



                        }
                        // for (let i = 0; i < maxLen; i++) {
                        //     if (i < data?.epodNotSubmittedCount) {
                        //         // newData[i] = epod[i];
                        //         vendorBlock[i] = {
                        //             ...vendorBlock[i],
                        //             "epod_customer": data?.epodNotSubmitted[i]?.customer?.name,
                        //             "epod_waybill": data?.epodNotSubmitted[i]?.waybillNo,
                        //             "epod_source": data?.epodNotSubmitted[i]?.bookingOfficeLoc?.name,
                        //             "epod_destination": data?.epodNotSubmitted[i]?.destinationShortCode?.favLocationBO?.name,
                        //         }
                        //     } else {
                        //         vendorBlock[i] = {
                        //             ...vendorBlock[i],
                        //             "epod_customer": null,
                        //             "epod_waybill": null,
                        //             "epod_source": null,
                        //             "epod_destination": null,
                        //         }
                        //     }
                        //     if (i < data?.hpodNotSubmittedCount) {
                        //         // newData[i] = hpod[i];
                        //         vendorBlock[i] = {
                        //             ...vendorBlock[i],
                        //             "hpod_customer": data?.hpodNotSubmitted[i]?.customer?.name,
                        //             "hpod_waybill": data?.hpodNotSubmitted[i]?.waybillNo,
                        //             "hpod_source": data?.hpodNotSubmitted[i]?.bookingOfficeLoc?.name,
                        //             "hpod_destination": data?.hpodNotSubmitted[i]?.destinationShortCode?.favLocationBO?.name,
                        //         }
                        //     } else {
                        //         vendorBlock[i] = {
                        //             ...vendorBlock[i],
                        //             "hpod_customer": null,
                        //             "hpod_waybill": null,
                        //             "hpod_source": null,
                        //             "hpod_destination": null,
                        //         }
                        //     }
                        // console.log("newData", vendorBlock)
                        this.setState({ vendorBlock, loading: false, vendorName: data?.name })
                    }




                }

            })
        }
    }

    getTitle() {
        return (
            <VendorCardTitle {...this.props} title={`Vendor Block - ${this.state.vendorName}`}>
            </VendorCardTitle>
        )
    }

    searchData = e => {
        console.log("e", e)
    }



    render() {
        // const columns = [
        //     {
        //         title: <b style={{fontSize: 20}}> EPOD <img src={EPOD} width="40" /></b>,
        //         children: [
        //             {
        //                 title: 'WaybillNo',
        //                 dataIndex: 'epod_waybill',
        //                 key: 'epod_waybill',
        //                 width: 130
        //             }, {
        //                 title: 'Customer',
        //                 dataIndex: 'epod_customer',
        //                 key: 'epod_customer',
        //             }, {
        //                 title: 'Source',
        //                 dataIndex: 'epod_source',
        //                 key: 'epod_source',
        //             }, {
        //                 title: 'Destination',
        //                 dataIndex: 'epod_destination',
        //                 key: 'epod_destination',
        //             }
        //         ]

        //     }, {
        //         title: <b style={{fontSize: 20}}>HPOD <img src={HPOD} width="40" /></b>,
        //         children: [
        //             {
        //                 title: 'WaybillNo',
        //                 dataIndex: 'hpod_waybill',
        //                 key: 'hpod_waybill',
        //                 width: 130
        //             }, {
        //                 title: 'Customer',
        //                 dataIndex: 'hpod_source',
        //                 key: 'hpod_customer',
        //             }, {
        //                 title: 'Source',
        //                 dataIndex: 'hpod_source',
        //                 key: 'hpod_source',
        //             }, {
        //                 title: 'Destination',
        //                 dataIndex: 'hpod_destination',
        //                 key: 'hpod_destination',
        //             }
        //         ]
        //     }



        // ];
        const columns = [
            {
                title: 'WaybillNo',
                dataIndex: 'waybill',
                key: 'waybill',
                width: 140,
                render: (data) => <WaybillLink>{data}</WaybillLink>
            }, {
                title: 'Customer',
                dataIndex: 'customer',
                key: 'customer',
                width: 150,
            }, {
                title: 'Booking office',
                dataIndex: 'bookingOffice',
                key: 'bookingOffice',
                width: 140,
            }, {
                title: 'Source',
                dataIndex: 'source',
                key: 'source',
                width: 160,
            }, {
                title: 'Destination',
                dataIndex: 'destination',
                key: 'destination',
                width: 160,
            }, {
                title: 'Breached for',
                dataIndex: 'breachedFor',
                key: 'breachedFor',
                width: 150
            }
        ]


        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Card
                        title={this.getTitle()}
                        className="vendor-list-wrapper"
                    >
                        <Row gutter={[24, 24]}>
                            {/* <Col span={24}>
                                <Input.Search
                                    style={{ width: '30%' }}
                                    placeholder={"Search by WaybillNo"}
                                    onSearch={this.searchData}
                                />
                            </Col> */}
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    bordered
                                    dataSource={this.state.vendorBlock}
                                />
                            </Col>
                        </Row>





                    </Card>
                </Spin>
            </>
        )
    }
}

export default VendorBlock;