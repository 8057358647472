import { apiRequest } from '../../common/utils/apiGateway';

export default class RoleService {

    getRoles() {
        let url = `/b2c/v1/role`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    getRole(roleId) {
        let url = `/b2c/v1/role/${roleId}`;
        return apiRequest({
            url,
            method: "GET"
        });
    }

    createRole(role) {
        let url = `/b2c/v1/role`;
        return apiRequest({
            url,
            method: "POST",
            data: role
        });
    }

    updateRole(role) {
        let url = `/b2c/v1/role`;
        return apiRequest({
            url,
            method: "PUT",
            data: role
        });
    }

    getPermissions() {
        let url = `/b2c/v1/permissions`;
        return apiRequest({
            url,
            method: "GET"
        });
    }
}