import { apiRequest } from "../../common/utils/apiGateway";
import { BASE_URL_TYPES, Constants } from "../../common/utils/constants";

export default class PaymentStatusService {
  getPayments(data) {
    const url = `/b2c/v1/payments/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data
    });
  }

  getpayouts(data) {
    const url = `/b2c/v1/payouts/user/${data.feuserId}?pageNo=${data.pageNo}&pageSize=${data.pageSize}`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_HEADERS_WEBVIEW
    });
  }

  payoutDetailsdata(id) {
    const url = `/b2c/v1/payouts/${id}/break-up`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_HEADERS_WEBVIEW
    });
  }

  getPaidPayouts(data) {
    // localStorage.setItem("accessToken", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb21wYW55Ijoie1wiaWRcIjoyNjh9IiwibG9jYXRpb24iOiJ7XCJpZFwiOjI0NTU2MX0iLCJleHAiOjE2MzE1MTcwOTQsInVzZXIiOiJ7XCJpZFwiOjc2NzYsXCJwaG9uZU51bWJlclwiOlwiOTg5OTk4MDg0NlwifSJ9.rTkKC0DgnrqRaIYPOv0BXZa9OgSQDfgncl0jNhqnqgM");
    // localStorage.setItem("tokenId", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyI3MzNlZjg1OC1lNGQwLTQxNzAtYjAwNC1mYmE0OWI4MjRhMDQiOiJiSE51YldGemRHVnlhMlY1Wm05eWFuZDAifQ.x8ifm_uwyTyummvkd79LIuVeGrOt3oSh-5vPwyC-Rzs")

    const url = `/b2c/v1/payouts/fetch`;
    return apiRequest({
      url,
      method: "POST",
      data,
      overrideHeader: Constants.HTTP_HEADERS_WEBVIEW
      // overrideHeader: Constants.HTTP_HEADERS
    });
  }

  getPendingPayouts(data) {
    // localStorage.setItem("accessToken", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb21wYW55Ijoie1wiaWRcIjoyNjh9IiwibG9jYXRpb24iOiJ7XCJpZFwiOjI0NTU2MX0iLCJleHAiOjE2MzE1MTcwOTQsInVzZXIiOiJ7XCJpZFwiOjc2NzYsXCJwaG9uZU51bWJlclwiOlwiOTg5OTk4MDg0NlwifSJ9.rTkKC0DgnrqRaIYPOv0BXZa9OgSQDfgncl0jNhqnqgM");
    // localStorage.setItem("tokenId", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyI3MzNlZjg1OC1lNGQwLTQxNzAtYjAwNC1mYmE0OWI4MjRhMDQiOiJiSE51YldGemRHVnlhMlY1Wm05eWFuZDAifQ.x8ifm_uwyTyummvkd79LIuVeGrOt3oSh-5vPwyC-Rzs")

    const url = `/b2c/v1/pending-payouts/get`;
    return apiRequest({
      url,
      method: "POST",
      data,
      overrideHeader: Constants.HTTP_HEADERS_WEBVIEW
      // overrideHeader: Constants.HTTP_HEADERS
    });
  }
  getSummary(data) {
    // localStorage.setItem("accessToken", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb21wYW55Ijoie1wiaWRcIjoyNjh9IiwibG9jYXRpb24iOiJ7XCJpZFwiOjI0NTU2MX0iLCJleHAiOjE2MzE1MTcwOTQsInVzZXIiOiJ7XCJpZFwiOjc2NzYsXCJwaG9uZU51bWJlclwiOlwiOTg5OTk4MDg0NlwifSJ9.rTkKC0DgnrqRaIYPOv0BXZa9OgSQDfgncl0jNhqnqgM");
    // localStorage.setItem("tokenId", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyI3MzNlZjg1OC1lNGQwLTQxNzAtYjAwNC1mYmE0OWI4MjRhMDQiOiJiSE51YldGemRHVnlhMlY1Wm05eWFuZDAifQ.x8ifm_uwyTyummvkd79LIuVeGrOt3oSh-5vPwyC-Rzs")

    const url = `/b2c/v1/pending-payouts/getTotalPayoutSummary`;
    return apiRequest({
      url,
      method: "POST",
      data,
      overrideHeader: Constants.HTTP_HEADERS_WEBVIEW
      // overrideHeader: Constants.HTTP_HEADERS
    });
  }

  // new api for sumary v3
  getWeeklyPayoutSummary() {
    const url = `trule/user/${localStorage.getItem("feuserId")}/payouts/weekly`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  getWeeklyPayoutDetailSummary(date) {
    const url = `trule/user/${localStorage.getItem("feuserId")}/payouts/weekly/${date}`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  // new api for sumary v2
  getPayoutSummary(date) {
    const url = `trule/user/${localStorage.getItem("feuserId")}/payouts/${date}`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  // new titan apis
  getWalletSummary() {
    const url = `trule/user/${localStorage.getItem("feuserId")}/earnings`;
    return apiRequest({
      url,
      method: "POST",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  getPassbook() {
    const url = `trule/user/${localStorage.getItem("feuserId")}/earnings/passbook`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  getPassbookBreakup(id) {
    const url = `trule/user/${localStorage.getItem("feuserId")}/passbook/${id}/breakup`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  getEarnings(month) {
    const url = `trule/user/${localStorage.getItem("feuserId")}/earnings/${month}`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
  getEarnignsBreakup(id) {
    const url = `trule/user/${localStorage.getItem("feuserId")}/earnings/breakup/${id}`;
    return apiRequest({
      url,
      method: "GET",
      overrideHeader: Constants.HTTP_TITAN_AUTH_HEADERS,
      baseUrlType: BASE_URL_TYPES.TITAN
    });
  }
}
